import React, {Component, useEffect} from 'react';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';
import {ReRenderHook} from '../utils/hooks';
import {BitterSweet, Grass, LightBlue} from "../app/StyleCommon";
import {intFormatter, uniquesFormatter} from "../utils/formatter";

export default class extends Component {

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate() {
    this.renderChart();
  }

  render() {
    return (
      <div className="chart gauge-chart" style={{display: 'flex', justifyContent: 'center'}}>
        <div ref={this.containerRef}/>
      </div>
    )
  }

  renderChart() {
    let {data, formatter, markerValue, hideVisualMap, period} = this.props;
    const container = this.containerRef.current;
    const myChart = echarts.init(container, null, {renderer: 'svg', width: 120, height: 40});
    var option = {
      tooltip: {
        trigger: 'axis',
        formatter: (params) => {
          const param = params[0];
          const end = (period || {}).end;
          return `${moment(end).add(-data.length + param.dataIndex + 1, 'day').format('YYYY-MM-DD')}: ${formatter(param.value)}`;
        }
      },
      grid: {
        left: 5,
        right: 45,
        top: 10,
        bottom: 5
      },
      xAxis: {
        type: 'category',
        show: false,
        data: data.map((d, i) => i)
      },
      yAxis: {
        type: 'value',
        show: false,
        min: hideVisualMap ? 0 : _.min(data) * 1.1,
        max: _.max(data) * 1.1,
        position: 'right',
      },
      visualMap: hideVisualMap ? null : [
        {
          show: false,
          dimension: 1,
          pieces: [
            {
              gt: -10000,
              lte: -0.001,
              color: BitterSweet
            },
            {
              gt: 0,
              lte: 100000,
              color: Grass
            }
          ]
        }
      ],
      series: [{
        data: data,
        type: 'line',
        showSymbol: false,
        animation: false,
        itemStyle: {normal: {color: '#abcdf4'}},
        markLine: markerValue ? {
          animation: false,
          symbolSize: 4,
          precision: 4,
          label: {
            formatter: params => {
              return Number(params.value * 100).toFixed(2) + '%';
            }
          },
          lineStyle: {
            type: 'dotted',
            color: 'green',
            opacity: 0.3
          },
          data: [{
            name: 'Current',
            yAxis: markerValue
          }]
        } : null,
        areaStyle: {
          opacity: 0.5,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#e2efff'
            },
            {
              offset: 1,
              color: '#e1f0f6'
            }
          ])
        }
      }]
    };
    myChart.setOption(option);
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
}