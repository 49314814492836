import React, {Component} from 'react';
import ReactServer from 'react-dom/server';
import createTooltip from '../uikit/tooltip/index';
import * as d3 from 'd3';
import moment from 'moment';
import _ from 'lodash';
import {Aqua, BitterSweet, BlueJeans, DarkGray, Grass, Grass1, Mint,Aqua1} from '../app/StyleCommon';
import {Levander, LightGray, MediumGray, Sunflower, MenuBlue, RedSands, FaceBook, Google} from '../app/StyleCommon';
import {ReRenderHook} from '../utils/hooks';
import {SelectInlineStyled, BarWrapper} from './index.style';
import {TimeseriesWrapper, TooltipContainer} from './timeseries.style';
import {Table} from '../uikit/index';
import {metrics, algorithms} from '../utils/metadata';
import {timeseriesDimensions, getDimensionKeyLabel, getDimensionLabel, getMetricLabel} from './metadata';
import {pctFormatter, pct2Formatter, uniquesFormatter, getTicks, intFormatter} from '../utils/formatter';

const colors = [
  BlueJeans, Grass, Sunflower, Aqua, Levander,
  Mint, BitterSweet,
  MenuBlue, RedSands, Google,
  MediumGray, DarkGray, FaceBook,
  '#BAA286', '#AA8E69', '#8E8271', '#7B7163', '#3C3B3D', '#E6E9ED'
];

const staticCache = {};
export default class extends Component {

  constructor(props) {
    super(props);
    const {metadata, data, labelMap = {}} = this.props;
    const {items = [], period, dimension} = data || {};
    let processedData = items.map(d => {
      return {
        ...d,
        date: moment(d.date).startOf('day').format('YYYY-MM-DD'),
        dateObj: moment(d.date).startOf('day').toDate()
      }
    });
    // let dates = _.uniq(processedData.map(d => d.dateObj));
    let dimensionValues = _.uniq(processedData.map(d => d[dimension]));
    dimensionValues = this.sortDimensionValue(dimensionValues);
    if(dimensionValues.length > 18) {
      dimensionValues = dimensionValues.slice(0, 18);
      dimensionValues.push('Others');
      let otherMap = {};
      processedData = processedData.reduce((ret, next) => {
        if(dimensionValues.indexOf(next[dimension]) >= 0) {
          ret.push(next);
        } else {
          let existing = otherMap[next.date];
          if(existing) {
            ['clicks', 'errors', 'impressions', 'total_response_time'].forEach(k => {
              existing[k] = (existing[k] || 0) + (next[k] || 0);
            })
            existing.ctr = existing.clicks / existing.impressions;
            existing.error_rate = existing.errors / existing.impressions;
            existing.response_time = existing.total_response_time / existing.impressions;
          } else {
            next[dimension] = 'Others';
            otherMap[next.date] = next;
            ret.push(next);
          }
        }
        return ret;
      }, [])
    }

    let minDataDate = moment(_.min(processedData.map(d => d.date)));
    let maxDataDate = moment(_.max(processedData.map(d => d.date)));
    let {start, end} = period || {};
    if (moment(end).diff(moment(start), 'day') < 4) {
      start = moment(start).add(-3, 'day').format('YYYY-MM-DD');
      end = moment(end).add(3, 'day').format('YYYY-MM-DD');
    }
    let dates = [], cur = moment(start), counter = 0;
    const processForDay = cur => {
      dates.push(cur.toDate());
      let hasData = processedData.find(d => cur.isSame(d.date));
      if (cur >= minDataDate && cur <= maxDataDate) {
        dimensionValues.forEach(dv => {
          let item = processedData.find(d => d[dimension] === dv && cur.isSame(d.date));
          if (!item) {
            processedData.push({
              [dimension]: dv,
              clicks: 0,
              impressions: 0,
              uniques: 0,
              response_time: 0,
              error_rate: 0,
              ctr: 0,
              date: cur.format('YYYY-MM-DD'),
              dateObj: cur.toDate()
            })
          }
        })
      }
    }
    while (moment(end) >= cur && counter < 180) {
      processForDay(cur);
      cur.add(1, 'day');
      counter++;
    }
    // dates.push(moment().add(1, 'day').startOf('day').toDate());
    // dates.push(moment().startOf('day').toDate());
    let periodDomain = [_.min(dates), _.max(dates)];
    processedData = _.sortBy(processedData, _.property('date'));
    this.state = {
      data: processedData,
      period: periodDomain,
      dates,
      dimensionValues: dimensionValues,
      metric0: staticCache.metric0 || 'ctr',
      metric1: staticCache.metric1 || 'impressions',
      metric0Disabled: !!staticCache.metric0Disabled,
      metric1Disabled: !!staticCache.metric1Disabled
    };

    if (['site', 'access_method'].indexOf(dimension) < 0) {
      let {metric0, metric1} = this.state;
      this.state.metric0 = ['error_rate', 'response_time'].indexOf(metric0) < 0 ? metric0 : 'ctr';
      this.state.metric1 = ['errors'].indexOf(metric1) < 0 ? metric1 : 'impressions';
    }
    this.colorScale = d3.scaleOrdinal().domain(dimensionValues).range(colors);

    this.legendTooltip = createTooltip({
      targetSelector: 'dynamic',
      tipCreator: dv => {
        if(dimension === 'algorithm') {
          // return null;
          let algoMeta = algorithms.find(d => d.key === dv);
          if(!algoMeta || !algoMeta.description) {
            return null;
          }
          return algoMeta.description;
          // return algoMeta ? algoMeta.label + (algoMeta.description || '') : dv;
          // return (
          //   <div style={{maxWidth: '200px'}}>
          //     {!!algoMeta && algoMeta.label}
          //     {!!algoMeta && (algoMeta.description || '')}
          //     {!algoMeta && dv}
          //   </div>
          // )
        } else {
          return null;
        }
      },
      directionFunc: d => 'top',
    });
  }

  sortDimensionValue(values) {
    const {data, labelMap = {}} = this.props;
    const {dimension} = data || {};
    if (dimension === 'variant') {
      let sorted = _.sortBy(values.map(d => ({id: d, ...labelMap[d]})), _.property('name'));
      let exists = sorted.filter(d => !!d.exist);
      let nonexists = sorted.filter(d => !d.exist);
      return [...exists, ...nonexists].map(d => d.id);
    } else if (dimension === 'slot_position') {
      let sorted = _.sortBy(values, d => Number(d));
      return sorted;
    } else {
      let sorted = values.sort();
      ['cxense', 'rnd', 'Hybrid', 'na', 'Cxense'].forEach(k => {
        sorted.push(sorted.splice(sorted.indexOf(k), 1)[0]);
      })
      return sorted;
    }
  }

  isMetricSupported(metric) {
    return ['clicks', 'impressions', 'ctr'].indexOf(metric) >= 0;
    // const {dimension} = this.props.data || {};
    // if (['site', 'access_method', 'widget_type', 'site_widget_level'].indexOf(dimension) < 0) {
    //   return ['errors', 'error_rate', 'response_time'].indexOf(metric) < 0;
    // }
    // return true;
  }

  render() {
    const {labelMap = {}} = this.props;
    const {dimension} = this.props.data;
    const {dimensionValues, data, metric0, metric1, metric0Disabled, metric1Disabled} = this.state;
    return (
      <TimeseriesWrapper className={this.props.className}>
        <div className="wrapper-header">
          <div className="legend">
            {dimensionValues.filter(dv => (dv == 0 || !!dv)).map((dv, i) => {
              return (
                <div
                  key={`${dv} -  ${i}`}
                  className={`legend-item`}
                  style={{cursor: 'pointer'}}
                  onMouseOver={this.legendTooltip.onMouseOver(dv)}
                  onMouseLeave={this.legendTooltip.onMouseOut()}>
                  <span className="rect" style={{backgroundColor: this.colorScale(dv)}}></span>
                  <span>{getDimensionLabel(dimension, dv, labelMap)}</span>
                </div>
              )
            })}
          </div>
          <div className="legend nowrap">
            <div className={`legend-item ${metric0Disabled ? 'disabled' : ''}`}>
              <span className="icon line blue"
                    onClick={e => {
                      this.setState({metric0Disabled: !metric0Disabled});
                      staticCache.metric0Disabled = !metric0Disabled;
                    }}/>
              <span style={{margin: '2px 5px 0 5px'}}>CTR</span>
              {/*<SelectInlineStyled*/}
              {/*  selected={metric0}*/}
              {/*  data={metrics.filter(d => d.type === 'efficiency' && this.isMetricSupported(d.key))}*/}
              {/*  onChange={metric0 => {*/}
              {/*    this.setState({metric0: metric0});*/}
              {/*    staticCache.metric0 = metric0;*/}
              {/*  }}/>*/}
            </div>
            <div className={`legend-item ${metric1Disabled ? 'disabled' : ''}`}>
              <span className="icon rect blue"
                    onClick={e => {
                      this.setState({metric1Disabled: !metric1Disabled});
                      staticCache.metric1Disabled = !metric1Disabled;
                    }}/>
              <span style={{margin: '2px 5px 0 5px'}}>Impressions</span>
              {/*<SelectInlineStyled*/}
              {/*  selected={metric1}*/}
              {/*  data={metrics.filter(d => d.type === 'cumulative' && this.isMetricSupported(d.key))}*/}
              {/*  onChange={metric1 => {*/}
              {/*    this.setState({metric1: metric1});*/}
              {/*    staticCache.metric1 = metric1;*/}
              {/*  }}/>*/}
              {/*<span>{this.getMetricLabel('impressions')}</span>*/}
            </div>
          </div>
        </div>
        <div className="svgContainer" ref="svgContainer"/>
        <ReRenderHook renderer={this.renderChart.bind(this)}/>
      </TimeseriesWrapper>
    )
  }

  trimDate(today) {
    return moment(today).startOf('day');
  }

  getMetricFormatter(metric) {
    let metricMeta = metrics.find(m => m.key === metric) || {};
    return metricMeta.formatter || ((v) => v);
  }

  renderChart() {
    const {labelMap = {}} = this.props;
    const {dimension} = this.props.data;
    const {data, dimensionValues, period, dates, metric0, metric1, metric0Disabled, metric1Disabled} = this.state;
    const container = this.refs.svgContainer;
    if (!container) {
      return;
    }
    d3.select(container).select('*').remove();
    const grouped = _.groupBy(data, _.property(dimension));
    const groupedByDate = _.groupBy(data, _.property('date'));
    const dailyMetric0Values = _.mapValues(groupedByDate, arr => _.max(arr.map(a => a[metric0])))
    const dailyMetric1Values = _.mapValues(groupedByDate, arr => _.sum(arr.map(a => a[metric1])))

    const svgWidth = container.clientWidth;
    const svgHeight = container.clientHeight || 200;
    const margin = {top: 20, left: 50, bottom: 25, right: 50};
    const width = svgWidth - margin.left - margin.right;
    const height = svgHeight - margin.top - margin.bottom;

    d3.select(container).selectAll("*").remove();
    const svg = d3.select(container).append('svg').attr('class', 'timeseries-grouped-svg').attr('width', svgWidth).attr('height', svgHeight);

    const x = d3.scaleTime().range([0, width]).domain(period);

    const y1 = d3.scaleLinear().range([0, height]).domain([_.max(Object.values(dailyMetric0Values)) || .01, 0]);
    const y2 = d3.scaleLinear().range([0, height]).domain([_.max(Object.values(dailyMetric1Values)) || 1, 0]);

    let timeFormat = d3.timeFormat('%b %d');
    let timeFormatFull = d3.timeFormat('%b %d %Y');

    svg.append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(${margin.left}, ${height + margin.top})`)
      .call(d3.axisBottom(x).tickSize(5).tickPadding(10).ticks(Math.round(window.innerWidth / 150)));
    d3.selectAll('.x.axis .tick text').attr('opacity', d => {
      return d.getHours() === 0 ? '1' : '0';
    });

    let ticks2 = getTicks(y2.domain(), 5);
    let ticks1 = ticks2.map(d => (y1.domain()[0] * d / y2.domain()[0]));

    let metric0Formatter = this.getMetricFormatter(metric0);
    let metric1Formatter = this.getMetricFormatter(metric1);
    const yAxisLeft = d3.axisRight(y1).tickValues(ticks1).tickPadding(10).tickFormat(metric0Formatter).tickSize(-width - 20);
    const yAxisRight = d3.axisLeft(y2).tickValues(ticks2).tickPadding(10).tickFormat(metric1Formatter).tickSize(-width - 20);

    svg.append('g')
      .attr('class', 'y axis right')
      .attr('transform', `translate(${margin.left + width + 10}, ${margin.top})`)
      .call(yAxisRight)
      .append('text')
      .attr('y', height + 10)
      .attr('dy', '0')
      .attr('dx', '40px')
      .attr('fill', '#959595')
      .attr('text-anchor', 'end')
      .style('font-size', 10)
      .text(getMetricLabel(dimension, metric1));

    svg.append('g')
      .attr('class', 'y axis left')
      .attr('transform', `translate(${margin.left}, ${margin.top})`)
      .call(yAxisLeft)
      .append('text')
      .attr('y', height + 10)
      .attr('dy', '0')
      .attr('dx', '-40px')
      .attr('fill', '#959595')
      .style('text-anchor', 'start')
      .style('font-size', 10)
      .text(getMetricLabel(dimension, metric0));

    var focus = svg.append('g')
      .attr('class', `focus`)
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    let bandWidth = width / dates.length;
    let barWidth = width * 0.5 / dates.length;
    barWidth = barWidth < 20 ? barWidth : 20;
    Object.keys(groupedByDate).forEach(date => {
      let stack = 0;
      let stacked = _.sortBy(groupedByDate[date], d => dimensionValues.indexOf(d[dimension])).map(d => {
        let ret = {...d, stack: stack};
        stack += d[metric1];
        return ret;
      });
      focus.selectAll(`.stack .d${date}`).append('g')
        .attr('class', `stack .d${date}`)
        .data(stacked)
        .enter()
        .append('rect')
        .attr('class', `stack-bar`)
        .attr('x', d => x(d.dateObj) - barWidth / 2)
        .attr('y', d => y2(d.stack + d[metric1]))
        .attr('fill', (d, i) => metric1Disabled ? 'none' : this.colorScale(d[dimension]))
        .attr('fill-opacity', '0.9')
        .attr('rx', 1)
        .attr('width', barWidth)
        .attr('height', (d) => height - y2(d[metric1]))
    });

    var line = d3.line().curve(d3.curveMonotoneX).x(d => x(d.dateObj)).y(d => y1(d[metric0]));

    _.sortBy(Object.keys(grouped), k => dimensionValues.indexOf(k)).forEach((groupKey, i) => {
      focus.append('path')
        .attr('stroke', metric0Disabled ? 'none' : this.colorScale(groupKey))
        .attr('stroke-width', 2)
        .attr('stroke-linecap', 'round')
        .attr('stroke-opacity', labelMap[groupKey] && !labelMap[groupKey].exist ? '0' : '1')
        .attr('stroke-dasharray', labelMap[groupKey] && !labelMap[groupKey].exist ? '10, 10' : 'none')
        .attr('fill', 'none')
        .attr('d', line(grouped[groupKey]))
        .attr('style', 'pointer-events: none');
    });

    var zoomRect = svg.append('g').append("rect").attr("width", width).attr("height", height).attr('fill-opacity', '0')
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    var verticalLine = focus.append('line').attr('class', 'verticalLine').attr('stroke', MediumGray).attr('y2', height).attr('y1', 0).attr('stroke-dasharray', '5 5').attr('opacity', 0);
    var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    var getPointData = function () {
      let offsetX = d3.event.offsetX + (isFirefox ? margin.left : 0);
      let m = moment(x.invert(offsetX - margin.left + barWidth / 2)).startOf('day');
      let pointData = data.filter(d => m.isSame(d.dateObj)) || {};
      return {pointData, mnt: m};
    }
    var tooltip = createTooltip({
      targetSelector: 'dynamic',
      tipCreator: d => {
        let {pointData, mnt} = getPointData();
        let dateFormat = 'DD-MMM-YYYY, dddd';
        let groupKeys = _.sortBy(Object.keys(grouped), k => dimensionValues.indexOf(k))
        let sortMetric = 'ctr';
        let tableData = groupKeys.map(dv => {
          return {
            [dimension]: dv,
            ...(pointData.find(d => d[dimension] === dv))
          }
        }).filter(d => !!d.impressions);
        tableData = _.sortBy(tableData, td => td[sortMetric] || 0).reverse();
        return ReactServer.renderToString(
          <TooltipContainer>
            <div className="time">{mnt.format(dateFormat)}</div>
            {
              !!tableData.length &&
              <div className="table-tip">
              <Table
                columns={[
                  {
                    key: dimension,
                    label: getDimensionKeyLabel(dimension),
                    renderer: (d, i) => (
                      <span>
                      <span className="rect" style={{backgroundColor: this.colorScale(d[dimension])}}/>
                        {getDimensionLabel(dimension, d[dimension], labelMap)}
                    </span>
                    )
                  },
                  {
                    key: 'impressions', label: 'Impressions', align: 'center',
                    renderer: d => (
                      sortMetric !== 'impressions' ? intFormatter(d.impressions) :
                        <BarWrapper>
                          <div className="num">{intFormatter(d.impressions)}</div>
                          <div className="bar"
                               style={{width: pctFormatter(d.impressions / _.max(tableData.map(x => x.impressions || 0)))}}></div>
                        </BarWrapper>
                    )
                  },
                  {
                    key: 'clicks', label: 'Clicks', align: 'center',
                    renderer: d => (
                      sortMetric !== 'clicks' ? intFormatter(d.clicks) :
                        <BarWrapper>
                          <div className="num">{intFormatter(d.clicks)}</div>
                          <div className="bar"
                               style={{width: pctFormatter(d.clicks / _.max(tableData.map(x => x.clicks || 0)))}}></div>
                        </BarWrapper>
                    )
                  },
                  {
                    key: 'ctr', label: 'CTR', align: 'center',
                    renderer: d => (
                      sortMetric !== 'ctr' ? pct2Formatter(d.ctr) :
                        <BarWrapper>
                          <div className="num">{pct2Formatter(d.ctr)}</div>
                          <div className="bar"
                               style={{width: pct2Formatter(d.ctr / _.max(tableData.map(x => x.ctr || 0)))}}></div>
                        </BarWrapper>
                    )
                  },
                  {
                    key: 'response_time', label: 'Response Time', align: 'center',
                    renderer: d => (
                      d3.format('.2')((d.response_time || 0) / 1000) + 's'
                    ),
                    hidden: ['site', 'access_method'].indexOf(dimension) < 0
                  },
                  {
                    key: 'errors', label: 'Errors', align: 'center',
                    hidden: ['site', 'access_method'].indexOf(dimension) < 0,
                    renderer: d => (
                      sortMetric !== 'errors' ? intFormatter(d.errors) :
                        <BarWrapper>
                          <div className="num">{intFormatter(d.errors)}</div>
                          <div className="bar"
                               style={{width: pctFormatter(d.errors / _.max(tableData.map(x => x.errors || 0)))}}></div>
                        </BarWrapper>
                    )
                  },
                ]}
                rows={tableData}
                noDataText={""}
              />
              </div>
            }
          </TooltipContainer>
        )
      },
      directionFunc: d => {
        return window.innerWidth - d3.event.pageX < 220 ? 'lefttop' : 'top';
      },
      positionFunc: d => {
        let {pointData, mnt} = getPointData();
        let offsetX = d3.event.offsetX + (isFirefox ? margin.left : 0);
        let offsetY = d3.event.offsetY + (isFirefox ? (margin.top * 2) : 0);
        return [
          d3.event.pageX - offsetX + margin.left + x(mnt.toDate()),
          d3.event.pageY - offsetY + 20
        ]
      }
    });

    function handleMouseOver(d, i) {
      tooltip.show.call(this, d, i);
      let {pointData, mnt} = getPointData();
      // d3.selectAll('.bar').filter((d, index) => (d && mnt.isSame(d.dateObj))).style('fill', BlueJeans)
      // d3.selectAll('.bar').filter((d, index) => (d && !mnt.isSame(d.dateObj))).style('fill', 'url(#barGradient)')
      verticalLine
        .attr('x1', x(mnt.toDate()))
        .attr('x2', x(mnt.toDate()))
        .attr('pointer-events', 'none')
        .attr('opacity', 1);
    };

    function handleMouseOut(d) {
      tooltip.hide.call(this, d);
      verticalLine.attr('opacity', 0);
      let {pointData, mnt} = getPointData();
      // d3.selectAll('.bar').attr('stroke', LightGray).style('fill', 'url(#barGradient)')
    };

    zoomRect.on('mouseover', handleMouseOver).on('mousemove', handleMouseOver).on('mouseout', handleMouseOut)
  }
}