import React from 'react';
import ReactDOM from 'react-dom';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import {withConsumer} from '../app/ApplicationContext';
import {abtestingSortOptions} from './index.metadata';
import {ProgressBar, Btn, TabContainer, CondirmDialog, Loader} from '../uikit/index';
import MainLayout from '../layout/index';
import {SelectStyled} from './select.style';
import {ABTestingWrapper, FilterBarFirst, FilterBarSecond, ABListView, VariantPreviewBody} from './index.style';
import {NoData, ListFooter, ABTestingItem, StyledTable} from './index.style';
import {PaginationStyled} from '../dashboard/index.style';
import {post} from '../utils/request';
import AreaChart from './AreaChart';
import RealtimeChart from './realtimeChart';
import {filterTags, intFormatter} from '../utils/formatter';
import {StyledDropdown} from '../uikit/dropdown/styled';
import {getFromCache, cacheResource} from '../utils/cache'
import PreviewBox from '../widgets/previewBox';
import {SortByItemRenderer, Taglist} from "../widgets/index.style";
import {widgetSortOptions} from "../widgets/index.metadata";
import copy from 'copy-to-clipboard';

const FILTER_CACHE_KEY = 'abtesting_home_filters';

export default withRouter(withConsumer(class extends React.Component {

  constructor(props) {
    super(props);

    const cachedSettings = getFromCache(FILTER_CACHE_KEY) || {};
    this.state = {
      mode: 'grid',
      status: 'Published',
      page: 1,
      PAGE_SIZE: 6,
      sortBy: 'last_hour_impressions',
      sortDirection: 'desc',
      chartIndex: {},
      ...(cachedSettings || {}),
    };
    this.search = _.debounce(() => this.setState({data: null, page: 1}), 800);
  }

  componentDidMount() {
    // this.realtimeTimer = setInterval(() => {
    //   post('/api/queryABTesting', this.buildQuery()).then(newData => (this.setState({data: newData}))).catch(e => null);
    // }, 30000);
  }

  componentWillUnmount() {
    this.realtimeTimer && clearInterval(this.realtimeTimer);
  }

  buildQuery() {
    const {data} = this.state;
    const {keyword, sortBy, sortDirection, page, PAGE_SIZE, status, site} = this.state;
    return {
      limit: PAGE_SIZE, offset: (page - 1) * PAGE_SIZE, keyword, site, sortBy, sortDirection, status,
    };
  }

  cacheParams() {
    cacheResource(FILTER_CACHE_KEY, _.pick(this.state, ['site', 'page', 'PAGE_SIZE', 'status', 'mode', 'page', 'sortBy']))
  }

  render() {
    const {data, error, loading} = this.state;
    const {keyword, sortBy, sortDirection, page, PAGE_SIZE, chartIndex} = this.state;
    const {status, site, mode} = this.state;
    const {sites = [], abtestings, total, newInstanceId} = data || {};
    const {appState: {session}} = this.props;
    const {isAdmin, isOperator} = session || {};
    let quickTags = ['Published', 'Draft'];
    if (['mewatch', 'preprod_mewatch'].includes(site) || (session.sites.length === 1 && ['mewatch', 'preprod_mewatch'].includes(session.sites[0] || {}).key)) {
      quickTags = ['Published', 'TA_BOOST', 'Draft'];
    }
    return (
      <MainLayout activeItem="abtesting" breadcrumb={[{path: '/abtesting', label: 'A/B Test'}]}>
        <ABTestingWrapper>
          {
            !data && !error &&
            <ProgressBar
              withoutCache={true}
              fixcenter={true}
              url={`/api/queryABTesting`}
              params={this.buildQuery()}
              successHandler={data => this.setState({data: data})}
              errorHandler={e => this.setState({error: e.message})}/>
          }
          <FilterBarFirst>
            <div className="left">
              <h3>A/B Test</h3>
              <ul className="status-list">
                {
                  quickTags.map(s => (
                    <li
                      key={s}
                      className={`${s} ${s === status ? 'selected' : ''}`}
                      onClick={e => this.setState({status: s, data: null}, () => this.cacheParams())}>
                      {s}
                    </li>
                  ))
                }
              </ul>
            </div>
            <div className="right">
              {/*<div className="layout-mode">*/}
              {/*  <a className={mode === 'grid' ? 'selected' : ''} onClick={e => this.setState({mode: 'grid'})}>*/}
              {/*    <i className="fa fa-th"/>*/}
              {/*  </a>*/}
              {/*  <a className={mode === 'list' ? 'selected' : ''} onClick={e => this.setState({mode: 'list'})}>*/}
              {/*    <i className="fa fa-list"/>*/}
              {/*  </a>*/}
              {/*</div>*/}
              <Link className="create-btn" to={`/abtesting/create/${newInstanceId}`} onClick={e => {
                if(!isAdmin && !isOperator) {
                  e.preventDefault();
                }
              }}>
                <Btn disabled={!isAdmin && !isOperator} type="primary">Create A/B Test</Btn>
              </Link>
            </div>
          </FilterBarFirst>
          <FilterBarSecond>
            <div className="searchBox">
              <i className="fa fa-search"/>
              <input
                type="text"
                placeholder="Enter A/B Test ID, name, tag, creator to search ..."
                value={keyword}
                onChange={e => this.setState({keyword: e.target.value}, () => this.search())}/>
            </div>
            <SelectStyled
              selected={site || 'All'}
              defaultLabel={'All sites'}
              data={[
                {key: 'All', label: 'All sites'},
              ].concat(session.sites || [])}
              showGroupBatch={false}
              onChange={site => {
                this.setState({site: site, page: 1, data: null}, () => this.cacheParams())
              }}/>
            <SelectStyled
              selected={sortBy}
              defaultLabel="Sort By"
              showGroupBatch={false}
              data={abtestingSortOptions.map(d => {
                return {
                  ...d,
                  label: (d.key !== 'CreatedDateTime' || status !== 'Published') ? d.label : 'Published Time',
                  icon: `/img/icons/sort-${sortDirection || d.direction}.png`
                }
              })}
              itemRenderer={d => {
                return (
                  <SortByItemRenderer onClick={e => {
                    if(d.key === sortBy) {
                      this.setState({sortDirection: (sortDirection || d.direction) === 'desc' ? 'asc' : 'desc', data: null});
                    }
                  }}>
                    <span>{d.label} &nbsp;</span>
                    <img src={`/img/icons/sort-${sortDirection || d.direction}.png`} className={d.key === sortBy ? '' : 'hidden'}/>
                  </SortByItemRenderer>
                )
              }}
              onChange={sortBy => {
                let sortMeta = abtestingSortOptions.find(d => d.key === sortBy);
                this.setState({sortBy: sortBy, sortDirection: sortMeta.direction, page: 1, data: null}, () => this.cacheParams())
              }}/>
          </FilterBarSecond>
          <ABListView mode={mode}>
            {
              !!data && !abtestings.length &&
              <NoData>
                No {status} A/B Tests Found.
                {/*{*/}
                  {/*status !== 'Disabled' && status !== 'Draft' &&*/}
                  {/*<span><Link to={`/abtesting/create/${newInstanceId}`}>Create</Link>your first A/B test here.</span>*/}
                {/*}*/}
              </NoData>
            }
            {!!data && abtestings.length > 0 && abtestings.map((d, index) => (
              <ABTestingItem key={d.name + index} className="abtesting-item">
                <div className="abtest-item-header">
                  <div>
                    <Link className="title" to={`/abtesting/${d.id}`}>
                      <img className="site" src={`/img/property/${d.site || 'cna'}.png`}/>
                      <span className="name">
                        {d.name}
                        {!!d.servingDisabled && <span style={{color: 'red', fontSize: '11px'}}> (Disabled)</span>}
                      </span>
                    </Link>
                  </div>
                  <div className="actions">
                    <Btn size="small" type="link"><Link to={`/abtesting/edit/${d.id}`}><i
                      className="fa fa-edit"/></Link></Btn>
                    <StyledDropdown
                      closeOnClick={true}
                      useFixed={true}
                      fixedLeft={-150}
                      toggler={<i className="fa fa-ellipsis-v"/>}>
                      <div className={`menu-item`}>
                        <a onClick={e => copy(d.id)}><i className="fa fa-copy"/>Copy ID ({d.id})</a>
                      </div>
                      {
                        (isAdmin || isOperator) &&
                        <div className={`menu-item`}>
                          <a onClick={e => this.duplicateABTesting(d)}><i className="fa fa-copy"/>Duplicate</a>
                        </div>
                      }

                      {/* <div className="menu-item">
                        <a><i className="fa fa-stop-circle"/>Disable</a>
                      </div> */}
                      <div className={`menu-item`}>
                        <Link to={`/abtesting/edit/${d.id}`}><i className="fa fa-edit"/>{isAdmin ? 'Edit' : 'View Settings'}</Link>
                      </div>
                      {
                        (isAdmin || isOperator) &&
                        <div className={`menu-item`}>
                          <a onClick={e => this.deleteABTesting(d)}><i className="fa fa-trash"/>Delete</a>
                        </div>
                      }

                      <div className="menu-item">
                        <Link to={`/abtesting/history/${d.id}`}><i className="fa fa-clock-o"/>Revision History</Link>
                      </div>
                    </StyledDropdown>
                  </div>
                </div>
                <div className="abtest-item-body">
                  <div className="basic-info">
                    {/*<div className="attr abtesting-publish">*/}
                    {/*  <label>Publish Date</label>*/}
                    {/*  <span>{moment(d.CreatedDateTime).format('MMM Do YYYY HH:mm A')}</span>*/}
                    {/*</div>*/}
                    <div className="attr tags">
                      <label>Tags</label>
                      <Taglist className="tag-list">
                        {filterTags(d.tags, 'abtesting', isAdmin).map(t => {
                          return <span className={`tag ${t.split('::').join(' ')} `} key={t}>{t.split('::').pop()}</span>
                        })}
                        {!filterTags(d.tags, 'abtesting', isAdmin).length && '--'}
                      </Taglist>
                    </div>
                    <div className="attr variants">
                      <label>Variants</label>
                      <span className="variant-preview">
                        {
                          !session.isAdmin &&
                          <a onClick={e => this.showPreviewPopup(d)}>
                            {d.variants ? d.variants.length : '--'} <i className="fa fa-eye"/>
                          </a>
                        }
                        {
                          !!session.isAdmin &&
                          <div>{d.variants.length || '--'}</div>
                        }
                      </span>
                    </div>
                    <div className="attr abtesting-status">
                      <label>Created</label>
                      <span>
                        {moment(d.CreatedDateTime).fromNow()}
                        {d.UpdatedDateTime !== d.CreatedDateTime && <span>, updated {moment(d.UpdatedDateTime).fromNow()}</span>}
                      </span>
                    </div>
                    <div className="attr author">
                      <label>{d.status === 'Draft' ? 'Created' : 'Publish'} Time</label>
                      <span className="author">{moment().from(moment(d.CreatedDateTime))}
                        &nbsp;ago  by {d.creator}</span>
                    </div>
                  </div>
                  <div className="metrics">
                    <label>Traffic </label>
                    {this.renderABTestingBody(d, isAdmin)}
                  </div>
                </div>
              </ABTestingItem>
            ))}
            {
              total > 1 &&
              <ListFooter>
                <span className="blockIfMobile">
                  <span className="blockIfMobile">
                    There are <strong>{intFormatter(total)}</strong> A/B tests found.
                  </span>
                  <span className="blockIfMobile">
                    Show
                  <select
                    value={PAGE_SIZE}
                    onChange={e => this.setState({
                      data: null,
                      PAGE_SIZE: Number(e.target.value),
                      selected: [],
                      page: 1,
                      chartIndex: {},
                    }, () => this.cacheParams())}>
                  {[6, 12, 24, 30, 60, 120].map(d => {
                    return (
                      <option key={d} value={d}>{d}</option>
                    )
                  })}
                  </select>
                  per page
                  </span>
              </span>
                <PaginationStyled
                  onChange={p => this.setState({page: p, chartIndex: {}, data: null}, () => this.cacheParams())}
                  current={page}
                  pageSize={PAGE_SIZE}
                  total={total}
                  hideOnSinglePage={false}/>
              </ListFooter>
            }
          </ABListView>
        </ABTestingWrapper>
      </MainLayout>
    )
  }

  renderABTestingBody(abtesting, isAdmin) {
    const {chartIndex} = this.state;
    const {appState: {session}} = this.props;
    const {report = {}, status} = abtesting;

    if (status === 'Draft') {
      return <NoData>Data will be available once it is deployed</NoData>
    }

    const {abtestings} = this.state.data || {};
    const maxHourly = _.max(_.flattenDeep((abtestings || []).map(x => ((x.report || {}).last_24_hours || []).map(y => y.impressions || 0))))

    return (
      <TabContainer
        activeIndex={chartIndex[abtesting.id] || 0} tabTitles={['Last 24 Hours', 'Last 90 days']}
        onChange={index => {
          if(index === 1 && !report.dailyLoaded) {
            this.loadLifetimeReport(abtesting);
          }
          this.setState({chartIndex: {...chartIndex, [abtesting.id]: index}})
        }}>
        <RealtimeChart
          type="lastHour"
          showTimeout={!session.isExternal}
          data={report.last_24_hours}/>
        <div>
          <div style={{position: 'relative'}}>
            {!report.dailyLoaded && <Loader type="absolute"/>}
            <AreaChart
              showTimeout={!session.isExternal}
              key={abtesting.id + '-' + report.dailyLoaded}
              type="daily"
              data={report.daily}
              cfg={{showErrors: !!isAdmin}}/>
          </div>

        </div>
      </TabContainer>
    )
  }

  renderABTestingStatus(abtesting) {
    const {status, schedule, CreatedDateTime, report = {}} = abtesting || {};
    let {start = '', end = ''} = schedule || {};
    let {last_24_hours = [], daily = []} = report || {};

    let runningStatus = 'Not Running';
    if (status === 'Published') {
      const today = new Date();

      if (!start) {
        start = CreatedDateTime;
      }

      let hasImpressions = last_24_hours.length && daily.length;

      if (hasImpressions && moment(end).isBefore(today)) {
        runningStatus = 'Completed';
      } else if (moment(start).isAfter(today)) {
        runningStatus = 'Not Started';
      } else if (hasImpressions) {
        runningStatus = 'Running';
      }
    }

    return (
      <div className={`status ${runningStatus.replace(/\s/g, '')}`}>
        <i className="fa fa-clock-o"/>
        <span>{runningStatus}</span>
      </div>
    )
  }

  showPreviewPopup(abtesting) {
    const {variants, site} = abtesting;
    const dialogWidth = 1000;
    const prevItmWidget = variants.length > 0 ? (1000 / variants.length).toFixed() : dialogWidth;

    const {appState: {session}} = this.props;
    const siteOption = session.sites.find(s => s.key === abtesting.site);

    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      width: '1000px',
      hideCancel: true,
      hideOK: true,
      onConfirm: () => CondirmDialog.closeAll(),
      onCancel: () => CondirmDialog.closeAll(),
      dialogBody: (
        <VariantPreviewBody>
          <PreviewBox ids={variants.map(v => v.id)} site={site} siteOption={siteOption}/>
          {
            !variants.length &&
            <div className="no-data">No Data</div>
          }
        </VariantPreviewBody>
      )
    }

    ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById('global-confirm'));
  }

  duplicateABTesting(d) {
    this.setState({loading: true})
    return post('/api/duplicateABTesting', {id: d.id}).then(newABTesting => {
      this.setState({data: null, loading: false});
      this.props.history.push(`/abtesting/create/${newABTesting.id}?clone=y`);
    }).catch(e => {
      this.setState({loading: false});
      console.error(e);
    })
  }

  loadLifetimeReport(d) {
    this.setState({loading: true})
    return post('/api/queryABTestingLifetimeMetrics', {abtestingId: d.id}).then(ret => {
      const {data} = this.state;
      d.report = d.report || {};
      d.report.daily = ret.daily;
      d.report.dailyLoaded = true;
      this.setState({loading: false, data: data});
    }).catch(e => {
      this.setState({loading: false})
      console.error(e);
    })
  }

  deleteABTesting(d) {
    CondirmDialog.showConfirm(`Do you really want to delete A/B test ${d.name || d.id}?`, () => {
      this.setState({loading: true})
      return post('/api/deleteABTesting', {id: d.id}).then(results => {
        this.setState({data: null, loading: false});
      }).catch(e => {
        this.setState({loading: false});
        console.error(e);
      })
    })
  }
}))