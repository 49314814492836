import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';
import {
  BitterSweet1, BlueJeans, DarkBlue, DarkGray, DarkGray1, FontSizeBig, LightGray, LightGray1, MediumGray1, Mint,
  SilverDarkGray, SilverGray, SecondaryMediumBlue, BlueJeans1
} from "../app/StyleCommon";
import {SideMenuZIndex, BodyMaskZIndex, HeaderZIndex, DialogMaskZIndex} from "../app/StyleCommon";
import {Dropdown} from "../uikit/index";

const HeaderHeight = '0';
const HeaderHeightNumber = 50;
const SideMenuWidth = '200px';
const SideMenuWidthNumber = 200;

export const MainWrapper = styled.div`
  display: block;
  ${props => !props.open && css`
    ${LogoWrapper}{
      overflow: hidden;
      border-bottom: 1px solid rgba(255,255,255,.1);
      i {
        color: ${DarkBlue};
      }
      span {
        opacity: 0;
      }
    }
    ${SideMenuWrapper} {
      width: 50px !important;
      box-shadow: 1px 0 5px rgba(0,0,0,.1);
    }
    ${SideMenuItem} {
      padding: 0 10px;
      text-align: center;
      min-width: inherit;
      display: flex;
      align-items: center;
      i {
        margin-right: 0;
        text-align: center;
        margin-left: 5px;
      }
      span {
        opacity: 0;
      }
    }
    ${Welcome} {
      opacity: 0;
      max-height: 0px;
      padding: 0;
      padding-top: 15px;
      overflow: hidden;
    }
    ${HeaderSection} {
      padding-left: 50px !important;
    }
    ${BodySection} {
      margin-left: 60px;
    }
  `}
  ${props => !props.open && css`
     @media screen and (max-width: 667px) {
     ${LogoWrapper}{
       padding-left: 10px;
     }
      ${SideMenuWrapper} {
        width: 35px !important;
      }
      ${SideMenuItem} {
        padding: 0 5px;
      }
      ${HeaderSection} {
        padding-left: 20px;
      }
      ${BodySection} {
        margin-left: 40px;
      }
    }
  `}
`;


export const SideMenuWrapper = styled.div`
  z-index: ${SideMenuZIndex};
  background-color: white;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  min-height: 100%;
  padding-bottom: 250px;
  width: ${SideMenuWidth};
  transition: width .2s ease-in;
  background: rgb(21,28,35);
  background-image: linear-gradient(30deg,rgb(21,28,35),rgb(49, 91, 130));
  overflow: hidden;
  @media print {
    display: none;
  }
`;
export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #2e5478;
  //color: white;
  height: 40px;
  padding: 5px 0px 5px 15px;
  overflow: visible;
  border-right: 1px solid rgba(0,0,0,.05);
  border-bottom: 1px solid rgba(34, 53, 105, 0.1);
  background-color: white;
  background-image: linear-gradient(0deg, #fafbfc, #eeeded00);
  box-shadow: 0 1px 2px 0 rgba(60,64,67,.1), 0 1px 3px 1px rgba(60,64,67,.1);
  white-space: nowrap;
  i {
    font-size: 18px;
    //color: white;
  }
  a {
    cursor: pointer;
    display: inline-block;
    width: 32px;
    flex: none;
  }
  > span {
    transition: all .2s ease;
    opacity: 1;
    font-weight: bold;
  }
`;
export const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  color: white;
  height: 40px;
  padding: 5px 10px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  box-sizing: border-box;
  height: ${HeaderHeightNumber}px;
  padding-left: ${SideMenuWidthNumber}px;
  transition: all .2s ease-in-out;
  z-index: 1;
  background-image: linear-gradient(0deg, #fafbfc, #eeeded00);
  box-shadow: 0 1px 2px 0 rgba(60,64,67,.1), 0 1px 3px 1px rgba(60,64,67,.1);
  a {
    cursor: pointer;
    display: inline-block;
    width: 32px;
  }
  .right-side {
    display: flex;
    align-items: center;
  }
`;

export const UserMenuDropdown = styled(Dropdown)`
  .dropdown-toggle {
    width: 30px;
    display: flex;
    justify-content: flex-end;
    height: 50px;
    align-items: center;
    padding-right: 20px;
    img {
      border-radius: 50%;
      width: 24px;
      height: 24px;
    }
    i {
      position: absolute;
      top: 15px;
      right: 5px;
      color: #aaa;
      font-size: 16px;
    }
  }
  .menu {
    top: 50px;
    right: 0;
    left: auto;
    .user-info {
      padding: 15px 20px;
      border-bottom: 1px solid ${LightGray1};
      .name {
        display: block;
      }
      .email {
        font-size: 11px;
      }
      .desc {
        font-size: 12px;
        color: ${MediumGray1};
      }
    }
    .menu-item {
      a {
        text-decoration: none;
      }
      &:hover {
        a {
          color: ${BlueJeans1};
        }
      }
    }
  }
`

export const Welcome = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 10px 30px 10px;
  font-size: ${FontSizeBig};
  border-bottom: 1px solid rgba(255,255,255,.05);
  color: white;
  max-height: 250px;
  transition: all .2s ease-in-out;
  .name {
     max-width: 180px;
     white-space: normal;
     text-align: center;
     span {
      margin-right: 5px;
     }
  }
  .email {
    margin-top: 5px;
    font-size: 11px;
    color: ${MediumGray1};
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  img {
    //border-radius: 50%;
    height: 48px;
    width: 48px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
`

export const SideMenuList = styled.div`
  padding-top: 30px;
  //box-shadow: 1px 0 5px rgba(0,0,0,.2), 2px 0 15px rgba(0,0,0,.1);
  @media screen and (max-width: 667px) {

  }
`;

export const SideMenuItem = styled(Link)`
  width: 100%;
  padding: 15px 10px 15px 50px;
  text-align: left;
  box-sizing: border-box;
  font-size: 13px;
  color: white;
  display: block;
  text-decoration: none;
  cursor: pointer;
  transition: all .2s ease-in-out;
  position: relative;
  min-height: 45px;
  white-space: nowrap;
  i {
    font-size: 16px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    color: #fff;
    width: 16px;
    text-align: left;
    flex: none;
  }
  span {
    text-decoration: none;
    vertical-align: middle;
    color: #fff;
    font-weight: 600;
  }
  &&:hover {
    background: #0F5985;
  }
  ${props => props.selected && css`
    && {
      //background: rgb(32,40,51);
      span, i {
        font-weight: bold;
        letter-spacing: -0.2px;
        color: ${SecondaryMediumBlue};
      }
    }
  `}

  ${props => !props.selected && css`
    &:hover {
      background-color: ${LightGray1};
    }
  `}

  &.logout {
    position: absolute;
    bottom: 0;
    border-top: 1px solid rgba(255,255,255,.2);
  }
`;

export const BodyMask = styled.div`
  //background-color: rgba(0,0,0, 0);
  position: fixed;
  top: ${HeaderHeight};
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  transition: all .2s ease;
  ${props => props.visible && css`
    z-index: ${BodyMaskZIndex};
    background-color: rgba(0,0,0, .6);
  `}
`;

export const BodySection = styled.div`
  min-height: 500px;
  min-width: 400px;
  min-height: calc(100vh - ${HeaderHeightNumber}px);
  transition: margin-left .2s ease-in-out;
  position: relative;
  box-sizing: border-box;
  margin-top: ${HeaderHeightNumber}px;
  margin-left: ${SideMenuWidthNumber}px;
  @media screen and (max-width: 667px) {
    margin-left: ${SideMenuWidthNumber - 10}px;
    min-width: auto;
  }
`;

export const CommonErrorMsg = styled.div`
  padding: 10px 0;
  color: ${BitterSweet1};
  font-weight: bold;
`;

export const Breadcrumb = styled.ul`
  margin: 0;
  margin-left: 20px;
  height: ${HeaderHeightNumber}px;
  color:  white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  >li {
    white-space: nowrap;
    &.seperator {
      margin: auto 14px;
      color: ${DarkGray1};
    }
    >a {
      color: ${DarkGray1};
      text-decoration: none;
      width: auto;
      &:hover {
        color: ${SecondaryMediumBlue};
      }
    }
  }
`;