import React from 'react';
import styled, {css} from 'styled-components';
import {
  BitterSweet1,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass1, LightGray, LightGray1, MediumGray,
  MediumGray1, LightBlue
} from "../../app/StyleCommon";

import {WidgetWrapper} from './grid.style';

const RED = '#e85142';
export const iPrice_DFP_300x250_2 = styled(WidgetWrapper)`
  &&& {
    width: 300px;
    height: 250px;
    overflow: hidden;
    padding: 5 5x;
    box-sizing: border-box;
    .widget-title {
      color: #e85142;
      padding: 5px 10px;
      border-bottom: 1px solid ${LightGray1};
      background: ${LightGray};
      >span {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 15px;
        img {
          height: 18px;
        }
      }
    }
    .item-list {
      padding: 0;
      .item {
        padding: 5px 0;
        border-bottom: 1px solid ${LightGray1};
        height: 62px;
        .item-inner  {
          margin-left: 0;
          margin-right: 0;
          padding-left: 0;
          padding-right: 0;
          padding: 2px 0 2px 5px;
          &:hover {
            background: ${LightGray};
          }
          .thumbnail {
             display: none;
          }
          .brief {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .section {
              display: block;
            }
            .title {
              color: ${DarkGray1};
              font-weight: bold;
              line-height: 1.3em;
              text-decoration: none;
              overflow: visible;
              text-overflow: ellipsis;
              display: block;
              font-size: 12px;
              margin: 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;
            }
            .label-tag {
              display: none;
            }
            .cta-button {
              padding: 5px;
              margin-left: 5px;
              background: ${RED};
              color: white;
              text-decoration: none;
              font-size: 12px;
              border-radius: 5px;
              text-align: center;
              white-space: nowrap;
            }
            .expire {
              display: none;
            }
            .publish_date {
              display: none;
            }
            .store {
              font-size: 10px;
              color: ${MediumGray1};
              font-weight: bold;
            }
          }
        }
      }
    }
    .widget-footer {
      padding: 5px 10px;
      margin: 0;
      background: #fafbfc;
      >span {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      a {
        display: block;
        color: #e85142;
        text-decoration: none;
        text-align: left;
        font-size: 14px;
        &:hover {
          text-decoration: underline;
        }
      }
      .iprice-cpr {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 10px;
        color: ${MediumGray};
        img {
          height: 18px;
          display: inline;
          margin-left: 5px;iprice.png
        }
      }
    }
  }
`