import styled, {css} from 'styled-components';
import {BlueJeans, LightGray, LightGray1, LoadingZIndex} from "../../app/StyleCommon";

export const LoaderWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${LoadingZIndex};
  background: rgba(0,0,0,.2);
  ${props => props.type === 'absolute' && css`
    position: absolute;
    background: rgba(0,0,0,.05);
    ${Spinner} {
      height: 18px;
      width: 18px;
      border-width: 3px !important;
    }
  `}
  ${props => props.size === 'normal' && css`
    ${Spinner} {
      height: 36px;
      width: 36px;
      border-width: 6px !important;
    }
  `}
  ${props => props.transparent && css`
    background: transparent;
    pointer-events: none;
  `}
`

export const Spinner = styled.div`
    height: 36px;
    width: 36px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    animation: rotation 1.2s infinite linear;
    border-left: 6px solid ${LightGray1};
    border-right: 6px solid ${LightGray1};
    border-bottom: 6px solid ${LightGray1};
    border-top: 6px solid ${BlueJeans};
    border-radius: 100%;
    margin: 0 5px;
    ${props => props.type === 'small' && css`
      height: 16px;
      width: 16px;
      border-width: 3px;
    `}
    ${props => props.type === 'mini' && css`
      height: 12px;
      width: 12px;
      border-width: 2px;
    `}

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

`