import {duration, intFormatter, pct2Formatter, pctFormatter, uniquesFormatter} from "../utils/formatter";
import {metrics} from '../utils/metadata';
import * as d3 from 'd3';

export const dashboardCards = [
  {
    key: 'impressionsStat',
    label: 'Impressions',
    color: 'blue3',
    icon: 'fa fa-eye',
    formatter: intFormatter
  },
  {
    key: 'clicksStat',
    label: 'Clicks',
    color: 'green1',
    icon: 'fa fa-mouse-pointer',
    formatter: intFormatter
  },
  {
    key: 'ctrStat',
    label: 'CTR',
    color: 'blue4',
    icon: 'fa fa-hand-pointer-o',
    formatter: pct2Formatter
  },
];

export const timeseriesDimensions = [
  {key: 'creative_id', label: 'Creative', description: 'Compare performance metrics across all related creatives'},
  {key: 'widget_id', label: 'Widget', description: 'Compare performance metrics across all related widgets'},
]

export const tagTimeseriesDimensions = [
  {key: 'creative_id', label: 'Creative'},
  {key: 'email_campaign_id', label: 'EDM'},
  {key: 'campaign_id', label: 'Campaign'},
]
export const creativeTimeseriesDimensions = [
  {key: 'tag_id', label: 'Email Tag'},
  {key: 'campaign_id', label: 'Campaign'},
  {key: 'email_campaign_id', label: 'EDM'},
]

export const campaignStatus = [
  {key: 'READY', label: 'Ready'},
  {key: 'DELIVERING', label: 'Delivering'},
  {key: 'COMPLETED', label: 'Completed'},
  {key: 'PAUSED', label: 'Paused'},
]
export const campaignTypes = [
  {key: 'DIRECT', label: 'Direct'},
  {key: 'STANDARD', label: 'Standard'},
  {key: 'HOUSE', label: 'House'},
]