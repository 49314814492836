import React from 'react';
import styled, {css} from 'styled-components';

const SvgWrapper = styled.svg`
  path {
    color:#000000;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:2;marker:none;visibility:visible;display:inline;overflow:visible;enable-background:accumulate
  }
`

export default props => {
  return (
    <SvgWrapper xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" {...props}>
      <g transform="translate(0,-952.362)">
        <path
          d="m 43.75,966.94535 c -4.166667,0 -7.859,4.19375 -8.333333,8.33333 l -1.432292,12.49999 -6.901042,0 c -2.083333,0 -4.166666,2.08333 -4.166666,4.16666 L 18.75,1033.612 c 0,2.0833 2.083333,4.1667 4.166667,4.1667 l 54.166667,0 c 2.083333,0 4.166666,-2.0834 4.166666,-4.1667 l -4.166666,-41.66667 c 0,-2.08333 -2.083334,-4.16666 -4.166667,-4.16666 l -6.901042,0 -1.432291,-12.49999 C 64.109,971.1391 60.416667,966.94535 56.25,966.94535 z m 0,4.16666 12.5,0 c 2.083334,0 3.9295,2.09708 4.166667,4.16667 l 1.432292,12.49999 -23.697917,0 1.432291,-12.49999 c 0.237084,-2.06959 2.083334,-4.16667 4.166667,-4.16667 z"/>
      </g>
    </SvgWrapper>
  )
}
