import React, {Component, useEffect} from 'react';
import _ from 'lodash';
import * as echarts from 'echarts';
import {
  getMetricFormatter,
  intFormatter,
  moneyFormatterGenerator,
  pct0Formatter,
  pct2Formatter,
  pctFormatter,
  uniquesFormatter
} from "../utils/formatter";
import styled from "styled-components";
import {Wrapper} from "./index.style";
import Breakdown from "./breakdown";
import {metricMap, metrics} from "../utils/metadata";
import {MediumGray1} from "../app/StyleCommon";

export default class extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    let {data = [], cfg, currency, metric} = this.props;
    let metricMeta = metrics.find(d => d.key === metric);
    return (
      <ChartWrapper style={this.props.style}>
        <ul className="video-list">
          {data.map(d => {
            return (
              <li key={d.content_id} className="item">
                <img src={d.thumbnail || 'https://placehold.jp/150x90.png'}/>
                <div className="title">{d.title} ({d.content_id})</div>
                <div className="desc">
                  {metricMap[metric]}: {(metricMeta.formatter1 || metricMeta.formatter)(d[metric])}
                  {metric === 'ctr' && <span style={{color: MediumGray1}}>{` (impressions: ${intFormatter(d.impressions)}, clicks: ${intFormatter(d.clicks)})`}</span>}
                  {metric === 'vcr' && <span style={{color: MediumGray1}}>{` (video views: ${intFormatter(d.video_starts)}, video complete: ${intFormatter(d.video_completions)})`}</span>}
                  {metric === 'watch_time' && <span style={{color: MediumGray1}}>{` (uvv: ${intFormatter(d.uvv)})`}</span>}
                </div>

              </li>
            )
          })}
        </ul>
      </ChartWrapper>
    )
  }
}

export const ChartWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  .video-list {
    li {
      position: relative;
      padding-left: 120px;
      margin-bottom: 10px;
      min-height: 70px;
      img {
        position: absolute;
        left: 10px;
        width: 80px;
        height: 56px;
        top: 0px;
      }
      .title {
        font-weight: bold;
      }
    }
  }
`;