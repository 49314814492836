import {BlueJeans, DarkGray, Grass, LightGray1, MediumGray, MediumGray1} from "../app/StyleCommon";
import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import * as d3 from 'd3';
import {ReRenderHook} from '../utils/hooks';
import createTooltip from '../uikit/tooltip/index';
import {BriefWrapper} from './brief.style';

export default class extends React.Component {
  render() {
    return (
      <BriefWrapper className="svg-wrapper" innerRef={comp => this.wrapper = comp}>
        <ReRenderHook renderer={this.renderSVG.bind(this)}/>
      </BriefWrapper>
    )
  }

  renderSVG() {
    const {data, height: paramHeight} = this.props;
    var svgWidth = this.wrapper.clientWidth || 200;
    var svgHeight = paramHeight || svgWidth / 3;
    var margin = {top: 0, left: 0, bottom: 2, right: 0};
    var width = svgWidth - margin.left - margin.right;
    var height = svgHeight - margin.top - margin.bottom;

    d3.select(this.wrapper).selectAll("*").remove();
    const svg = d3.select(this.wrapper).append('svg').attr('width', svgWidth).attr('height', svgHeight);

    var x = d3.scaleLinear().range([0, width]);
    var y = d3.scaleLinear().range([1, height]);
    var y1 = d3.scaleLinear().range([1, height]);

    x.domain([0, data.length - 1]);
    y.domain([d3.max(data.map(d => d.impressions)), 0]);
    y1.domain([d3.max(data.map(d => d.clicks / d.impressions)), 0]);

    var line = d3.line()
      .x((d, index) => x(index))
      .y((d) => y1(d.clicks / d.impressions));

    svg.append('g').attr("class", "bargroup").selectAll('.bar')
      .data(data)
      .enter().append('rect')
      .attr('class', `bar`)
      .attr('x', (d, i) => x(i) - width * 0.5 / data.length)
      .attr('y', d => y(d.impressions))
      .attr('width', width * 0.5 / data.length)
      .attr('height', (d) => height - y(d.impressions))
      .attr('fill', BlueJeans)
      .attr('transform', 'translate(' + margin.left + ',' + (margin.top) + ')');

    svg.append('path')
      .attr("class", "sparkline")
      .attr('stroke', Grass)
      .attr('stroke-width', 2)
      .attr('fill', 'none')
      .attr('transform', 'translate(' + margin.left + ',' + (margin.top) + ')')
      .attr('d', line(data));
  }
}