import styled, {css} from 'styled-components';
import Select from '../uikit/selectbox/index';
import {BlueJeans, DarkGray, DarkGray1, LightGray, LightGray1, MediumGray, MediumGray1} from "../app/StyleCommon";

export const SelectStyled = styled(Select)`
  width: 100%;
  border-bottom: 1px solid rgba(255,255,255,.6);
  .toggler {
    background-color: inherit;
    color: rgba(255,255,255,.8);
    filter: none;
    border-radius: 4px;
    padding: 5px 10px;
    padding-right: 30px;
    font-size: 14px;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:hover {
      background-color: inherit;
    }
    .default-label {
      color: rgba(255,255,255,.5);
    }
    .prefix-label {
      font-weight: bold;
      margin-right: 10px;
    }
  }
  .menu {
    top: 30px;
    left: 0;
    right: 0;
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid ${LightGray1};
    border-top: none;
    label:hover {
      background-color: ${LightGray1};
    }
    label.selected {
      background-color: ${MediumGray};
      color: ${DarkGray1};
      font-weight: bold;
    }
  }
`
