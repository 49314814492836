const domains = [
  {domain: 'channelnewsasia.com', property: 'CNA', key: 'cna'},
  {domain: 'www.channelnewsasia.com', property: 'CNA', key: 'cna'},
  {domain: 'www.channelnewsasia.id', property: 'CNA Indonesia', key: 'cna'},
  {domain: '8world.com', property: '8World', key: '8world'},
  {domain: 'www.8world.com', property: '8World', key: '8world'},
  {domain: 'todayonline.com', property: 'TODAYonline', key: 'tdy'},
  {domain: 'www.todayonline.com', property: 'TODAYonline', key: 'tdy'},
  {domain: 'toggle.sg', property: 'Toggle', key: 'toggle'},
  {domain: 'www.toggle.sg', property: 'Toggle', key: 'toggle'},
  {domain: 'meclub.sg', property: 'MeClub', key: 'meclub'},
  {domain: 'www.8days.sg', property: '8 Days', key: '8days'},
  {domain: '8days.sg', property: '8 Days', key: '8days'},
  {domain: 'mediacorp.sg', property: 'Mediacorp Corporate Site', key: 'mcs'},
  {domain: 'melisten.sg', property: 'meLISTEN', key: 'melisten'},
  {domain: 'mewatch.sg', property: 'meWATCH', key: 'mewatch'},
]
export const getProperty = function (url) {
  return domains.find(d => (url || '').indexOf(d.domain) >= 0);
}