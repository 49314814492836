import * as d3 from 'd3';

var timer, trigger;

if ('ontouchstart' in document.documentElement) {
  d3.selectAll('body').style('cursor', 'pointer');
}

var tooltip = d3.selectAll('.ripple-tip');
if (tooltip.empty()) {
  tooltip = d3.select('body')
    .append('div')
    .attr("class", "ripple-tip");
}

export default function (config = {}) {
  const {targetSelector, tipCreator, directionFunc, positionFunc, minWidth, delay} = config;
  var cleanTimerAndRun = function (action, timeout, desc) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      tooltip.style('display', action === 'show' ? 'block' : 'none');
    }, timeout || 0);
  }
  tooltip.on('mouseover', () => {
    cleanTimerAndRun('show', delay || 0, 'mouseover tooltip');
  });
  tooltip.on('mouseout', () => {
    cleanTimerAndRun('hide', 100, 'mouseleave tooltip')
  });

  const show = function (d) {
    trigger = this;
    let direction = directionFunc || 'top';
    if (typeof directionFunc === 'function') {
      direction = directionFunc(d);
    }
    let tipContent = tipCreator;
    if (typeof tipCreator === 'function') {
      tipContent = tipCreator(d);
    }
    if(!tipContent) {
      return;
    }
    if (typeof tipContent === 'string') {
      tipContent = {body: tipContent};
    }
    if (minWidth) {
      tooltip.style("min-width", minWidth);
    }
    tooltip.html(`
      <div class="tip-inner">
        ${tipContent.title ? `<div class="tip-header">${tipContent.title}</div>` : ''}
        <div class="tip-body">${tipContent.body}</div>
      </div>
      <svg class="tip-arrow" width="14" height="14" viewBox="0 0 10 10"><rect x="1.5" y="1.5" transform="matrix(0.7071 0.7071 -0.7071 0.7071 5 -2.0711)" width="7.1" height="7.1"></rect></svg>
    `);

    if (typeof positionFunc === 'function') {
      var position = positionFunc(d);
      tooltip.style("left", position[0] + "px")
      tooltip.style("top", position[1] + "px");
    } else {
      let rect = this.getBoundingClientRect();
      var rX = window.pageXOffset + rect.left;
      var rY = window.pageYOffset + rect.top;
      if (direction === 'rightstart') {
        tooltip.style("left", (rX + rect.width) + "px")
        tooltip.style("top", rY + "px");
      }
      if (direction === 'lefttop') {
        tooltip.style("left", (rX - rect.width / 2) + "px")
        tooltip.style("top", rY + "px");
      }
      if (direction === 'left') {
        tooltip.style("left", rX + "px")
        tooltip.style("top", (rY + rect.height / 2) + "px");
      } else if (direction === 'right') {
        tooltip.style("left", (rX + rect.width) + "px")
        tooltip.style("top", (rY + rect.height / 2) + "px");
      } else if (direction === 'top') {
        tooltip.style("left", (rX + rect.width / 2) + "px")
        tooltip.style("top", rY + "px");
      } else if (direction === 'bottom') {
        tooltip.style("left", (rX + rect.width / 2) + "px")
        tooltip.style("top", (rY + rect.height) + "px");
      }
    }
    tooltip.classed('left', false);
    tooltip.classed('right', false);
    tooltip.classed('top', false);
    tooltip.classed('bottom', false);
    tooltip.classed('no-title', !tipContent.title);
    tooltip.classed(direction, true);
    cleanTimerAndRun('show', 0, 'mouseon trigger');
  };
  const hide = function () {
    cleanTimerAndRun('hide', 200, 'mouseleave trigger');
  }
  if (targetSelector !== 'dynamic' && targetSelector !== 'dynamic_responsive') {
    d3.selectAll(targetSelector).on('mouseover', show);
    d3.selectAll(targetSelector).on('mouseout', hide);
  }
  if (targetSelector === 'dynamic_responsive') {
    tooltip.classed('fit-for-mobile', true);
  } else {
    tooltip.classed('fit-for-mobile', false);
  }

  d3.select('body').on('touchstart click', () => {
    var target = d3.event.target;
    setTimeout(() => {
      if (trigger && !trigger.contains(target) && !tooltip.node().contains(target)) {
        cleanTimerAndRun('hide', 0, 'body click');
      }
    }, 50);
  });
  return {
    show,
    hide,
    onMouseOver(d) {
      return function (e) {
        show.call(e.currentTarget || e.target, d);
      }
    },

    onMouseOut(d) {
      return function (e) {
        hide.call(e.currentTarget || e.target, d);
      }
    }
  };
}