import React from 'react'
import ReactDOM from 'react-dom';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import moment from 'moment';
import * as d3 from 'd3';
import {withConsumer} from '../app/ApplicationContext';
import CondirmDialog from '../uikit/dialog/index';
import {Btn, TabContainer, Table, ProgressBar, Loader} from '../uikit/index';
import {SelectStyled} from './select.style';
import {PaginationStyled, ErrorBox} from './index.style';
import {ClickLogPanelWrapper} from './clickLogPanel.style';
import {BlueJeans} from "../app/StyleCommon";
import createTooltip from "../uikit/tooltip";
import ReactServer from "react-dom/server";
import {ErrorLogPanelWrapper} from "./errorLogPanel.style";

export default withConsumer(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {data: [], page: 1};
    this.tooltip = this.createTagTooltip();
    this.searchKeyword = _.debounce(this._searchKeyword.bind(this), 400);
  }

  _searchKeyword() {
    this.setState({data: [], page: 1, cutoff: null})
  }

  render() {

    const {type, id, period} = this.props;
    const {data, error, loading, page, keyword} = this.state;
    const dataFlatten = _.flatten(data);
    const lastLog = dataFlatten.slice(-1)[0] || {};
    const {appState: {session}} = this.props;
    const isAdmin = (session || {}).isAdmin;
    return (
      <ClickLogPanelWrapper>
        {
          (!data || page > data.length)  && !error &&
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/${type}/getClickLogs`}
            params={{period, id, keyword, cutoff: (lastLog.msg || {}).server_time}}
            successHandler={newPage => {
              this.setState({data: [...data, newPage], loading: false, error: false});
            }}
            errorHandler={e => this.setState({error: e.message})}/>
        }
        {
          !!loading && <Loader transparent={true} type="absolute"/>
        }
        {
          !!error && <ErrorBox>{error}</ErrorBox>
        }
        <div className="searchBox">
          <i className="fa fa-search"/>
          <input
            type="text"
            placeholder="Search by context ContentID, Url..."
            value={keyword}
            onChange={e => this.setState({keyword: e.target.value}, () => this.searchKeyword())}/>
        </div>
        <div className="table-wrapper">
          <Table
            columns={[
              {
                key: 'no', label: 'No', align: 'center',
                renderer: (d, i) => i + 1
              },
              {
                key: 'context', label: 'Context', align: 'left', renderer: (d) => {
                return (
                  <div>
                    <div className="content-view">
                      {!!d.parent.thumbnail && <img src={d.parent.thumbnail}/>}
                      <div className="title">
                        <span>
                          {
                            !!d.parent.title &&
                            <strong className="title-text">
                              {d.parent.title}
                              <span> [{d.parent.id || d.msg.content_id}]</span>
                            </strong>
                          }
                          {!d.parent.title && <a className="link">{d.msg.url}</a>}
                          &nbsp;<a href={d.msg.url} target="_blank" rel="noopener noreferrer"><i className="fa fa-external-link"/></a>
                        </span>
                        {!!d.parent.publish_date && <div className="publish_time"><strong>published</strong>: {moment(d.parent.publish_date).format('lll')}</div>}
                      </div>
                    </div>
                    <div className="meid">
                      <strong>meid</strong>: {d.msg.meid}
                    </div>
                    <div className="time">
                      <strong>{moment(d.msg.server_time).format('YYYY-MM-DD HH:mm:ss')}</strong>
                    </div>
                  </div>
                )
              }},
              {
                key: 'child', label: 'Click Item', align: 'left', renderer: (d) => {
                return (
                  <div>
                    <div className="content-view">
                      {!!d.child.thumbnail && <img src={d.child.thumbnail}/>}
                      <div className="title">
                        {!!d.child.title && <strong className="title-text">{d.child.title} [{d.msg.click_item.split('|').slice(0, 2).join(',')}] </strong>}
                        <div><a href={d.child.url} target="_blank" rel="noopener noreferrer">{d.child.url}.</a></div>
                        {!!d.child.publish_date && <div className="publish_time">Published: {moment(d.child.publish_date).format('lll')}</div>}
                      </div>
                      <div className="recs">
                        <strong>Recommendation Items:</strong>
                        {d.msg.recs.split(',').map(rec => {
                          let [recId, algo] = rec.split('|');
                          let meta = d.recMap[recId] || {};
                          return (
                            <a
                              key={recId}
                              className={d.msg.click_item.indexOf(recId) >= 0 ? 'current': ''}
                              onMouseOver={this.tooltip.onMouseOver(meta)}
                              onMouseLeave={this.tooltip.onMouseOut()}>
                              {recId}
                              {isAdmin && <span>,{algo}</span>}
                            </a>
                          )
                        })}
                      </div>
                    </div>
                    {
                      isAdmin &&
                      <div className="details">
                        <strong>Details:</strong> {JSON.stringify(d.msg)}
                      </div>
                    }

                  </div>
                )
              }},
            ]}
            rows={dataFlatten || []}
            noDataText={""}
          />
        </div>
        <span>{dataFlatten.length} loaded,  <Btn type="link" onClick={e => this.setState({page: page + 1})}>Load More</Btn></span>
        <div className="actions">
          <Btn type="reset" onClick={e => this.props.onCancel()}>Close</Btn>
        </div>
      </ClickLogPanelWrapper>
    );
  }
  createTagTooltip() {
    return createTooltip({
      targetSelector: 'dynamic',
      tipCreator: d => {
        return ReactServer.renderToString(
          <div style={{maxWidth: '380px'}}>
            <img src={d.thumbnail} style={{width: '80px', height: '45', float: 'left', margin: '5px 10px 10px 0'}}/>
            <div className="title">
              <strong className="title-text">[{d.id}] {d.title}</strong>
            </div>
            <div className="publish_time">Published: {moment(d.publish_time).format('lll')}</div>
            <a className="link" href={d.url} rel="noopener noreferrer">{d.url}</a>
          </div>
        )
      },
      directionFunc: 'bottom'
    });
  }
})
