import React, {Component} from 'react';
import ReactServer from 'react-dom/server';
import * as d3 from 'd3';
import _ from 'lodash';
import moment from 'moment';
import {ReRenderHook} from '../utils/hooks';
import createTooltip from '../uikit/tooltip/index';
import {AreaWrapper, TooltipWrapper} from './AreaChart.style';
import {InfoMessage} from '../uikit/errorbox/infoMessage';
import {BitterSweet} from '../app/StyleCommon';
import {intFormatter, pctFormatter, pct2Formatter, uniquesFormatter} from '../utils/formatter';

export default class extends Component {

  constructor(props) {
    super(props);
    let {type, data, cfg = {}} = this.props;
    const {showErrors = true} = cfg;

    let minStart = '2020-01-01';
    let maxEnd = moment().format('YYYY-MM-DD');
    data = (data || []).filter(d => d.date.substring(0, 10) >= minStart  && d.date.substring(0, 10) <= maxEnd);
    let processedData = null, hasData = false, totalImpressions = 0, totalUniques = 0, totalErrors = 0, dayCount = 1;
    if (data && data.length) {
      if (type === 'lastHour') {
        let cursor = moment().startOf('minute').add(-60, 'minute');
        processedData = _.range(0, 60).map(i => {
          cursor.add(1, 'minute');
          let datapoint = data.find(d => moment(d.date).startOf('minute').isSame(cursor));
          if (datapoint) {
            hasData = true;
            return datapoint;
          } else {
            return {impressions: 0, uniques: 0, clicks: 0, errors: 0};
          }
        });
      } else if (type === 'daily') {
        let dates = data.map(d => moment(d.date).format('YYYY-MM-DD'));
        let start = moment(_.min(dates)).startOf('day');
        let end = moment(_.max(dates)).startOf('day');
        dayCount = end.diff(start, 'day') + 1;
        let cursor = start;
        processedData = _.range(0, dayCount).map(i => {
          let ret;
          let datapoint = data.find(d => moment(d.date).startOf('day').isSame(cursor));
          if (datapoint) {
            hasData = true;
            ret = {...datapoint};
          } else {
            ret = {impressions: 0, uniques: 0, clicks: 0, errors: 0, date: cursor.format('YYYY-MM-DD')};
          }
          cursor.add(1, 'day');
          return ret;
        });
      }


      totalImpressions = _.sum(data.map(d => d.impressions || 0));
      totalUniques = totalUniques || _.sum(data.map(d => d.uniques || 0));
      totalErrors = showErrors ? _.sum(data.map(d => d.errors || 0)) : 0;
    }

    this.state = {
      type: type,
      dayCount: dayCount,
      data: processedData,
      hasData,
      totalImpressions,
      totalErrors,
      totalUniques: props.last_hour_uniques || totalUniques
    }
  }

  render() {
    const {type, showTimeout} = this.props;
    const {data, hasData, totalImpressions, totalUniques, totalErrors} = this.state;
    const periodStr = type === 'daily' ? ' last 90 days' : ' in last 1 hour'
    if (!hasData) {
      return <InfoMessage align="center" message={`No traffic ${periodStr}.`} style={{minHeight: '90px'}}/>
    }
    return (
      <AreaWrapper className="area-chart">
        {
          type === 'lastHour' &&
          <div className="hero">
            <span className="num blue">{intFormatter(totalImpressions)}</span> impressions&nbsp;
            {
              !!totalErrors && showTimeout &&
              <span>, <span className="num red">{uniquesFormatter(totalErrors)}</span> timeouts</span>
            }
            {/*&nbsp; <span className="num green">{intFormatter(totalUniques)}</span> uniques in last hour*/}
            &nbsp; in last hour
          </div>
        }
        {
          type === 'daily' &&
          <div className="hero">
            <span className="num blue">{intFormatter(totalImpressions)}</span> impressions
            {
              !!totalErrors && showTimeout &&
              <span>, <span className="num red">{uniquesFormatter(totalErrors)}</span> timeouts &nbsp;</span>
            }
          </div>
        }

        <svg ref='svg'></svg>
        <ReRenderHook renderer={this.renderChart.bind(this)}/>
      </AreaWrapper>
    )
  }

  renderChart() {
    const {maxHourly, maxDaily, showTimeout} = this.props;
    const {data, type, dayCount, totalErrors} = this.state;
    const isHourly = this.props.type === 'lastHour';
    let areaMetrics = ['impressions', 'errors'];
    if (!data.length) {
      return;
    }
    if (!totalErrors || !showTimeout) {
      areaMetrics = areaMetrics.filter(d => d !== 'errors');
    }

    const svgDom = this.refs.svg;
    let {width: svgWidth, height: svgHeight} = this.props;
    svgWidth = svgWidth || svgDom.parentNode.clientWidth || 380;
    svgHeight = svgHeight || 84.75; //svgWidth * .25;
    const margin = {top: 10, left: isHourly ? 12 : 30, bottom: 20, right: 30};
    const width = svgWidth - margin.left - margin.right;
    const height = svgHeight - margin.top - margin.bottom;

    const svg = d3.select(svgDom).attr('width', svgWidth).attr('height', svgHeight).attr('viewBox', `0 0 ${svgWidth} ${svgHeight}`);
    svg.selectAll('*').remove();

    const x = d3.scaleBand().range([0, width]).domain(data.map((d, i) => String(i)));

    const maxY = d3.max(_.flatten(data.map(d => areaMetrics.map(x => d[x] || 0)))) || 3;
    const contextMax = (isHourly ? maxHourly : maxDaily) || 0;

    const y = d3.scaleLinear().range([height, 0]).domain([0, Math.max(contextMax, maxY)]);
    const colorScale = d3.scaleOrdinal().domain(areaMetrics).range(["rgb(54, 175, 122)", '#d8ae2f', "rgb(87, 170, 249)"]);
    const focus = svg.append('g').attr('class', 'focus').attr("transform", `translate(${margin.left}, ${margin.top})`);

    var bandwidth = x.bandwidth();
    var lineFunc = metric => {
      return d3.line().curve(d3.curveMonotoneX).x((d, i) => x(String(i)) + bandwidth / 2).y(d => y(d[metric] || 0));
    }
    const areaFunc = metric => {
      return d3.area().curve(d3.curveMonotoneX).x((d, i) => x(String(i)) + bandwidth / 2).y0(y(0)).y1(d => y(d[metric] || 0));
    }
    const xAxis = d3.axisBottom(x).tickPadding(5);
    if (type === 'lastHour') {
      xAxis.tickFormat((d, i) => {
        if (i === data.length - 1) {
          return 'Now';
        } else if (i % 15 === 0) {
          return i - 60 + ' min';
        } else {
          return '';
        }
      });
    } else {
      let today = moment().format('YYYY-MM-DD');
      let last = moment(data.slice(-1).pop().date).format('YYYY-MM-DD');
      xAxis.tickFormat((d, i) => {
        if (i === data.length - 1) {
          return today === last.date ? 'Today' : last;
        } else if (i === 0) {
          return moment(data[0].date).format('YYYY-MM-DD')
        } else {
          return '';
        }
      });
    }

    focus.append("g")
      .attr("class", "axis axis--x")
      .attr("transform", `translate(0, ${height})`)
      .call(xAxis)
      .select(".domain").remove();

    focus.append("g")
      .attr("class", "axis axis--y")
      .call(d3.axisRight(y).ticks(2).tickFormat(uniquesFormatter).tickSize(width))
      .select(".domain").remove();

    let lastValid = _.findLastIndex(data, d => (d.impressions || d.errors || d.uniques));
    let stripData = data.slice(0, lastValid + 1);
    focus.selectAll(".area")
      .data(areaMetrics)
      .enter().append("g")
      .append("path")
      .attr("class", d => `area ${d}`)
      .attr("d", d => areaFunc(d)(stripData))
      .style("fill-opacity", '0.03')
      .style("fill", d => colorScale(d));

    focus.selectAll(".line")
      .data(areaMetrics)
      .enter().append("g")
      .append("path")
      .attr("class", d => `line ${d}`)
      .attr("d", d => lineFunc(d)(stripData))
      .style("fill", 'none')
      .style("stroke-opacity", '0.8')
      .style("stroke", d => colorScale(d));


    var zoomRect = svg.append('g').append("rect").attr("width", width).attr("height", height).attr('fill-opacity', '0')
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    var verticalLine = focus.append('line').attr('class', 'verticalLine').attr('stroke', '#ddd').attr('y2', height).attr('y1', 0).attr('stroke-dasharray', '5 5').attr('opacity', 0);
    var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    var getPointIndex = function () {
      let offsetX = d3.event.offsetX + (isFirefox ? margin.left : 0) - margin.left;
      let index = Math.floor(offsetX / bandwidth);
      return index;
      // return index - (type === 'daily' ? 1 : 3); //??? todo , why need such offset?
    }
    var tooltip = createTooltip({
      targetSelector: 'dynamic',
      tipCreator: d => {
        let index = getPointIndex();
        let dateFormat = type === 'lastHour' ? 'DD-MMM, HH:mm A' : 'YYYY MMM Do';
        let pointData = data[index] || {};
        return ReactServer.renderToString(
          <TooltipWrapper>
            {
              type === 'lastHour' &&
              <div className="time">{moment().add(-1, 'hour').add(index, 'minute').format(dateFormat)}</div>
            }
            {
              type === 'daily' &&
              <div className="time">{moment(pointData.date).format(dateFormat)}</div>
            }
            <div className="metricList">
              <div className="metric"><i className="fa fa-eye"/>Impressions: {intFormatter(pointData.impressions)}
              </div>
              <div className="metric"><i className="fa fa-hand-pointer-o"/>Clicks: {intFormatter(pointData.clicks)}
              </div>
              <div className="metric">
                <i className="fa fa-exclamation-triangle"/>Timeouts: {intFormatter(pointData.errors)}
              </div>
            </div>
          </TooltipWrapper>
        )
      },
      directionFunc: 'top',
      positionFunc: d => {
        let index = getPointIndex();
        let offsetX = d3.event.offsetX + (isFirefox ? margin.left : 0);
        let offsetY = d3.event.offsetY + (isFirefox ? (margin.top * 2) : 0);
        return [
          d3.event.pageX - offsetX + margin.left + x(String(index)) + bandwidth / 2,
          d3.event.pageY - offsetY + 20
        ]
      }
    });

    function handleMouseOver(d, i) {
      tooltip.show.call(this, d, i);
      let index = getPointIndex();
      verticalLine
        .attr('x1', x(String(index)) + bandwidth / 2)
        .attr('x2', x(String(index)) + bandwidth / 2)
        .attr('pointer-events', 'none')
        .attr('opacity', 1);
    };

    function handleMouseOut(d) {
      tooltip.hide.call(this, d);
    };

    zoomRect.on('mouseover', handleMouseOver).on('mousemove', handleMouseOver).on('mouseout', handleMouseOut)
  }
}