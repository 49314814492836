import styled, {css} from 'styled-components';
import {
  BlueJeans, BitterSweet, DarkGray, DarkGray1, Grass1, LightGray, LightGray1, MediumGray,
  MediumGray1, Sunflower1
} from "../app/StyleCommon";
import {SelectStyled} from './select.style';

export const WidgetCreateWrapper = styled.div`
  background-color: white;
  width: 100%;
  box-sizing: border-box;
  @media screen and (max-width: 667px) {
    padding: 10px 10px;
  }
  .actions {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid ${LightGray1};
    justify-content: flex-end;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    background: rgba(255,255,255,.95);
    //background: white;
    box-shadow: 0 -1px 02px 0 rgba(60,64,67,.1), 0 -1px -3px 1px rgba(60,64,67,.1);
    z-index: 2;
    button {
      margin: 0 !important;
      margin-left: 20px !important;
    }
  }
`;

export const SelectInlineStyled = styled(SelectStyled)`
  .toggler {
    border: none;
    display: inline;
    background: none;
    padding: 0 30px 0 0;
    margin-left: 5px;
    border-radius: 0;
    font-size: 12px;
    &:hover {
      background: none;
    }
    img {
      display: none;
    }
  }
  .menu {
    top: 25px;
    text-align: left;
    right: auto !important;
    min-width: 120px;
  }
`

export const StepContainer = styled.div`
  counter-reset: section;
  padding: 10px;
  @media screen and (max-width: 667px) {
    padding: 10px 0;
    .step-container {
      display: block !important;
      padding-top: 0px !important;
      padding-bottom: 35px !important;
      .left-side {
        display: block !important;
        .steps  {
          justify-content: center;
          padding: 10px 5px;
          background-color: ${LightGray};
          > li {
            width: 90px;
          }
        }
        .site-selector {
          margin: 15px 0;
          display: block;
          text-align: center;
          margin-left: 15px;
          .menu {
            right: 0 !important;
            left: auto !important;
          }
          &:before {
            content: 'Deployment site:';
          }
        }
      }
      .basic-info {
        text-align: center;
        i {
          display: none;
        }
        input {
          width: 90% !important;
          text-align: center;
          background-color: ${LightGray};
          border: 1px solid ${LightGray1} !important;
          padding: 5px 0;
          margin-top: 10px;
          &:focus {
            border: 1px solid ${BlueJeans} !important；
          }
        }
      }
    }
  }
  .step-container {
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0 10px 0;
    border-bottom: 1px solid ${LightGray1};
    padding-bottom: 15px;
    .left-side {
      display:flex;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-left: 30px;
      }
    }
    .basic-info {
      i {
        margin: 0 10px;
        color: ${BlueJeans};
        font-size: 14px;
      }
      input {
        width: 200px;
        border: none;
        outline: none;
        font-size: 14px;
        padding: 2px 10px;
        max-width: 300px;
        border-bottom: 1px solid ${MediumGray};
        &:focus {
          border-bottom: 1px solid ${BlueJeans};
        }
      }
    }
    ul.steps {
      align-self: center;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      ${props => !props.clickNavigatable && css`
        li {
          pointer-events: none;
        }
      `}
      li {
        position: relative;
        margin-top: 0
        margin-left: 20px;
        font-size: 14px;
        cursor: pointer;
        color: ${DarkGray};
        display: flex;
        align-items: center;
        padding-left: 20px;
        font-weight: bold;
        &.selected {
          color: ${BlueJeans};
          &:before, &:after {
            background: ${BlueJeans};
          }
        }
        &:not(:last-child):after {
          content: '';
          display: block;
          width: 100px;
          height: 1px;
          background: ${MediumGray1};
          margin: auto 10px;
        }
        &:before {
          counter-increment: section;
          content: counter(section);
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          font-size: 12px;
          font-weight: bold;
          transform: translate(-50%, -50%);
          width: 22px;
          height: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: ${MediumGray1};
          color: white;
          border-radius: 50%;
          border: 5px solid white;
        }
      }    
    }
  }
`;

export const Step = styled.div`
  position: relative;
  display: none;
  .row {
    display: flex;
    justify-content: space-between;
    padding: 10px 25px;
  }
  .col {
    &:not(:first-child) {
      padding-left: 50px;
    }
    &.standalone {
      padding: 10px 20px;
    }
    > label {
      display: block;
      font-weight: bold;
      color: ${DarkGray};
      margin: 10px 0;
    }
    input[type=text] {
      width: 100%;
      font-size: 14px;
      padding: 8px 10px;
      box-sizing: border-box;
      border-radius: 5px;
      border: 1px solid ${MediumGray};
      outline: none;
      //background: ${LightGray};
      &:focus {
        border: 1px solid ${BlueJeans};
        background: #fafbfc;
      }
    }
    .select-box {
      width: 100%;
      .toggler {
        width: 100%;
        border: 1px solid ${MediumGray}
        background: white;
      }
      .menu {
        top: 36px;
      }
    }
    &.col-1-1 {
      width: 50%;
    }
    &.col-1-3 {
      width: 33.33%;
      width: calc(33.33% - 50px);
    }
    &.col-2-3 {
      width: 66.66%;
    }
  }
  ${props => props.selected && css`
    display: block;
    &:before {
      background: ${BlueJeans};
    }
  `}
`;

export const PromptBody = styled.div`
  margin: 20px 0;
  i {
    color: ${Sunflower1};
    margin-right: 10px;
  }
`;

export const PublishDialogWrapper = styled.div`
  svg {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 5px;
  }
  .res-success-icon {
    border: 3px solid ${Grass1};
    path {
      fill: ${Grass1};
    }
  }
  .res-failure-icon {
    border: 3px solid ${BitterSweet};
    path {
      fill: ${BitterSweet};
    }
  }
`;