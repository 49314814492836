import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import {DialogWrapper} from "./index.style";
import {Btn} from '../btn/index';
import Loader from '../Loader/index';

class Dialog extends React.Component {
  static defaultProps = {
    active: true,
    onConfirm: _ => undefined,
    onCancel: _ => undefined,
  }

  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.documentClickHandler = this.handleOutsideClick.bind(this);
    this.documentKeypressHandler = this.handleEscKeypress.bind(this);
  }

  componentDidMount() {
    // setTimeout(() => {
    //   let wrapper = this.wrapperRef.current;
    //   if(wrapper) {
    //     wrapper.classList.add('dialog--open');
    //   }
    // })
    if (this.props.backgroundClose) {
      document.addEventListener('click', this.documentClickHandler, true); //use capture
      document.addEventListener('keyPress', this.documentKeypressHandler);
    }
  }

  componentWillUnmount() {
    if (this.props.backgroundClose) {
      document.removeEventListener('click', this.documentClickHandler, true);
      document.removeEventListener('keyPress', this.documentKeypressHandler);
    }
  }

  handleClose(callback) {
    // let wrapper = this.wrapperRef.current;
    //   if(wrapper) {
    //     wrapper.classList.remove('dialog--open');
    //   }
    // setTimeout(() => {
    //   if (typeof callback === 'function') {
    //     callback();
    //   } else {
    //     this.props.onCancel && this.props.onCancel();
    //   }
    // }, 100);
    if (typeof callback === 'function') {
      callback();
    } else {
      this.props.onCancel && this.props.onCancel();
    }
  }

  render() {
    const {
      confirmEnabled, children, title, dialogBody, active,
      onConfirm, onCancel, onIgnore, ignoreLabel, cancelLabel, okLabel, confirmInfo,
      loading, closeIconTemplate
    } = this.props;
    const {hideCancel, hideOK} = this.props;
    const wrapperProps = _.pick(this.props, ['type', 'fullScreen', 'width']);
    return (
      <DialogWrapper {...wrapperProps} title="" ref={this.wrapperRef}>
        {children}
        <div ref="dialog" className="dialog">
          {
            loading && <Loader type="absolute"/>
          }
          <div className="close" onClick={e => this.handleClose()}>
            {closeIconTemplate || <i className="fa fa-times"/>}
          </div>
          <div className="title" ref="title">{title}</div>
          {dialogBody}
          <div className="buttons">
            {
              !hideCancel &&
              <Btn type="reset" onClick={e => this.handleClose(onCancel)}>{cancelLabel || 'Cancel'}</Btn>
            }

            {
              !!ignoreLabel && !!onIgnore &&
              <Btn type="reset" onClick={e => this.handleClose(onIgnore)}>{ignoreLabel}</Btn>
            }
            {
              !hideOK &&
              <Btn type="primary" onClick={e => this.handleClose(onConfirm)}>{okLabel || 'OK'}</Btn>
            }

          </div>
        </div>
      </DialogWrapper>
    )
  }

  handleOutsideClick(e) {
    if (!this.props.backgroundClose) {
      return;
    }
    var dialog = this.refs.dialog;
    if (!dialog) {
      return;
    }
    if (!dialog.contains(e.target)) {
      this.handleClose(e);
    }
  }

  handleEscKeypress(e) {
    if (e.keyCode === 27) {
      if (!this.props.backgroundClose) {
        return;
      }
      this.handleClose(e);
    }
  }
}

Dialog.closeAll = function (containerID) {
  containerID = containerID || 'global-confirm'
  ReactDOM.render(<div/>, document.getElementById(containerID));

}

Dialog.showConfirm = function (title, callback, cancleCallback) {
  const confirmInfo = {
    title: <div style={{
      display: 'block',
      textAlign: 'center',
      margin: '20px 20px 10px 20px',
      lineHeight: '30px'
    }}>{title}</div>,
    onConfirm: () => {
      ReactDOM.render(<div/>, document.getElementById('global-confirm'));
      callback();
    },
    onCancel: () => {
      ReactDOM.render(<div/>, document.getElementById('global-confirm'));
      cancleCallback && cancleCallback();
    }
  };
  ReactDOM.render(
    <Dialog fullScreen={true} width={'500px'} {...confirmInfo} />, document.getElementById('global-confirm')
  );
}

export default Dialog;