import React from 'react';
import {SwitchBoxWrapper} from "./index.style";

export default class extends React.Component {
  static defaultProps = {
    checked: false,
    onChange: _ => console.log(_)
  }

  render() {
    return (
      <SwitchBoxWrapper {...this.props}>
        <input
          type="checkbox"
          tabIndex="-1"
          checked={this.props.checked}
          onChange={e => {
            this.props.onSwitchChange(!this.props.checked);
          }}/>
        <span></span>
      </SwitchBoxWrapper>
    )
  }
}