import React from 'react';
import {ConfirmWrapper} from "./index.style";
import {Btn} from '../btn/index';
import {elementInViewport} from '../../utils/misc';
import {findDOMNode} from 'react-dom';

export default class extends React.Component {
  static defaultProps = {
    active: true,
    confirmEnabled: true,
    onConfirm: _ => undefined,
    onCancel: _ => undefined,
  }

  componentDidMount() {
    this.checkVisible();
  }
  componentDidUpdate() {
    this.checkVisible();
  }

  checkVisible() {
    const wrapper = findDOMNode(this);
    const dialog = wrapper.querySelector('.confirm-dialog');
    if(dialog && !elementInViewport(dialog)) {
      dialog.style.position = 'fixed';
      dialog.style.top = '50%';
      dialog.style.transform = 'translate(-50%, -50px)';
    } else {
      dialog.style.position = 'absolute';
      dialog.style.top = '0';
      dialog.style.transform = 'translate(-50%, 120px)';
    }
  }

  render() {
    const {confirmEnabled, children, active, onConfirm, onCancel} = this.props;
    return (
      <ConfirmWrapper {...this.props}>
        {children}
        <div ref="dialog" className="confirm-dialog">
          <div className="title" ref="title">Do you want to apply change?</div>
          <div className="buttons">
            <Btn type="success" disabled={!confirmEnabled} onClick={e => onConfirm()}>OK</Btn>
            <Btn type="danger" onClick={e => onCancel()}>Cancel</Btn>
          </div>
        </div>
      </ConfirmWrapper>
    )
  }
}