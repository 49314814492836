import React from 'react';
import styled, {css} from 'styled-components';

const SvgWrapper = styled.svg`
  path {
  }

`

export default props => {
  return (
    <SvgWrapper xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512" {...props}>
     <g>
	<g>
		<g>
			<path d="M193.617,303.697l117.086,31.394l143.225,38.4c21.558,5.794,43.655-7.006,49.448-28.564
				c5.794-21.558-7.006-43.655-28.564-49.448l-143.225-38.4l-74.24-243.065L205.204,0v223.124l-133.794-35.84l-25.061-62.518
				L7.276,114.257v139.453l43.25,11.587L193.617,303.697z"/>
			<rect y="458.105" width="512" height="53.895"/>
		</g>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
    </SvgWrapper>
  )
}
