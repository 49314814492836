import styled, {css} from 'styled-components';
import {Dropdown} from "../uikit/index";
import Pagination from 'rc-pagination';
import {
  BitterSweet1,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass, LightGray, LightGray1, MediumGray, MediumGray1,
  PrimaryDarkBlue
} from "../app/StyleCommon";

export const WidgetHistoryWrapper = styled.div`
  background-color: white;
  padding: 10px 20px;
  min-height: calc(100vh - 50px);
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  @media screen and (max-width: 667px) {
    padding: 20px 10px;
  }
`;


export const RevisionList = styled.div`
  border-top: 1px solid ${LightGray1};
  > ul > li {
    //border-left: 1px solid ${MediumGray1};
    padding: 20px 10px 10px 20px;
    min-height: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    @media screen and (max-width: 667px) {
      padding: 20px 0px;
      .time {
        width: 80px !important;
        margin-right: 10px !important;
        padding-left: 5px !important;
        font-size: 12px;
        i {
          display: none;
        }
      }
      .basic {
        padding: 0 !important;
        align-items: flex-start !important;
        .brief {
          display: block;
          margin-left: 0 !important;
        }
      }
      button {
        margin-left: 10px !important;
      }
    }
    &:after {
      display: block;
      content: '';
      height: 0px;
      border-bottom: 1px dashed ${MediumGray}
      position: absolute;
      left: 250px;
      right: 20px;
      bottom: 10px;
      @media screen and (max-width: 667px) {
        left: 90px;
      }
    }
    &:before {
      content: '';
      width: 0px;
      border-left: 1px dashed ${MediumGray};
      position: absolute;
      top: 45px;
      left: 100px;
      bottom: -15px;
      @media screen and (max-width: 667px) {
        left: 50px;
      }
    }
    &.day-break:before {
      top: 60px;
      bottom: -20;
    }
    .time {
      width: 170px;
      text-align: center
      background-color: white;
      margin-right: 60px;
      font-weight: bold;
      white-space: nowrap;
      position: relative;
      flex: none;
      i {
        color: ${MediumGray1};
      }
      &.day-break-time {
        border-radius: 5px;
        // background-color: ${LightGray1};
        padding: 5px;
        padding-left: 50px;
        text-align: left;
        box-sizing: border-box;
        .time1 {
          font-size: 12px;
          font-weight: normal;
        }
        i {
          position: absolute;
          left: 20px;
          top: 5px;
          color: ${DarkGray};
        }
      }
      &.successive {
        font-weight: normal;
      }
    }
    .info {
      font-size: 12px;
      width: 90%;
      overflow-x:auto;
      .basic {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        cursor: pointer;
        transition: all .3s ease;
        &:hover {
          background-color: ${LightGray};
        }
        button {
          margin-left: 30px;
        }
        strong {
          font-weight: normal
          color: ${DarkGray1};
        }
        .brief {
          margin-left: 10px;
          .remove {
            color: ${BitterSweet1};
          }
          .add {
            color: ${Grass};
          }
          .replace {
            color: ${BlueJeans};
          }
        }
      }
    }
    pre {
      //max-width: 90%;
    }
    code {
      font-size: 12px;
    }

  }
`

export const DetailView = styled.div`
  cursor: text;
  width: 100%;
  padding: 20px 10px;
  box-sizing: border-box;
  .jsondiffpatch-delta {
    font-family: 'Bitstream Vera Sans Mono', 'DejaVu Sans Mono', Monaco, Courier, monospace;
    font-size: 11px;
    margin: 0;
    padding: 0 0 0 12px;
    display: inline-block;
  }
  .jsondiffpatch-delta pre {
    font-family: 'Bitstream Vera Sans Mono', 'DejaVu Sans Mono', Monaco, Courier, monospace;
    font-size: 11px;
    margin: 0;
    padding: 0;
    display: inline-block;
  }
  ul.jsondiffpatch-delta {
    list-style-type: none;
    padding: 0 0 0 20px;
    margin: 0;
  }
  .jsondiffpatch-delta ul {
    list-style-type: none;
    padding: 0 0 0 10px;
    margin: 0;
  }
  .jsondiffpatch-added .jsondiffpatch-property-name,
  .jsondiffpatch-added .jsondiffpatch-value pre,
  .jsondiffpatch-modified .jsondiffpatch-right-value pre,
  .jsondiffpatch-textdiff-added {
    background: #bbffbb;
  }
  .jsondiffpatch-deleted .jsondiffpatch-property-name,
  .jsondiffpatch-deleted pre,
  .jsondiffpatch-modified .jsondiffpatch-left-value pre,
  .jsondiffpatch-textdiff-deleted {
    background: #ffbbbb;
    text-decoration: line-through;
  }
  .jsondiffpatch-unchanged,
  .jsondiffpatch-movedestination {
    color: gray;
  }
  .jsondiffpatch-unchanged {
    display: none;
  }
  .jsondiffpatch-unchanged,
  .jsondiffpatch-movedestination > .jsondiffpatch-value {
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    overflow-y: hidden;
  }
  .jsondiffpatch-unchanged-showing .jsondiffpatch-unchanged,
  .jsondiffpatch-unchanged-showing .jsondiffpatch-movedestination > .jsondiffpatch-value {
    max-height: 100px;
  }
  .jsondiffpatch-unchanged-hidden .jsondiffpatch-unchanged,
  .jsondiffpatch-unchanged-hidden .jsondiffpatch-movedestination > .jsondiffpatch-value {
    max-height: 0;
  }
  .jsondiffpatch-unchanged-hiding .jsondiffpatch-movedestination > .jsondiffpatch-value,
  .jsondiffpatch-unchanged-hidden .jsondiffpatch-movedestination > .jsondiffpatch-value {
    display: block;
  }
  .jsondiffpatch-unchanged-visible .jsondiffpatch-unchanged,
  .jsondiffpatch-unchanged-visible .jsondiffpatch-movedestination > .jsondiffpatch-value {
    max-height: 100px;
  }
  .jsondiffpatch-unchanged-hiding .jsondiffpatch-unchanged,
  .jsondiffpatch-unchanged-hiding .jsondiffpatch-movedestination > .jsondiffpatch-value {
    max-height: 0;
  }
  .jsondiffpatch-unchanged-showing .jsondiffpatch-arrow,
  .jsondiffpatch-unchanged-hiding .jsondiffpatch-arrow {
    display: none;
  }
  .jsondiffpatch-value {
    display: inline-block;
  }
  .jsondiffpatch-property-name {
    display: inline-block;
    padding-right: 5px;
    vertical-align: top;
  }
  .jsondiffpatch-property-name:after {
    content: ': ';
  }
  .jsondiffpatch-child-node-type-array > .jsondiffpatch-property-name:after {
    content: ': [';
  }
  .jsondiffpatch-child-node-type-array:after {
    content: '],';
  }
  div.jsondiffpatch-child-node-type-array:before {
    content: '[';
  }
  div.jsondiffpatch-child-node-type-array:after {
    content: ']';
  }
  .jsondiffpatch-child-node-type-object > .jsondiffpatch-property-name:after {
    content: ': {';
  }
  .jsondiffpatch-child-node-type-object:after {
    content: '},';
  }
  div.jsondiffpatch-child-node-type-object:before {
    content: '{';
  }
  div.jsondiffpatch-child-node-type-object:after {
    content: '}';
  }
  .jsondiffpatch-value pre:after {
    content: ',';
  }
  li:last-child > .jsondiffpatch-value pre:after,
  .jsondiffpatch-modified > .jsondiffpatch-left-value pre:after {
    content: '';
  }
  .jsondiffpatch-modified .jsondiffpatch-value {
    display: inline-block;
  }
  .jsondiffpatch-modified .jsondiffpatch-right-value {
    margin-left: 5px;
  }
  .jsondiffpatch-moved .jsondiffpatch-value {
    display: none;
  }
  .jsondiffpatch-moved .jsondiffpatch-moved-destination {
    display: inline-block;
    background: #ffffbb;
    color: #888;
  }
  .jsondiffpatch-moved .jsondiffpatch-moved-destination:before {
    content: ' => ';
  }
  ul.jsondiffpatch-textdiff {
    padding: 0;
  }
  .jsondiffpatch-textdiff-location {
    color: #bbb;
    display: inline-block;
    min-width: 60px;
  }
  .jsondiffpatch-textdiff-line {
    display: inline-block;
  }
  .jsondiffpatch-textdiff-line-number:after {
    content: ',';
  }
  .jsondiffpatch-error {
    background: red;
    color: white;
    font-weight: bold;
  }
`
export const NoData = styled.div`
  min-height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${DarkGray};
  a {
    display: inline-block;
    margin: 0 10px;
  }
`
export const ShowMore = styled.div`
  margin: 40px 10px 20px 60px;
  i {
    margin-left: 5px;
  }
  a  {
    cursor: pointer;
    color: ${BlueJeans1};
  }
`