import styled from 'styled-components';

export const MeWatchButtonGroupWrapper = styled.div`
  & {
    button, .arrow {
      position: absolute;
      top: 20%;
      background: transparent;
      border: 0;
      padding: 0 !important;
      outline: none;
      z-index: 0;
      cursor: pointer;
      > div {
        display:block;
        margin-left: 10px;
        width: 15px;
        height: 15px;
        transform: rotate(45deg);
        box-sizing: border-box;
      }
      .arrow-left-pointer {
        border-left: 1.5px solid #fff;
        border-bottom: 1.5px solid #fff;
      }
      .arrow-right-pointer {
        border-right: 1.5px solid #fff;
        border-top: 1.5px solid #fff;
      }
      &:before {
        content: none;
      }
      &:hover {
        .arrow-left-pointer {
          border-left: 1.5px solid #D83BB3;
          border-bottom: 1.5px solid #D83BB3;
        }
        .arrow-right-pointer {
          border-right: 1.5px solid #D83BB3;
          border-top: 1.5px solid #D83BB3;
        }
      }
    }
  }
`;