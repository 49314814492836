import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import {post} from '../../utils/request';
import Highlighter from 'react-highlight-words';
import {
  FilterBoxWrapper,
  Actions,
  Btn,
  SelectWrapper,
  PeriodWrapper,
  NodeSettingWrapper,
  StyledPeriodSelect,
  Showmore
} from './index.style';
import {StyledSelectBox, ContentOptionWrapper} from './index.style';
import Hint from '../hint/index';
import createTooltip from '../tooltip/index';
import {uniquesFormatter, sinceThen} from '../../utils/formatter';
import {datePeriodWindows, getPeriodGroups} from '../../utils/misc';
import {
  BlockWrapper, GlobalControls, BlockControls, BlockLeftControls,
  BlockScopeMenuHeader, ExtraControls, NodeWrapper, NodeConnector
} from './index.style';
import {behaviourTypeMap} from './btAliasMap.json';

const Select = StyledSelectBox;

// const periodGroups = getPeriodGroups();
// const periodGroups = _.cloneDeep(getPeriodGroups()).concat([
//   {
//     key: 'forever',
//     label: 'Others',
//     data: [
//       {key: 'forever', label: 'Entire lifetime'}
//     ]
//   },
// ]);
const periodGroups = [];

const rangeOperatorOptions = [
  {key: '$in', value: '$in', label: 'is'},
  {key: '$nin', value: '$nin', label: 'is not'}
];

const stringOperatorOptions = [
  {key: '$match', value: '$match', label: 'match'},
  {key: '$nmatch', value: '$nmatch', label: 'not match'}
];

const booleanOperatorOptions = [
  {key: '$is', value: '$is', label: 'is'},
  {key: '$isnot', value: '$isnot', label: 'is not'}
];

const logicalOperatorOptions = [
  {key: '$and', value: '$and', label: 'AND'},
  {key: '$or', value: '$or', label: 'OR'},
];

const numberOperatorOptions = [
  {key: '$eq', value: '$eq', label: 'equals', shortLabel: '='},
  {key: '$neq', value: '$neq', label: 'does not equal', shortLabel: '≠'},
  {key: '$gt', value: '$gt', label: 'is greater than', shortLabel: '>'},
  {key: '$lt', value: '$lt', label: 'is less than', shortLabel: '<'},
  {key: '$gte', value: '$gte', label: 'is greater than or equal to', shortLabel: '≥'},
  {key: '$lte', value: '$lte', label: 'is less than or equal to', shortLabel: '≤'}
];

export default class extends React.Component {
  static defaultProps = {
    dimensions: [],
    onCancel: () => null,
    onFiltersChange: newFilters => null,
  }

  constructor(props) {
    super(props);
    let {periodStr} = props;
    periodStr = periodStr || props.defaultPeriod || 'last30days';
    this.state = {filters: {op: '$and', items: [], periodStr, ...(props.filters || {})}, extraSettingState: {}};
    if (!this.state.filters.items.length) {
      this.state.filters.items.push({
        op: '$and', items: [
          {key: null, op: '$in', value: []}
        ]
      });
    }
    this.buildDimensionGroup(props);
    this.tooltip = createTooltip('dynamic', not => `Choose Scope`, 'left', null, '180px');
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filterRecency) {
      this.setState({filters: {...this.state.filters, periodStr: nextProps.filterRecency}})
    }
    this.buildDimensionGroup(nextProps);
  }

  render() {
    let {extraBtnRenderer, autoApply = false, formStyle, hideGlobalPeriod, hasError, readOnly} = this.props;
    let {filters, filters: {op = '$and', items = [], periodStr}, applied} = this.state;
    let {filters: {items: propItems = []} = {}} = this.props;
    return (
      <FilterBoxWrapper className="filter-box-wrapper" hasError={hasError} formStyle={formStyle} supportScope={true}
                        readOnly={readOnly}>
        <div className="body">
          {
            !!items.length && !hideGlobalPeriod &&
            <PeriodWrapper className="global-period-settings">
              <label>
                <span>Recency</span>
                <Hint pos={'right'} width={350} content={
                  <div>
                    <h3>What is Recency?</h3>
                    <p>The period of time which a user matches an audience definition. For example, setting Recency to
                      ’30 days’ would mean that the segment would comprise of users who had exhibited a particular
                      behaviour in the last 30 days. In this case, it explicitly excludes users who exhibited the
                      behaviour 31 or more days ago but did not exhibit the behaviour in the last 30 days.</p>
                  </div>
                }/>:
              </label>
              <StyledPeriodSelect
                selected={periodStr}
                showGroupBatch={false}
                groups={periodGroups}
                onChange={period => this.setGlobalPeriodStr(period)}/>
            </PeriodWrapper>
          }
          {items.map((block, index) => {
            let notLastBlock = index < items.length - 1 && items.length;
            return (
              <span key={index}>
                {this.renderBlock(block, index)}
                {
                  notLastBlock &&
                  <NodeConnector style={{marginLeft: '20px'}}>
                    {this.renderLogicalOperator(op, newOp => newOp && this.changeLogicalOperator(filters, newOp))}
                  </NodeConnector>
                }
              </span>
            );
          })}
          {!items.length && this.renderEmptyBlock(0)}
        </div>
        {
          !autoApply &&
          <Actions className="actions">
            <div className="dynamic-position">
              {
                !!items.length &&
                <Btn className="btn add-block-btn" onClick={e => this.addBlock(items.length)}>
                  <i className="fa fa-plus-square" style={{marginTop: '2px'}}></i>
                  <span>Add Block</span>
                </Btn>
              }
              {this.renderGlobalConfig()}
            </div>

            <Btn className="btn inverse" onClick={e => {
              this.setState({filters: {op: '$and', items: []}});
              this.props.onCancel();
            }}>
              {this.props.resetLabel || 'Cancel'}
            </Btn>
            <Btn className="btn primary" onClick={e => {
              this.setState({applied: true});
              this.props.onFiltersChange(this.state.filters);
            }}>
              {this.props.applyLabel || 'Apply'}
            </Btn>

          </Actions>
        }

        {
          !!autoApply &&
          <Actions className="actions" leftAlign={true}>
            <div className="dynamic-position">

              {
                !!items.length &&
                <Btn className="btn add-block-btn" onClick={e => this.addBlock(items.length)}>
                  <i className="fa fa-plus-square"></i>
                  <span>Add Block</span>
                </Btn>
              }
              {this.renderGlobalConfig()}
            </div>
          </Actions>
        }

      </FilterBoxWrapper>
    );
  }

  renderGlobalConfig() {
    const {filters} = this.state;
    if ((filters.items || []).length < 2) {
      return null;
    }
    return (
      <GlobalControls className="global-scope">
        <span>
        <StyledSelectBox
          selected={filters.scope || 'hit'}
          multi={false}
          toggleLableFunc={d => <i className="fa fa-cog"/>}
          overflow={'visible'}
          menuHeader={'Block Scope'}
          data={[
            {key: 'hit', label: 'Activity'},
            {key: 'visitor', label: 'Visitor'},
          ]}
          onChange={key => {
            if (key === 'visitor') {
              this.setState({filters: {...filters, scope: 'visitor'}});
            } else if (key === 'hit') {
              this.setState({filters: {...filters, scope: 'hit'}});
            } else if (key === 'add') {
              this.addBlock(filters.items.length);
            }
          }}/>
        </span>
      </GlobalControls>
    );
  }

  renderBlock(block, blockPath) {
    const {op = '$and', not, scope = 'hit', closed, items = []} = block;
    const {extraSettingState} = this.state;
    return (
      <BlockWrapper className="block-wrapper" key={blockPath} not={not}>
        <BlockControls className="block-controls" not={not}>
          <BlockLeftControls>
            <div className="win-btn" onClick={e => this.setBlockOpenStatus(blockPath, !closed)}>
              <i className={`fa fa-folder${!closed ? '-open' : ''}`}/>
            </div>
            <div className="win-btn" onClick={e => this.removeBlock(blockPath)}>
              <i className={`fa fa-times`}/>
            </div>
          </BlockLeftControls>
          <ExtraControls>
            <Btn className="btn plus-btn"
                 title="add filter"
                 onClick={e => this.addFilterNode(blockPath + '-' + items.length)}>
              <i className="fa fa-plus"></i>
            </Btn>
            <StyledSelectBox
              selected={scope}
              multi={false}
              overflow={'visible'}
              toggleLableFunc={d => <i className="fa fa-cog"/>}
              groups={[
                {
                  key: 'actions', label: 'Actions',
                  data: [
                    {key: 'not', label: not ? 'Include' : 'Exclude'},
                    {key: 'subblock', label: 'Add Subblock', hidden: String(blockPath).split('-').length >= 4},
                    {key: 'remove', label: 'Remove Block'},
                  ].filter(d => !d.hidden)
                },
                {
                  key: 'scope',
                  label: 'Block Scope',
                  data: [{key: 'hit', label: 'Activity'}, {key: 'visitor', label: 'Visitor'}]
                }
              ]}
              onChange={key => {
                if (key === 'remove') {
                  this.removeBlock(blockPath);
                } else if (key === 'subblock') {
                  this.addBlock(blockPath + '-' + items.length);
                } else if (key === 'not') {
                  this.toggleBlockNotOperator(blockPath, !not);
                } else if (key === 'hit' || key === 'visitor') {
                  this.changeBlockScope(blockPath, key);
                }
              }}/>
          </ExtraControls>
        </BlockControls>
        {
          !closed &&
          <React.Fragment>
            {items.map((item, nodeIndex) => {
              const nodePath = blockPath + '-' + nodeIndex;
              const isInnerBlock = !!item.op && !!logicalOperatorOptions.find(d => d.value === item.op);
              return (
                <React.Fragment key={nodeIndex}>
                  {
                    items.length > 1 && nodeIndex > 0 &&
                    <NodeConnector theme={'small'}>
                      {
                        this.renderLogicalOperator(op,
                          newOp => this.changeLogicalOperator(block, newOp),
                          logicalOperatorOptions.filter(o => o.value !== '$it')
                        )
                      }
                    </NodeConnector>
                  }
                  {isInnerBlock && this.renderBlock(item, nodePath)}
                  {!isInnerBlock && this.renderFilterNodeWrapper(item, nodePath)}
                </React.Fragment>
              );
            })}
            {
              !items.length &&
              <NodeWrapper key="empty-block">
                <div className="block-inner">
                  {this.renderEmptyNode(blockPath + '-0')}
                  <span className="right">
                    <a className="btn extra-btn" title="more settings"></a>
                    <Btn className="btn trash-btn"
                         title="remove filter"
                         onClick={e => this.removeFilterNode(blockPath + '-0')}>
                      <i className="fa fa-trash"></i>
                    </Btn>
                  </span>
                </div>
              </NodeWrapper>
            }
          </React.Fragment>
        }
      </BlockWrapper>
    );
  }

  renderFilterNodeWrapper(item, nodePath) {
    const {extraSettingState, filters} = this.state;

    const isInnerBlock = item.op && logicalOperatorOptions.find(d => d.key === item.op);
    const showMoreSettings = !!extraSettingState[nodePath];
    const {key, periodStr = filters.periodStr, frequency = 1, frequencyOperator = '$gte', visitFrequency = 1} = item;
    return (
      <NodeWrapper className={`${showMoreSettings ? 'highlight' : ''}`} key={nodePath}>
        <div className="block-inner">
          {this.renderFilterNode(item, nodePath)}
          {
            !item.hideForEmailTargeting &&
              <span className="right">
                <Btn className="btn trash-btn"
                     title="remove filter"
                     onClick={e => this.removeFilterNode(nodePath)}>
                  <i className="fa fa-trash"></i>
                </Btn>
                <a className="btn extra-btn" title="more settings"
                   onClick={e => this.setState({
                     extraSettingState: {...extraSettingState, [nodePath]: !showMoreSettings}
                   })}>
                <i className="fa fa-ellipsis-v"></i>
                </a>
              </span>
          }
        </div>
        {
          !!extraSettingState[nodePath] &&
          <NodeSettingWrapper disabled={key === 'segment'}>
            <div className="setting-item">
              <label>
                Recency
                <Hint pos={'right'} width={350} content={
                  <div>
                    <h3>What is Recency?</h3>
                    <p>The period of time which a user matches an audience definition. For example, setting
                      Recency
                      to ’30 days’ would mean that the segment would comprise of users who had exhibited a
                      particular behaviour in the last 30 days. In this case, it explicitly excludes users who
                      exhibited the behaviour 31 or more days ago but did not exhibit the behaviour in the last
                      30
                      days.</p>
                  </div>
                }/>:
              </label>
              <div className="setting-item-value">
                <StyledPeriodSelect
                  selected={periodStr}
                  showGroupBatch={false}
                  groups={periodGroups}
                  onChange={period => this.setNodePeriod(nodePath, period)}/>
              </div>
            </div>
            <div className="setting-item">
              <label>
                Frequency
                <Hint pos={'right'} width={350} content={
                  <div>
                    <h3>What is Frequency?</h3>

                    <p>The number of occurrences a user exhibits within a specified period of time. For example,
                      setting Frequency to ‘3’ would mean that a segment would comprise of users who had
                      exhibited
                      a
                      particular behaviour at least 3 times over the period.</p>
                  </div>
                }/>:
              </label>
              <div className="setting-item-value">
                <StyledPeriodSelect
                  selected={frequencyOperator}
                  data={numberOperatorOptions.map(d => ({...d, key: d.value}))}
                  onChange={frequencyOperator => this.setNodeFrequencyOp(nodePath, frequencyOperator)}/>
                <input type="number"
                       min="1"
                       value={frequency}
                       onChange={e => this.setNodeFrequency(nodePath, e.target.value)}/>
              </div>
            </div>
          </NodeSettingWrapper>
        }
      </NodeWrapper>
    )
  }

  normalizeDimension(dimension) {
    if (dimension && !dimension.datatype && ['timeSpentInMin', 'videoTimeSpentInMin'].indexOf(dimension.value) >= 0) {
      dimension.datatype = 'number';
    }
    return dimension;
  }

  catchEmptyMeta(data, key, type) {
    if(!!data && !!data.length) {
      return data;
    }
    if(type === 'group') {
      return [{key: 'fallback', label: '', data: [{key: key, label: behaviourTypeMap[key] || key}]}]
    } else {
      return [{key: key, label: behaviourTypeMap[key] || key}];
    }
  }

  renderFilterNode(item, nodePath) {
    const {dimensions} = this.props;
    const {op, key, value, hideForEmailTargeting} = item;
    if(hideForEmailTargeting) {
      return 'SSO Users';
    }
    const {} = this.props;
    let opOptions = rangeOperatorOptions;
    let dim = this.props.dimensions.find(d => d.value === key);
    dim = this.normalizeDimension(dim);
    if (dim && dim.datatype === 'number') {
      opOptions = numberOperatorOptions;
    } else if (dim && dim.datatype === 'boolean') {
      opOptions = booleanOperatorOptions;
    } else if (dim && dim.type === 'composite') {
      opOptions = rangeOperatorOptions;
    } else if (dim && dim.type === 'dynamic') {
      opOptions = rangeOperatorOptions.concat(stringOperatorOptions)
      opOptions = opOptions.filter(o => dim.supportedOperators.indexOf(o.value) >= 0);
    }
    if(this.props.readOnly) {
      opOptions = rangeOperatorOptions.concat(stringOperatorOptions).concat(numberOperatorOptions)
    }

    let behaviourGroups = this.dimensionGroup;
    let {filters: {periodStr}} = this.state;
    if(periodStr === 'forever') {
      let demoGroup = _.cloneDeep(behaviourGroups.find(d => d.key === 'Demographics'));
      if(demoGroup) {
        demoGroup.data = demoGroup.data.filter(d => d.key === 'ageAll' || d.key === 'genderAll');
        behaviourGroups = [demoGroup];
      }
    }
    return (
      <div className="left" key={`${key}_${nodePath}`}>
        <StyledSelectBox
          selected={key}
          multi={false}
          defaultLabel="Select behaviour type"
          showGroupBatch={false}
          searchable={true}
          mutuallyExclusive={true}
          groups={this.catchEmptyMeta(behaviourGroups, key, 'group')}
          onChange={newFilterKey => this.changeFilterKey(item, nodePath, newFilterKey)}/>
        {op &&
        <SelectWrapper className="node-op-select">
          <Select
            placeholder="Select"
            selected={op}
            multi={false}
            searchable={false}
            autoBlur={true}
            autoFocus={false}
            onBlurResetsInput={false}
            data={opOptions.map(d => ({...d, label: d.shortLabel || d.label}))}
            onChange={newOp => this.changeFilterOperator(item, nodePath, newOp)}
          />
        </SelectWrapper>
        }
        {op && this.renderFilterNodeValue(item, nodePath)}
      </div>
    );
  }

  renderEmptyBlock(blockPath) {
    const placeholderRow = {op: '$and', items: []};
    return this.renderBlock(placeholderRow, blockPath);
  }

  renderEmptyNode(nodePath) {
    const placeholderNode = {};
    return this.renderFilterNode(placeholderNode, nodePath);
  }

  renderFilterNodeValue(item, nodePath) {
    const {op, key, value} = item;
    if (booleanOperatorOptions.find(o => o.value === op)) {
      return (
        <SelectWrapper className="value-select">
          <Select
            key={key}
            selected={value}
            multi={false}
            searchable={false}
            autoBlur={true}
            autoFocus={false}
            onBlurResetsInput={false}
            placeholder={'Search behaviour'}
            data={[{value: true, label: 'True'}, {key: false, label: 'False'}]}
            onChange={option => this.changeFilterValue(item, nodePath, option.value)}
          />
        </SelectWrapper>
      );
    } else if (numberOperatorOptions.find(o => o.value === op)) {
      return (
        <input
          className="text-box"
          type="number"
          placeholder=""
          value={value}
          min={0}
          onChange={e => this.changeFilterValue(item, nodePath, e.currentTarget.value)}/>
      );
    } else if (stringOperatorOptions.find(o => o.value === op)) {
      if(this.props.readOnly) {
        return <div className="readonly-text-box">{value}</div>
      }
      return (
        <input
          className="text-box"
          type="text"
          placeholder=""
          value={value}
          onChange={e => this.changeFilterValue(item, nodePath, e.currentTarget.value)}/>
      );
    } else if (rangeOperatorOptions.find(o => o.value === op)) {
      let inputValue;
      let dimensionMeta = (this.props.dimensions || []).find(d => d.value === key);
      // if (!dimensionMeta && !!value && !!value.length) {
      if (!dimensionMeta) {
        dimensionMeta = {key: 'placeholder', options: [value]};
      }
      let {options} = dimensionMeta;
      let ckey = key;
      if (!dimensionMeta.options) {
        ckey += value;
      }
      if (dimensionMeta && dimensionMeta.options.length) {
        return (
          <SelectWrapper className="value-select">
            <Select
              key={ckey + value}
              delimiter="---"
              selected={value.filter(v => !!v).map(v => String(v)).join('---')}
              multi={true}
              searchable={true}
              placeholder={'Search behaviour'}
              data={options.map(d => ({key: d[0], label: d[0] === 'UNKNOWN' ? 'Unknown' : d[0]}))}
              valueRenderer={option => <span title={option.label}>{option.label}</span>}
              optionRenderer={(option, index) => {
                if (index > 500) {
                  return null;
                }
                //meta = [key, size];
                let meta = options.find(d => d[0] === option.value) || [];
                return (
                  <ContentOptionWrapper title={option.label}>
                    <div>
                      <Highlighter searchWords={(inputValue || '').split(/\s/g)} autoEscape={true}
                                   textToHighlight={option.label}/>
                    </div>
                  </ContentOptionWrapper>
                )
              }}
              onInputChange={value => (inputValue = value)}
              onChange={options => {
                this.changeFilterValue(item, nodePath, options.map(o => o.value));
              }}
            />
          </SelectWrapper>

        );
      } else {
        return (
          <SelectWrapper className="value-select">
            null
          </SelectWrapper>
        );
      }
    }
  }

  dimensionLoader(dimension, selected = []) {
    // const appendExisting = options => {
    //   selected.forEach(s => {
    //     if (!options.find(o => o.value === s) && s !== 'showmore') {
    //       options.push({value: s, label: s});
    //     }
    //   });
    //   return options;
    // }
    // return debounce(keyword => {
    //   // if (!keyword || keyword.length < 2) {
    //   //   return Promise.resolve({options: appendExisting([])});
    //   // }
    //   const dimensionRetriver = this.props.dimensionRetriveAPI || '/api/contentSearch';
    //   return post(dimensionRetriver, {dimension, keyword, offset: 0, limit: 10})
    //     .then(result => {
    //       let {count, items} = result || {};
    //       if (items) {
    //         items.forEach(o => o.value = String(o.value));
    //       }
    //       if (count > 10) {
    //         items.unshift({
    //           value: 'showmore',
    //           label: 'Show more...',
    //           keyword: keyword,
    //           total: count
    //         })
    //       }
    //       return {options: appendExisting(items), complete: false};
    //     }).catch(e => {
    //       console.error(e);
    //       return {options: appendExisting([])};
    //     });
    // }, 500).bind(this);
  }

  renderLogicalOperator(operator, onChange, logicalOperatorOptionsHere) {
    return (
      <SelectWrapper className="logical-op-select">
        <Select
          placeholder="Select Option"
          selected={operator}
          multi={false}
          searchable={false}
          autoBlur={true}
          autoFocus={false}
          onBlurResetsInput={false}
          data={logicalOperatorOptionsHere || logicalOperatorOptions}
          onChange={newOp => onChange(newOp)}
        />
      </SelectWrapper>
    );
  }

  applyFilters = () => {
    this.props.autoApply && this.props.onFiltersChange(this.state.filters);
  }

  buildDimensionGroup(props) {
    const {dimensions} = props;
    let dimensionGroup = _.groupBy(dimensions, _.property('group'));
    this.dimensionGroup = Object.keys(dimensionGroup).map(groupKey => {
      return {
        key: groupKey,
        label: groupKey,
        data: dimensionGroup[groupKey].map(d => {
          return {
            ...d,
            key: d.value,
            data: d.options.map(o => ({key: o, label: o}))
          }
        })
      }
    })
  }

  setGlobalPeriodStr(periodStr) {
    let newFilters = this.state.filters;
    newFilters.periodStr = periodStr;
    if(periodStr === 'forever') {
      newFilters.items = [
        {op: '$and', items: []}
      ];
    }
    this.setState({filters: newFilters}, this.applyFilters);
  }

  getBlock(path) {
    if (path === 'ROOT') {
      return this.state.filters; //no path, return global
    }
    const {filters} = this.state;
    let pathArray = String(path).split('-').map(d => Number(d));
    let currentNode = filters; //set current to global block;
    pathArray.forEach((pathNodeIndex, index) => {
      if (index <= pathArray.length - 1) {
        const {op, items} = currentNode;
        currentNode = items[pathNodeIndex];
        if (!currentNode) {
          currentNode = items[pathNodeIndex] = {op: '$and', items: []}
        }
      }
    });
    return currentNode;
  }

  getNode(nodePath) {
    const {filters} = this.state;
    let pathArray = String(nodePath).split('-').map(d => Number(d));
    let currentNode = filters; //set current to global block;
    pathArray.forEach((pathNodeIndex, index) => {
      if (index <= pathArray.length - 1) {
        const {op, items} = currentNode;
        currentNode = items[pathNodeIndex];
        if (!currentNode && index < pathArray.length - 1) {
          currentNode = items[pathNodeIndex] = {op: '$and', items: []}
        } else if (!currentNode && index === pathArray.length - 1) {
          currentNode = items[pathNodeIndex] = {};
        }
      }
    });
    return currentNode;
  }

  getParentBlock(blockPath) {
    if (blockPath === 'ROOT') {
      return this.state.filters; //no path, return global
    }
    let parentBlockPath = this.getParentBlockPath(blockPath);
    return this.getBlock(parentBlockPath);
  }

  getParentBlockPath(blockPath) {
    let pathArray = String(blockPath).split('-').map(d => Number(d));
    pathArray.pop();
    return pathArray.length ? pathArray.join('-') : 'ROOT';
  }

  getLeafIndex(blockPath) {
    let pathArray = String(blockPath).split('-').map(d => Number(d));
    return Number(pathArray.pop());
  }

  addBlock(blockPath) {
    let leafIndex = this.getLeafIndex(blockPath);
    let parentBlock = this.getParentBlock(blockPath);
    const {op, items} = parentBlock;
    items[leafIndex] = {op: '$and', items: []};

    this.setState({filters: this.state.filters}, () => {
      this.applyFilters();
    });
  }

  removeBlock(blockPath) {
    if (blockPath === 'ROOT') {
      return this.setState({filters: {...this.state.filters, items: []}}, this.applyFilters);
    }
    let leafIndex = this.getLeafIndex(blockPath);
    let parentBlock = this.getParentBlock(blockPath);
    const {op, items} = parentBlock;
    items.splice(leafIndex, 1);
    // if (!items.length) {
    //   this.removeBlock(this.getParentBlockPath(blockPath));
    // }
    this.setState({filters: this.state.filters}, this.applyFilters);
  }

  setBlockOpenStatus(blockPath, closed) {
    let block = this.getBlock(blockPath);
    block.closed = closed;
    this.setState({filters: this.state.filters}, this.applyFilters);
  }

  toggleBlockNotOperator(blockPath, not) {
    let block = this.getBlock(blockPath);
    block.not = not;
    this.setState({filters: this.state.filters}, this.applyFilters);
  }

  changeBlockScope(blockPath, scope) {
    let newFilters = this.state.filters;
    let block = this.getBlock(blockPath);
    block.scope = scope;
    this.setState({filters: this.state.filters}, this.applyFilters);
  }

  addFilterNode(blockPath) {
    let leafIndex = this.getLeafIndex(blockPath);
    let parentBlock = this.getParentBlock(blockPath);
    const {op, items} = parentBlock;
    items[leafIndex] = {};
    this.setState({filters: this.state.filters}, this.applyFilters);
  }

  removeFilterNode(nodePath) {
    let leafIndex = this.getLeafIndex(nodePath);
    let parentBlock = this.getParentBlock(nodePath);
    const {op, items} = parentBlock;
    items.splice(leafIndex, 1);
    if (!items.length) {
      this.removeBlock(this.getParentBlockPath(nodePath))
    }
    this.setState({filters: this.state.filters}, this.applyFilters);
  }

  setNodePeriod(nodePath, periodStr) {
    const node = this.getNode(nodePath);
    node.periodStr = periodStr;
    this.setState({filters: this.state.filters}, this.applyFilters);
  }

  setNodeFrequency(nodePath, frequency) {
    const node = this.getNode(nodePath);
    node.frequency = frequency;
    this.setState({filters: this.state.filters}, this.applyFilters);
  }

  setNodeFrequencyOp(nodePath, frequencyOperator) {
    const node = this.getNode(nodePath);
    node.frequencyOperator = frequencyOperator;
    this.setState({filters: this.state.filters}, this.applyFilters);
  }

  setNodeVisitFrequency(nodePath, visitFrequency) {
    const node = this.getNode(nodePath);
    node.visitFrequency = visitFrequency;
    if (!node.frequencyOperator) {
      node.frequencyOperator = '$gte';
    }
    this.setState({filters: this.state.filters}, this.applyFilters);
  }

  changeFilterKey(item, nodePath, newKey) {
    const newFilterKey = (newKey && typeof newKey === 'object') ? newKey.value : newKey;
    if (!newFilterKey) {
      this.removeFilterNode(nodePath);
    } else {
      let node = this.getNode(nodePath);
      node.key = newFilterKey;
      const dim = this.props.dimensions.find(d => d.value === newFilterKey);
      if (dim && !!dim.supportedOperators) {
        if (node.op && dim.supportedOperators.indexOf(node.op) < 0) {
          node.op = dim.supportedOperators[0] || '$in';
        } else {
          node.op = node.op || dim.supportedOperators[0] || '$in'
        }
      }
      else if (dim && dim.datatype !== 'boolean') {
        node.op = node.op || '$in';
      } else {
        node.op = node.op || '$is';
      }
      if (rangeOperatorOptions.find(op => op.value === node.op)) {
        node.value = [];
      } else if (stringOperatorOptions.find(op => op.value === node.op)) {
        node.value = '';
      } else if (booleanOperatorOptions.find(op => op.value === node.op)) {
        node.value = undefined;
      }
      this.setState({filters: this.state.filters}, this.applyFilters);
    }
  }

  changeFilterOperator(node, nodePath, newOpParam) {
    const newOp = (newOpParam && typeof newOpParam === 'object') ? newOpParam.value : newOpParam;
    const {op: oldOp, value: oldValue} = node;
    node.op = newOp;
    let sameCat = [
      rangeOperatorOptions,
      stringOperatorOptions,
      booleanOperatorOptions
    ].some(cat => cat.find(o => o.value === newOp) && cat.find(o => o.value === oldOp));

    if (sameCat) {
      node.value = oldValue;
    } else {
      if (rangeOperatorOptions.find(option => option.value === newOp)) {
        node.value = [];
      }
      if (booleanOperatorOptions.find(option => option.value === newOp)) {
        node.value = false;
      } else if (stringOperatorOptions.find(option => option.value === newOp)) {
        node.value = '';
      }
    }
    this.setState({filters: this.state.filters}, this.applyFilters);
  }

  changeFilterValue(subItem, nodePath, value) {
    const node = this.getNode(nodePath);
    node.value = value;
    this.setState({filters: this.state.filters}, this.applyFilters);
  }

  changeLogicalOperator(node, newOp) {
    const {filters} = this.state;
    if (newOp) {
      node.op = typeof newOp === 'object' ? newOp.value : newOp;
      this.setState({filters: filters}, this.applyFilters);
    }
  }
};