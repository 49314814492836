import styled, {css} from 'styled-components';

export const BasicWrapper = styled.div`
  .inline-editor {
    padding: 3px 5px;
    outline: none;
    font-size: 12px;
    width: 90%;
    border: 1px solid transparent;
    background: transparent;
    border-radius: 0px;
    text-align: center;
    &:focus {
      border-radius: 5px;
      background: white;
      border-bottom: 1px dotted #d1d2d3;
    }
  }
`;
