import React from 'react';
import styled, {css} from 'styled-components';
import {
  BitterSweet1,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass1, LightGray, LightGray1, MediumGray,
  MediumGray1, LightBlue
} from "../../app/StyleCommon";

import {WidgetWrapper} from './grid.style';

export const CNA_Web_Home_MostWatched = styled(WidgetWrapper)`
  &&& {
    font-family: Buenard, serif;
    padding: 10px 5px;
    .widget-title {
    }
    .item-list {
      padding: 0;
      .item {
        padding: 0 5px;
        .item-inner  {
          margin-left: 0;
          margin-right: 0;
          padding-left: 0;
          padding-right: 0;
          .thumbnail {
             padding-bottom: 56.25%;
             height: 0;
             .video-icon {
                left: 0;
                bottom: 0;
                width: auto;
                right: auto;
                padding: 2px 5px 2px 15px;
                background-color: #a12528;
                background-image: url(/img/icons/video-play-cna.svg);
                background-size: 5px;
                background-position: 5px 6px;
                color: white;
                font-size: 11px;
             }
          }
          .brief {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .section {
              display: block;
            }
            .title {
              max-height: 2.6em;
              line-height: 1.3em;
              text-decoration: none;
              overflow: visible;
              text-overflow: ellipsis;
              display: block;
              font-size: 18px;
              color: black;
              min-height: 90px;
              margin-bottom: 20px;
              &:hover {
                text-decoration: underline;
              }
            }
            .publish_date {
              display: block;
            } 
          }
        }
      }
      .arrow {
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0,0,0,.5);
        border-radius: 0;
        border: none;
        box-shadow: none;
        color: #D83BB3;
        opacity: 1;
        &:before, &:after {
          color: white;
        }
        &.arrow-left {
          left: 0px;
        }
        &.arrow-right {
          right: 0px;
          left: auto;
        }
      }
    }
  }

  ${props => props.mediaWidth < 600 && css`
    &&& {
      .widget-title {
      }
      .item-list {
        .item {
          width: 100%;
          &:not(:last-child) .item-inner {
            border-bottom: 1px solid #ccc;
          }
          &.thumbnail_top .item-inner {
            border-right: none;
            .thumbnail {
              width: 33.33%;
              height: 0;
              padding-bottom: 19%;
            }
          }
          .item-inner {
            padding: 10px;
            display: flex;
            .thumbnail {
              width: 33.33%;
              height: 0;
              padding-bottom: 19%;
              max-width: inherit;
            }
            .brief {
              .title {
              }
              
              .publish_date {
              }
            }
          }
        }
      } 
    }
  `}
`