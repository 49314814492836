import React from 'react';
import styled, {css} from 'styled-components';
import {
  BitterSweet,
  BitterSweet1,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass, Grass1, LightBlue, LightGray, LightGray1, MediumGray,
  MediumGray1, Sunflower1
} from "../app/StyleCommon";

export const ClickLogPanelWrapper = styled.div`
  position: relative;
  .actions {
    text-align: right;
    .selectBtn {

    }
  }
  .table-wrapper {
    margin-top: 10px;
    max-height: 60vh;
    overflow-y: auto;
    margin-bottom: 20px;
    table {
      min-height: 200px;
      td {
        vertical-align: top;
      }
    }
    .error-msg {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 400px;
    }
    .time {
      white-space: nowrap;
    }
    .details {
      font-size: 11px;
      margin-top: 5px;
    }
    .recs {
      a {
        margin: 0 5px;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
        &.current {
          font-size: 13px;
          text-decoration: underline;
        }
      }
    }
    .content-view {
      min-width: 200px;
      img {
        width: 80px;
        height: 45px;
        margin-right: 10px;
        float: left;
        margin: 5px 10px 10px 0;
      }
      a {
        font-size: 11px;
        color: #6d9245 !important;
        font-weight: bold;
      }
    }
  }

  .searchBox {
    flex-grow: 1;
    margin-top: 20px;
    margin: 0;
    position: relative;
    i {
      position: absolute;
      left: 10px;
      top: 10px;
    }
    input {
      padding: 8px 10px 8px 30px;
      font-size: 14px;
      border-radius: 5px;
      border: 1px solid ${MediumGray};
      background: ${LightGray};
      width: 100%;
      box-sizing: border-box;
      outline: none;
      &:focus {
        border-color: ${BlueJeans};
        box-shadow: inset 0 0 3px rgba(0,0,0,.1);
      }
    }
  }
`