import styled, {css} from 'styled-components';
import {
  Aqua, BitterSweet, BitterSweet1,
  BlueJeans, DarkGray, DarkGray1, Grass, LightGray, LightGray1, MediumGray, MediumGray1, Mint, BlueJeans1,
  Sunflower1
} from "../app/StyleCommon";

export const CampaignPageWrapper = styled.div`
  margin-top: 35px;
`;


export const EmptyList = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  width: 100%;
  >div{
    display: inline-block;
    text-align: center;
    button {
      margin: 10px;
    }
  }
`;

export const CreateWrapper = styled.div`
  margin: 10px 0;
  .field-note {
    margin: -20px 10px 35px 10px;
    font-size: 12px;
    font-weight: bold;
    color: ${MediumGray1};
  }
  .field-grid {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .field {
      width: 50%;
      &:first-child {
        margin-right: 60px;
      }
    }
    @media screen and (max-width: 667px) {
      flex-wrap: wrap;
      .field {
        width: 100%;
        margin-right: 0 !important;
      }
    }
  }
  .field {
    margin-bottom: 25px;
    &.error > input,
    &.error .react-datepicker__input-container input,
    &.error .select-box .toggler
    {
      border-bottom: 1px dashed ${BitterSweet1}
    }
    > input {
      width: 100%;
      border: none;
      border-bottom: 1px solid ${MediumGray};
      padding: 5px 10px 5px 5px;
      margin: 10px 0;
      box-sizing: border-box;
    }
    .priority-set {
      display: flex;
      align-items: center;
      > .select-box:first-child {
        width: 80%;
      }
      > .select-box:last-child{
        width: 20%;
        min-width: 80px;
        .menu {
          min-width: auto;
        }
      }
      .sep {
        margin: 0 10px;
        font-size: 24px;
        color: ${MediumGray};
        font-weight: 300;
        user-select: none;
      }
    }
    .select-box {
      margin: 10px 0;
      .toggler {
        min-height: 26px;
        .default-label {
          color: ${MediumGray};
          font-size: 12px !important;
        }
      }
    }
    .error-msg {
      color: ${BitterSweet};
      display: block;
      width: 100%;
      font-size: 12px;
      margin-top: -6px;
    }
  }
  .priprity-item {
    padding: 5px 20px;
    white-space: normal;
    padding: 5px 0;
    img {
      height: 16px;
      margin-right: 10px;
      margin-top: 3px;
    }
    .name {
      font-weight: bold;
    }
    .description {
       font-size: 12px;
       font-weight: 400;
    }
  }
  .react-datepicker-wrapper {
    display: block;
    width: 100%;
    margin: 10px 0;
    .react-datepicker__input-container {
      width: 100%;
    }
    input[type=text] {
      padding: 5px 10px;
      width: 100%;
      border: none;
      box-sizing: border-box;
      border-bottom: 1px solid ${MediumGray};
    }
    .react-datepicker__close-icon::after {
      background-color: ${MediumGray};
    }
  }
  .react-datepicker-popper {
    z-index: 2;
  }
  .react-datepicker {
    border: 1px solid ${MediumGray};
    .react-datepicker__time-container  {
      border: 1px solid ${LightGray1};
    }
    &__header {
      background-color: white;
    }
    &__header--time {
      height: 41px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: capitalize;
    }
    &__time-list-item  {
      height: 18px !important;
    }
    &__time-list {
      padding-top: 10px;
    }
    &__time-list-item--selected {
      background-color: ${BlueJeans} !important;
    }
    &__day--selected {
      background-color: ${BlueJeans} !important;
    }
    &__navigation--next {
      right: 90px;
    }

    &__day--keyboard-selected {
      background-color: ${MediumGray};
    }
    @media screen and (max-width: 667px) {
      &__day {
        margin: 0;
      }
      &__time-container {
        width: 60px;
      }
      &__time-list-item {
        padding: 5px 0px !important;
        width: 60px !important;
      }
    }
  }
  .goal-settings {
    display: flex;
    align-items: center;
    position: relative;
    > input {
      width: 100%;
      box-sizing: border-box;
      border: none;
      border-bottom: 1px solid ${MediumGray};
      padding: 6px 10px 6px 5px;
      margin: 10px 0;
      flex: none;
      box-sizing: border-box;
      margin-right: 30px;
    }
    .select-box {
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
      .toggler {
        padding: 5px 10px;
        border: none;
        text-align: right;
        padding-right: 40px;
        background-color: transparent;
        outline: none;
      }
      .menu {
        right: 0;
        left: auto;
      }
    }
  }
  .fc-settings {
    display: flex;
    align-items: center;
    position: relative;
    > div:first-child {
      width: 100%;
      white-space: nowrap;
      z-index: 1;
    }
    > div:last-child {
      position: absolute;
      right: 0;
      top: 12px;
    }
    input {
      width: 100%;
      box-sizing: border-box;
      border: none;
      border-bottom: 1px solid ${MediumGray};
      padding: 6px 10px 6px 5px;
      margin: 10px 0;
      flex: none;
      box-sizing: border-box;
      margin-right: 30px;
      background-color: transparent;
      cursor: pointer;
      &[disabled] {
        background-color: #f7f8f9;
      }
    }
    .unit {
      display: flex;
      align-items: center;
      white-space: nowrap;
      .info {
      }
      label {
        &.selected {
          background-color: ${BlueJeans1};
          color: white;
        }
      }
    }
    .period {
      border-radius: 2px;
      border: 1px solid ${MediumGray};
      margin-left: 10px;
      padding-bottom: 1px;
      border-radius: 5px;
      overflow:hidden;
      z-index: 2;
      cursor: pointer;
      label {
        background-color: ${LightGray};
        padding: 1px 8px;
        //font-weight: bold;
        font-size: 12px;
        cursor: pointer;
        &.disabled {
          pointer-events: none;
          opacity: .8;
          cursor: not-allowed;
        }
        &:not(:last-child) {
          border-right: 1px solid ${MediumGray}
        }
      }
    }
  }
`;

export const CreativeWrapper = styled.div`
  margin: 10px 20px 50px 0px;
`;