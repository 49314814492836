import styled, {css} from 'styled-components';

import {DialogZIndex, ElementMaskZIndex} from "../../app/StyleCommon";

export const ConfirmWrapper = styled.div.attrs({
  'data-rp': 'confirm-wrapper'
})`
  position: relative;
  &:before {
    display: block;
    content: '';
    position: absolute;
    background-color: rgba(0,0,0,.5);
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
    z-index: ${ElementMaskZIndex};
    ${props => !props.active && css`
      display: none;
    `}
  }
  ${props => props.active && css`
    min-height: 220px;
  `}
  ${props => !props.active && css`
    .confirm-dialog {
      display: none;
    }
  `}
  .confirm-dialog {
    position: absolute;
    top: 0px;
    left: 50%;
    width: 300px;
    padding: 30px 20px;
    transform: translate(-50%, 120px);
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 3px 5px rgba(0,0,0, .1);
    z-index: ${DialogZIndex};
    @media (max-width: 800px) {
      bottom: 0;
      position: fixed !important;
      width: 100% !important;
      left: 0 !important;
      right: 0 !important;
      top: auto !important;
      transform: none !important;
      background: rgba(0,0,0,.5);
      padding: 10px 0;
      button {
        width: 40%;
        height: 45px;
      }
    }
    .title {
      font-size: 14px;  
      text-align: center;
      margin-bottom: 20px;
      display: none;
    }
    .buttons {
      display: flex;
      justify-content: center;
      align-items: center;
    }  
  }
`;