import styled, {css} from 'styled-components';
import {
  Aqua, BitterSweet,
  BlueJeans, DarkGray, DarkGray1, Grass, LightGray, LightGray1, MediumGray, MediumGray1,
  Sunflower1
} from "../app/StyleCommon";

export const SegmentPageWrapper = styled.div`
  h3 {
    color: ${DarkGray1};
    
  }
  .table-wrapper {
    margin-bottom: 10px;
    th {
      background-color: white;
    }
    tr:hover {
      background-color: ${LightGray1};
    }
    .campaign {
      div:last-child {
        color: ${MediumGray1};
        font-weight: bold;
      }
    }
  }
  .step-actions {
    position: absolute;
    top: 10px;
    right: 40px;
  }
`;

export const EmptyPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-right: 30px;
  margin-top: 50px;
  img {
    width: 100px;
  }
  >div{
    display: inline-block;
    text-align: center;
    button {
      margin: 10px;
    }
  }
`;

export const SegmentSelectorWrapper = styled.div`
  position: relative;
  &.overflow {
    max-height: calc(80vh);
    overflow-y: auto;
  }
  .popup-actions {
    text-align: right;
    margin-top: 30px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    .loader {
      
    }
  }
 `;

export const SegmentInfo = styled.div`
  position: relative;
  background: white;
  padding: 10px 20px;
  margin-left: -5px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-top: 60px;
  h5 {
    position: absolute;
    top: -45px;
    left: 0;
  }
  .note {
    font-size: 12px;
  }
  .attr {
    label {
      display: inline-block;
      width: 150px;
      font-size: 14px;
    }
    strong {
      font-weight: normal;
      font-size: 12px;
      &.strong {
        font-weight: bold !important;
      }
    }
  }
  >div {
    margin: 20px 0;
    &:first-child {
      margin-top: 0px;
    }
    .definition-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0 5px 0;
      position: relative;
      .delete-btn {
        position: absolute;
        right: 10px;
        bottom: -45px;
        z-index: 1;
      }
    }
    .global-period-settings {
      margin: 0 !important;
      background: linear-gradient(to bottom, white, #fafbfc);
      border-radius: 5px 5px 0 0;
    }
    .filter-box-wrapper {
      border-radius: 5px;
      box-shadow: 1px 1px 5px rgba(0,0,0,.1);
      margin: 20px 0 10px 0;
      border: 1px solid ${LightGray1} !important;
      border: none;
    }
  }
  .segment-actions {
    position: absolute;
    right: 10px;
    top: -25px;
    margin: 0;
    button {
      text-decoration: none !important;
    }
    @media screen and (max-width: 667px) {
      position: static;
    }
  }
 `;


export const CreateWrapper = styled.div`
  margin: 10px 20px;
  .field {
    margin-bottom: 20px;
    > input {
      width: 100%;
      border: none;
      border-bottom: 1px solid ${MediumGray};
      padding: 5px 10px;
      margin: 10px 0;
    }
    .error-msg {
      color: ${BitterSweet};
      display: block;
      width: 100%;
      font-size: 12px;
    }
  }
`

export const SelectSegmentWrapper = styled.div`
  min-height: 300px;
  .segment-name {
    cursor: pointer;
    //text-decoration: underline;
  }
`;
export const SegmentInfoForDialog = styled.div`
  min-height: 520px;
  .attrs {
    label {
      display: inline-block;
      width: 150px;
      margin: 5px 0;
    }
    strong {
      font-weight: normal;
    }
  }
`;

export const ControlBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  position: relative;
  .searchBox {
    flex-grow: 1;
    i {
      position: absolute;
      left: 10px;
      top: 10px;
    }
    input {
      padding: 8px 10px 8px 30px;
      font-size: 12px;
      border-radius: 5px;
      border: 1px solid ${MediumGray};
      background: ${LightGray};
      width: 100%;
      box-sizing: border-box;
      outline: none;
      &:focus {
        border-color: ${BlueJeans};
        box-shadow: inset 0 0 3px rgba(0,0,0,.1);
      }
    }
  }
  .select-box {
    margin-left: 20px;
    min-width: 150px;
    flex: 0;
  }
  button {
    margin-right: 0;
    margin-left: 10px;
  }
  @media screen and (max-width: 667px) {
    flex-wrap: wrap;
    .searchBox {
      width: 100%;
      margin-bottom: 5px;
    }
    .select-box {
      margin: 5px 0;
      width: 100%;
      .toggler {
        width: 100%;
      }
    }
  }
`;

