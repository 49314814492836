import styled, {css} from 'styled-components';
import {Dropdown} from "../uikit/index";
import Pagination from 'rc-pagination';
import {
  BitterSweet1, DarkGray1,
  BlueJeans, BlueJeans1, DarkGray, Grass, LightBlue, LightGray, LightGray1, MediumGray, MediumGray1,
  PrimaryDarkBlue, Grass1, Sunflower1
} from "../app/StyleCommon";
import Carousel from '../uikit/react-slider';

export const ContentHomeWrapper = styled.div`
  background-color: white;
  padding: 10px 20px;
  min-height: calc(100vh - 50px);
  width: 100%;
  box-sizing: border-box;
  @media screen and (max-width: 667px) {
    padding: 20px 10px;
  }
`;

export const FilterBarFirst = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 667px) {
    display: block;
    .layout-mode {
      display: none;
    }
    .create-btn {
      width: 100%;
      button {
        width: 100% !important;
      }
    }
  }
  .left, .right {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  h3 {
    margin-right: 30px;
  }
  .type-list {
    display: flex;
    flex-grow: 1;
    li {
      cursor: pointer;
      margin: 10px 10px 10px 0;
      padding: 5px 10px;
      border-radius: 5px;
      &.selected {
        color: ${BlueJeans1};
        background: #f1f3f6;
        font-weight: bold;
      }
    }
  }
  .create-btn {
    button {
      width: 180px;
    }
  }
`;

export const KeyMetrics = styled.div`
  display: flex;
  align-items: flex-start;
  border-left: 5px solid ${LightBlue};
  margin: 10px 0;
  &.no-border {
    border: none;
  }
  &.compact {
    margin: 15px 0 0px 0;
  }
  &.center-align {
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    .metric-item {
      margin: 0 0;
      background: #fafbfc;
      padding: 10px;
      width: 100%;
    }
  }
  .metric-item {
    margin: 10px 20px;
    text-align: left;
    background-color: ${LightGray};
    padding: 5px 15px;
    border-radius: 10px;
    min-width: 140px;
    .top {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      font-weight: bold;
      i {
        margin-right: 8px;
        color: ${MediumGray1};
      }
      img {
        align-self: center;
        width: 18px;
        margin-right: 10px;
        filter: grayscale(90);
        opacity: 0.4;
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      .value {
        font-size: 22px;
        font-weight: bold;
        color: ${Grass1};
        margin-right: 20px;
        span {
          font-size: 12px;
        }
      }
      .label {
        font-size: 14px;
        color: ${DarkGray1};
        margin: 5px 0;
        font-weight: bold;
        white-space: nowrap;
      }
      .desc {
        font-size: 12px;
        color: ${Grass1};
        font-weight: bold;
      }
    }

  }
`;

export const FilterDropdown = styled(Dropdown)`
  margin-right: 10px;
  .dropdown-toggle {
    width: 100px;
    border: 1px solid ${MediumGray};
    padding: 0;
    display: flex;
    justify-content: center;
    padding: 4px 10px;
    align-items: center;
    border-radius: 5px;
    background-color: ${LightGray};
    i {
      font-size: 16px;
      margin-right: 10px;
    }
    &:hover i {
      color: ${BlueJeans1};
    }
  }
  .menu {
    top: 40px;
    right: 0;
    left: auto;
    min-width: 200px;
    @media screen and (max-width: 667px) {
      left: 0;
    }
    h3 {
      margin: 0;
      padding: 10px 10px;
      font-weight: normal;
      border-bottom: 1px solid ${LightGray1};
    }
    .actions {
      padding: 10px;
    }
    .menu-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      >label {
        display: inline-block;
        width: 120px;
        margin-right: 10px;
        flex: none;
      }
      .select-box {
        flex-grow: 1;
        margin: 0;
        min-width: 80px;
        .toggler {
          border: none;
          position: relative;
          i {
            z-index: 1;
            margin-right: 0;
          }
          &:after {
            background-color: #F5F7FA;
            position: absolute;
            content: '';
            bottom: 0;
            top: 0;
            right: 0;
            width: 30px;
          }
        }
        .menu {
          label {
            white-space: normal !important;
            word-break: break-all;
          }
        }
      }
      .date-selector-dropdown {
        .toggler {
          display: flex;
          border: none;
          background: none;
          border-radius: 0;
          padding-left: 15px;
          padding-right: 40px;
          min-width: inherit;
          //border-bottom: 1px solid ${LightGray1};
          i {
            right: 10px;
          }
        }
        .dropdown-content {
          transform: translate(20px, -100%) translate(0px, -50px);
        }
        @media screen and (max-width: 667px) {
          .date-selector {
            position: fixed;
            border: 1px solid ${MediumGray};
            top: 60px;
            left: 10px;
            right: 10px;
            width: auto;
            flex-wrap: wrap;
            .month-panel {
              width: 100%;
            }
          }
          .dropdown-content {
            transform: none;
          }
        }
      }
    }
  }
`;

export const CreativeContainer = styled.div`
  cursor: pointer;
  //background-color: #f1f2f3;
  border-radius: 5px;
  white-space: nowrap;
  overflow:hidden;
  >span {
    display: inline-block;
    margin-bottom: 5px;
    margin-left: 5px;
    white-space: nowrap;
    max-width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  ${props => props.noImage && css`
    display: inline !important;
    color: ${BlueJeans1};
    margin: 0 5px;
    >span {
      display: inline !important;
    }
  `}
`

export const CreativeController = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 15px 0;
  position: relative;
  .searchBox {
    flex-grow: 1;
    margin-right: 20px;
    i {
      position: absolute;
      left: 10px;
      top: 10px;
    }
    input {
      padding: 6px 10px 6px 30px;
      font-size: 12px;
      border-radius: 5px;
      border: 1px solid ${MediumGray};
      background: ${LightGray};
      width: 100%;
      box-sizing: border-box;
      outline: none;
      &:focus {
        border-color: ${BlueJeans};
        box-shadow: inset 0 0 3px rgba(0,0,0,.1);
      }
    }
  }
  .select-box {
    max-width: 120px;
    margin-right: 10px;
    .toggler {
      border: 1px solid ${MediumGray};
      padding: 0;
      display: flex;
      padding: 4px 10px;
      align-items: center;
      border-radius: 5px;
      background-color: ${LightGray};
    }
  }
`;

export const CampaignController = CreativeController;

export const FilterTagWrapper = styled.div`
  display: flex;
  margin: 10px 0;
  .tag {
    padding: 5px 10px;
    background-color: ${LightGray};
    border: 1px solid ${MediumGray};
    margin: 5px 10px 10px 0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    strong{
      color: ${BlueJeans1};
      margin: 0 5px;
    }
    i {
      margin-left: 5px;
      font-weight: bold;
      cursor: pointer;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${MediumGray};
      &:hover {
        color: ${BlueJeans1};
      }
    }
  }
  @media screen and (max-width: 667px) {
    flex-wrap: wrap;
    .tag {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-right: 0;
    }
  }

`

export const CreativeModule = styled.div`
  .creative-name {
    display: flex;
    text-decoration: none;
    color: ${DarkGray1};
    .url {
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 11px;
    }
    img {
      max-height: 50px;
      max-width: 300px;
      margin-right: 5px;
    }
  }
  table {
  border-top: none;
   thead th {
    background: white;
    border: none;
    border-top: none;
    border-left: 1px solid white;
    border-right: 1px solid white;
    border-bottom: 1px solid ${MediumGray};
   }
 }
`;

export const CampaignModule = styled.div`
  .campaign-name {
    img {
      height: 50px;
      margin-right: 5px;
      border-radius: 5px;
      margin: 10px 10px 0 0;
    }
  }
  .creative-list {
    display: flex;
  }
  table {
   border-top: none;
   thead th {
    background: white;
    border: none;
    border-top: none;
    border-left: 1px solid white;
    border-right: 1px solid white;
    border-bottom: 1px solid ${MediumGray};
   }
 }
`;


export const StatusTag = styled.div`
  display: inline-block;
  vertical-align: middle;
  border-radius: 5px;
  color: ${DarkGray};
  padding: 1px 3px;
  font-weight: bold;
  font-size: 11px;
  min-width: 70px;
  font-weight: bold;
  ${props => String(props.status).toLowerCase() === 'delivering' && css`
     color: ${Grass1};
  `}
  ${props => String(props.status).toLowerCase() === 'optimising' && css`
     color: ${Grass1};
  `}
  ${props => String(props.status).toLowerCase() === 'ready' && css`
     color: ${BlueJeans1};
  `}
  ${props => String(props.status).toLowerCase() === 'paused' && css`
     color: ${Sunflower1};
  `}
  ${props => String(props.status).toLowerCase() === 'completed' && css`
     color: ${DarkGray};
  `}
  ${props => props.reversed && css`
     color: transparent;
     color: ${MediumGray1};
      color: #656D78;
      color: white;
      background-color: transparent;
      display: inline;
      margin: 0 5px;
  `}
  ${props => props.reversed && String(props.status).toLowerCase() === 'delivering' && css`
     color: ${Grass1};
  `}
  ${props => props.reversed && String(props.status).toLowerCase() === 'optimising' && css`
     color: ${Grass1};
  `}
  ${props => props.reversed && String(props.status).toLowerCase() === 'ready' && css`
     color: ${BlueJeans1};
  `}
  ${props => props.reversed && String(props.status).toLowerCase() === 'paused' && css`
     color: ${Sunflower1};
  `}
  ${props => props.reversed && String(props.status).toLowerCase() === 'completed' && css`
     color: ${DarkGray};
  `}
`;