import React from 'react'
import ReactDOM from 'react-dom';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import moment from 'moment';
import * as d3 from 'd3';
import {withConsumer} from '../app/ApplicationContext';
import CondirmDialog from '../uikit/dialog/index';
import {Btn, TabContainer, Table, ProgressBar, Loader} from '../uikit/index';
import {SelectStyled} from './select.style';
import {PaginationStyled, ErrorBox} from './index.style';
import {ThinkSelectorWrapper} from './thinkSelector.style';
import {BlueJeans} from "../app/StyleCommon";

export default withConsumer(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {data, error, loading, keyword} = this.state;
    const {thinkWidgets = []} = data || {};
    let filteredWidgets = thinkWidgets;
    if(!!keyword) {
      let keywordLowerCase = keyword.toLowerCase();
      filteredWidgets = thinkWidgets.filter(d => {
        let descLowerCase = (d.id || '').toLowerCase();
        return d.id === keyword || descLowerCase.indexOf(keywordLowerCase) >= 0
      });
    }
    return (
      <ThinkSelectorWrapper>
        {
          !data && !error &&
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/getThinkWidgets`}
            params={{site: this.props.site}}
            successHandler={data => {
              this.setState({data: data, loading: false, error: false});
            }}
            errorHandler={e => this.setState({error: e.message})}/>
        }
        {
          !!loading && <Loader transparent={true} type="absolute"/>
        }
        {
          !!error && <ErrorBox>{error}</ErrorBox>
        }
        <div className="searchBox">
          <i className="fa fa-search"/>
          <input
            type="text"
            placeholder="Search By Name, ID"
            value={keyword}
            onChange={e => this.setState({keyword: e.target.value})}/>
        </div>
        <div className="table-wrapper">
          {
            !!filteredWidgets.length &&
            <Table
              columns={[
                {
                  key: 'no', label: 'No', align: 'center',
                  renderer: (d, i) => i+ 1
                },
                {key: 'id', label: 'ID', align: 'left', renderer: (d) => {
                  return (
                    <div>
                      <div style={{fontSize: '11px'}}>{d.id}</div>
                    </div>
                  )
                }},
                {
                  key: 'actions', label: '', align: 'right',
                  renderer: d => {
                    return (
                      <Btn type="primary"
                           size="small"
                           className="selectBtn"
                           disabled={this.props.selected && this.props.selected.indexOf(d.id) >= 0}
                           onClick={e => this.props.onChange(d)}>
                        Select
                      </Btn>
                    );
                  }
                },
              ]}
              rows={filteredWidgets || []}
              noDataText={""}
            />
          }
        </div>
        <div className="actions">
          <Btn type="reset" onClick={e => this.props.onCancel()}>Cancel</Btn>
        </div>
      </ThinkSelectorWrapper>
    );
  }
})
