import React, {Component} from 'react';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import {withConsumer} from '../app/ApplicationContext';
import Notification from './notification';
import {MainWrapper, SideMenuWrapper, HeaderSection, UserMenuDropdown, BodyMask} from './index.style';
import {Welcome, LogoWrapper, SideMenuList, SideMenuItem, BodySection, Breadcrumb} from './index.style';
import menuData from './menu';
import {CondirmDialog, Loader} from '../uikit/index';
import createTooltip from '../uikit/tooltip/index';

// let cachedOpen = window.innerWidth >= 900;
let cachedOpen = window.innerWidth >= 900;
export default withConsumer(class extends Component {

  constructor(props) {
    super(props);
    this.state = {open: cachedOpen};
    this.tooltip = createTooltip({
      targetSelector: 'dynamic',
      tipCreator: d => `<strong>${d.label}</strong>`,
      directionFunc: 'right'
    });

    if(props.forceClose) {
      this.state.open = false;
    }
  }

  render() {
    const {activeItem, confirmInfo} = this.props;
    const {appState, loading} = this.props;
    const {session} = appState || {};
    const gender = (session.gender || 'male').toLowerCase();
    const {open} = this.state;
    const isMobile = window.innerWidth < 768;
    if (!session) {
      return (
        <MainWrapper>
          {this.props.children}
        </MainWrapper>
      );
    }

    return (
      <MainWrapper open={open}>
        {
          !!confirmInfo &&
          <CondirmDialog type="fixed" fullScreen={true} {...confirmInfo} />
        }
        <HeaderSection id="header">
          <div>
            {this.renderBreadcrumb(this.props.breadcrumb)}
          </div>
          <div className="right-side">
            {/*<Notification listerner={null}/>*/}
            <UserMenuDropdown toggler={
              <div>
                <img src={`/img/avatar-${gender}.png`} alt="avatar"/>
                <i className="caret fa fa-ellipsis-v"/>
              </div>
            }>
              <div className="user-info">
                <div className="name">{session.name}</div>
                <div className="email">{session.userId}</div>
                <div className={'desc'}>({session.isAdmin ? 'Admin user' : 'Normal User'})</div>
              </div>
              {
                session.userId === 'demo@mediacorp.com.sg' &&
                <div className="menu-item">
                  <Link to="/simulate/home"><i className="fa fa-clock-o"/>Mewatch Simulator</Link>
                </div>
              }

              <div className="menu-item">
                <Link to="/settings"><i className="fa fa-clock-o"/>Settings</Link>
              </div>
              <div className="menu-item">
                <a onClick={e => this.props.logout()}><i className="fa fa-sign-out"/>Sign Out</a>
              </div>
            </UserMenuDropdown>
          </div>
        </HeaderSection>
        <SideMenuWrapper id="sidebar">
          <LogoWrapper>
            <a onClick={e => {
              this.setState({open: !open}, () => {
                cachedOpen = this.state.open;
              })
              setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
              }, 100)
              setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
              }, 300)
              setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
              }, 500)
            }}>
              <i className="fa fa-bars"/>
            </a>
            {/*<span>BOOST Recommend</span>*/}
            <img src={`/img/logo1.png?v=20221004`} style={{width: '100px'}}/>
          </LogoWrapper>
          {
            !!session.isAdmin &&
            <Welcome>
              {/*<img src={`/img/user.png`} alt="avatar"/>*/}
              <img src={`/img/avatar.png?v=20220926`} alt="avatar"/>
              <div className="name">{session.name}</div>
              <div className="email">{session.userId}</div>
            </Welcome>
          }
          <SideMenuList activeItem={activeItem}>
            {menuData.filter(d => {
              return d.key !== 'admin' || session.isAdmin;
            }).filter(d => {
              return d.key !== 'sponsored_ads' || session.isExternal;
            }).filter(d => {
              return d.key !== 'widget' || session.isAdmin;  // operator don't need them see widget level
            }).filter(d => {
              if(d.key === 'simulator') {
                console.log(process.env.NODE_ENV);
                return window.location.hostname !== 'boost-recommend.mediacorp.sg' && (session.isAdmin || session.userId === 'demo@mediacorp.com.sg');
              } else {
                return true;
              }
            }).map(item => {
              return (
                <SideMenuItem
                  key={item.key}
                  className={item.key}
                  selected={activeItem === item.key}
                  to={item.href}
                  onMouseOver={(!open && !isMobile) ? this.tooltip.onMouseOver(item) : undefined}
                  onMouseLeave={(!open && !isMobile) ? this.tooltip.onMouseOut() : undefined}
                  onClick={e => {
                    if(isMobile && open) {
                      cachedOpen = false;
                      this.setState({open: false});
                    }
                    if (item.key === 'logout') {
                      e.stopPropagation();
                      e.preventDefault();
                      this.props.logout();
                    }
                  }}>
                  {item.icon}
                  <span>{item.label}</span>
                </SideMenuItem>
              );
            })}
          </SideMenuList>
        </SideMenuWrapper>
        {
          window.innerWidth < 1000 &&
          <BodyMask visible={open} onClick={e => {
            this.setState({open: !open});
          }}/>
        }
        <BodySection id="main">
          {
            (appState.loading || this.props.loading) && <Loader/>
          }
          {this.props.children}
        </BodySection>
      </MainWrapper>
    );
  }

  renderBreadcrumb(data) {
    return (
      <Breadcrumb>
        <li><Link to='/'><i className="fa fa-home"/>{!data && <span>&nbsp;&nbsp;Home</span>}</Link></li>
        {
          (data || []).map((item, index) => {
            return (
              <React.Fragment key={index}>
                {
                  index < data.length &&
                  <li className="seperator"><i className="fa fa-angle-right"/></li>
                }
                <li><Link to={item.path}>{item.label}</Link></li>
              </React.Fragment>
            )
          })
        }
      </Breadcrumb>
    )
  }
});
