import _ from 'lodash';
import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group'

export default (props) => {
  const {wrapper, withoutInnerWrapper, children, effect='fade'} = props;
  return (
    <CSSTransitionGroup
      transitionName={effect}
      transitionEnterTimeout={500}
      transitionAppearTimeout={500}
      transitionLeave={false}
      transitionAppear={true}
      component={wrapper || 'span'}
      {...(_.omit(props, ['withoutInnerWrapper']))}>
      {!!withoutInnerWrapper && children}
      {!withoutInnerWrapper && <span>{children}</span>}
    </CSSTransitionGroup>
  );
}