import React from 'react';
import styled, {css} from 'styled-components';
import {
  BitterSweet,
  BitterSweet1,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass, Grass1, LightBlue, LightGray, LightGray1, MediumGray,
  MediumGray1, Sunflower1
} from "../app/StyleCommon";
import {Switch} from '../uikit/index';

export const RuleEditorWrapper = styled.div`
  position: relative;
  margin-top: -20px;
  margin-bottom: 20px;
  min-height: 400px;
  .errors {
    position: absolute;
    bottom: -45px;
  }
  .actions {
    text-align: right;
    margin-top: 20px;
    .selectBtn {

    }
  }
  .table-wrapper {
    margin-top: 10px;
    max-height: 350px;
    min-height: 100px;
    overflow: auto;
    table {
      td {
        padding: 10px 10px;
        font-size: 12px;
      }
    }
  }

  .rc-pagination li{
    min-width: 24px;
    height: 24px;
    line-height: 24px;
  }

  .searchBox {
    flex-grow: 1;
    margin-top: 40px;
    i {
      position: absolute;
      left: 10px;
      top: 10px;
    }
    input {
      padding: 8px 10px 8px 30px;
      font-size: 12px;
      border-radius: 5px;
      border: 1px solid ${MediumGray};
      background: ${LightGray};
      width: 100%;
      box-sizing: border-box;
      outline: none;
      &:focus {
        border-color: ${BlueJeans};
        box-shadow: inset 0 0 3px rgba(0,0,0,.1);
      }
    }
  }
`

export const RuleRendererWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    .rule-instance-action,
    .rule-action {
      opacity: 1;
    }
  }
  .rule-instance {
    line-height: 1.2;
    display: flex;
    align-items: center;
    margin: 10px 0;
    //font-weight: bold;
    &.disabled {
      color: ${DarkGray1};
      font-weight: normal;
    }
  }
  .rule-action,
  .rule-instance-action {
    opacity: 0.3;
    cursor: pointer;
    color: ${MediumGray1};
    margin-left: 15px;
    &:hover {
      color: ${BlueJeans1};
    }
  }
  .select-box {
    margin: 0 5px;
    .toggler {
      padding: 0;
      border: 0;
      padding-right: 30px;
      min-width: inherit;
      width: auto;
      border-bottom: 1px dotted ${MediumGray1};
      border-radius: 0;
      background: transparent;
      font-size: 12px;
      white-space: normal;
      //text-transform: lowercase;
      min-width: 80px;
      i {
        display: inline-block !important;
        right: 4px;
      }
      .default-label {
        color: ${MediumGray1};
      }
    }
    .menu {
      top: 20px;
      width: auto;
      right: auto;
      min-width: 150px;
      max-height: 250px;
      overflow: auto;
    }
  }
  .rule-input{
    padding: 0px 5px 0px 5px;
    margin: 0 5px;
    border: none;
    border-bottom: 1px dotted ${MediumGray};
    outline: none;
    font-size: 12px;
    width: 50px;
    text-align: center;
    background: transparent;
    &:active {
      border-bottom-color: ${BlueJeans};
    }
    &:disabled {
      color: ${MediumGray1};
    }
  }
`

export const SwitchForTable = styled(Switch)`
  vertical-align: middle;
  * {
    cursor: pointer !important;
  }
  input[type=checkbox] {
    width: 46px;
    height: 20px;
    & + span::before {
      background-color: ${LightGray1};
    }
    & + span::after {
      height: 15px;
      width: 22px;
      font-weight: normal;
      font-size: 9px;
    }
    &:checked + span::after {
      left: 22px;
    }
  }
`;

export const AjaxLoader = styled.div`
  .error {
    color: red;
    padding: 10px;
    font-weight: normal;
  }
  .loading {
    padding: 10px;
    color: ${MediumGray1};
  }
  .btn {
    padding: 10px;
    background-color: ${LightGray};
    cursor: pointer;
    text-align: left;
    min-width: 100px;
    &:hover {
      background-color: ${LightGray1};
    }
  }
`;