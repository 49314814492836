import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import MonthPanel from './MonthPanel';
import {DateSelectorWrapper} from './index.style';

export default class extends React.Component {
  static defaultProps = {
    onDateRangeUpdated: period => null
  }

  constructor(props) {
    super(props);
    const {period, mode} = props;
    this.state = {
      period: period,
      selecting: [], //date stack, can be empty|one date | two dates.
      panelMonth1: period ? period.start : moment().format('YYYY-MM-DD'), //the month showing in the left month panel
      panelMonth2: period ? period.end : moment().format('YYYY-MM-DD'), //the month showing in the right month panel
      dragingDates: []
    }
    if (mode === 'single' && !!period) {
      this.state.period = _.omit(period, ['end']);
    } else if (!!period) {
      const {panelMonth1, panelMonth2} = this.state;
      if (String(panelMonth1).substring(0, 7) === String(panelMonth2).substring(0, 7)) {
        this.state.panelMonth1 = moment(panelMonth1).add(-1, 'month');
      }
    }
  }


  componentWillReceiveProps(props) {
    const {period, mode} = props;
    let newState = {
      period: period,
      selecting: [],
      panelMonth1: period.start,
      panelMonth2: period.end,
    };
    if (mode === 'single' && period) {
      newState.period = _.omit(period, ['end']);
    } else if (!!period) {
      const {panelMonth1, panelMonth2} = newState;
      if (String(panelMonth1).substring(0, 7) === String(panelMonth2).substring(0, 7)) {
        newState.panelMonth2 = moment(panelMonth2).add(1, 'month');
      }
    }
    this.setState(newState);
  }

  render() {
    const {mode} = this.props;
    return (
      <DateSelectorWrapper className={`${this.props.className} ${mode}`}>
        <MonthPanel key="month1"
                    name={mode === 'single' ? 'panel' : 'firstPanel'}
                    boundry={this.state.panelMonth2}
                    futureDateAllowed={false}
                    historyDateAllowed={true}
                    onMonthUpdated={date => this.onMonthUpdated(date, true)}
                    onDateSelected={date => this.onDateSelected(date)}
                    onDragSelection={this.onDragSelection.bind(this)}
                    dragingDates={this.state.dragingDates}
                    date={this.state.panelMonth1}
                    period={this.state.period}>

        </MonthPanel>
        {
          mode !== 'single' &&
          <MonthPanel key="month2"
                      name="secondPanel"
                      boundry={this.state.panelMonth1}
                      futureDateAllowed={false}
                      historyDateAllowed={true}
                      onMonthUpdated={date => this.onMonthUpdated(date, false)}
                      onDateSelected={date => this.onDateSelected(date)}
                      onDragSelection={this.onDragSelection.bind(this)}
                      dragingDates={this.state.dragingDates}
                      date={this.state.panelMonth2}
                      period={this.state.period}>

          </MonthPanel>
        }
        {/*<i className="fa fa-bolt" aria-hidden="true"></i>*/}
      </DateSelectorWrapper>
    );
  }

  onDateSelected(date) {
    const {mode = 'range'} = this.props;
    var selecting = this.state.selecting;

    if (mode === 'single') {
      let newPeriod = {
        start: date, end: undefined, mode: 'single'
      };
      this.setState({period: newPeriod});
      this.props.onDateRangeUpdated(newPeriod);
    } else {
      if (selecting.length === 2) {
        selecting = [];
      }
      selecting.push(date);
      selecting.sort((date1, date2) => date1 > date2 ? 1 : -1);
      let newPeriod = {
        start: selecting[0],
        end: selecting[1]
      };
      this.setState({
        selecting: selecting,
        period: newPeriod
      });
      if (selecting.length === 2) {
        this.props.onDateRangeUpdated(newPeriod);
        this.setState({period: newPeriod});
      } else {
        this.onDragSelectionStart(date);
      }
    }
  }

  onMonthUpdated(date, isMonth1) {
    this.setState({
      [isMonth1 ? 'panelMonth1' : 'panelMonth2']: date
    });
  }

  onDragSelection(date, phase) {
    const {mode = 'range'} = this.props;
    if (mode === 'single') {
      return;
    }
    var capitalized = phase[0].toUpperCase() + phase.slice(1);
    this['onDragSelection' + capitalized](date);
  }

  onDragSelectionStart(date) {
    const {mode = 'range'} = this.props;
    if (mode === 'single') {
      return;
    }
    //sometimes mouse up outside of the dateselector.
    this.dragStarted = true;
    let dragingDates = [date];
    this.setState({dragingDates});
  }

  onDragSelectionUpdate(date) {
    let {dragingDates} = this.state;
    if (dragingDates.length) {
      dragingDates.push(date);
      dragingDates = [dragingDates[0], date];
      this.setState({dragingDates});
    }
  }

  onDragSelectionEnd(e) {
    let {dragingDates} = this.state;
    if (dragingDates.length === 0) {
      return;
    } else if (dragingDates.length === 1) {
      //a short dragging selection. abort it.
      this.setState({dragingDates: []});
    } else {
      //finish the dragging.
      let [start, end] = [dragingDates[0], dragingDates[dragingDates.length - 1]].sort();
      this.setState({
        selecting: [],
        dragingDates: [],
        period: {start, end}
      });
      this.props.onDateRangeUpdated({start, end});
    }
  }

  componentWillMount() {
    document.addEventListener('mouseup', this.onDragSelectionEnd.bind(this), true)
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.onDragSelectionEnd.bind(this), true);
  }
}