import React from 'react';
import styled, {css} from 'styled-components';

const SvgWrapper = styled.svg`
  path {
    color:#000000;
    fill:#000000;
    fill-opacity:1;
    fill-rule:nonzero;
    stroke:none;
    stroke-width:2;
    marker:none;
    visibility:visible;
    display:inline;
    overflow:visible;
    enable-background:accumulate
  }
  
`

export default props => {
  return (
    <SvgWrapper xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" {...props}>
      <g transform="translate(0,-952.362)">
        <path d="m 31.250002,964.86205 c -2.083334,0 -4.166667,2.0833 -4.166667,4.1667 l 0,14.5833 0,6.25 c 0,4.1667 4.166667,4.1667 4.166667,8.3333 l 0,37.50005 c 0,2.0833 2.083333,4.1666 4.166666,4.1666 l 4.166667,0 c 2.083333,0 4.166667,-2.0833 4.166667,-4.1666 l 0,-37.50005 c 0,-4.1666 4.166666,-4.1666 4.166666,-8.3333 l 0,-6.25 0,-2.0833 0,-12.5 c 0,-2.0834 -2.083333,-4.1667 -4.166666,-4.1667 -1.041667,0 -1.041667,1.2704 -1.041667,2.0833 l 0,16.6667 c 0,0.8129 0,2.0833 -1.041667,2.0833 -1.041666,0 -1.041666,-1.2704 -1.041666,-2.0833 l 0,-16.6667 c 0,-0.8129 0,-2.0833 -1.041667,-2.0833 l -1.041667,0 -3.125,0 c -1.041666,0 -1.041666,1.2704 -1.041666,2.0833 l 0,16.6667 c 0,0.8129 0,2.0833 -1.041667,2.0833 -1.041667,0 -1.041667,-1.2704 -1.041667,-2.0833 l 0,-16.6667 c 0,-0.8129 0,-2.0833 -1.041666,-2.0833 z m 41.666663,0 c -4.16666,0 -11.94808,0.9796 -14.58333,6.25 -2.083333,4.1667 -2.083333,14.5833 -2.083333,18.75 l 0,16.66665 c 0,2.0834 2.083333,4.1667 4.166667,4.1667 l 0,25 c 0,2.0833 2.083333,4.1666 4.166666,4.1666 l 4.16667,0 c 2.08333,0 4.16666,-2.0833 4.16666,-4.1666 z"/>
      </g>
    </SvgWrapper>
  )
}
