import React from 'react';
import styled, {css} from 'styled-components';
import {
  BitterSweet,
  BitterSweet1,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass, Grass1, LightBlue, LightGray, LightGray1, MediumGray,
  MediumGray1, Sunflower1
} from "../app/StyleCommon";

export const SlotAllocatorWrapper = styled.div`
  position: relative;
  min-width: 380px;
  max-width: 80vw;
  @media screen and (max-width: 667px) {
    min-width: auto;
    .table-wrapper {
      padding: 10px 0 !important;
      display: block !important;
    }
  }
  .auto-ranking {
    margin: 10px 0;
    display: flex;
    align-items: center;
    @media screen and (max-width: 667px) {
      display: block;
      .select-box{
        width: 90%;
        margin: 10px 0 !important
        .toggler {
          width: 100%;
        }
      }

    }
    input {
      margin-right: 10px;
    }
    .select-box {
      margin: 0 6px;
      .toggler {
        padding: 3px 20px 3px 5px;
        min-width: 180px;
      }
      .menu {
        transform: translate(0, -100%) translate(0, -30px);
        right: 0;
        left: auto;
      }
    }
  }
  .more-grid-cells {
    margin: 10px 0;
    input {
      margin-right: 10px;
    }
  }
  .slot-allocator {
    max-height: 66.8vh;
    overflow-y: auto;
    .table-wrapper {
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 20px 50px;
      //overflow: hidden;
      &:hover .table-actions {
        opacity: 1;
      }
      .table-actions {
        margin-right: -50px;
        opacity: 0;
        display: flex;
        align-items: center;
        transition: all .3s ease;
        transition-delay: .2s;
        &.hidden {
          visibility: hidden;
        }
        .table-action {
          display: inline-block;
          cursor: pointer;
          margin: 0 8px;
          font-size: 11px;
          color: ${BlueJeans};
          &:hover {
            culor: ${BlueJeans1};
          }
        }
      }
    }
  }

  .actions {
    text-align: right;
    margin-top: 20px;
    .selectBtn {

    }
  }
`

export const GridSelector = styled.table`
  box-sizing: border-box;
  border-collapse: collapse;
  border-spacing: 0px;
  border: 1px solid ${MediumGray1};
  //margin-left: 50px;
  .select-box {
    .menu {
      let: 0;
      right: auto;
      min-width: 250px;
      //right: auto !important;
      //width: auto !important;
    }
  }
  tr {
    border: 1px solid ${MediumGray}
    td {
      border: 1px solid ${MediumGray}
      height: 20px;
    }
    &.active td.active {
      border: 1px solid #57b8ff;
      background: ${LightBlue};
    }
    td.main {
      background: ${LightBlue};
      height: 60px;
      width: 60px;
    }
    td.thumbnail {
      width: 40px;
      font-size:10px;
      padding: 0 10px;
    }
    &.hover td.hover {
      background: #cbe5ff;
    }
  }
`;
export const SlotGroupRedirect = styled.table`
  cursor: pointer;
  margin: 15px 20px;
  .click-hint {
    font-size: 11px;
    visibility: hidden;
  }
  &:hover{
    color: ${BlueJeans};
    .click-hint {
      visibility: visible;
    }
  }
`;

export const SlotSelector = styled.div`
  padding: 15px 20px;
  position: relative;
  .actions {
    position: absolute;
    right: 0px;
    bottom: 0px;
    opacity: 0;
    display: flex;
    align-items: center;
    transition: all .3s ease;
    transition-delay: .2s;
    //&.active {
    //  opacity: 1;
    //}
    .cell-action {
      display: inline-block;
      cursor: pointer;
      margin: 0 8px;
      font-size: 11px;
      color: ${BlueJeans};
      &:hover {
        transform: scale(1.2);
        culor: ${BlueJeans1};
      }
    }
  }
  .autoRankingRipple {
    background-position: 0 100%;
    background-size: 100% 1px;
    padding-bottom: 2px;
    background-repeat: no-repeat;
  }
  &:hover .actions {
    opacity: 1;
  }
`;
export const SlotItemRenderer = styled.div`
  width: 100%;
  padding: 5px 0;
  @media screen and (max-width: 667px) {
    max-width: calc(100vw - 130px);
  }
  ${props => props.type === 'action' && css`
    padding: 10px 0 5px 0;
  `}
  &:hover .name .slot-group-actions {
    opacity: 1;
  }
  .name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .slot-group-actions {
      opacity: .5;
      transition: all .3s ease;
      transition-delay: .2s;
      @media screen and (max-width: 667px) {
        opacity: 1;
      }
      .action-item {
        padding: 3px 5px;
        cursor: pointer;
        color: ${BlueJeans1};
        display: inline-block;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
  .desc {
    font-size: 10px;
    color: ${MediumGray1};
    white-space: normal;
    word-break: break-all;
  }
`;