import React, {Component, useEffect} from 'react';
import ReactServer from 'react-dom/server';
import createTooltip from '../uikit/tooltip/index';
import * as echarts from 'echarts';
import classnames from 'classnames';
import moment from 'moment';
import styled from 'styled-components';
import _ from 'lodash';
import {ReRenderHook} from '../utils/hooks';
import {
  BitterSweet,
  BitterSweet1,
  BlueJeans, BlueJeans0,
  BlueJeans1, colorCategory1, DarkGray, GrapeFruit, Grass,
  Grass1,
  LightBlue,
  LightGray,
  LightGray1, MediumGray, MediumGray1,
  Sunflower,
  Sunflower1
} from "../app/StyleCommon";
import {
  filterTags,
  intFormatter,
  pct0Formatter,
  pct2Formatter,
  pctFormatter,
  responseTimeFormatter,
  uniquesFormatter
} from "../utils/formatter";
import {Table, TableScrollWrapper} from "../uikit";
import {BarWrapper} from "../home/index.style";
import Sparkline3 from "./sparkline3";
import {Card, CardTitle, PaginationStyled, TopWidgets} from "./index.style";
import {Link} from "react-router-dom";
import {Taglist} from "../widgets/index.style";


const MetricWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${LightGray};
  padding: 0 5px;
  .trend {
    text-align: right;
  }
  .marker {
    width: 10px;
    height: 8px;
    margin-left: 4px;
    margin-right: 6px;
    max-width: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    &.gray {
      filter: grayscale(100);
    }
    &.revert {
      transform: rotate(180deg);
      transform-origin: 50% 50%;
    }
  }
`

export default class extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openMap: {},
      openSite: 'cna'
    };
  }

  render() {
    const {data, config, period} = this.props;
    const {
      total,
      items,
      maxCtr,
      maxClicks,
      maxImpressions,
      maxResponseTime,
      maxRrrorRate
    } = data || {};
    const {twSortBy, twSortDirection, twPage} = config || {}
    const today = moment().format('YYYY-MM-DD');
    const yesterday = moment().add(-1, 'day').format('YYYY-MM-DD');
    return (
      <TopWidgets>
        <TableScrollWrapper className="middle">
          <Table
            columns={[
              {
                key: 'content',
                label: 'Widget Name',
                renderer: d => {
                  return (
                    <div className="widget-info">
                      <div className="top">
                        <img className="publisher" src={`/img/property/${d.site}.png`} style={{width: '16px'}}></img>
                        <Link className="title" to={`/abtesting/${d.id}`}>
                          <span style={{marginRight: '6px'}}>{d.name}</span>
                        </Link>
                      </div>
                      <div>
                        <Taglist className="tag-list"  style={{marginLeft: '15px'}}>
                          {filterTags(d.tags, 'abtesting').map(t => {
                            return <span className={`tag ${t.split('::').join(' ')} `} key={t}>{t.split('::').pop()}</span>
                          })}
                          {!filterTags(d.tags, 'abtesting').length && '--'}
                        </Taglist>
                        {/*<span>{d.variants.length} variants</span>*/}
                      </div>
                    </div>
                  )
                }
              },
              {
                key: 'impressions',
                label: 'Impressions',
                align: 'left',
                // style: {minWidth: '100px'},
                sortable: true,
                renderer: d => {
                  let adjustPeriod = period;
                  let adjustTrending = d.trending.map(item => item.impressions || 0);
                  let lastDate = (d.trending[d.trending.length -1] || {}).date;
                  if(lastDate === today && adjustTrending.length > 2) {
                    adjustPeriod = {end: yesterday};
                    adjustTrending.pop();
                  }
                  return (
                    <MetricWrapper>
                      <BarWrapper className="small">
                        <div className="num">{uniquesFormatter(d.impressions)}</div>
                        <div className="bar"
                             style={{width: pctFormatter(d.impressions / data.maxImpressions)}}></div>
                      </BarWrapper>
                      <Sparkline3
                        period={adjustPeriod}
                        hideVisualMap={true}
                        data={adjustTrending}
                        formatter={uniquesFormatter}/>
                      <div className="trend">
                        <img className='marker gray' src={`https://ripple.mediacorp.sg/img/arrow3/${d.parts.a.impressions < d.parts.b.impressions ? 'up' : 'down'}.png`}/>
                        {pctFormatter((d.parts.b.impressions - d.parts.a.impressions) / d.parts.a.impressions)}
                      </div>

                    </MetricWrapper>
                  )
                }
              },
              {
                key: 'clicks',
                label: 'Clicks',
                align: 'left',
                // style: {minWidth: '100px'},
                sortable: true,
                renderer: d => {
                  let adjustPeriod = period;
                  let adjustTrending = d.trending.map(item => item.clicks || 0);
                  let lastDate = (d.trending[d.trending.length -1] || {}).date;
                  if(lastDate === today && adjustTrending.length > 2) {
                    adjustPeriod = {end: yesterday};
                    adjustTrending.pop();
                  }
                  return (
                    <MetricWrapper>
                      <BarWrapper className="small">
                        <div className="num">{uniquesFormatter(d.clicks)}</div>
                        <div className="bar"
                             style={{width: pctFormatter(d.clicks / data.maxClicks)}}></div>
                      </BarWrapper>
                      <Sparkline3
                        period={adjustPeriod}
                        hideVisualMap={true}
                        data={adjustTrending}
                        formatter={uniquesFormatter}/>
                      <img className='marker' src={`https://ripple.mediacorp.sg/img/arrow3/${d.parts.a.clicks < d.parts.b.clicks ? 'up' : 'down'}.png`}/>
                      <div>{pctFormatter((d.parts.b.clicks - d.parts.a.clicks) / d.parts.a.clicks)}</div>

                    </MetricWrapper>
                  )
                }
              },
              {
                key: 'ctr',
                label: 'CTR',
                align: 'left',
                // style: {minWidth: '100px'},
                sortable: true,
                renderer: d => {
                  console.log(d.parts.a, d.parts.b);
                  return (
                    <MetricWrapper>
                      <BarWrapper className="small">
                        <div className="num">{pct2Formatter(d.ctr)}</div>
                        <div className="bar"
                             style={{width: pct2Formatter(d.ctr / data.maxCtr)}}></div>
                      </BarWrapper>
                      <Sparkline3
                        hideVisualMap={true}
                        data={d.trending.map(item => item.ctr || 0)}
                        formatter={pct2Formatter}/>
                      <img className='marker' src={`https://ripple.mediacorp.sg/img/arrow3/${d.parts.a.ctr < d.parts.b.ctr ? 'up' : 'down'}.png`}/>
                      <div>{pctFormatter((d.parts.b.ctr - d.parts.a.ctr) / d.parts.a.ctr)}</div>
                    </MetricWrapper>
                  )
                }
              },
              {
                key: 'response_time',
                label: 'Avg Response Time',
                align: 'left',
                // style: {minWidth: '100px'},
                sortable: true,
                renderer: d => (
                  <MetricWrapper>
                    <BarWrapper className="small">
                      <div className="num">{responseTimeFormatter(d.response_time)}</div>
                      <div className="bar"
                           style={{width: pctFormatter(d.response_time / data.maxResponseTime)}}></div>
                    </BarWrapper>
                    <Sparkline3
                      hideVisualMap={true}
                      data={d.trending.map(item => item.response_time || 0)}
                      formatter={responseTimeFormatter}/>
                    <img className='marker revert' src={`https://ripple.mediacorp.sg/img/arrow3/${d.parts.a.response_time < d.parts.b.response_time ? 'down' : 'up'}.png`}/>
                    <div>{pctFormatter((d.parts.b.response_time - d.parts.a.response_time) / d.parts.a.response_time)}</div>
                  </MetricWrapper>
                )
              },
              // {
              //   key: 'errors',
              //   label: 'Timeouts',
              //   align: 'left',
              //   // style: {minWidth: '100px'},
              //   sortable: true,
              //   renderer: d => (
              //     <MetricWrapper>
              //       <BarWrapper className="small">
              //         <div className="num">{pct2Formatter(d.error_rate)}</div>
              //         <div className="bar"
              //              style={{width: pctFormatter(d.error_rate / data.maxRrrorRate)}}></div>
              //       </BarWrapper>
              //       <Sparkline3
              //         hideVisualMap={true}
              //         data={d.trending.map(item => item.error_rate || 0)}
              //         formatter={pct2Formatter}/>
              //       <img className='revert marker' src={`https://ripple.mediacorp.sg/img/arrow3/${d.parts.a.error_rate < d.parts.b.error_rate ? 'down' : 'up'}.png`}/>
              //       <div>{pctFormatter((d.parts.b.error_rate - d.parts.a.error_rate) / d.parts.a.error_rate)}</div>
              //     </MetricWrapper>
              //   )
              // },
            ]}
            rows={items || []}
            sort={{sortColumn: twSortBy, direction: twSortDirection}}
            sortChange={orderBy => {
              this.props.refreshChart('topABTestings', {'twSortBy': orderBy})
            }}
            directionChange={direction => {
              this.props.refreshChart('topABTestings', {'twSortDirection': direction})
            }}
            noDataText={"No Widgets Found"}
          />
        </TableScrollWrapper>
        <PaginationStyled
          onChange={p => this.props.refreshChart('topABTestings', {twPage: p})}
          current={twPage}
          pageSize={5}
          total={total}
          hideOnSinglePage={true}/>
      </TopWidgets>
    )
  }
}