import React from 'react';
import styled, {css} from 'styled-components';
import {
  Aqua,
  BitterSweet,
  BitterSweet1,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass, Grass1, LightBlue, LightGray, LightGray1, MediumGray,
  MediumGray1, Mint, Sunflower1
} from "../app/StyleCommon";

export const CreativeWrapper = styled.div`
  padding: 20px 10px;
  .page-header {
    margin: 10px 20px 30px 20px;
  }
`
export const UploadWrapper = styled.div`
  margin: 10px 20px;
  .field-grid {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .field {
      width: 50%;
      &:first-child {
        margin-right: 50px;
      }
    }
    @media screen and (max-width: 667px) {
      flex-wrap: wrap;
      .field {
        width: 100%;
        margin-right: 0 !important;
      }
    }
  }
  .field {
    margin-bottom: 20px;
    &.error > input,
    &.error .select-box .toggler{
      border-bottom: 1px dashed ${BitterSweet1}
    }
    &.error .dropzone-wrapper {
      border: 1px dashed ${BitterSweet};
    }
    > input {
      width: 100%;
      border: none;
      border-bottom: 1px solid ${MediumGray};
      padding: 5px 10px;
      margin: 10px 0;
    }
    .select-box {
      margin: 10px 0;
    }
    .error-msg {
      color: ${BitterSweet};
      display: block;
      width: 100%;
      font-size: 12px;
      margin-top: -5px;
    }
  }
`;
export const DropzoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  >div {
    overflow: hidden;
    cursor: pointer;
    min-height: 120px !important;
    height: auto !important;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #818181;
    background-color: rgba(0,0,0,.01);
    border-color: rgb(229, 222, 222) !important;
    position: relative;
    &:hover {
      background-color: ${LightGray1};
    }
    p {
      max-width: 280px;
      text-align: center;
    }
    .droped-file {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      word-break: break-all;
      i {
        margin-right: 10px;
      }
      img {
        display: inline-block;
        vertical-align: middle;
        margin-right: 25px;
      }
    }
    .uploadIcon {
      margin-right: 10px;
    }
  }
`;

