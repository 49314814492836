import React from 'react';
import styled, {css} from 'styled-components';
import {
  BitterSweet1,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass1, LightGray, LightGray1, MediumGray,
  MediumGray1, LightBlue
} from "../../app/StyleCommon";

import {WidgetWrapper} from './grid.style';

export const WidgetWrapper8World = styled(WidgetWrapper)`
  ${props => props.column === 1 && css`
    &&& {
      font-family: Noto Sans SC,Noto Sans,Arial,sans-serif;
      .widget-title {
        font-size: 24px;
        line-height: 1.3;
        font-weight: 700;
        color: #333;
        position: relative;
        padding: 0 70px 0 0;
        margin-bottom: 15px;
        display: inline-block;
        &:before {
          content: '';
          position: absolute;
          bottom: 50%;
          transform: translateY(50%);
          width: 7px;
          height: 7px;
          border-radius: 50%;
          left: 100%;
          background-color: #2062af;
        }
        &:after {
          content: "";
          position: absolute;
          bottom: 50%;
          transform: translateY(50%);
          height: 3px;
          display: block;
          width: 60px;
          left: calc(100% - 65px);
          background-color: #2062af;
        }
      }
      .item-list {
        padding: 0;
        .item {
          padding-left: 0;
          padding-right: 0;
          &:not(:last-child) {
            border-bottom: 1px solid #eaedf3;
          }
          .item-inner  {
            margin-left: 0;
            margin-right: 0;
            padding-left: 0;
            padding-right: 0;
            .thumbnail {
              border-radius: 5px;
            }
            .publish_date {
              display: block;
              text-decoration: none;
            }
            .title {
              max-height: 2.6em;
              line-height: 1.3em;
              overflow: hidden;
               text-overflow: ellipsis;
               display: -webkit-box;
               -webkit-box-orient: vertical;
               -webkit-line-clamp: 2;
            }
            .section {
              display: block;
              font-size: 12px;
              color: #a12528;
            }
          }
        }
      }
      @media (max-width: 968px) {
       .item-list .item.thumbnail_left .item-inner .thumbnail,
        .item-list .item.thumbnail_right .item-inner .thumbnail{
          width: 98.91px;
          height: 64px;
          padding: 0;
          margin-right: 10px;
       }
      }
      @media (min-width: 968px) {
       .item-list .item.thumbnail_left .item-inner .thumbnail,
        .item-list .item.thumbnail_right .item-inner .thumbnail{
          width: 165.39px;
          height: 93.03px;
          padding: 0;
          margin-right: 20px;
       }
      }
      ${props => props.mediaWidth < 320 && css`
        .item-list .item.thumbnail_left .item-inner .thumbnail {
            width: 30%;
            height: 0;
            padding-bottom: 18%;
         }`
      }
    }
  `}
`