import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {StyledTable} from "./index.style";
import {Transition} from '../index';
import Sortable from '../Sortable/index';

const tdbody = styled.tbody``;

export default class extends React.Component {
  render() {
    const {
      headerGroups,
      columns,
      rows = [],
      sort: {sortColumn, direction} = {},
      noDataText = 'No Data',
      sortChange,
      directionChange,
      placeHolderRowCount,
      rowClicker,
      rowSortable
    } = this.props;
    let placeHolderRows = [];
    if (placeHolderRowCount && (placeHolderRowCount - rows.length) > 0) {
      placeHolderRows = _.range(0, placeHolderRowCount - rows.length);
    }
    const TBody = rowSortable ? Sortable : tdbody;
    return (
      <StyledTable cellspacing="0" className={this.props.className}>
        <thead>
        {
          !!headerGroups &&
          <tr>
            {headerGroups.map(r => (
              <th key={r.key} colSpan={r.colSpan}>{r.label}</th>
            ))}
          </tr>
        }
        <tr>
          {columns.filter(r => !r.hidden).map(column => (
            <th key={column.key}
                style={column.style}
                colSpan={column.colSpan || 1}
                className={(column.align || '') + (column.sortable ? ' sortable' : '')}
                onClick={e => {
                  if (column.sortable && sortChange && sortColumn !== column.key) {
                    sortChange(column.key);
                  }
                  if (column.sortable && directionChange && sortColumn === column.key) {
                    directionChange(direction === 'desc' ? 'asc' : 'desc');
                  }
                }}>
              {column.label}
              {
                sortColumn === column.key &&
                <div className={`sort-arrow ${direction || 'asc'}`}>
                  <i className="sort-icon fa fa-caret-up"/>
                  <i className="sort-icon fa fa-caret-down"/>
                </div>
              }
            </th>
          ))}
        </tr>
        </thead>
        <TBody tag="tbody" sortableSelector="tr" handleSelector=".draggble" onReplacePosition={this.props.onReplaceRow}>
        {rows.map((row, index) => {
          if(row.isCustomRow) {
            return (
              <tr key={row.id + '_' + index} onClick={e => rowClicker && rowClicker(row)}>
                {row.columns.map(c => (
                  <td colSpan={c.colSpan} key={c.key + row[c.key]} className={c.align} style={c.style}>
                    {c.content}
                  </td>
                ))}
              </tr>
            )
          }
          return (
            <tr key={row.id + '_' + index} onClick={e => rowClicker && rowClicker(row)}>
              {columns.filter(r => !r.hidden).map(column => (
                <td key={column.key + row[column.key]} className={column.align} style={column.styleFunc ? column.styleFunc(row): column.style}>
                  {column.renderer ? column.renderer(row, index) : row[column.key]}
                </td>
              ))}
            </tr>
          )
        })}
        {placeHolderRows.map((row, index) => (
          <tr key={index} className="placeholder">
            {columns.filter(r => !r.hidden).map((column, index) => (
              <td key={column.key}>&nbsp;</td>
            ))}
          </tr>
        ))}
        {
          !rows.length &&
          <tr className="no-data">
            <td colSpan={columns.filter(d => !d.hidden).length}>{noDataText}</td>
          </tr>
        }
        </TBody>
      </StyledTable>
    )
  }
}