
export const itemStyles = [
  {key: 'thumbnail_left', label: 'Thumbnail on Left'},
  {key: 'thumbnail_right', label: 'Thumbnail on Right'},
  {key: 'thumbnail_top', label: 'Thumbnail on Top'},
  {key: 'text_only', label: 'Text Only'},
];

export const stickynessOptions = [
  {key: 'top', label: 'Top', icon: '/img/stickness/top.png'},
  {key: 'left', label: 'Left', icon: '/img/stickness/left.png'},
  {key: 'left_bottom', label: 'Left Bottom', icon: '/img/stickness/left_bottom.png'},
  // {key: 'left_top', label: 'Left Top', icon: '/img/stickness/left_top.png'},
  {key: 'right', label: 'Right', icon: '/img/stickness/right.png'},
  {key: 'right_bottom', label: 'Right Bottom', icon: '/img/stickness/right_bottom.png'},
  // {key: 'right_top', label: 'Right Top', icon: '/img/stickness/right_top.png'},
  {key: 'bottom', label: 'Bottom', icon: '/img/stickness/bottom.png'},
];

export const layouts = [
  {
    key: 'grid',
    label: 'Grid',
    icon: '/img/layouts/grid.png',
    defaultParams: {
      row: 3,
      column: 3
    },
    // maxColumn: 5,
    // maxRow: 5,
    // maxColumnExtra: 10,
    // maxRowExtra: 10,
  },
  {
    key: 'grid_main_top',
    label: 'Grid',
    description: 'Grid with main article on the top',
    icon: '/img/layouts/grid_main_top.png',
    main: 'top',
    defaultParams: {
      row: 3,
      column: 3
    },
    // maxColumn: 5,
    // maxRow: 5,
    // maxColumnExtra: 10,
    // maxRowExtra: 10,
  },
  {
    key: 'grid_main_right',
    label: 'Grid',
    description: 'Grid with main article on right side',
    icon: '/img/layouts/grid_main_right.png',
    main: 'right',
    defaultParams: {
      row: 3,
      column: 3,
    },
    // maxColumn: 5,
    // maxRow: 5,
    // maxColumnExtra: 10,
    // maxRowExtra: 10,
  },
  {
    key: 'single_column',
    label: 'List',
    icon: '/img/layouts/column.png',
    description: 'Single Column Layout vertically aligned',
    maxColumn: 1,
    // maxRow: 10,
    // maxColumnExtra: 1,
    // maxRowExtra: 20,
  },
  {
    key: 'single_column_main_top',
    label: 'List',
    description: 'Single Column with main article on the top',
    icon: '/img/layouts/column_main_top.png',
    main: 'top',
    maxColumn: 1,
    // maxRow: 10,
    // maxColumnExtra: 1,
    // maxRowExtra: 20,
  },
  {
    key: 'single_row',
    label: 'Carousel',
    description: 'Single Row, display as a carosell component if overflow',
    icon: '/img/layouts/row.png',
    maxRow: 1,
    // maxColumn: 10,
    // maxColumnExtra: 20,
    // maxRowExtra: 1,
  },
];