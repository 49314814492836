import React from 'react';
import styled, {css} from 'styled-components';

const SvgWrapper = styled.svg`
  
`

export default props => {
  return (
    <SvgWrapper xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        d="M14 26c3.31 0 6-2.69 6-6s-2.69-6-6-6-6 2.69-6 6 2.69 6 6 6zm24-12h-16v14h-16v-18h-4v30h4v-6h36v6h4v-18c0-4.42-3.58-8-8-8z"/>
    </SvgWrapper>
  )
}
