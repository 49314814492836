import React, {Component, useEffect} from 'react';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';
import {
  BlueJeans,
  BlueJeans1, DarkGray, DarkGray1, GrapeFruit, Grass,
  Grass1,
  LightBlue,
  LightGray,
  LightGray1, MediumGray1,MediumGray,
  Sunflower,
  Sunflower1
} from "../app/StyleCommon";
import {intFormatter, pct0Formatter, pct2Formatter, uniquesFormatter, duration} from "../utils/formatter";
import styled from "styled-components";
import {InfoMessage} from "../uikit/errorbox/infoMessage";

const ChartWrapper = styled.div`
  .hero {
    margin-top: 5px;
    white-space: normal;
    .num {
      font-weight: bold;
      &.blue {
        color: rgba(87, 170, 249, 1);
      }
      &.green {
        color: rgba(54, 175, 122, 1);
      }
      &.red {
        color: #d8ae2f;
      }
      &.gray {
        color: ${MediumGray1};
      }
    }
  }
`

export default class extends Component {

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate() {
    this.renderChart();
  }

  render() {
    const {data, showTimeout} = this.props;
    let lastUpdate = _.maxBy(data, _.property('date'));
    let totalImpressions = _.sumBy(data, _.property('impressions'));
    let totalErrors = _.sumBy(data, _.property('errors'));
    let distance = moment().diff(moment((lastUpdate || {}).date), 'second');
    if (!totalImpressions) {
      return <div align="center"style={{minHeight: '40px', display: 'flex', alignItems: 'center'}}>No traffic recently</div>
    }
    return (
      <ChartWrapper>
        <div className="hero">
          <span className="num blue">{uniquesFormatter(totalImpressions)}</span> impressions&nbsp;
          {/*{*/}
          {/*  !!totalErrors &&*/}
          {/*  <span>, <span className="num red">{pct2Formatter(totalErrors / totalImpressions)}</span> timeout rate</span>*/}
          {/*}*/}
          {/*&nbsp;in last 24 hours*/}
          {/*{*/}
          {/*  !!distance && <span>, updated  <span className="num gray">{duration(distance)}</span> ago</span>*/}
          {/*}*/}
        </div>
        <div ref={this.containerRef}/>
      </ChartWrapper>
    )
  }

  renderChart() {
    const {data: rawData, network = {}, showTimeout} = this.props;
    const container = this.containerRef.current;
    if(!rawData || !container) {
      return null;
    }
    const myChart = echarts.init(container, null, {renderer: 'svg', height: 50});
    const now = moment().add(-30, 'day');
    const data = _.sortBy(rawData || [], _.property('date'));
    const todayStart = moment().startOf('day').toDate().getTime();
    const dates = data.map(d => d.date);
    const maxDate = _.max(dates);
    const oneHourAgo = moment(maxDate).add(-1, 'hour').toISOString();
    const offset = [...dates, oneHourAgo].sort().indexOf(oneHourAgo);
    const offsetStart = Math.floor(100 * offset / dates.length);
    var option = {
      color: [Grass, Sunflower],
      grid: {
        bottom: '20',
        top: '5',
        left: '0px',
        right: '10px'
      },
      xAxis: {
        type: 'time',
        axisLabel: {
          align: 'left',
          fontSize: 10,
          hideOverlap: true,
          // formatter: (date, i) => {
          //   return moment(date).format('hh:mm A');
          //   let dt = new Date(date);
          //   // return `${dt.getTime() < todayStart ? 'Yesterday ': ''}${dt.getHours()|| '00'}:${dt.getMinutes()|| '00'} ${dt.getHours() < 12 ? 'AM': 'PM'}`;
          //   return `${dt.getTime() < todayStart ? 'YDA ': ''}${moment(dt).format('hh:mm A')}`;
          // }
        },
        data: dates
      },
      yAxis: {
        min: 0,
        // max: _.max(data.map(d => Math.max(d.impressions, d.errors))) * 1.1,
        position: 'right',
        inside: true,
        splitNumber: 1,
        axisTick: {
          alignWithLabel: true
        },
        splitLine: {
          show: false,
          lineStyle: {
            type: 'dashed'
          }
        },
        axisLabel: {
          inside: true,
          color: DarkGray,
          showMaxLabel: false,
          showMinLabel: false,
          formatter: value => {
            return uniquesFormatter(Math.floor(value))
          }
        }
      },

      tooltip: {
        trigger: 'axis',
        formatter: params => {
          const [ser1] = params;
          console.log(params);
          return `${moment(ser1.axisValueLabel).format('DD-MMM hh:mm A')}<br/>` + params.map(ser => `${ser.seriesName}: ${intFormatter(ser.data[1])}`).join('<br/>');
        }
      },
      series: [
        {label: 'Impressions', key: 'impressions', color: Grass},
        showTimeout ? {label: 'Timeouts', key: 'errors', color: Sunflower} : null,
      ].filter(d => !!d).map(item => {
        return {
          name: item.label,
          type: 'line',
          showSymbol: data.length === 1 ? true : false,
          connectNulls: false,
          smooth: false,
          // sampling: 'lttb',
          endLabel: {show: false},
          animation: true,
          animationDuration: 500,
          data: data.map(d => [new Date(d.date).getTime(), d[item.key] || 0]),
          lineStyle: {
            opacity: .7,
            width: 1.5
          },
          areaStyle: {
            opacity: 0.2,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: item.color
              },
              {
                offset: 1,
                color: 'white'
              }
            ])
          }
        }
      })
    };
    myChart.setOption(option);
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
}