import React from 'react';
import styled, {css} from 'styled-components';
import Pagination from 'rc-pagination';
import {
  BitterSweet1,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass1, LightBlue, LightGray, LightGray1, LightGray2, MediumGray,
  MediumGray1
} from "../app/StyleCommon";

export const FlexWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 50px;
  position: relative;
  :after {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    background: ${LightGray1};
    left: 350px;
    top: 15px;
    height: calc(100% - 10px);
  }
`;

export const SettingPanel = styled.div`
  width: 350px;
  flex-grow: 0;
  flex: none;
  box-sizing: border-box;
  padding: 0 20px 0 10px;
  min-height: calc(100vh - 250px);
  .tab-menu-wrapper {
    padding: 0;
    &:after {
      //filter: drop-shadow(0px 1px 2px #535353);
    }
  }
  @media screen and (max-width: 667px) {
    padding: 0;
    width: 100%;
    .tab-menu-wrapper {
      justify-content: center;
      li {
        margin: 10px !important;
        padding: 10px !important;
      }
    }
  }
`;

export const TitleConfigItem = styled.div`
  & {
    margin-bottom: 15px;
  }
  label {
    display: block;
    margin: 8px 0 8px 0;
    width: 100%;
    font-weight: bold;
    font-size: 11px;
  }
  input {
    width: 100%;
    padding: 6px 10px;
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid ${MediumGray};
    outline: none;
    transition: all .5s ease;
    &:focus {
      border-color: ${BlueJeans};
    }
  }
  .multi-lang-input {
    position: relative;
    .lang-trigger {
      cursor: pointer;
      padding: 0 10px;
      position: absolute;
      right: 5px;
      top: 6px;
      display: inline-block;
      color: ${MediumGray1};
      &:hover {
        color: ${BlueJeans1};
      }
      .hint {
        font-size: 11px;
        color: ${DarkGray};
        font-weight: bold;
        margin-right: 5px;
        opacity:.8;
      }
    }
  }
`;

export const LayoutConfigItem = styled.div`
   & {
    margin-bottom: 15px;
  }
  label {
    display: block;
    margin: 8px 0 5px 0;
    width: 100%;
    font-weight: bold;
    font-size: 11px;
  }
  .select-box {
    width: 100%;
    .toggler {
      width: 100%;
      background: white;
      border: 1px solid ${MediumGray};
      display: flex;
      align-items: center;
    }
    .menu {
      top: 36px;
      input {
        display: none;
      }
      label.selected {
        background: none;
        color: ${BlueJeans1};
      }
      label:hover {
        background: ${LightGray1};
      }
      .layout-item {
        display: flex;
        align-items: flex-start;
        padding: 5px 20px;
        white-space: normal;
        img {
          height: 16px;
          margin-right: 10px;
          margin-top: 3px;
        }
        .description {
           font-size: 11px;
           color: ${DarkGray1};
           font-weight: 300;
        }
      }
    }
  }
`;
export const DesignConfigItem = styled.div`
  & {
    margin-bottom: 15px;
  }
  label {
    display: block;
    margin: 8px 0 5px 0;
    width: 100%;
    font-weight: bold;
    font-size: 11px;
  }
  .select-box {
    width: 100%;
    .toggler {
      width: 100%;
      background: white;
      border: 1px solid ${MediumGray};
      display: flex;
      align-items: center;
    }
    .menu {
      top: 36px;
      border-radius: 5px;
      max-height: 450px;
      label {
        padding: 10px 10px;
        margin: 0;
      }
      input {
        display: none;
      }
    }
  }
`;

export const ItemConfigItem = styled.div`
  & {
    margin-bottom: 15px;
  }
  label {
    display: block;
    margin: 8px 0 8px 0;
    width: 100%;
    font-weight: bold;
    font-size: 11px;
  }
  .select-box {
    width: 100%;
    .toggler {
      width: 100%;
      background: white;
      border: 1px solid ${MediumGray};
      display: flex;
      align-items: center;
    }
    .menu {
      top: 36px;
      border-radius: 5px;
      max-height: 450px;
      overflow-y: auto;
      label:hover {
        background: ${LightGray};
      }
      label.selected {
        background: none;
        color: ${BlueJeans1};
        i, .title span, .description span{
          color: ${BlueJeans1};
          background: ${BlueJeans1};
        }
      }
      input {
        display: none;
      }
    }
  }
  .item {
    margin-bottom: 10px;
    p {
      margin: 0 0 5px 0;
    }
  }
  .stickyness-item {
    display: flex;
    align-items: flex-start;
    padding: 5px 20px;
    white-space: normal;
    img {
      height: 12px;
      margin-right: 5px;
      margin-top: 3px;
    }
    .description {
       font-size: 11px;
       color: ${DarkGray1};
       font-weight: 300;
    }
  }
  .item-style {
    width: 90%;
    box-sizing: border-box;
    i {
      background: none !important;
      font-size: 42px;
      color: ${MediumGray};
      display: block
      margin: 5px;
    }
    .title {
      span {
        font-size: 8px;
        background: ${MediumGray};
      }
      line-height: 6px;
      margin-bottom: 5px;
    }
    .description {
      white-space: normal;
      word-break: break-word;
      line-height: 6px;
      span {
        font-size: 4px;
        background: ${MediumGray};
      }
    }
    &.thumbnail_top {
      width: 50px;
      text-align: left;
      i {
        font-size: 42px;
      }
      .description {display: none}
    }
    &.thumbnail_left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    &.thumbnail_right {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row-reverse;
      i {margin-left: 10px;}
    }
    &.text-only {
    }
  }
`;

export const AllocateConfigItem = styled.div`
  margin-bottom: 20px;
  label {
    display: block;
    margin: 10px 0;
    width: 100%;
    font-weight: bold;
    font-size: 11px;
  }
  button {
    width: 100%;
    text-align: left;
    font-weight: 300 !important;
    height: 35px;
    padding: 5px 10px !important;
    color: ${DarkGray1} !important;
    border: 1px solid ${MediumGray} !important;
    background: linear-gradient(to bottom, white, #fafbfc) !important;
    &:hover {
      background: ${LightBlue} !important;
    }
    img {
      width: 16px;
      margin-right: 10px;
      vertical-align: middle;
    }
  }
`;

export const RightPanel = styled.div`
  width: 100%;
  width: calc(100% - 280px);
  flex-grow: 1;
  padding-left: 20px;
  overflow-x: hidden;
  @media screen and (max-width: 667px) {
    width: 100%;
    padding: 10px 0;
  }
`;

export const BrowserToolbar = styled.div`
  width: 100%;
  display: flex;
  margin: 10px 0
  align-items: center;
  @media screen and (max-width: 667px) {
    display: block;
    input {
      width: 90% !important;
      margin: 5px 0 10px 0 !important;
    }
  }
  .addressBox {
    flex: 1;
    font-size: 14px;
    padding: 5px 10px;
    outline: none;
    border: 1px solid ${MediumGray1};
    border-radius: 5px;
    margin-right: 10px;
    margin-left: 22px;
    background: #fcfdfe;
    &:focus {
      border: 1px solid ${BlueJeans};
    }
  }
`;

export const PreviewWrapper = styled.div`
  position: relative;
  //overflow-y: auto;
  overflow: hidden;
  @media screen and (max-width: 667px) {
    display: block;
    .preview-header {
      display: block !important;
      margin-bottom: 40px !important;
    }
    .preview-modes span:first-child {
      display: none;
    }
    .platform-switch {
      white-space: nowrap;
      margin-left: 0 !important;
      margin-bottom: 10px !important;
      display: block;
    }
    .custom-actions {
      display: flex !important;
      align-items: center;
    }
    .preview-canvas {
       padding: 10px 0 !important;
       .preview-layout.mode-mobile {
          width: 98vw;
       }
    }
  }
  &.stickiness {
    overflow: hidden;
    max-height: calc(100vh - 100px);
    .transform-container {
      transform-origin: 0 0;
      width: 190%;
      transform: scale(0.5); //make it a 'fixed' container
    }
    .preview-canvas {
       overflow: auto;
       height: 1000px;
       height: calc(200vh - 400px) ;
       .preview-layout {
          min-height: 1800px;
          background: rgba(0,0,0,.1);
          padding: 20px 0;
          position: relative;
          background: url(/img/stickness/page_frame.png) 100% 100% no-repeat;
          background-size: 100% 100%;
          //&:before,
          //&:after {
          //  display: block;
          //  position: absolute;
          //  content: '';
          //  background: rgba(0,10,20,.06);
          //  top: 150px;
          //  bottom: 100px;
          //}
          //&:before {
          //  left: 0;
          //  width: 30%;
          //  background: rgba(0,0,50,.1);
          //}
          //&:after {
          //  right: 0;
          //  width: 30%;
          //  background: rgba(50,0,0,.1);
          //}
       }
       .widget-loader {
          opacity: .95;
        }
    }
  }
  .preview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    padding: 5px 10px 5px 0;
    .platform-switch {
      margin-left:  -5px;
    }
    .preview-modes {
      a {
        cursor: pointer;
        margin: 0 5px;
        opacity: .5;
        vertical-align: middle;
        display: inline-flex;
        align-items: center;
        font-size: 12px;
        &.selected {
          opacity: 1;
          font-weight: bold;
        }
        &:not(:first-child) {
          margin-left: 10px;
        }
        img {
          width: 12px;
          height: 12px;
          vertical-align: middle;
          margin-right: 5px;
          margin-top: 2px;
        }
      }
    }
    .custom-actions {
      margin-right: 10px;
      display: flex;
      align-items: center;
      a {
        cursor: pointer;
        font-size: 12px;
        margin-right: 20px;
        position: relative;
        text-align: right;
        &.tag-list-button {
          display: flex;
          align-items: center;
        }
        .tag-list {

        }
        &:hover {
          text-decoration: underline;
          color: ${BlueJeans};
        }
        span {
          white-space: nowrap;
        }
        img {
          width: 18px;
          height: 18px;
          vertical-align: middle;
          margin-right: 10px;
        }
        .desc {
          position: absolute;
          font-size: 8px;
          text-align: right;
          right: 0;
          bottom: -12px;
          white-space: nowrap;
        }
      }
    }
  }
  .preview-canvas {
    min-height: calc(100vh - 350px);
    background: url(/img/assets/preview_canvas.png);
    background-size: 24px 24px;
    padding: 10px 20px;
    border: 1px solid ${MediumGray1};
    overflow: auto;
    &.desktop {
      padding: 10px 0px;
    }
    .error-msg {
      color: ${BitterSweet1};
      margin: 10px 0;
      background: rgba(255,255,255, .2);
    }
    .widget-loader {
      max-width: 100%;
    }
  }
`;

export const PreviewLayout = styled.div`
  position: relative;
  //background-color: white;
  > iframe {
    display: none;
  }
  ${props => ['mobile', 'desktop'].indexOf(props.platform) >= 0 && css`
    &:after {
      position: absolute;
      display: block;
      content: '';
      background: url(/img/ajax-loader.gif) center center no-repeat white;
      z-index: 0;
    }
    iframe {
      border: none;
      position: relative;
      z-index: 1;
      display: block;
    }
  `}
  ${props => props.platform === 'mobile' && css`
    transform: scale(0.8);
    transform-origin: 0 0;
    width: 400px;
    height: 620px;
    background: url(/img/assets/mobile_frame.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: transparent;
    padding: 60px 10px 80px 10px;
    overflow:hidden;
    border-radius: 42px;
    &:after {
      left: 10px;
      top: 60px;
      bottom: 80px;
      right: 10px;
    }
  `}
  ${props => {
  if (props.platform === 'desktop') {
    const simulatorWidth = 1100;
    const simulatorHeight = simulatorWidth * 0.65;
    return css`
        transform: scale(0.52);
        transform-origin: 0 0;
        width: ${simulatorWidth}px;
        height: ${simulatorHeight}px;
        padding: ${simulatorHeight * 0.115}px ${simulatorWidth * 0.185}px ${simulatorHeight * 0.105}px ${simulatorWidth * 0.185}px;
        background: url(/img/assets/macbook.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-color: transparent;
        border-radius: 38px;
        overflow:hidden;
        background-size: cover;
        position: relative;
        &:after {
          top: ${simulatorHeight * 0.121}px;
          right: ${simulatorWidth * 0.185}px;
          bottom: ${simulatorHeight * 0.105}px;
          left: ${simulatorWidth * 0.185}px;
        }
      `;
  }
}}
  ${props => props.platform === 'all' && css`
    .widget-loader {
      width: calc(100% - 250px);
      max-width: calc(100% - 250px);
    }
  `}
`;

export const GridSelectorWrapper = styled.div`
  .selector-wrapper-title {
    margin: 20px 0 10px 0;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .sub {
      display: block;
      font-size: 11px;
      font-weight: normal;
    }
    button {
      i {
        color: ${MediumGray1};
      }
      &:hover {
        color: ${BlueJeans};
      }
    }
  }
  .table-wrapper.wide {
    overflow: auto;
    >table {
      table-layout: auto;
      td {
        min-width: 40px;
      }
    }
  }
`;

export const GridSelector = styled.table`
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box;
  border-collapse: separate;
  border-spacing: 5px;
  table-layout: fixed;
  border: 1px solid ${MediumGray};
  &.single_column td {
    height: 15px;
  }
  &.single_row {
    td {
      width: 34px;
      min-width: 34px !important;
      div {font-size: 8px !important;}
      margin-bottom: 5px;
    }
    tr {
      display: flex;
      flex-wrap: wrap;
    }
  }
  tr {
    border: 1px solid ${MediumGray}
    td {
      border: 1px dotted ${MediumGray}
      height: 20px;
      cursor: pointer;
      text-align: center;
      .assignment {
        display: none;
      }
    }
    &.active td.active {
      //border: 1px solid #57b8ff;
      background: ${LightBlue};
      .assignment {
        display: block;
        font-size: 10px;
        font-weight: bold;
        overflow: hidden;
      }
    }
    td.main {
      background: ${LightBlue};
      height: 60px;
      width: 60px;
      font-size: 8px;
      font-weight: bold;
    }
    td.thumbnail {
      width: 40px;
      font-size:10px;
      padding: 0 10px;
    }
    &.hover td.hover {
      //background: #cbe5ff;
      border: 1px solid ${BlueJeans1};
    }
  }
`;


export const DataProviderWrapper = styled.div`
  width: 100%;
  .cxense-widget {
    display: flex;
    align-items: center;
    position: relative;
    .cxense-widget-info {
      width: 100%;
      border: 1px solid ${MediumGray1};
      background: ${LightGray};
      padding: 6px 10px;
      margin-right: 10px;
      border-radius: 5px;
      color: ${DarkGray};
      cursor: pointer;
      white-space: nowrap;
      padding-right: 50px;
      text-overflow: ellipsis;
      overflow: hidden;
      img {
        width: 18px;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    button {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`

export const DataProviderSelector = styled.div`
  .provider  {
    width: 100%;
    height: 40px;
    margin: 10px 20px 10px 0;
    border: 1px solid ${MediumGray};
    // background: ${LightGray};
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    box-sizing: border-box;
    background: linear-gradient(to bottom, white, #fafbfc);
    &:hover {
      color: ${BlueJeans1};
    }
    img {
      width: 32px;
      height: 32px;
      margin-right: 20px;
    }
  }
`

export const ResponseFields = styled.div`
  .field-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    label {
      color: ${DarkGray};
      font-weight: bold;
      font-size: 11px;
    }
  }
  .select-box {
    width: 100%;
    .toggler {
      width: 100%;
      background: white;
      border: 1px solid ${MediumGray};
      display: flex;
      align-items: center;
      .caret {
        border: 10px solid white;
        right: -10px;
        background: white;
      }
    }
    .menu {
      //transform: translateY(-100%) translateY(-35px);
      max-height: 350px;
    }
  }
`
export const SlotGroupList = styled.div`
  margin-bottom: 20px;
  .slot-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    label {
      color: ${DarkGray};
      font-weight: bold;
      font-size: 11px;
    }
  }
  .slot-group-item {
    background-image: linear-gradient(0deg, #fafbfc, rgba(255,255,255,.1));
    margin: 10px 0 18px 0;
    cursor: pointer;
    border: 1px solid ${MediumGray};
    border-radius: 4px;
    .slot-group-info {
      &:hover {
        background: #f1f2f3;
      }
      .header {
        padding: 4px 0 4px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:hover {
          color: ${BlueJeans1};
        }
       .name {
          font-weight: bold;
          line-height: 1.5em
          margin-right: 20px;
        }
        .slot-group-actions {
          display: flex;
          align-items: center;
          button {
            margin: 0 0 0 5px;
            color: ${MediumGray1};
            &:hover {
              color: ${BlueJeans1};
            }
            &:hover img{
              filter: none;
            }
            img {
              width: 14px;
              width: 14px;
              vertical-align: middle;
              filter: grayscale(90);
            }
          }
        }
      }
      .desc {
        padding: 4px 10px;
        font-size: 11px;
        color: #919293;
        strong {
          //color: ${BlueJeans};
        }
      }
    }
    .targeting-list {
      padding: 4px 0 4px 10px;
      margin-top: 10px;
      font-size: 12px;
      border-top: 1px solid ${LightGray1};
      padding-top: 5px;
      background-color: white;
      .targeting-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4px 5px 4px 0px;
        cursor: pointer;
        i {
          margin-right: 5px;
          color: ${MediumGray1};
          &:hover {
            color: ${BlueJeans1};
          }
        }
        &:hover {
          background-color: ${LightGray1};
        }
      }
      .no-targeting-data {
        padding: 5px 0px 5px 20px;
        color: ${MediumGray1};
      }
      .targeting-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 0px 5px 20px;
        strong {
          margin-right: 5px;
        }
        &:hover {
          background-color: ${LightGray1};
        }
        i {
          color: ${MediumGray1};
          &:hover {
            color: ${BlueJeans1};
          }
        }
      }
    }
    .backfill {
      padding: 10px 0 10px 10px;
      font-size: 12px;
      border-top: 1px solid ${LightGray1};
      padding-top: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: white;
      .left {
        i {
          margin-right: 5px;
        }
        &:hover {
          color: ${BlueJeans1};
        }
      }
      &:hover {
         .right {
          opacity: 1;
        }
      }
      .right {
        opacity: 0;
      }
    }
  }
  .no-data {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

`

export const ItemTooltipWrapper = styled.div`
  max-width: 500px;
  max-height: 250px;
  overflow-y: auto;
  background: rgba(255,255,255,.5);
  table {
    thead {
      display: none;
    }
    td {
      padding: 4px 5px !important;
    }
  }
`
