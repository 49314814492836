import React from 'react';

const fixedLabel = (label) => {
  return label;
  // let [size, name] = label.split('--');
  // return (
  //   <span><span style={{display: 'inline-block', width: '65px'}}>{size}</span> -- <span style={{marginLeft: '10px'}}>{name}</span></span>
  // )
}

export default [
  {key: '970x250', label: fixedLabel('970x250 -- Billboard')},
  {key: '300x50', label: fixedLabel('300x50 -- Smartphone Banner 1')},
  {key: '320x50', label: fixedLabel('320x50 -- Smartphone Banner 2')},
  {key: '728x90', label: fixedLabel('728x90 -- Leaderboard')},
  {key: '970x90', label: fixedLabel('970x90 -- Super Leaderboard/ Pushdown')},
  {key: '300x1050', label: fixedLabel('300x1050 -- Portrait')},
  {key: '160x600', label: fixedLabel('160x600 -- Skyscraper')},
  {key: '300x250', label: fixedLabel('300x250 -- Medium Rectangle')},
  {key: '900x300', label: fixedLabel('900x300 -- 3:1 Rectangle')},
  {key: '640x1136', label: fixedLabel('640x1136 -- Mobile Phone Small Interstitial')},
  {key: '750x1334', label: fixedLabel('750x1334 -- Mobile Phone Medium Interstitial')},
  {key: '1080x1920', label: fixedLabel('1080x1920 -- Mobile Phone Large Interstitial')},
  {key: '120x20', label: fixedLabel('120x20 -- Feature Phone Small Banner')},
  {key: '168x28', label: fixedLabel('168x28 -- Feature Phone Medium Banner')},
  {key: '216x36', label: fixedLabel('216x36 -- Feature Phone Large Banner')},
]