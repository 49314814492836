import React from 'react';
import classnames from 'classnames';
import ReactDOM from 'react-dom';
import {withRouter} from 'react-router';
import _ from 'lodash';
import Dropzone from 'react-dropzone'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {withConsumer} from '../app/ApplicationContext';
import MainLayout from '../layout/index';
import {sites} from '../utils/metadata';
import campaignPriorities from '../utils/campaignPriorities';
import {post} from '../utils/request';
import {ProgressBar, Table, TableScrollWrapper, Btn, CondirmDialog, Loader, Hint} from '../uikit/index';
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import {CampaignPageWrapper, ControlBar, StatusTag, CreativeWrapper} from './builder-campaign.style';
import {EmptyList, CreateWrapper} from './builder-campaign.style';
import {SelectStyled} from './select.style';
import {PaginationStyled} from '../uikit/Pagination/custom.style';
import {BlueJeans} from "../app/StyleCommon";
import {intFormatter, periodFormat} from "../utils/formatter";
import CreativeSelector from './builder-creative';

export default class extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <CampaignPageWrapper>
        {this.renderCreationForm()}
      </CampaignPageWrapper>
    )
  }


  renderCreationForm() {
    const {campaign, advertisers = [], orders = [], errorMap = {}, readOnly} = this.props;
    if(!campaign) {
      return null;
    }
    const {name, advertiser, io, type, priority, goal_value, goal_type, start_date, end_date, frequency_cap, frequency_unit} = campaign;
    return (
      <CreateWrapper>
        <div className="field-grid">
          <div className={classnames('field', {error: !!errorMap.name})}>
            <label>Campaign Name *</label>
            <input
              type="text"
              // name="campaign_name"
              disabled={readOnly}
              placeholder=""
              value={name || ''}
              onChange={e => this.updateCampaign({name: e.target.value})}/>
            {
              !!errorMap.name &&
              <div className="error-msg">{errorMap.name}</div>
            }
          </div>
          <div className={classnames('field', {error: !!errorMap.advertiser})}>
            <label>
              Advertiser *</label>
            <div>
              {/*<input*/}
                {/*type="text"*/}
                {/*disabled={readOnly}*/}
                {/*// name="advertiser"*/}
                {/*placeholder={'Advertiser'}*/}
                {/*value={advertiser || ''}*/}
                {/*onChange={e => this.updateCampaign({advertiser: e.target.value})}/>*/}
              <SelectStyled
                defaultLabel=""
                searchable={true}
                inputable={true}
                plainStyle={true}
                disabled={readOnly}
                selected={advertiser}
                data={advertisers.map(d => ({key: d, label: d}))}
                onChange={advertiser => {
                  this.updateCampaign({advertiser: advertiser})
                }}/>
            </div>
            {
              !!errorMap.advertiser &&
              <div className="error-msg">{errorMap.advertiser}</div>
            }
          </div>

        </div>
        <div className="field-grid">
          <div className={classnames('field', {error: !!errorMap.start_date})}>
            <label>Start Time *</label>
            <DatePicker
              selected={campaign.start_date ? moment(campaign.start_date).toDate() : null}
              onChange={date => {
                this.updateCampaign({start_date: date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null})
              }}
              disabled={readOnly}
              showTimeSelect
              placeholderText={''}
              timeFormat="HH:mm"
              timeIntervals={30}
              timeCaption="time"
              isClearable
              minDate={new Date()}
              dateFormat="MMMM d, yyyy h:mm aa"/>
            {
              !!errorMap.start_date &&
              <div className="error-msg">{errorMap.start_date}</div>
            }
          </div>
          <div className={classnames('field', {error: !!errorMap.end_date})}>
            <label>End Time (Optional)</label>
            <DatePicker
              selected={campaign.end_date ? moment(campaign.end_date).toDate() : null}
              placeholderText={end_date ? '' : 'Unlimited'}
              onChange={date => {
                this.updateCampaign({end_date: date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null})
              }}
              disabled={readOnly}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={30}
              timeCaption="time"
              isClearable
              minDate={new Date()}
              dateFormat="MMMM d, yyyy h:mm aa"/>
            {
              !!errorMap.end_date &&
              <div className="error-msg">{errorMap.end_date}</div>
            }
          </div>
        </div>

        <div className="field-grid">
          <div className={classnames('field', {error: !!errorMap.type})}>
            <label>
              Insertion Order (Optional)
              <Hint
                width={280}
                pos="top"
                content={
                  <div>
                    An insertion order contains a set of campaigns that are related to the same advertising contract.
                  </div>
                }/>
            </label>
            {
              !orders.length &&
              <input
                type="text"
                disabled={readOnly}
                // name="advertiser"
                placeholder={''}
                value={io || ''}
                onChange={e => this.updateCampaign({io: e.target.value})}/>
            }
            {
              !!orders.length &&
              <SelectStyled
                defaultLabel=""
                disabled={readOnly}
                searchable={true}
                inputable={true}
                plainStyle={true}
                selected={io}
                menuHeader={
                  <div style={{padding: '10px 20px', borderBottom: '1px solid #ddd', background: '#fafbfc'}}>
                    Input a new one or select existing:
                  </div>
                }
                data={orders.map(d => ({key: d, label: d}))}
                onChange={io => {
                  this.updateCampaign({io: io})
                }}/>
            }
          </div>
          <div className={classnames('field', {error: !!errorMap.type})}>
            <label>
              Campaign Type and Priority *
              <Hint
                width={280}
                pos="righttop"
                content={
                  <div>
                    {`The priority is arranged in descending order, with 'Direct' having the highest priority, followed by 'Standard,' and then 'House’.`}
                  </div>
                }/>
            </label>
            <div className="priority-set">
              <SelectStyled
                plainStyle={true}
                defaultLabel="Campaign Type"
                disabled={readOnly}
                selected={campaign.type}
                data={campaignPriorities}
                itemRenderer={d => {
                  return (
                    <div className="priprity-item" key={d.key}>
                      <div>
                        <div className="name">{d.label}</div>
                        <div className="description">{d.description}</div>
                      </div>
                    </div>
                  )
                }}
                onChange={type => this.updateCampaign({type: type, priority: (campaign.priority || 1)})}/>
              <span className="sep">/</span>
              <SelectStyled
                plainStyle={true}
                defaultLabel="Priority"
                disabled={readOnly}
                selected={campaign.priority}
                data={[
                  {key: 1, label: 'High'},
                  {key: 2, label: 'Medium'},
                  {key: 3, label: 'Low'},
                ]}
                onChange={priority => this.updateCampaign({priority})}/>
            </div>
            {
              !!errorMap.priority &&
              <div className="error-msg">{errorMap.priority}</div>
            }
          </div>
        </div>
        <div className="field-grid">
          <div className={classnames('field', {error: !!errorMap.goal_type || !!errorMap.goal_value})}>
            <label>
              Goal
              <Hint
                width={280}
                pos="righttop"
                content={
                  <div>
                    <div>
                      <div>Mandatory for non-house campaigns.</div>
                      <div>Total impressions: The total number of impressions to be delivered for this campaign.</div>
                      <div>% of available impressions: The campaign will deliver to this percentage of total available impressions.</div>
                    </div>
                  </div>
                }/>
            </label>
            <div className="goal-settings">
              <input
                type="number"
                min={0}
                max={100}
                disabled={readOnly}
                placeholder=""
                value={campaign.goal_value || ''}
                onChange={e => this.updateCampaign({
                  goal_value: e.target.value, goal_type: goal_type || 'FIXED'
                })}
              />
              <SelectStyled
                plainStyle={true}
                disabled={readOnly}
                selected={campaign.goal_type || 'FIXED'}
                data={[
                  {key: 'FIXED', label: 'total impressions'},
                  {key: 'PERCENTAGE', label: '% of available impressions'},
                ]}
                onChange={goal_type => this.updateCampaign({goal_type: goal_type})}/>
            </div>
            {
              !!errorMap.goal_value &&
              <div className="error-msg">{errorMap.goal_value}</div>
            }
            {
              !!errorMap.goal_type &&
              <div className="error-msg">{errorMap.goal_type}</div>
            }
          </div>
          <div className={classnames('field', {error: !!errorMap.frequency_cap})}>
            <label>
              Frequency Cap (Optional)
              <Hint
                width={280}
                pos="righttop"
                content={
                  <div>
                    Campaign ads will not be shown if the frequency cap is reached.
                  </div>
                }/>
            </label>
            <div className="fc-settings">
              <div>
                <input
                  type="number"
                  min={0}
                  max={100}
                  placeholder=""
                  disabled={readOnly}
                  value={campaign.frequency_cap}
                  onChange={e => this.updateCampaign({frequency_cap: e.target.value, frequency_unit: frequency_unit || 'day'})}
                />
              </div>
              <div className="unit">
                <span className="info">impressions per</span>
                <div className="period">
                  {
                    [
                      {key: 'day', label: 'Day'},
                      {key: 'week', label: 'Week'},
                      {key: 'month', label: 'Month'},
                      // {key: 'lifetime', label: 'Whole Campaign Period'},
                    ].map(item => {
                      return (
                        <label
                          key={item.key}
                          onClick={e => this.updateCampaign({frequency_unit: item.key, frequency_cap: frequency_cap || 1})}
                          className={classnames({disabled: readOnly, selected: frequency_unit === item.key})}>
                          {item.label}
                        </label>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            {
              !!errorMap.frequency_cap &&
              <div className="error-msg">{errorMap.frequency_cap}</div>
            }
          </div>
        </div>

      </CreateWrapper>
    )
  }

  updateCampaign(partial) {
    this.props.onChange(partial);
  }
}