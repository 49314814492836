import styled, {css} from 'styled-components';
import Pagination from 'rc-pagination';

import {Dropdown} from "../uikit/index";
import {BlueJeans, BlueJeans1, Grass, LightGray, MediumGray,
  DarkGray, LightGray1, Levander, Sunflower, MediumGray1, DarkGray1, SilverGray} from "../app/StyleCommon";

export const ABTestingWrapper = styled.div`
  background-color: white;
  padding: 10px 20px;
  min-height: calc(100vh - 40px);
  width: 100%;
  box-sizing: border-box;
  @media screen and (max-width: 667px) {
    padding: 20px 10px;
  }
`;

export const FilterBarFirst = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  //margin: 10px 0;
   @media screen and (max-width: 667px) {
    display: block;
    .layout-mode {
      display: none;
    }
    .create-btn {
      width: 100%;
      button {
        width: 100% !important;
        margin: 10px 0;
      }
    }
  }
  .left, .right {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  h3 {
    margin-right: 30px;
  }
  .status-list {
    display: flex;
    flex-grow: 1;
    li {
      cursor: pointer;
      margin: 10px 10px 10px 0;
      padding: 5px 10px;
      border-radius: 5px;
      &.selected {
        color: ${BlueJeans1};
        background: #f1f3f6;
        font-weight: bold;
      }
    }
  }
  .layout-mode {
    margin: auto 20px;
    a {
      display: inline-block;
      margin-right: 10px;
      cursor: pointer;
      color: ${MediumGray};
      padding: 5px;
      border-radius: 5px;
      &:hover {
        background: ${LightGray};
      }
      &.selected {
        color: ${BlueJeans1}
      }
    }
  }
`;

export const FilterBarSecond = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  position: relative;
  .select-box {
    margin-left: 20px;
  }
  .searchBox {
    flex-grow: 1;
    i {
      position: absolute;
      left: 10px;
      top: 10px;
    }
    input {
      padding: 8px 10px 8px 30px;
      font-size: 14px;
      border-radius: 5px;
      border: 1px solid ${MediumGray};
      background: ${LightGray};
      width: 100%;
      box-sizing: border-box;
      outline: none;
      &:focus {
        border-color: ${BlueJeans};
        box-shadow: inset 0 0 3px rgba(0,0,0,.1);
      }
    }
  }
  @media screen and (max-width: 667px) {
    flex-wrap: wrap;
    .searchBox {
      width: 100%;
      margin-bottom: 5px;
    }
    .select-box {
      margin: 5px 0;
      width: 100%;
      .toggler {
        width: 100%;
      }
    }
  }
`;

export const ABListView = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  ${props => props.mode === 'list' && css`
    && {
      .abtesting-item {
        width: 100%;
        margin: 10px 0px;
        .abtest-item-body {
          display: flex;
          justify-content: flex-start;
          min-height: 60px;
          .attr {
            &.author {
              display: flex;
            }
          }
          .metrics {
            margin-right: 60px;
            margin-left: 20px;
            position: relative;
            width: 40%;
            max-width: 400px;
            margin-top: -25px;
            display: flex;
            align-items: flex-start;
            >label {
              display: block;
              font-weight: normal;
              font-size: 13px;
              margin-top: 30px;
              margin-right: 20px;
              white-space: nowrap;
            }
            .tab-container-wrapper {
              margin: 0;
              width: 100%;
              background-color: transparent;
              .tab-menu-wrapper {
                padding: 0  20px;
                white-space: nowrap;
                flex-wrap: nowrap;
                &:before {
                  content: none;
                }
                &:after {
                  left: 0;
                  right: 0;
                  width: auto;
                }
                li {
                  font-weight: normal
                  padding: 0 0px;
                  &.selected {
                    border-bottom: 2px solid ${MediumGray};
                    border-radius: 5px 5px 0 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  `}
`;

export const ABTestingItem = styled.div`
  margin: 10px 30px 10px 0;
  display: inline-block;
  width: 31.5%;
  width: calc(33.3333% - 20px);
  border-radius: 5px;
  border: none;
  box-shadow: 0px 1px 4px rgba(0,0,0,.2);
  //background-image: linear-gradient(0deg,#fafbfc,#eeeded00);
  background: white;
  box-sizing: border-box;
  padding: 10px 10px 20px 10px;
  position: relative;
  @media screen and (max-width: 1024px) {
    width: 48％;
    width: calc(50% - 20px);
    margin-right: 0 !important;
    &:nth-child(2n+1) {
      margin-right: 20px !important;
    }
  }
  @media screen and (max-width: 667px) {
    width: 100%;
    margin-right: 0 !important;
    &:nth-child(2n + 1) {
      margin-right: 0 !important;
    }
  }
  &:nth-child(3n +3) {
    margin-right: 0;
  }
  .abtest-item-header {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    flex-wrap: wrap;
    .site {
      height: 18px;
      width: auto;
      margin-right: 10px;
      vertical-align: middle;
      display: inline-block;
    }
    .title {
      font-size: 14px;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 85%;
      text-decoration: none;
      span:hover {
        text-decoration: underline;
      }
      .name {
        max-width: 250px;
        display: inline-block;
        word-break: break-word;
        white-space: normal;
        vertical-align: middle;
      }
      img {
        width: auto;
        height: 18px;
      }
       button {
        margin-left: 10px;
        color: ${DarkGray}
        a {
          color: ${DarkGray}
        }
      }
    }
    .actions {
      display: flex;
      align-items: center;
      z-index: 1;
      button {
        color: ${DarkGray}
        a {
          color: ${DarkGray}
        }
      }
      .dropdown-toggle {
        height: 30px;
        width: 20px;
      }
    }
  }
  .abtest-item-body {
    position: relative;
    padding: 0 10px;
    font-size: 13px;
    .basic-info {
      min-width: 50%;
      .attr {
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 3px 0;
        label {
          display: inline-block;
          white-space: nowrap;
          width: 80px;
          margin-right: 20px;
          font-weight: normal;
        }
        >span {
          display: inline-block;
          padding: 0px 10px;
          width: 100%;
        }
        &.author {
          display: none;
        }
        .variant-preview {
          a {
            &:hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
      }
      .status {
        font-weight: 700;
        color: ${Levander};
        i {
          font-size: 18px;
          margin-right: 5px;
        }
        &.Running {
          color: ${Sunflower};
        }
        &.Completed {
          color: ${Grass};
        }
      }
    }
    .metrics {
      width: 100%;
      display: block;
      margin: 0;
      margin-top: 0px;
      padding-top: 5px;
      >label {
        margin: 0 0 5px 0;
        font-weight: bold;
        display: none;
        font-size: 13px;
      }
      .tab-items-wrapper {
        width: 100%;
        height: 116.75px;
      }
      .tab-menu-wrapper {
        padding: 0;
        white-space: nowrap;
        flex-wrap: nowrap;
        &:before {
          content: 'Traffic';
          margin-right: 10px;
          display: inline-block;
          width: 70px;
          font-weight: normal;
          font-size: 13px;
          margin-top: 3px;
        }
        &:after {
          left: 105px;
          right: 15px;
          width: auto;
        }
        >li {
          font-weight: normal;
          font-size: 13px;
          padding: 0;
          &.selected {
            border-bottom: 2px solid ${MediumGray};
            border-radius: 5px 5px 0 0;
          }
        }
      }
    }
    .legend {
      font-weight: 600;
      font-size: 14px;
      color: ${DarkGray};
      padding: 10px;
    }
    .imp-count {
      padding: 5px 0;
      font-size: 16px;
      color: rgba(87, 170, 249, 0.7);
      font-weight: bold;
    }
    .unq-count {
      padding: 5px 0;
      font-size: 16px;
      color: rgba(54, 175, 122, 0.6);
      font-weight: bold;
    }
  }
`;

export const NoData = styled.div`
  min-height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${DarkGray};
  a {
    display: inline-block;
    margin: 0 10px;
  }
`;

export const ListFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  select {
    padding: 2px 5px;
    font-size: 12px;
    min-width: 50px;
    margin: auto 5px;
  }
  @media screen and (max-width: 667px) {
    flex-wrap: wrap;
    .info {
      display: block
      margin: 10px 0;
    }
    .rc-pagination {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .blockIfMobile {
      display: block;
      margin: 10px 0;
      width: 100%;
      text-align: center;
    }
  }
`;

export const PaginationStyled = styled(Pagination)`
  >li {
    outline: none;
    border: none !important;
    &:hover {
      border-color: ${BlueJeans};
    }
    a {
        font-size: 14px;
    }
    &.rc-pagination-item-active {
      background-color: ${BlueJeans};
    }
  }
`;

export const VariantPreviewBody = styled.div`
  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledTable = styled.table`
  display: block;
  min-height: 250px;
  //max-height: 500px;
  //overflow: scroll;
  width: 100%;
  box-sizing: border-box;
  border-collapse: collapse;
  border-spacing: 0;
  color: ${DarkGray1};
  background-color: rgba(255,255,255,.9);
  position: relative;
  table-layout: fixed;
  thead {
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    background: #fafbfc;
    background: linear-gradient(to right bottom,  ${SilverGray}, ${LightGray1});
    th {
      width: 100%;
      margin: 3px 0;
      padding: 8px 10px;
      text-align: center;
      border-bottom: 1px solid ${LightGray1};
      position: relative;
      border-right: 1px solid ${LightGray1};
    }
  }
  tbody {
    position: relative;
    tr {
      &:nth-child(odd) {
        background: ${LightGray};
      }
      td {
        vertical-align: top;
        // padding: 8px 10px;
      }
      &.no-data {
        color: ${MediumGray};
        td {
          text-align: center;
          min-height: 150px;
          &:hover {
            background: white;
          }
        }
      }
    }
  }
`;