import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import styled, {css} from 'styled-components';
import {withConsumer} from '../app/ApplicationContext';
import {ProgressBar, Loader} from '../uikit/index';
import {ErrorBox} from './index.style';
import defaultContexts from './defaultContext';
import {readCookie} from '../utils/misc';
import {MediumGray, MediumGray1} from "../app/StyleCommon";

var PreviewWrapper = styled.div`
  position: relative;
  padding: 0 10px;
  .context {
    margin: 10px 0;
    border-bottom: 1px solid ${MediumGray};
    .attr {
      margin: 10px 0;
      label {
        flex: none;
        display: block;
        width: 100px;
        white-space: nowrap;
      }
      display: flex;
      input {
        flex-grow: 1;
        padding: 5px 5px;
        border: 1px solid ${MediumGray};
        border-radius: 5px;
        font-size: 14px;
        margin-left: 10px;
        outline: none;
      }
    }
    .parent {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      img {
        width: 80px;
        height: 49px;
        margin-right: 30px;
        box-sizing: 5px;
      }
      .title {
        font-size: 14px;
        i {
          margin-left: 5px;
          vertical-align: middle;
          font-size: 12px;
        }
      }
      .publish_time {
        font-size: 12px;
        color: ${MediumGray1};
      }
    }
  }
  .widget-list {
    display: flex;
    max-width: 100%;
    align-items: flex-start;
    min-height: 200px;
    max-height: 400px;
    max-height: calc(80vh - 160px);
    overflow: auto;
    max-width: 900px;
    max-width: calc(100vw - 150px);
    @media screen and (max-width: 667px) {
      max-width: none;
    }
    .rec-container {
      width: 100%;
      min-width: 350px;
      flex-wrap: 1;
      &:not(:first-child) {
        margin-left: 40px;
      }
    }
  }
  .loader {
    margin-top: 80px;
  }
`

export default withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    const {siteOption = {}} = props;
    this.state = {
      site: props.site,
      context: {
        ...(siteOption.defaultContext ||  defaultContexts[props.site] || {}),
        meid: readCookie('UID') || '15797052-eec2-4015-8850-39d53e2801f8'
      }
    };
  }

  render() {
    const {id, ids = []} = this.props;
    const {data, context, site, error, loading} = this.state;
    const {widgets, metadata}= data || {};
    const widgetIds = _.uniq(_.compact([...ids, id]));
    return (
      <PreviewWrapper>
        {
          !data && !error &&
          <ProgressBar
            withoutCache={true}
            loaderProps={{type: 'absolute', transparent: true}}
            fixcenter={false}
            url={`/api/previewWidgets`}
            params={{ids: widgetIds, context: context}}
            successHandler={data => {
              this.setState({data: data, loading: false, error: false});
              (data.widgets || []).forEach(widget => {
                window.recApp.renderWidget(widget, {...context, debug: true});
              })
            }}
            errorHandler={e => this.setState({error: e.message, loading: false})}/>
        }
        {
          !!error && <ErrorBox>{error}</ErrorBox>
        }
        <div className="context">
          <div className="attr">
            <label>Context Url</label>
            <input className="url"
                   type="text"
                   value={context.url}
                   onFocus={e => e.target.select()}
                   onChange={e => this.updateContext('url', e.target.value)}/>
          </div>
          <div className="attr">
            <label>MeID: </label>
            <input className="meid"
                   type="text"
                   value={context.meid}
                   onFocus={e => e.target.select()}
                   onChange={e => this.updateContext('meid', e.target.value)}/>
          </div>
          {
            !!metadata &&
            <div className="parent">
              <img src={metadata.thumbnail}/>
              <div className="title">
                <a href={context.url} target="_blank" rel="noopener noreferrer">{metadata.title}. <i className="fa fa-external-link"/></a>
                <div className="publish_time">Published: {moment(metadata.publish_time).format('lll')}</div>
              </div>
            </div>
          }

        </div>
        <div className="widget-list">
          {widgetIds.map(id => {
            let widget = (widgets || []).find(d => d.id === id);
            return (
              <div key={id} className="rec-container">
                {widget && <p>{widget.name}</p>}
                {
                  !!widget &&
                  <div
                    id={id}
                    data-role="rec-widget"
                    style={{margin: 'auto', minHeight: '100px'}} />
                }
              </div>
            )
          })}
        </div>
      </PreviewWrapper>
    );
  }

  updateContext(attr, value) {
    this.setState({context: {...this.state.context, [attr]: value}}, () => {
      const {loading, context} = this.state;
      if (String(context.meid).length >= 36 && String(context.url).length > 20 && !context.loading) {
        this.setState({data: null, loading: true}); //reload widget
      }
    })
  }
})
