import React from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import {CondirmDialog} from '../uikit/index';
import {CreativeContainer} from './index.style';

export default (props) => {
  const {creative, width: fixedWidth, height: fixedHeight} = props;
  if(!creative || creative.status === 'deleted') {
    return 'deleted';
  }
  let {name, size, thumbnail, click_through_url} = creative;
  let [width, height] = size.split('x').map(d => Number(d));
  if(fixedWidth && fixedHeight) {
    width = fixedWidth;
    height = fixedHeight;
  } else {
    let wratio = 1, hratio = 1;
    if (width > 160) {
      wratio = 160 / width;
    }
    if (height > 50) {
      hratio = 50 / height;
    }
    let ratio = Math.min(wratio, hratio);
    width = ratio * width;
    height = ratio * height;
  }

  return (
    <CreativeContainer {...props} onClick={e => {
      if(props.onClick) {
        return props.onClick(e);
      }
      const confirmInfo = {
        type: 'form',
        backgroundClose: true,
        title: <strong>Preview Creative</strong>,
        hideCancel: true,
        hideOK: true,
        onCancel: () => CondirmDialog.closeAll(props.mountPoint),
        dialogBody: (
          <div>
            <div>{name}</div>
            <div>{size} (ID: {creative.id})</div>
            <img src={thumbnail}/>
            {/*<p>Click Through Url: <a href={click_through_url} target="_blank">{click_through_url}</a></p>*/}
          </div>
        ),
      }
      ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById(props.mountPoint || 'global-confirm'));
    }}>
      {
        !props.noImage &&
        <img src={thumbnail} style={{
          width: `${width}px`,
          height: `${height}px`,
          background: `url(https://via.placeholder.com/${width}x${height}.png?text=Loading...) no-repeat`,
          backgroundSize: 'cover'
        }}/>
      }
      {
        !props.noName && <span>{name}</span>
      }
    </CreativeContainer>
  )
}