import styled, {css} from 'styled-components';
import {DarkGray, DarkGray1, LightGray, MediumGray, Mint} from "../app/StyleCommon";

export const PietWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .legend {
    min-height: 135px;
    margin-top: 30px;
  }
  .legend-item {
    display: block;
    margin: 8px 0;
    vertical-align: middle;
    font-size: 12px;
    @media (max-width: 667px) {
      display: block;
      margin: 5px 10px;
    }
    .colorBlock{
      vertical-align: middle;
      color: white;
      margin-right: 5px;
      border-radius: 2px;
      text-align: center;
      width: 18px;
      height: 16px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      img {
        width: 12px;
        height: 12px;
        vertical-align: middle;
      }
    }
    .total {
      margin-left: 5px;
      font-size: 12px;
      color: ${DarkGray} !important;
      display: inline-block;
      vertical-align: middle;
      margin-top: -2px;
    }
  }
  
        
`;