import styled, {css} from 'styled-components';
import Select from '../uikit/selectbox/index';
import {
  BlueJeans, DarkGray, DarkGray1, LightGray, LightGray1, MediumGray, MediumGray1, MeOrange,
  MeOrangeLight
} from "../app/StyleCommon";

export const SelectStyled = styled(Select)`
  width: 100%;
  .toggler {
    padding: 6px 20px 6px 10px;
    border-radius: 5px;
    background-color: ${LightGray};
    border: 1px solid ${MediumGray};
    padding-right: 40px;
    .default-label {
      color: ${DarkGray1};
    }
  }
  .menu {
    top: 36px;
    left: auto;
    right: 0;
    max-height: 400px;
    overflow-y: auto;
    label:not(.selected):hover {
      background-color: ${LightGray};
      color: ${DarkGray1};
    }
    .menu-header {
      padding: 10px 20px;
      font-size: 12px;
      font-weight: bold;
      background-color: ${LightGray};
      color: ${MediumGray1}
    }
  }
  ${props => props.plainStyle && css`
  .toggler {
    background-color: white;
    padding: 3px 10px;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid ${MediumGray};
    cursor: pointer;
    &.active {
      outline: 2px solid #6EB3DE;
    }
  }
  .menu {
    left: 0;
    right: 0;
  }
`}
`
