import React, {Component, useEffect} from 'react';
import ReactServer from 'react-dom/server';
import createTooltip from '../uikit/tooltip/index';
import * as echarts from 'echarts';
import classnames from 'classnames';
import moment from 'moment';
import styled from 'styled-components';
import _ from 'lodash';
import {ReRenderHook} from '../utils/hooks';
import {timeseriesDimensions, getDimensionKeyLabel, getDimensionLabel, getMetricLabel} from './metadata';
import {
  BitterSweet,
  BitterSweet1,
  BlueJeans, BlueJeans0,
  BlueJeans1, colorCategory1, DarkGray, GrapeFruit, Grass,
  Grass1, Levander,
  LightBlue,
  LightGray,
  LightGray1, MediumGray, MediumGray1,
  Sunflower,
  Sunflower1
} from "../app/StyleCommon";
import {
  intFormatter,
  pct0Formatter,
  pct2Formatter,
  pctFormatter,
  responseTimeFormatter,
  uniquesFormatter
} from "../utils/formatter";
import Btn from "../uikit/btn";
import {TooltipContainer} from "./timeseries.style";
import {Table} from "../uikit";
import {BarWrapper} from "./index.style";
import * as d3 from "d3";
import {ChartWrapper, UnitItem} from './histogram.style';

export default class extends Component {

  constructor(props) {
    super(props);
    const {dimension, items = []} = props.data || {}
    let showCTR = false;
    let singleSite = false;
    if(dimension === 'site' && _.compact(_.uniq(items.map(d => d.site))).length === 1) {
      showCTR = true;
      singleSite = true;
    }
    this.state = {
      unit: 'daily',
      selection: 'all',
      singleSite: singleSite,
      closeState: {
        'CTR': !showCTR,
        'Avg Response Time': true
      }
    };
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate() {
    this.renderChart();
  }

  render() {
    let {data: dataRaw, type, dimension, labelMap = {}, hideResponseTime} = this.props;
    const {unit, selection, closeState, singleSite} = this.state;
    const {data, labels, colorScale} = this.calculateData();
    const hasSelection = Object.keys(closeState).filter(label => !!closeState[label]).length === 0;
    return (
      <ChartWrapper>
        <div className="chart-header">
          <div className="chart-legent">
            {labels.map(label => {
              let legentType = ['CTR', 'Timeouts', 'Avg Response Time'].indexOf(label) < 0 ? 'rect': 'line';
              let selectClass = closeState[label] ? 'none' : '';
              return (
                <div key={label} className={`legent-item ${selectClass}`} onClick={e => {
                  this.setState({closeState: {...closeState, [label]: !closeState[label]}});
                }}>
                  <div className={legentType} style={{backgroundColor: colorScale(label)}}></div>
                  <div>{label}</div>
                </div>
              )
            })}
          </div>
          <div className="chart-actions">
            <Btn className={`select-btn ${hasSelection ? 'none' : 'all'}`} onClick={e => {
              if(hasSelection) {
                this.setState({closeState: labels.reduce((ret, next) => ({...ret, [next]: true}), {})});
              } else {
                this.setState({closeState: {}});
              }
            }}>
              {hasSelection ? 'Deselect All' : 'Select All'}
            </Btn>
            <div style={{textAlign: 'right'}}>
              {
                ['daily', 'weekly', 'monthly'].map(t => {
                  return (
                    <UnitItem
                      key={t}
                      onClick={e => this.setState({unit: t})}
                      className={classnames({selected: unit === t})}>
                      {_.capitalize(t)}
                    </UnitItem>
                  )
                })
              }
            </div>
          </div>
        </div>
        <div ref={this.containerRef} key={Date.now()}/>
      </ChartWrapper>
    )
  }

  calculateData() {
    let {data: dataRaw, type, dimension, labelMap, showPerformanceBreakdown, hideResponseTime} = this.props;
    let {unit, closeState, singleSite} = this.state;
    let unitData = dataRaw.items;
    if(labelMap) {
      unitData = unitData.filter(d => !!labelMap[d[dimension]]);
    }
    if(unit === 'monthly' || unit === 'weekly') {
      let newUnitData = [];
      let dimGrouped = _.groupBy(unitData, _.property(dimension));
      Object.keys(dimGrouped).forEach(dim => {
        let dimData = dimGrouped[dim];
        let unitGrouped = _.groupBy(dimData, d => {
          return unit === 'monthly' ? (d.date.substring(0, 7) + '-01') : moment(d.date).startOf('week').format('YYYY-MM-DD')
        });
        let monthlyGrouped = _.mapValues(unitGrouped, (arr, date) => {
          let agg = this.aggregateItems(arr);
          agg[dimension] = dim;
          agg.date = date;
          return agg;
        });
        newUnitData = newUnitData.concat(Object.keys(monthlyGrouped).sort().map(date => monthlyGrouped[date]));
      })
      unitData = newUnitData;
    }
    const groupedByDim = _.groupBy(unitData, _.property(dimension));
    const groupedByDate = _.groupBy(unitData, _.property('date'));
    const dimAgg = _.mapValues(groupedByDim, (v, k) => _.sum(v.map(d => d.impressions || 0)));
    const data = _.sortBy(Object.keys(groupedByDim), k => - dimAgg[k]).map(dim => {
      let dimLabel = getDimensionLabel(dimension, dim, labelMap, true);
      return {
        [dimension]: dimLabel || dim,
        data: _.sortBy(groupedByDim[dim], _.property('date'))
      };
    });
    const dates = _.uniq(unitData.map(d => d.date)).sort();
    data.forEach(d => {
      let dgrouped = _.groupBy(d.data, _.property('date'));
      d.data = dates.map(date => {
        return dgrouped[date] ? dgrouped[date][0] : {date: date, [dimension]: d[dimension]};
      })
    })
    const agg = dates.map(date => {
      let dateItems = groupedByDate[date].filter(d => {
        let dimLabel = getDimensionLabel(dimension, d[dimension], labelMap, true);
        return !closeState[dimLabel];
      });
      let aggItem = this.aggregateItems(dateItems || []) ;
      aggItem.date = date;
      return aggItem;
    })
    if(data.length > 1) {
      data.unshift({[dimension]: 'All', data: agg});
    }
    // const labels = [...(data.map(d => d[dimension])), 'CTR', 'Timeouts', 'Response Time'];
    const labels = [...(data.map(d => d[dimension])), 'CTR', 'Avg Response Time'];
    if(hideResponseTime) {
      labels.pop();
    }
    let colorScale = d3.scaleOrdinal().domain(labels).range(colorCategory1);
    if(singleSite) {
      colorScale = d3.scaleOrdinal().domain(labels).range([
        '#97c2f8', '#a0d564', '#d2c3f5', '#f5baae'
      ]);
    }
    return {data,unitData,dates,agg,labels, colorScale}
  }

  renderChart() {
    let {data: dataRaw, type, dimension, labelMap, showPerformanceBreakdown} = this.props;
    let {unit, closeState, singleSite} = this.state;
    const {data, dates, agg, colorScale} = this.calculateData()
    const container = this.containerRef.current;
    let legendBufferHeight = data.map(d => d[dimension]).join(' ').length * 13 / window.innerWidth * 20;
    let basicHeight = 200;
    if(window.innerWidth < 800) {
      basicHeight = 300;
      legendBufferHeight += 100;
    }
    const myChart = echarts.init(container, null, {renderer: 'svg', height: basicHeight + legendBufferHeight});


    var option = {
      color: colorCategory1,
      dataZoom: [
        {
          type: 'slider',
          filterMode: 'filter',
          // start: 0,
          start: singleSite ? 0 : (dates.length > 12 ? Math.max(0, 1 - 12 / dates.length) * 100 : 0),
          end: 100,
          height: 20,
          backgroundColor: 'white',
          fillerColor: 'rgba(135, 175, 255, 0.01)',
          moveHandleStyle: {
            color: 'rgba(250,250,250, 0.01)'
          },
          borderColor: 'white',
          emphasis: {
            moveHandleStyle: {
              color: 'rgba(250,250,250, 0.01)'
            },
          }
        }
      ],
      xAxis: {
        data: dates,
        axisLabel: {
          formatter: (value, i) => {
            return moment(value).format(i ? 'MMM DD' : 'MMM DD, YYYY')
          }
        }
      },
      yAxis: [
        {
          type: 'value',
          name: 'Impressions',
          nameLocation: 'start',
          splitNumber:3,
          splitLine: {
            show: false
          },
          axisLabel: {
            formatter: function (value) {
              return uniquesFormatter(value);
            }
          }
        },
        {
          type: 'value',
          name: 'CTR',
          nameLocation: 'center',
          splitNumber:3,
          show: !closeState['CTR'],
          nameGap: -10,
          splitLine: {
            show: false
          },
          axisLabel: {
            formatter: function (value) {
              return pctFormatter(value);
            }
          }
        },
        {
          type: 'value',
          name: 'Avg Response Time',
          nameLocation: 'center',
          nameGap: -10,
          splitNumber:3,
          offset: closeState['CTR'] ? 10 : 60,
          show: !closeState['Avg Response Time'],
          splitLine: {
            show: false
          },
          axisLabel: {
            formatter: function (value) {
              return responseTimeFormatter(value);
            }
          }
        }
      ],
      grid: {
        bottom: '80',
        top: 10,
        left: '60px',
        right: (closeState['CTR'] ? 0 : 60) + (closeState['Avg Response Time'] ? 0 : 60) +  10 + 'px'
      },
      // legend: {
      //   // orient: 'vertical',
      //   left: 'left',
      //   // type: 'scroll',
      //   // bottom: '80',
      //   padding: [10, 120, 50, 20],
      // },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
        },
        // valueFormatter: (value, i) => {
        //   return value > 1 ? uniquesFormatter(value) : pctFormatter(value)
        // },
        enterable: true,
        formatter: (params) => {
          const date = (params[0] || {}).name;
          const items = data.filter(d => (!closeState[d[dimension]])).map(d => {
            return {
              ...(d.data[dates.indexOf(date)]),
              [dimension]: d[dimension],
            }
          });
          return this.renderTooltip(date, items, colorScale);
        }
      },
      series: [
        ...(data.filter(d => {
          return !closeState[d[dimension]];
        }).map(item => {
          return {
            name: item[dimension],
            type: 'bar',
            smooth: true,
            barCategoryGap: '10%',
            barMaxWidth: 15,
            itemStyle: {
              color: colorScale(item[dimension]),
              borderRadius: [5,5, 0, 0]
            },
            emphasis: {
              focus: 'series'
            },
            data: item.data.map(d => d.impressions)
          }
        })),
        ...(data.filter(d => {
          return !closeState[d[dimension]];
        }).map((item, i) => {
          return (showPerformanceBreakdown && !closeState['CTR']) ? {
            name: 'CTR',
            type: 'line',
            lineStyle: {color: colorScale(item[dimension])},
            yAxisIndex: 1,
            data: item.data.map(d => d.ctr)
          } : null;
        })).filter(meta => !!meta),
        ...([
          (!closeState['CTR'] && !showPerformanceBreakdown)  ? {key: 'ctr', label: 'CTR'} : null,
          (!closeState['Avg Response Time']) ?{key: 'response_time', label: 'Avg Response Time'} : null,
          // {key: 'error_rate', label: 'Timeouts'},
        ].filter(meta => !!meta).map(meta => {
          return {
            yAxisIndex: meta.key === 'response_time' ? 2 : 1,
            name: meta.label,
            type: 'line',
            showSymbol: true,
            connectNulls: true,
            smooth: true,
            endLabel: {show: false},
            data: agg.map(d => d[meta.key]),
            itemStyle: {
              color: colorScale(meta.label)
            },
            lineStyle: {
              opacity: 0.5,
              width: 2
            },
            areaStyle: {
              opacity: 0.08,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: colorScale(meta.label)
                },
                {
                  offset: 1,
                  color: 'white'
                }
              ])
            }
          };
        }))
      ]
    };
    myChart.setOption(option);
    window.addEventListener('resize', () => {
      myChart.resize();
    });
    this.myChart = myChart;
  }

  aggregateItems(items) {
    let aggregated = items.reduce((ret, next) => {
      Object.keys(ret).forEach(k => {
        ret[k] += (next[k] || 0);
      });
      return ret;
    }, {impressions: 0, clicks: 0, total_response_time: 0, errors: 0});
    aggregated.ctr = aggregated.clicks / aggregated.impressions;
    aggregated.response_time = aggregated.total_response_time / aggregated.impressions;
    aggregated.error_rate = aggregated.errors / aggregated.impressions;
    return aggregated;
  }

  renderTooltip(date, items, colorScale) {
    let {dimension, labelMap} = this.props;
    let {unit} = this.state;
    let end  = '';
    let today = moment().format('YYYY-MM-DD');
    if(unit === 'weekly') {
      end = ' - ' + moment(date).endOf('week').format('YYYY-MM-DD');
    } else if (unit === 'monthly') {
      end = ' - ' + moment(date).endOf('month').format('YYYY-MM-DD');
    }
    if(end > today) {
      end = today;
    }

    return ReactServer.renderToString(
      <TooltipContainer>
        <div className="time">{date}{end}</div>
        {
          !!items.length &&
          <div className="table-tip">
            <Table
              columns={[
                {
                  key: dimension,
                  label: getDimensionKeyLabel(dimension),
                  style: {maxWidth: '200px', whiteSpace: 'normal'},
                  renderer: (d, i) => (
                    <span>
                      <span className="rect" style={{backgroundColor: colorScale(d[dimension])}}/>
                      {d[dimension]}
                    </span>
                  )
                },
                {
                  key: 'impressions', label: 'Impressions', align: 'center',
                  renderer: d => (
                      <BarWrapper>
                        <div className="num">{intFormatter(d.impressions)}</div>
                        {!!d.impressions && <div className="bar"
                             style={{width: pctFormatter(d.impressions / _.max(items.map(x => x.impressions || 0)))}}></div>}
                      </BarWrapper>
                  )
                },
                {
                  key: 'clicks', label: 'Clicks', align: 'center',
                  renderer: d => (
                      <BarWrapper>
                        <div className="num">{intFormatter(d.clicks)}</div>
                        {!!d.clicks && <div className="bar"
                             style={{width: pctFormatter(d.clicks / _.max(items.map(x => x.clicks || 0)))}}></div>}
                      </BarWrapper>
                  )
                },
                {
                  key: 'ctr', label: 'CTR', align: 'center',
                  renderer: d => (
                      <BarWrapper>
                        <div className="num">{pct2Formatter(d.ctr)}</div>
                        {!!d.ctr && <div className="bar"
                             style={{width: pct2Formatter(d.ctr / _.max(items.map(x => x.ctr || 0)))}}></div>}
                      </BarWrapper>
                  )
                },
                {
                  key: 'response_time', label: 'Avg Response Time', align: 'center',
                  renderer: d => (
                      <BarWrapper>
                        <div className="num">{responseTimeFormatter(d.response_time)}</div>
                        {!!d.response_time && <div className="bar"
                                         style={{width: pct2Formatter(d.response_time / _.max(items.map(x => x.response_time || 0)))}}></div>}
                      </BarWrapper>
                  )
                },
                // {
                //   key: 'errors', label: 'Timeouts', align: 'center',
                //   renderer: d => (
                //       <BarWrapper>
                //         <div className="num">{pct2Formatter(d.error_rate)}</div>
                //         <div className="bar"
                //              style={{width: pctFormatter(d.error_rate / _.max(items.map(x => x.error_rate || 0)))}}></div>
                //       </BarWrapper>
                //   )
                // },
              ]}
              rows={items}
              noDataText={""}
            />
          </div>
        }
      </TooltipContainer>
    );
  }
}