import styled, {css} from 'styled-components';

import Select from '../uikit/selectbox/index';
import {BlueJeans, DarkGray, DarkGray1, LightBlue, LightGray, LightGray1, MediumGray} from '../app/StyleCommon';

export const SelectStyled = styled(Select)`
  .toggler {
    background-color: ${LightGray};
    border: 1px solid ${MediumGray}
    color: ${DarkGray1};
    filter: none;
    border-radius: 4px;
    padding: 8px 10px;
    padding-right: 30px;
    border-radius: 5px;
    font-size: 14px;
    width: 170px;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all .5s ease;
    ${props => props.togglerMinWidth && css`
      min-width: ${props.togglerMinWidth}px;
    `}
    &:hover {
      background-color: ${LightGray1};
    }
    .default-label {
      color: ${DarkGray}
    }
    .prefix-label {
      margin-right: 10px;
      font-weight: bold;
    }
  }
  .menu {
    top: 29px;
    left: 0;
    right: 0;
    min-width: auto;
    border: 1px solid ${MediumGray};
    label {
      &:hover:not(.selected) {
        background: ${LightGray1};
      }
      &.selected {
        background: ${LightBlue};
        color: ${BlueJeans};
        font-weight: bold;
      }
    }
  }
`;
