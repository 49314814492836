import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import _ from 'lodash';
import {withConsumer} from '../app/ApplicationContext';
import {withRouter} from 'react-router';
import Dropzone from 'react-dropzone'
import moment from 'moment';
import * as d3 from 'd3';
import {isValidUrl} from '../utils/misc';
import {Redirect, Link} from 'react-router-dom';
import {ProgressBar, Btn, Table, CondirmDialog, TabContainer, Loader, TableScrollWrapper} from '../uikit/index';
import MainLayout from '../layout/index';
import {ContentHomeWrapper, FilterBarFirst, KeyMetrics, CreativeController, CreativeModule} from './index.style';
import {FilterDropdown, FilterTagWrapper} from './index.style';
import {PaginationStyled} from '../dashboard/index.style';
import {StyledDropdown} from '../uikit/dropdown/styled';
import {post} from "../utils/request";
import {SelectStyled} from './select.style';
import {CreativeWrapper, UploadWrapper, DropzoneWrapper} from './creative.create.style';
import {BlueJeans, MediumGray1} from "../app/StyleCommon";
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import iabAdSizes from "../utils/iabAdSizes";
import RealtimeChart from "../widgets/realtimeChart";
import Sparkline from "../dashboard/sparkline";
import qs from "querystring";


export default withRouter(withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      errorMap: {},
      newCreative: {},
      mode: 'create'
    };
  }

  render() {
    const {loading, error, loaded} = this.state;
    const {newCreative, errorMap, imgSize, mode} = this.state;
    const {file} = newCreative || {};
    const urlParams = qs.parse(window.location.search.slice(1));
    return (
      <MainLayout activeItem="sponsored_ads"
                  loading={loading}
                  breadcrumb={[
        {path: '/sponsored_ads', label: 'Sponsored Ads'},
        {path: '/sponsored_ads/create_creative', label: 'Create'},
      ]}>
        <CreativeWrapper>
          <div className="page-header">
            <h3>Create Creative</h3>
            <p>A creative can be in various formats such as image, video, or audio and is served to users on a widget ads slot. To serve ads, creatives must be added to campaign items.</p>
          </div>
          {
            !loaded && !!urlParams.id &&
            <ProgressBar
              withoutCache={true}
              fixcenter={false}
              url={`/api/queryCreative`}
              params={{id: urlParams.id}}
              successHandler={(data) => {
                if (data && data.creative) {
                  this.setState({newCreative: data.creative, loaded: true});
                }
              }}
              errorHandler={e => this.setState({error: e.message})}/>
          }
          {
            !!error && <ErrorMessage message={error}/>
          }
          <UploadWrapper>
            <div className="field-grid">
              <div className={classnames('field', {error: !!errorMap.name})}>
                <label>Creative Name *</label>
                <input
                  type="text"
                  placeholder=""
                  value={newCreative.name}
                  onChange={e => this.setState({
                    errorMap: {...errorMap, name: null},
                    newCreative: {...newCreative, name: e.target.value}
                  })}/>
                {
                  !!errorMap.name &&
                  <div className="error-msg">{errorMap.name}</div>
                }
              </div>
              <div className={classnames('field', {error: !!errorMap.size})}>
                <label>Size *</label>
                <SelectStyled
                  plainStyle={true}
                  inputable={true}
                  selected={newCreative.size}
                  data={iabAdSizes}
                  onChange={size => {
                    this.setState({errorMap: {...errorMap, size: null}, newCreative: {...newCreative, size: size}})
                  }}/>
                {
                  !!errorMap.size &&
                  <div className="error-msg">{errorMap.size}</div>
                }
              </div>
            </div>
            <div className={classnames('field', {error: !!errorMap.title})}>
              <label>Display Title: *</label>
              <input
                type="text"
                placeholder=""
                value={newCreative.title}
                onChange={e => this.setState({
                  errorMap: {...errorMap, title: null},
                  newCreative: {...newCreative, title: e.target.value}
                })}/>
              {
                !!errorMap.title &&
                <div className="error-msg">{errorMap.title}</div>
              }
            </div>
            <div className={classnames('field', {error: !!errorMap.url})}>
              <label>Click Through URL: *</label>
              <input
                type="text"
                placeholder=""
                value={newCreative.url}
                onChange={e => this.setState({
                  errorMap: {...errorMap, url: null},
                  newCreative: {...newCreative, url: e.target.value}
                })}/>
              {
                !!errorMap.url &&
                <div className="error-msg">{errorMap.url}</div>
              }
            </div>
            <div className={classnames('field', {error: !!errorMap.file})}>
              <label>Drag and drop creative image below: *</label>
              <DropzoneWrapper className="dropzone-wrapper">
                <Dropzone
                  onDrop={files => this.setState({
                    errorMap: {...errorMap, file: null},
                    newCreative: {...newCreative, file: files[0]}
                  })}
                  accept="image/jpeg,image/png,image/gif,image/jpg">
                  {
                    !file &&
                    <div>
                      {
                        !!newCreative.thumbnail && <img src={newCreative.thumbnail}/>
                      }
                      {
                        !newCreative.thumbnail &&
                        <p className="desktop-upload">
                          Drag creative image here to upload. <br/>
                          or <a style={{color: BlueJeans, textDecoration: 'underline'}}>browse</a>
                        </p>
                      }
                    </div>
                  }
                  {
                    !!file &&
                    <div className="droped-file">
                      <div>
                        <img src={URL.createObjectURL(file)} onLoad={e => {
                          const imgSize = e.target.naturalWidth + 'x' + e.target.naturalHeight;
                          this.setState({imgSize: imgSize});
                        }}/>
                        <i className="fa fa-file"></i>
                        <span>{file.name} {imgSize}</span>
                      </div>
                    </div>
                  }
                </Dropzone>
              </DropzoneWrapper>
              {
                !!errorMap.file &&
                <div className="error-msg">{errorMap.file}</div>
              }
            </div>
            <div className="actions">
              <Btn type="reset" onClick={e => this.props.onCancel()}>Cancel</Btn>
              <Btn type="primary" onClick={e => this.uploadCreative()}>
                {newCreative.id ? 'Update' : 'Create'}
              </Btn>
            </div>
          </UploadWrapper>
        </CreativeWrapper>
      </MainLayout>
    )
  }

  validateCreative() {
    const {newCreative, imgSize} = this.state;
    const {name, size, url, file, thumbnail, title} = newCreative;
    const errorMap = {};
    if (!name) {
      errorMap.name = 'Creative Name is required';
    }
    if (!/[a-z0-9_-]/ig.test(name)) {
      errorMap.name = 'Creative Name should only contains letters, number, underscore and dash.';
    }
    if (!title) {
      errorMap.title = 'Creative title is required';
    }
    if (!size) {
      errorMap.size = 'Creative Size is required';
    }
    if (!url) {
      errorMap.url = 'Click through url is required';
    }
    if (!isValidUrl(url)) {
      errorMap.url = 'Not a valid url string';
    }
    if (!thumbnail && !file) {
      errorMap.file = 'Creative Image is required';
    }
    if (imgSize !== size) {
      const [x, y, x1, y1] = `${imgSize}x${size}`.split('x').map(p => Number(p));
      if ((x / y).toFixed(2) !== (x1 / y1).toFixed(2)) {
        errorMap.file = 'Creative Image Size not match.';
      }
    }
    return errorMap;
  }

  uploadCreative() {
    const errorMap = this.validateCreative();
    if (Object.keys(errorMap).length > 0) {
      this.setState({errorMap: errorMap});
      return;
    }
    const {newCreative} = this.state;

    var formData = new FormData();
    formData.append('advertiser', this.props.advertiser);
    Object.keys(newCreative).forEach(field => {
      formData.append(field, newCreative[field]);
    })
    this.setState({loading: true});
    post('/api/uploadCreative', formData, {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(result => {
      this.setState({loading: false});
      if (result.success) {
        this.props.history.push(`/sponsored_ads/creatives`);
      } else {
        this.setState({error: 'upload failed'});
      }
    }).catch(e => {
      this.setState({loading: false, error: e.message});
    });
  }
}))