import React from 'react';
import ReactDOM from 'react-dom';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import * as d3 from 'd3';
import moment from 'moment';
import qs from 'querystring';
import {post} from '../utils/request';
import {sites, datePeriodWindows, algorithms} from '../utils/metadata';
import {algorithms as adAlgorithms} from '../utils/metadata.ads';
import {withConsumer} from '../app/ApplicationContext';
import MainLayout from '../layout/index';
import {ProgressBar, Table, DateSelectorDropdown, TableScrollWrapper, Btn} from '../uikit/index';
import {GridContainer} from '../uikit/grid/index.style';
import {WidgetDetailsWrapper, WidgetOverview, KeyMetricsComparison, KeymetricCard, BarWrapper} from './report.style';
import {SelectInlineStyled, CreativeWrapper, TagWrapper, EDMWrapper, ColorBlock, colors} from './report.style';
import {dashboardCards, timeseriesDimensions} from './report.metadata';
import {PaginationStyled} from '../uikit/Pagination/custom.style';
import Sparkline from "../dashboard/sparkline";
import {StatusTag} from "./index.style";
import {intFormatter, pct2Formatter, pctFormatter, periodFormat} from "../utils/formatter";
import {ErrorMessage} from "../settings/index.style";
import CreativeRenderer from './creativeRenderer';
import HistogramTimeseries from "../dashboard/histogram";


export default withRouter(withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      period: undefined,
      // dimension: 'email_campaign_id',
      dimension: 'creative_id',
      page: 1,
      pageSize: 8,
      sortBy: 'ctr',
      sortDirection: 'desc',
    };
  }

  render() {
    const urlParams = qs.parse(window.location.search.slice(1));
    const id = urlParams.id;
    const {loading, data, error, period} = this.state;
    const {dimension, page, pageSize, sortBy, sortDirection} = this.state;
    const {campaign, keyMetrics = {}, keyMetricsComparison = {}, histogramBreakDown = {}} = data || {};
    const dimensionValues = (keyMetricsComparison.items || []).map(d => d[dimension]);
    const colorScale = d3.scaleOrdinal().domain(dimensionValues).range(colors);
    let sortedItems = _.sortBy(keyMetricsComparison.items || [], _.property(sortBy));
    if (sortDirection === 'desc') {
      sortedItems = sortedItems.reverse();
    }
    let pageItems = sortedItems.slice((page - 1) * pageSize, page * pageSize);
    return (
      <MainLayout activeItem="sponsored_ads" loading={loading}
                  breadcrumb={[
                    {path: '/sponsored_ads', label: 'Sponsored Ads'},
                    {path: `/sponsored_ads/campaign/report`, label: `Campaign Report`}
                  ]}>
        <WidgetDetailsWrapper>
          <div className="detail-header">
            <div className="title">
              <span>Campaign Delivery Report</span>
              <Link to={`/sponsored_ads/campaigns/edit?id=${id}`}><i className="fa fa-cog"/> Settings</Link>
            </div>
            <div className="actions">
              <DateSelectorDropdown
                mode={'range'}
                placeholder={'placeholder'}
                period={period}
                shortcuts={datePeriodWindows}
                onDateRangeUpdated={period => this.setState({period, data: null})}/>
            </div>
          </div>
          {
            !data && !error &&
            <ProgressBar
              withoutCache={true}
              fixcenter={true}
              url={`/api/queryCampaignInsights`}
              params={this.buildInsightQuery()}
              successHandler={data => {
                this.setState({data: data, period: period || data.period});
              }}
              errorHandler={e => this.setState({error: e.message})}/>
          }
          {
            !!error && <ErrorMessage>{error}</ErrorMessage>
          }
          {
            !!data &&
            <WidgetOverview>
              <div className="basic-info">
                <div className="attr">
                  <label>Campaign Name</label>
                  <div className="name">
                    {campaign.name}
                  </div>
                </div>
                <div className="attr">
                  <label>Period</label>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    {periodFormat(campaign)} &nbsp;&nbsp;
                    (
                    <StatusTag status={campaign.status}>{campaign.status}</StatusTag>
                    {
                      !!campaign.goal_value && campaign.goal_type === 'FIXED' && !!campaign.impressions &&
                      <span
                        style={{whiteSpace: 'nowrap'}}>{pct2Formatter(campaign.impressions / campaign.goal_value)}</span>
                    }
                    )
                  </div>
                </div>
                <div className="attr">
                  <label>Targeting</label>
                  <div className="name">
                    {typeof campaign.segment === 'string' && (campaign.segment === 'RON' ? 'Run of Network' : campaign.segment)}
                    {
                      typeof campaign.segment !== 'string' &&
                      <span>
                      {campaign.segment.name} &nbsp;<a
                        href={`https://meid.mediacorp.sg/segments?id=${campaign.segment.id}`} rel="noopener noreferrer" target="_blank"><i
                        className="fa fa-external-link"/></a>
                    </span>
                    }
                  </div>
                </div>
                <div className="attr">
                  <label style={{alignSelf: 'flex-start', marginTop: '8px'}}>Key Metrics</label>
                  <GridContainer type='1-1-1-1-1'>
                    {!!data && dashboardCards.map(({formatter, key, label, color, icon}) => {
                      let keyMetricData = keyMetrics[key];
                      let value = keyMetricData;
                      debugger;
                      if(!keyMetricData.value && key === 'errorsStat') {
                        return null;
                      }
                      let trending = null;
                      if (keyMetricData && typeof keyMetricData === 'object') {
                        value = keyMetricData.value;
                        trending = keyMetricData.data;
                      }
                      return (
                        <KeymetricCard
                          className={`card ${key}`}
                          key={key + value}
                          type={color}
                          onClick={e => {
                            if (key === 'errorsStat') {
                              this.showErrorLogs();
                            }
                          }}>
                          <div className="top">
                            <i className={icon} aria-hidden="true"></i>
                            <span>{label}</span>
                          </div>
                          <div className="bottom">
                            <h3>{formatter(value)}</h3>
                            <div className="rate">
                              <Sparkline data={trending} size="small" formatter={formatter}/>
                            </div>
                          </div>
                        </KeymetricCard>
                      )
                    })}
                  </GridContainer>
                </div>
              </div>
              {/*<label style={{alignSelf: 'flex-start', marginTop: '10px'}}>Analysis by</label>*/}
              <div className="attr metric-table">
                <KeyMetricsComparison className="key-metric-wrapper">
                  <TableScrollWrapper>
                    <Table
                      columns={[
                        {
                          key: 'color',
                          label: '',
                          align: 'left',
                          // hidden: dimensionValues.length < 2,
                          renderer: d => {
                            return <ColorBlock style={{backgroundColor: colorScale(d[dimension])}}></ColorBlock>
                          }
                        },
                        {
                          key: 'key',
                          label: (
                            <span style={{display: 'flex', alignItems: 'center', whiteSpace: 'no-wrap'}}>
                              <span style={{
                                whiteSpace: 'nowrap',
                                fontWeight: 'normal',
                                fontSize: '12px',
                                marginLeft: '10px'
                              }}>Analysis by</span>
                              <SelectInlineStyled
                                selected={dimension}
                                data={timeseriesDimensions}
                                useFixed={true}
                                itemRenderer={meta => {
                                  return (
                                    <div className="dimension-item" key={meta.key}>
                                      <div>
                                        <div className="name">{meta.label}</div>
                                        <div className="description">{meta.description}</div>
                                      </div>
                                    </div>
                                  )
                                }}
                                onChange={dimension => {
                                  this.setState({dimension: dimension, page: 1}, () => this.refreshChart('dimension'))
                                }}/>
                            </span>
                          ),
                          align: 'center',
                          renderer: d => {
                            if (keyMetricsComparison.dimension === 'creative_id') {
                              return (
                                <CreativeWrapper>
                                  <CreativeRenderer creative={d.creative}/>
                                  {/*<Link to={`/creative-report?id=${d.creative.id}`}><i*/}
                                  {/*  className="fa fa-external-link"/></Link>*/}
                                </CreativeWrapper>
                              )
                            }  else if (keyMetricsComparison.dimension === 'widget_id') {
                              return (
                                <div className="widget-name-card">
                                  {
                                    !!d.widget &&
                                    <Link to={`/widget/${d.widget.id}`} className="widget-name-card">
                                      <div>{d.widget.name}</div>
                                      <div className="tag-list">
                                        {(d.widget.tags || []).map(t => {
                                          return <span className={`tag ${t.split('::')[0]}`} key={t}>{t.split('::').pop()}</span>
                                        })}
                                        {(!d.widget.tags || !d.widget.tags.length) && '--'}
                                        {this.renderWidgetAlgorithm(d.widget)}
                                      </div>
                                      {/*<a className="name" onClick={e => this.showPreviewPopup(d.id)}>*/}
                                      {/*<i className="fa fa-eye"/>*/}
                                      {/*</a>*/}
                                    </Link>
                                  }
                                  {!d.widget && <div><strong>{d.widget_id}</strong></div>}
                                </div>
                              );
                            }

                          }
                        },
                        {
                          key: 'impressions',
                          label: 'Impressions',
                          align: 'center',
                          sortable: true,
                          renderer: d => sortBy !== 'impressions' ? intFormatter(d.impressions) : (
                            <BarWrapper>
                              <div className="num">{intFormatter(d.impressions)}</div>
                              <div className="bar"
                                   style={{width: pctFormatter(d.impressions / keyMetricsComparison.maxImpressions)}}></div>
                            </BarWrapper>
                          )
                        },
                        {
                          key: 'clicks',
                          label: 'Clicks',
                          sortable: true,
                          align: 'center',
                          renderer: d => sortBy !== 'clicks' ? intFormatter(d.clicks) : (
                            <BarWrapper>
                              <div className="num">{intFormatter(d.clicks)}</div>
                              <div className="bar"
                                   style={{width: pctFormatter(d.clicks / keyMetricsComparison.maxClicks)}}></div>
                            </BarWrapper>
                          )

                        },
                        {
                          key: 'ctr',
                          label: 'CTR',
                          sortable: true,
                          align: 'center',
                          renderer: d => sortBy !== 'ctr' ? pct2Formatter(d.ctr) : (
                            <BarWrapper>
                              <div className="num">{pct2Formatter(d.ctr)}</div>
                              <div className="bar"
                                   style={{width: pct2Formatter(d.ctr / keyMetricsComparison.maxCtr)}}></div>
                            </BarWrapper>
                          )
                        },
                      ]}
                      rows={pageItems}
                      sort={{sortColumn: sortBy, direction: sortDirection}}
                      sortChange={orderBy => {
                        this.setState({'sortBy': orderBy});
                      }}
                      directionChange={direction => {
                        this.setState({'sortDirection': direction});
                      }}
                      noDataText={"No Data"}
                    />
                  </TableScrollWrapper>
                  <PaginationStyled
                    onChange={p => this.setState({page: p})}
                    current={page}
                    pageSize={pageSize}
                    total={keyMetricsComparison.items.length}
                    showLessItems={true}
                    hideOnSinglePage={true}/>
                </KeyMetricsComparison>
              </div>
              {
                !!histogramBreakDown &&
                <div className="attr timeseries">
                  {/*<label style={{alignSelf: 'flex-start'}}></label>*/}
                  <div>
                    <HistogramTimeseries
                      key={histogramBreakDown.timestamp + sortBy + sortDirection + page}
                      dimension={histogramBreakDown.dimension}
                      hideResponseTime={true}
                      data={histogramBreakDown}/>
                  </div>
                </div>
              }
            </WidgetOverview>
          }
        </WidgetDetailsWrapper>
      </MainLayout>
    )
  }

  renderWidgetAlgorithm(d) {
    if (d.cxenseWidget) {
      return <span className="tag algo">Cxense</span>;
    }
    else if (d.thinkWidget) {
      return <span className="tag algo">Think Analytics</span>;
    } else {
      const {layoutParams = {}} = d.layoutConfig || {};
      let usedSlots = _.uniq(Object.values(layoutParams.slotGroupMapping || {}));
      let usedSlotGroup = (d.slotGroups || []).filter(s => usedSlots.indexOf(s.name) >= 0);
      let algos = _.uniq(usedSlotGroup.map(s => s.algorithm));
      let backfill = _.uniq(_.compact(usedSlotGroup.map(s => (s.backfill || {}).algorithm)))[0];
      if (algos.length > 1) {
        return <span className="tag algo">Hybrid</span>
      } else if (algos.length === 1) {
        let algo = algos[0];
        let allAlgos = [...algorithms, ...adAlgorithms];
        let meta = allAlgos.find(d => d.key === algo);
        let backfillmeta = allAlgos.find(d => d.key === backfill);
        return (
          <>
            <span className="tag algo" key={algo}>{meta ? meta.label : algo}</span>
            {!!backfill && <span className="tag backfill"
                                 key={backfill}>, Fallback: <strong>{backfillmeta ? backfillmeta.label : backfill}</strong></span>}
          </>
        )
      }
    }
  }

  buildInsightQuery() {
    const urlParams = qs.parse(window.location.search.slice(1));
    const campaignId = urlParams.id;
    return {...(_.omit(this.state, ['error', 'loading', 'data'])), id: campaignId};
  }


  refreshChart(insightType) {
    const query = this.buildInsightQuery();
    query.requiredInsights = [insightType];
    this.setState({loading: true});
    post('/api/queryCampaignInsights', query).then(newData => {
      let {data: oldData} = this.state;
      this.setState({data: {...oldData, ...newData}, loading: false});
    }).catch(e => {
      this.setState({loading: false});
    });
  }
}))

