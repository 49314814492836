import React, {Component} from 'react';
import ReactServer from 'react-dom/server';
import * as d3 from 'd3';
import moment from 'moment';
import _ from 'lodash';
import {ReRenderHook} from '../utils/hooks';
import {Wrapper, TooltipWrapper} from './timeseries.style';
import {SelectStyled} from './select.style';
import createTooltip from '../uikit/tooltip/index';
import {
  DarkGray, BlueJeans, Grass, Sunflower, LightGray1, LightGray, MediumGray,
  MediumGray1, Sunflower1
} from "../app/StyleCommon";
import {intFormatter, pctFormatter, pct2Formatter, getTicks} from "../utils/formatter";
import {metrics} from './common';
const globalTransformCache = {};

export default class extends Component {
  constructor(props) {
    super(props);
    let {data, period} = this.props;
    const {start, end} = period;
    data.forEach(d => {
      d.ctr = (d.clicks / d.impressions) || 0;
      d.dateObj = moment(d.date).startOf('day').toDate();
    });
    data = _.sortBy(data, _.property('date'));
    let dates = data.map(d => d.dateObj);
    let processedPeriod = [moment(start).toDate(), moment(end).add(1, 'day').toDate()];
    // let processedPeriod = [_.min(dates), _.max(dates)];

    let periodLength = moment(period[1]).diff(moment(period[0]), 'day');
    this.state = {
      data: data,
      dates: dates,
      period: processedPeriod,
      periodLength: periodLength,
      firstMetric: 'impressions',
      secondMetric: 'ctr'
    }
  }

  componentWillReceiveProps(props) {
    globalTransformCache[this.transformKey] = null;
  }

  render() {
    const {data} = this.state;
    const {firstMetric, secondMetric} = this.state;
    return (
      <Wrapper>
        <div className="legend">
          <div className="legend-item">
            <span className="rect1 yellow"/>
            {this.renderDropdownMetric(firstMetric, 'firstMetric')}
          </div>
          <div className="legend-item">
            <span className="line green"/>
            {this.renderDropdownMetric(secondMetric, 'secondMetric')}
          </div>
        </div>
        <div className="svgContainer" ref="svgContainer"/>
        <ReRenderHook renderer={this.renderChart.bind(this)}/>
      </Wrapper>
    )
  }

  renderDropdownMetric(metric, stateKey) {
    return (
      <SelectStyled
        selected={metric}
        defaultLabel={'All Sites'}
        data={metrics}
        showGroupBatch={false}
        onChange={metric => {
          this.setState({[stateKey]: metric})
        }}/>
    )
  }

  renderChart() {
    const container = this.refs.svgContainer;
    d3.select(container).select('*').remove();
    const {period: defaultPeriod} = this.props;
    const {data, period, periodLength, firstMetric, secondMetric} = this.state;
    const svgWidth = container.clientWidth || 500;
    const svgHeight = 200;
    const margin = {top: 15, left: 25, bottom: 50, right: 90};
    const margin2 = {top: svgHeight - margin.bottom + 30, left: margin.left, bottom: 0, right: margin.right};
    const width = svgWidth - margin.left - margin.right;
    const height = svgHeight - margin.top - margin.bottom;
    const height2 = svgHeight - margin2.top - margin2.bottom;
    const getLabel = metric => (metrics.find(m => m.key === metric) || {}).label || metric;
    const getFormatter = metric => (metrics.find(m => m.key === metric) || {}).formatter;
    const svg = d3.select(container).append('svg').attr('class', 'ddd').attr('width', svgWidth).attr('height', svgHeight);
    var currentMode;

    var x = d3.scaleTime().range([0, width]).domain([period]);
    var xFixed = d3.scaleTime().range([0, width]).domain(period);

    var y1Domain = [0, d3.max(data.map(d => d[firstMetric])) * 1 || 10];
    var y2Domain = [0, d3.max(data.map(d => d[secondMetric])) * 1.1 || 0.1];

    var y1 = d3.scaleLinear().range([height, 0]).domain(y1Domain);
    var y2 = d3.scaleLinear().range([height, 0]).domain(y2Domain);

    var xAxis = d3.axisBottom(x).ticks(3);
    var xAxis2 = d3.axisBottom(xFixed).ticks(6);

    let ticks1 = getTicks(y1Domain, 3);
    let ticks2 = ticks1.map(d => (y2Domain[1] * d / y1Domain[1]));
    var yAxis1 = d3.axisLeft(y1).tickValues(ticks1).tickPadding(12).tickFormat(getFormatter(firstMetric)).tickSize(10);
    var yAxis2 = d3.axisRight(y2).tickValues(ticks2).tickPadding(10).tickFormat(getFormatter(secondMetric)).tickSize(width);

    var brush = d3.brushX().extent([[0, 0], [width, height2]]).on("brush end", brushed);
    var zoom = d3.zoom().scaleExtent([1, periodLength / 7]).translateExtent([[0, 0], [width, height]]).extent([[0, 0], [width, height]]).on("zoom", zoomed);

    var defs = svg.append("defs");
    defs.append("clipPath").attr("id", "clip").append("rect").attr("width", width).attr("height", height + 20);
    var gradient = defs.append("linearGradient").attr("id", "barGradient");
    gradient.attr('x1', 0).attr('y1', 0).attr('x2', 0).attr('y2', 1);
    gradient.append("stop").attr("offset", '0%').attr("stop-color", Sunflower);
    gradient.append("stop").attr("offset", '100%').attr("stop-color", Sunflower1);

    var context = svg.append("g").attr("class", "context").attr("transform", "translate(" + margin2.left + "," + margin2.top + ")");
    var axises = svg.append("g").attr("class", "axises").attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    var focus = svg.append("g").attr("class", "focus").attr("clip-path", "url(#clip)").attr("transform", `translate(${margin.left}, ${margin.top})`);
    var tips = svg.append("g").attr("class", "tips").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    axises.append("g").attr("class", "axis axis--x").attr("transform", "translate(0," + height + ")").call(xAxis);
    axises.append("g").attr("class", "axis axis--y2").call(yAxis2);
    axises.append("g").attr("class", "axis axis--y1").call(yAxis1);
    context.append("g").attr("class", "brush").call(brush).call(brush.move, xFixed.range());
    context.append("g").attr("class", "axis axis--x").attr("transform", "translate(0," + 0 + ")").call(xAxis2);

    axises.append("text").text(getLabel(firstMetric)).attr("class", "axis-label first").attr('x', -margin.left).attr('y', height + margin.top + 2);
    axises.append("text").text(getLabel(secondMetric)).attr("class", "axis-label second").attr('x', width + 10).attr('y', height + margin.top + 2);

    var line = d3.line().curve(d3.curveLinear).x(d => x(d.dateObj)).y(d => y2(d[secondMetric]));

    //zoom & brush funcs
    var zoomRect = svg.append('g').append("rect").attr("class", "zoom").attr("width", width).attr("height", height)
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
      .call(zoom);

    var verticalLine = focus.append('line').attr('class', 'verticalLine').attr('stroke', MediumGray).attr('y2', height).attr('y1', 0).attr('stroke-dasharray', '5 5').attr('opacity', 0);

    var inited = false;
    let initTransform = globalTransformCache[this.transformKey];
    if (!initTransform) {
      startTransition('default');
    } else {
      updateTransform(initTransform);
      inited = true;
    }

    d3.select(this.refs.zoomin).on('click', () => startTransition('zoomin'));
    d3.select(this.refs.zoomout).on('click', () => startTransition('zoomout'));

    function startTransition(mode) {
      if (mode === 'default') {
        if (periodLength <= 30) {
          updateTransform(d3.zoomIdentity.translate(0, 0).scale(1));
        } else {
          let startDate = moment(defaultPeriod.start);
          let endDate = moment(defaultPeriod.end);
          let windowLength = endDate.diff(startDate, 'day');
          let k = periodLength / windowLength;
          let tx = k * xFixed(startDate);
          // updateTransform(d3.zoomIdentity.translate(-tx, 0).scale(1));
          updateTransform(d3.zoomIdentity.translate(-tx, 0).scale(k));
        }
      }
      else if (mode === 'zoomin') {
        zoom.scaleBy(zoomRect, 1.5);
      }
      else if (mode === 'zoomout') {
        zoom.scaleBy(zoomRect, 0.5);
      }
      inited = true;
    }

    function getBandwidth() {
      let [start, end] = x.domain();
      return x(moment(start).add(1, 'days').toDate()) - x(start);
    }

    const transformKey = this.transformKey;

    function updateTransform(t, type) {
      globalTransformCache[transformKey] = t;
      x.domain(t.rescaleX(xFixed).domain());
      let bandwidth = getBandwidth();

      focus.selectAll(".circle").attr("cx", d => x(d.dateObj)).attr("cy", d => y2(d[secondMetric]));
      focus.selectAll(".line").attr("d", line);
      focus.selectAll(".bar").attr("x", d => x(d.dateObj) - bandwidth / 8).attr("width", d => bandwidth / 4);

      axises.selectAll(".axis--x").call(xAxis);
      type !== 'brush' && context.selectAll(".brush").call(brush.move, x.range().map(t.invertX, t));
      type !== 'zoom' && svg.select(".zoom").call(zoom.transform, t);
      updateAxisRotate()
      plotFocusData(data);
    }

    function plotFocusData(data) {
      focus.selectAll(".line").remove();
      focus.selectAll(".area").remove();
      focus.selectAll(".bargroup").remove();
      focus.selectAll(".circles").remove();
      plotBar(focus, data);

      focus.append("path").datum(data).attr("class", `line`).attr("d", line);

      let domainArr = x.domain();
      let dataPoints = moment(domainArr[1]).diff(moment(domainArr[0]), 'day');
      if (dataPoints < 50) {
        plotCircles(focus, data);
      }
    }

    function plotBar(container, data) {
      let barWidth = getBandwidth() / 3;
      barWidth = barWidth > 20 ? 20 : barWidth;
      container.append('g').attr("class", "bargroup").selectAll('.bar')
        .data(data)
        .enter().append('rect')
        .attr('class', `bar ${firstMetric}`)
        .attr('x', d => x(d.dateObj) - barWidth / 2)
        .attr('y', d => y1(d[firstMetric]))
        .attr('rx', 1)
        .attr('width', barWidth)
        .attr('height', (d) => height - y1(d[firstMetric]))
    }

    function plotCircles(container, data) {
      const [first = {}, second = {}] = data;
      let distance = x(second.date) - x(first.date);
      container.append('g').attr("class", "circles").selectAll('.circle')
        .data(data)
        .enter()
        .append('circle')
        .attr("class", `circle`)
        .attr("fill", Grass)
        .attr("stroke", distance > 5 ? 'rgb(255,255,255)' : 'none')
        .attr("r", d => distance > 5 ? '6px' : '3px')
        .attr("cx", d => x(d.dateObj))
        .attr("cy", d => y2(d[secondMetric]));
    }

    function updateAxisRotate() {
      if (width < 400) {
        axises.selectAll(".axis--x").selectAll('text').style("text-anchor", "end").style('transform', 'rotate(-15deg) translate(0, -5px)');
      }
    }

    function brushed() {
      if (!inited) return;
      if (d3.event.sourceEvent && d3.event.sourceEvent.type === "zoom") return; // ignore brush-by-zoom
      var s = d3.event.selection || xFixed.range();
      var t = d3.zoomIdentity.scale(width / (s[1] - s[0])).translate(-s[0], 0);
      updateTransform(t, 'brush');
    }

    function zoomed() {

      if (d3.event.sourceEvent && d3.event.sourceEvent.type === "brush") return; // ignore zoom-by-brush
      var t = d3.event.transform;
      updateTransform(t, 'zoom');
    }

    var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    // tooltip section;

    var getPointData = function () {
      let offsetX = d3.event.offsetX + (isFirefox ? margin.left : 0);
      let bandWidth = getBandwidth();
      let barWidth = getBandwidth() / 3;
      barWidth = barWidth > 20 ? 20 : barWidth;
      let m = moment(x.invert(offsetX - margin.left + bandWidth / 2)).startOf('day');
      let pointData = data.find(d => m.isSame(d.dateObj)) || {};
      return {pointData, mnt: m};
    }
    var tooltip = createTooltip({
      targetSelector: 'dynamic',
      tipCreator: d => {
        let {pointData, mnt} = getPointData();
        let dateFormat = 'DD-MMM-YYYY, dddd';
        return ReactServer.renderToString(
          <TooltipWrapper>
            <div className="time">{mnt.format(dateFormat)}</div>
            <div className="metricList">
              {metrics.map(m => {
                return (
                  <span className="metric" key={m.key}>
                    <strong>{m.label}</strong>: {m.formatter(pointData[m.key])}
                  </span>
                )
              })}
            </div>
          </TooltipWrapper>
        )
      },
      directionFunc: 'top',
      positionFunc: d => {
        let {pointData, mnt} = getPointData();
        let offsetX = d3.event.offsetX + (isFirefox ? margin.left : 0);
        let offsetY = d3.event.offsetY + (isFirefox ? (margin.top * 2) : 0);
        return [
          d3.event.pageX - offsetX + margin.left + x(mnt.toDate()),
          d3.event.pageY - offsetY + 20
        ]
      }
    });

    function handleMouseOver(d, i) {
      tooltip.show.call(this, d, i);
      let {pointData, mnt} = getPointData();
      d3.selectAll('.bar').filter((d, index) => (d && mnt.isSame(d.dateObj))).style('fill', Sunflower1)
      d3.selectAll('.bar').filter((d, index) => (d && !mnt.isSame(d.dateObj))).style('fill', 'url(#barGradient)')
      d3.selectAll('.circle').filter((d, index) => (d && mnt.isSame(d.dateObj))).attr('stroke', Grass)
      d3.selectAll('.circle').filter((d, index) => (d && !mnt.isSame(d.dateObj))).attr('stroke', Grass)
      verticalLine
        .attr('x1', x(mnt.toDate()))
        .attr('x2', x(mnt.toDate()))
        .attr('pointer-events', 'none')
        .attr('opacity', 1);
    };

    function handleMouseOut(d) {
      tooltip.hide.call(this, d);
      verticalLine.attr('opacity', 0);
      let {pointData, mnt} = getPointData();
      d3.selectAll('.bar').attr('stroke', LightGray).style('fill', 'url(#barGradient)')
      d3.selectAll('.circle').attr('stroke', LightGray).attr('stroke', LightGray)
    };

    zoomRect.on('mouseover', handleMouseOver).on('mousemove', handleMouseOver).on('mouseout', handleMouseOut)
  }
}
