import React from 'react';
import styled, {css} from 'styled-components';
import {
  BitterSweet1,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass1, LightGray, LightGray1, MediumGray,
  MediumGray1, LightBlue
} from "../../app/StyleCommon";

import {WidgetWrapper} from './grid.style';
import {CNA_Web_Home_MostWatched} from './CNA_Web_Home_MostWatched.style';

export const CNA_Web_Video_RelatedVideos = styled(CNA_Web_Home_MostWatched)`
  &&& {
    background-color: rgb(66, 63, 61);
    .widget-title {
      color: white;
      padding: 10px 0 20px 0;
    }
    .item-list {
      .item {
        .item-inner  {
          .thumbnail {
          }
          
          .brief {
            .section {
              display: block;
              color: white;
            }
            .title {
              color: white;
              font-size: 16px;
              min-height: 106px;
            }
            .publish_date {
              color: rgba(255,255,255,.5);
            }
          }
        }
      }
      .arrow {
      }
    }
  }
`