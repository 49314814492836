import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import * as d3 from 'd3';
import {ReRenderHook} from '../utils/hooks';
import createTooltip from '../uikit/tooltip/index';
import {BlueJeans, DarkGray, Grass, LightGray1, MediumGray, MediumGray1} from "../app/StyleCommon";
import {intFormatter} from "../utils/formatter";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
  }
  render() {
    if(!this.props.data) {
      return null;
    }
    return (
      <div ref={this.wrapperRef}>
        <ReRenderHook renderer={this.renderSVG.bind(this)}/>
      </div>
    )
  }

  renderSVG() {
    let {data, size, formatter, period, theme} = this.props;
    let sparkFormatter = formatter || intFormatter;
    const container = this.wrapperRef.current;
    var svgWidth = size === 'small' ? 80 : 120, svgHeight = svgWidth / 4;
    var margin = {top: 0, left: 0, bottom: 0, right: 0};
    var width = svgWidth - margin.left - margin.right;
    var height = svgHeight - margin.top - margin.bottom;

    d3.select(container).selectAll("svg").remove();
    const svg = d3.select(container).append('svg').attr('width', svgWidth).attr('height', svgHeight);

    var x = d3.scaleLinear().range([0, width]);
    var x1 = d3.scaleLinear().range([0, svgWidth]);
    var y = d3.scaleLinear().range([1, height]);
    var y1 = d3.scaleLinear().range([1, height]);

    x.domain([0, data.length - 1]);
    y.domain([d3.max(data.map(d => d * 1.1)) || 1, 0]);

    var colors = [BlueJeans, Grass];

    var line = d3.line().x((d, index) => x(index)).y((d) => y(Number(d)));

    var area = d3.area().x((d, index) => x(index)).y0(height).y1((d) => y((d)));

    svg.append('path')
      .attr("class", "sparkline")
      .attr('stroke', theme === 'dark' ? MediumGray1 : 'rgba(255,255,255,.5)')
      .attr('stroke-width', 1.5)
      .attr('fill', 'none')
      .attr('fill-opacity', '0.4')
      .attr('transform', 'translate(' + margin.left + ',' + (margin.top) + ')')
      .attr('d', line(data));

    svg.append('path')
      .attr('fill', theme === 'dark' ? MediumGray1 :'white')
      .attr('opacity', '0.05')
      .attr('transform', 'translate(' + margin.left + ',' + (margin.top) + ')')
      .attr('d', area(data));


    var verticalLine = svg.append('line')
      .attr('stroke', '#535353')
      .attr('stroke-width', 1)
      .attr('y2', svgHeight)
      .attr('stroke-dasharray', '5 5')
      .attr('opacity', 0);

    var tooltip = createTooltip({
      targetSelector: 'dynamic',
      tipCreator: d => {
        const index = Math.round(x.invert(d3.event.offsetX - margin.left));
        const end = (period || {}).end;
        const time = moment(end).add(index - data.length + 1, 'day').format('MMM Do');
        return `${time}: <strong>${sparkFormatter(data[index])}</strong>`;
      },
      directionFunc: 'top',
      positionFunc:  d => {
      return [
        d3.event.pageX,
        d3.event.pageY - d3.event.offsetY + 10
      ]
    }});

    var handleMouseOver = function (d, i) {
      tooltip.show.call(this, d, i);
      var lineX = d3.event.offsetX - margin.left
      verticalLine
        .attr('x1', lineX)
        .attr('x2', lineX)
        .attr('y1', 0)
        .attr('pointer-events', 'none')
        .attr('opacity', 1);
    };

    var handleMouseOut = function (d) {
      tooltip.hide.call(this, d);
      verticalLine.attr('opacity', 0);
    };

    svg.append('rect')
      .attr('width', width)
      .attr('height', height)
      .attr('x', margin.left)
      .attr('y', margin.top)
      .style('fill', 'transparent')
      .style('stroke', 'none')
      .on('mouseover', handleMouseOver)
      .on('mousemove', handleMouseOver)
      .on('mouseout', handleMouseOut)
  }

}