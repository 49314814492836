import React from 'react';
import classnames from 'classnames';
import ReactDOM from 'react-dom';
import ReactServer from 'react-dom/server';
import {withRouter} from 'react-router';
import _ from 'lodash';
import moment from 'moment';
import {post} from '../utils/request';
import {ProgressBar, Table, TableScrollWrapper, Btn, Loader} from '../uikit/index';
import {CondirmDialog, TabContainer} from '../uikit/index';
import {Spinner} from "../uikit/Loader/index.style";
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import {SegmentSelectorWrapper, ControlBar, CreateWrapper, SelectSegmentWrapper, SegmentInfoForDialog} from './builder-segment.style';
import {SelectStyled} from './select.style';
import {PaginationStyled} from '../dashboard/index.style';
import {intFormatter, periodFormat, uniquesFormatter} from "../utils/formatter";
import createTooltip from '../uikit/tooltip/index';

export default class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
      sortBy: 'name',
      sortDirection: 'asc',
      errorMap: {},
    };
  }

  render() {
    const {loading, data, error, segment} = this.state;
    return (
      <SegmentSelectorWrapper>
        {
          !data &&
          <ProgressBar
            withoutCache={true}
            fixcenter={false}
            url={`/api/querySegments`}
            params={{}}
            successHandler={data => this.setState({data: data})}
            errorHandler={e => this.setState({error: e.message})}/>
        }
        {
          !!error && <ErrorMessage message={error}/>
        }
        {!!loading && <Loader type="absolute"/>}
        {this.renderExistingSegmentList()}
      </SegmentSelectorWrapper>
    )
  }

  renderExistingSegmentList() {
    const {loading, data, error, segment} = this.state;
    const {keyword, page, pageSize, sortBy, sortDirection} = this.state;
    const {segments = []} = data || {};
    const filteredSegments = segments.filter(d =>  {
      if(!d.ssoSize) return false;
      if(!keyword) return true;
      if(d.name.toLowerCase().indexOf(String(keyword).toLowerCase()) >= 0) {
        return true;
      }
      if(String(d.id).toLowerCase().indexOf(String(keyword).toLowerCase()) >= 0) {
        return true;
      }
      return false;
    })
    return (
      <SelectSegmentWrapper>
        <ControlBar>
          <div className="searchBox">
            <i className="fa fa-search"/>
            <input
              type="text"
              placeholder="Search Segment"
              value={keyword}
              onChange={e => this.setState({keyword: e.target.value, page: 1})}/>
          </div>
        </ControlBar>
        <TableScrollWrapper className="table-wrapper">
          <Table
            columns={[
              {
                key: 'rank',
                align: 'center',
                label: 'No',
                renderer: (d, i) => i + 1
              },
              {
                key: 'name', align: 'left', label: 'Name',
                renderer: d => {
                  return (
                    <div
                      className="segment-name">{d.name}
                    </div>
                  )
                }
              },
              {
                key: 'size', align: 'left', label: 'SSO Size',
                sortable: true,
                renderer: d => uniquesFormatter(d.ssoSize)
              },
              {
                key: 'creator',
                align: 'left',
                label: 'Creator',
              },
              {
                key: 'created',
                align: 'left',
                label: 'Created',
                renderer: d => moment(d.CreatedDateTime).format('MMM DD, YYYY HH:mm A'),
              },
              {
                key: 'action-btn',
                label: 'Actions',
                align: 'center',
                renderer: (d) => {
                  return (
                    <div className="actions">
                      <Btn type="primary" size="mini" onClick={e => this.selectSegment(d)}>Select</Btn>
                    </div>
                  )
                }
              }
            ]}
            rows={filteredSegments.slice((page - 1) * pageSize, page * pageSize)}
            sort={{sortColumn: sortBy, direction: sortDirection}}
            sortChange={sortBy => {
              this.updateFilterOptions('sortBy', sortBy);
            }}
            directionChange={direction => {
              this.updateFilterOptions('sortDirection', direction);
            }}
            noDataText="No results for selected criteria"
          />
        </TableScrollWrapper>
        <PaginationStyled
          onChange={p => this.setState({page: p})}
          current={page}
          pageSize={pageSize}
          total={filteredSegments.length}
          showLessItems={false}
          hideOnSinglePage={true}/>
      </SelectSegmentWrapper>
    )
  }

  selectSegment(segment) {
    const {dimensionOptions} = this.state.data || {};
    segment.dimensionOptions = dimensionOptions;
    this.props.onConfirm(segment);
  }
};