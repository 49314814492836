import React from 'react';
import styled, {css} from 'styled-components';
import {
  BitterSweet,
  BitterSweet1,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass, Grass1, LightBlue, LightGray, LightGray1, MediumGray,
  MediumGray1, Sunflower1
} from "../app/StyleCommon";

export const TagManagerWrapper = styled.div`
  position: relative;
  h3 {
    border-bottom: 1px solid ${MediumGray};
    width: 90%;
    padding: 0 0 10px 0;
  }
  .tag-groups {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .tag-list-group {
      width: 45%;
    }
  }
  .tag-list-group {
    >label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 667px) {
        display: block;
        input {
          margin-left: 0 !important;
          margin-top: 10px !important;
        }
      }
      >span {
        min-width: 120px;
        display: block;
        flex: 0;
      }
      .select-box {
        flex-grow: 1;
        margin: 0 10px 0 30px;
        width: 100%;
        .toggler {
          width: auto;
          padding: 3px 10px;
        }
      }
      input.tag-input {
        flex-grow: 1;
        padding: 5px 10px;
        font-size: 14px;
        border: 1px solid ${MediumGray};
        border-radius: 5px;
        margin: 0 10px 0 30px;
        outline: none;
        width: 130px;
      }
    }
    .tag-list {
      display: inline-flex;
      align-items: center;
      margin: 5px 0;
      margin-right: 10px;
      margin-right: 20px;
      width: 100%;
      padding: 5px 0 10px 0;
      .error {
        color: ${BitterSweet}
      }
      .no-data {
        color: ${MediumGray1};
      }
      .tag {
        white-space: nowrap;
        margin: 0 10px 0 5px;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        justify-content: space-between;
        border: 1px solid ${LightGray};
        background: ${LightGray};
        padding: 0px 5px;
        border-radius: 4px;
        padding-right: 30px;
        position: relative;
        &.project {
          background: #d79beb78;
        }
        &.platform {
          background: #57c5f766;
        }
        &.page {
          background: #a0d46894;
        }
        &.algo {
          background: #ffce54ab;
        }
        &.exclude {
          background: ${BitterSweet};
          color: white;
          text-decoration: line-through;
        }
        &.include {
          border: 1px solid ${MediumGray};
        }
        &:not(:first-child) {
          content: ' - AND - ';
        }
        i {
          position: absolute;
          right: 0;
        }
      }

      i {
        margin-right: 5px;
      }
      i.fa-times {
        color: ${Sunflower1};
        margin-left: 10px;
        cursor: pointer;
        position: absolute;
        top: 3px;
      }
    }
  }
  .desc-editor {
    img {
    }
  }
  .actions {
    margin-top: 10px;
    padding-top: 15px;
    text-align: right;
  }

`