import React, {Component, useEffect} from 'react';
import _ from 'lodash';

export const ReRenderHook = (props) => {
  let rendererDebounced = _.debounce(props.renderer || _.once, 100)
  useEffect(() => {
    props.renderer();
    const handler = e => rendererDebounced();
    window.addEventListener('resize', handler);
    return () => {
      window.removeEventListener('resize', handler);
    };
  });
  return <div/>;
}