import React, {Component, useEffect} from 'react';
import moment from 'moment';
import * as d3 from 'd3';
import {countries} from './countries';
import {duration, hmsDuration, intFormatter, pct2Formatter, pctFormatter, uniquesFormatter} from "./formatter";
export * from './layouts';

export const widgetTypeMapping = {
  'Content Based Filtering': 'Content Based Filtering',
  'Content Based Filtering(Real-time Feedback)': 'Content Based Filtering (Real-time Feedback)',
  'Contextual (Content Based Filtering v2)': 'Content Based Filtering v2',
  'Contextual (Content Based Filtering v3)': 'Content Based Filtering v3',
  'Contextual (Content Based Filtering v4)': 'Content Based Filtering v4',
  'Trending': 'Trending (6 hours)',
  'Trending (Concurrents)': 'Trending (5 mins)',
  'Trending (P3H)': 'Trending (3 hours)',
  'Trending (P12H)': 'Trending (12 hours)',
  'Trending (P24H)': 'Trending (24 hours)',
  'Trending in Social': 'Trending in Social',
  'User Short-term Interest Based': 'User Short-term Interest Based',
  'User Long-term Interest Based': 'User Long-term Interest Based',
  'Behavioural+Contextual (Word2vec)': 'Word2vec',
  'Cxense': 'Cxense',
  'Random': 'Fallback',
}

export const resourceTypes = [
  {
    key: 'Mediacorp Content',
    label: 'Mediacorp Content',
    data: [
      {
        key: 'article', label: 'Article',
      },
      {
        key: 'video', label: 'Video',
      },
      {
        key: 'audio', label: 'Audio',
      },
      // {
      //   key: 'keyword', label: 'Keyword',
      // },
    ]
  },
  // {
  //   key: 'iPrice Coupon',
  //   label: 'iPrice Assets',
  //   data: [
  //     {
  //       key: 'coupon', label: 'Coupon'
  //     }
  //   ]
  // }
  // {
  //   key: 'ads', label: 'Sponsored ad'
  // }
];
export const dayparts = [
  {key: 'morning', label: 'Morning', description: '5:00 AM - 10:00 PM'},
  {key: 'midday', label: 'Midday', description: '10:00 AM - 2:00 PM'},
  {key: 'afternoon', label: 'Afternoon', description: '2:00 PM - 7:00 PM'},
  {key: 'evening', label: 'Evening', description: '7:00 PM - 00:00 AM'},
  {key: 'overnight', label: 'Overnight',description: '00:00 AM - 5:00 AM'},
  {key: 'weekday', label: 'Weekday', description: 'Monday - Friday'},
  {key: 'weekend', label: 'Weekend', description: 'Saturday, Sunday'},
]

export let algorithms = [
  {
    key: 'fpm',
    shortLabel: 'Frequent Pattern Mining',
    label: 'Frequent Pattern Mining',
    supported_res_types: ['article', 'video', 'audio']
  },
  {
    key: 'pmi',
    shortLabel: 'Pointwise Mutual Information',
    label: 'Pointwise Mutual Information',
    supported_res_types: ['article', 'video', 'audio']
  },
  {
    key: 'w2v',
    shortLabel: 'Word2vec',
    label: 'Word2vec',
    description: 'word2vec article embedding based on article co-viewing graph',
    supported_res_types: ['article', 'video', 'audio']
  },
  {
    key: 'cbf',
    shortLabel: 'Content Based Filtering',
    label: 'Content Based Filtering',
    description: 'Content Based Filtering embedding vector uses Title & Tags',
    supported_res_types: ['article', 'video', 'audio']
  },
  {
    key: 'cbf1',
    shortLabel: 'Content Based Filtering(Real-time Feedback)',
    label: 'Content Based Filtering(Real-time Feedback)',
    supported_res_types: ['article', 'video', 'audio']
  },
  {
    key: 'cbf_v1_css',
    label: 'Content Based Filtering(Custom semantic similarity)',
    description: 'Content based filtering for vectors v1 using custom semantic similarity',
    shortLabel: 'Content Based Filtering(Custom semantic similarity)',
    supported_res_types: ['article', 'video', 'audio']
  },
  {
    key: 'cbf_v2',
    shortLabel: 'Content Based Filtering v2',
    label: 'Content Based Filtering v2',
    description: 'Content Based Filtering embedding vector uses Title & Abstract',
    supported_res_types: ['article', 'video', 'audio']
  },
  {
    key: 'cbf_v3',
    shortLabel: 'Content Based Filtering v3',
    label: 'Content Based Filtering v3',
    description: 'Content Based Filtering embedding vector uses Title & Abstract',
    supported_res_types: ['article', 'video', 'audio']
  },
  {
    key: 'cbf_v4',
    shortLabel: 'Content Based Filtering v4',
    label: 'Content Based Filtering v4',
    description: 'Content Based Filtering embedding vector uses Title & Abstract',
    supported_res_types: ['article', 'video', 'audio']
  },
  {
    key: 'usr1',
    shortLabel: 'User Short-term Interest Based',
    label: 'User Short-term Interest Based',
    description: 'Calculates user\'s interest based on the last 24 hours of viewing history',
    supported_res_types: ['article', 'video', 'audio']
  },
  {
    key: 'usr2',
    shortLabel: 'User Long-term Interest Based',
    label: 'User Long-term Interest Based',
    description: 'Uses user\'s long term interest based on browsing history',
    supported_res_types: ['article', 'video', 'audio']
  },
  {
    key: 'nmf',
    shortLabel: 'Non Negative Matrix Factorisation',
    label: 'Non Negative Matrix Factorisation',
    description: 'Matrix Factorisation based recommendation system',
    supported_res_types: ['article', 'video', 'audio']
  },
  {
    key: 'nmf_cna_app',
    shortLabel: 'Matrix Factorisation (CNA App)',
    description: 'Matrix Factorisation based recommendation system for cna app',
    label: 'Matrix Factorisation (CNA App)', supported_res_types: ['article', 'video', 'audio']
  },
  {
    key: 'trn',
    shortLabel: 'Trending (6 hours)',
    description: 'Trending articles in the past 6 hours',
    label: 'Trending (6 hours)', supported_res_types: ['article', 'video', 'audio']
  },
  {
    key: 'trn_sns',
    shortLabel: 'Trending in Social',
    label: 'Trending in Social',
    supported_res_types: ['article', 'video', 'audio']
  },
  {
    key: 'trn_sec',
    shortLabel: 'Trending in Section',
    label: 'Trending in Section', supported_res_types: ['article', 'video', 'audio']
  },
  {
    key: 'trn_ccr',
    shortLabel: 'Trending (5 mins)',
    description: 'Trending articles in the last 5 mins',
    label: 'Trending (5 mins)', supported_res_types: ['article', 'video', 'audio']
  },
  {
    key: 'trn_h03',
    shortLabel: 'Trending (3 hours)',
    description: 'Trending articles in the last 3 hours',
    label: 'Trending (3 hours)', supported_res_types: ['article', 'video', 'audio']
  },
  {
    key: 'trn_h12',
    shortLabel: 'Trending (12 hours)',
    description: 'Trending articles in the last 12 hours',
    label: 'Trending (12 hours)', supported_res_types: ['article', 'video', 'audio']
  },
  {
    key: 'trn_h24',
    shortLabel: 'Trending (24 hours)',
    description: 'Trending articles in the last 24 hours',
    label: 'Trending (24 hours)', supported_res_types: ['article', 'video', 'audio']
  },
  {
    key: 'vm1',
    shortLabel: 'Video Rec (version 1)',
    label: 'Video Rec (version 1)', supported_res_types: ['article', 'video']
  }
];

export const setAlgorithmMetadata = function(data) {
  algorithms = data;
}

export let rankingModels = [
  {key: '', label: 'None'},
  {key: 'rnk1', label: 'Ranking Model (Roy & ChernHorng)', supported_res_types: ['article', 'video']},
  {key: 'rnk2', label: 'Ranking Model (Varun)', supported_res_types: ['article', 'video']},
  {key: 'rnk3', label: 'Ranking Model (ShiXuan)', supported_res_types: ['article', 'video']},
  {key: 'rnk4', label: 'Ranking Model (Ktawut)', supported_res_types: ['article', 'video']},
  {key: 'rnk5', label: 'Ranking Model (ThompsonSampling)', supported_res_types: ['article', 'video']},
  {key: 'rnk6', label: 'Ranking Model (Concurrents)', supported_res_types: ['article', 'video']},
  {key: 'rnk7', label: 'Ranking Model (Aamir)', supported_res_types: ['article', 'video']},
  {key: 'rnk8', label: 'Ranking Model (Subhargh)', supported_res_types: ['article', 'video']},
  {key: 'rnk9', label: 'Ranking Model (Viknesh)', supported_res_types: ['article', 'video']},
  {key: 'rnk9base', label: 'Ranking Model (Viknesh) (Base)', supported_res_types: ['article', 'video']},
];

export const setRankingAlgorithmMetadata = function(data) {
  rankingModels = data;
}

export const datePeriodWindows = [
  // {key: 'today', label: 'Today'},
  // {key: 'yesterday', label: 'Yesterday'},
  {key: 'last7days', label: 'Last 7 Days'},
  // {key: 'last14days', label: 'Last 14 Days'},
  {key: 'last30days', label: 'Last 30 Days'},
  {key: 'last60days', label: 'Last 60 Days'},
  {key: 'last90days', label: 'Last 90 Days'},
  {key: 'last180days', label: 'Last 180 Days'}
];

export const facetMeta = [
  {
    key: 'author',
    label: 'Author',
    contentTypes: ['article', 'video'],
  },
  {
    key: 'section',
    label: 'Section',
    contentTypes: ['article', 'video', 'keyword'],
  },
  {
    key: 'language',
    label: 'Content Language',
    contentTypes: ['article'],
  },
  {
    key: 'categories',
    label: 'Categories',
    contentTypes: ['article', 'video', 'audio'],
  },
  {
    key: 'tags/keywords',
    label: 'Keywords',
    contentTypes: ['article', 'audio'],
  },
  {
    key: 'tags/semantic_keywords',
    label: 'Semantic Keywords',
    contentTypes: ['article'],
  },
  {
    key: 'tags/named_entities',
    label: 'Named Entities',
    contentTypes: ['article'],
  },
  {
    key: 'taxonomy/iab_tier1/name',
    label: 'Taxonomy Tier1',
    contentTypes: ['article'],
  },
  {
    key: 'taxonomy/iab_tier2/name',
    label: 'Taxonomy Tier2',
    contentTypes: ['article'],
  },
  {
    key: 'media_attributes/genre',
    label: 'Genre',
    contentTypes: ['video', 'audio'],
  },
  {
    key: 'media_attributes/cast',
    label: 'Cast',
    contentTypes: ['video'],
  },
  {
    key: 'media_attributes/monetizable',
    label: 'Monetizable',
    contentTypes: ['video', 'audio'],
  },
  {
    key: 'media_attributes/is_series',
    label: 'Is Series',
    contentTypes: ['video', 'audio'],
  },
  {
    key: 'media_attributes/product',
    label: 'Product',
    contentTypes: ['video'],
  },
  {
    key: 'media_attributes/billing_id',
    label: 'Billing ID',
    contentTypes: ['video'],
  },
  {
    key: 'media_attributes/rating',
    label: 'Ratings',
    contentTypes: ['video'],
  },
  {
    key: 'media_attributes/cast_crew',
    label: 'Cast Crew',
    contentTypes: ['video'],
  },
  {
    key: 'media_attributes/taxonomy_tier1',
    label: 'Taxonomy Tier1',
    contentTypes: ['video'],
  },
  {
    key: 'media_attributes/taxonomy_tier2',
    label: 'Taxonomy Tier2',
    contentTypes: ['video'],
  },
  {
    key: 'media_attributes/audio_language',
    label: 'Audio Language',
    contentTypes: ['video', 'audio'],
  },
  {
    key: 'media_attributes/cuepoints',
    label: 'Cue points',
    contentTypes: ['video'],
  },
  {
    key: 'media_attributes/status',
    label: 'Media Status',
    contentTypes: ['video'],
  },
  {
    key: 'media_attributes/media_type',
    label: 'Media type',
    contentTypes: ['video'],
  },
  {
    key: 'subscriptions',
    label: 'Subscriptions',
    contentTypes: ['video'],
  },
  {
    key: 'media_attributes/duration',
    label: 'Duration',
    isNumeric: true,
    contentTypes: ['video', 'audio'],
  },
  {
    key: 'content_tags',
    label: 'Content Tags',
    contentTypes: ['article', 'video'],
    supportSites: ['cna', 'cnalifestyle', 'cnaluxury'],
    disabledInCustomRule: true
  },
  {
    key: 'is_deleted',
    label: 'Is Deleted',
    contentTypes: ['article', 'video', 'audio'],
    disabledInCustomRule: true
  },
  {
    key: 'content_sub_type',
    label: 'Content Sub Type',
    contentTypes: ['article', 'video', 'audio'],
    disabledInCustomRule: true
  },
  {
    key: 'url',
    label: 'URL',
    contentTypes: ['article', 'video'],
    filterType: 'regex',
    disabledInCustomRule: true
  },
  {
    key: 'view_count',
    label: 'View Count',
    contentTypes: ['article', 'video'],
    isNumeric: true,
    disabledInCustomRule: true
  },
  {
    key: 'bounce_rate',
    label: 'Bounce Rate',
    contentTypes: ['article', 'video'],
    isNumeric: true,
    disabledInCustomRule: true
  },
  {
    key: 'ripple_score',
    label: 'Ripple Score',
    contentTypes: ['article', 'video'],
    isNumeric: true,
    disabledInCustomRule: true
  },
  {
    key: 'id',
    label: 'Content ID',
    contentTypes: ['article', 'video', 'audio'],
    isNumeric: false,
    disabledInCustomRule: true
  },
  // {
  //   key: 'avg_time_spent_on_page',
  //   label: 'Avg Dwell Time',
  //   contentTypes: ['article', 'video'],
  //   disabledInCustomRule: true
  // },
  // {
  //   key: 'is_evergreen_content',
  //   label: 'Ever Green Content',
  //   supportSites: ['cna', 'cnalifestyle', 'cnaluxury'],
  //   contentTypes: ['article', 'video'],
  //   disabledInCustomRule: true
  // },
  // {
  //   key: 'is_feelgood_content',
  //   label: 'Feel Good Content',
  //   supportSites: ['cna', 'cnalifestyle', 'cnaluxury'],
  //   contentTypes: ['article', 'video'],
  //   disabledInCustomRule: true
  // },
  // {
  //   key: 'is_original_content',
  //   label: 'Original Content',
  //   supportSites: ['cna', 'cnalifestyle', 'cnaluxury'],
  //   contentTypes: ['article', 'video'],
  //   disabledInCustomRule: true
  // },
  // {
  //   key: 'source',
  //   label: 'Source',
  //   supportSites: ['cna', 'cnalifestyle', 'cnaluxury'],
  //   contentTypes: ['article', 'video'],
  //   disabledInCustomRule: true
  // },
  {
    key: 'store',
    label: 'Store',
    contentTypes: ['coupon'],
  },
  {
    key: 'category',
    label: 'Category',
    contentTypes: ['coupon'],
  },

  {
    key: 'label',
    label: 'Label',
    contentTypes: ['coupon'],
  },
];

const stringOperatorOptions = [
  {key: '$match', label: 'match'},
  {key: '$nmatch', label: 'not match'}
];

const booleanOperatorOptions = [
  {key: '$is', label: 'is'},
  {key: '$isnot', label: 'is not'}
];

export const abTestTargetOptions = [
  // {
  //   key: 'country',
  //   label: 'Country',
  //   operators: booleanOperatorOptions,
  //   data: countries
  // },
  // {
  //   key: 'accessMethod',
  //   label: 'Access Method',
  //   operators: booleanOperatorOptions,
  //   data: [
  //     {key: 'Mobile Web', label: 'Mobile Web'},
  //     {key: 'Fixed Web', label: 'Fixed Web'},
  //     {key: 'Mobile App', label: 'Mobile App'},
  //     {key: 'Others', label: 'Others'},
  //   ]
  // },
  {
    key: 'os',
    label: 'OS',
    operators: booleanOperatorOptions,
    data: [
      {key: 'Android', label: 'Android'},
      {key: 'iOS', label: 'iOS'},
      {key: 'Windows', label: 'Windows'},
      {key: 'Mac OS', label: 'Mac OS'},
      {key: 'Linux', label: 'Linux'},
      {key: 'Others', label: 'Others'},
    ]
  },
  {
    key: 'browser',
    label: 'Browser',
    operators: booleanOperatorOptions,
    data: [
      {key: 'Chrome', label: 'Chrome'},
      {key: 'Mozilla', label: 'Mozilla'},
      {key: 'Explorer', label: 'Explorer'},
      {key: 'Safari', label: 'Safari'},
      {key: 'Opera', label: 'Opera'},
      {key: 'Others', label: 'Others'},
    ]
  },
  // {
  //   key: 'ip',
  //   label: 'IP Address',
  //   operators: stringOperatorOptions
  // },
  // {
  //   key: 'url',
  //   label: 'Page URL',
  //   operators: stringOperatorOptions
  // },
];

export const targets = [
  {
    key: 'all',
    label: 'All Users'
  },
  {
    key: 'target_users',
    label: 'Target Users'
  },
  {
    key: 'non_target_users',
    label: 'Non Target Users'
  }
];

export const metrics = [
  {
    key: 'ctr',
    label: 'CTR',
    type: 'efficiency',
    priority: 'P1',
    formatter: pct2Formatter,
    formatter1: pct2Formatter,
  },
  {
    key: 'response_time',
    label: 'Response Time',
    type: 'efficiency',
    formatter: d => d3.format('.2')((d || 0) / 1000) + 's',
    formatter1: d => d3.format('.2')((d || 0) / 1000) + 's',
  },
  {
    key: 'error_rate',
    label: 'Error Rate',
    type: 'efficiency',
    formatter: pct2Formatter,
    formatter1: pct2Formatter,
  },
  {
    key: 'impressions',
    label: 'Impressions',
    type: 'cumulative',
    formatter: uniquesFormatter,
    formatter1: intFormatter,
  },
  {
    key: 'clicks',
    label: 'Clicks',
    type: 'cumulative',
    formatter: uniquesFormatter,
    formatter1: intFormatter,
  },
  // {
  //   key: 'visible_impressions',
  //   label: 'Visible Impressions',
  //   type: 'cumulative',
  //   formatter: uniquesFormatter,
  //   formatter1: intFormatter,
  // },
  {
    key: 'errors',
    label: 'Errors',
    type: 'cumulative',
    formatter: uniquesFormatter,
    formatter1: intFormatter,
  },
  {
    key: 'video_starts',
    label: 'Video View',
    type: 'cumulative',
    priority: 'P0',
    formatter: uniquesFormatter,
    formatter1: intFormatter,
  },
  {
    key: 'video_completions',
    label: 'Video Completion',
    type: 'cumulative',
    formatter: uniquesFormatter,
    formatter1: intFormatter,
  },
  {
    key: 'avg_view_duration',
    label: 'Watch Time',
    type: 'cumulative',
    priority: 'P0',
    formatter: hmsDuration,
    formatter1: hmsDuration,
  },
  {
    key: 'watch_time',
    label: 'Watch Time (Avg Daily)',
    type: 'efficiency',
    priority: 'P0',
    formatter: hmsDuration,
    formatter1: hmsDuration,
    description: (
      <div>
        <p>Total Watch Time per unique video viewer (Daily Average)</p>
      </div>
    )
  },
  {
    key: 'bounces',
    label: 'Bounces',
    type: 'cumulative',
    formatter: uniquesFormatter,
    formatter1: intFormatter,
  },
  {
    key: 'uvv',
    label: 'UVV (Avg Daily)',
    type: 'cumulative',
    formatter: uniquesFormatter,
    formatter1: intFormatter,
    priority: 'Guard Rail',
    description: (
      <div>
        <p>UVV: Unique Video Viewer (Daily Average)</p>
        <div>* global level, apply to any user assigned to this engine.</div>
      </div>
    )
  },
  {
    key: 'avg_session_duration',
    label: 'Session Duration',
    type: 'cumulative',
    formatter: hmsDuration,
    formatter1: hmsDuration,
    priority: 'P2',
    description: (
      <div>
        <p>A session initiates when a user interact with our app and no session is currently active, for example, their previous session has timed out</p>
        <p>A session ends or times out after 30 minutes of user inactivity.</p>
        <div>* global level, apply to any user assigned to this engine.</div>
      </div>
    )
  },
  {
    key: 'retention_rate',
    label: 'Retention Rate',
    type: 'efficiency',
    priority: 'P1',
    formatter: pctFormatter,
    formatter1: pct2Formatter,
    description: (
      <div>
        <p>D7: 7 Days Retention rate. formula: <br/>remainingActiveUser / activeUser7DaysAgo</p>
        <p>M1: 1 Month Retention rate. formula: <br/>remainingActiveUser / activeUser1MonthAgo</p>
        <div>* global level, apply to any user assigned to this engine.</div>
      </div>
    )
  },
  {
    key: 'retention_rate_30days',
    label: 'Retention Rate (30 Days)',
    type: 'efficiency',
    formatter: pctFormatter,
    formatter1: pct2Formatter,
  },

  {
    key: 'uv',
    label: 'Daily UV',
    type: 'cumulative',
    formatter: uniquesFormatter,
    formatter1: intFormatter,
  },
  {
    key: 'subscribes',
    label: 'Subscriptions',
    type: 'cumulative',
    formatter: uniquesFormatter,
    formatter1: intFormatter,
  },
  {
    key: 'bookmarks',
    label: 'Add to List',
    type: 'cumulative',
    priority: 'P2',
    formatter: uniquesFormatter,
    formatter1: intFormatter,
  },
  {
    key: 'onboard',
    label: 'Onboard',
    type: 'cumulative',
    formatter: uniquesFormatter,
    formatter1: intFormatter,
  },
  {
    key: 'downloads',
    label: 'Downloads',
    type: 'cumulative',
    formatter: uniquesFormatter,
    formatter1: intFormatter,
  },
  {
    key: 'vcr',
    label: 'Completion Rate',
    type: 'efficiency',
    priority: 'P1',
    formatter: pctFormatter,
    formatter1: pct2Formatter,
  },
  {
    key: 'vpr',
    label: 'Video Play Rate',
    type: 'efficiency',
    formatter: pctFormatter,
    formatter1: pct2Formatter,
  },
  {
    key: 'bounce_rate',
    label: 'Bounce Rate',
    type: 'efficiency',
    formatter: pctFormatter,
    formatter1: pct2Formatter,
  },
  // {
  //   key: 'uniques',
  //   label: 'Uniques',
  //   type: 'efficiency',
  //   formatter: uniquesFormatter,
  //   formatter1: intFormatter,
  // },
];

export const metricMap = metrics.reduce((ret, next) => {
  return {...ret, [next.key]: next.label};
}, {});

export const engineMap = {
  ta: 'Think Analytics',
  boost: 'BOOST',
}

export const itemFields = [
  {key: 'id', label: 'Content ID', disabled: true},
  {key: 'title', label: 'Title', disabled: true},
  {key: 'site', label: 'Site key'},
  {key: 'site_label', label: 'Site Label'},
  {key: 'thumbnail_type', label: 'Content Type'},
  {key: 'publish_date', label: 'Publish Date'},
  {key: 'thumbnail', label: 'Thumbnail'},
  {key: 'image', label: 'Image'},
  {key: 'categories', label: 'Categories'},
  {key: 'main_category', label: 'Main category'},
  {key: 'sub_category', label: 'Sub category'},
  {key: 'genre', label: 'Genre'},
  {key: 'author', label: 'Author'},
  {key: 'video_length', label: 'Video Length'},
  {key: 'uuid', label: 'Item UUID'},
  {key: 'url', label: 'Url'},
  {key: 'click_through_url', label: 'Click Through Url', disabled: true},
  {key: 'click_tracker', label: 'Click Tracker'},
]

export const defaultItemFields = [
  'id',
  'title',
  'site',
  'site_label',
  'thumbnail_type',
  'publish_date',
  'thumbnail',
  'image',
  'categories',
  'main_category',
  'sub_category',
  'genre',
  'author',
  'video_length',
  'uuid',
  'url',
  'click_through_url',
  'click_tracker',
]
