import React from 'react';

export default [
  {
    key: 'dashboard',
    label: 'Dashboard',
    icon: <i className="fa fa-bar-chart" aria-hidden="true"></i>,
    href: '/dashboard'
  },
  {
    key: 'widget',
    label: 'Widget',
    icon: <i className="fa fa-th-large" aria-hidden="true"></i>,
    href: '/widget'
  },
  {
    key: 'abtesting',
    label: 'A/B Test',
    icon: <i className="fa fa-thermometer-full" aria-hidden="true"></i>,
    href: '/abtesting'
  },
  // {
  //   key: 'simulator',
  //   label: 'MeWATCH Test',
  //   icon: <i className="fa fa-caret-square-o-right" aria-hidden="true"></i>,
  //   href: '/simulate/home'
  // },
  // {
  //   key: 'sponsored_ads',
  //   label: 'Sponsored Ads',
  //   icon: <i className="fa fa-diamond" aria-hidden="true" style={{fontSize: '12px'}}></i>,
  //   // icon: <i className="fa fa-buysellads" aria-hidden="true"></i>,
  //   href: '/sponsored_ads'
  // },
  {
    key: 'settings',
    label: 'Settings',
    icon: <i className="fa fa-cog" aria-hidden="true"></i>,
    href: '/settings'
  },
  {
    key: 'logout',
    label: 'Sign Out',
    icon: <i className="fa fa-sign-out" aria-hidden="true"></i>,
    href: '/login'
  },
]