import styled, {css} from 'styled-components';
import * as d3 from 'd3';

import {
  BlueJeans, LightGray1, DarkGray1, Grass, LightGray, PrimaryDarkBlue,
  BitterSweet, DarkGray, DarkBlue, MediumGray1, MediumGray, PrimaryMediumBlue,
  SecondaryMediumBlue, PrimaryLightBlue
} from '../app/StyleCommon';

export const ABDetailsWrapper = styled.div`
  background-color: white;
  padding: 20px;
  @media screen and (max-width: 667px) {
    padding: 20px 10px;
  }
  >.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    //border-bottom: 1px solid ${MediumGray1};
    .dropdown-toggle {
      width: 20px;
      i {
        right: 0;
      }
    }
    @media screen and (max-width: 667px) {
      display: block;
      padding-bottom: 10px;
      .header {
        margin-bottom: 10px;
        display: block !important;
        h3 {
          font-size: 14px;
          white-space: nowrap;
        }
        .title {
          width: 100% !important;
        }
        .toggler {
          width: 100%;
        }
      }
      .filters {
        width: 100%;
      }
     .date-selector {
        flex-wrap: wrap !important;
      }
      .month-panel {
        width: 100%  !important;
      }
    }
  }
`;

export const ABOverview = styled.div`
  .details-item {
    margin: 12px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 13px;
    @media screen and (max-width: 667px) {
      display: block;
      >label {
        width: 100% !important;
        text-align: left !important;
      }
      >div {
        padding: 5px 0 !important;
      }
    }
    > label {
      flex: none;
      display: inline-block;
      width: 150px;
      text-align: right;
      font-weight: bold;
      margin-right: 20px;
      color: ${DarkGray};
      height: 100%;
      &.table-title {
        align-self: flex-start;
        padding-top: 10px;
      }
    }
    >div {
      width: 98%;
      padding: 5px 10px;
      border-radius: 3px;
      &:not(:last-child) {
        border-bottom: 1px solid ${LightGray};
      }
    }
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    table {
      tr td, tr th {
        font-size: 13px;
      }
    }
    .tag-list {
      display: flex;
      padding: 0 !important;
      flex-wrap: wrap;
      .tag {
        //border: 1px solid ${LightGray1};
        white-space: nowrap;
        border-radius: 5px;
        font-size: 11px;
        display: inline !important;;
        background: ${LightGray};
        font-weight: bold;
        margin: 5px 2px 5px 2px !important;
        padding: 0px 5px !important;
        &.project {
          background: #d79beb78;
        }
        &.platform {
          background: #57c5f766;
        }
        &.page {
          background: #a0d46894;
        }
        &.algo {
          background: #ffce54ab;
        }
        &.backfill {
          background: #f1f2f3;
          font-size: 9px;
        }
      }
    }
    .schedule {
      .status {
        font-weight: 700;
        color: ${BlueJeans};
      }
    }
    &.timeseries {
      margin-top: 20px;
      background: white;
      >label {
        margin-top: 5px;
      }
      >div {
        padding: 0;
      }
      .timeseries-header {
        margin: 5px 0 20px 0;
        display: flex;
        align-items: center;
      }
      .timeseries-body {
        margin-left: -10px;
      }
    }
  }
`;

export const ABBasicInfo = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    > div {
      width: 25%;
      &:first-child {
        width: 75%;
      }
    }
    .title {
      display: flex;
      align-items: center;
      img {
        border-radius: 50%;
        width: 40px;
        height: 40px;
      }
      .name {
        margin-left: 10px;
        > div {
          display: flex;
          button {
            margin-left: 5px;
            color: ${DarkGray};
            a {
              color: ${DarkGray};
            }
          }
        }
        h3 {
          margin: 0;
        }
        p {
          font-size: 13px;
          margin: 0;
        }
      }
    }
    .filters {
      display: flex;
      align-items: center;
      .variant-filter {
        display: flex;
        align-items: center;
        margin-right: 10px;
        .select-box {
          width: 100%;
        }
        .toggler {
          font-size: 13px;
          padding: 9px 10px;
        }
        .menu {
          top: 36px;
          border-radius: 5px;
        }
      }
      .date-range {
        width: 100%;
      }
      .date-selector-dropdown {
        .toggler {
          background-color: ${LightGray};
          border-radius: 5px;
          font-size: 13px;
          border: 1px solid ${MediumGray};
        }
      }
    }
  }
  .compare-date {
    font-size: 10px;
    padding: 5px 0;
    i {
      margin-right: 10px;
    }
  }
`;

export const ABMetricsOverview = styled.div`
  table {
    border: none;
    overflow: visible;
    thead {
      tr {
        background-color: white;
        border: none;
        border-bottom: 1px solid ${LightGray1};
        th {
          border: none;
          font-size: 14px;
          padding: 4px 10px 4px 0;
        }
      }
      .no-data {
        top: 40px;
        border-top: 1px solid ${MediumGray1};
        width: 100%;
      }
    }
    td {
      text-align: left;
      .widget-name-card {
        cursor: pointer;
      }
    }
  }
`;

export const PctBar = styled.div`
  height: 18px;
  position: relative;
	background: #f1f2f3;
  border-radius: 4px;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    background-color: ${BlueJeans};
    ${props => css`
      width: ${props.ratio * 100}%;
    `}
  }
  &:after {
    display: block;
    position: absolute;
    top: 3px;
    ${props => css`
      font-size: 12px;
      left: ${props.ratio * 100}%;
      transform: translate(10px, -3px);
      content: '${d3.format('.1%')(props.ratio)}';
    `}
    ${props => props.ratio > 0.8 && css`
      left: ${props.ratio * 100}%;
      transform: translate(-105%, -3px);
      color: white;
    `}
  }
`;

export const KeymetricCard = styled.div`
  width: 100%;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid #eaebec;
  //box-shadow: 0 0 4px 0 rgba(10, 10, 10, 0.1);
  background-color: #fbfdff;
  &.errorsStat {
    // cursor: pointer;
    // &:hover {
    //   background-color: ${LightGray1};
    // }
  }
  ${props => props.type === 'blue1' && css`
    color: ${PrimaryDarkBlue};
    svg path {stroke: ${PrimaryDarkBlue}}
  `}
  ${props => props.type === 'blue2' && css`
    color: ${PrimaryMediumBlue};
    svg path {stroke: ${PrimaryMediumBlue}}
  `}
  ${props => props.type === 'blue3' && css`
    color: ${SecondaryMediumBlue};
    svg path {stroke: ${SecondaryMediumBlue}}
  `}
  ${props => props.type === 'blue4' && css`
    color: ${PrimaryLightBlue};
    svg path {stroke: ${PrimaryLightBlue}}
  `}
  ${props => props.type === 'green1' && css`
    color: ${Grass};
    svg path {stroke: ${Grass}}
  `}
  ${props => props.type === 'red1' && css`
    color: ${BitterSweet};
    svg path {stroke: ${BitterSweet}}
  `}
  .top {
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: bold;
    i {
      margin-right: 10px;
      font-size: 12px;
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    height: 35px;
    > h3 {
      margin-right: 10px;
      font-size: 24px;
      font-weight: 500;
      min-width: 30px;
    }
    > div {
      padding: 2px 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      font-size: 12px;
      .status, .value {
        margin-right: 5px;
      }
      .arrow {
        font-size: 10px;
      }
    }
  }
`;