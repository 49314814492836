import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';

import moment from 'moment';
// moment.tz.setDefault('Asia/Singapore');
moment.locale('en');

if(window.location.hostname === 'recommend-zoom.mediacorp.sg') {
  window.location.replace(`https://boost-recommend.mediacorp.sg` + (window.location.pathname || '') + (window.location.search || ''));
} else {
  ReactDOM.render(<App />, document.getElementById('root'));
}