import React from 'react';
import styled, {css} from 'styled-components';
import {
  MediumGray, FontSizeMedium, Mint1, BitterSweet1, Mint, BitterSweet, LightGray,
  LightGray1, DarkGray1, BlueJeans, BlueJeans1, MediumGray1
} from "../app/StyleCommon";

export const Wrapper = styled.div`
  background-color: white;
  padding: 10px 20px;
  min-height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  h3 {
    display: flex;
    align-items: center;
    color: ${DarkGray1};
    margin-bottom: 30px;
    img {
      margin-right: 20px;
      border-radius: 50%;
      height: 48px;
      width: 48px;
    }
  }
  >.legend {
      margin: 5px 0 5px 0;
      display: block;
      font-size: 11px;
      position: relative;
      width: 100%;
      span {
        z-index: 1;
        position: relative;
        background: ${LightGray};
        padding: 0 10px;
        margin-left: 10px;
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 1px;
        background: #ddd;
        top: 50%;
        z-index: 0;
        // box-shadow: 0 1px 4px rgba(0,0,0,.1);
      }
  }
  form {
    width: 100%;
    //max-width: 800px
  }
`

export const FieldSet = styled.div`
  margin: 20px 0;
  border: 1px solid ${MediumGray};
  border-radius: 5px;
  padding-bottom: 10px;
  width: 100%;
  legend {
    font-size: 22px;
    font-weight: bold;
  }
`;

export const FormField = styled.div`
  margin: 30px 0;
  white-space: nowrap;
  > label {
    display: inline-block;
    vertical-align: middle;
    width: 180px;
    max-width: 300px;
    text-align: right;
    margin-right: 30px;
    white-space: normal;
    &.inlineblock {
      text-align: left;
      display: inline-block;
      width: auto;
      max-width:inherit;
      cursor: pointer;
      input {
        margin-right: 8px;
      }
      &:hover {
        color: ${DarkGray1};
      }
    }
    @media (max-width: 900px) {
      font-size: 14px;
    }
    @media (max-width: 667px) {
      font-size: 12px;
    }
  }
  input[type=text],
  input[type=password],
  input[type=number],
  input[type=email] {
    color: #535353;
    font-size: ${FontSizeMedium};
    padding: 5px 10px;
    width: 60%;
    max-width: 500px;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid ${MediumGray};
    outline: none;
    &:focus {
      border-bottom: 1px solid ${MediumGray1}
    }
    &:disabled {
      background: ${LightGray};
    }
    @media screen and (max-width: 667px) {
      width: 100%;
    }
  }
  > div {
    width: 60%;
    display: inline-block;
    vertical-align: middle;
    max-width: 500px;
  }
  .password-strength-wrapper {
    border: none;
    display: inline-block !important;
    @media screen and (max-width: 667px) {
      width: 100%;
    }
    .ReactPasswordStrength-strength-bar {
      top: -2px;
      height: 2px;
    }
    .ReactPasswordStrength-strength-desc {
      padding: 5px 20px;
      font-size: 14px;
      white-space: nowrap;
    }
    >input {
      width: 100%;
    }
  }
  @media (max-width: 667px) {
    white-space: normal;
    > label {
      width: 100%;
      text-align: left;
    }
  }
`;

export const ErrorMessage = styled.div`
  color: ${BitterSweet1};
  font-weight: 700;
  white-space: normal;
`
export const SuccessMessage = styled.div`
  color: ${Mint1};
  white-space: normal;
  i {
    margin-right: 5px;
  }
`

export const ConfirmSection = styled.div`
  margin-top: 20px;
  label {
    display: inline-block;
    width: 180px;
    max-width: 300px;
    margin-right: 30px;
  }
  @media (max-width: 667px) {
    label {
      display: none;
    }
  }
  ${props => props.hidden && css`

  `}
  ${props => props.children.length > 2 && css`
    @media (max-width: 667px) {
      label {
        display: none;
      }
      text-align: center;
      button {
        min-width: 100px;
      }
    }
  `}
`;

export const RadioGroup = styled.div`
  display: inline-block;
  white-space: pre-wrap;
  label {
    cursor: pointer;
    margin-right: 5px;
    white-space: nowrap;
  }
  input {
    vertical-align: middle;
    margin-top: -1px;
  }
`;

export const Btn = styled.button`
  padding: 5px 20px;
  margin-right: 10px;
  background: ${Mint1};
  border: none;
  box-shadow: 0 3px 6px rgba(0,0,0,.05);
  border-radius: 5px;
  font-size: ${FontSizeMedium};
  font-weight: bold;
  color: white;
  cursor: pointer;
  outline: none;
  transition: background 250ms ease-in-out, transform 150ms ease;
  img {
    margin-right: 5px;
    vertical-align: middle;
  }
  ${props => props.type === 'success' && `background-color: ${BlueJeans1};`}
  ${props => props.type === 'danger' && `background-color: ${BitterSweet1};`}
  &:hover {
    ${props => props.type === 'success' && `background-color: ${BlueJeans}`}
    ${props => props.type === 'danger' && `background-color: ${BitterSweet}`}
    box-shadow: 0 3px 6px rgba(0,0,0,.1);
  }
  &:disabled {
    //pointer-events: none;
    cursor: normal;
    opacity: .5;
  }
`;

export const Spinner = props => <img src="/img/spinning.gif" alt="spinning" style={{width: 16}}/>;