import styled, {css} from 'styled-components';

import {
  BlueJeans, Sunflower1, Grass1, DarkGray1, Grass, LightGray, LightGray1, MediumGray,
  MediumGray1, BitterSweet
} from '../app/StyleCommon';

export const ABCreateWrapper = styled.div`
  background-color: white;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  @media screen and (max-width: 667px) {
    padding: 10px;
  }
  .wrapper-actions {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid ${LightGray1};
    justify-content: flex-end;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    background: #fff;
    z-index: 2;
    button {
      margin: 0 !important;
      margin-left: 20px !important;
    }
  }
`;

export const StepContainer = styled.div`
  counter-reset: section;
  padding: 20px 40px 20px 40px;
  margin-bottom: 50px;
  @media screen and (max-width: 667px) {
    padding: 20px 0 20px 10px;
  }
`;

export const Step = styled.div`
  position: relative;
  min-height: 50px;
  &:not(:last-child) {
    border-left: 1px solid ${MediumGray};
  }
  &:before {
    counter-increment: section;
    content: counter(section);
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 12px;
    font-weight: bold;
    transform: translate(-50%, -5px);
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${BlueJeans};
    color: white;
    border-radius: 50%;
    border: 5px solid white;
  }
  h3 {
    margin-top: 0
    margin-left: 30px;
    font-size: 14px;
    cursor: pointer;
    color: ${DarkGray1};
    .desc {
      color: rgba(0,0,0,.54);
      padding-top: 10px;
      display: block;
      font-size: 12px;
      font-weight: normal;
      span {
        margin-right: 5px;
        img {
          border-radius: 50%;
          width: 24px;
          height: 24px;
        }
      }
      p {
        padding: 0;
        margin: 0;
      }
    }
    .basic {
      display: flex;
      align-items: center;
    }
  }
  .content {
    overflow: hidden;
    max-height: 0;
    margin: 0 20px;
    transition: all .5s ease;
    opacity: 0;
    @media screen and (max-width: 667px) {
      margin: 0 10px !important;
    }
    .row {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      @media screen and (max-width: 667px) {
        display: block;
        .col {
          margin-right: 0;
          width: 100% !important;
        }
      }
      .col {
        &:not(:last-child) {
          margin-right: 20px;
        }
        > label {
          display: block;
          margin: 10px 0 5px 0;
        }
        input[type=text] {
          width: 100%;
          font-size: 14px;
          padding: 8px 10px;
          box-sizing: border-box;
          border-radius: 5px;
          border: 1px solid ${MediumGray};
          outline: none;
          &:focus {
            border: 1px solid ${BlueJeans};
          }
        }
        .select-box {
          width: 100%;
          .toggler {
            width: 100%;
            border: 1px solid ${MediumGray}
            background: white;
            .menu {
              top: 40px;
            }
          }
        }
        &.col-1-1 {
          width: 50%;
        }
        &.col-1-3 {
          width: 33.33%;
          width: calc(33.33% - 50px);
        }
        &.col-2-3 {
          width: 66.66%;
        }
      }
    }
    .actions {
      padding: 20px 0 30px 0;
      &.last {
        margin-bottom: 50px;
      }
    }
  }
  ${props => props.selected && css`
    h3 {
      cursor: default;
    }
    .content {
      margin: 10px 20px;
      max-height: 3000px;
      overflow: visible;
      opacity: 1;
    }
  `}
  ${props => props.disabled && css`
    pointer-events: none;
    &:before {
      background: ${MediumGray1};
    }
  `}
`;

export const TargetTable = styled.table`
  width: 100%;
  box-sizing: border-box;
  // border: 1px solid ${LightGray1};
  border-spacing: 0;
  background-color: #fff;
  overflow: hidden;
  border-radius: 5px;
  &:hover {
    background-color: #fff;
  }
  tbody {
    &:hover {
      background-color: #fff;
    }
  }
  tr {
    // &:not(.placeholder):hover {
    //   background-color: #fff;
    // }
    &.placeholder {
      user-select: none;
    }
    &:not(:last-child) td {
      border-bottom: 1px solid ${LightGray1};
    }
    td {
      padding: 8px 0;
      font-weight: 500;
      font-size: 12px;
      &.center {
        text-align: center;
      }
      &.right {
        text-align: right;
      }
    }
  }
  .name {
    width: 150px;
  }
  .info {
    width: 100px;
  }
`;

export const PromptBody = styled.div`
  margin: 20px 0;
  i {
    color: ${Sunflower1};
    margin-right: 10px;
  }
`;

export const PublishDialogWrapper = styled.div`
  svg {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 5px;
  }
  .res-success-icon {
    border: 3px solid ${Grass1};
    path {
      fill: ${Grass1};
    }
  }
  .res-failure-icon {
    border: 3px solid ${BitterSweet};
    path {
      fill: ${BitterSweet};
    }
  }
`;