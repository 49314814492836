import React from 'react'
import ReactDOM from 'react-dom';
import moment from 'moment'
import {withRouter} from 'react-router-dom'
import {withConsumer} from './ApplicationContext';
import {Redirect} from 'react-router-dom';
import CondirmDialog from '../uikit/dialog/index';

export default withRouter(withConsumer(class extends React.Component {
  render() {
    const {path, component: Component, appState, ...rest} = this.props;
    const {session} = appState || {};
    if(!!session && !session.error) {
      if (session.require2FA && path !== '/login_2fa') {
        return <Redirect to={{pathname: '/login_2fa', state: {from: this.props.location}}}/>;
      }
      if (path.indexOf('/settings') < 0 && path.indexOf('/login_2fa') < 0) {
        const confirmInfo = {
          title: null,
          hideCancel: true,
          onConfirm: () => {
            this.props.history.push(`/settings`);
            ReactDOM.render(<div/>, document.getElementById('global-confirm'));
          }
        };
        if (session.passwordWillExpire) {
          confirmInfo.title = (
            <div>Your password will be expired {moment(session.passwordExpireDate).fromNow()}, please change your password immediately.</div>
          );
          confirmInfo.hideCancel = false;
          confirmInfo.cancelLabel = 'Later';
          confirmInfo.onCancel = () => {
            delete session.passwordExpired;
            delete session.passwordWillExpire;
            CondirmDialog.closeAll()
          };
        } else if (session.passwordExpired) {
          confirmInfo.title = (
            <div>Your password is expired, please change your password immediately.</div>
          );
        }
        if(confirmInfo.title) {
          ReactDOM.render(<CondirmDialog fullScreen={true} {...confirmInfo} />, document.getElementById('global-confirm'));
          if(session.passwordExpired) {
            return <div></div>;
          }
        }
      }
    } else if(path !== '/login') {
      return <Redirect to={{pathname: '/login', state: {from: this.props.location}}}/>;
    }
    return <Component {...rest}/>;
  }
}))