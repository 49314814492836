import React from 'react';
import styled, {css} from 'styled-components';

export const ErrorMessageWrapper = styled.div`
  color: #E9573F;
  display: inline-block;
  font-weight: bold;
  margin-left: 15px;
  margin: 10px 0;
  i {
    margin-right: 5px;
  }
`
export const ErrorMessage = props => (
  <ErrorMessageWrapper>
    <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
    {props.message}
  </ErrorMessageWrapper>
)
