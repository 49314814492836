import React from 'react';
import styled, {css} from 'styled-components';
import Pagination from 'rc-pagination';
import {
  BitterSweet1,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass1, LightGray, LightGray1, MediumGray,
  MediumGray1
} from "../app/StyleCommon";

export const DeployPanel = styled.div`
  box-sizing: border-box;
  width: 100%;
  background: white;
  padding: 0px 10px 10px 10px;
  position: relative;
  margin-bottom: 50px;
  @media screen and (max-width: 667px) {
    padding-top: 20px;
  }
  h1 {
    font-size: 18px;
  }
  h2 {
    font-size: 14px;
  }
  h3 {
    font-size: 12px;
    font-weight: normal;
  }
  p {
    margin: 5px 0;
  }
  code {
    font-size: 12px;
  }
  pre {
    padding: 5px !important;
    border-radius: 5px;
  }
  .ab-test-create {
    display: flex;
    align-items: center;
    font-size: 12px;
    > input {
      margin-right: 10px;
      &:disabled {
        pointer-events: none;
        opacity: .5;
      }
    }
  }
  .copy-able {
    position: relative;
    @media screen and (max-width: 667px) {
      margin-top: 30px;
    }
    &:hover .copy-cat{
      opacity: 1;
    }
    .copy-cat {
      opacity: 0.9;
      position: absolute;
      right: 0;
      top -22px;
      transition: all .3s ease-in-out;
      .copied {
        color: ${Grass1};
      }
    }
  }

  .btn {
    border: 1px solid ${BlueJeans};
    padding: 2px 10px;
    color: white;
    background: ${BlueJeans};
    box-shadow: 0 0 3px rgba(0,0,0,.15), 1px 1px 10px rgba(0,0,0,.08);
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
    &:hover {
      box-shadow: 0 0 3px rgba(0,0,0,.3), 1px 1px 10px rgba(0,0,0,.1);
    }
    &.success {
      background: ${Grass1};
    }
    &.danger {
      background: ${BitterSweet1};
    }
    &.reset {
      background: white;
      border: none;
      box-shadow: none;
      text-decoration: underline;
      color: ${DarkGray1};
      &:hover {
        color: ${BlueJeans};
      }
    }
  }
  .code-panel-body {
    width: 100%;
    margin: 0;
    max-height: 120px;
    overflow-y: auto;
    border: 1px solid ${LightGray1};
    border-radius: 5px;
    pre {
      margin: 0 !important;;
      //background: white !important;
      font-size: 12px;
      span {
        word-break: break-all;
        white-space: pre-line;
        background: transparent !important;
      }
    }
  }
  .code-foot-note {
    margin-top: 20px;
    font-size: 12px;
  }
`