import styled, {css} from 'styled-components';
import {Btn} from '../uikit/index';
import {BlueJeans1, BitterSweet1, Grass1, BitterSweet, Mint, Mint1, BlueJeans, DarkBlue} from "../app/StyleCommon";

export const LoginWrapper = styled.div.attrs({
  'data-rp': 'login-wrapper'
})`
  background-color: black;
  background-image: url(/img/bg.jpg);
  background-size: cover;
  display: flex;
  justify-content: center;
  -ms-flex-pack: center;
  align-items: center;
  flex-direction: column;
  height:auto !important;
  min-height: 100%;
  width: 100vw;
  min-height: 100vh;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    &:not(.default-style) {
      animation: autofill 0s forwards;
    }
  }

  @keyframes autofill {
    100% {
      background: transparent;
      color: white;
    }
  }
  img, form {
    z-index: 1;
  }
  @media (max-width: 667px) {
    padding: 10px 30px;
    box-sizing: border-box;
  }
  ${props => props.isIE && css`
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      >form {
        margin: 0 auto;
      }
  `}
`;

export const Tint = styled.div`
  display: block;
  background-color: rgba(0,0,0, .6);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 0;
`;

export const Logo = styled.img`
  margin-bottom: 40px;
`;

export const FormWrapper = styled.form`
  background: rgba(10,10,10,.9);
  box-shadow: 0px 0px 5px rgba(255,255,255,.5), 0px 0px 15px rgba(255,255,255,.1);
  padding: 30px 30px;
  border-radius: 5px;
  width: 90%;
  max-width: 500px;
  text-align: left;
  input[type=text], input[type=password], input[type=email]{
    font-size: 14px;
    width: 100%;
    padding: 8px 0;
    box-sizing: border-box;
    transition: all .3s ease;
    vertical-align: middle;
    color: white;
    background: none;
    outline: none;
    border-radius: 0;
    border: 1px solid transparent;
    border-bottom: 1px solid rgba(255,255,255,.5);
    &:focus {
      border-bottom: 1px solid rgba(255,255,255,.8);
    }
  }
  input[type=checkbox] {
    vertical-align: middle;
  }
`;

export const Header = styled.h3`
  font-size: 20px;
  color: white;
  margin-bottom: 40px;
`;

export const ErrorMsgWrapper = styled.div`
  color: ${BitterSweet1};
  margin: 20px 0 30px 0;
`;

export const FormField = styled.div`
  &:not(:first-child) {
    margin-bottom: 20px;
  }
  > label {
    display: block;
    width: auto;
    margin: 15px 0 5px 0;
    color: white;
    font-size: 14px;
    cursor: pointer;
  }
`;

export const BottomPart = styled.div`
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  && a {
    color: white;
  }
`;

export const SubmitBtn = styled(Btn)`
  margin: 10px 0 10px;
  padding: 15px 10px;
  height: 35px;
  color: white;
  border:none;
  background-color: ${BitterSweet1} !important;
  width: 100%;
  align-items: center;
  ox-shadow: inset 0 -2px 2px rgba(255,255,255,.1);
  &:hover {
    box-shadow: inset 0 -2px 5px rgba(255,255,255,.1);
    text-shadow: 0 0px 2px rgba(255,255,255, .2);
  }
  img {
    margin-right: 5px;
    vertical-align: middle;
  }
`;