import React from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import {withConsumer} from '../app/ApplicationContext';
import {withRouter} from 'react-router';
import moment from 'moment';
import * as d3 from 'd3';
import {Redirect, Link} from 'react-router-dom';
import {ProgressBar, Btn, Table, CondirmDialog, TabContainer, Loader, TableScrollWrapper} from '../uikit/index';
import MainLayout from '../layout/index';
import {HomeWrapper} from './home.style';
import {post} from "../utils/request";
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import {SelectStyled} from "../ads/select.style";
import {genSessionId, genSSO} from "./meidGenerator";


let homeWidgets = [
  {id: 'zNuaUFJ__jr', label: 'Cold Start'},
  {id: 'OT7Ncy-RKi', label: 'Popular'},
  {id: 'hBj10j1HBmY', label: 'Top 10 Series'},
  {id: '_x5l5vgsIF', label: 'Top Picks for You'},
  {id: 'hBm_bkZOt1y', label: 'Top x genre series for you'},
  {id: 'UGYVOYuDiW', label: 'Top 10 Movies'},
  {id: '5Jb_pAx1mI', label: 'Top 10 Kids Series'},
  {id: 'Awtjj-6rDH-', label: 'Because you watched x series'},
  {id: '0vDz9LgK7J8', label: 'Because you are a fan of x cast/director'},
]
export default withRouter(withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    let sessionId = localStorage.getItem('sessionId');
    let deviceType = Number(localStorage.getItem('deviceType'));
    let allWidgets = homeWidgets;
    if(!sessionId) {
      localStorage.setItem('sessionId', genSessionId());
    } else {
      allWidgets = homeWidgets.filter(d => d.id !== 'zNuaUFJ__jr'); //cold start only for new user.
    }
    if (!deviceType) {
      deviceType = 101;
      localStorage.setItem('deviceType', deviceType);
    }
    this.state = {
      deviceType: deviceType,
      allWidgets: allWidgets
    }
  }

  buildQuery() {
    const {deviceType, allWidgets} = this.state;
    let sessionId = localStorage.getItem('sessionId');
    let userId = localStorage.getItem('userId');
    return {userId, sessionId, deviceType, widgetIds: allWidgets.map(d => d.id)};
  }

  render() {
    const {appState: {session}} = this.props;
    const {data, error, deviceType, allWidgets} = this.state;
    let sessionId = localStorage.getItem('sessionId');
    let userId = localStorage.getItem('userId');
    return (
      <MainLayout activeItem="simulator">
        {
          !data && !error &&
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/simulate/mewatch/simulatePage`}
            params={this.buildQuery()}
            successHandler={data => this.setState({data: data})}
            errorHandler={e => this.setState({error: e.message})}/>
        }
        {
          !!error && <ErrorMessage message={error}></ErrorMessage>
        }
        {
          !!data &&
          <HomeWrapper>
            <h3 style={{display:"flex", justifyContent: 'space-between'}}>
              MeWATCH HomePage simulator
              <SelectStyled
                selected={String(deviceType)}
                style={{width: '150px'}}
                defaultLabel="Device Type"
                data={[
                  {key: '101', label: 'Desktop Web'},
                  {key: '102', label: 'Mobile Web'},
                  {key: '103', label: 'Android App'},
                  {key: '104', label: 'IOS App'}
                ]}
                onChange={deviceType => {
                  localStorage.setItem('deviceType', deviceType);
                  this.setState({deviceType: Number(deviceType), data: null})
                }}/>
            </h3>
            <div style={{padding: '10px', background: '#f1f2f3', display: 'flex', justifyContent: 'space-between'}}>
              <div>sessionId: {sessionId}, userid: {userId}</div>
              <span>
              {
                !userId &&
                <button onClick={e => {
                  // let mockMeid = 'abcdefgh-ijkl-mnop-qrst-123456789012'.replace('123456789012', String(Math.random()).substring(2, 14));
                  localStorage.setItem('userId', genSSO(sessionId));
                  this.setState({data: null});
                }}>Login SSO</button>
              }
                {
                  !!userId &&
                  <button onClick={e => {
                    localStorage.removeItem('userId');
                    this.setState({data: null});
                  }}>Login Out</button>
                }

                <button onClick={e => {
                  localStorage.setItem('sessionId', genSessionId());
                  localStorage.removeItem('userId');
                  this.setState({data: null, allWidgets: homeWidgets});
                }}>Reset New User</button>
              </span>
            </div>
            <h3>Widgets</h3>
            {allWidgets.map(d => {
              const widgetData = (data.data || {})[d.id] || {};
              const recommendation = ((widgetData.recommendationsResult || {}).recommendations || {}).recommendation || [];
              const profileFeature = ((widgetData.recommendationsResult || {}).profileFeatures || {}).profileFeature;
              const actionedItem = ((widgetData.recommendationsResult || {}).actionedItems || {}).actionedItem;
              return (
                <div style={{margin: '10px 0 20px 0', background: '#f3f5f7', padding: '5px', borderRadius: '5px'}} key={d.id}>
                  <h4>{d.label} ({d.id})</h4>
                  <div>source_id: {widgetData.source_id}</div>
                  <div>refUsecase: {widgetData.refUsecase}</div>
                  {
                    profileFeature && profileFeature.length &&
                    <div>profileFeature: {JSON.stringify(profileFeature)}</div>
                  }
                  {
                    actionedItem && actionedItem.length &&
                    <div>actionedItem: {JSON.stringify(actionedItem)}</div>
                  }
                  <div style={{display: 'flex'}}>
                    {
                      recommendation.map(item => {
                        return (
                          <a key={item.contentItemId} onClick={e => this.triggerClickFeedback(item, widgetData.source_id, widgetData.refUsecase)} style={{margin: '5px 10px', cursor: 'pointer'}}>
                            <img src={item.imageUrl} style={{width: '96px', height: '56px'}}/>
                            <div>{item.contentItemId}</div>
                          </a>
                        )
                      })
                    }
                  </div>
                  {
                    !!widgetData.message && <ErrorMessage message={JSON.stringify(widgetData.message)}></ErrorMessage>
                  }
                </div>
              )
            })}
          </HomeWrapper>
        }
      </MainLayout>
    )
  }

  triggerClickFeedback(item, source_id, refUsecase) {
    let deviceType = Number(localStorage.getItem('deviceType'));
    let sessionId = localStorage.getItem('sessionId');
    let userId = localStorage.getItem('userId');
    post(`/api/v1/mewatch/learn/click?token=e6f6h28e26vbc8442b288eb6121d85b9a4&userId=${userId|| ''}&sessionId=${sessionId|| ''}`, {
      contentItemId: item.contentItemId,
      "countryCode": "SG",
      "deviceType": deviceType,
      "age": 18,
      "tz": "Asia/Singapore",
      "contentItemInstanceId": item.contentItemId,
      "contentSourceId": 2,
      "actionTime": Math.round(Date.now() / 1000),
      source_id,
      refUsecase,
    }).then(results => {
      console.log('click feedback sent');
    }).catch(e => {
      console.error(e);
    });

    this.props.history.push(`/simulate/details?source_id=${source_id}&refUsecase=${refUsecase ? encodeURIComponent(refUsecase) : ''}&item=${encodeURIComponent(JSON.stringify(item))}`);
  }
}))