import React, {Component} from 'react';
import {TabContainerWrapper, TabItemsWrapper, TabMenuWrapper, TabMenuItem, TabItem} from "./index.style";

export default class extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: props.activeIndex || 0
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.activeIndex !== this.state.activeIndex) {
      this.setState({activeIndex: nextProps.activeIndex});
    }
  }

  render() {
    const {hideMenu, tabTitles} = this.props;
    let {activeIndex} = this.state;
    if(hideMenu) {
      activeIndex = 0;
    }
    let {noMenuMargin, children, renderAll, className} = this.props;
    if (!Array.isArray(children)) {
      children = [children];
    }
    return (
      <TabContainerWrapper className={`tab-container-wrapper ${className}`}>
        {
          !hideMenu &&
          <TabMenuWrapper className="tab-menu-wrapper" noMenuMargin={noMenuMargin}>
            {tabTitles.map((title, index) => {
              return (
                <TabMenuItem
                  className={activeIndex === index ? 'selected' : ''}
                  key={title + index}
                  active={activeIndex === index}
                  onClick={() => this.changeTab(index)}>
                  {title}
                </TabMenuItem>
              );
            })}
          </TabMenuWrapper>
        }

        <TabItemsWrapper className="tab-items-wrapper">
          {children.map((child, index) => {
            if (activeIndex === index) {
              return (
                <TabItem key={index} active={true}>
                  {child}
                </TabItem>
              )
            } else if (renderAll) {
              return (
                <TabItem hidden={true} key={index} active={false}>
                  {child}
                </TabItem>
              );
            } else {
              return undefined;
            }
          })}
        </TabItemsWrapper>
      </TabContainerWrapper>
    )
  }

  changeTab(index) {
    this.setState({activeIndex: index});
    if (this.props.onChange) {
      this.props.onChange(index);
    }
  }
}