import styled, {css} from 'styled-components';
import {
  LightGray,
  LightGray1,
  DarkGray,
  DarkGray1,
  Grass,
  Grass1,
  BitterSweet1,
  BitterSweet,
  SecondaryMediumBlue,
  PrimaryDarkBlue,
  PrimaryLightBlue,
  PrimaryMediumBlue,
  MediumGray,
  Mint,
  Aqua,
  BlueJeans1,
  Levander,
  Sunflower,
  BlueJeans,
  MenuBlue, MediumGray1,
} from "../app/StyleCommon";

import {SelectStyled} from './select.style';

export const colors = [
  Mint, Grass , Sunflower, Aqua, Levander, BitterSweet,
  MenuBlue, MediumGray, DarkGray, Grass1, BlueJeans1, Sunflower
];

export const DashbaordWrapper = styled.div`
  padding: 20px;
  .page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    @media screen and (max-width: 667px) {display: block;}
    > h3 {
      margin: 0;
      padding: 0;

    }
  }
`;


export const WidgetDetailsWrapper = styled.div`
  background-color: white;
  padding: 20px 20px;
  @media screen and (max-width: 667px) {
    padding: 20px 10px;
  }
  .detail-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid ${MediumGray};
    padding: 10px 0;
    font-weight: bold;
    font-size: 16px;
    padding: 5px 10px;
    .title {
      img {
        width: 24px;
        height: 24px;
        vertical-align: middle;
        margin-right: 10px;
      }
      span {
        margin-right: 20px;
      }
      a {
        color: ${MediumGray1};
        font-size: 14px;
      }
    }
    .widget-name-card {
      padding: 5px;
      @media screen and (max-width: 667px) {
        font-size: 12px;
      }
      .name {
        margin: 0;
        padding: 0;
        img {
          width: 18px;
          margin-right: 5px;
          vertical-align: middle;
        }
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
      .description {
        margin: 0;
        padding: 0;
        font-size: 10px;
      }
    }
    .actions {
      display: flex;
      align-items: center;
      .date-selector-dropdown {
        .toggler {
          width: 250px;
          border: 1px solid ${LightGray1};
          background: ${LightGray};
          margin-right: 20px;
          font-size: 13px;
          border-radius: 4px;
          font-weight: normal;
        }
        .date-selector {
          right: 0;
          left: auto;
        }
      }
      .dropdown-toggle {
        height: 30px;
        width: 20px;
      }
      button {
        white-space: nowrap;
        width: auto;
        min-width: auto;
      }
    }
    @media screen and (max-width: 667px) {
      display: block;
      padding-bottom: 10px;
      .title {
        margin-bottom: 10px;
      }
      .date-selector {
        flex-wrap: wrap !important;
        position: fixed;
        border: 1px solid ${MediumGray};
        top: 60px !important;
        left: 10px !important;
        right: 10px !important;
        width: auto !important;
        flex-wrap: wrap !important;
        z-index: 1 !important;
      }
      .month-panel {
        width: 100%  !important;
      }
    }
  }
`;

export const WidgetOverview = styled.div`
  .basic-info {
    // border: 1px solid ${LightGray1};
    //border-radius: 5px;
    //margin: 10px 20px;
    margin-top: 20px;
  }
  .attr {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 13px;
    @media screen and (max-width: 667px) {
      display: block;
      background: #fafbfc;
      border: 1px solid #f1f2f3;
      padding: 5px;
      >label {
        width: 100% !important;
        text-align: left !important;
      }
      > div {
        width: 100%;
        padding: 5px 0 !important;
      }
    }
    > label {
      flex: none;
      display: inline-block;
      width: 120px;
      text-align: right;
      font-weight: bold;
      margin-right: 30px;
      color: ${DarkGray1}
      height: 100%;
      font-size: 13px;
    }
    >div {
      width: 100%;
      padding: 5px 10px;
      border-radius: 3px;
      &.name {
        display: flex;
        align-items: center;
        img {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid ${LightGray};
      }
    }
    a {
      text-decoration: none;
    }
    .layout {
      display: inline-block;
      vertical-align: middle;
      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-right: 5px;
      }
      button {
        margin-left: 10px;
        color: ${DarkGray}
        a {
          color: ${DarkGray}
        }
      }
    }
    &.metric-table {
      width: 100%;
      margin-top: -15px;
      background: white;
      margin-bottom: 10px;
      >label {
        margin-top: 15px;
      }
      @media screen and (max-width: 667px) {
        padding: 0;
        border: none;
        .key-metric-wrapper {
          margin: 0;
        }
      }
      .key-metric-wrapper {
        height: auto;
        padding: 0;
        >div {
          margin: 0;
        }
      }
      table {
        th {
          text-align: left;
          padding-left: 0;
        }
        td {
          text-align: left;
          position: relative;
        }
      }
    }
    &.timeseries {
      background: white;
      margin: 10px 20px 0 20px;
      margin-top: -10px;
      border-top: 0;
      >label {
        margin-top: 5px;
      }
      >div {
        padding: 0;
      }
      @media screen and (max-width: 667px) {
        padding: 0;
        border: none;
        margin: 0;
      }
      .timeseries-header {
        margin: 5px 0 20px 0;
        display: flex;
        align-items: center;
      }
      .timeseries-body {
        border: 1px solid ${LightGray1};
        background-color: #fafbfc;
      }
    }
  }
`;

export const KeymetricCard = styled.div`
  width: 100%;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  box-sizing: border-box;
  //border: 1px solid #eaebec;
  //box-shadow: 0 0 4px 0 rgba(10, 10, 10, 0.1);
  //background-color: #fbfdff;
  @media screen and (max-width: 667px) {
    width: 100%;
    &:not(:last-child) {
      border-bottom: 1px solid ${LightGray};
      border-radius: 0;
    }
  }
  &.errorsStat {
    cursor: pointer;
    &:hover {
      background-color: ${LightGray1};
    }
  }
  ${props => props.type === 'blue1' && css`
    color: ${PrimaryDarkBlue};
    svg path {stroke: ${PrimaryDarkBlue}}
  `}
  ${props => props.type === 'blue2' && css`
    color: ${PrimaryMediumBlue};
    svg path {stroke: ${PrimaryMediumBlue}}
  `}
  ${props => props.type === 'blue3' && css`
    color: ${SecondaryMediumBlue};
    svg path {stroke: ${SecondaryMediumBlue}}
  `}
  ${props => props.type === 'blue4' && css`
    color: ${PrimaryLightBlue};
    svg path {stroke: ${PrimaryLightBlue}}
  `}
  ${props => props.type === 'green1' && css`
    color: ${Grass};
    svg path {stroke: ${Grass}}
  `}
  ${props => props.type === 'red1' && css`
    color: ${BitterSweet};
    svg path {stroke: ${BitterSweet}}
  `}

  .top {
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: bold;
    i {
      margin-right: 10px;
      font-size: 12px;
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    height: 35px;
    margin-top: 6px;
    > h3 {
      margin-right: 10px;
      font-size: 20px;
      font-weight: 500;
      min-width: 30px;
    }
    > div {
      padding: 2px 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      font-size: 12px;
      .status, .value {
        margin-right: 5px;
      }
      .arrow {
        font-size: 10px;
      }
    }
  }
`;

export const KeyMetricsComparison = styled.div`
  margin: 10px 20px;
  color: ${DarkGray1};
  //.middle {
  //  width: 100%;
  //  padding: 10px 0;
  //  box-sizing: border-box;
  //}
  .middle {
    min-height: 280px;
    .select-box .toggler {
      border-bottom: none;
      padding: 0 30px 0 0;
      margin-left: 0;
    }
  }
  .middle > table,
  table {
    overflow: visible;
    border: 1px solid ${LightGray1};
    thead {
      color: ${DarkGray};
      th {
        text-align: left;
        background-color: #fafbfc;
        border-bottom: 1px solid ${LightGray1};
        .select-box {margin-bottom: 1px;}
      }
    }
    tbody {
      tr td {
        padding: 10px;
        text-align: left;
        text-align: left;
      }
      tr {
        border-top: 1px solid ${LightGray};
        &.no-data td {
          text-align: center;
        }
      }
  }
`;

export const BarWrapper = styled.div`
  position: relative;
  line-height: 12px;
  text-align: left;
  width: 100px;
  .bar {
    background: ${BlueJeans};
    height: 14px;
    border-radius: 3px;
    //position: absolute;
    min-width: 1px;
    margin-bottom: -10px;
    top: 0;
    z-index: 0;
    height: 3px;
    margin-top: 3px;
  }
  .num {
    display: block;
    font-size: 12px;
    position: relative;
    z-index: 1;
  }
`;

export const CreativeWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  a {
    margin-left: 5px;
    margin-top: -5px;
  }
`
export const CampaignWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  img {
    width: 14px;
    height: 14px;
    margin-right: 10px;
  }
  a {
    margin-left: 5px;
  }
`
export const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  img {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
  a {
    margin-left: 5px;
  }
`
export const EDMWrapper = styled.div`
  white-space: normal;
  img {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
  .desc {
    color: ${MediumGray1};
  }
  a {
    margin-left: 5px;
  }
  // .current {
  //   color: ${BlueJeans1};
  //   font-weight: bold;
  // }
`

export const ColorBlock = styled.div`
  width: 3px;
  position: absolute;
  left: 0px;
  top: 2px;
  bottom: 3px;
`

export const SelectInlineStyled = styled(SelectStyled)`
  .toggler {
    border: none;
    display: inline;
    background: none;
    padding: 0 30px 0 5px;
    margin-left: 5px;
    border-radius: 0;
    &:hover {
      background: none;
    }
    img {
      display: none;
    }
  }
  .menu {
    top: 25px;
    text-align: left;
    right: auto !important;
    ${props => props.mobileAdjust && css`
      @media screen and (max-width: 667px) {
        left: 50px !important;
        right: 10px !important;
        width: calc(100vw - 60px) !important;
      }
    `}
    .dimension-item {
        display: flex;
        align-items: flex-start;
        padding: 5px 20px;
        white-space: normal;
        padding: 5px 0;
        width: 300px;
        img {
          height: 16px;
          margin-right: 10px;
          margin-top: 3px;
        }
        .description {
           font-size: 11px;
           color: ${DarkGray1};
           font-weight: 400;
        }
    }
    .selected .dimension-item  .description {
      color: white;
    }
  }
`