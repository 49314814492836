import React from 'react'
import ReactDOM from 'react-dom';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import moment from 'moment';
import * as d3 from 'd3';
import {Spinner} from '../uikit/Loader/index.style';
import {withConsumer} from '../app/ApplicationContext';
import CondirmDialog from '../uikit/dialog/index';
import {Btn, TabContainer, Table, ProgressBar, Loader, Switch} from '../uikit/index';
import {SelectStyled} from './select.style';
import {PaginationStyled, ErrorBox} from './index.style';
import {RuleEditorWrapper, SwitchForTable, RuleRendererWrapper, AjaxLoader} from './buzRuleEditor.style';
import {BlueJeans} from "../app/StyleCommon";
import ruleMetadata from './buzRules';
import ruleMetadataIPrice from './buzRules_iprice';
import {post} from '../utils/request';

export default withConsumer(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  validate() {
    const {ruleMap = {}} = this.props;
    const enabledRules = Object.keys(ruleMap).filter(k => ruleMap[k].enabled);
    const instances = _.flatten(enabledRules.map(k => (ruleMap[k].instances || [])));
    const boosters = instances.filter(d => d.type === 'Boost Function');
    if(boosters.length > 1) {
      return 'You can enable only one boost function';
    }
  }

  render() {
    const {keyword} = this.state;
    const {ruleMap = {}, site} = this.props;
    const supportedRuleMetadata = site.indexOf('iprice') < 0 ?  ruleMetadata : ruleMetadataIPrice;
    const filteredRuleMeta = supportedRuleMetadata.filter(d => {
      if (!keyword) {
        return true;
      }
      let keywordLowercase = keyword.toLowerCase();
      return d.description.toLowerCase().indexOf(keywordLowercase) >= 0 || keyword === d.key;
    });
    return (
      <RuleEditorWrapper>
        <div className="searchBox">
          <i className="fa fa-search"/>
          <input
            type="text"
            placeholder="Search By Key, Description"
            value={keyword}
            onChange={e => this.setState({keyword: e.target.value})}/>
        </div>
        <div className="table-wrapper">
          <Table
            columns={[
              {
                key: 'no', label: 'No', align: 'center',
                renderer: (d, i) => i + 1
              },
              {
                key: 'type', label: 'Type', align: 'left',
                renderer: (d) => d.type
              },
              {
                key: 'description', label: 'Rule', align: 'left',
                renderer: (d) => this.renderRule(d)
              },
              {
                key: 'actions', label: 'Activate', align: 'center',
                renderer: d => {
                  let activated = ruleMap[d.key] && !!ruleMap[d.key].enabled;
                  return (
                    <SwitchForTable
                      onLabel="YES"
                      offLabel="NO"
                      checked={activated}
                      onSwitchChange={checked => {
                        ruleMap[d.key] = ruleMap[d.key] || {};
                        ruleMap[d.key].enabled = checked;
                        if (!ruleMap[d.key].instances) {
                          ruleMap[d.key].instances = [{
                            ...d,
                            id: Date.now(),
                            values: _.mapValues(d.parameters, (p) => p.default_value)
                          }]
                        }
                        this.props.onChange(ruleMap, this.validate());
                      }}/>
                  );
                }
              },
            ]}
            rows={filteredRuleMeta}
            noDataText={"No rule found."}
          />
        </div>
      </RuleEditorWrapper>
    );
  }

  renderRule(d) {
    const {ruleMap = {}} = this.props;
    let {instances, enabled} = ruleMap[d.key] || {
      instances: [{
        ...d,
        id: Date.now(),
        values: _.mapValues(d.parameters, (p) => p.default_value)
      }]
    };

    return (
      <RuleRendererWrapper className="rule-renderer" key={d.key}>
        <div className="rule-instance-list">
          {
            instances.map((instance, instanceIndex) => {
              let splited = d.description.split(/\$\{[^}]*\}/ig);
              return (
                <div className={`rule-instance ${enabled ? 'enabled' : 'disabled'}`} key={instanceIndex}>
                  <span className="description">
                    {
                      splited.map((preText, index) => {
                        let parameterKey = (d.description.split('${')[index + 1] || '').split('}')[0]
                        let parameterMeta = d.parameters[parameterKey] || {};
                        let paramValue = instance.values[parameterKey];
                        if(paramValue === undefined && !!parameterMeta.default_value) {
                          paramValue = parameterMeta.default_value;
                        }
                        let extraOptions = [];
                        if(parameterMeta && parameterMeta.type === 'string') {
                          extraOptions = _.flatten([paramValue]).filter(k => {
                            return !parameterMeta.options.find(o => o.key === k);
                          }).map(k => {
                            return {key: k, label: k};
                          })
                        }
                        const facet = (instance.values || {})[parameterMeta.keyParameter || ''];
                        return (
                          <span key={preText + index}>
                          <span>{preText}</span>
                            {
                              parameterMeta && parameterMeta.type === 'string' &&
                              <SelectStyled
                                key={instance.id + facet}
                                useFixed={true}
                                fixedTop={20}
                                defaultLabel={parameterMeta.label}
                                selected={parameterMeta.multiple ? _.flatten([paramValue]) : paramValue}
                                data={parameterMeta.options}
                                showGroupBatch={false}
                                disabled={!enabled}
                                multi={parameterMeta.multiple}
                                searchable={!!parameterMeta.keyParameter}
                                AjaxDataLoader={!parameterMeta.keyParameter ? undefined : this.getDataProvider(instance, parameterMeta)}
                                onChange={selected => {
                                  if(Array.isArray(selected)) {
                                    selected = _.uniq(_.compact(selected));
                                  }
                                  console.log(selected);
                                  if(Array.isArray(selected) && selected.indexOf('same') === 0 && selected.length > 1) {
                                    selected = selected.slice(1);
                                  } else if(Array.isArray(selected) && selected.slice(-1)[0] === 'same' && selected.length > 1) {
                                    selected = ['same'];
                                  }
                                  instance.values = instance.values || {};
                                  instance.values[parameterKey] = selected;
                                  if(parameterMeta.valueParameter) {
                                    delete instance.values[parameterMeta.valueParameter];
                                  }
                                  this.props.onChange(ruleMap, this.validate());
                                }}/>
                            }
                            {
                              parameterMeta && parameterMeta.type === 'integer' &&
                              <input
                                className="rule-input"
                                type="number"
                                value={paramValue}
                                placeholder={parameterMeta.label}
                                disabled={!enabled}
                                min={parameterMeta.min}
                                onChange={e => {
                                  let value = e.target.value;
                                  if(value === '') {
                                    value = '';
                                  } else {
                                    value = Number(value);
                                  }
                                  instance.values = instance.values || {};
                                  instance.values[parameterKey] = value;
                                  this.props.onChange(ruleMap, this.validate());
                                }}
                              />
                            }
                        </span>
                        )
                      })
                    }
                  </span>
                  <span>
                    {
                      !!enabled && instances.length > 1 &&
                      <div className="rule-instance-action" onClick={e => {
                        ruleMap[d.key].instances.splice(instanceIndex, 1);
                        this.props.onChange(ruleMap, this.validate());
                      }}><i className="fa fa-trash"/></div>
                    }
                  </span>
                </div>
              )
            })
          }
        </div>
        {
          !d.singleton && enabled &&
          <div className="rule-action" onClick={e => {
            ruleMap[d.key].instances.push({
              ...d,
              id: Date.now(),
              values: _.mapValues(d.parameters, (p) => p.default_value)
            })
            this.props.onChange(ruleMap, this.validate());
            console.log(JSON.stringify(ruleMap));
          }}>
            <i className="fa fa-plus"/>
          </div>
        }
      </RuleRendererWrapper>
    )
  }

  getDataProvider(ruleInstance, parameterMeta) {
    const {site, resourceType, filters = [], maxAge = 30, maxAgeUnit = 'day'} = this.props.slotGroup || {};
    const parent = this;
    return class extends React.Component {
      constructor(props) {
        super(props);
        this.state = {};
        this.loadMore = _.debounce(this._loadMore.bind(this), 400);
      }

      componentDidMount() {
        if (this.props.page === 0) {
          this.loadMore();
        }
      }

      componentWillReceiveProps(nextProps) {
        if (nextProps.page === 0) {
          this.loadMore();
        }
      }

      render() {
        const {keyParameter} = parameterMeta;
        let {page, keywords, onLoad} = this.props;
        let facet = (ruleInstance.values || {})[keyParameter] || 'section';
        const {loading, error, empty} = this.state;
        return (
          <AjaxLoader key={ruleInstance.id + facet}>
            {
              !!empty &&
              <div className="loading">No Data</div>
            }
            {
              !!loading &&
              <div className="loading">
                <Spinner className="spinner" type="mini"/>
              </div>
            }
            {
              !!error &&
              <div className="error">{error}</div>
            }
            <div className="btn" onClick={e => this.loadMore()}>Load more...</div>
          </AjaxLoader>
        )
      }

      _loadMore() {
        const {keyParameter} = parameterMeta;
        let {page, keywords, onLoad} = this.props;
        let facet = (ruleInstance.values || {})[keyParameter] || 'section';
        this.setState({loading: true})
        const params = {
          site: site,
          filters,
          keywords: keywords,
          facet: facet,
          limit: 20,
          offset: page * 20,
          maxAge,
          maxAgeUnit,
          resourceType
        };
        console.log('page: ' + page);
        return post('/api/searchItemFacets', params).then(results => {
          const {count, items, maxFacet, offset} = results.facets || {};
          const hasMore = count > offset;
          this.setState({loading: false, empty: !count});
          onLoad(items.map(item => {
            let ret = {
              key: item.value,
              label: item.value
            };
            if(facet === 'section') {
             ret.label = item.value.split(':').slice(1).join('/');
            }
            return ret;
          }), hasMore);
        }).catch(e => {
          this.setState({loading: false, error: e.message});
          console.error(e);
        })
      }
    }

  }
})
