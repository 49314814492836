export default {
  'cna': {
    referrer: 'https://www.channelnewsasia.com/',
    url: 'https://www.channelnewsasia.com/news/world/donald-trump-impeachment-how-it-will-unroll-us-president-12081728#cxrecs_s',
    content_id: '12081728',
  },
  '8world': {
    referrer: 'https://www.8world.com/',
    url: 'https://www.8world.com/entertainment/e-news/article/ruby-lin-makes-fun-of-her-unfiltered-photo-984161',
    content_id: '984161',
  },
  'toggle': {
    referrer: 'https://entertainment.toggle.sg/',
    url: 'https://entertainment.toggle.sg/en/localbuzz/photos/zhang-zetong-all-around-you-12116582#slideshow-1',
    content_id: '12116582',
  },
  '8days': {
    referrer: 'https://www.8days.sg/',
    url: 'https://www.8days.sg/liveandlearn/vanityandhealth/why-you-should-give-yourself-or-your-loved-ones-the-gift-of-12085222',
    content_id: '12085222',
  },
  'mcs': {
    referrer: 'https://www.mediacorp.sg',
    url: 'https://www.mediacorp.sg'
  },
}