import React from 'react';
import styled, {css} from 'styled-components';
import {
  BitterSweet,
  BitterSweet1,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass, Grass1, LightBlue, LightGray, LightGray1, MediumGray,
  MediumGray1, Sunflower1
} from "../app/StyleCommon";

export const LangTitleWrapper = styled.div`
  position: relative;
  min-height: 400px;
  .actions {
    text-align: right;
    margin-top: 20px;
    .selectBtn {
      
    }
  }
  .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .add-btn {
      cursor:pointer;
    }
  }
  .table-wrapper {
    width: 100%;
    margin-top: 10px;
    max-height: 60vh;
    overflow-y: auto;
    min-height: 300px;
    table {
      .toggler {
        padding: 3px 10px;
        //background: white;
      }
    }
  }
  input.textEditor {
    border: 1px solid ${LightGray1};
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;
    outline: none;
  }
`