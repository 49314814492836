import moment from 'moment';
import * as d3 from 'd3';
import {countries} from './countries';
import {intFormatter, pct2Formatter, pctFormatter, uniquesFormatter} from "./formatter";

export const resourceTypes = [
  {
    key: 'Mediacorp Content',
    label: 'Mediacorp Content',
    data: [
      {
        key: 'sponsor_ads', label: 'Sponsored Ads',
      }
    ]
  }
];

export let algorithms = [
  {
    key: 'ads_rec_default',
    shortLabel: 'Default Ads Recommendation',
    label: 'Default Ads Recommendation',
    supported_res_types: ['sponsor_ads']
  }
];

export const setAlgorithmMetadata = function(data) {
  algorithms = data;
}

export const facetMeta = [
  {
    key: 'size',
    label: 'Size',
    contentTypes: ['sponsor_ads'],
  },
  {
    key: 'name',
    label: 'Name',
    contentTypes: ['sponsor_ads'],
  },
  {
    key: 'title',
    label: 'Title',
    contentTypes: ['sponsor_ads'],
  },
  {
    key: 'url',
    label: 'URL',
    contentTypes: ['sponsor_ads'],
  },
];