import React, {Component, useEffect} from 'react';
import ReactServer from 'react-dom/server';
import createTooltip from '../uikit/tooltip/index';
import * as echarts from 'echarts';
import classnames from 'classnames';
import moment from 'moment';
import styled from 'styled-components';
import _ from 'lodash';
import {ReRenderHook} from '../utils/hooks';
import {
  BitterSweet,
  BitterSweet1,
  BlueJeans, BlueJeans0,
  BlueJeans1, colorCategory1, DarkGray, GrapeFruit, Grass,
  Grass1,
  LightBlue,
  LightGray,
  LightGray1, MediumGray, MediumGray1,
  Sunflower,
  Sunflower1
} from "../app/StyleCommon";
import {
  filterTags,
  intFormatter,
  pct0Formatter,
  pct2Formatter,
  pctFormatter,
  responseTimeFormatter,
  uniquesFormatter
} from "../utils/formatter";
import {Hint, Table, TableScrollWrapper} from "../uikit";
import {BarWrapper} from "../home/index.style";
import {engineMap, metrics} from "../utils/metadata";
import Sparkline3 from "./sparkline3";
import {Card, CardTitle, PaginationStyled, TopWidgets} from "./index.style";
import {Link} from "react-router-dom";
import {Taglist} from "../widgets/index.style";
import {metricMap} from "../utils/metadata";


const MetricWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  //background: ${LightGray};
  padding: 5px 5px 5px 5px;
  .num {
    margin-right: 10px;
  }
  .marker {
    width: 10px;
    height: 8px;
    margin-left: 4px;
    margin-right: 6px;
    max-width: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    &.gray {
      filter: grayscale(100);
    }
    &.revert {
      transform: rotate(180deg);
      transform-origin: 50% 50%;
    }
  }
`

export default class extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openMap: {},
      openSite: 'cna'
    };
  }

  render() {
    const {data, config, period} = this.props;
    let {total,items,} = data || {};
    const {engineSortBy, engineSortDirection} = config || {}
    const today = moment().format('YYYY-MM-DD');
    const yesterday = moment().add(-1, 'day').format('YYYY-MM-DD');
    // items = [
    //   {key: 'ta', label: 'Think Analytics', impressions: 213123, clicks: 2324, trending: _.range(0, 30).map(i => {
    //     return {impressions: 12, clicks: 1};
    //     })},
    //   {key: 'boost', label: 'BOOST', impressions: 213123, clicks: 2324, trending: _.range(0, 30).map(i => {
    //   return {impressions: 12, clicks: 1};
    // })}
    // ]
    let retentionMetric = period === 'last30days' ? 'retention_rate_30days' : 'retention_rate';

    return (
      <TopWidgets>
        <TableScrollWrapper className="middle">
          <Table
            columns={[
              {
                key: 'engine',
                label: 'Engine',
                style: {verticalAlign: 'top'},
                renderer: d => {
                  return (
                    <div className="engine-info">
                      <div className="top">
                        <img className="publisher" src={`/img/property/${d.engine}.png`} style={{width: '16px'}}></img>
                        <span style={{marginLeft: '6px'}}>{engineMap[d.engine]}</span>
                      </div>
                    </div>
                  )
                }
              },
              ...(['impressions', 'ctr', 'video_starts', 'vcr', 'watch_time','bookmarks',  'avg_session_duration', 'uvv', 'retention_rate'].map(metric => {
                let metricMeta = metrics.find(m => m.key === metric) || {};
                return {
                  key: metric,
                  label: (
                    <div >
                      <span>
                        {metricMap[metric]}
                      </span>
                      {
                        !!metricMeta.description &&
                        <span style={{position: 'absolute'}}><Hint pos="lefttop"
                              className="bright-hint lightAnker"
                              useFixed={false} width={350} content={
                          <span style={{textAlign: 'left'}}>
                            {metricMeta.description}
                          </span>
                        }/>
                          </span>
                      }
                      {!!metricMeta.priority && <div style={{color: MediumGray1, fontSize: '11px', fontWeight: 'normal'}}> ({metricMeta.priority})</div>}
                    </div>
                  ),
                  align: 'left',
                  style: {verticalAlign: 'top'},
                  // style: {minWidth: '100px'},
                  sortable: false,
                  renderer: d => {
                    let max = _.max(items.map(item => item[metric] || 0));
                    let metricMeta = metrics.find(m => m.key === metric);
                    if(metric === 'retention_rate') {
                      return (
                        <MetricWrapper>
                          <div className="num">D7: {metricMeta.formatter(d.retention_rate)}</div>
                          <div className="num">M1: {metricMeta.formatter(d.retention_rate_30days)}</div>
                        </MetricWrapper>
                      )
                    }
                    return (
                      <MetricWrapper onClick={e => this.props.showDeeperInsights(metric, d.engine)}
                        style={{alignItems: 'flex-start'}}>
                        <div className="num">
                          {metricMeta.formatter(d[metric])}
                          {
                            metric === 'uvv' &&
                            <div style={{fontSize: '11px', color: MediumGray}}>(UV: {intFormatter(Math.round(d.uv))})</div>
                          }
                        </div>
                        {/*<BarWrapper className="small bar-wrapper">*/}
                        {/*  */}
                        {/*  <div className="bar"*/}
                        {/*       style={{width: pctFormatter(d[metric] / max)}}></div>*/}
                        {/*</BarWrapper>*/}
                        <Sparkline3
                          className="trend"
                          period={period}
                          hideVisualMap={true}
                          width={50}
                          height={20}
                          data={(d.trend || []).map(item => item[metric])}
                          formatter={metricMeta.formatter}/>
                      </MetricWrapper>
                    )
                  }
                };
              })),
            ]}
            rows={(items || []).filter(d => ['ta', 'boost'].indexOf(d.engine)>= 0)}
            sort={{sortColumn: engineSortBy, direction: engineSortDirection}}
            sortChange={orderBy => {
              this.props.refreshChart('engineBreakdown', {'engineSortBy': orderBy})
            }}
            directionChange={direction => {
              this.props.refreshChart('engineBreakdown', {'engineSortDirection': direction})
            }}
            noDataText={"No Engine data Found"}
          />
        </TableScrollWrapper>
      </TopWidgets>
    )
  }
}