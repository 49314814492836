import React from 'react';
import ReactDOM from 'react-dom';
import { EditorState, Modifier } from 'draft-js';
import CondirmDialog from '../dialog/index';


export default class extends React.Component {

  renderModal = () => {
    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      title: <strong>Personalize EDM</strong>,
      width: '400px',
      hideCancel: true,
      hideOK: true,
      onCancel: () => CondirmDialog.closeAll(),
      dialogBody: (
        <div>
          <ul>
            <li><a onClick={e => this.addWapper('${first_name}')}>First Name</a></li>
            <li><a onClick={e => this.addWapper('${last_name}')}>Last Name</a></li>
            <li><a onClick={e => this.addWapper('${email}')}>Email</a></li>
            <li><a onClick={e => this.addWapper('${sso_id}')}>Email</a></li>
            <li><a onClick={e => this.addWapper('${gender}')}>Gender</a></li>
            <li><a onClick={e => this.addWapper('${dob}')}>Birthday</a></li>
            <li><a onClick={e => this.addWapper('${survey_url}')}>Survey URL</a></li>
            <li><a onClick={e => this.addWapper(`{{{unsubscribe}}}`)}>Opt out link</a></li>
          </ul>
        </div>
      ),
    }
    ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById('global-confirm'));
  };

  addWapper(text) {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      text,
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
    CondirmDialog.closeAll();
  };

  render() {
    return (
      <a onClick={e => this.renderModal()}>⭐</a>
    );
  }
}