import React from 'react';
import styled, {css} from 'styled-components';
import {
  BitterSweet,
  BitterSweet1,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass, Grass1, LightBlue, LightGray, LightGray1, MediumGray,
  MediumGray1, Sunflower1
} from "../app/StyleCommon";

export const CxenseSelectorWrapper = styled.div`
  position: relative;
  margin-top: -20px;
  min-height: 400px;
  .actions {
    text-align: right;
    margin-top: 20px;
    .selectBtn {
      
    }
  }
  .table-wrapper {
    width: 100%;
    margin-top: 10px;
    max-height: 60vh;
    overflow-y: auto;
    min-height: 300px;
    table {
      
    }
  }
  
  .rc-pagination li{
    min-width: 24px;
    height: 24px;
    line-height: 24px;
  }
  
  .searchBox {
    flex-grow: 1;
    margin-top: 40px;
    i {
      position: absolute;
      left: 10px;
      top: 10px;
    }
    input {
      padding: 8px 10px 8px 30px;
      font-size: 14px;
      border-radius: 5px;
      border: 1px solid ${MediumGray};
      background: ${LightGray};
      width: 100%;
      box-sizing: border-box;
      outline: none;
      &:focus {
        border-color: ${BlueJeans};
        box-shadow: inset 0 0 3px rgba(0,0,0,.1);
      }
    }
  }
`