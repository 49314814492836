import React from 'react';
import ReactDOM from 'react-dom';
import GridWidget from './widgets/layouts/grid'

window.recApp = window.recApp || {};
window.recApp.renderWidget = function (widget, context = {}, mountPoint) {

  if(!mountPoint) {
    var containterId = widget.id;
    if (context && context.abtesting) {
      containterId = context.abtesting.id;
    }
    mountPoint = document.getElementById(containterId);
    if (context.loadid) {
      mountPoint = document.querySelector('[data-loadid="' + containterId + '-' + context.loadid + '"]');
    }
  } else {
    mountPoint = document.querySelector(mountPoint);
  }

  if (mountPoint) {
    let {layout, layoutParams} = widget;
    let WidgetComponent = {
      'grid': GridWidget,
    }[layout] || GridWidget;
    ReactDOM.render(<WidgetComponent widget={widget} key={Date.now()} context={context}/>, mountPoint);
  }
}