import React from 'react';
import ReactDOM from 'react-dom';
import * as d3 from 'd3';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import {TableScrollWrapper} from '../uikit/index';
import {withConsumer} from '../app/ApplicationContext';
import VariantPreview from '../widgets/previewBox';
import {Table, CondirmDialog} from '../uikit/index';
import {BarWrapper} from "../dashboard/index.style";
import {
  filterTags,
  intFormatter,
  pct2Formatter,
  pctFormatter,
  responseTimeFormatter,
  uniquesFormatter
} from '../utils/formatter';
import ClickLogPanel from "../widgets/clickLogPanel";
import {algorithms} from "../utils/metadata";
import styled from "styled-components";

const BreakdownTableWrapper = styled.table`
  width: 100%;
  text-align: center;
  min-width: 200px;
  th {
    font-size: 11px !important;
    font-weight: normal !important;
    padding: 0 3px 0 0 !important;
  }
  td{
    border: none !important;
    text-align: left !important;
    padding: 2px 3px 2px 0 !important;
    font-size: 11px !important;
  }
  tr:not(:last-child) td{
    border-bottom: 1px dashed #d1d2d3 !important;
  }
  tr td:first-child{
    text-align: left;
  }
`
export default withConsumer(class extends React.Component {

  render() {
    const {appState: {session}} = this.props;
    const isAdmin = (session || {}).isAdmin;

    const {abtesting, data, widgets, showTimeout, showBreakdown} = this.props;
    const {status, variants, site, isEngineSticky} = abtesting || {};
    const isEngineAB = site === 'mewatch' && isEngineSticky;
    let {items = [], maxImpressions} = data || {};
    const controlItem = variants.find(d => !!d.isControl) || {};
    const baseItem = items.find(d => d.variant === controlItem.id) || {};
    const stats = variants.map(d => {
      const curVariant = items.find(v => v.variant === d.id);
      return {
        ...curVariant,
        name: d.name,
        weight: d.weight,
        id: d.id
      }
    });
    let maxCtr = _.max(stats.map(d => d.ctr));
    let minCtr = _.min(stats.map(d => d.ctr));
    let maxLift = (maxCtr - baseItem.ctr) / baseItem.ctr;
    let minLift = (minCtr - baseItem.ctr) / baseItem.ctr;
    const sortedStats = _.sortBy(stats, d => isNaN(d.ctr) ? -1 : d.ctr).reverse();
    const colorScale = d3.scaleLinear().domain([-10000, -Math.abs(minLift), 0, maxLift, 10000]).range(['#E9573F', '#E9573F', '#656D78', '#8CC152', '#8CC152']);
    return (
      <TableScrollWrapper>
        <Table
          columns={[
            {
              key: 'name',
              label: 'Variants',
              align: 'left',
              renderer: (d, index) => {
                let widget = (widgets || []).find(w => w.id === d.id) || {};
                return (
                  <Link to={`/widget/${d.id}`} className="widget-name-card">
                    <div>{d.name}</div>
                    <div className="tag-list">
                      {filterTags(widget.tags || [], 'widget', isAdmin).map(t => {
                        return <span className={`tag ${t.split('::')[0]}`} key={t}>{t.split('::').pop()}</span>
                      })}
                      {(!widget.tags || !widget.tags.length) && '--'}
                      {isAdmin && this.renderWidgetAlgorithm(widget)}
                    </div>
                    {/*<a className="name" onClick={e => this.showPreviewPopup(d.id)}>*/}
                    {/*<i className="fa fa-eye"/>*/}
                    {/*</a>*/}
                  </Link>
                )
              }
            },
            {
              key: 'weight',
              label: 'Weight',
              align: 'center',
              renderer: d => `${(d.weight * 100).toFixed()}%`
            },
            {
              key: 'impressions',
              label: 'Impressions',
              align: 'left',
              renderer: d => {
                return (
                  <BarWrapper className="small">
                    <div className="num">{uniquesFormatter(d.impressions)}</div>
                    <div className="bar"
                         style={{width: pctFormatter(d.impressions / maxImpressions)}}></div>
                  </BarWrapper>
                )
              }
            },
            {
              key: 'clicks',
              label: 'Clicks',
              hidden: isEngineAB,
              align: 'left',
              renderer: d => {
                return (
                  <div style={d.clicks ? {textDecoration: 'underline', cursor: 'pointer'} : undefined}
                       onClick={e => {
                         this.showClickDetails(d)
                       }}>{uniquesFormatter(d.clicks)}</div>
                );
              }
            },
            // {
            //   key: 'response_time',
            //   label: 'Response Time',
            //   align: 'center',
            //   renderer: d => {
            //     return responseTimeFormatter(d.response_time);
            //   }
            // },
            {
              key: 'error_rate',
              label: 'Timeouts',
              align: 'left',
              hidden: !showTimeout || isEngineAB,
              renderer: d => {
                return pct2Formatter(d.error_rate);
              }
            },
            {
              key: 'ctr',
              label: 'CTR',
              align: 'left',
              style: {minWidth: 80},
              renderer: d => {
                if (status === 'Draft' || d.ctr === undefined) {
                  return '--';
                }
                return (
                  <BarWrapper className="small">
                    <div className="num">{pct2Formatter(d.ctr)}</div>
                    <div className="bar"
                         style={{width: pct2Formatter(d.ctr / maxCtr)}}></div>
                  </BarWrapper>
                )
              }
            },
            {
              key: 'lift',
              label: 'Lift',
              align: 'left',
              hidden: isEngineAB,
              style: {minWidth: 80},
              renderer: d => {
                if (d.variant === baseItem.variant) {
                  return 'baseline';
                }
                if (status === 'Draft' || d.ctr === undefined) {
                  return '--';
                }
                const lift = (d.ctr - baseItem.ctr) / baseItem.ctr;
                return (
                  <BarWrapper className="small">
                    <div className="num"
                         style={{fontWeight: 'bold', color: colorScale(lift)}}>{pctFormatter(lift)}</div>
                    {/*<div className="bar"*/}
                    {/*     style={{width: pct2Formatter(d.ctr / maxCtr)}}></div>*/}
                  </BarWrapper>
                )
              }
            },
            {
              key: 'breakdown',
              hidden: !isAdmin || !showBreakdown || isEngineAB,
              label: (
                <div>
                  <div>Model Breakdown</div>
                  <BreakdownTableWrapper>
                    <tr>
                      <th>Model</th>
                      <th>Coverage</th>
                      <th>CTR</th>
                      <th>Lift</th>
                    </tr>
                  </BreakdownTableWrapper>
                </div>
              ),
              align: 'left',
              colSpan: 3,
              style: {paddingLeft: '0'},
              renderer: d => {
                let isMab = (d.breakdown ||[]).every(r => r.name.indexOf('Fallback') >= 0);
                return (
                  <BreakdownTableWrapper>
                    {_.sortBy((d.breakdown || []), r => r.ctr).reverse().filter(r => r.coverage).map(r => {
                      let lift = (r.ctr - baseItem.ctr) / baseItem.ctr;
                      return (
                        <tr key={r.name} style={{fontSize: '11px', color: '#535353'}}>
                          <td>{isMab ? r.name.replace('Fallback', 'MAB') : r.name}</td>
                          <td title={r.coverage}>{pctFormatter(r.coverage)}</td>
                          <td title={r.ctr}>{pctFormatter(r.ctr)}</td>
                          <td style={{fontWeight: 'bold', color: colorScale(lift)}}>
                            {d.variant === baseItem.variant ?  '--' : pctFormatter(lift)}
                          </td>
                        </tr>
                      )
                    })}
                  </BreakdownTableWrapper>
                )
              }
            },
          ]}
          rows={sortedStats}
          noDataText={"No Data"}
        />
      </TableScrollWrapper>
    );
  }

  renderWidgetAlgorithm(d) {
    if (d.cxenseWidget) {
      return <span className="tag algo">Cxense</span>;
    }
    else if (d.thinkWidget) {
      return <span className="tag algo">Think Analytics</span>;
    } else {
      const {layoutParams = {}} = d.layoutConfig || {};
      let usedSlots = _.uniq(Object.values(layoutParams.slotGroupMapping || {}));
      let usedSlotGroup = (d.slotGroups || []).filter(s => usedSlots.indexOf(s.name) >= 0);
      let algos = _.uniq(usedSlotGroup.map(s => s.algorithm));
      let backfill = _.uniq(_.compact(usedSlotGroup.map(s => (s.backfill || {}).algorithm)))[0];
      if (algos.length > 1) {
        return <span className="tag algo">Hybrid</span>
      } else if (algos.length === 1) {
        let algo = algos[0];
        let meta = algorithms.find(d => d.key === algo);
        let backfillmeta = algorithms.find(d => d.key === backfill);
        return (
          <>
            <span className="tag algo" key={algo}>{meta ? meta.label : algo}</span>
            {!!backfill && <span className="tag backfill"
                                 key={backfill}>Fallback: <strong>{backfillmeta ? backfillmeta.label : backfill}</strong></span>}
          </>
        )
      }
    }
  }

  showPreviewPopup(widgetId) {
    const {abtesting} = this.props;
    const {appState: {session}} = this.props;
    const siteOption = session.sites.find(s => s.key === abtesting.site);
    const confirmInfo = {
      type: 'form',
      backgroundClose: false,
      width: '650px',
      hideCancel: true,
      hideOK: true,
      dialogBody: <VariantPreview id={widgetId} site={abtesting.site} siteOption={siteOption}/>,
      onConfirm: () => {
        CondirmDialog.closeAll();
      },
      onCancel: () => {
        CondirmDialog.closeAll();
      }
    }

    ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById('global-confirm'));
  }

  showClickDetails(d) {
    const {period} = this.props;
    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      title: <strong>Click Logs for {d.name}</strong>,
      width: '1000px',
      hideCancel: true,
      hideOK: true,
      onCancel: () => CondirmDialog.closeAll(),
      dialogBody: <ClickLogPanel
        type='widget'
        id={d.id}
        period={period}
        onConfirm={() => null}
        onCancel={() => {
          CondirmDialog.closeAll();
        }}
      />,
    }
    ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById('global-confirm'));
  }
})
