import React from 'react'
import ReactServer from 'react-dom/server';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import Dropzone from 'react-dropzone'
import moment from 'moment';
import * as d3 from 'd3';
import {withConsumer} from '../app/ApplicationContext';
import {post} from '../utils/request';
import {SelectStyled} from './select.style';
import {Btn, TableScrollWrapper, Table, ProgressBar, CondirmDialog, Loader} from '../uikit/index';
import {
  PageWrapper,
  SelectorWrapper,
  ControlBar,
  UploadWrapper,
  CreativeList,
  EmptyList
} from './builder-creative.style';
import {PaginationStyled} from '../uikit/Pagination/index.style';
import {BlueJeans} from "../app/StyleCommon";
import CreativeSelector from './builder-creative-selector';
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import CreativeRenderer from './creativeRenderer';

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {campaign, errorMap, readOnly} = this.props;
    const {creatives = []} = campaign;
    const {loading, error} = this.state;
    return (
      <PageWrapper>
        {!!error && <ErrorMessage message={error}/>}
        {!!errorMap.creatives && <ErrorMessage message={errorMap.creatives}/>}
        {!!loading && <Loader type="absolute"/>}
        {
          !!creatives.length &&
          <CreativeList>
            <div className="creative-list-header">
              <p><strong>{creatives.length}</strong> creatives selected:</p>
              <div className="action-suggestions">
                <Btn disabled={readOnly} type="link" onClick={e => this.showCreativeSelector('all')}><i
                  className="fa fa-plus"/> Add More</Btn>
              </div>
            </div>
            <ul className="creative-list-body">
              {creatives.map((d, index) => {
                return (
                  <div className="selected-creative" key={d.id}>
                    <div className="img-container">
                      <div>
                        <CreativeRenderer creative={d} noName={true} width={d.size.split('x')[0] / 2}
                                          height={d.size.split('x')[1] / 2}/>
                        <div style={{margin: 'auto', textAlign: 'center'}}>{d.size}</div>
                      </div>
                    </div>
                    <div className="attrs">
                      <div className="attr">
                        <label>ID:</label> <input type="text" value={d.id}/>
                      </div>
                      <div className="attr">
                        <label>Name:</label> <input type="text" value={d.name}/>
                      </div>
                      <div className="attr">
                        <label>Size:</label> <input type="text" value={d.size}/>
                      </div>
                      <div className="attr">
                        <label>Title:</label> <input type="text" value={d.title}/>
                      </div>
                      <div className="attr">
                        <label>Click Through Url:</label> <input type="text" value={d.url}/>
                      </div>
                      {/*{*/}
                      {/*  !!(campaign.widget ||[]).filter(t => t.size === d.size).length &&*/}
                      {/*  <div className="attr bottom-align">*/}
                      {/*    <label>Target Email Tag:</label>*/}
                      {/*    <SelectStyled*/}
                      {/*      defaultLabel="Select..."*/}
                      {/*      allSelectedLabel="All"*/}
                      {/*      multi={true}*/}
                      {/*      showGroupBatch={false}*/}
                      {/*      plainStyle={true}*/}
                      {/*      disabled={readOnly}*/}
                      {/*      searchable={false}*/}
                      {/*      selected={d.tag_ids}*/}
                      {/*      data={supportedTags}*/}
                      {/*      onChange={tag_ids => {*/}
                      {/*        d.tag_ids = tag_ids;*/}
                      {/*        this.props.onChange({creatives: campaign.creatives});*/}
                      {/*      }}/>*/}
                      {/*  </div>*/}
                      {/*}*/}
                      {/*{*/}
                      {/*  !(campaign.widgets ||[]).filter(t => t.size === d.size).length &&*/}
                      {/*  <div className="attr">*/}
                      {/*    <div style={{marginTop: '10px', color: 'red', fontSize: '12px'}}>No matching tag found, please*/}
                      {/*      add an Email tag with this size or remove this creative from campaign.*/}
                      {/*    </div>*/}
                      {/*  </div>*/}
                      {/*}*/}

                      <div className="actions">
                        <Btn disabled={readOnly} type="reset" size="mini" onClick={e => this.deselectCreative(d)}>
                          <i className="fa fa-times"/> Remove
                        </Btn>
                        {/*{*/}
                        {/*  d.source !== 'dfp' &&*/}
                        {/*  <Btn disabled={readOnly} type="reset" size="mini"*/}
                        {/*       onClick={e => this.showCreativeSelector('edit', d)}>*/}
                        {/*    <i className="fa fa-edit"/> Modify*/}
                        {/*  </Btn>*/}
                        {/*}*/}
                      </div>
                    </div>
                  </div>
                )
              })}
            </ul>
            {/*<p className="note"><strong>Note:</strong> You can add more creative by clicking the '+' on the right side above the list.</p>*/}
          </CreativeList>
        }
        {
          !creatives.length &&
          <EmptyList>
            <div>
              <img src="/img/ads/builder/creative.png"/>
              <p>Please click the bellow button to select at least one creative for this campaign.</p>
              <div>
                <Btn type="primary" onClick={e => this.showCreativeSelector('select')}>Select Creatives</Btn>
              </div>
            </div>
          </EmptyList>
        }
      </PageWrapper>
    );
  }

  showCreativeSelector(mode, creative) {
    const {campaign} = this.props;
    const {loading, data, error, tags} = this.state;
    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      title: <strong>Select Creative</strong>,
      width: '900px',
      hideCancel: true,
      hideOK: true,
      onCancel: () => CondirmDialog.closeAll(),
      dialogBody: <CreativeSelector
        data={data}
        creative={creative}
        advertiser={campaign.advertiser}
        selected={campaign.creatives}
        mode={mode}
        onConfirm={(creative) => {
          this.selectCreative(creative);
          CondirmDialog.closeAll();
        }}
        onCancel={() => {
          CondirmDialog.closeAll();
        }}
      />,
    }
    ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById('global-confirm'));
  }

  selectCreative(creative) {
    const {campaign} = this.props;
    this.props.onChange({creatives: [...campaign.creatives, creative]});
  }

  deselectCreative(creative) {
    const {campaign} = this.props;
    this.props.onChange({creatives: campaign.creatives.filter(d => d.id !== creative.id)});
  }

  static validate(campaign) {
    const {creatives} = campaign || {};
    if (!creatives || !creatives.length) {
      return {
        tags: 'Need to select at least 1 creative'
      };
    }
  }
}