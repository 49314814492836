import {duration, hmsDuration, intFormatter, pct2Formatter, pctFormatter, uniquesFormatter} from "../utils/formatter";
import {metrics, algorithms, sites} from '../utils/metadata';
import * as d3 from 'd3';

export const dashboardCards = [
  {
    key: 'impressionStat',
    label: 'Impressions',
    color: 'blue3',
    icon: 'fa fa-eye',
    formatter: uniquesFormatter
  },
  {
    key: 'clickStat',
    label: 'Clicks',
    color: 'green1',
    icon: 'fa fa-mouse-pointer',
    formatter: uniquesFormatter
  },
  {
    key: 'ctrStat',
    label: 'CTR',
    color: 'blue4',
    icon: 'fa fa-flag',
    formatter: pct2Formatter
  },
  {
    key: 'responseTime',
    label: 'Avg Response Time',
    color: 'blue2',
    icon: 'fa fa-hourglass',
    formatter: d => d3.format(d > 1000 * 60 ? '.5' : '.3')((d || 0) / 1000) + 's'
  },
  {
    key: 'errorRateStat',
    label: 'Timeouts',
    color: 'red1',
    icon: 'fa fa-clock-o',
    formatter: pct2Formatter
  },
];

export const dashboardCardsMewatch = [
  {
    key: 'impressionsStat',
    label: 'Impressions',
    color: 'blue3',
    icon: 'fa fa-eye',
    formatter: uniquesFormatter
  },
  {
    key: 'ctrStat',
    label: 'CTR',
    color: 'green1',
    icon: 'fa fa-mouse-pointer',
    formatter: pct2Formatter
  },
  {
    key: 'video_startsStat',
    label: 'Video View',
    color: 'blue4',
    icon: 'fa fa-flag',
    formatter: uniquesFormatter
  },
  {
    key: 'vcrStat',
    label: 'Completion Rate',
    color: 'blue4',
    icon: 'fa fa-flag',
    formatter: pct2Formatter
  },
  {
    key: 'watch_timeStat',
    label: 'Watch Time',
    color: 'blue2',
    icon: 'fa fa-hourglass',
    formatter: hmsDuration
  }
];

export const timeseriesDimensions = [
  {key: 'algorithm', label: 'Algorithms'},
  {key: 'variant', label: 'Variants'},
  // {key: 'target', label: 'Target Users'},
  // {key: 'non_target', label: 'Non Target Users'}
];

export const abtestingSortOptions = [
  {key: 'name', label: 'A/B Test Name', direction: 'asc'},
  {key: 'CreatedDateTime', label: 'Created Time', direction: 'desc'},
  {key: 'UpdatedDateTime', label: 'Last Updated Time', direction: 'desc'},
  {key: 'last_hour_impressions', label: 'Last Hour Traffic', direction: 'desc'},
]