import {duration, intFormatter, pct2Formatter, pctFormatter, uniquesFormatter} from "../utils/formatter";
import {metrics, algorithms} from '../utils/metadata';
import * as d3 from 'd3';

export const dashboardCards = [
  {
    key: 'impressionsStat',
    label: 'Impressions',
    color: 'blue3',
    icon: 'fa fa-eye',
    formatter: uniquesFormatter
  },
  {
    key: 'clicksStat',
    label: 'Clicks',
    color: 'green1',
    icon: 'fa fa-mouse-pointer',
    formatter: uniquesFormatter
  },
  {
    key: 'ctrStat',
    label: 'CTR',
    color: 'blue4',
    icon: 'fa fa-flag',
    formatter: pct2Formatter
  },
  {
    key: 'response_timeStat',
    label: 'Avg Response Time',
    color: 'blue2',
    icon: 'fa fa-hourglass',
    formatter: d => d3.format(d > 1000 * 60 ? '.5' : '.3')((d || 0) / 1000) + 's'
  },
  // {
  //   key: 'errorsStat',
  //   label: 'Timeouts',
  //   color: 'red1',
  //   icon: 'fa fa-clock-o',
  //   formatter: intFormatter
  // },
  {
    key: 'error_rateStat',
    label: 'Timeouts',
    color: 'red1',
    icon: 'fa fa-clock-o',
    formatter: pct2Formatter
  },
];

export const timeseriesDimensions = [
  {key: 'slot_group', label: 'Slot Group'},
  {key: 'algorithm', label: 'Model Algorithm'},
  // {key: 'widget_type', label: 'Widget Type'},
  // {key: 'site', label: 'Site'},
  // {key: 'ranking_algorithm', label: 'Ranking Algorithm'},
  {key: 'slot_position', label: 'Slot Position'},
  {key: 'access_method', label: 'Access Method'},
  // {key: 'os', label: 'OS'},
  // {key: 'browser', label: 'browser'},
]

export const widgetSortOptions = [
  // {key: 'impressions', label: 'Total Impressions', direction: 'desc'},
  // {key: 'clicks', label: 'Total Clicks', direction: 'desc'},
  // {key: 'response_time', label: 'Averate Response Time', direction: 'desc'},
  // {key: 'ctr', label: 'CTR', direction: 'desc'},
  {key: 'name', label: 'Widget Name', direction: 'asc'},
  {key: 'CreatedDateTime', label: 'Created Time', direction: 'desc'},
  {key: 'UpdatedDateTime', label: 'Last Updated Time', direction: 'desc'},
  {key: 'last_hour_impressions', label: 'Last Hour Traffic', direction: 'desc'},
]