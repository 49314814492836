import React from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import {withConsumer} from '../app/ApplicationContext';
import {withRouter} from 'react-router';
import moment from 'moment';
import * as d3 from 'd3';
import {Redirect, Link} from 'react-router-dom';
import {getFromCache, cacheResource} from '../utils/cache'
import {campaignStatus, campaignTypes} from './report.metadata';
import campaignPriorities from '../utils/campaignPriorities';
import {ProgressBar, Btn, Table, CondirmDialog, TabContainer, Loader, TableScrollWrapper} from '../uikit/index';
import {DateSelectorDropdown} from '../uikit/index';
import MainLayout from '../layout/index';
import {ContentHomeWrapper, FilterBarFirst, KeyMetrics, CreativeController, CreativeModule} from './index.style';
import {CampaignModule, CampaignController, StatusTag} from './index.style';
import {FilterDropdown, FilterTagWrapper} from './index.style';
import {PaginationStyled} from '../dashboard/index.style';
import {StyledDropdown} from '../uikit/dropdown/styled';
import {post} from "../utils/request";
import {SelectStyled} from './select.style';
import {
  intFormatter,
  pctFormatter,
  uniquesFormatter,
  filterTags,
  pct2Formatter,
  responseTimeFormatter, periodFormat
} from "../utils/formatter";
import {BlueJeans, MediumGray1} from "../app/StyleCommon";
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import copy from 'copy-to-clipboard';
import CollectionEditor from "../widgets/slotCollection";
import {ListFooter, Taglist} from "../widgets/index.style";
import {layouts} from "../utils/layouts";
import RealtimeChart from "../widgets/realtimeChart";
import Sparkline from "../dashboard/sparkline";
import CreativeRenderer from "./creativeRenderer";

const FILTER_CACHE_KEY = 'campaign_list_filters';
export default withRouter(withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    const cachedSettings = getFromCache(FILTER_CACHE_KEY) || {};
    this.state = {
      activeIndex: props.activeIndex || 0,
      PAGE_SIZE: 5,
      module: 'Sponsored Ads',
      creativeSearch: '',
      creativePage: 1,
      creativeSortBy: 'CreatedDateTime',
      creativeSortDirection: 'desc',
      campaignPage: 1,
      campaignSearch: '',
      campaignSortBy: 'CreatedDateTime',
      campaignSortDirection: 'desc',
      ...(cachedSettings || {}),
    };
    this.search = _.debounce(() => this.setState({data: null, page: 1}), 800);
  }

  buildQuery() {
    const {PAGE_SIZE, creativeSearch, campaignSearch} = this.state;
    const {status, type, io, advertiser, period, size} = this.state;
    return {
      creativeQuery: {
        limit: PAGE_SIZE,
        search: creativeSearch,
        offset: (this.state.creativePage - 1) * PAGE_SIZE,
        sortBy: this.state.creativeSortBy,
        sortDirection: this.state.creativeSortDirection,
        size
      },
      campaignQuery: {
        limit: PAGE_SIZE,
        search: campaignSearch,
        offset: (this.state.campaignPage - 1) * PAGE_SIZE,
        sortBy: this.state.campaignSortBy,
        sortDirection: this.state.campaignSortDirection,
        status, type, io, advertiser, period
      },
    };
  }

  cacheParams() {
    cacheResource(FILTER_CACHE_KEY, _.pick(this.state, ['site', 'page', 'PAGE_SIZE', 'status', 'mode', 'page', 'sortBy']))
  }

  render() {
    const {appState: {session}} = this.props;
    const isAdmin = (session || {}).isAdmin;
    const {data, error, loading, module, activeIndex, campaignSearch, creativeSearch} = this.state;
    const {PAGE_SIZE, creativePage, creativeSortBy, creativeSortDirection, campaignPage, campaignSortBy, campaignSortDirection} = this.state;
    const {status, type, io, advertiser, period, size} = this.state;
    const {creatives, campaigns, articles, videos, audios, campaignCount, creativeCount, adInsights, advertisers, sizes} = data || {};
    return (
      <MainLayout activeItem="sponsored_ads" breadcrumb={[{path: '/sponsored_ads', label: 'Sponsored Ads'}]}>
        <ContentHomeWrapper>
          {
            !data && !error &&
            <ProgressBar
              withoutCache={true}
              fixcenter={true}
              url={`/api/querySponsorAds`}
              params={this.buildQuery()}
              successHandler={data => this.setState({data: data})}
              errorHandler={e => this.setState({error: e.message})}/>
          }

          <FilterBarFirst>
            <div className="left">
              <h3>Sponsored Ads</h3>
            </div>
            <div  className="right"></div>
          </FilterBarFirst>
          {
            !!error && <ErrorMessage message={error}></ErrorMessage>
          }
          {
            !!data &&
            <div>
              <KeyMetrics className="no-border">
                <div className="metric-item">
                  <div className="top">
                    <i className="fa fa-bullhorn" aria-hidden="true"></i>
                    <div className="label">Campaigns</div>
                  </div>
                  <div className="bottom">
                    <div className="value">{uniquesFormatter(campaignCount)}</div>
                  </div>
                </div>
                <div className="metric-item">
                  <div className="top">
                    <i className="fa fa-file-image-o" aria-hidden="true"></i>
                    <div className="label">Creatives</div>
                  </div>
                  <div className="bottom">
                    <div className="value">{uniquesFormatter(creativeCount)}</div>
                  </div>
                </div>
                <div className="metric-item">
                  <div className="top">
                    <i className="fa fa-eye" aria-hidden="true"></i>
                    <div className="label">Avg Daily Impressions</div>
                  </div>

                  <div className="bottom">
                    <div className="value">{uniquesFormatter(Math.round(adInsights.impressions / (adInsights.history || []).length))}</div>
                    <Sparkline
                      size="small"
                      data={(adInsights.history || []).map(d => d.impressions || 0)}
                      formatter={intFormatter}
                      theme="dark"
                      period={adInsights.period}/>
                  </div>
                </div>
                <div className="metric-item">
                  <div className="top">
                    <i className="fa fa-flag" aria-hidden="true"></i>
                    <div className="label">Avg CTR</div>
                  </div>
                  <div className="bottom">
                    <div className="value">{pct2Formatter(adInsights.clicks / adInsights.impressions)}</div>
                    <Sparkline
                      data={(adInsights.history || []).map(d => (d.clicks / d.impressions) || 0)}
                      formatter={pct2Formatter}
                      theme="dark"
                      period={adInsights.period}/>
                  </div>
                </div>
              </KeyMetrics>
              <TabContainer
                tabTitles={['Campaigns', 'Creatives']}
                activeIndex={activeIndex || 0}
                onChange={i => this.setState({activeIndex: i})}>
                <CampaignModule>
                  <CampaignController>
                    <div className="searchBox">
                      <i className="fa fa-search"/>
                      <input
                        type="text"
                        placeholder="Enter campaign ID, name to search ..."
                        value={campaignSearch}
                        onChange={e => this.setState({campaignSearch: e.target.value}, () => {
                          this.search();
                        })}/>
                    </div>
                    <FilterDropdown toggler={(
                      <div><i className="caret fa fa-filter"/> Filters</div>
                    )}>
                      <h3>Filters: </h3>
                      <div className="menu-item">
                        <label>Advertiser</label>
                        <SelectStyled
                          selected={advertiser}
                          plainStyle={true}
                          defaultLabel="All"
                          data={[
                            {key: 'All', label: 'All'},
                          ].concat((advertisers || []).map(a => ({key: a, label: a})))}
                          onChange={advertiser => {
                            this.setState({campaignPage: 1, advertiser: advertiser, data: null})
                          }}/>
                      </div>
                      <div className="menu-item">
                        <label>Status</label>
                        <SelectStyled
                          selected={status}
                          plainStyle={true}
                          defaultLabel="All"
                          data={[
                            {key: 'All', label: 'All'},
                          ].concat(campaignStatus)}
                          itemRenderer={d => {
                            return <StatusTag status={d.key}>{d.key}</StatusTag>
                          }}
                          onChange={newStatus => {
                            this.setState({campaignPage: 1, status: newStatus, data: null})
                          }}/>
                      </div>
                      <div className="menu-item">
                        <label>Campaign Type</label>
                        <SelectStyled
                          selected={type}
                          plainStyle={true}
                          defaultLabel="All"
                          data={[
                            {key: 'All', label: 'All'},
                          ].concat(campaignPriorities)}
                          onChange={type => {
                            this.setState({campaignPage: 1, type: type, data: null})
                          }}/>
                      </div>
                      {/*<div className="menu-item">*/}
                      {/*  <label>Date range</label>*/}
                      {/*  <DateSelectorDropdown*/}
                      {/*    mode='range'*/}
                      {/*    placeholder='--'*/}
                      {/*    period={period}*/}
                      {/*    futureDateAllowed={true}*/}
                      {/*    historyDateAllowed={true}*/}
                      {/*    onDateRangeUpdated={period => this.setState({campaignPage: 1, period: period, data: null})}/>*/}
                      {/*</div>*/}
                      <div className="actions">
                        <Btn type="reset" onClick={e => this.setState({campaignPage: 1, period: undefined, advertiser: null, type: null, status: null, data: null})}>Clear Filters</Btn>
                      </div>
                    </FilterDropdown>
                    <Link className="create-btn" to={`/sponsored_ads/campaigns/create`} onClick={e => {
                      if(!isAdmin) {
                        e.preventDefault();
                      }
                    }}>
                      <Btn disabled={!isAdmin} type="primary"><i className="fa fa-plus"/> Campaign</Btn>
                    </Link>
                  </CampaignController>
                  <FilterTagWrapper>
                    {
                      [
                        {key: 'status', label: <span>Campaign Status is <strong>{status}</strong></span>},
                        {key: 'type', label: <span>Campaign type is <strong>{type}</strong></span>},
                        {key: 'advertiser', label: <span>Advertiser is <strong>{advertiser}</strong></span>},
                        {key: 'period', label: period ? <span>Camapign period has overlap with <strong>{moment(period.start).format('MMM DD YYYY')}</strong> - <strong>{moment(period.end).format('MMM DD YYYY')}</strong></span> : null},
                      ].map(d => {
                        if(!this.state[d.key] || this.state[d.key] === 'All') {
                          return null;
                        }
                        return (
                          <div className="tag" key={d.key}>
                            <span>{d.label}</span>
                            <i className="fa fa-times" onClick={e => this.setState({campaignPage: 1, [d.key]: null, data: null}, () => this.cacheParams())}/>
                          </div>
                        );
                      })
                    }
                  </FilterTagWrapper>
                  <TableScrollWrapper className="middle">
                    <Table
                      columns={[
                        {
                          key: 'index',
                          label: '',
                          align: 'center',
                          sortable: true,
                          style: {maxWidth: '20px'},
                          renderer: (d, i) => (PAGE_SIZE * (campaignPage - 1) + i + 1)
                        },
                        {
                          key: 'campaign',
                          label: 'Campaign',
                          style: {maxWidth: '350px'},
                          renderer: d => {
                            return (
                              <div className="campaign-name" title={d.id}>
                                <div>
                                  <Link
                                    to={`/sponsored_ads/campaigns/edit?id=${encodeURIComponent(d.id)}`} className="name">
                                    {d.name}
                                  </Link>
                                </div>
                                <div className="creatives creative-list">
                                  {
                                    (d.creatives || []).map(t => <CreativeRenderer key={t.id} creative={t} noName={true}/>)
                                  }
                                </div>
                              </div>
                            )
                          }
                        },
                        {
                          key: 'advertiser',
                          sortable: false,
                          label: 'Advertiser',
                          align: 'left',
                          renderer: d => {
                            return d.advertiser
                          }
                        },
                        {
                          key: 'segment',
                          sortable: false,
                          label: 'Target Segment',
                          align: 'left',
                          renderer: (d, index) => {
                            if(!d.segment || d.segment === 'RON') {
                              return '--';
                            }
                            return (
                              <div className="segment">
                                <div>{d.segment.name}</div>
                                <div style={{fontSize: '11px', color: MediumGray1}}>Uniques: <strong>{intFormatter(d.segment.size)}</strong></div>
                              </div>
                            )
                          }
                        },
                        {
                          key: 'type',
                          sortable: false,
                          label: 'Priority',
                          align: 'left',
                          renderer: d => {
                            return d.type + ` (${{1: 'High', 2: 'Medium', 3: 'Low'}[d.priority]})`;
                          }
                        },
                        {
                          key: 'goal',
                          sortable: false,
                          label: 'Goal',
                          align: 'left',
                          renderer: d => {
                            if(!d.goal_value) return '--';
                            return (
                              <div>
                                <span>{intFormatter(d.goal_value)}</span> <span>{d.goal_type === 'FIXED' ? '' : '%'}</span>
                              </div>
                            )
                          }
                        },
                        {
                          key: 'impressions',
                          sortable: true,
                          label: 'Impressions',
                          align: 'center',
                          renderer: d => {
                            if(d.goal_value && Number(d.goal_value) < d.impressions) {
                              return intFormatter(d.goal_value);
                            }
                            return intFormatter(d.impressions);
                          }
                        },
                        {
                          key: 'start_date',
                          sortable: true,
                          label: 'Period',
                          align: 'left',
                          renderer: d => {
                            return periodFormat({start: d.start_date, end: d.end_date});
                          }
                        },
                        {
                          key: 'status',
                          sortable: true,
                          label: 'Status',
                          align: 'left',
                          renderer: d => {
                            return <StatusTag status={d.status}>{d.status}</StatusTag>;
                          }
                        },
                        {
                          key: 'action',
                          label: 'Actions',
                          renderer: (d, index) => {
                            return (
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                <StyledDropdown
                                  useFixed={true}
                                  fixedLeft={-50}
                                  closeOnClick={true}
                                  up={index === creatives.length - 1}
                                  toggler={<i className="fa fa-ellipsis-v"/>}>
                                  <div className={`menu-item`}>
                                    <Link to={`/sponsored_ads/campaigns/edit?id=${encodeURIComponent(d.id)}`}><i className="fa fa-edit"/>Edit</Link>
                                  </div>
                                  {
                                    d.status === 'DELIVERING' &&
                                    <div className={`menu-item ${!isAdmin ? 'disabled' : ''}`}>
                                      <a onClick={e => this.pauseCampaign(d)}><i className="fa fa-pause"/>Pause</a>
                                    </div>
                                  }
                                  {
                                    d.status === 'PAUSED' &&
                                    <div className={`menu-item ${!isAdmin ? 'disabled' : ''}`}>
                                      <a onClick={e => this.resumeCampaign(d)}><i className="fa fa-play"/>Resume</a>
                                    </div>
                                  }
                                  <div className={`menu-item`}>
                                    <Link to={`/sponsored_ads/campaign/report?id=${encodeURIComponent(d.id)}`}><i className="fa fa-bar-chart"/>Report</Link>
                                  </div>
                                  <div className={`menu-item ${!isAdmin ? 'disabled' : ''}`}>
                                    <a onClick={e => this.deleteCampaign(d)} style={{color: 'red'}}><i className="fa fa-trash"/>Delete</a>
                                  </div>
                                </StyledDropdown>
                              </div>
                            )
                          }
                        },
                      ]}
                      rows={campaigns || []}
                      noDataText={<div style={{height: '110px', marginTop: '50px'}}>No Campaigns Found</div>}
                    />
                  </TableScrollWrapper>
                  {
                    campaignCount > 1 &&
                    <ListFooter>
                      <span className="info">
                        <strong>{d3.format(',')(campaignCount)}</strong> campaigns found.
                        Show
                        <select
                          value={PAGE_SIZE}
                          onChange={e => this.setState({
                            data: null,
                            PAGE_SIZE: Number(e.target.value),
                            campaignPage: 1,
                          })}>
                        {[5, 10, 20, 50].map(d => {
                          return (
                            <option key={d} value={d}>{d}</option>
                          )
                        })}
                        </select>
                        per page
                    </span>
                      <PaginationStyled
                        onChange={p => this.setState({campaignPage: p, data: null})}
                        current={campaignPage}
                        pageSize={PAGE_SIZE}
                        total={campaignCount}
                        hideOnSinglePage={false}/>
                    </ListFooter>
                  }
                </CampaignModule>
                <CreativeModule>
                  <CreativeController>
                    <div className="searchBox">
                      <i className="fa fa-search"/>
                      <input
                        type="text"
                        placeholder="Enter creative ID, name to search ..."
                        value={creativeSearch}
                        onChange={e => this.setState({creativeSearch: e.target.value}, () => {
                          this.search();
                        })}/>
                    </div>
                    <SelectStyled
                      selected={size}
                      plainStyle={true}
                      defaultLabel="All Sizes"
                      data={[
                        {key: 'All', label: 'All Sizes'},
                      ].concat((sizes || []).map(a => ({key: a, label: a})))}
                      onChange={size => {
                        this.setState({creativePage: 1, size: size, data: null})
                      }}/>
                    <Link className="create-btn" to={`/sponsored_ads/create_creative`} onClick={e => {
                      if(!isAdmin) {
                        e.preventDefault();
                      }
                    }}>
                      <Btn disabled={!isAdmin} type="primary"><i className="fa fa-plus"/> Creative</Btn>
                    </Link>
                  </CreativeController>
                  <TableScrollWrapper className="middle">
                    <Table
                      columns={[
                        {
                          key: 'index',
                          label: '',
                          align: 'center',
                          sortable: true,
                          style: {maxWidth: '20px'},
                          renderer: (d, i) => (PAGE_SIZE * (creativePage - 1) + i + 1)
                        },
                        {
                          key: 'content',
                          label: 'Creative',
                          style: {maxWidth: '350px'},
                          renderer: d => {
                            return (
                              <a href={`/sponsored_ads/creative/settings?id=${d.id}`} className="creative-name">
                                <img src={d.thumbnail}/>
                                <div>
                                  <div>{d.size}</div>
                                  <div>{d.title}</div>
                                  <div className="url">{d.url}</div>
                                </div>
                              </a>
                            )
                          }
                        },
                        {
                          key: 'name',
                          label: 'Creative Name',
                        },
                        {
                          key: 'CreatedDateTime',
                          label: 'Created Time',
                          renderer: (d, index) => {
                            return moment(d.CreatedDateTime).format('MMM DD YYYY hh:mm A')
                          }
                        },
                        {
                          key: 'creator',
                          label: 'Creator',
                        },
                        {
                          key: 'action',
                          label: 'Actions',
                          renderer: (d, index) => {
                            return (
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                <StyledDropdown
                                  useFixed={true}
                                  fixedLeft={-50}
                                  closeOnClick={true}
                                  up={index === creatives.length - 1}
                                  toggler={<i className="fa fa-ellipsis-v"/>}>
                                  <div className={`menu-item`}>
                                    <Link to={`/sponsored_ads/creative/settings?id=${d.id}`}><i className="fa fa-edit"/>Edit</Link>
                                  </div>
                                  <div className={`menu-item ${!isAdmin ? 'disabled' : ''}`} style={{color: 'red'}}>
                                    <a onClick={e => this.deleteCreative(d)}><i className="fa fa-trash"/>Delete</a>
                                  </div>
                                </StyledDropdown>
                              </div>
                            )
                          }
                        },
                      ]}
                      rows={creatives || []}
                      noDataText={<div style={{height: '110px', marginTop: '50px'}}>No Creative Found</div>}
                    />
                  </TableScrollWrapper>
                  {
                    creativeCount > 1 &&
                    <ListFooter>
                      <span className="info">
                        <strong>{d3.format(',')(creativeCount)}</strong> creatives found.
                        Show
                        <select
                          value={PAGE_SIZE}
                          onChange={e => this.setState({
                            data: null,
                            PAGE_SIZE: Number(e.target.value),
                            creativePage: 1,
                          })}>
                        {[5, 10, 20, 50].map(d => {
                          return (
                            <option key={d} value={d}>{d}</option>
                          )
                        })}
                        </select>
                        per page
                    </span>
                    <PaginationStyled
                      onChange={p => this.setState({creativePage: p, data: null})}
                      current={creativePage}
                      pageSize={PAGE_SIZE}
                      total={creativeCount}
                      hideOnSinglePage={false}/>
                  </ListFooter>
                }
                </CreativeModule>
              </TabContainer>

            </div>
          }
        </ContentHomeWrapper>
      </MainLayout>
    )
  }

  deleteCampaign(campaign) {
    CondirmDialog.showConfirm(`Do you really want to delete this campaign ?`, () => {
      this.setState({loading: true});
      post('/api/deleteCampaign', {id: campaign.id}).then(result => {
        if (result.success) {
          this.setState({loading: false, data: null})
          this.props.history.push(`/sponsored_ads`);
        } else {
          this.setState({loading: false, error: 'failed'});
        }
      }).catch(e => {
        this.setState({loading: false, error: e.message});
      });
    });
  }

  deleteCreative(d) {
    CondirmDialog.showConfirm(`Do you really want to delete creative ${d.name || d.id}?`, () => {
      this.setState({loading: true})
      return post('/api/deleteCreative', {id: d.id}).then(results => {
        this.setState({data: null, loading: false})
      }).catch(e => {
        this.setState({loading: false, error: e.message})
        console.error(e);
      })
    })
  }

  pauseCampaign(campaign) {
    this.setState({loading: true});
    post('/api/pauseCampaign', {id: campaign.id}).then(result => {
      if (result.success) {
        this.setState({loading: false, data: null})
      } else {
        this.setState({loading: false, error: 'failed'});
      }
    }).catch(e => {
      this.setState({loading: false, error: e.message});
    });
  }

  resumeCampaign(campaign) {
    this.setState({loading: true});
    post('/api/resumeCampaign', {id: campaign.id}).then(result => {
      if (result.success) {
        this.setState({loading: false, data: null})
      } else {
        this.setState({loading: false, error: 'failed'});
      }
    }).catch(e => {
      this.setState({loading: false, error: e.message});
    });
  }
}))