import React from 'react'
import ReactDOM from 'react-dom';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import moment from 'moment';
import * as d3 from 'd3';
import {withConsumer} from '../app/ApplicationContext';
import CondirmDialog from '../uikit/dialog/index';
import {Btn, TabContainer, Table, ProgressBar, Loader} from '../uikit/index';
import {SelectStyled} from './select.style';
import {PaginationStyled, ErrorBox} from './index.style';
import {LangTitleWrapper} from './langTitle.style';
import {BlueJeans} from "../app/StyleCommon";

export default withConsumer(class extends React.Component {
  constructor(props) {
    super(props);
    const {title, headers = []} = props.layoutConfig || {};
    if (title && !headers.length) {
      headers.push({
        lang: 'en',
        title: title
      });
    }
    this.state = {headers: headers};
  }

  render() {
    const {headers = []} = this.state;
    return (
      <LangTitleWrapper>
        <p>You can add header with different language setting.</p>
        <div className="table-header">
          <span/>
          <span className="add-btn" onClick={e => {
            this.setState({headers: [...headers, {lang: 'en', title: ''}]});
          }}>
            <i className="fa fa-plus"/> Add Language
          </span>
        </div>
        <div className="table-wrapper">
          {
            <Table
              columns={[
                {
                  key: 'no', label: 'No', align: 'center',
                  renderer: (d, i) => i + 1
                },
                {
                  key: 'lang', label: 'Language', align: 'left', renderer: (d) => {
                  return (
                    <SelectStyled
                      defaultLabel={'Select...'}
                      selected={d.lang}
                      useFixed={true}
                      data={[
                        {key: 'en', label: 'English'},
                        {key: 'zh', label: '中文'},
                      ]}
                      showGroupBatch={false}
                      onChange={lang => {
                        d.lang = lang;
                        this.setState({headers});
                      }}/>
                  )
                }
                },
                {
                  key: 'titleEditor', label: 'Title', align: 'left',
                  renderer: (d) => {
                    return (
                      <input
                        className="textEditor"
                        placeholder="Title"
                        value={d.title} onChange={e => {
                        d.title = e.target.value;
                        this.setState({headers});
                      }}/>
                    )
                  }
                },
                {
                  key: 'titleUrl', label: 'Title Link', align: 'left',
                  renderer: (d) => {
                    return (
                      <input
                        className="textEditor"
                        value={d.titleLink}
                        placeholder="Optional"
                        onChange={e => {
                          d.titleLink = e.target.value;
                          this.setState({headers});
                        }}/>
                    )
                  }
                },
                // {
                //   key: 'moreTextEditor', label: 'More Link Text', align: 'left',
                //   renderer: (d) => {
                //     return (
                //       <input
                //         className="textEditor"
                //         placeholder="Optional"
                //         value={d.moreLinkText} onChange={e => {
                //         d.moreLinkText = e.target.value;
                //         this.setState({headers});
                //       }}/>
                //     )
                //   }
                // },
                // {
                //   key: 'moreLinkEditor', label: 'More Link', align: 'left',
                //   renderer: (d) => {
                //     return (
                //       <input
                //         className="textEditor"
                //         placeholder="Optional"
                //         value={d.moreLink} onChange={e => {
                //         d.moreLink = e.target.value;
                //         this.setState({headers});
                //       }}/>
                //     )
                //   }
                // },
                {
                  key: 'actions', label: '', align: 'right',
                  renderer: (d, index) => {
                    return (
                      <Btn type="reset"
                           size="mini"
                           className="delBtn"
                           onClick={e => this.setState({headers: headers.filter((x, i) => i !== index)})}>
                        Delete
                      </Btn>
                    );
                  }
                },
              ]}
              rows={headers || []}
              noDataText={"No header setting found"}
            />
          }
        </div>
        <div className="actions">
          <Btn type="reset" onClick={e => this.props.onCancel()}>Cancel</Btn>
          <Btn type="primary" onClick={e => this.props.onChange(this.state.headers)}>OK</Btn>
        </div>
      </LangTitleWrapper>
    );
  }
})
