import React from 'react'
import ReactDOM from 'react-dom';
import ReactServer from 'react-dom/server'
import _ from 'lodash';
import {Link} from 'react-router-dom';
import moment from 'moment';
import * as d3 from 'd3';
import {withConsumer} from '../app/ApplicationContext';
import createTooltip from '../uikit/tooltip/index';
import {rankingModels, layouts, itemStyles, algorithms} from '../utils/metadata';
import CondirmDialog from '../uikit/dialog/index';
import {Btn, TabContainer, Table, ProgressBar, Loader, Hint} from '../uikit/index';
import {SelectStyled} from './select.style';
import {PaginationStyled, ErrorBox} from './index.style';
import {
  SlotAllocatorWrapper,
  GridSelector,
  SlotGroupRedirect,
  SlotSelector,
  SlotItemRenderer
} from './slotAllocator.style';
import {BlueJeans, DarkGray, Grass1, LightGray1, MediumGray1} from "../app/StyleCommon";
import {colorCategory2} from '../app/StyleCommon'
import {serializeFilters} from '../utils/serializeUtil';

export default withConsumer(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      widget: _.cloneDeep(props.widget)
    };
    this.tooltip = this.createTagTooltip();
  }

  render() {
    const {appState: {session = {}}} = this.props;
    const {widget} = this.state;
    const {layoutConfig, ranking, cxenseWidget, thinkWidget, slotGroups = []} = widget || {};
    const {autoRanking, rankingAlgorithm} = ranking || {};
    const {layout, layoutParams} = layoutConfig || {};
    const {row = 1, column = 1, slotGroupMapping = {}} = layoutParams || {};
    const layoutOptions = layouts.find(d => d.key === layout);
    const {main} = layoutOptions || {};
    return (
      <SlotAllocatorWrapper>
        <div className="slot-allocator">
          <label>Create or select slot rules for each slot.
            <Hint pos="right"
                  useFixed={true} width={250} content={
              <ul style={{textAlign: 'left', color: 'white'}}>
                <li>Slot Group: The chosen algorithm and a defined set of filters</li>
                <li>Slot Position: The position of the Slot Group within the widget.</li>
              </ul>
            }/>
          </label>

          <div className="table-wrapper">
            <GridSelector className={layout}>
              <tbody>
              {main === 'left' && <tr className="main">
                <td className="main" rowSpan={row}>{this.renderSlotGroupSelector('main')}</td>
              </tr>}
              {main === 'top' && <tr className="main">
                <td className="main" colSpan={column}>{this.renderSlotGroupSelector('main')}</td>
              </tr>}
              {_.range(1, row + 1).map((rIndex) => {
                return (
                  <tr key={rIndex}>
                    {_.range(1, column + 1).map((cIndex) => {
                      return (
                        <td key={rIndex + '-' + cIndex}>
                          {this.renderSlotGroupSelector(`p_${rIndex}_${cIndex}`)}
                        </td>
                      )
                    })}
                    {
                      main === 'right' && rIndex === 1 &&
                      <td className="main" rowSpan={row}>
                        {this.renderSlotGroupSelector('main')}
                      </td>
                    }
                  </tr>
                )
              })}
              </tbody>
            </GridSelector>
            {
              !autoRanking &&
              <div className={`table-actions ${_.compact(_.values(slotGroupMapping)).length === 0 ? 'hidden' : ''}`}>
              <span
                className="table-action"
                onClick={e => {
                  this.layoutParamsChanged({...layoutParams, slotGroupMapping: {}});
                }}
                onMouseOver={this.tooltip.onMouseOver({message: 'Clear all cells allocation'})}
                onMouseLeave={this.tooltip.onMouseOut()}>
                  <i className="fa fa-times"/> Clear
                </span>
              </div>
            }
          </div>
        </div>
        {
          !!this.props.isAdmin &&
          <div className="auto-ranking">
            <label>
              <input
                disabled={cxenseWidget || thinkWidget}
                type="checkbox"
                checked={!cxenseWidget && !thinkWidget && !!autoRanking}
                onChange={e => this.rankingConfigChanged({
                  ...ranking,
                  autoRanking: e.target.checked,
                  rankingAlgorithm: (!autoRanking) ? (rankingAlgorithm || 'rnk1') : undefined
                })}/>
              Use auto ranking algorithm
            </label>
            <SelectStyled
              disabled={cxenseWidget || thinkWidget}
              defaultLabel={'Select...'}
              selected={rankingAlgorithm}
              data={rankingModels.filter(d => !!d.key)}
              showGroupBatch={false}
              onChange={rankingAlgorithm => {
                this.rankingConfigChanged({...ranking, rankingAlgorithm: rankingAlgorithm})
              }}/>
          </div>
        }
        <div className="actions">
          <Btn type="reset" onClick={e => this.props.onCancel()}>Cancel</Btn>
          <Btn type="ok" onClick={e => this.props.onConfirm(widget)}>OK</Btn>
        </div>
      </SlotAllocatorWrapper>
    );
  }

  getRouteCallback(positionKey) {
    const {widget} = this.state;
    const {layoutConfig, slotGroups = [], cxenseWidget, thinkWidget, ranking} = widget || {};
    const {autoRanking} = ranking || {};
    const {layout, layoutParams} = layoutConfig || {};
    const {row = 1, column = 1, slotGroupMapping = {}} = layoutParams || {};
    return {
      newSlotGroupCallback: slotGroup => {
        delete widget.cxenseWidget;
        delete widget.thinkWidget;
        slotGroups.push(slotGroup);
        this.layoutParamsChanged({
          ...layoutParams,
          slotGroupMapping: {
            ...slotGroupMapping,
            [positionKey]: slotGroup.name
          }
        });
      },
      updateSlotGroupCallback: (newSlotGroup, oldSlotGroup) => {
        let replaceIndex = slotGroups.findIndex(sg => sg.name === oldSlotGroup.name);
        slotGroups.splice(replaceIndex, 1, newSlotGroup);
        this.setState({widget: widget});
        if (newSlotGroup.name !== oldSlotGroup.name) {
          Object.keys(slotGroupMapping).forEach(positionKey => {
            if (slotGroupMapping[positionKey] === oldSlotGroup.name) {
              slotGroupMapping[positionKey] = newSlotGroup.name;
            }
          });
          this.layoutParamsChanged({...layoutParams, slotGroupMapping});
        }
      },
      newCxenseCallback: cxenseWidget => {
        widget.cxenseWidget = cxenseWidget;
        widget.slotGroups = [];
        delete widget.thinkWidget;
        this.layoutParamsChanged({
          ...layoutParams,
          slotGroupMapping: {}
        });
      },
      newThinkCallback: thinkWidget => {
        widget.thinkWidget = thinkWidget;
        widget.slotGroups = [];
        delete widget.cxenseWidget;
        this.layoutParamsChanged({
          ...layoutParams,
          slotGroupMapping: {}
        });
      }

    }
  }

  renderSlotGroupSelector(positionKey) {
    const {widget} = this.state;
    const {layoutConfig, slotGroups = [], cxenseWidget, thinkWidget, ranking} = widget || {};
    const {autoRanking} = ranking || {};
    const {layout, layoutParams} = layoutConfig || {};
    const {row = 1, column = 1, slotGroupMapping = {}} = layoutParams || {};
    const {newSlotGroupCallback, updateSlotGroupCallback, newCxenseCallback, newThinkCallback} = this.getRouteCallback(positionKey);
    let dataSelectable = [].concat(slotGroups);
    // if (!dataSelectable.length && !cxenseWidget && !thinkWidget) {
    //   return (
    //     <SlotGroupRedirect
    //       onClick={e => this.props.onSlotGroupRedirect({callback: newSlotGroupCallback, type: 'create', autoRanking})}>
    //       <div>Define Slot Rules.</div>
    //       <div className="click-hint">Click to create slot group.</div>
    //     </SlotGroupRedirect>
    //   )
    // }
    let selectedSlotGroupName = slotGroupMapping[positionKey];
    dataSelectable = dataSelectable.map(d => {
      let algorithm = algorithms.find(al => al.key === d.algorithm) || {};
      return {
        key: d.name, label: d.name,
        description: <span
          dangerouslySetInnerHTML={{__html: serializeFilters(d) + `, <strong>${algorithm.label}</strong>`}}/>
      }
    });

    if (!!cxenseWidget) {
      selectedSlotGroupName = `Cxense`;
      dataSelectable.push({key: 'Cxense', label: 'Cxense', description: cxenseWidget.description});
    }
    if (!!thinkWidget) {
      selectedSlotGroupName = `Think Analytics`;
      dataSelectable.push({key: 'think_analytics', label: 'Think Analytics', description: thinkWidget.description});
    }
    const CREATE_NEW_SLOT_GROUP = 'create_new_slot_group';
    const CREATE_NEW_SLOT_GROUP_ADS = 'create_new_slot_group_ads';
    const SELECT_CXENSE = 'select_cxense_widget';
    const SELECT_THINK = 'select_think_widget';
    dataSelectable = dataSelectable.concat([
      {
        type: 'action',
        key: CREATE_NEW_SLOT_GROUP,
        label: <div><i className="fa fa-plus-circle"/> Create New Slot Group...</div>
      }
    ]);
    if(this.props.isExternal) {
      dataSelectable.push({
        type: 'action',
        key: CREATE_NEW_SLOT_GROUP_ADS,
        label: <div><i className="fa fa-plus-circle"/> Create New Ads Slot Group...</div>
      })
    }
    // if(!autoRanking && !this.props.isExternal) {
    if(!autoRanking) {
      dataSelectable = dataSelectable.concat([
        {
          type: 'action',
          key: SELECT_CXENSE,
          label: <div><i className="fa fa-hand-pointer-o"/> Select Cxense Widget...</div>
        },
        {
          type: 'action',
          key: SELECT_THINK,
          label: <div><i className="fa fa-hand-pointer-o"/> Select Think Analytics Widget...</div>
        },
      ]);
    }

    const slotNameIndex = dataSelectable.findIndex(d => d.label === selectedSlotGroupName);
    const bgColor = autoRanking ? undefined : colorCategory2[slotNameIndex];
    return (
      <SlotSelector>
        <SelectStyled
          className={autoRanking ? 'autoRanking' : ''}
          useFixed={true}
          defaultLabel={'Select Slot Group'}
          toggleLableFunc={autoRanking ? () => {
            let dynamicStyle = undefined;
            if(widget.slotGroups && widget.slotGroups.length > 0) {
              dynamicStyle = {backgroundImage: `linear-gradient(90deg, ${(widget.slotGroups || []).map((n, slotIndex)=>colorCategory2[slotIndex]).join(',')})`}
            }
            return (
              <div className="autoRankingRipple" style={dynamicStyle} key={Date.now()}>
                Dynamic Assignment
              </div>
            )
          } : undefined}
          selected={(autoRanking || slotNameIndex < 0) ? undefined : selectedSlotGroupName}
          data={dataSelectable}
          showGroupBatch={false}
          togglerTheme={bgColor}
          // disabled={autoRanking}
          itemRenderer={d => {
            return (
              <SlotItemRenderer type={d.type}>
                <div className="name">
                  {d.label}
                  {
                    d.type !== 'action' && d.key !== 'Cxense' && d.key !== 'think_analytics' &&
                    <div className="slot-group-actions">
                        <span
                          title="Edit Slot Group"
                          className="action-item"
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.onSlotGroupRedirect({
                              callback: updateSlotGroupCallback,
                              slotGroup: slotGroups.find(sg => sg.name === d.key),
                              type: 'update',
                              autoRanking
                            });
                          }}>
                          <i className="fa fa-edit"/>
                        </span>

                      <span
                        title="Delete Slot Group"
                        className="action-item"
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          widget.slotGroups = widget.slotGroups.filter(sg => sg.name !== d.key);
                          this.setState({widget: widget});
                        }}>
                          <i className="fa fa-trash"/>
                        </span>
                    </div>
                  }
                </div>
                {!!d.description && <div className="desc">{d.description}</div>}
              </SlotItemRenderer>
            )
          }}
          onChange={slotGroupName => {
            if (slotGroupName === CREATE_NEW_SLOT_GROUP) {
              this.props.onSlotGroupRedirect({callback: newSlotGroupCallback, type: 'create', autoRanking});
            }
            else if (slotGroupName === CREATE_NEW_SLOT_GROUP_ADS) {
              this.props.onSlotGroupRedirect({callback: newSlotGroupCallback, type: 'create', autoRanking, category: 'ads'});
            }
            else if (slotGroupName === SELECT_CXENSE) {
              this.props.onCxenseRedirect({callback: newCxenseCallback});
            }
            else if (slotGroupName === SELECT_THINK) {
              this.props.onThinkAnalyticsRedirect({callback: newThinkCallback});
            } else if(!autoRanking) {
              this.layoutParamsChanged({
                ...layoutParams,
                slotGroupMapping: {
                  ...slotGroupMapping,
                  [positionKey]: slotGroupName
                }
              });
            }

          }}/>
        {
          !!selectedSlotGroupName && !cxenseWidget && !thinkWidget && !autoRanking &&
          <div className={`actions ${selectedSlotGroupName ? 'active': ''}`}>
            {
              row > 1 && column > 1 &&
              <span
                className="cell-action"
                onClick={e => this.applySlotTo('all', positionKey, selectedSlotGroupName)}
                onMouseOver={this.tooltip.onMouseOver({message: 'Apply to all slot cells'})}
                onMouseLeave={this.tooltip.onMouseOut()}>
              <i className="fa fa-files-o"/>
            </span>
            }
            {
              positionKey !== 'main' && column > 1 &&
              <span
                className="cell-action"
                onClick={e => this.applySlotTo('row', positionKey, selectedSlotGroupName)}
                onMouseOver={this.tooltip.onMouseOver({message: 'Apply to all cells in current row'})}
                onMouseLeave={this.tooltip.onMouseOut()}>
                <i className="fa fa-arrows-h"/>
              </span>
            }
            {
              positionKey !== 'main' && row > 1 &&
              <span
                className="cell-action"
                onClick={e => this.applySlotTo('column', positionKey, selectedSlotGroupName)}
                onMouseOver={this.tooltip.onMouseOver({message: 'Apply to all cells in current column'})}
                onMouseLeave={this.tooltip.onMouseOut()}>
              <i className="fa fa-arrows-v"/>
            </span>
            }
          </div>
        }
      </SlotSelector>
    )
  }

  applySlotTo(type, positionKey, slotGroupName) {
    const {widget} = this.state;
    const {layoutConfig, ranking, cxenseWidget, thinkWidget} = widget || {};
    const {layout, layoutParams} = layoutConfig || {};
    const {row = 1, column = 1, slotGroupMapping = {}} = layoutParams || {};
    const layoutOptions = layouts.find(d => d.key === layout);
    const {main} = layoutOptions || {};

    const currentRow = Number(positionKey.split('_')[1]);
    const currentColumn = Number(positionKey.split('_')[2]);
    for (var i = 1; i <= row; i++) {
      for (var j = 1; j <= column; j++) {
        if (type === 'all' || (type === 'row' && currentRow === i) || (type === 'column' && currentColumn === j)) {
          slotGroupMapping[`p_${i}_${j}`] = slotGroupName;
        }
      }
    }
    if (type === 'all' && !!main) {
      slotGroupMapping[`main`] = slotGroupName;
    }
    this.layoutParamsChanged({...layoutParams, slotGroupMapping: slotGroupMapping});
  }


  layoutParamsChanged(layoutConfigParam) {
    const {widget} = this.state;
    const {layoutConfig} = widget || {};
    const newWidget = {
      ...widget,
      layoutConfig: {
        ...layoutConfig,
        layoutParams: layoutConfigParam
      }
    };
    this.setState({widget: newWidget});
  }

  rankingConfigChanged(ranking) {
    const {widget} = this.state;
    const newWidget = {
      ...widget,
      ranking: ranking,
    };
    this.setState({widget: newWidget});
  }

  createTagTooltip() {
    return createTooltip({
      targetSelector: 'dynamic',
      tipCreator: d => {
        return ReactServer.renderToString(
          <div style={{minWidth: '200px'}}>
            <div>{d.message}</div>
          </div>
        )
      },
      directionFunc: 'bottom'
    });
  }
})
