export const tooltipStyles = `
  .ripple-tip {
    color: #434a54;
    font-size: 14px;
    position: absolute;
    right: auto;
    bottom: auto;
    display: none;
    font-weight: bold;
    transition: all 0.2s ease;
    max-width: 750px;
    z-index: 100;
    filter: drop-shadow(0 1px 8px rgba(0, 0, 0, .2));
    border: 1px solid #E6E9ED;
    border-radius: 5px;
    font-style: normal;
    background-color: white;
  }

  .ripple-tip.show {
    display: block;
  }


  @media (max-width: 667px) {
    .ripple-tip.fit-for-mobile {
      transform: none !important;
      left: 5px !important;
      right: 5px !important;
      min-width: auto !important;
    }
    .ripple-tip.fit-for-mobile .tip-arrow{
      display: none !important;
    }
  }

  .ripple-tip.rightstart {
    transform: translate(-10px, -0);
  }
  .ripple-tip.lefttop {
    transform: translate(-100%, -100%) translate(20px, -8px);
  }

  .ripple-tip.left {
    transform: translate(-100%, -50%) translate(-8px);
  }

  .ripple-tip.top {
    transform: translate(-50%, -100%) translate(0, -8px);
  }

  .ripple-tip.right {
    transform: translate(0, -50%) translate(8px);
  }

  .ripple-tip.bottom {
    transform: translate(-50%, 0) translate(0, 8px);
  }

  .ripple-tip .tip-inner {
    z-index: 1;
    background: white;
    z-index: 1;
    border-radius: 5px;
    overflow: hidden;
    /*box-shadow: 0 1px 10px rgba(0,0,0,.1), 0 2px 15px rgba(0,0,0,.05);*/
  }

  .ripple-tip .tip-arrow {
    position: absolute;
    transform-origin: 50% 50%;
    fill: white;
    stroke: #f1f1f1;
    z-index: -1;
  }

  .ripple-tip.rightstart .tip-arrow {
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
  }
  .ripple-tip.lefttop .tip-arrow {
    right: 0;
    bottom: 0;
    transform: translate(-10px, 50%);
  }

  .ripple-tip.left .tip-arrow {
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
  }

  .ripple-tip.bottom .tip-arrow {
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
  }

  .ripple-tip.right .tip-arrow {
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .ripple-tip.top .tip-arrow {
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%);
  }

  .ripple-tip.bottom:not(.no-title) .tip-arrow {
    fill: #6EB3DE;
  }

  .ripple-tip.default {
    display: block;
  }

  .ripple-tip .tip-header {
    background: #6EB3DE;
    padding: 10px 15px;
    color: white;
  }

  .ripple-tip .tip-body {
    background: white;
    padding: 10px 15px;
    color: #666;
    font-size: 14px;
    font-weight: normal;
  }

  .ripple-tip .tip-content {
    padding-left: 70px;
    min-height: 40px;
    position: relative;
  }
  .ripple-tip .tip-content.left-align {
    text-align: left;
  }

  .ripple-tip .tip-content img {
    width: 60px;
    height: 30px;
    position: absolute;
    left: 5px;
    top: 5px;
  }

  .ripple-tip .tip-content i {
    margin-left: 5px;
    vertical-align: middle;
  }

  .ripple-tip .tip-content a {
    color: black;
    font-weight: normal;
    cursor: pointer;
  }
`