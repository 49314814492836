import React from 'react';
import _ from 'lodash';
import {withConsumer} from '../app/ApplicationContext';
import {withRouter} from 'react-router';
import moment from 'moment';
import * as d3 from 'd3';
import {Redirect, Link} from 'react-router-dom';
import {ProgressBar, Btn, Table} from '../uikit/index';
import MainLayout from '../layout/index';
import {SelectStyled} from './select.style';
import {ABTestingHistoryWrapper, RevisionList, NoData, DetailView} from './history.style';
import {StyledDropdown} from '../uikit/dropdown/styled';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {post} from "../utils/request";
import {format} from "../utils/jsonpatch";
import queryString from "qs";

export default withRouter(withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    const urlParams = queryString.parse(this.props.location.search.slice(1));
    this.state = {detailMap: {[urlParams.time]: true}};

  }

  componentDidMount() {
    var script = document.createElement('script');
    script.onload = () => {
      this.setState({jsondiffpatchReady: true})
    };
    script.src = '/scripts/jsondiffpatch.umd.min.js';
    document.head.appendChild(script); //or something of the likes
  }

  render() {
    const {data, error, loading, detailMap, jsondiffpatchReady} = this.state;
    let {history = [], abtesting} = data || {};
    const abtestingId = this.props.computedMatch.params.abtestingId;
    if (data && abtesting) {
      history = [{
        userId: abtesting.creator,
        CreatedDateTime: abtesting.CreatedDateTime,
        action: 'Created',
        abtesting: abtesting
      },
        ...history
      ];
    }
    history = _.sortBy(history, _.property('CreatedDateTime'));
    history.forEach((d, i) => {
      const jsondiffpatch = window.jsondiffpatch
      const ignoreFields = ['UpdatedDateTime'];
      let right = _.omit(d.abtesting || {}, ignoreFields);
      let currentIndex = history.findIndex(h => h.CreatedDateTime === d.CreatedDateTime);
      let left = _.omit((history[currentIndex - 1] || {}).abtesting, ignoreFields);
      let delta = jsondiffpatch.diff(left, right);
      d.deltaHtml = jsondiffpatch.formatters.html.format(delta, left);

      let patch = format(delta, left);

      let jsonpatchGrouped = _.groupBy(patch, _.property('op'));
      d.briefHtml = Object.keys(jsonpatchGrouped).map(k => {
        return `<span class="${k}">${jsonpatchGrouped[k].length + ' ' + k}</span>`
      }).join(', ');
    })
    history = history.filter(d => !!d.deltaHtml);
    const historyGroupByDay = _.groupBy(history, d => moment(d.CreatedDateTime).format('YYYY-MM-DD'));
    return (
      <MainLayout activeItem="abtesting"
                  breadcrumb={[{path: '/abtesting', label: 'A/B Testing'}, {path: '#', label: 'A/B Testing History'}]}>
        <ABTestingHistoryWrapper>
          {
            !data && !error &&
            <ProgressBar
              withoutCache={true}
              fixcenter={true}
              url={`/api/queryABTestingHistory`}
              params={{id: abtestingId}}
              successHandler={data => this.setState({data: data})}
              errorHandler={e => this.setState({error: e.message})}/>
          }
          {!!data && <p>Revision History for <strong>{abtesting.name}</strong></p>}
          {
            !!data && !!history.length &&
            <RevisionList>
              {Object.keys(historyGroupByDay).sort().reverse().map(date => {
                let items = historyGroupByDay[date];
                items = _.sortBy(items, _.property('CreatedDateTime')).reverse();
                return (
                  <ul key={date}>
                    {items.map((d, i) => {
                      const showDetails = detailMap[d.CreatedDateTime];
                      return (
                        <li key={d.CreatedDateTime} className={i === 0 ? 'day-break' : ''}>
                          {
                            i === 0 &&
                            <div className="time day-break-time">
                              <div><i className="icon fa fa-calendar"/></div>
                              <div>{moment(d.CreatedDateTime).format('MMM-DD, YYYY')}</div>
                              <div className="time1">{moment(d.CreatedDateTime).format('HH:mm A')}</div>
                            </div>
                          }
                          {
                            i !== 0 &&
                            <div className="time successive">
                              <div><i className="fa fa-clock-o"/> {moment(d.CreatedDateTime).format('HH:mm A')}</div>
                            </div>
                          }
                          <div className="info">
                            <div className="basic" onClick={e => this.setState({
                              detailMap: {
                                ...detailMap,
                                [d.CreatedDateTime]: !showDetails
                              }
                            })}>
                              <span>
                                {d.action || 'Modified'} by <strong>{d.userId}</strong>.
                                &nbsp;
                                <span className="brief" dangerouslySetInnerHTML={{__html: d.briefHtml}}/>
                              </span>
                              <Btn type="link" size="small">
                                <i className={`fa fa-angle-double-${showDetails ? 'up' : 'down'}`}/>
                              </Btn>
                            </div>
                            {
                              !!showDetails && <DetailView dangerouslySetInnerHTML={{__html: d.deltaHtml}}/>
                            }
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                )
              })}
            </RevisionList>
          }
          {
            !!data && !history.length &&
            <NoData>
              No Revision History Found.
            </NoData>
          }
        </ABTestingHistoryWrapper>
      </MainLayout>
    )
  }
}))