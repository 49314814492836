import React from 'react';
import classnames from 'classnames';
import ReactDOM from 'react-dom';
import {withRouter} from 'react-router';
import _ from 'lodash';
import moment from 'moment';
import {withConsumer} from '../app/ApplicationContext';
import MainLayout from '../layout/index';
import {algorithms, layouts, sites} from '../utils/metadata';
import {algorithms as adAlgorithms} from '../utils/metadata.ads';
import {post} from '../utils/request';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {ProgressBar, Table, TableScrollWrapper, FilterBox, Btn, CondirmDialog, Loader} from '../uikit/index';
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import {PageWrapper, ControlBar, Copiable, CreateWrapper, PlacementInfo} from './builder-placement.style';
import {EmptyList, TagList} from './builder-placement.style';
import {SelectStyled} from './select.style';
import {PaginationStyled} from '../uikit/Pagination/index.style';
import {BlueJeans, MediumGray1} from "../app/StyleCommon";
import copy from 'copy-to-clipboard';
import {filterTags, intFormatter, periodFormat, uniquesFormatter} from "../utils/formatter";
import CreativeRenderer from './creativeRenderer';
import VariantSelector from "../abtesting/variant-selector";
import {SegmentInfo} from "./builder-segment.style";
import {Link} from "react-router-dom";
import {Taglist} from "../widgets/index.style";
import RealtimeChart from "../widgets/realtimeChart";
import {StyledDropdown} from "../uikit/dropdown/styled";

//https://support.liveintent.com/hc/en-us/articles/360001247043-How-to-Implement-LiveTags-

export default class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {campaign, errorMap = {}, readOnly} = this.props;
    const {loading, data, error} = this.state;
    const {placement} = campaign;
    const {type, widgets, sites} = placement || {};
    return (
      <PageWrapper>
        {!!error && <ErrorMessage message={error}/>}
        {!!errorMap.placement && <ErrorMessage message={errorMap.placement}/>}
        {
          !!placement &&
          <PlacementInfo>
            <h5>Target Ad slots:</h5>
            <div className="segment-actions">
              <Btn disabled={readOnly} className="delete-btn" type="link" onClick={e => this.props.onChange({placement: null})}>Change</Btn>
            </div>
            {
              type === 'RON' &&
              <>
                <div className="attr">
                  <span><strong className="strong">Run of Network</strong> </span>
                </div>
                <div className="note">
                  <span>The campaign will target all Ad slots across your network.</span>
                </div>
              </>
            }
            {
              type!== 'RON' && !!widgets && widgets.length &&
              <TagList>
                <div className="tag-list-header">
                  <p><strong>{widgets.length}</strong> Widget selected:</p>
                  <div className="action-suggestions">
                    <Btn disabled={readOnly} type="link" onClick={e => this.showWidgetSelector()}><i
                      className="fa fa-plus"/> Add More</Btn>
                  </div>
                </div>
                <Table
                  columns={[
                    {
                      key: 'content',
                      label: 'Widget Name',
                      style: {maxWidth: '350px'},
                      renderer: d => {
                        return (
                          <div className="widget-info">
                            <div className="top" >
                              <img className="publisher" src={`/img/property/${d.site}.png`} style={{width: '16px', verticalAlign: 'middle', marginRight: '5px'}}></img>
                              <Link className="title" to={`/widget/${d.id}`}>
                                <span style={{marginRight: '6px'}}>{d.name}</span>
                              </Link>
                              <em className="status">{d.status === 'Published' ? '' : `(${d.status})`}</em>
                            </div>
                            <div>
                              <Taglist className="tag-list"  style={{marginLeft: '15px'}}>
                                {filterTags(d.tags, 'abtesting').map(t => {
                                  return <span className={`tag ${t.split('::').join(' ')} `} key={t}>{t.split('::').pop()}</span>
                                })}
                                {!filterTags(d.tags, 'abtesting').length && '--'}
                                {this.renderWidgetAlgorithm(d)}
                              </Taglist>
                            </div>
                          </div>
                        )
                      }
                    },
                    {
                      key: 'layout',
                      label: 'Layout',
                      renderer: d => {
                        const {layout, layoutParams} = d.layoutConfig || {};
                        const {row = 1, column = 1} = layoutParams || {};
                        const layoutOption = layouts.find(l => l.key === layout) || {};
                        return (
                          <div className="widget-layout" onClick={e => this.showPreview(d)}>
                            <div className="layout-icon"
                                 style={{backgroundImage: `url(${d.thumbnail || layoutOption.icon || '/img/icons/placement.png'})`}}>
                            </div>
                            <span
                              className="layout-desc">
                              {layoutOption.label || ' -- '}
                              &nbsp;
                              {layoutOption.key ? `(${row}x${column})` : ''}
                            </span>
                          </div>
                        )
                      }
                    },
                    {
                      key: 'created',
                      label: 'Created Time',
                      renderer: d => {
                        return (
                          <div>
                            <div>{moment(d.CreatedDateTime).format('DD MMM, YYYY HH:mm A')}</div>
                            <div style={{fontSize: '11px', color: MediumGray1}}>{d.creator}</div>
                          </div>
                        )
                      }
                    },
                    {
                      key: 'created',
                      label: 'Last Updated Time',
                      renderer: d => {
                        return (
                          <div>
                            <div>{moment(d.UpdatedDateTime).format('DD MMM, YYYY HH:mm A')}</div>
                            <div style={{fontSize: '11px', color: MediumGray1}}>by {d.lastModifyBy}</div>
                          </div>
                        )
                      }
                    },
                    {
                      key: 'action',
                      label: 'Actions',
                      renderer: (d, index) => {
                        return (
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <Btn disabled={readOnly} type="reset" size="mini" onClick={e => this.deselectWidget(d)}>
                              <i className="fa fa-trash"/> Remove
                            </Btn>
                            {/*<Btn disabled={readOnly} type="primary" size="mini" onClick={e => this.previewWidget(d)}>*/}
                            {/*  <i className="fa fa-eye"/> Preview*/}
                            {/*</Btn>*/}
                          </div>
                        )
                      }
                    },
                  ]}
                  rows={widgets || []}
                  noDataText={<div style={{height: '110px', marginTop: '50px'}}>No Widgets Found</div>}
                />
              </TagList>
            }
          </PlacementInfo>
        }
        {
          (!placement) && this.renderEmptylist()
        }
      </PageWrapper>
    )
  }

  renderWidgetAlgorithm(d) {
    const allAlgorithms = [...algorithms, ...adAlgorithms];
    if(d.cxenseWidget) {
      return <span className="tag algo">Cxense</span>;
    }
    else if(d.thinkWidget) {
      return <span className="tag algo">Think Analytics</span>;
    } else {
      const {layoutParams = {}} = d.layoutConfig || {};
      let usedSlots = _.uniq(Object.values(layoutParams.slotGroupMapping || {}));
      let usedSlotGroup = (d.slotGroups || []).filter(s => usedSlots.indexOf(s.name) >= 0);
      let algos = _.uniq(usedSlotGroup.map(s => s.algorithm));
      let backfill = _.uniq(_.compact(usedSlotGroup.map(s => (s.backfill || {}).algorithm)))[0];
      if(algos.length > 1) {
        return <span className="tag algo">Hybrid</span>
      } else if (algos.length === 1) {
        let algo = algos[0];
        let meta = allAlgorithms.find(d => d.key === algo);
        let backfillmeta = allAlgorithms.find(d => d.key === backfill);
        return (
          <>
            <span className="tag algo" key={algo}>{meta ? meta.label : algo}</span>
            {!!backfill && <span className="tag backfill" key={backfill}>Fallback: <strong>{backfillmeta ? backfillmeta.label : backfill}</strong></span>}
          </>
        )
      }
    }
  }

  renderEmptylist() {
    const {campaign} = this.props;
    return (
      <EmptyList>
        <div>
          <img src="/img/ads/builder/placement.png"/>
          <p>You can select an widget with ads slots or choose to display the ads in all ads slots across your network:</p>
          <div>
            <Btn type="reset" onClick={e => this.selectPlacement(null, 'RON')}>Run of Network</Btn>
            {/*<Btn type="primary" onClick={e => this.showWidgetSelector()}>Select Sites</Btn>*/}
            <Btn type="primary" onClick={e => this.showWidgetSelector()}>Select Widget</Btn>
          </div>
        </div>
      </EmptyList>
    )
  }

  showWidgetSelector() {
    const {campaign} = this.props;
    const confirmInfo = {
      type: 'form',
      backgroundClose: false,
      title: <strong>Select Widgets</strong>,
      width: '850px',
      hideCancel: true,
      hideOK: true,
      onCancel: () => CondirmDialog.closeAll(),
      dialogBody: <VariantSelector
        site={null}
        withFullInfo={true}
        category="ads"
        variants={campaign.widgets}
        onAdd={widget => {
          this.selectPlacement(widget, 'widget');
          CondirmDialog.closeAll();
        }}
        onCancel={() => {
          CondirmDialog.closeAll();
        }}
      />,
    }
    ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById('global-confirm'));
  }

  previewWidget(widget) {

  }

  selectPlacement(d, type) {
    const {campaign = {}} = this.props;
    const placement = campaign.placement || [];

    if(type === 'RON') {
      this.props.onChange({
        placement: {type: 'RON'}
      });
    } else if(type === 'widget'){
      this.props.onChange({
        placement: {
          ...placement,
          type: 'NONRON',
          widgets: [...(placement.widgets || []), d]
        }
      });
    }
  }

  deselectWidget(widget) {
    const {campaign = {}} = this.props;
    const placement = campaign.placement || [];
    const newWidgets = (placement.widgets ||[]).filter(d => d.id !== widget.id);
    if(!newWidgets.length) {
      this.props.onChange({
        placement: null
      });
    } else {
      this.props.onChange({
        placement: {type: 'NONRON', widgets: newWidgets}
      });
    }
  }

}