import React from 'react';
import _ from 'lodash';
import DatePicker from 'react-datepicker';

import {withConsumer} from '../app/ApplicationContext';
import {DeploymentWrapper, CronBuilder} from './step-deploy.style';
import DeploymentCode from './deployment-code';

export default withConsumer(class extends React.Component {

  constructor(props) {
    super(props);

    const {abtesting} = this.props;
    const {schedule} = abtesting || {};

    if (!schedule) {
      abtesting['schedule'] = {
        start: '',
        end: ''
      };
    }

    this.state = {
      abtesting: abtesting
    };
  }

  render() {
    const {abtesting} = this.state;
    const {schedule, edm = {}, servingDisabled} = abtesting || {};
    const {start = '', end = ''} = schedule || {};
    const {enabled: edmEnabled, cronExpr = '0 0 0 * * *'} = edm || {};
    return (
      <DeploymentWrapper className="step-deployment">
        {/*<div>*/}
        {/*  <h2 className="row-title">Schedule your A/B test</h2>*/}
        {/*  <div className="row">*/}
        {/*    <div className="col col-1-1">*/}
        {/*      <label className="time-start">Start Time</label>*/}
        {/*      <div className="time-selector">*/}
        {/*        <label className="time-check">*/}
        {/*          <input*/}
        {/*            ref="start"*/}
        {/*            type="checkbox"*/}
        {/*            checked={!start}*/}
        {/*            onChange={e => this.handleInputChange('start',*/}
        {/*              this.refs.start.checked ? '' : new Date(), abtesting)}*/}
        {/*          />*/}
        {/*          <span>Start Immediately</span>*/}
        {/*        </label>*/}
        {/*        <DatePicker*/}
        {/*          className="date-picker"*/}
        {/*          placeholderText="Select start time"*/}
        {/*          selected={start ? new Date(start) : ''}*/}
        {/*          disabled={(this.refs.start || {}).checked}*/}
        {/*          onChange={e => this.handleInputChange('start', e, abtesting)}*/}
        {/*          dateFormat="Pp"*/}
        {/*          showTimeSelect*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div className="col col-1-1">*/}
        {/*      <label className="time-end">End Time</label>*/}
        {/*      <div className="time-selector">*/}
        {/*        <label className="time-check">*/}
        {/*          <input*/}
        {/*            ref="end"*/}
        {/*            type="checkbox"*/}
        {/*            checked={!end}*/}
        {/*            onChange={e => this.handleInputChange('end', this.refs.end.checked ? '' : new Date(), abtesting)}*/}
        {/*          />*/}
        {/*          <span>No End Time</span>*/}
        {/*        </label>*/}
        {/*        <DatePicker*/}
        {/*          className="date-picker"*/}
        {/*          placeholderText="Select end time"*/}
        {/*          disabled={(this.refs.end || {}).checked}*/}
        {/*          selected={end ? new Date(end) : ''}*/}
        {/*          onChange={e => this.handleInputChange('end', e, abtesting)}*/}
        {/*          dateFormat="Pp"*/}
        {/*          showTimeSelect*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*  <h2 className="row-title">Serving control</h2>*/}
        {/*  <div className="row">*/}
        {/*    <label className="time-check edm-check">*/}
        {/*      <input*/}
        {/*        type="checkbox" checked={servingDisabled}*/}
        {/*        onInput={e => {*/}
        {/*          this.saveAbTesting({*/}
        {/*            ...abtesting,*/}
        {/*            servingDisabled: !servingDisabled*/}
        {/*          });*/}
        {/*        }}*/}
        {/*      />*/}
        {/*      <span>Disable this a/b testing. </span>*/}
        {/*    </label>*/}
        {/*  </div>*/}
          {/*{*/}
            {/*!!edmEnabled &&*/}
            {/*<CronBuilder>*/}
              {/*<div className="cron-input">*/}
                {/*<label>Cron Expression:</label>*/}
                {/*<input*/}
                  {/*type="text"*/}
                  {/*className="form-control"*/}
                  {/*value={cronExpr}*/}
                  {/*placeholder="Minute, Hour, Day (month), Month, Day (week)"*/}
                  {/*onChange={(e) => {*/}
                    {/*this.saveAbTesting({*/}
                      {/*...abtesting, edm: {*/}
                        {/*...(edm || {}),*/}
                        {/*cronExpr: e.target.value*/}
                      {/*}*/}
                    {/*});*/}
                  {/*}}/>*/}
              {/*</div>*/}
              {/*<ReCron*/}
                {/*className="cron-builder"*/}
                {/*value={cronExpr}*/}
                {/*onChange={(cronValue) => {*/}
                  {/*this.saveAbTesting({*/}
                    {/*...abtesting, edm: {*/}
                      {/*...(edm || {}),*/}
                      {/*cronExpr: cronValue*/}
                    {/*}*/}
                  {/*});*/}
                {/*}}>*/}
              {/*</ReCron>*/}
            {/*</CronBuilder>*/}
          {/*}*/}

        {/*</div>*/}
        <DeploymentCode abtesting={abtesting}></DeploymentCode>
      </DeploymentWrapper>
    )
  }

  saveAbTesting(abtesting) {
    this.setState({abtesting: abtesting});
    this.props.onChange(abtesting);
  }

  handleInputChange(type, value, abtesting) {
    const {schedule} = abtesting || {};
    const scheduledData = {...schedule, [type]: value};
    this.saveAbTesting({...abtesting, schedule: scheduledData});
  }
})
