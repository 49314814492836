import React from 'react';
import styled, {css} from 'styled-components';
import {
  BitterSweet,
  BitterSweet1,
  BlueJeans, BlueJeans1, Dark, DarkGray, DarkGray1, Grass, Grass1, LightBlue, LightGray, LightGray1, MediumGray,
  MediumGray1, Sunflower1
} from "../app/StyleCommon";

export const SlotGroupWrapper = styled.div`
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
  margin-top: -20px;
  min-height: 500px;
  .actions {
    text-align: right;
  }
  .rc-pagination li{
    min-width: 24px;
    height: 24px;
    line-height: 24px;
  }
  > .tab-container-wrapper {
    .tab-menu-wrapper {
      padding-left: 0;
    }
  }
`
export const ErrorBox = styled.div`
  margin: 10px 0;
  color: ${BitterSweet1};
`
export const LabelContainer = styled.div`
  padding-bottom: 5px;
  > label,
 .col >label {
    display: block;
    font-weight: bold;
    color: ${DarkGray};
    margin: 8px 0;
  }
  > input,
 .col >input {
    width: 100%;
    font-size: 14px;
    padding: 5px 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid ${MediumGray};
    outline: none;
    //background: ${LightGray};
    &:focus {
      border: 1px solid ${BlueJeans};
    }
    &[disabled] {
      opacity: .5;
    }
  }
`;

export const BasicSettings = styled(LabelContainer)`
  display: flex;
  margin-top: 10px;
  @media screen and (max-width: 667px) {
    display: block;
    .col {
      width: 100% !important;
    }
  }
  .col {
    margin-right: 25px;
    &.col-1-3 {
      width: 33%;
    }
    &.col-1-4 {
      width: 22%;
    }
    &.col-1-5 {
      width: 20%;
    }
    &.col-2-4 {
      width: 48%;
    }

    input[type=text] {
      width: 100%;
      font-size: 14px;
      padding: 6px 10px;
      box-sizing: border-box;
      border-radius: 5px;
      border: 1px solid ${MediumGray};
      outline: none;
      //background: ${LightGray};
      &:focus {
        border: 1px solid ${BlueJeans};
      }
    }
    .select-box {
      width: 100%;
      .toggler {
        width: 100%;
        padding: 5px 10px;
        border: 1px solid ${MediumGray}
        background: white;
        padding-right: 30px;
        min-height: 30px;
      }
      .menu {
        top: 30px;
        left: 0;
        right: 0;
        .segment-item {
          padding-bottom: 5px;
          &:not(:last-child) {
            border-bottom: 1px solid ${LightGray1};
          }
          .name {
          }
          .desc {
            color: ${DarkGray};
            font-size: 11px;
            a {
              margin-left: 5px;
              color: ${MediumGray1};
            }
          }
        }
      }
    }
  }
`

export const SplitView = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 667px) {
    display: block;
  }
  .right-side {
    border-left: 1px solid ${LightGray1}
    padding-left: 15px;
    min-height: 400px;
    max-width: 320px;
    .tab-menu-wrapper {
      padding: 0;
    }
    .time_selection {
      .dropdown {
        display: inline-block;
        margin-left: 10px;
        .menu {
          left: -100px;
          .menu-item {
            padding: 8px 10px;
          }
        }
      }
    }
  }
`
export const FilterView = styled(LabelContainer)`
  flex-grow: 1;
  margin-right: 30px;
  .select-box {
    .menu {
      max-height: 300px;
      overflow: auto;
    }
  }
  .data-filter {
    .search-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
      @media screen and (max-width: 667px) {
        flex-wrap: wrap;
        .select-box {
          margin: 10px 0;
          width: 100%;
          .toggler {
            width: 100%;
          }
        }
      }
      >label {
        margin-right: 20px;
      }
      input[type=text] {
        flex: 1;
        width: 40px;
        height: 22px;
        margin-right: 10px;
        font-size: 14px;
        padding: 5px 5px 5px 10px;
        border-radius: 5px;
        border: 1px solid ${MediumGray1};
        outline: none;
      }
    }
    .regex-editor {
      display: flex;
      align-items: center;
      min-height: 300px;
      justify-content: center;
      .regex-editor-rule {
        width: 68%;
        display: flex;
        align-items: center;
        input {
          flex-grow: 1;
          margin: 5px 10px;
          font-size: 14px;
          padding: 5px 5px 5px 10px;
          border-radius: 0;
          border: none;
          border-bottom: 1px solid ${MediumGray1};
        }
        a {
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          &.disabled {
            pointer-events: none;
            color: ${MediumGray1};
          }
          img {
            width: 16px;
            height: 16px;
            margin-right: 5px;
          }
        }

      }
    }
    .filter-items {
      margin-bottom: 10px;
      min-height: 300px;
      background: #fafbfc;
      border: 1px solid ${LightGray1};
      table {
        //thead {display: none}
        //min-height: 300px;
        border: none;
      }
      .facet-actions {
        white-space: nowrap;
        &.disabled {
          opacity: .5;
          pointer-events: none !important;
        }
        a {
          cursor: pointer;
          margin-left: 10px;
          &:hover {
            color: ${BlueJeans};
          }
        }
        img {
          width: 12px;
          height: 12px;
          margin: 0 5px;
          vertical-align: middle;
        }
      }
      .bar-wrapper {
        position: relative;
        .bar {
          background: ${BlueJeans};
          height: 10px;
          border-radius: 2px;
          min-width: 1px;
        }
        //.num {
        //  position: absolute;
        //  right: 10px;
        //  top: 50%;
        //  transform: translateY(-50%);
        //}
      }
    }
  }
`
export const FilterTabWrapper = styled.div`
  > .tab-container-wrapper {
    display: flex;
    align-items: flex-start;
    &.hideMenu > .tab-menu-wrapper{
      display: none;
    }
    .tab-items-wrapper {
      flex-grow: 1;
    }
    > .tab-menu-wrapper {
      display: block;
      margin: 0px 30px 0 0;
      padding: 0;
      &:after {
        display: none;
      }
      >li {
        display: block;
        position: relative;
        padding: 5px 30px 5px 5px;
        margin: 15px 0 15px 0;
        border-radius: 3px;
        &:hover button {
          opacity: 1;
          color: ${BitterSweet1};
        }
        button {
          position: absolute;
          right: -25px;
          opacity: 0;
          transition: opacity .3s ease;
        }
        &.selected {
          border: none;
          background: ${BlueJeans1};
          color: white;
        }
        &:not(:last-child):after {
          position: absolute;
          content: 'OR';
          display: block;
          bottom: -16px;
          left: 20px;
          font-size: 10px;
          color: ${MediumGray};
        }
      }
    }
  }

`;

export const FilterTagList = styled.div`
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &:hover .filter-control-group {
      opacity: 1;
    }
    .filter-control-group {
      margin-left: 10px;
      opacity: 0;
      transition: opacity .3s ease;
      white-space: nowrap;
      button {
        cursor: pointer;
        padding: 3px 5px;
      }
     }
    .select-box {
      .toggler {
        background: none;
        border: none;
        width: auto;
      }
      .menu {
        width: auto;
        right: auto;
        left: auto;
        min-width: 150px;
      }
     }
    >ul {
      background: #f1f2f3;
      width: 100%;
      padding-left: 5px;
      border-radius: 5px;
      li {
        display: inline-flex;
        align-items: center;
        margin: 5px 0;
        margin-right: 10px;
        margin-right: 20px;
        flex-wrap: wrap;
        position: relative;
        &.tag-group-wrapper {
          background: #cfe3ed;
          padding: 2px 5px;
          border-radius: 5px;
        }
        &:not(:last-child):after {
          content: 'and';
          display: inline-block;
          font-size: 10px;
          color: ${BlueJeans1};
          font-weight: bold;
          position: absolute;
          right: -15px;
        }
        .tag-group {
          display: flex;
          position: relative;
          flex-wrap: wrap;
          &.include.multiple {
            background: #aeca93;
            border-radius: 5px;
            .tag {
              background: rgba(255,255,255,.8);
            }
          }

          &.exclude .tag:not(:last-child):after {
            content: 'and' !important;
          }
          &:not(:last-child) {
            margin-right: 10px;
          }
          &:not(:last-child):after {
            position: absolute;
            top: 5px;
            right: -18px;
            content: 'and';
            display: inline-block;
            font-size: 10px;
            color: ${DarkGray};
            font-weight: bold;
          }
        }
        .tag {
          margin: 0 10px 0 10px;
          justify-content: space-between;
          border: 1px solid ${LightGray};
          background: ${LightGray};
          padding: 1px 5px;
          border-radius: 4px;
          position: relative;
          font-size: 12px;
          display: flex;
          align-items: center;
          &:not(:last-child):after {
            position: absolute;
            top: 3px;
            right: -18px;
            content: 'or';
            display: inline-block;
            font-size: 10px;
            color: ${DarkGray};
            font-weight: bold;
          }
          > span {
            display: inline-block;
            vertical-align: middle;
            white-space: nowrap;
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &.exclude {
            background: ${BitterSweet};
            color: white;
            &.exclude:before {
              content: 'exclude';
              color: #2196f3;
              margin-right: 5px;
            }
            //text-decoration: line-through;
          }
          &.include {
            border: 1px solid ${MediumGray};
          }
          &:not(:first-child) {
            content: ' - AND - ';
          }
        }

        i {
          margin-right: 5px;
        }
        i.fa-times {
          color: ${Sunflower1};
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
`
export const ItemGrid = styled.div`

  ul {
    max-height: 260px;
    overflow-y: auto;
    margin: 10px 0 20px 0;
  }
  .content-item {
    width: 100%;
    margin-right: 10px;
    display: flex;
    .title {
      line-clamp: 3;
      a {
        text-decoration: none;
      }
    }
    .time {
      font-size: 11px;
      color: #535353;
      margin-left: 20px;
    }
    img {
      width: 64px;
      height: auto;
      float: left;
      margin: 5px;
    }
  }
`
export const StatView = styled(LabelContainer)`
  padding: 5px 10px;
  >label {
    padding: 10px 10px;
  }
  svg {
    .x.axis {
      path {
        stroke: ${DarkGray1};
      }
      text {
        transform: translate(25px, 0);
      }
    }
    .y.axis {
      path {
        stroke: none;
      }
      .tick {
        line {
          stroke: ${MediumGray};
        }
        text {
          color: ${DarkGray};
        }
      }
    }
  }
`

export const ImprCapWrapper = styled.div`
  display: flex;
  align-items: center;
  //border-bottom: 1px solid ${LightGray1};
  padding: 15px 0 20px 0;
  ~ p {
    margin-bottom: 360px;
    margin-top: 0;
  }
  >label {
    font-weight: bold;
    margin-right: 30px;
    display: inline-block;
  }
`

export const ImprCap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: no-wrap;
  @media screen and (max-width: 667px) {
    display: block !important;
    .cap-metric-selector {
      display: block !important;
      > label {
        font-weight: bold;
        margin: 10px 0 10px 0;
        display: block;
      }
    }
    .blockInMobile {
      display: block;
      width: 100% !important;
      margin: 5px 10px;
    }
  }
  .cap-metric-selector {
    display: flex;
    align-items: center;
    margin-right: 10px;
    > span {
      display: inline-block;
      margin-right: 10px;
    }
    .or {
      font-weight: 300;
      color: ${DarkGray};
    }
    input {
      width: 45px;
      height: 24px;
      font-size: 14px;
      margin-right: 10px;
      margin-left: 5px;
      padding: 0px 5px;
      border-radius: 4px;
      border: 1px solid ${MediumGray};
      background: ${LightGray};
      text-align: center;
      outline: none;
    }
  }
  .cap-unit-selector {
    display: flex;
    align-items: center;
    ul {
      display: flex;
      align-items: center;
      border: 1px solid ${MediumGray};
      border-radius: 4px;
      font-size: 12px;
      margin-left: 5px;
      li {
        padding: 5px 8px;
        cursor: pointer;
        white-space: nowrap;
        font-size: 12px;
        &.selected {
          font-weight: bold;
          background-color: ${LightGray1};
        }
        &:first-child {
          border-radius: 5px 0 0  5px;
        }
        &:last-child {
          border-radius: 0 5px 5px 0;
        }
        &:not(:last-child) {
          border-right: 1px solid ${MediumGray};
        }
      }

    }
  }
`

export const MaxAge = styled(LabelContainer)`
  && {
    .max-age-settings {
      margin-top: 20px;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      &.disabled {
        pointer-events: none;
        opacity: .5;
      }
      ul {
        display: flex;
        align-items: center;
        border: 1px solid ${MediumGray};
        border-radius: 5px;
        font-size: 12px;
        li {
          padding: 5px 10px;
          cursor: pointer;
          white-space: nowrap;
          &.selected {
            background: ${BlueJeans1};
            color: white;
          }
          &:not(:last-child) {
            border-right: 1px solid ${MediumGray};
          }
        }

      }
      > span {
        display: inline-block;
        margin-right: 10px;
      }
      > input {
        width: 40px;
        height: 25px;
        margin-right: 10px;
        padding: 1px 5px;
        border-radius: 5px;
        border: 1px solid ${MediumGray1};
        text-align: center;
        outline: none;
      }
    }
  }
`