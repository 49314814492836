import React from 'react';
import styled, {css} from 'styled-components';
import {
  BitterSweet1,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass1, LightGray, LightGray1, MediumGray,
  MediumGray1, LightBlue
} from "../../app/StyleCommon";

import {WidgetWrapper} from './grid.style';

const RED = '#e85142';
export const iPrice_DFP_300x250_default = styled(WidgetWrapper)`
  &&& {
    font-family: sans-serif;
    width: 300px;
    height: 250px;
    overflow: hidden;
    box-sizing: border-box;
    .title:hover, .cta-button:hover {
      text-decoration: underline;
    }
    .coupon {
      position: relative;
      width: 300px;
      height: 250px;
      border-radius: 10px;
      background-repeat: no-repeat;
      .thumbnail {
        height: 120px;
      }
      .offerText {
        background: ${RED};
        border-radius: 10px 0 0 0;
        color: white;
        color: rgba(255,255,255,.8);
        font-weight: bold;
        padding: 10px 5px;
        display: inline-block;
        font-size: 12px;
      }
      .category {
        position: absolute;
        right: 0;
        bottom: 90px;
        border-radius: 0 0 0 0;
        background: ${RED};
        color: white;
        padding: 5px 10px;
        font-weight: bold;
        font-size: 12px;
        word-break: break-all;
      }
      .details {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: auto;
        border-radius: 0 0 10px 10px;
        background: rgba(0,0,0,.55);
        color: white;
        padding: 5px;
        height: 90px;
        box-sizing: border-box;
      }
      .title {
        font-weight: bold;
        font-size: 12px;
        max-height: 55px;
        overflow: hidden;
        line-height: 1.5em;
        .store {
          width: 48px;
          vertical-align: middle;
          float: left;
          margin: 5px 10px 5px 0;
          background: rgba(255,255,255,.9);
        }
      }
      .label-tag {
        display: none;
      }
      .iprice-cpr {
        position: absolute;
        bottom: 2px;
        right: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 10px;
        color: ${MediumGray};
        font-weight: bold;
        img {
          height: 18px;
          display: inline;
          margin-left: 5px;
          background: rgba(255,255,255,.8);
        }
      }
      .cta-button {
        position: absolute;
        bottom: 15px;
        color: ${RED};
        font-weight: bold;
        font-size: 14px;
      }
      .expire {
        display: none;
      }
      .publish_date {
        display: none;
      }
      .store {
        font-size: 10px;
        color: ${MediumGray1};
        font-weight: bold;
      }
    }
  }
`