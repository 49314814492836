import React from 'react';
import styled, {css} from 'styled-components';
import {
  Aqua,
  BitterSweet,
  BitterSweet1,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass, Grass1, LightBlue, LightGray, LightGray1, MediumGray,
  MediumGray1, Mint, Sunflower1
} from "../app/StyleCommon";


export const PageWrapper = styled.div`
`;
export const CreativeList = styled.div`
  margin: 10px 0 20px 0;
  .creative-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    button {
      text-decoration: none !important;
    }
  }
  .creative-list-body {
    display: flex;
    flex-wrap: wrap;
    .selected-creative {
      background-color: white;
      width: 100%;
      margin: 10px;
      margin-bottom: 10px;
      padding: 10px;
      border: 1px solid ${MediumGray};
      border-radius: 5px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
      padding-right: 20px;
      //background-color: #fafbfc;
      @media screen and (max-width: 667px) {
        padding: 10px;
        display: block;
        .img-container {
          width: auto !important;
          position: static;
        }
        .attr {
          display: block !important;
          label {
            display: block !important;
            font-weight: bold !important;
          }
          a {
            margin-left: 5px;

          }
        }
      }
      .img-container {
        width: 40%;
        width: calc(32% - 20px);
        min-width: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f1f2f3;
        margin: 5px;
        border-radius: 5px;
        margin-right: 15px;
        min-height: 150px;
        padding: 5px 10px;
        font-size: 12px;
        flex: none;
        img {
          margin: 10px auto;
          height: auto;
          display: block;
          max-width: 100%;
        }
      }
      .attrs {
        flex-grow: 1;
        margin-top: 10px;
        .actions {
          text-align: right;
          margin-top: 20px;
        }
        .attr {
          display: flex;
          align-items: center;
          margin: 6px 0;
          > label {
            display: inline-block;
            width: 120px;
            font-size: 12px;
            font-weight: bold;
            white-space: nowrap;
            flex: none;
            margin-right: 10px;
          }
          > span {
            display: inline-block;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 2px 5px;
            white-space: nowrap;
          }
          > input {
            width: 100%;
            border: none;
            padding: 5px 5px;
            background-color: transparent;
            &:focus {
              background-color: white;
            }
          }
          .select-box {
            .toggler {
              background: transparent;
              font-size: 12px;
              white-space: normal;
            }
          }
          &.bottom-align {
            align-items: flex-end;
            > label {
              border-bottom: 3px solid transparent;
            }
          }
        }
        .size {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
        }
        .name {
          //font-weight: bold;
          margin: 10px 0;
        }
      }
      }
  }
`;

export const EmptyList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-right: 30px;
  margin-top: 50px;
  img {
    width: 100px;
  }
  >div{
    display: inline-block;
    text-align: center;
    button {
      margin: 10px;
    }
  }
`;

export const SelectorWrapper = styled.div`
  position: relative;
  min-height: 350px;
  .actions {
    text-align: right;
    .selectBtn {

    }
  }
  .table-wrapper {
    max-height: 500px;
    max-height: 70vh;
    overflow: auto;
    .creative {
      padding: 5px;
      img {
        //max-height: 50px;
        //max-width: 350px;
      }
    }
  }
  .tab-container-wrapper {
    //display: flex;
    //align-items: flex-start;
    // .tab-menu-wrapper {
    //   width: 180px;
    //   flex: none;
    //   padding: 10px 0;
    //   &:after {display: none}
    //   li {
    //     border: none;
    //     padding: 3px 10px;
    //     margin: 5px 0;
    //     img {
    //       width: 14px;
    //       height: 14px;
    //       margin-right: 5px;
    //     }
    //   }
    //   li.selected {
    //     background-color: ${BlueJeans};
    //     color: white;
    //     position: relative;
    //     border-radius: 5px 0 0 5px;
    //     &:after {
    //       content: '';
    //       display: block;
    //       width: 0;
    //       height: 0;
    //       border: 12px solid transparent;
    //       border-left: 15px solid #4FC1E9;
    //       border-right-width: 0px;
    //       right: -15px;
    //       top: 0px;
    //       position: absolute;
    //     }
    //   }
    // }
    .tab-items-wrapper {
      width: 100%;
    }
  }
`

export const ControlBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  position: relative;
  .searchBox {
    flex-grow: 1;
    i {
      position: absolute;
      left: 10px;
      top: 10px;
    }
    input {
      padding: 8px 10px 8px 30px;
      font-size: 12px;
      border-radius: 5px;
      border: 1px solid ${MediumGray};
      background: ${LightGray};
      width: 100%;
      box-sizing: border-box;
      outline: none;
      &:focus {
        border-color: ${BlueJeans};
        box-shadow: inset 0 0 3px rgba(0,0,0,.1);
      }
    }
  }
  .select-box {
    width: 180px;
    flex: none;
    margin-left: 20px;
  }
  @media screen and (max-width: 667px) {
    flex-wrap: wrap;
    .searchBox {
      width: 100%;
      margin-bottom: 5px;
    }
    .select-box {
      margin: 5px 0;
      width: 100%;
      .toggler {
        width: 100%;
      }
    }
  }
`;

export const UploadWrapper = styled.div`
  margin: 10px 20px;
  .field-grid {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .field {
      width: 50%;
      &:first-child {
        margin-right: 50px;
      }
    }
    @media screen and (max-width: 667px) {
      flex-wrap: wrap;
      .field {
        width: 100%;
        margin-right: 0 !important;
      }
    }
  }
  .field {
    margin-bottom: 20px;
    &.error > input,
    &.error .select-box .toggler{
      border-bottom: 1px dashed ${BitterSweet1}
    }
    &.error .dropzone-wrapper {
      border: 1px dashed ${BitterSweet};
    }
    > input {
      width: 100%;
      border: none;
      border-bottom: 1px solid ${MediumGray};
      padding: 5px 10px;
      margin: 10px 0;
    }
    .select-box {
      margin: 10px 0;
    }
    .error-msg {
      color: ${BitterSweet};
      display: block;
      width: 100%;
      font-size: 12px;
      margin-top: -5px;
    }
  }
`;
export const DropzoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  >div {
    overflow: hidden;
    cursor: pointer;
    height: 120px !important;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #818181;
    background-color: rgba(0,0,0,.01);
    border-color: rgb(229, 222, 222) !important;
    position: relative;
    &:hover {
      background-color: ${LightGray1};
    }
    p {
      max-width: 280px;
      text-align: center;
    }
    .droped-file {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      word-break: break-all;
      i {
        margin-right: 10px;
      }
      img {
        display: inline-block;
        vertical-align: middle;
        margin-right: 25px;
      }
    }
    .uploadIcon {
      margin-right: 10px;
    }
  }
`;

