import styled, {css} from 'styled-components';
import {
  MediumGray,
  DarkGray,
  DarkGray1,
  MediumGray1,
  Sunflower,
  BlueJeans,
  Grass,
  LightGray1
} from '../app/StyleCommon';

export const TimeseriesWrapper = styled.div`
  @media (max-width: 667px) {
    display: block
    .svgContainer, .legend {
      width: 100% !important;
    }
  }
  .wrapper-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .legend {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .tag.algo {
      background: #fafbfc;
      border-radius: 5px;
      padding: 0 3px;
      margin: 0 3px;
      font-size: 10px;
      font-weight: bold;
      border: 1px solid ${LightGray1};
    }
    &.nowrap {
      flex-wrap: nowrap;
      margin-left: 30px;
      @media screen and (max-width: 667px) {
        flex-wrap: wrap;
        margin-left: 0;
        .legend-item {
          width: 100%;
        }
      }
    }
    @media (max-width: 667px) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
    .legend-item {
      margin: 5px 10px
      font-size: 12px;
      white-space: nowrap;
      color: ${DarkGray};
      @media screen and (max-width: 667px) {
        width: 100%;
        .toggler {
          padding-left: 0px;
          font-size: 11px;
        }
      }
      &.disabled {
        color: ${MediumGray} !important;
        .icon {
          background: ${MediumGray1} !important;
        }
      }
      .rect, .rect1, .line{
        width: 12px;
        height: 12px;
        display: inline-block;
        vertical-align: middle;
        &.icon {
          cursor: pointer;
        }
        &:hover {
          transform: scale(1.1);
        }
        &.green {
          background: ${Grass};
        }
        &.gray {
          background: ${MediumGray1};
        }
        &.yellow {
          background: ${Sunflower};
        }
        &.blue {
          background: ${BlueJeans};
        }
      }
      .rect1 {
        width: 6px;
        height: 12px;
      }
      .line {
        width: 20px;
        height: 2px;
        border-top: 5px solid white;
        border-bottom: 5px solid white;
        background-color: gray;
        position: relative;
        margin-right: 5px;
        &.blue:before {
          background: ${BlueJeans};
        }
        &.green:before {
          background: ${Grass};
        }
        //&:before {
        //  content: '';
        //  position: absolute;
        //  width: 5px;
        //  height: 5px;
        //  border: 1px solid white;
        //  border-radius: 50%;
        //  left: 50%;
        //  top: 50%;
        //  transform: translate(-50%, -50%);
        //}
      }
      > span.rect:first-child {
        display: inline-block;
        vertical-align: middle;
        width: 14px;
        height: 14px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        border-radius: 2px;
      }
      >span.rect:last-child {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .svgContainer {
    width: 100%;
    min-height: 200px;
    // width: calc(100% - 100px);
  }
  svg {
    .y.axis {
      text {
        color: ${DarkGray} !important;
      }
      path {
        fill: none;
        stroke: none;
      }
      line {
        stroke: #f1f1f1;
        stroke-dasharray: 5, 8;
      }
      .tick:nth-child(:even) {
        opacity: 0;
      }
      .tick:nth-last-child(2) {
        line {
          stroke: ${MediumGray1};
          stroke-dasharray: none;
        }
        text {
          opacity: 0;
        }
      }
    }
    .x.axis {
      color: ${DarkGray1};
      path {
        opacity: 0;
      }
      .tick {
        line {
          opacity: 0.2;
        }
        &:last-child line{
          opacity: 1;
        }
      }
    }
    .circle, .verticalLine {
      transition: all .1s linear;
    }
  }
`;
export const TooltipContainer = styled.div`
  max-height: 350px;
  max-width: 700px;
  overflow: hidden;
  .time {
    margin-bottom: 10px;
  }
  .table-tip {
    max-height: 300px;
    overflow-y: scroll;
  }
  table {
    display: block;
    border: none;
    td {
      white-space: nowrap;
    }
  }
  .tag.algo {
    background: #ffce54ab;
    border-radius: 5px;
    padding: 0 3px;
    margin: 0 3px;
    font-size: 10px;
    border: 1px solid ${LightGray1};
  }
  .rect{
    width: 10px;
    height: 10px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    &.green {
      background: ${Grass};
    }
    &.gray {
      background: ${MediumGray1};
    }
    &.yellow {
      background: ${Sunflower};
    }
    &.blue {
      background: ${BlueJeans};
    }
  }

`;