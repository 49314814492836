import React, {PureComponent} from 'react';
import {SplashWrapper} from './index.style';

export default class extends PureComponent {
  render() {
    return (
      <SplashWrapper>
        {/*<Spinner />*/}
      </SplashWrapper>
    );
  }
}