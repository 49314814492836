import styled, {keyframes, css} from 'styled-components'

export const SplashWrapper = styled.div`
  width: 100%;
  width: 100vw;
  height: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 80px;
    height: 80px;
  }
`;

const rotation = keyframes`
  from {
    transform: rotate(0deg); 
  }
  to {
    transform: rotate(359deg); 
  }
`;

export const Spinner = styled.div`
  height: 36px;
  width: 36px;
  display: inline-block;
  position: relative;
  animation: ${rotation} .8s infinite linear;
  border-left: 6px solid lightgrey;
  border-right: 6px solid lightgrey;
  border-bottom: 6px solid lightgrey;
  border-top: 6px solid purple;
  border-radius: 100%;
  
  ${props => props.size === 'S' && css`
    height: 12px;
    width: 12px;
    border-width: 3px;
  `}
`;

