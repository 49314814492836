import React from 'react';
import _ from 'lodash';
import * as d3 from 'd3';
import ReactFauxDOM from 'react-faux-dom';
import createTooltip from '../uikit/tooltip/index';
import {PietWrapper} from './pie.style';
import {Transition} from '../uikit/index';
import {Aqua, BitterSweet, BlueJeans, Grass, Levander, Sunflower} from "../app/StyleCommon";

const colors = [BlueJeans, Grass, Sunflower, BitterSweet, Aqua, Levander];

const DONUT_EXTERNAL_LABEL_LIMIT = 0.09;

export default class extends React.Component {

  render() {
    const {data, radius} = this.props;
    let container = ReactFauxDOM.createElement('div');
    renderDonut(container, data, 60)
    return (
      <PietWrapper>
        {container.toReact()}
      </PietWrapper>
    )
  }
}

function renderDonut(container, data, radius) {
  // data = data.sort((a, b) => a.ratio - b.ratio);
  if(!data || !data.length) {
    return null;
  }
  d3.select(container).select('*').remove();
  const svgWidth = radius * 2.2;
  const svgHeight = radius * 2;
  const showExternalLabel = !data[0].hideLabel && data[0].ratio < DONUT_EXTERNAL_LABEL_LIMIT;
  const marginRight = showExternalLabel ? 30 : 5;
  const margin = {top: 5, left: 5, bottom: 5, right: marginRight};
  const width = svgWidth - margin.left - margin.right;
  const height = svgHeight - margin.top - margin.bottom;
  radius = radius || d3.min([width, height]) / 2;

  const svg = d3.select(container).append('svg')
    .attr('class', 'donut-svg')
    .attr('width', svgWidth)
    .attr('height', svgHeight);

  var pie = d3.pie().value(d => d.ratio).sort(null);

  var arc = d3.arc().outerRadius(radius * 0.9).innerRadius(0);
  var shadowArc = d3.arc().outerRadius(radius * 1).innerRadius(radius * 0.2);
  var arcLabel = d3.arc().outerRadius(radius * 0.6).innerRadius(radius * 0.6);
  var arcTip = d3.arc().outerRadius(radius * 1.0).innerRadius(radius * 1);
  var edgeArc = d3.arc().outerRadius(radius * 0.9).innerRadius(radius * 0.9);
  var outerArc = d3.arc().outerRadius(radius * 1).innerRadius(radius * 1);

  var arcGroup = svg.selectAll('.arc-group')
    .data(pie(data))
    .enter()
    .append('g')
    .attr('transform', 'translate(' + (width / 2 + margin.left) + ',' + (height / 2 + margin.top) + ')');

  arcGroup.append('path')
    .attr('class', 'arc-shadow')
    .attr('fill', (d, i) => colors[i])
    .attr('fill-opacity', '0')
    .attr('d', shadowArc)

  arcGroup.append('path')
    .attr('class', 'arc')
    .attr('fill', (d, i) => colors[i])
    .attr('stroke', 'white')
    .attr('stroke-width', '1px')
    .attr('d', arc)
    .attr('title', d => (d.data.ratio * 100).toFixed(0) + '%')
    .append('title')
    .text(d => {
      return (d.data.ratio * 100).toFixed(0) + '%';
    });

  arcGroup.append('text')
    .attr('fill', (d, i) => 'white')
    .attr('dy', '.50em')
    .style('text-anchor', 'middle')
    .style('font-weight', 'bold')
    .style('font-size', '11px')
    .attr('transform', (d) => {
      if(d.data.ratio === 1) {
        return 'translate(0, 0)';
      }
      return 'translate(' + arcLabel.centroid(d) + ')'
    })
    .text(d => {
      if (d.data.ratio < 0.1) {
        return '';
      }
      return d.data.ratio >= DONUT_EXTERNAL_LABEL_LIMIT ? ((d.data.ratio * 100).toFixed(0) + '%') : '';
    })

  function midAngle(d) {
    return d.startAngle + (d.endAngle - d.startAngle) / 2;
  }

  return container;
}