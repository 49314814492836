export const PureWhite = '#fff';
export const Dark = '#000';

export const GrapeFruit = '#ED5565';
export const GrapeFruit1 = '#DA4453';

export const BitterSweet = '#FC6E51';
export const BitterSweet1 = '#E9573F';

export const Sunflower = '#FFCE54';
export const Sunflower1 = '#F6BB42';

export const Grass0 = '#d8f3ba';
export const Grass = '#A0D468';
export const Grass1 = '#8CC152';

export const Mint = '#48CFAD';
export const Mint1 = '#37BC9B';

export const Aqua = '#4FC1E9';
export const Aqua1 = '#3BAFDA';

export const BlueJeans0 = '#e0eeff';
export const BlueJeans = '#5D9CEC';
export const BlueJeans1 = '#4A89DC';
export const LightBlue = '#e2efff';
export const DarkBlue = '#404854';

export const PrimaryDarkBlue = '#0A5685';
export const PrimaryMediumBlue = '#587080';
export const SecondaryMediumBlue= '#63C3FF';
export const PrimaryLightBlue = '#8DB4CC';

export const Levander = '#AC92EC';
export const Levander1 = '#867ADC';

export const PinkRose = '#EC87C0';
export const PinkRose1 = '#D770AD';

export const LightGray = '#F5F7FA';
export const LightGray1 = '#E6E9ED';
export const LightGray2 = '#f7f7f7';

export const MediumGray = '#CCD1D9';
export const MediumGray1 = '#AAB2BD';

export const DarkGray = '#656D78';
export const DarkGray1 = '#434A54';

export const SilverGray = '#f6f8fa';
export const SilverDarkGray = '#2a2a36';

export const MenuBlue = "#2196F3";

export const RedSands = '#94020c';

export const FaceBook = '#325a9d';
export const Google = '#e33330';

export const colorCategory1 = [
  BlueJeans, Grass, Levander, BitterSweet, Sunflower, GrapeFruit, Mint, Aqua, PinkRose, MenuBlue, '#ff9800', '#8ed4f6', '#c14c21', '#87f48b'
];

export const colorCategory2 = [
  BlueJeans, Grass, Sunflower, BitterSweet, GrapeFruit, MediumGray, PinkRose, Mint
];
export const colorCategory3 = [
  Grass, Sunflower, BitterSweet, GrapeFruit, Aqua, PinkRose, MediumGray
];


/*
* global z-indexs
* */
export const LoadingZIndex = 15;
export const SideMenuZIndex = 10;
export const HeaderZIndex = 9;
export const BodyMaskZIndex = 9;
export const ElementMaskZIndex = 5;
export const DialogZIndex = 7;
export const DialogMaskZIndex = 11;
export const DropdownZIndex = 6;

/**
 * global font size
 */
export const FontSizeSuperMini = '10px';
export const FontSizeMini = '11px';
export const FontSizeSmall = '12px';
export const FontSizeMedium = '14px';
export const FontSizeBig = '16px';
export const FontSizeBig1 = '18px';
export const FontSizeExtraBig = '22px';
export const FontSizeExtraBig2 = '26px';
export const FontSizeExtraBig3 = '32px';



