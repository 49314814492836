import React from 'react'
import ReactDOM from 'react-dom';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import moment from 'moment';
import * as d3 from 'd3';
import {withConsumer} from '../app/ApplicationContext';
import CondirmDialog from '../uikit/dialog/index';
import copy from 'copy-to-clipboard';
import {duration} from '../utils/formatter';
import {Btn, TabContainer, Table, TableScrollWrapper, ProgressBar, Loader, Hint} from '../uikit/index';
import {SelectStyled} from './select.style';
import {PaginationStyled} from './index.style';
import {resourceTypes, algorithms, rankingModels, facetMeta, dayparts} from '../utils/metadata';
import {SlotGroupWrapper, ErrorBox, BasicSettings, ImprCapWrapper, ImprCap} from './slotCollection.style';
import {
  MaxAge,
  SplitView,
  FilterView,
  StatView,
  ItemGrid,
  FilterTabWrapper,
  FilterTagList
} from './slotCollection.style';
import {BlueJeans, DarkGray, Grass, LightGray, MediumGray, MediumGray1, Sunflower1} from "../app/StyleCommon";
import {ReRenderHook} from "../utils/hooks";
import {post} from "../utils/request";
import BuzRuleEditor from './buzRuleEditor';
import createTooltip from "../uikit/tooltip";
import ReactServer from "react-dom/server";
import {TooltipWrapper} from "./timeseries.style";
import {metrics} from "./common";
import Dropdown from "../uikit/dropdown/index";

const FACET_PAGE_SIZE = 8;
const COLLECTION_PAGE_SIZE = 20;
export default withConsumer(class extends React.Component {
  constructor(props) {
    super(props);
    this.updateFacets = _.debounce(this._updateFacets.bind(this), 300);
    const {resourceType, deploySite} = props.slotGroup || {};
    const supportedFacets = this.findSupportFacets(resourceType, deploySite);
    this.state = {
      slotGroup: props.slotGroup || {},
      facet: supportedFacets.length ? supportedFacets[0].key : 'tags/keywords',
      unionActiveIndex: 0,
      activeTabIndex: 0,
      regex_filter_cache: {}
    }
    this.union_filter_counter = (this.state.slotGroup.extraFilters || []).length + 1;
  }

  render() {
    const {deploySite} = this.props;
    const {slotGroup, data, error, loading, facet, keywords, facetPage = 1, collectionPage = 1} = this.state;
    const {unionActiveIndex, activeTabIndex, regex_filter_cache} = this.state;
    const {facets = {}, collection = {}, distribution = {}, segments} = data || {};
    const {
      name, type, segment, daypart, kv, algorithm, rankingAlgorithm, mabCandidates,
      site, resourceType, impressionCap,
      maxAge = 7, maxAgeUnit = 'day', freshnessKey = 'publish_date',
      filters = [], extraFilters = [], extraAgeSettings = [],
    } = slotGroup || {};
    const {impressions = 3, clicks = 1, unit: impressionCapPeriod = '24hours'} = impressionCap || {};
    const unionFilters = [filters, ...extraFilters];
    const unionAgeSettings = [{maxAge, maxAgeUnit}, ...extraAgeSettings];
    const {readOnly, appState: {session = {}} = {}} = this.props;
    slotGroup.ruleMap = slotGroup.ruleMap || {};
    let enabledRuleKeys = Object.keys(slotGroup.ruleMap).filter(k => slotGroup.ruleMap[k] && slotGroup.ruleMap[k].enabled);
    let enabledRules = _.flatten(enabledRuleKeys.map(k => slotGroup.ruleMap[k].instances));
    let supportedFacets = this.findSupportFacets(resourceType, deploySite);
    return (
      <SlotGroupWrapper>
        {
          !data && !error &&
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/querySlotPage`}
            params={{
              site: site,
              filters: filters,
              extraFilters: extraFilters,
              extraAgeSettings: extraAgeSettings,
              keywords,
              facet,
              facetLimit: FACET_PAGE_SIZE,
              facetOffset: (facetPage - 1) * FACET_PAGE_SIZE,
              collectionLimit: COLLECTION_PAGE_SIZE,
              collectionOffset: (collectionPage - 1) * COLLECTION_PAGE_SIZE,
              maxAge,
              maxAgeUnit,
              resourceType,
              freshnessKey,
              type
            }}
            successHandler={data => {
              this.setState({data: data, loading: false, error: false});
            }}
            errorHandler={e => this.setState({error: e.message})}/>
        }
        {
          !!loading && <Loader transparent={true} type="absolute"/>
        }
        {
          !!error && <ErrorBox>{error}</ErrorBox>
        }
        <BasicSettings>
          {
            ['custom_targeting'].indexOf(type) < 0 &&
            <div className="col col-1-3">
              <label>Name</label>
              <input
                type="text"
                placeholder={'Slot Group Name'} value={name}
                disabled={readOnly}
                onChange={e => this.slotGroupChanged({...slotGroup, name: e.target.value})}/>
            </div>
          }
          {
            type === 'custom_targeting' &&
            <div className="col col-1-4">
              <label>Segment</label>
              <SelectStyled
                className="segment-option-list"
                defaultLabel={'Select Segment'}
                selected={(segment || {}).id}
                data={(segments || []).map(s => ({key: s.id, label: s.name, ...s}))}
                disabled={readOnly}
                showGroupBatch={false}
                searchable={true}
                unSelectable={true}
                itemRenderer={d => {
                  return (
                    <div className="segment-item" key={d.id}>
                      <div className="name">{d.name}</div>
                      <div className="desc">
                        ID: {d.id}, Size: {d.size}
                        <a target="_blank"
                           rel="noopener noreferrer"
                           href={`https://meid.mediacorp.sg/segments?id=${d.id}`}><i
                          className="fa fa-external-link"/></a>
                      </div>
                    </div>
                  )
                }}
                onChange={segID => this.slotGroupChanged({...slotGroup, segment: segments.find(s => s.id === segID)})}/>
            </div>
          }
          {
            type === 'custom_targeting' &&
            <div className="col col-1-4">
              <label>Daypart</label>
              <SelectStyled
                className="segment-option-list"
                defaultLabel={'Select Daypart'}
                selected={(daypart || {}).key}
                data={dayparts}
                disabled={readOnly}
                showGroupBatch={false}
                searchable={true}
                unSelectable={true}
                itemRenderer={d => {
                  return (
                    <div className="segment-item" key={d.key}>
                      <div className="name">{d.label}</div>
                      <div className="desc">{d.description}</div>
                    </div>
                  )
                }}
                onChange={key => this.slotGroupChanged({...slotGroup, daypart: dayparts.find(s => s.key === key)})}/>
            </div>
          }
          {
            type === 'custom_targeting' &&
            <div className="col col-1-4">
              <label>Key-Value</label>
              <input
                type="text"
                placeholder={'Key Value Params'} value={kv}
                disabled={readOnly}
                onChange={e => this.slotGroupChanged({...slotGroup, kv: e.target.value})}/>
            </div>
          }

          <div className="col col-1-3">
            <label>Recommendation model</label>
            <SelectStyled
              defaultLabel={'Select Model'}
              selected={algorithm}
              data={algorithms}
              disabled={readOnly}
              searchable={true}
              showGroupBatch={false}
              extraStyle={`.menu {max-height: 450px; overflow: auto}`}
              itemRenderer={d => {
                return (
                  <div key={d.key}>
                    <div>
                      <div className="name">{d.label}</div>
                      <div className="description" style={{color: '#656d789c', fontSize: '11px'}}>{d.description}</div>
                    </div>
                  </div>
                )
              }}
              onChange={algorithm => this.slotGroupChanged({...slotGroup, algorithm: algorithm})}/>
          </div>
          {
            algorithm === 'mab' &&
            <div className="col col-1-3">
              <label>MAB candidates</label>
              <SelectStyled
                defaultLabel={'Select MAB candidates'}
                selected={mabCandidates === undefined ? undefined : mabCandidates}
                multi={true}
                searchable={true}
                data={algorithms}
                disabled={readOnly}
                showGroupBatch={true}
                extraStyle={`.menu {max-height: 450px; overflow: auto}`}
                itemRenderer={d => {
                  return (
                    <div key={d.key}>
                      <div>
                        <div className="name">{d.label}</div>
                        <div className="description" style={{color: '#656d789c', fontSize: '11px'}}>{d.description}</div>
                      </div>
                    </div>
                  )
                }}
                onChange={algos => this.slotGroupChanged({...slotGroup, mabCandidates: algos})}/>
            </div>
          }
          {
            type !== 'custom_targeting' && !!this.props.isAdmin && !this.props.isExternal && algorithm !== 'mab' &&
            <div className="col col-1-3">
              <label>Ranking Algorithm</label>
              <SelectStyled
                disabled={!!this.props.parentSlotGroup || this.props.rankingAlgorithmDisabled}
                defaultLabel={'Select Ranking Model'}
                selected={rankingAlgorithm}
                data={rankingModels}
                showGroupBatch={false}
                onChange={rankingAlgorithm => this.slotGroupChanged({
                  ...slotGroup,
                  rankingAlgorithm: rankingAlgorithm
                })}/>
            </div>
          }
        </BasicSettings>
        <TabContainer
          tabTitles={[
            'Filters',
            // 'Rules',
            !this.props.parentSlotGroup ? 'Impression Cap' : undefined]}
          activeIndex={activeTabIndex}
          onChange={i => {this.setState({activeTabIndex: i})}}>
          <FilterTabWrapper>
            <TabContainer
              className={unionFilters.length === 1 ? 'hideMenu' : ''}
              activeIndex={unionActiveIndex}
              onChange={i => {
                this.setState({unionActiveIndex: i});
              }}
              tabTitles={unionFilters.map((f, i) => {
              return (
                <span key={i}>
                  <span>Filters{i ? ('_' + (f.number || i)) : ''}</span>
                  {
                    unionFilters.length > 1 &&
                    <Btn type="link" onClick={e => {
                      if(unionActiveIndex === i) {
                        this.setState({unionActiveIndex: 0});
                      }
                      if(i === 0) {
                        let newFilters = extraFilters.shift();
                        let newAgeSettings = extraAgeSettings.shift();
                        let site = newFilters.find(f => f.dimension === 'site').value;
                        let resourceType = newFilters.find(f => f.dimension === 'content_type').value;
                        newFilters = newFilters.filter(f => ['site', 'content_type'].indexOf(f.dimension) < 0);
                        this.slotGroupChanged({
                          ...slotGroup,
                          site,
                          resourceType,
                          filters: newFilters,
                          ...newAgeSettings,
                          extraFilters, extraAgeSettings
                        }, () => this.updateFilters());
                      } else {
                        debugger;
                        extraFilters.splice(i - 1, 1);
                        extraAgeSettings.splice(i - 1, 1);
                        this.slotGroupChanged({...slotGroup, extraFilters, extraAgeSettings}, () => this.updateFilters());
                        setTimeout(() => {
                          this.setState({unionActiveIndex: 0});
                        }, 100)
                      }
                    }}><i className="fa fa-times"/>
                    </Btn>
                  }
                </span>
              )
            })}>
              {
                unionFilters.map((curFilters, unionIndex) => {
                  const curAgeSettings = unionAgeSettings[unionIndex] || {};
                  const filterGrouped = _.groupBy(curFilters, _.property('dimension'));
                  let filterSite = site, filterResourceType = resourceType;
                  if(unionIndex > 0) {
                    filterSite = curFilters.find(f => f.dimension === 'site').value;
                    filterResourceType = curFilters.find(f => f.dimension === 'content_type').value;
                  }
                  let selectedContentTypes = unionIndex === 0 ? resourceType : curFilters.find(f => f.dimension === 'content_type').value;
                  let selectedSite = unionIndex === 0 ? site : curFilters.find(f => f.dimension === 'site').value;
                  selectedContentTypes = _.compact(_.flatten([selectedContentTypes]));
                  selectedSite = _.compact(_.flatten([selectedSite]));
                  let {maxAge: curMaxAge, maxAgeUnit: curMaxAgeUnit, freshnessKey: curFreshnessKey} = curAgeSettings;
                  curMaxAge = curMaxAge || maxAge;
                  curMaxAgeUnit = curMaxAgeUnit || maxAgeUnit;
                  curFreshnessKey = curFreshnessKey || freshnessKey;
                  return (
                    <div key={unionIndex}>
                      <FilterTagList>
                        {unionFilters.length < 2 && <span style={{marginRight: '10px'}}>Filters:</span>}
                        <ul>
                          <li>
                            <strong>Site: </strong>
                            <SelectStyled
                              useFixed={true}
                              defaultLabel={'Select Site'}
                              selected={selectedSite}
                              groups={[
                                {
                                  key: 'Content Sites',
                                  label: 'Feed Sites',
                                  data: (window.sites || session.sites || [])
                                },
                                // {key: 'Coupon Sites', label: 'Coupon Sites', data: [{key: 'iprice', label: 'iPrice'}]}
                              ]}
                              mutuallyExclusive={true}
                              multi={true}
                              disabled={readOnly}
                              showGroupBatch={false}
                              onChange={newSite => {
                                let newContentType = selectedContentTypes;
                                if(newSite.indexOf('iprice') >= 0) {
                                  this.setState({facet: 'store'});
                                  newContentType = ['coupon'];
                                } else if(selectedSite.indexOf('iprice') >= 0) {
                                  this.setState({facet: 'section'});
                                  newContentType = ['article'];
                                }
                                if(unionIndex === 0) {
                                  this.slotGroupChanged({...slotGroup, site: newSite, resourceType: newContentType});
                                } else {
                                  curFilters.find(f => f.dimension === 'site').value = newSite;
                                  curFilters.find(f => f.dimension === 'content_type').value = newContentType;
                                  this.slotGroupChanged({...slotGroup, extraFilters});
                                }
                                if (newSite.length > 0) {
                                  this.setState({data: null});//reload facets
                                }
                              }}/>
                          </li>
                          <li>
                            <strong>Content Types: </strong>
                            <SelectStyled
                              useFixed={true}
                              defaultLabel={'Select Resource Type'}
                              selected={_.cloneDeep(selectedContentTypes)}
                              multi={true}
                              disabled={readOnly}
                              data={selectedSite.indexOf('iprice') >= 0 ? resourceTypes[1].data : resourceTypes[0].data}
                              showGroupBatch={false}
                              mutuallyExclusive={false}
                              onChange={newResourceType => {
                                if(newResourceType.indexOf('coupon') >= 0) {
                                  this.setState({facet: 'store'});
                                } else if(selectedContentTypes.indexOf('iprice') >= 0) {
                                  this.setState({facet: 'section'});
                                }
                                if(selectedContentTypes.indexOf('keyword') < 0 && newResourceType.indexOf('keyword') >= 0) {
                                  newResourceType = ['keyword'];
                                  this.setState({facet: 'section'});
                                }
                                 else if(selectedContentTypes.indexOf('keyword') >= 0 && newResourceType.length > 1) {
                                  newResourceType = newResourceType.filter(x => x !=='keyword');
                                }
                                if(unionIndex === 0) {
                                  this.slotGroupChanged({...slotGroup,resourceType: newResourceType}, () => this.updateFilters())
                                } else {
                                  curFilters.find(f => f.dimension === 'content_type').value = newResourceType;
                                  this.slotGroupChanged({...slotGroup,extraFilters}, () => this.updateFilters())
                                }
                              }}/>
                          </li>
                          {Object.keys(filterGrouped).map((dimension) => {
                            if(dimension === 'site' || dimension === 'content_type') {
                              return null;
                            }
                            let dimensionFilters = filterGrouped[dimension];
                            let dimensionFiltersGrouped = _.groupBy(dimensionFilters, _.property('op'));
                            return (
                              <li key={dimension} className="tag-group-wrapper">
                                <strong>{this.getDimLabel(dimension)}: </strong>
                                {Object.keys(dimensionFiltersGrouped).map(op => {
                                  let tagCount = dimensionFiltersGrouped[op].length;
                                  return (
                                    <span key={op} className={`tag-group ${op} ${tagCount > 1 ? 'multiple': ''}`}>
                                      {dimensionFiltersGrouped[op].map((filter, index) => {
                                        return (
                                          <div key={`${filter.op}_${filter.value}`} className={`tag ${filter.op}`}>
                                            <span title={filter.value}>{filter.value}</span>
                                            <i className="fa fa-times" onClick={e => {
                                              let delIndex =  curFilters.findIndex((f) => (f.dimension === dimension && f.value === filter.value && f.op === filter.op));
                                              curFilters.splice(delIndex, 1);
                                              this.slotGroupChanged({...slotGroup, filters, extraFilters}, () => this.updateFilters());
                                            }}/>
                                          </div>
                                        )
                                      })}
                                    </span>
                                  )
                                })}
                              </li>
                            )
                          })}
                        </ul>
                        <div className={`filter-control-group`}>
                          <Btn type="link" onClick={e => {
                            let newFilters = [
                              {op: 'include', dimension: 'site', value: site},
                              {op: 'include', dimension: 'content_type', value: resourceType},
                            ];
                            let newAgeSettings = {maxAge, maxAgeUnit};
                            newFilters.number = this.union_filter_counter++;
                            extraFilters.splice(unionIndex + 1, 0, newFilters);
                            extraAgeSettings.splice(unionIndex + 1, 0, newAgeSettings)
                            this.slotGroupChanged({...slotGroup, extraFilters, extraAgeSettings}, () => this.updateFilters());
                            this.setState({unionActiveIndex: extraFilters.length})
                          }}><i className="fa fa-plus"/></Btn>
                        </div>
                      </FilterTagList>
                      <SplitView>
                        <FilterView>
                          <div className="data-filter">
                            <div className="search-box">
                              <label>Add Filters:</label>
                              <input
                                type="text"
                                value={keywords}
                                disabled={facet === 'url'}
                                placeholder={`Search ${this.getDimLabel(facet)}`}
                                onChange={e => this.setState({
                                  keywords: e.target.value,
                                  facetPage: 1
                                }, () => this.updateFacets())}
                              />
                              <SelectStyled
                                defaultLabel={'Select Filters'}
                                selected={facet}
                                data={supportedFacets}
                                showGroupBatch={false}
                                onChange={facet => this.setState({facet: facet, facetPage: 1}, () => this.updateFacets())}/>
                            </div>
                            {
                              facet === 'url' &&
                              <div className="regex-editor">
                                <div className="regex-editor-rule">
                                  <span className="field-name">URL matches</span>
                                  <input
                                    type="text"
                                    value={regex_filter_cache.url}
                                    onChange={e => this.setState({regex_filter_cache: {...regex_filter_cache, url: e.target.value}})} />
                                  <a className={regex_filter_cache.url ? '' : 'disabled'}
                                     onClick={e => {
                                    curFilters.push({op: 'match', dimension: facet, value: `~= ${regex_filter_cache.url}`});
                                    this.slotGroupChanged({...slotGroup,filters, extraFilters}, () => this.updateFilters());
                                  }}>
                                    <img src="/img/icons/include.png"/>
                                    Add
                                  </a>
                                </div>
                              </div>
                            }
                            {
                              facet !== 'url' &&
                              <>
                                <TableScrollWrapper className="filter-items">
                                  <Table
                                    columns={[
                                      {
                                        key: 'value', label: this.getDimLabel(facet), align: 'left',
                                        style: {minWidth: '120px'},
                                        renderer: d => {
                                          return this.normalizeDimValue(facet, d.value);
                                        }
                                      },
                                      {
                                        key: '', label: 'Item Count', align: 'left',
                                        style: {minWidth: '120px'},
                                        renderer: d => {
                                          // let max = _.max((facets.items || []).map(d => d.count));
                                          return (
                                            <div className="bar-wrapper">
                                              <div className="bar"
                                                   style={{width: d3.format('%')(d.count / facets.maxFacet)}}/>
                                            </div>
                                          )
                                        }
                                      },
                                      {
                                        key: 'count', label: '', align: 'left', style: {maxWidth: '20px'},
                                        renderer: d => d3.format(',')(d.count)
                                      },
                                      {
                                        key: 'actions', label: 'Actions', align: 'right',
                                        renderer: d => {
                                          let hasChoosen = !!curFilters.find(f => f.value === d.value && f.dimension === facet);
                                          let facetInfo = facetMeta.find(f => f.key === facet) || {};
                                          return (
                                            <span
                                              className={`facet-actions ${(readOnly || hasChoosen) ? 'disabled' : ''}`}>
                                          {
                                            !facetInfo.isNumeric &&
                                            <a onClick={e => {
                                              curFilters.push({op: 'exclude', dimension: facet, value: d.value});
                                              this.slotGroupChanged({...slotGroup,filters, extraFilters}, () => this.updateFilters());
                                            }}>
                                              <img src="/img/icons/exclude.png"/>
                                              Exclude
                                            </a>
                                          }

                                              <a onClick={e => {
                                                curFilters.push({op: 'include', dimension: facet, value: d.value});
                                                this.slotGroupChanged({...slotGroup,filters, extraFilters}, () => this.updateFilters());
                                              }}>
                                            <img src="/img/icons/include.png"/>
                                            Include
                                          </a>
                                        </span>
                                          );
                                        }
                                      },
                                    ]}
                                    rows={facets.items || []}
                                    noDataText={loading ? 'Loading' : 'No Data'}
                                  />
                                </TableScrollWrapper>
                                <PaginationStyled
                                  onChange={p => this.setState({facetPage: p}, () => this.updateFacets())}
                                  current={Number(facetPage) || 1}
                                  pageSize={FACET_PAGE_SIZE}
                                  total={facets.count}
                                  hideOnSinglePage={true}/>
                              </>
                            }
                          </div>
                        </FilterView>
                        <div className="right-side">
                          <TabContainer activeIndex={0} tabTitles={[
                            <div key="time_selection" className="time_selection">
                              <span>{curFreshnessKey === 'update_date' ? 'Update': 'Publish'} Time</span>
                              <Dropdown
                                closeOnClick={true}
                                toggler={
                                  <div style={{whiteSpace: 'nowrap'}}>
                                    <i className="fa fa-angle-down"/>
                                  </div>
                                }>
                                <div className="menu-item" onClick={e => this.setFreshnessKey('publish_date', unionIndex)}>
                                  Publish Time
                                </div>
                                <div className="menu-item" onClick={e => this.setFreshnessKey('update_date', unionIndex)}>
                                  Update Time
                                </div>
                              </Dropdown>
                            </div>,
                            <div key="item">
                              Content
                              <Hint
                                pos={'lefttop'}
                                useFixed={false} width={190} content={
                                <div style={{textAlign: 'left', color: 'white', padding: '10px'}}>
                                  Preview the list of filtered content
                                </div>
                              }/>
                            </div>
                          ]}>
                            <StatView>
                              {
                                unionFilters.length <= 1 &&
                                <label>
                                  {collection ? (collection.count || 0) : 0} items published in
                                  last {curMaxAge} {curMaxAgeUnit}{curMaxAge > 1 ? 's' : ''}
                                </label>
                              }
                              {
                                unionFilters.length > 1 &&
                                <label>
                                  {collection ? (collection.count || 0) : 0} items found.
                                </label>
                              }

                              <div ref={comp => {
                                this.chartWrapper = comp
                              }}>
                                <ReRenderHook renderer={this.renderStatChart.bind(this)}/>
                              </div>

                              <MaxAge>
                                <label>
                                  Max content age
                                  <Hint
                                        useFixed={true} width={250} content={
                                    <div style={{textAlign: 'left', color: 'white'}}>
                                      The maximum age of content to recommend e.g. if 90 days was selected, the content that will be recommended would be published or last updated up to 90 days ago.
                                    </div>
                                  }/>
                                </label>
                                <div className={`max-age-settings ${readOnly ? 'disabled' : ''}`}>
                                  <input
                                    type="number"
                                    disabled={readOnly}
                                    value={curMaxAge || ''}
                                    onChange={e => {
                                      if(unionIndex === 0) {
                                        this.slotGroupChanged({...slotGroup, maxAge: Number(e.target.value)}, () => this.updateFilters());
                                      } else {
                                        extraAgeSettings[unionIndex - 1].maxAge = Number(e.target.value);
                                        this.slotGroupChanged({...slotGroup, extraAgeSettings}, () => this.updateFilters());
                                      }
                                    }}
                                  />
                                  <ul>
                                    {
                                      [
                                        {key: 'hour', label: 'Hours'},
                                        {key: 'day', label: 'Days'},
                                        {key: 'month', label: 'Months'},
                                        // {key: 'year', label: 'Years'},
                                      ].map(d => {
                                        return (
                                          <li
                                            key={d.key}
                                            onClick={e => {
                                              if(unionIndex === 0) {
                                                this.slotGroupChanged({...slotGroup, maxAgeUnit: d.key}, () => this.updateFilters());
                                              } else {
                                                extraAgeSettings[unionIndex - 1].maxAgeUnit = d.key;
                                                this.slotGroupChanged({...slotGroup, extraAgeSettings}, () => this.updateFilters());
                                              }
                                            }}
                                            className={d.key === curMaxAgeUnit ? 'selected' : ''}>
                                            {d.label}
                                          </li>
                                        )
                                      })
                                    }
                                  </ul>
                                </div>
                              </MaxAge>
                            </StatView>
                            <ItemGrid>
                              <ul>
                                {(collection.items || []).map(d => (
                                  <li key={d.id} className="content-item">
                                    <div className="avatar"><img
                                      src={d.thumbnail || 'https://via.placeholder.com/64x48.png'}/>
                                    </div>
                                    <div className="title">
                                      <a
                                        href={d.url}>{d.title.length < 83 ? d.title : (d.title.substring(0, 80) + '...')}</a>
                                      <span className="time">{moment(d.publishTime).format('YYYY-MM-DD HH:mm')}</span>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                              <PaginationStyled
                                onChange={p => this.setState({collectionPage: p}, () => this.updateFilters())}
                                current={Number(collectionPage) || 1}
                                pageSize={COLLECTION_PAGE_SIZE}
                                showLessItems={true}
                                total={collection.count}
                                hideOnSinglePage={true}/>
                            </ItemGrid>
                          </TabContainer>
                        </div>
                      </SplitView>
                    </div>
                  )
                })
              }
            </TabContainer>
          </FilterTabWrapper>
          {/*<div className="rule-tab-wraper">*/}
          {/*  <BuzRuleEditor*/}
          {/*    site={site}*/}
          {/*    slotGroup={slotGroup}*/}
          {/*    ruleMap={slotGroup.ruleMap || {}}*/}
          {/*    onChange={(ruleMap, error) => {*/}
          {/*      this.setState({error});*/}
          {/*      this.slotGroupChanged({*/}
          {/*        ...slotGroup, ruleMap: ruleMap*/}
          {/*      });*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</div>*/}
          {
            !this.props.parentSlotGroup &&
            <div>
              <ImprCapWrapper>
                <ImprCap>
                  <div className="cap-metric-selector">
                    <label>Impression capping: </label>
                    <span className="blockInMobile">
                  <input
                    type="number"
                    value={impressions || ''}
                    onChange={e => this.slotGroupChanged({
                      ...slotGroup,
                      impressionCap: {...impressionCap, impressions: Math.abs(Number(e.target.value || 0))}
                    })}
                  />
                  <span>Impressions</span>
                </span>
                    <span className="or blockInMobile"> OR </span>
                    <span className="blockInMobile">
                  <input
                    type="number"
                    value={clicks || ''}
                    onChange={e => this.slotGroupChanged({
                      ...slotGroup,
                      impressionCap: {...impressionCap, clicks: Math.abs(Number(e.target.value || 0))}
                    })}
                  />
                <span>Clicks</span>
                </span>
                  </div>
                  <div className="cap-unit-selector blockInMobile">
                    <span>Per</span>
                    <ul>
                      {
                        [
                          {key: '1hour', label: 'Hour'},
                          {key: '24hours', label: '24 Hours'},
                          {key: '7days', label: '7 Days'},
                          {key: '30days', label: '30 Days'}
                        ].map(d => {
                          return (
                            <li key={d.key}
                                className={impressionCapPeriod === d.key ? 'selected' : ''}
                                onClick={e => this.slotGroupChanged({
                                  ...slotGroup,
                                  impressionCap: {...impressionCap, unit: d.key}
                                })}>
                              {d.label}
                            </li>
                          )
                        })
                      }
                    </ul>
                  </div>
                </ImprCap>
              </ImprCapWrapper>
              <p>Related article or video will not be recommended if the impression / click cap is reached.</p>
            </div>
          }
        </TabContainer>
        <div className="actions">
          <Btn type="reset" onClick={e => this.props.onCancel()}>
            {this.props.readOnly ? 'Close' : 'Cancel'}
          </Btn>
          {
            !this.props.readOnly &&
            <Btn type="primary"
                 disabled={
                   !!error ||
                   !name ||
                   !resourceType ||
                   !resourceType.length ||
                   !algorithm ||
                   (type === 'custom_targeting' && !segment && !daypart && (kv || '').split('=').filter(s => !!s.trim()).length < 2)
                 }
                 onClick={e => this.props.onConfirm(slotGroup)}>OK</Btn>
          }

        </div>
      </SlotGroupWrapper>
    );
  }

  findSupportFacets(resourceType, deploySite) {
    return facetMeta.filter(d => {
      if (d.contentTypes.every(t => resourceType.indexOf(t) < 0)) {
        return false;
      }
      return (!d.supportSites || d.supportSites.indexOf(deploySite) >= 0);
    });
  }

  getDimLabel(key) {
    let meta = facetMeta.find(d => d.key === key);
    return (meta || {}).label || key;
  }

  normalizeDimValue(key, value) {
    if (!value) {
      return '-';
    } else if (key === 'section') {
      let {site} = this.state.slotGroup || {};
      if (typeof site === 'string') {
        site = [site];
      }
      if (!site || site.length < 2) {
        return value.split(':').slice(1).map(d => _.capitalize(d)).join(' >> ');
      } else {
        return value.split(':').map((d, i) => {
          if (i === 0) {
            let property = window.sites.find(p => p.key === d);
            return property ? property.label : d;
          }
          return _.capitalize(d);
        }).join(' >> ');
      }
    }
    return value;
  }

  slotGroupChanged(slotGroup, callback) {
    this.setState({slotGroup: slotGroup}, callback);
  }

  setFreshnessKey(freshnessKey, unionIndex) {
    const {slotGroup} = this.state;
    const {extraAgeSettings = []} = slotGroup || {};

    if(unionIndex === 0) {
      this.slotGroupChanged({...slotGroup, freshnessKey: freshnessKey}, () => this.updateFilters());
    } else {
      extraAgeSettings[unionIndex - 1].freshnessKey = freshnessKey;
      this.slotGroupChanged({...slotGroup, extraAgeSettings}, () => this.updateFilters());
    }
  }

  _updateFacets() {
    const {slotGroup, data, error, loading, facet, keywords, facetPage = 1} = this.state;
    const {site, resourceType, filters = [], extraFilters, extraAgeSettings, maxAge = 30, maxAgeUnit = 'day', freshnessKey} = slotGroup || {};
    this.setState({loading: true})
    const params = {
      site: site,
      filters,
      extraFilters,
      extraAgeSettings,
      keywords,
      facet,
      limit: FACET_PAGE_SIZE,
      offset: (facetPage - 1) * FACET_PAGE_SIZE,
      maxAge,
      maxAgeUnit,
      resourceType,
      freshnessKey
    };
    let reqIFId = this.reqIFId = Date.now();
    return post('/api/queryItemFacets', params).then(results => {
      if(this.reqIFId !== reqIFId) {
        return;
      }
      this.setState({
        loading: false,
        error: null,
        data: {
          ...data,
          facets: results.facets,
        }
      })
    }).catch(e => {
      this.setState({loading: false, error: e.message})
      console.error(e);
    })
  }

  updateFilters() {
    const {slotGroup, data, keywords, facet, facetPage, collectionPage} = this.state;
    const {site, resourceType, filters = [], extraFilters, extraAgeSettings, maxAge = 30, maxAgeUnit = 'day', freshnessKey} = slotGroup || {};
    this.setState({loading: true})
    const params = {
      site: site,
      filters,
      extraFilters,
      extraAgeSettings,
      keywords,
      facet,
      facetLimit: FACET_PAGE_SIZE,
      facetOffset: (facetPage - 1) * FACET_PAGE_SIZE,
      collectionLimit: COLLECTION_PAGE_SIZE,
      collectionOffset: (collectionPage - 1) * COLLECTION_PAGE_SIZE,
      maxAge,
      maxAgeUnit,
      resourceType,
      freshnessKey
    };
    let reqSPId = this.reqSPId = Date.now();
    return post('/api/querySlotPage', params).then(results => {
      if(reqSPId !== this.reqSPId) {
        return; //ignore it.
      }
      this.setState({
        loading: false,
        error: null,
        data: {
          ...data,
          collection: results.collection,
          distribution: results.distribution,
          facets: results.facets
        }
      })
    }).catch(e => {
      this.setState({loading: false, error: e.message})
      console.error(e);
    })
  }

  renderStatChart() {
    const {data, slotGroup} = this.state;
    if (!this.chartWrapper || !data) {
      return;
    }
    const {distribution = {}} = data;
    const {items = []} = distribution;
    let {maxAge = 30, maxAgeUnit = 'day', extraAgeSettings = []} = slotGroup || {};
    if(extraAgeSettings && extraAgeSettings.length) {
      let hourCountMap = {'day': 24, 'hour': 1, month: 24 * 30};
      extraAgeSettings.forEach(d => {
        console.log(d);
        if(d.maxAge * hourCountMap[d.maxAgeUnit] > maxAge * hourCountMap[maxAgeUnit]) {
          maxAge = d.maxAge;
          maxAgeUnit = d.maxAgeUnit;
        }
      })
    }
    const current = moment(), points = [];
    const distributionMap = items.reduce((ret, next) => {
      return {...ret, [moment(next.value).startOf(maxAgeUnit).format('YYYY-MM-DD HH:mm:ss')]: next.count};
    }, {});
    for (let i = 0; i < maxAge; i++) {
      current.add(-1, maxAgeUnit);
      const currentTime = current.startOf(maxAgeUnit).format('YYYY-MM-DD HH:mm:ss');
      points.unshift(distributionMap[currentTime] || 0);
    }
    // const points = items.map(d => d.count);
    if (!points.length) {
      return;
    }
    var svgWidth = 300; //this.chartWrapper.clientWidth;
    var svgHeight = svgWidth * 0.55;
    var margin = {top: 20, left: 10, bottom: 40, right: 40};
    var width = svgWidth - margin.left - margin.right;
    var height = svgHeight - margin.top - margin.bottom;

    d3.select(this.chartWrapper).selectAll("*").remove();
    const svg = d3.select(this.chartWrapper).append('svg').attr('width', svgWidth).attr('height', svgHeight);

    var x = d3.scaleLinear().range([0, width]);
    var y = d3.scaleLinear().range([1, height]);

    x.domain([0, points.length - 1]);
    y.domain([d3.max(points) * 1.5, 0]);

    const xAxis = d3.axisBottom(x).tickSize(5).tickSizeInner(5).tickPadding(10).tickFormat((text, index) => {
      if (!index) {
        return `    ${maxAge} ${maxAgeUnit}${points.length > 1 ? 's' : ''} ago`;
      } else if (Number(text) === points.length - 1) {
        return 'now';
      } else {
        return ''
      }
    });

    var yAxis = d3.axisRight(y).ticks(3).tickPadding(10).tickFormat(d3.format(',')).tickSize(-(width + 15));
    svg.append('g').attr('class', 'x axis').attr('transform', `translate(${margin.left}, ${height + margin.top})`).call(xAxis);
    svg.append('g').attr('class', 'y axis').attr('transform', `translate(${margin.left + width}, ${margin.top})`).call(yAxis);

    var line = d3.line().curve(d3.curveCatmullRom).x((d, index) => x(index)).y((d) => y(d));

    svg.append('path')
      .attr("class", "sparkline")
      .attr('stroke', BlueJeans)
      .attr('stroke-width', 2)
      .attr('fill', 'none')
      .attr('transform', 'translate(' + margin.left + ',' + (margin.top) + ')')
      .attr('d', line(points));

    var verticalLine = svg.append('line')
      .attr('class', 'verticalLine')
      .attr('stroke', '#ddd')
      .attr('y2', height)
      .attr('stroke-dasharray', '5 5')
      .attr('opacity', 0);

    var tooltip = createTooltip({
      targetSelector: 'dynamic',
      tipCreator: () => {
        let index = Math.floor(x.invert(d3.event.offsetX - margin.left));
        let time = moment().add(-maxAge + index + 1, maxAgeUnit).startOf(maxAgeUnit);
        let timeLabel = time.format('YYYY-MM-DD');
        if (maxAgeUnit === 'hour') {
          timeLabel = time.format('YYYY-MM-DD HH:mm')
        } else if (maxAgeUnit === 'month') {
          timeLabel = time.format('MMM YYYY')
        }
        return `${timeLabel}: ${points[index]}`;
      },
      directionFunc: 'top',
      positionFunc: d => {
        let index = x.invert(d3.event.offsetX - margin.left);
        return [
          d3.event.pageX - d3.event.offsetX + margin.left + x(index),
          d3.event.pageY - d3.event.offsetY + 20
        ]
      }
    });

    function handleMouseOver() {
      tooltip.show.call(this);
      let index = x.invert(d3.event.offsetX - margin.left);
      verticalLine
        .attr('x1', x(index) + margin.left)
        .attr('x2', x(index) + margin.left)
        .attr('pointer-events', 'none')
        .attr('opacity', 1);
    };

    var handleMouseOut = function (d) {
      tooltip.hide.call(this, d);
      verticalLine.attr('opacity', 0);
    };

    svg.append('rect')
      .attr('width', width)
      .attr('height', height)
      .attr('x', margin.left)
      .attr('y', margin.top)
      .style('fill', 'transparent')
      .style('stroke', 'none')
      .on('mouseover', handleMouseOver)
      .on('mousemove', handleMouseOver)
      .on('mouseout', handleMouseOut)
  }
})
