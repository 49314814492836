import React, {Component} from 'react';
import classNames from 'classnames';
import {DropdownStyledWrapper} from './index.style'
import {FixedHook} from "../selectbox/hooks";
import {SelectMenu} from "../selectbox/index.style";

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {active: false};
    this.togglerRef = React.createRef();
    this.menuRef = React.createRef();
    this.wrapperRef = React.createRef();
  }

  render() {
    const {useFixed, fixedTop, fixedLeft} = this.props;
    return (
      <DropdownStyledWrapper
        ref={this.wrapperRef}
        className={classNames('dropdown', this.props.className, {active: this.state.active})}>
        <div ref={this.togglerRef} className="dropdown-toggle"
             onClick={this.toggleMenu.bind(this)}>{this.props.icon || this.props.toggler}</div>
        <div ref={this.menuRef}  onClick={() => {
          this.props.closeOnClick && this.closeMenu();
        }} className={classNames('menu', {hidden: !this.state.active})}>
          {this.props.children}
        </div>
        {!!useFixed && <FixedHook wrapperRef={this.wrapperRef} fixedRef={this.menuRef} offset={{top: fixedTop || 35, left: fixedLeft || 0}}/>}
      </DropdownStyledWrapper>
    );
  }

  toggleMenu(e) {
    if (this.beforeDropdownClosing && this.state.active) {
      this.beforeDropdownClosing();
    }
    this.setState({
      active: !this.state.active
    });
  }

  closeMenu(e) {
    this.setState({active: false});
  }

  handleClick(e) {
    var toggler = this.togglerRef.current;
    var menu = this.menuRef.current;
    if (!toggler || !menu) {
      return;
    }
    if (this.state.active && toggler !== e.target && !toggler.contains(e.target) && menu !== e.target && !menu.contains(e.target)) {
      this.setState({active: false});
      if (this.beforeDropdownClosing) {
        this.beforeDropdownClosing();
      }
    }
  }

  componentWillMount() {
    if (global.document) {
      global.document.addEventListener('click', this.handleClick.bind(this), true); // capture phase
    }
  }

  componentWillUnmount() {
    if (global.document) {
      global.document.removeEventListener('click', this.handleClick.bind(this), true);
    }
  }
}