import React from 'react';
import _ from 'lodash';

export const serializeFilters = d => {
  const siteMetas = window.sites || [];
  const {resourceType, filters, site} = d;
  let contentTypes = Array.isArray(resourceType) ? resourceType : _.compact([resourceType]);
  let sites = Array.isArray(site) ? site : _.compact([site]);

  let customFilterGroups = _.groupBy(d.filters || [], d => d.dimension + d.op);
  const serSite = site => (siteMetas.find(s => s.key === site) || {label: site}).label;
  let customFilters = Object.keys(customFilterGroups).map(k => {
    let items = customFilterGroups[k];
    let first = items[0];
    return `<strong>${first.dimension}</strong> ${first.op === 'include' ? 'in' : 'not in'} ${items.map(d => d.value).join(', ')}`
  });

  let ret = `<strong>${contentTypes.map(d => `${_.capitalize(d)}s`).join(', ')}</strong> in <strong>${sites.map(serSite).join(', ')}</strong>`;
  if(customFilters) {
    ret += (', ' + customFilters);
  }
  ret = `${ret} published in <strong>last ${d.maxAge} ${d.maxAgeUnit}s</strong>`;
  return ret;
}