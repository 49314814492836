import React from 'react';
import styled, {css} from 'styled-components';
import {
  BitterSweet1,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass1, LightGray, LightGray1, MediumGray,
  MediumGray1, LightBlue
} from "../../app/StyleCommon";

import {WidgetWrapper} from './grid.style';

export const WidgetWrapperMewatch = styled(WidgetWrapper)`
  &&& {
    font-family: "Proxima Nova",Helvetica,"微软雅黑",Arial,Tahoma,sans-serif;
    background-color:  rgba(39,39,39,.85);
    padding: 10px 5px;
    .widget-title {
      line-height: 1.3;
      font-weight: 700;
      color: white;
      position: relative;
      padding: 0 70px 0 0;
      margin-bottom: 15px;
      display: inline-block;
      font-size: ${props => props.isWebView ? '18px' : '24px'};
      font-size: ${props => props.isWebView ? '1.125rem' : '1.5rem'};
      margin: 0 0 .625rem;
    }
    .item-list,
    .react-multi-carousel-list {
      padding: 0;
      .item {
        padding: 0 5px;
        .item-inner  {
          margin-left: 0;
          margin-right: 0;
          padding-left: 0;
          padding-right: 0;
          display: block;
          &:hover {
            .thumbnail:after {
              background-color: rgba(0,0,0,.2);
            }
            .title {
              color: #D83BB3;
            }
           }
          .thumbnail {
             max-height: 300px;
             border-radius: 5px;
             padding-bottom: 56.25%;
             width: 80%;
             //padding-bottom: 100%;
             height: 0;
             position: relative;
             &:after {
                content: '';
                background-color: rgba(0,0,0,.0);
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                display: block;
                transition: all .3s ease;
             }
          }
          .publish_date {
            display: none;
          }
          .title {
            max-height: 2.6em;
            line-height: 1.3em;
            text-decoration: none;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            color: white;
            height: 2.1em;
            line-height: 1.1em;
            font-size: 14.4px;
            &:hover {
              color: #D83BB3;
            }
          }
          .section {
            display: none;
          }
        }
      }
    }
    .react-multi-carousel-list,
    .item-list {
      &:hover {
        .react-multiple-carousel__arrow--left,
        .react-multiple-carousel__arrow--right,
        .arrow {
          transform: translate(0, 0) !important;
          opacity: 1;
        }
      }
      .react-multiple-carousel__arrow,
      .arrow {
        background: none;
        border: none;
        box-shadow: none;
        transition: all .3s ease;
        opacity: 0;
        &:before, &:after {
          font-size: 32px;
        }
        &:hover {
          background: none;
          border: none;
          box-shadow: none;
          color: #D83BB3;
          &:before, &:after {
            color: #D83BB3;
          }
        }
        &.react-multiple-carousel__arrow--left,
        &.arrow-left {
          left: 0px;
          transform: translate(-100px, 0);
        }
        &.react-multiple-carousel__arrow--right,
        &.arrow-right {
          right: 0px;
          left: auto;
          transform: translate(100px, 0);
        }
      }
    }
    ${props => props.mediaWidth < 320 && css`
      .item-list .item.thumbnail_left .item-inner .thumbnail {
          width: 30%;
          height: 0;
          padding-bottom: 18%;
       }`
    }
  }
`;