import React from 'react';
import styled, {css} from 'styled-components';
import {
  BitterSweet1,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass1, LightGray, LightGray1, MediumGray,
  MediumGray1, LightBlue
} from "../../app/StyleCommon";

import {WidgetWrapper} from './grid.style';

export const WidgetWrapperToday = styled(WidgetWrapper)`
  &&& {
    font-family: Benton Mod Text Reg, serif;
    padding-bottom: 10px;
    .widget-title {
      font-size: 26px;
      margin: 15px 10px 8px 10px;
      padding: 0;
    }
    .item-list {
      background: transparent;
      display: flex;
      align-items: flex-start;
      .item.thumbnail_top {
        width: 33.333%;
        padding: 0;
        .item-inner {
          padding: 0px 10px;
          .thumbnail {
            width: 100%;
            height: 0;
            padding-bottom: 75%;
          }
          .brief {
            .title {
              font-size: 20px;
              letter-spacing: -.005rem;
              text-decoration: none;
              color: rgb(41, 42, 43);
              font-weight: 400;
              &:hover {
                text-decoration: none;
              }
            }
            .publish_date {
              display: block;
              margin-top: 5px;
              font-size: 14px;
              text-transform: uppercase;
              color: #5b5d5f;
            }
          }
        }
      }
    }
  }
  ${props => props.row === 1 && props.mediaWidth >= 650 && css`
     &&& {
      .item-list {
        .item.thumbnail_top {
          width: 33.333%;
          .item-inner {
            padding: 0 10px;
            .thumbnail {
              width: 100%;
              height: 0;
              padding-bottom: 75%;
            }
            .brief {
              .title {
              }
              .publish_date {
              }
            }
          }
        }
      }
    }
  `}
  ${props => props.row === 1 && props.mediaWidth < 650 && css`
    &&& {
      .item-list {
        .item.thumbnail_top {
          width: 100%;
          .item-inner {
            padding: 10px;
            display: flex;
            .thumbnail {
              width: 33.33%;
              height: 0;
              padding-bottom: 25% ;;
              max-width: inherit;
            }
            .brief {
              .title {
                font-size: 20px;
              }
              
              .publish_date {
                display: none;
              }
            }
          }
        }
      } 
    }
  `}
  ${props => props.column === 1 && css`
    &&& {
      font-family: Benton Mod Text Reg, serif;
      background-color: #f9f8f4;
      padding: 20px;
      .widget-title {
        font-size: 26px;
        border-bottom: 2px solid #292a2b;
      }
      .item-list {
        background: transparent;
        .item {
          width: 100%;
          border-bottom: 1px solid #dcdddd;
          padding: 8px 0;
          .item-inner {
            padding: 10px;
            display: flex;
            .thumbnail {
              width: 33.33%;
              height: 0;
              padding-bottom: 25% ;
              max-width: inherit;
              margin-bottom: 0;
            }
            .brief {
              .title {
                font-size: 16px;
              }
              
              .publish_date {
                display: none;
              }
            }
          }
        }
      }
    }
  `}
`