import React from 'react';
import styled, {css} from 'styled-components';
import {
  BitterSweet1,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass1, LightGray, LightGray1, MediumGray,
  MediumGray1, LightBlue
} from "../../app/StyleCommon";

import {WidgetWrapper} from './grid.style';

export const CNA_Web_Home_MostPopular = styled(WidgetWrapper)`
  &&& {
    font-family: Buenard, serif;
    .widget-title {
      border-bottom: 1px solid #a12528;
      color: #a12528;
      position: relative;
      font-size: 35px;
      b {
        font-family: Lato,sans-serif;
        letter-spacing: 0;
      }
        
      em {
        font-family: Merriweather, serif;
        letter-spacing: -.03em;
        font-weight: 300;
        font-style: italic;
      }
      .hide-btn {
        font-size: 18px;
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        cursor: pointer;
        padding-right: 25px;
        display: flex;
        align-items: center;
        &.show:after {
          transform: rotate(-135deg);
        }
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: 5px;
          margin-top: -3px;
          border-right: 2px solid;
          border-bottom: 2px solid;
          width: 8px;
          height: 8px;
          display: block;
          transform: rotate(45deg);
          transition: transform .2s ease-in-out;
        }
      }
    }
    .widget-body {
      margin-top: 20px;
      margin-bottom: 20px;
      transition: all .2s ease-in-out;
      &.show {
        max-height: 1000px;
      }
      &.hide {
        height: 0;
        max-height: 0;
        overflow: hidden;
        visibility: hidden;
      }
    }
    .item.main {
      border: none;
      margin-bottom: 10px;
      .item-inner {
        display: flex;
        align-items: stretch;
        background-color: #a4191e;
        .thumbnail {
          max-width: 470px;
          width:60%;
          height: 0;
          padding-bottom: 33%;
          box-sizing: border-box;
          overflow: hidden;
        }
        .brief {
          background: linear-gradient(45deg,#a4191e 0,#620d0b 100%);
          justify-content: center;
          padding: 30px 35px 30px 30px;
          color: white;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .title {
            font-size: 20px;
            margin-bottom: 7px;
          }
          .section {
            display: block;
            font-size: 14px;
            color: white;
          }
          .abstract  {
            display: none;
          }
          .publish_date {
            display: block;
            color: rgba(255,255,255,.5);
            font-size: 12px;
          }
        }
      }
    }
    .item-list {
      padding: 10px 0 0 0;
      display: flex;
      align-items: flex-start;
      .item {
        padding: 0;
        &.thumbnail_top .item-inner {
          border-right: 1px solid #ccc;
          margin-bottom: 15px;
          padding-top: 0;
          .thumbnail {
            width: 100%;
            height: 0;
            padding-bottom: 56%;
            margin-right: 0;
            margin: 0 auto 15px auto;
          }
        }
        .item-inner {
          padding: 20px 0;
          .thumbnail {
            width: 100px;
            height: 56px
            padding: 0;
          }
          .brief {
            .title {
              font-size: 20px;
              letter-spacing: -.005rem;
              text-decoration: none;
              color: rgb(41, 42, 43);
              font-weight: 400;
              line-height: 1.2em;
              &:hover {
                text-decoration: none;
              }
            }
            .section {
              display: block;
              font-size: 12px;
              color: rgba(0,0,0,.5) !important;
              text-transform: uppercase;
              font-family: Lato,sans-serif;
              letter-spacing: .5px;
            }
            .publish_date {
              display: block;
              color: rgba(0,0,0,.5);
            }
          }
        }
      }
    }
  }

  ${props => props.mediaWidth >= 1200 && css`
    &&& {
      .item-list .item.thumbnail_top {
        width: 25%;
        .item-inner {
          padding-left: 10px;
          padding-right: 10px;
          border-right: 1px solid #ccc;
        }
        &:nth-child(4n+1) .item-inner {
          padding-left: 0;
        }
        &:nth-child(4n+4) .item-inner {
          border-right: none;
          padding-right: 0;
        }
      } 
    }
  `}
  ${props => props.mediaWidth < 1200 && props.mediaWidth >= 600 && css`
    &&& {
      .item-list .item.thumbnail_top {
        width: 33%;
        .item-inner {
          padding-left: 15px;
          padding-right: 15px;
          border-right: 1px solid #ccc;
        }
        &:nth-child(3n+1) .item-inner {
          padding-left: 0;
        }
        &:nth-child(3n+3) .item-inner {
          border-right: none;
          padding-right: 0;
        }
        &:not(:nth-child(-n+6)) .item-inner {
          display: none;
        }
      } 
    }
  `}

  ${props => props.mediaWidth < 600 && css`
    &&& {
      .widget-title {
      }
      .item-list {
        .item {
          width: 100%;
          &:not(:last-child) .item-inner {
            border-bottom: 1px solid #ccc;
          }
          &.thumbnail_top .item-inner {
            border-right: none;
            .thumbnail {
              width: 33.33%;
              height: 0;
              padding-bottom: 19%;
            }
          }
          .item-inner {
            padding: 10px;
            display: flex;
            .thumbnail {
              width: 33.33%;
              height: 0;
              padding-bottom: 19%;
              max-width: inherit;
            }
            .brief {
              .title {
              }
              
              .publish_date {
              }
            }
          }
        }
      } 
    }
  `}
`