import axios from 'axios';

function request(method) {
  return (url, data, config = {}) => {
    return axios({
      url: url,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...(config.headers || {})
      },
      credentials: 'include',
      cache: 'no-store',
      method: method,
      data: data,
      timeout: 300 * 1000
    }).then(response => {
      const {data, status, statusText} = response;
      if (status !== 200) {
        throw new Error(statusText);
      }
      return data;
    }).catch(err => {
      let {statusText, data} = err.response || {};
      if(statusText) {
        throw new Error(statusText);
      }
      let {message, code, statusCode} = data || {};
      if((code >= 500 || statusCode >= 400) && message) {
        throw new Error(message);
      }
      if (err.message !== 'Unauthorized') {
        console.error(err);
      }
      throw err;
    });
  };
}

export const get = request('GET');
export const post = request('POST');
export const del = request('DELETE');