import styled, {css} from 'styled-components';
import * as d3 from 'd3';

import {
  PrimaryDarkBlue, PrimaryMediumBlue, SecondaryMediumBlue, PrimaryLightBlue,
  GrapeFruit, MediumGray1, DarkGray1, LightGray, DarkGray, LightGray1, BlueJeans,
  MediumGray, BitterSweet, Grass1, DarkBlue, Sunflower, LightGray2, Grass
} from '../app/StyleCommon';

export const WidgetDetailsWrapper = styled.div`
  background-color: white;
  padding: 20px 20px;
  @media screen and (max-width: 667px) {
    padding: 20px 10px;
  }
  .detail-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid ${MediumGray};
    padding: 10px 0;
    font-weight: bold;
    font-size: 16px;
    padding: 5px 10px;
    @media screen and (max-width: 667px) {
      display: block;
      padding-bottom: 10px;
      .title {
        margin-bottom: 10px;
      }
      .date-selector {
        flex-wrap: wrap !important;
      }
      .month-panel {
        width: 100%  !important;
      }
    }
    .title {
      img {
        width: 24px;
        height: 24px;
        vertical-align: middle;
        margin-right: 10px;
      }
      span {
        margin-right: 20px;
      }
      button {
        color: ${DarkGray}
        a {
          color: ${DarkGray}
        }
      }
    }
    .actions {
      display: flex;
      align-items: center;
      .date-selector-dropdown {
        .toggler {
          width: 250px;
          border: 1px solid ${LightGray1};
          background: ${LightGray};
          margin-right: 20px;
          font-size: 13px;
          border-radius: 4px;
          font-weight: normal;
        }
        .date-selector {
          right: 0;
          left: auto;
        }
      }
      .dropdown-toggle {
        height: 30px;
        width: 20px;
      }
    }
  }
`;

export const WidgetOverview = styled.div`
  .attr {
    margin: 14px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 13px;
    @media screen and (max-width: 667px) {
      display: block;
      >label {
        width: 100% !important;
        text-align: left !important;
      }
      > div {
        width: 100%;
        padding: 5px 0 !important;
      }
    }
    > label {
      flex: none;
      display: inline-block;
      width: 120px;
      text-align: right;
      font-weight: bold;
      margin-right: 30px;
      color: ${DarkGray1}
      height: 100%;
      font-size: 13px;
    }
    >div {
      width: 98%;
      padding: 5px 10px;
      border-radius: 3px;
      &:not(:last-child) {
        border-bottom: 1px solid ${LightGray};
      }
    }
    a {
      text-decoration: none;
    }
    .tag-list {
      display: flex;
      padding: 0 !important;
      flex-wrap: wrap;
      .tag {
        //border: 1px solid ${LightGray1};
        white-space: nowrap;
        border-radius: 5px;
        font-size: 12px;
        display: inline !important;;
        background: ${LightGray};
        font-weight: bold;
        margin: 5px 5px 5px 0 !important;
        padding: 5px 10px !important;
        &.algo {
          background: white;
        }
      }
    }
    .layout {
      display: inline-block;
      vertical-align: middle;
      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-right: 5px;
      }
      button {
        margin-left: 10px;
        color: ${DarkGray}
        a {
          color: ${DarkGray}
        }
      }
    }
    &.metric-table {
      width: 100%;
      margin-top: 30px;
      background: white;
      margin-bottom: 25px;
      >label {
        margin-top: 0px;
      }
      .key-metric-wrapper {
        height: auto;
        padding: 0;
      }
      table {
        th {
          text-align: left;
          padding-left: 0;
        }
        td {
          text-align: left;
        }
      }
    }
    &.timeseries {
      margin-top: 20px;
      background: white;
      >label {
        margin-top: 5px;
      }
      >div {
        padding: 0;
      }
      .timeseries-header {
        margin: 5px 0 20px 0;
        display: flex;
        align-items: center;
      }
      .timeseries-body {
        margin-left: -10px;
      }
    }
  }
`;

export const KeymetricCard = styled.div`
  width: 100%;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid #eaebec;
  //box-shadow: 0 0 4px 0 rgba(10, 10, 10, 0.1);
  background-color: #fbfdff;
  &.errorsStat, &.clicksStat {
    //cursor: pointer;
    // &:hover {
    //   background-color: ${LightGray1};
    // }
  }
  ${props => props.type === 'blue1' && css`
    color: ${PrimaryDarkBlue};
    svg path {stroke: ${PrimaryDarkBlue}}
  `}
  ${props => props.type === 'blue2' && css`
    color: ${PrimaryMediumBlue};
    svg path {stroke: ${PrimaryMediumBlue}}
  `}
  ${props => props.type === 'blue3' && css`
    color: ${SecondaryMediumBlue};
    svg path {stroke: ${SecondaryMediumBlue}}
  `}
  ${props => props.type === 'blue4' && css`
    color: ${PrimaryLightBlue};
    svg path {stroke: ${PrimaryLightBlue}}
  `}
  ${props => props.type === 'green1' && css`
    color: ${Grass};
    svg path {stroke: ${Grass}}
  `}
  ${props => props.type === 'red1' && css`
    color: ${BitterSweet};
    svg path {stroke: ${BitterSweet}}
  `}

  .top {
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: bold;
    i {
      margin-right: 10px;
      font-size: 12px;
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    height: 35px;
    margin-top: 6px;
    > h3 {
      margin-right: 10px;
      font-size: 24px;
      font-weight: 500;
      min-width: 30px;
    }
    > div {
      padding: 2px 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      font-size: 12px;
      .status, .value {
        margin-right: 5px;
      }
      .arrow {
        font-size: 10px;
      }
    }
  }
`;