import React from 'react';
import styled, {css} from 'styled-components';
import {
  BitterSweet1,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass1, LightGray, LightGray1, MediumGray,
  MediumGray1, LightBlue
} from "../../app/StyleCommon";

import {WidgetWrapper} from './grid.style';

export const WidgetWrapperCNALifestyle = styled(WidgetWrapper)`
  &&& {
    font-family: Buenard, serif;
    .widget-title {
      text-align: center;
      padding: 0;
      >span {
        background-color: #b50000;
        color: #fff;
        min-height: 60px;
        box-shadow: 5px 5px 0 0 #000;
        font-size: 30px;
        padding: 9px 15px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: -15px;
      }
    }
    .item-list {
      padding: 30px 0 20px;
      display: flex;
      align-items: flex-start;
      .item {
        padding: 0;
        .item-inner {
          padding: 20px 30px;
          .thumbnail {
            width: 100px;
            height: 100px;
            padding: 0;
          }
          .brief {
            .title {
              font-size: 20px;
              letter-spacing: -.005rem;
              text-decoration: none;
              color: rgb(41, 42, 43);
              font-weight: 400;
              line-height: 1.2em;
              &:hover {
                text-decoration: none;
              }
            }
            .section {
              display: block;
              font-size: 10px;
              color: #b50000;
              text-transform: uppercase;
              font-family: Lato,sans-serif;
              letter-spacing: .5px;
              margin-bottom: 12px;
            }
          }
        }
      }
    }
  }
  ${props => props.row === 1 && props.mediaWidth >= 948 && css`
    &&& {
      .item-list {
        .item {
          width: 25%;
          &:not(:last-child) .item-inner {
            border-right: 1px solid #ccc;
          }
          .item-inner.thumbnail_left {
            padding: 20px 30px;
            .thumbnail {
            }
            .brief {
              .title {
              }
              .section {
              }
            }
          }
        }
      }
    }
  `}
  ${props => props.row === 1 && props.mediaWidth < 948 && props.mediaWidth >= 768 && css`
    &&& {
      .widget-title {
        >span {
          font-size: 25px;
          min-height: 35px;
        }
      }
      .item-list {
        flex-wrap: wrap;
        .item {
          width: 50%;
          &:nth-child(-n+2) .item-inner {
            border-bottom: 1px solid #ccc;
          }
          &:nth-child(odd) .item-inner {
            border-right: 1px solid #ccc;
          }
          .item-inner.thumbnail_left {
            padding: 30px 0 20px;
            display: flex;
            .thumbnail {
              width: 33.33%;
              height: 0;
              padding-bottom: 25% ;;
              max-width: inherit;
            }
            .brief {
              .title {
              }
              
              .publish_date {
              }
            }
          }
        }
      } 
    }
  `}
  ${props => props.row === 1 && props.mediaWidth < 768 && css`
    &&& {
    .widget-title {
        >span {
          font-size: 25px;
          min-height: 35px;
        }
      }
      .item-list {
        .item {
          width: 100%;
          &:not(:last-child) .item-inner {
            border-bottom: 1px solid #ccc;
          }
          .item-inner.thumbnail_left {
            padding: 10px;
            display: flex;
            .thumbnail {
              width: 33.33%;
              height: 0;
              padding-bottom: 25% ;;
              max-width: inherit;
            }
            .brief {
              .title {
              }
              
              .publish_date {
              }
            }
          }
        }
      } 
    }
  `}
`