import React from 'react';
import styled, {css} from 'styled-components';

const SvgWrapper = styled.svg`
  path {
  }

`

export default props => {
  return (
    <SvgWrapper xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <polygon points="22 10.5 22 8.5 20 8.5 20 10.5 18 10.5 18 12.5 20 12.5 20 14.5 22 14.5 22 12.5 24 12.5 24 10.5 22 10.5"/><path d="M8,10.5v3h4.74561a4.97476,4.97476,0,1,1-1.21008-5.03552l2.12134-2.12134A8.05789,8.05789,0,1,0,15.85461,10.5Z"/>
    </SvgWrapper>
  )
}
