import styled from 'styled-components';
import {LightGray1, DarkGray1, Aqua, LightGray, SilverGray, MediumGray, DarkGray} from '../../app/StyleCommon';

export const StyledTable = styled.table`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${LightGray1}
  border-collapse: collapse;
  border-spacing: 0;
  color: ${DarkGray1};
  background-color: rgba(255,255,255,.9);
  overflow: hidden;
  position: relative;
  thead {
    font-size: 14px;
    font-weight: 700;
    background: #fafbfc;
    background: linear-gradient(to right bottom,  ${SilverGray}, ${LightGray1});
    th {
      margin: 3px 0;
      padding: 8px 10px;
      text-align: left;
      border-bottom: 1px solid ${LightGray1};
      position: relative;
      border-right: 1px solid ${LightGray1};
      &.center {
        text-align: center;
      }
      &.right {
        text-align: right;
        padding-right: 10px;
      }
      &.sortable {
        cursor: pointer;
        padding-right: 20px;
      }
      .sort-arrow {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 5px;
        top: 0;
        bottom: 0;
        justify-content: center;
        align-items: center;
        &.asc i:first-child{
          color: ${DarkGray}
        }
        &.desc i:last-child{
          color: ${DarkGray}
        }
        i {
          color: ${MediumGray};
          display: inline-block;
          height: 10px;
        }
      }
    }
  }
  tbody {
    position: relative;
    tr {
      //border-bottom: 1px solid ${LightGray1};
      //&:not(.placeholder):not(:nth-child(even)):hover {
      //  background-color: #f2f2f2;
      //}
      &:nth-child(odd) {
        background: ${LightGray};
      }
      &.placeholder {
        user-select: none;
      }
      td {
        padding: 8px 10px;
        color: ${DarkGray1};
        font-weight: 500;
        font-size: 12px;
        &.center {
          text-align: center;
        }
        &.right {
          text-align: right;
        }
      }
      &.no-data {
        color: ${MediumGray};
        td {
          text-align: center;
          min-height: 150px;
          background-color: white;
        }
      }
    }
  }
`;