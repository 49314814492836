import styled, {css} from 'styled-components';
import {
  Aqua, BitterSweet, BitterSweet1,
  BlueJeans, DarkGray, DarkGray1, Grass, Grass1, LightGray, LightGray1, MediumGray, MediumGray1,
  Sunflower1
} from "../app/StyleCommon";

export const PageWrapper = styled.div`
  h3 {
    color: ${DarkGray1};

  }
  .table-wrapper {
    margin-bottom: 10px;
    th {
      background-color: white;
    }
    tr:hover {
      background-color: ${LightGray1};
    }
    .campaign {
      div:last-child {
        color: ${MediumGray1};
        font-weight: bold;
      }
    }
  }
  //.action-suggestions {
  //  margin: 30px 0 10px 0;
  //}
`;

export const EmptyList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-right: 30px;
  margin-top: 80px;
  img {
    width: 100px;
  }
  >div{
    display: inline-block;
    text-align: center;
    button {
      margin: 10px;
    }
  }
`;

export const TagManageWrapper = styled.div`
  min-height: 300px;
  .select-tag-wrapper {
    min-height: 400px;

    .tag-name {
      display: flex;
      align-self: center;
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }

  }
`

export const CreateWrapper = styled.div`
  margin: 10px 0px;
  .field-grid {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .field {
      width: 50%;
      &:first-child {
        margin-right: 50px;
      }
    }
    @media screen and (max-width: 667px) {
      flex-wrap: wrap;
      .field {
        width: 100%;
        margin-right: 0 !important;
      }
    }
  }
  .field {
    margin-bottom: 20px;
    &.error > input,
    &.error .select-box .toggler
    {
      border-bottom: 1px dashed ${BitterSweet1}
    }
    > input {
      width: 100%;
      border: none;
      border-bottom: 1px solid ${MediumGray};
      padding: 5px 10px;
      margin: 10px 0;
    }
    .error-msg {
      color: ${BitterSweet};
      display: block;
      width: 100%;
      font-size: 12px;
      margin-top: -8px;
    }
    .select-box {
      margin: 10px 0;
    }
  }
  .fallback-settings {
    >label {
      margin-right: 5px;
      margin: 10px;
      display: inline-block;
      input {
        margin-right: 5px;
      }
    }
    .creative {
      display: flex;
      align-items: center;
      background: #fafbfc;
      padding: 5px 10px;
      img {
        max-height: 50px;
        max-width: 300px;
        margin-right: 10px;
      }
    }
  }
  .actions {
    text-align: right;
    margin-top: 15px;
  }
`

export const Copiable = styled.div`
  position: relative;
  @media screen and (max-width: 667px) {
    margin-top: 30px;
  }
  ${props => props.disabled && css`
    opacity: .6;
  `}
  &:hover .copy-cat{
    opacity: 1;
  }
  .copy-cat {
    //background: rgb(245, 242, 240);
    opacity: 0.9;
    position: absolute;
    right: 0;
    top: -18px;
    font-size: 12px;
    transition: all .3s ease-in-out;
    cursor: pointer;
    i {
      margin-right: 5px;
    }
    .copied {
      color: ${Grass1};
    }
    .select-box {
      border: none;
      margin: 0 5px;
      color: ${DarkGray1};
      outline: none;
      display: inline;
      .toggler {
        display: inline-box;
        padding: 0;
        font-size: 12px;
        width: 130px;
        border: none;
        img {
          width: 14px;
          height: 14px;
          margin-right: 10px;
          vertical-align: middle;
          border-radius: 50%;
          overflow: hidden;
        }
      }
    }
  }
   pre {
    //margin: 10px 0;
    background: ${LightGray} !important;
    font-size: 12px;
    border: 1px solid ${LightGray1};
    span {
      word-break: break-all;
      //white-space: pre-line;
      background: transparent !important;
    }
  }
  code {
    margin: 20px 0;
    white-space: normal !important;
    word-break: break-all;
  }
  pre {
    border-radius: 10px;
    max-height: 40px !important;
    overflow: auto;
    white-space: normal;
    word-break: break-all;
    font-size: 11px;
  }
`;

export const ControlBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  position: relative;
  .searchBox {
    flex-grow: 1;
    i {
      position: absolute;
      left: 10px;
      top: 10px;
    }
    input {
      padding: 8px 10px 8px 30px;
      font-size: 12px;
      border-radius: 5px;
      border: 1px solid ${MediumGray};
      background: ${LightGray};
      width: 100%;
      box-sizing: border-box;
      outline: none;
      &:focus {
        border-color: ${BlueJeans};
        box-shadow: inset 0 0 3px rgba(0,0,0,.1);
      }
    }
  }
  .select-box {
    margin-left: 20px;
    min-width: 150px;
    flex: 0;
  }
  button {
    margin-right: 0;
    margin-left: 10px;
  }
  @media screen and (max-width: 667px) {
    flex-wrap: wrap;
    .searchBox {
      width: 100%;
      margin-bottom: 5px;
    }
    .select-box {
      margin: 5px 0;
      width: 100%;
      .toggler {
        width: 100%;
      }
    }
  }
`;

export const TagList = styled.ul`
  margin: 10px 0 20px 0;
  .tag-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      text-decoration: none !important;
    }
  }
  .selected-tag {
    margin-bottom: 30px;
    position: relative;
    border: 1px solid ${MediumGray};
    border-radius: 5px;
    background: #fafbfc;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 667px) {
      padding: 10px;
      .actions, .size {
        position: static !important;
        margin: 10px 0 !important;
      }
      .actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px !important;
      }
    }
    .actions {
      button{
        margin: 10px 0;
        padding: 2px 5px;
        display: block;
        min-width: 100px;
        border: 1px solid #ccc;
        &:hover {
          box-shadow: 1px 1px 5px rgba(0,0,0,.2);
        }
      }
    }
    .size {
      position: absolute;
      width: 60px;
      height: 40px;
      left: 10px;
      top: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
    }
    .name {
      //font-weight: bold;
      color: ${DarkGray};
      word-break: break-all;
      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-right: 8px;
      }
    }
    .fallback {
      font-size: 12px;
    }
    .code {

    }
    code {
      margin: 20px 0;
      white-space: normal !important;
      word-break: break-all;
    }
    pre {
      border-radius: 10px;
      max-height: 80px;
      overflow:auto;
      white-space: normal;
      word-break: break-all;
      background-color: #f1f2f3;
    }
    .fallback {
      img {
        margin: 10px 5px 0 0;
        display: block;
      }
    }
  }
`;

export const PlacementInfo = styled.div`
  position: relative;
  background: white;
  padding: 10px 20px;
  margin-left: -5px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-top: 60px;
  h5 {
    position: absolute;
    top: -45px;
    left: 0;
  }
  .note {
    font-size: 12px;
  }
  .attr {
    label {
      display: inline-block;
      width: 150px;
      font-size: 14px;
    }
    strong {
      font-weight: normal;
      font-size: 12px;
      &.strong {
        font-weight: bold !important;
      }
    }
  }
  >div {
    margin: 20px 0;
    &:first-child {
      margin-top: 0px;
    }
  }
  .segment-actions {
    position: absolute;
    right: 10px;
    top: -25px;
    margin: 0;
    button {
      text-decoration: none !important;
    }
    @media screen and (max-width: 667px) {
      position: static;
    }
  }
 `;

