import React from 'react'
import MainLayout from '../layout/index';
import {withConsumer} from '../app/ApplicationContext';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import {withRouter} from 'react-router';
import {post} from '../utils/request';
import {ProgressBar} from "../uikit/index";
import {Spinner} from '../uikit/Loader/index.style';
import {DeployPanel} from './step.deploy.style';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {duration} from '../utils/formatter';
import copy from 'copy-to-clipboard';
import {scroller} from 'react-scroll';
import Btn from "../uikit/btn/index";

export default withConsumer(class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    render() {
      const copyMap = this.state;
      const {appState: {session}} = this.props;
      const {widget, abTestingEnabled = false, onCreateAbTesting} = this.props;
      return (
        <DeployPanel>
          <h2>Deploy to website</h2>
          <h3>Step 1: Add the lib to every page of your website</h3>
          <div className="copy-able">
            <div className="copy-cat">
              <Btn
                type="link"
                size="small"
                className={copyMap['tag'] ? 'copied' : ''}
                onClick={e => this.copyCode(this.genTag(), 'tag')}>
                <i className="fa fa-copy"/>
                {copyMap['tag'] ? 'Copied' : 'Copy Code'}
              </Btn>
            </div>
            <SyntaxHighlighter language="html" wrapLines={true}>
              {this.genTag()}
            </SyntaxHighlighter>
          </div>

          <h3>Step 2: Render the widget</h3>
          <h5>(1) Render the widget automatically: </h5>
          <p>
            The library will render the widget using the default style that created in our admin portal widget builder module.
          </p>
          <div className="copy-able">
            <div className="copy-cat">
              <Btn type="link" size="small"
                   className={copyMap['container'] ? 'copied' : ''}
                   onClick={e => this.copyCode(this.genCode(), 'container')}>
                <i className="fa fa-copy"/>
                {copyMap['container'] ? 'Copied' : 'Copy Code'}
              </Btn>
            </div>
            <SyntaxHighlighter language="html" wrapLines={true}>
              {this.genCode()}
            </SyntaxHighlighter>
            <p>Copy and paste this code into the position you want.</p>
          </div>
          <h5>(2) Render the widget manually: </h5>
          <p>
            Instead of using the buildin UI renderer you may specify a function, which is then responsible for the rendering the widget.
          </p>
          <div className="copy-able">
            <div className="copy-cat">
              <Btn type="link" size="small"
                   className={copyMap['manually'] ? 'copied' : ''}
                   onClick={e => this.copyCode(this.genManualCode(), 'manually')}>
                <i className="fa fa-copy"/>
                {copyMap['manually'] ? 'Copied' : 'Copy Code'}
              </Btn>
            </div>
            <SyntaxHighlighter language="javascript" wrapLines={true}>
              {this.genManualCode()}
            </SyntaxHighlighter>
          </div>
          <br/>
          {/*<hr style={{border: '1px solid #ddd'}}/>*/}
          {/*<br/>*/}
          {/*<h2>Deploy to mobile app</h2>*/}
          {/*<h5>&nbsp;&nbsp;(Option 1): Using Webview. </h5>*/}
          {/*<p>Replace the parameters in the following html code to the real value*/}
          {/*  and put code into a webview in the*/}
          {/*  correct position</p>*/}

          {/*<div className="copy-able">*/}
          {/*  <div className="copy-cat">*/}
          {/*    <Btn type="link" size="small"*/}
          {/*         className={copyMap['mobile'] ? 'copied' : ''}*/}
          {/*         onClick={e => this.copyCode(this.genMobileCode(), 'mobile')}>*/}
          {/*      <i className="fa fa-copy"/>*/}
          {/*      {copyMap['mobile'] ? 'Copied' : 'Copy Code'}*/}
          {/*    </Btn>*/}
          {/*  </div>*/}
          {/*  <SyntaxHighlighter language="html" wrapLines={true}>*/}
          {/*    {this.genMobileCode()}*/}
          {/*  </SyntaxHighlighter>*/}
          {/*</div>*/}

          {/*<h5>&nbsp;&nbsp;(Option 2): Using REST API. </h5>*/}
          {/*<p>*/}
          {/*  Call the following API to fetch recommendation data and render the*/}
          {/*  items using your native code.*/}
          {/*  correct position*/}
          {/*</p>*/}
          {/*<div className="copy-able">*/}
          {/*  <div className="copy-cat">*/}
          {/*    <Btn type="link" size="small"*/}
          {/*         className={copyMap['api'] ? 'copied' : ''}*/}
          {/*         onClick={e => this.copyCode(this.genRESTCode(), 'api')}>*/}
          {/*      <i className="fa fa-copy"/>*/}
          {/*      {copyMap['api'] ? 'Copied' : 'Copy Code'}*/}
          {/*    </Btn>*/}
          {/*  </div>*/}
          {/*  <SyntaxHighlighter language="json" wrapLines={true}>*/}
          {/*    {this.genRESTCode()}*/}
          {/*  </SyntaxHighlighter>*/}
          {/*  <p>Response Structure:</p>*/}
          {/*  <SyntaxHighlighter language="json" wrapLines={true}>*/}
          {/*    {`{"data": {"items": [...]}, "context": {...}}`}*/}
          {/*  </SyntaxHighlighter>*/}
          {/*</div>*/}

          {/*<hr style={{border: '1px solid #ddd'}}/>*/}
          {/*<br/>*/}
          {/*<h2>Export as HTML5 Creative</h2>*/}
          {/*<p>*/}
          {/*  You can <a href={`/api/export/html5_creative?widgetId=${widget.id}`}>download</a>*/}
          {/*  &nbsp;the widget as html5 creative and upload to Goggle Ad Manager to deploy it to Mediacorp Ad Unit.*/}
          {/*  When you upload the creative to Google Ad Manager, make sure SafeFrame is turned off so that the widget can read meid cookie for proper recommendation.*/}
          {/*</p>*/}
          {/*<img src={'/img/assets/safeframe.png'} style={{maxWidth: '380px'}}/>*/}

          {widget.status === 'Draft' && !session.isExternal &&
            <>
              <hr style={{border: '1px solid #ddd'}}/>
              <br/>
              <h2>Create A/B Test</h2>
              <div className="ab-test-create">
                <input
                name="createAbTesting"
                type="checkbox"
                checked={abTestingEnabled}
                onChange={e => onCreateAbTesting(e.target.checked)}/>
                <label>Create A/B testing for the widget after publishing.</label>
              </div>
            </>
          }
        </DeployPanel>
      )
    }

    genTag() {
      const {appState: {session}} = this.props;
      const {network = 'mediacorp'} = session || {};
      return `<script async src="${process.env.REACT_APP_SERVER_URL}/tag.js?network=${network}"></script>`;
    }

    genCode() {
      const {widget} = this.props;
      const code = `<div id="${widget.id}" data-role="rec-widget"></div>`;
      return code;
    }

    genManualCode() {
      const {widget} = this.props;
      const code = `var recApp = recApp || {};
recApp.callQueue = recApp.callQueue || [];
recApp.callQueue.push(['insertWidget', {
  widgetId: '${widget.id}',
  type: 'widget',
  renderFunction: function (data, context) {
    //use the the data.items to do any rendering you want. for example:
    //recApp.renderWidget(data, context, '#recommendContainner')
  }
}])`;
      return code;
    }

    genRESTCode() {
      const {widget} = this.props;
      const code = `curl -X POST '${process.env.REACT_APP_SERVER_URL}/api/v1/loadWidget?token=e6f6h28e26vbc8442b288eb6121d85b9a4' -H 'Accept: application/json' -H 'Content-Type: application/json' --data-binary '{
  "id":"${widget.id}",
  "context": {
    "meid": "feea1fb7-093e-4904-842d-4efaa7e9856b",
    "cxense_id": "k4s54akt21t2sd8c",
    "url": "https://www.channelnewsasia.com/news/business/singapore-world-past-peak-trade-globalisation-cptpp-rcep-asean-12929886",
    "content_id": "12929886"
  }
}'`;
      return code;
    }

    genMobileCode() {
      const {appState: {session}} = this.props;
      const {network = 'mediacorp'} = session || {};
      const {widget} = this.props;
      return `<!DOCTYPE html>
<html lang="en">
 <head>
  <meta charset="utf-8">
    <meta name="viewport" content="initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    <script>
      window.recApp = window.recApp || {};
      recApp.context = {
        isWebView: true,
        site: '{{site}}',
        lang: '{{lang}}',
        url: '{{url}}', //current article url
        referrer: '{{ueferrer}}',
        content_id: '{{content_id}}',
        meid: '{{meid}}',
        device_id: '{{device_id}}', //iOS IDFA or Androdi GAID
        cxense_id: '{{cxense_id}}', // used for a/b testing benchmark
      };
    </script>
  </head>
  <body>
    <div id="${widget.id}" data-role="rec-widget"></div>
    <script async src="${process.env.REACT_APP_SERVER_URL}/tag.js?network=${network}"></script>
  </body>
</html>`
    }

    copyCode(code, codeType) {
      copy(code);
      this.setState({[codeType]: true});
      setTimeout(() => {
        this.setState({[codeType]: false});
      }, 2000);
    }
  }
)
