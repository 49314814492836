import React from 'react';
import classnames from 'classnames';
import ReactDOM from 'react-dom';
import {withRouter} from 'react-router';
import _ from 'lodash';
import moment from 'moment';
import {withConsumer} from '../app/ApplicationContext';
import MainLayout from '../layout/index';
import {sites} from '../utils/metadata';
import {post} from '../utils/request';
import {ProgressBar, Table, TableScrollWrapper, Btn, CondirmDialog, Loader} from '../uikit/index';
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import {SegmentPageWrapper, EmptyPlaceholder, SegmentInfo} from './builder-segment.style';
import {SelectStyled} from './select.style';
import {Spinner} from "../uikit/Loader/index.style";
import {PaginationStyled} from '../uikit/Pagination/index.style';
import FilterBox from '../uikit/FilterBox/index';
import {BlueJeans} from "../app/StyleCommon";
import {intFormatter, periodFormat, uniquesFormatter} from "../utils/formatter";
import SegmentSelector from './builder-segment-selector';

export default class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {campaign, totalSSO, readOnly, errorMap} = this.props;
    const {segment} = campaign;
    const {loading, data, error} = this.state;
    const {name, query} = segment || {};
    console.log((query || {}).periodStr || 'last30days');
    return (
      <SegmentPageWrapper>
        {!!error && <ErrorMessage message={error}/>}
        {!!errorMap.segment && <ErrorMessage message={errorMap.segment}/>}
        {!!loading && <Loader type="absolute"/>}
        {
          !!segment &&
            <SegmentInfo>
              <h5>Selected Target Audience:</h5>
              <div className="segment-actions">
                {/*<Btn disabled={readOnly} className="delete-btn" type="link" onClick={e => this.deselectSegment()}><i*/}
                {/*  className="fa fa-trash"/> Reset</Btn>*/}
                <Btn disabled={readOnly} type="link" onClick={e => this.deselectSegment()}>Change</Btn>
              </div>
              {
                segment !== 'RON' &&
                <>
                <div className="attr">
                <span><label>Segment name:</label> <strong>{segment.name}</strong> &nbsp;<a
                  rel="noopener noreferrer"
                  href={`https://meid.mediacorp.sg/segments?id=${segment.id}`} target="_blank"><i
                  className="fa fa-external-link"/></a></span>,
                </div>
                <div className="attr">
                  <span><label>Created:</label> <strong>{moment(segment.CreatedDateTime).format('MMM DD YYYY, HH:mm A')}</strong> by {segment.creator}</span>
                </div>
                <div className="attr">
                  <label className="definition-header">
                    Segment criteria:
                  </label>
                  <FilterBox
                    key={(segment || {}).id}
                    readOnly={true}
                    formStyle={true}
                    defaultPeriod={'last30days'}
                    dimensionRetriveAPI={'/api/contentSearch'}
                    dimensions={[]}
                    autoApply={true}
                    filters={segment.query || {op: '$and', items: [], period: 'last30days'}}
                    onSearchBoxPopup={confirmInfo => this.setState({confirmInfo: confirmInfo})}
                    onFiltersChange={query => this.setState({query})}/>
                </div>
                </>
              }
              {
                segment === 'RON' &&
                <>
                <div className="attr">
                  <span><strong className="strong">Run of Network</strong> </span>,
                </div>
                <div className="note">
                  <span>The campaign will target all users across your network.</span>
                </div>
                </>
              }
            </SegmentInfo>
        }
        {
          !segment &&
          <EmptyPlaceholder>
            <div>
              <img src="/img/ads/builder/audience.png"/>
              <p>Please select target audience segment using the below buttons.</p>
              <p className="note">(If “Run of Network” is selected, the campaign will target all users across your network.)</p>
              <div>
                <Btn type="reset" onClick={e => this.selectSegment('RON')}>Run of Network</Btn>
                <Btn type="primary" onClick={e => this.showSegmentSelector('all')}>Select Segment</Btn>
              </div>
            </div>
          </EmptyPlaceholder>
        }
      </SegmentPageWrapper>
    )
  }

  showSegmentSelector(mode) {
    const {campaign} = this.props;
    const {loading, data, error, segment} = this.state;
    const confirmInfo = {
      type: 'form',
      backgroundClose: false,
      title: <strong>Select Segment</strong>,
      width: '950px',
      hideCancel: true,
      hideOK: true,
      onCancel: () => CondirmDialog.closeAll(),
      dialogBody: <SegmentSelector
        data={data}
        mode={mode}
        onConfirm={(segment) => {
          this.selectSegment(segment);
          CondirmDialog.closeAll();
        }}
        onCancel={() => {
          CondirmDialog.closeAll();
        }}
      />,
    }
    ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById('global-confirm'));
  }

  selectSegment(segment) {
    this.props.onChange({segment: segment});
  }

  deselectSegment() {
    this.props.onChange({segment: null});
  }
}