import React from 'react'
import MainLayout from '../layout/index';
import {withConsumer} from '../app/ApplicationContext';
import {Wrapper,  Spinner} from './profile.style';
import ReactPasswordStrength from '../uikit/password/index';
import {Btn, ProgressBar} from "../uikit";
import {post} from "../utils/request";
import {ErrorMessage} from "../uikit/errorbox/errorMessage";

export default withConsumer(class extends React.Component {
  constructor(props) {
    super(props);
    const {session} = this.props.appState;
    this.state = {
      changed: false,
      name: session.name,
    }
  }
  render() {
    const {changed, name, successMessage, profileMessage, error, loading, oldPassword, newPassword, newPassword1} = this.state;
    const {session} = this.props.appState;

    // let canViewProfile = session.rights.indexOf('View My Profile') >= 0;
    // let canEditProfile = session.rights.indexOf('Edit My Profile') >= 0;
    return (
      <MainLayout activeItem="settings" loading={false} breadcrumb={[{path: '/settings', label: 'Settings'}]}>
        {
          <Wrapper>
            <h3>
              <span>My Profile</span>
            </h3>

            <div className="setting-wrapper">
              {/*<div className="avatar-wrapper" id="avatar">*/}
              {/*  <img className="avatar" src="/img/user_icon/default_user_icon.jpeg"/>*/}
              {/*  <div>*/}
              {/*    <Btn type="primary">Choose a File</Btn>*/}
              {/*    <p>Acceptable formats: .jpg or .png, max file size is 500kb and min size is 70kb</p>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="field-wrapper"  id="account">
                <h4>Account Information</h4>
                {/*<p>Your account is connected with Mediacorp SSO Account. use mediacorp email password to login to system.</p>*/}
                <div className="field">
                  <label>Name</label>
                  <input disabled={true} type="text" value={name} onInput={e => this.setState({changed: true, name: e.target.value})}/>
                </div>
                <div className="field">
                  <label>Email Address</label>
                  <input disabled={true} type="text" value={session.userId}/>
                </div>
                <span>
              {
                !!profileMessage &&
                <div className="field">
                  <label></label>
                  <div className="success-msg">
                    <i className="fa fa-check" aria-hidden="true"></i>
                    {profileMessage}
                  </div>
                </div>

              }
                  {
                    changed &&
                    <div className="field">
                      <label></label>
                      <div>
                        <Btn disabled={!name} type="reset" onClick={e => this.setState({name: session.name, changed: false})}>Cancel</Btn>
                        <Btn disabled={!name} type="primary"
                             onClick={e => this.updateUser()}
                        >Save</Btn>
                      </div>
                    </div>
                  }
            </span>
              </div>
              <div className="field-wrapper"  id="password">
                <h4>Change Password</h4>
                <div className="field">
                  <label>Current Password</label>
                  <input type="password" value={oldPassword} onInput={e => this.setState({oldPassword: e.target.value})}/>
                </div>
                <div className="field">
                  <label>New Password</label>
                  <ReactPasswordStrength
                    className="password-strength-wrapper"
                    style={{display: 'block'}}
                    minLength={8}
                    minScore={5}
                    defaultValue={newPassword}
                    changeCallback={v => {
                      this.setState({validP1: v.isValid});
                      this.onNewPasswordInput('newPassword', v.password)
                    }}
                    inputProps={{name: "password_input", autoComplete: "off", className: "form-control"}}
                  />
                </div>
                <div className="field">
                  <label>Confirm New Password</label>
                  <ReactPasswordStrength
                    className="password-strength-wrapper"
                    style={{display: 'block'}}
                    minLength={8}
                    minScore={5}
                    defaultValue={''}
                    changeCallback={v => {
                      this.setState({validP2: v.isValid});
                      this.onNewPasswordInput('newPassword1', v.password)
                    }}
                    inputProps={{name: "password_input", autoComplete: "off", className: "form-control"}}
                  />
                </div>
                <div className="field">
                  <label></label>
                  <p style={{marginLeft: '20px'}}>
                    Password should be at least 8 characters without leading or trailing blanks <br/> with a mixture of at least one or more special characters, number, upper case and lower case characters
                  </p>
                </div>
                {
                  (!!error || !!successMessage) &&
                  <div className="field">
                    <label></label>
                    {!!error && <div className="error-msg">{error}</div>}
                    {
                      !!successMessage &&
                      <div className="success-msg">
                        <i className="fa fa-check" aria-hidden="true"></i>
                        {successMessage}
                      </div>
                    }
                  </div>
                }
                <div className="field">
                  <label></label>
                  <Btn disabled={!this.validate()} onClick={e => this.updatePassword()}>
                    {false && <Spinner/>}
                    Confirm
                  </Btn>
                </div>
              </div>

              <div className="field-wrapper"  id="2fa">
                <h4>2FA Authentication</h4>
                <div className="field">
                  <label>Using Email</label>
                  <p style={{marginLeft: '20px'}}>When you sign in to the dashboard, a code will be sent to your Mediacorp email to finish the login process.</p>
                </div>
                {/*{*/}
                {/*  !!session.duoDevice &&*/}
                {/*  <div>You will receive DUO Push when you sign in to this dashboard.</div>*/}
                {/*}*/}
                {/*{*/}
                {/*  !session.duoDevice &&*/}
                {/*  <>*/}
                {/*    <p>Please use Duo Mobile to scan the below QR code with its built-in camera so that you can receive and respond to Duo Push login requests.</p>*/}
                {/*    <div className="field">*/}
                {/*      <label style={{alignSelf: 'flex-start', marginTop: '10px'}}>Scan to use DUO 2FA</label>*/}
                {/*      <div>*/}
                {/*        <img src={session.duoQR || '/img/user_icon/qr.jpeg'}/>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </>*/}
                {/*}*/}
              </div>
              {/*<div className="field-wrapper"  id="privilege">*/}
              {/*  <h4>My privilege </h4>*/}
              {/*  <div className="field">*/}
              {/*    <label>Role groups</label>*/}
              {/*    <div>*/}
              {/*      {session.roles.join(', ')}*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
            {/*<ul className="navigation">*/}
            {/*  /!*<li><a href="#avatar"><i className="material-symbols-outlined">contacts</i> Avatar Image</a></li>*!/*/}
            {/*  <li><a href="#account"><i className="material-symbols-outlined">signature</i> Account Information</a></li>*/}
            {/*  <li><a href="#password"><i className="material-symbols-outlined">password</i> Change Password</a></li>*/}
            {/*  <li><a href="#2fa"><i className="material-symbols-outlined">duo</i> 2FA Authentication</a></li>*/}
            {/*  /!*<li><a href="#privilege"><i className="material-symbols-outlined">military_tech</i> Roles</a></li>*!/*/}
            {/*</ul>*/}
          </Wrapper>
        }
      </MainLayout>
    )
  }

  onNewPasswordInput(type, value) {
    this.setState({[type]: value}, () => {
      const {newPassword, newPassword1, validP1, validP2} = this.state;
      if(validP1 && validP2 && /^[a-z0-9]$/ig.test(newPassword)) {
        this.setState({error: 'New Password shall be a mixture of alphanumeric characters with at least one or more special characters, number, upper case and lower case characters.'})
      } else if(validP1 && validP2 && (!/[A-Z]/g.test(newPassword) || !/[a-z]/g.test(newPassword) || !/[0-9]/g.test(newPassword))) {
        this.setState({error: 'New Password shall be a mixture of alphanumeric characters with at least one or more special characters, number, upper case and lower case characters.'})
      }
      else if (validP1 && validP2 && newPassword1 && newPassword !== newPassword1) {
        this.setState({error: 'The new password and confirmation password  do not match.'})
      } else {
        this.setState({error: undefined})
      }
    });
  }

  validate() {
    const {oldPassword, newPassword, newPassword1, validP1, validP2} = this.state;
    return validP1 && validP2 && oldPassword && newPassword === newPassword1;
  }

  updatePassword(e) {
    const {oldPassword, newPassword} = this.state;
    const {appState: {session = {}}, syncSession} = this.props;
    this.setState({loading: true, error: undefined});
    post('/api/resetPW', {
      userId: session.userId,
      oldPassword: oldPassword,
      newPassword: newPassword
    }).then(() => {
      this.setState({successMessage: 'update user password successfully', loading: false});
      delete session.passwordTooWeak;
      delete session.passwordExpired;
      delete session.passwordWillExpire;
      delete session.passwordExpireDate;
      delete session.isTempPassword;
      syncSession();
      setTimeout(() => {
        this.setState({successMessage: undefined})
      }, 5000);
    }).catch(e => {
      this.setState({error: e.message, loading: false});
    });
  }

  updateUser(e) {
    const {name} = this.state;
    const {appState: {session = {}}, syncSession} = this.props;
    this.setState({loading: true, error: undefined});
    post('/api/updateProfile', {
      id: session.userId, name: name}).then(() => {
      this.setState({profileMessage: 'update profile successfully', loading: false});
      session.name = name;
      syncSession();
      setTimeout(() => {
        this.setState({loading: false, changed: false})
      }, 5000);
    }).catch(e => {
      this.setState({error: e.message, loading: false});
    });
  }
})