import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import {Redirect, Link} from 'react-router-dom';
import {withConsumer} from '../app/ApplicationContext';
import {Btn, Table, ProgressBar, Loader, CondirmDialog} from '../uikit/index';
import PreviewBox from '../widgets/previewBox';
import {VariantSelectorWrapper, ErrorBox} from './step-variants.style';
import {algorithms} from "../utils/metadata";
import {algorithms as adAlgorithms} from "../utils/metadata.ads";
import {TagList} from "./step-variants.style";
import {filterTags} from "../utils/formatter";
import {PaginationStyled} from "./index.style";

export default withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      PAGE_SIZE: 20
    };
    this.search = _.debounce(() => this.setState({data: null, page: 1}), 500).bind(this);
  }

  render() {
    const {site, category, abtesting} = this.props;
    const {data, error, loading, keyword, page, PAGE_SIZE} = this.state;
    let {widgets = [], total} = data || {};
    const {appState: {session}} = this.props;
    const isAdmin = (session || {}).isAdmin;
    return (
      <VariantSelectorWrapper>
        {
          !data && !error &&
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/listAllWidgets`}
            params={{
              status: 'Published',
              site: site,
              category: category,
              limit: PAGE_SIZE,
              offset: (page - 1) * PAGE_SIZE,
              keyword: keyword,
              isTaCompatible: abtesting.isEngineSticky
            }}
            successHandler={data => {
              this.setState({data: data, loading: false, error: false});
            }}
            errorHandler={e => this.setState({error: e.message})}/>
        }
        {
          !!loading && <Loader transparent={true} type="absolute"/>
        }
        {
          !!error && <ErrorBox>{error}</ErrorBox>
        }
        <div className="searchBox">
          <i className="fa fa-search"/>
          <input
            type="text"
            placeholder="Search By Name, ID"
            value={keyword}
            onChange={e => this.setState({keyword: e.target.value}, () => this.search())}/>
        </div>
        <div style={{maxHeight: '500px', overflow: 'auto', marginBottom: '10px'}}>
          <Table
            columns={[
              {
                key: 'no', label: 'No', align: 'center',
                renderer: (d, i) => i + 1
              },
              // {key: 'status', label: 'Status', align: 'left'},
              {
                key: 'name', label: 'Name', align: 'left',
                renderer: d => {
                  return <span className="preview-link">
                    <img className="site" src={`/img/property/${d.site}.png`}/>
                    {d.name} <strong style={{margin: '0 5px'}}>(ID: {d.id})</strong>
                    {/*<i className="fa fa-eye"/>*/}
                    <TagList>
                      {filterTags(d.tags, 'widget', isAdmin).map(t => {
                        return <span className={`tag ${t.split('::')[0]}`} key={t}>{t.split('::').pop()}</span>
                      })}
                      {isAdmin && this.renderWidgetAlgorithm(d)}
                      {!isAdmin && d.thinkWidget && <span className="tag">TA</span>}
                    </TagList>
                  </span>
                }
              },
              {
                key: 'creator', label: 'Creator', align: 'left'
              },
              {
                key: 'actions', label: '', align: 'right',
                renderer: d => {
                  return (
                    <Btn
                      disabled={this.isItemSelected(d)}
                      type="primary"
                      className="selectBtn"
                      onClick={e => this.addVariant(d)}>
                      Select
                    </Btn>
                  );
                }
              },
            ]}
            rows={widgets || []}
            noDataText={
              <div style={{margin: '20px 10px'}}>
                No widget for selection, Please
                &nbsp;
                <a className="create-btn" target="_blank" rel="noopener noreferrer" href={`/widget/create/${this.props.newInstanceId}`}>
                  create new widget
                </a>
                &nbsp;first.
              </div>
            }
          />
        </div>
        <PaginationStyled
          onChange={p => this.setState({page: p, data: null})}
          current={page}
          pageSize={PAGE_SIZE}
          total={total}
          hideOnSinglePage={false}/>
        <div className="actions">
          <Btn type="reset" onClick={e => this.props.onCancel()}>Cancel</Btn>
        </div>
      </VariantSelectorWrapper>
    );
  }

  renderWidgetAlgorithm(d, onlyString) {
    const allAlgorithms = [...algorithms, ...adAlgorithms];
    if(d.cxenseWidget) {
      return onlyString ? 'Cxense' : <span className="tag algo">Cxense</span>;
    }
    else if(d.thinkWidget) {
      return onlyString ? 'Think Analytics' : <span className="tag algo">Think Analytics</span>;
    } else {
      const {layoutParams = {}} = d.layoutConfig || {};
      let usedSlots = _.uniq(Object.values(layoutParams.slotGroupMapping || {}));
      let usedSlotGroup = (d.slotGroups || []).filter(s => usedSlots.indexOf(s.name) >= 0);
      let algos = _.uniq(usedSlotGroup.map(s => s.algorithm));
      let backfill = _.uniq(_.compact(usedSlotGroup.map(s => (s.backfill || {}).algorithm)))[0];
      if(algos.length > 1) {
        return onlyString ? 'Hybrid' : <span className="tag algo">Hybrid</span>
      }
      return algos.map(algo => {
        let meta = allAlgorithms.find(d => d.key === algo);
        let algoLabel = meta ? meta.label : algo;
        let backfillmeta = allAlgorithms.find(d => d.key === backfill);
        if(onlyString) {
          return algoLabel
        } else {
          return (
            <>
              <span className="tag algo" key={algo}>{algoLabel}</span>
              {!!backfill && <span className="tag backfill" key={backfill}>Fallback: <strong>{backfillmeta ? backfillmeta.label : backfill}</strong></span>}
            </>
          )
        }
      });
    }
  }

  showPreview(d) {
    const {appState: {session}} = this.props;
    const siteOption = ((session || {}).sites || []).find(s => s.key === d.site);

    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      width: '650px',
      hideCancel: true,
      hideOK: true,
      dialogBody: (
        <div style={{maxHeight: '80vh', overflow: 'auto', marginBottom: '20px'}}>
          <PreviewBox id={d.id} site={d.site} siteOption={siteOption}/>
        </div>
      ),
      onConfirm: () => {
        CondirmDialog.closeAll('global-confirm2');
      },
      onCancel: () => {
        CondirmDialog.closeAll('global-confirm2');
      }
    }

    ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById('global-confirm2'));
  }

  isItemSelected(item) {
    const {variants = []} = this.props;
    const index = variants.findIndex(d => d.id === item.id);
    return index > -1;
  }

  addVariant(d) {
    const {variants = []} = this.props;
    const {id, name, description} = d;
    if(this.props.withFullInfo) {
      this.props.onAdd(d);
    } else {
      this.props.onAdd({
        isControl: variants.length === 0,
        id,
        name,
        description,
        algorithm: this.getWidgetAlgorithm(d),
        tags: d.tags
      });
    }
  }

  getWidgetAlgorithm(d) {
    if(d.cxenseWidget) {
      return 'cxense';
    }
    else if(d.thinkWidget) {
      return 'Think Analytics';
    } else {
      const {layoutParams = {}} = d.layoutConfig || {};
      let usedSlots = _.uniq(Object.values(layoutParams.slotGroupMapping || {}));
      let algos = _.uniq((d.slotGroups || []).filter(s => usedSlots.indexOf(s.name) >= 0).map(s => s.algorithm));
      if(algos.length > 1) {
        return null;
      }
      return algos[0];
    }
  }
})
