import React, {Component} from 'react';
import moment from 'moment';
import {findDOMNode} from 'react-dom';
import io from 'socket.io-client';
import _ from 'lodash';
import {withConsumer} from '../app/ApplicationContext';
import {NotificationWrapper, NotificationMenu, NotificationToggler, MenuItem, EmptyBlock} from './notification.style'
import Btn from "../uikit/btn/index";
import {DarkGray1} from "../app/StyleCommon";

let cachedSocket = null;
let cacheInstance = null;
let cachedListener = null;

export default withConsumer(class extends Component {
  constructor(props) {
    super(props);
    this.state = {active: false};
    const {listerner, appState: {session = {}} = {}} = props
    this.socket = cachedSocket;
    cachedListener = listerner || cachedListener;
    if (!cachedSocket) {
      this.socket = cachedSocket = io({
        transports: ['websocket'],
        query: {
          userId: session.userId,
          userName: session.name
        }
      });
      this.socket.on('notification', data => {
        try {
          let caller = cacheInstance || this;
          caller.setState({messages: data});
        } catch (e) {
          //ignore it;
        }

      });
      this.socket.on('msg', msg => {
        cachedListener && cachedListener(msg);
      });
      // this.socket.on('reconnect_attempt', () => {
      //   this.socket.io.opts.transports = ['websocket', 'polling'];
      // });
    }
    this.socket.emit('fetch')
    cacheInstance = this;
  }

  cleanNotification() {
    this.socket.emit('clear');
    this.setState({active: false})
  }

  readNotification(ids) {
    this.socket.emit('read', ids);
  }

  deleteNotification(id) {
    this.socket.emit('delete', id);
  }

  render() {
    const {className} = this.props;
    const {messages = []} = this.state;
    const newMessages = (messages || []).filter(m => m.isNew === 'YES');
    return (
      <NotificationWrapper className={className}>
        <NotificationToggler
          className="toggler"
          active={this.state.active}
          onClick={e => this.handleToggle(e)}>
          <i className="fa fa-bell-o" aria-hidden="true"></i>
          {
            !!newMessages.length &&
            <span className="count">{newMessages.length >= 10 ? '10+' : newMessages.length}</span>
          }
        </NotificationToggler>
        <NotificationMenu className="menu" active={this.state.active}>
          <h3>
            Notifications
            {
              !!messages.length &&
              <Btn onClick={e => this.cleanNotification()}>Clear</Btn>
            }

          </h3>
          {
            !messages.length &&
            <EmptyBlock>
              <div>
                {/*<img src={`/img/misc/jingle.gif`} style={{width: '60px'}}/>*/}
                <br/>
                <span style={{color: DarkGray1}}>All caught up!</span>
              </div>
            </EmptyBlock>
          }
          {messages.map(m => (
            <MenuItem isNew={m.isNew === 'YES'} key={m.id}>
              <div className="noti-header">
                {
                  m.type.indexOf('Failed') >= 0 &&
                  <i className="fa fa-close" aria-hidden="true"></i>
                }
                {
                  m.type.indexOf('Failed') < 0 &&
                  <i className="fa fa-check" aria-hidden="true"></i>
                }
                {m.type}
              </div>
              <div className="noti-body">
                <a href={m.message.href} onClick={e => {
                  if (m.message.href) {
                    this.beforeClose();
                  }
                }}>
                  <span dangerouslySetInnerHTML={{__html: m.message.title}}/></a>
              </div>
              <div className="noti-footer">
                {/*<span>by <strong>{m.userName || m.userId}</strong></span> */}
                <span></span>
                <span>{moment().from(moment(m.date))}</span>
              </div>
              <a className="del" onClick={e => this.deleteNotification(m.id)}>x</a>
            </MenuItem>
          ))}
        </NotificationMenu>
      </NotificationWrapper>
    );
  }

  handleToggle(e) {
    const {active} = this.state;
    //when switch from active to hidden, clear 'isNew' message mark;
    if (active) {
      this.beforeClose();
    }
    this.setState({active: !active});
  }

  beforeClose() {
    const {messages = []} = this.state;
    const newMessages = (messages || []).filter(m => m.isNew === 'YES');
    if (newMessages.length) {
      this.readNotification(newMessages.map(m => m.id))
    }
  }

  handleClickOutside(event) {
    let {active} = this.state;
    if (active) {
      try {
        const self = findDOMNode(this)
        if (!self || !self.contains(event.target)) {
          this.beforeClose();
          this.setState({active: false});
        }
      } catch (e) {
        //happens when the dom is already destroyed
        console.error(e);
      }
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside.bind(this), true); // capture phase
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside.bind(this), true);
  }
})