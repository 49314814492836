import React from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import {withConsumer} from '../app/ApplicationContext';
import {withRouter} from 'react-router';
import moment from 'moment';
import * as d3 from 'd3';
import {Redirect, Link} from 'react-router-dom';
import {ProgressBar, Btn, Table, CondirmDialog, TabContainer, Loader, TableScrollWrapper} from '../uikit/index';
import MainLayout from '../layout/index';
import {HomeWrapper} from './home.style';
import {post} from "../utils/request";
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import qs from "querystring";
import {Grass1} from "../app/StyleCommon";
import {genSessionId, genSSO} from "./meidGenerator";


const detailWidgets = [
  {id: '8s8yGq_fY5', label: 'You May Also Like'},
  // {id: 'vU8SO_YF8T', label: 'End of Play'},
]
const endWidgets = {id: 'vU8SO_YF8T', label: 'End of Play'};

const allWidgets = [...detailWidgets, endWidgets];

const deviceTypes = [
  {key: '101', label: 'Desktop Web'},
  {key: '102', label: 'Mobile Web'},
  {key: '103', label: 'Android App'},
  {key: '104', label: 'IOS App'}
]

export default withRouter(withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    let deviceType = Number(localStorage.getItem('deviceType'));
    if (!deviceType) {
      deviceType = 101;
      localStorage.setItem('deviceType', deviceType);
    }
    this.state = {
      playing: false,
      paused: false,
      stopped: false,
    }
  }

  buildQuery() {
    const {deviceType = 101} = this.state;
    let sessionId = localStorage.getItem('sessionId');
    let userId = localStorage.getItem('userId');
    const urlParams = qs.parse(window.location.search.slice(1));
    const item = JSON.parse(decodeURIComponent(urlParams.item));
    return {userId, sessionId, deviceType, content_id: item.contentItemId, widgetIds: detailWidgets.map(d => d.id)};
  }

  render() {
    const {appState: {session}} = this.props;
    const {data, error, playing, paused, stopped, flash, endOfPlayData} = this.state;
    let sessionId = localStorage.getItem('sessionId');
    let userId = localStorage.getItem('userId');
    const urlParams = qs.parse(window.location.search.slice(1));
    const item = JSON.parse(decodeURIComponent(urlParams.item));
    let selectedEpisode = this.state.selectedEpisode || item;
    return (
      <MainLayout activeItem="simulator">
        {
          !data && !error &&
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/simulate/mewatch/simulatePage`}
            params={this.buildQuery()}
            successHandler={data => this.setState({data: data})}
            errorHandler={e => this.setState({error: e.message})}/>
        }
        {
          !!error && <ErrorMessage message={error}></ErrorMessage>
        }
        {
          !!data &&
          <HomeWrapper>

            <div style={{padding: '10px', background: '#f1f2f3', display: 'flex', justifyContent: 'space-between'}}>
              <div>sessionId: {sessionId}, userid: {userId}</div>
              <span>
              {
                !userId &&
                <button onClick={e => {
                  localStorage.setItem('userId', genSSO(sessionId));
                  this.setState({data: null});
                }}>Login SSO</button>
              }
              {
                !!userId &&
                <button onClick={e => {
                  localStorage.removeItem('userId');
                  this.setState({data: null});
                }}>Login Out</button>
              }
              <button onClick={e => {
                localStorage.setItem('sessionId', genSessionId());
                localStorage.removeItem('userId');
                this.setState({data: null});
              }}>Reset New User</button>
            </span>
            </div>
            <div>
              <h3>MeWATCH Details Page simulator</h3>
              <p><a href="/simulate/home">&lt;&lt;Back to Mewatch Home</a></p>
              <div>
                Context Content: <br/><img src={decodeURIComponent(item.imageUrl)} style={{width: '96px', height: '56px'}}/>
                Content ID: {item.contentItemId}

                <button style={{margin: '20px 10px'}} onClick={e => this.triggerFeedback(item, 'bookmark')}>Bookmark</button>
                <button style={{margin: '20px 10px'}} onClick={e => this.triggerFeedback(item, 'on_boarding')}>On Board</button>
                <button style={{margin: '20px 10px'}} onClick={e => this.triggerFeedback(item, 'purchase')}>Purchase</button>
                <button style={{margin: '20px 10px'}} onClick={e => this.triggerFeedback(item, 'download')}>Download</button>
              </div>
              <hr/>
              {
                !!flash && <div style={{color: Grass1, margin: '10px'}}>{flash}</div>
              }
              <div style={{padding: '10px', background: "#f1f2f3"}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <h3>Video Player</h3>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <img src={selectedEpisode.imageUrl} style={{width: '35px', height: '24px'}}/>
                    &nbsp;Current Video Player ID: {selectedEpisode.contentItemId}
                  </div>

                  {!stopped && !playing && <button onClick={e => this.triggerFeedback(selectedEpisode || item, 'start_play')}>Play Video</button>}
                  {!stopped && !!playing &&
                    <div>
                      <button onClick={e => this.triggerFeedback(selectedEpisode || item, 'stop_play', 15)}>Pause Play (15 seconds)</button>
                      <button onClick={e => this.triggerFeedback(selectedEpisode || item, 'stop_play', 30)}>Pause Play (30 seconds)</button>
                      <button onClick={e => this.triggerFeedback(selectedEpisode || item, 'stop_play', 60)}>Pause Play (60 seconds)</button>
                      <button onClick={e => this.triggerFeedback(selectedEpisode || item, 'stop_play', 180)}>Pause Play (3 minutes)</button>
                      <button onClick={e => this.triggerFeedback(selectedEpisode || item, 'stop_play', 300)}>Pause Play (5 minutes)</button>
                      <button onClick={e => this.triggerFeedback(selectedEpisode || item, 'stop_play', 600)}>Pause Play (10 minutes)</button>
                      <button onClick={e => this.triggerFeedback(selectedEpisode || item, 'stop_play', 1200)}>Pause Play (20 minutes)</button>
                      <button onClick={e => this.triggerFeedback(selectedEpisode || item, 'stop_play', 1800)}>Pause Play (30 minutes)</button>
                      <button onClick={e => this.triggerFeedback(selectedEpisode || item, 'stop_play', 3600)}>Pause Play (60 minutes)</button>
                    </div>
                  }
                  {
                    !stopped && (!!playing || !!paused) &&
                    <button style={{margin: '20px 10px'}} onClick={e => this.triggerFeedback(selectedEpisode || item, 'end_play', 3600)}>End Play (60 minutes)</button>
                  }
                  {
                    !!stopped &&
                    <ProgressBar
                      withoutCache={true}
                      fixcenter={true}
                      url={`/api/simulate/mewatch/simulatePage`}
                      params={{...(this.buildQuery()), widgetIds: [endWidgets.id]}}
                      successHandler={endOfPlayData => {
                        this.setState({endOfPlayData: endOfPlayData});
                      }}
                      errorHandler={e => this.setState({error: e.message})}/>
                  }
                </div>
                {Object.keys((endOfPlayData ||{}).data || {}).map(widgetId => {
                  const widgetData = endOfPlayData.data[widgetId];
                  const d = allWidgets.find(d => d.id === widgetId);
                  const recommendation = ((widgetData.recommendationsResult || {}).recommendations || {}).recommendation || [];
                  return (
                    <div style={{margin: '10px 0'}} key={d.id}>
                      <h4>{d.label} ({d.id})</h4>
                      <div>source_id: {widgetData.source_id}</div>
                      <div>refUsecase: {widgetData.refUsecase}</div>
                      {
                        !!data.meta && !!data.meta.url &&
                        <div>{data.meta.url}</div>
                      }
                      <div style={{display: 'flex'}}>
                        {
                          recommendation.map(item => {
                            return (
                              <a key={item.contentItemId} onClick={e => this.triggerClickFeedback(item, widgetData.source_id, widgetData.refUsecase)} style={{margin: '5px', cursor: 'pointer'}}>
                                <img src={item.imageUrl} style={{width: '128px', height: '56px'}}/>
                                <div>{item.contentItemId}</div>
                              </a>
                            )
                          })
                        }
                      </div>
                      {
                        !!widgetData.message && <ErrorMessage message={widgetData.message}></ErrorMessage>
                      }
                    </div>
                  )
                })}
              </div>
            </div>
            <h3>Episodes</h3>
            <ul style={{maxHeight: '250px', overflow: 'auto'}}>
              {
                ((data.meta || {}).episodes || []).map(episode => {
                  return (
                    <li key={episode.content_id} onClick={e => this.setState({selectedEpisode: {
                        "contentItemId": episode.content_id,
                        "contentSourceId": episode.content_id,
                        "imageUrl": (episode.images[0] ||{}).thumbnail,
                      }})}>
                      <img src={(episode.images[0] || {}).thumbnail} style={{width: '128px', height: '56px'}}/>
                      <div>{episode.title} {episode.content_id}</div>
                      <div>{episode.abstract}</div>
                    </li>
                  )
                })
              }
            </ul>
            <h3>Widgets</h3>
            {Object.keys(data.data || {}).map(widgetId => {
              const widgetData = data.data[widgetId];
              const d = allWidgets.find(d => d.id === widgetId);
              const recommendation = ((widgetData.recommendationsResult || {}).recommendations || {}).recommendation || [];
              return (
                <div style={{margin: '10px 0 20px 0', background: '#f3f5f7', padding: '5px', borderRadius: '5px'}} key={d.id}>
                  <h4>{d.label} ({d.id})</h4>
                  <h4>source_id: {widgetData.source_id}</h4>
                  {
                    !!data.meta && !!data.meta.url &&
                    <div>{data.meta.url}</div>
                  }
                  <div style={{display: 'flex'}}>
                    {
                      recommendation.map(item => {
                        return (
                          <a key={item.contentItemId} onClick={e => this.triggerClickFeedback(item, widgetData.source_id, widgetData.refUsecase)} style={{margin: '5px', cursor: 'pointer'}}>
                            <img src={item.imageUrl} style={{width: '128px', height: '56px'}}/>
                            <div>{item.contentItemId}</div>
                          </a>
                        )
                      })
                    }
                  </div>
                  {
                    !!widgetData.message && <ErrorMessage message={widgetData.message}></ErrorMessage>
                  }
                </div>
              )
            })}
          </HomeWrapper>
        }
      </MainLayout>
    )
  }

  triggerFeedback(item, action, sessionDuration) {
    const urlParams = qs.parse(window.location.search.slice(1));
    let deviceType = localStorage.getItem('deviceType');
    let sessionId = localStorage.getItem('sessionId');
    let userId = localStorage.getItem('userId');
    this.setState({loading: true});
    post(`/api/v1/mewatch/learn/${action}?token=e6f6h28e26vbc8442b288eb6121d85b9a4&userId=${userId|| ''}&sessionId=${sessionId|| ''}`, {
      "countryCode": "SG",
      "deviceType": Number(deviceType),
      "age": 18,
      "actionTime": Math.round(Date.now() / 1000),
      "lastPosition": sessionDuration,
      "tz": "Asia/Singapore",
      "contentItemId": item.contentItemId,
      "contentSourceId": item.contentSourceId || 2,
      "contentItemInstanceId": item.contentItemId,
      "sessionDuration": sessionDuration,
      "source_id": urlParams.source_id ? decodeURIComponent(urlParams.source_id) : undefined,
      "refUsecase": urlParams.refUsecase ? decodeURIComponent(urlParams.refUsecase) : undefined,
    }).then(result => {
      if(action === 'start_play') {
        this.setState({playing: true, paused: false});
      }
      else if(action === 'stop_play') {
        this.setState({playing: false, paused: true});
      }
      else if(action === 'end_play') {
        this.setState({playing: false, paused: false, stopped: true});
      }
      this.setState({loading: false});
      this.setState({flash: `${action} feedback sent.`}, () => {
        setTimeout(() => {
          this.setState({flash: null});
        }, 5000)
      });
    }).catch(e => {
      this.setState({loading: false, flash: e.message});
    });
  }

  triggerClickFeedback(item, source_id, refUsecase) {
    let deviceType = Number(localStorage.getItem('deviceType'));
    let sessionId = localStorage.getItem('sessionId');
    let userId = localStorage.getItem('userId');
    post(`/api/v1/mewatch/learn/click?token=e6f6h28e26vbc8442b288eb6121d85b9a4&userId=${userId|| ''}&sessionId=${sessionId|| ''}`, {
      contentItemId: item.contentItemId,
      "countryCode": "SG",
      "deviceType": deviceType,
      "age": 18,
      "tz": "Asia/Singapore",
      "contentItemInstanceId": item.contentItemId,
      "contentSourceId": 2,
      "actionTime": Math.round(Date.now() / 1000),
      source_id,
      refUsecase,
    }).then(results => {
      console.log('click feedback sent');
    }).catch(e => {
      console.error(e);
    });
    this.props.history.push(`/simulate/details?source_id=${source_id}&refUsecase=${encodeURIComponent(refUsecase)}&item=${encodeURIComponent(JSON.stringify(item))}`);
    this.setState({
      playing: false,
      paused: false,
      stopped: false,
      endOfPlayData: null,
      data: null,
    })
  }
}))