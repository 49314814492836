import styled, {css} from 'styled-components';
import Select from '../uikit/selectbox/index';
import {BlueJeans, DarkGray, DarkGray1, LightBlue, LightGray, LightGray1, MediumGray} from "../app/StyleCommon";

export const SelectStyled = styled(Select)`
  ${props => props.extraStyle && css`
    ${props.extraStyle}
  `}
  .toggler {
    background-color: ${LightGray};
    border: 1px solid ${MediumGray}
    color: ${DarkGray1};
    filter: none;
    border-radius: 4px;
    padding: 7px 10px;
    padding-right: 30px;
    border-radius: 5px;
    font-size: 14px;
    width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all .5s ease;

    ${props => props.togglerTheme && css`
      background-color: ${props.togglerTheme} !important;
      color: white  !important;;
      span {
        color: white  !important;;
      }
    `}
    ${props => props.togglerMinWidth && css`
      min-width: ${props.togglerMinWidth}px;
    `}
    &:hover {
      background-color: ${LightGray1};
    }
    .default-label {
      color: ${DarkGray}
    }
    .prefix-label {
      margin-right: 10px;
      font-weight: bold;
    }
  }
  &.segment-option-list .menu {
    max-height: 450px;
    overflow: auto;
  }
  .menu {
    top: 35px;
    left: 0;
    right: 0;
    min-width: auto;
    border: 1px solid ${MediumGray};
    ${props => props.up && css`
      transform: translate(0, -100%) translate(0, -30px);
      box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.16);
    `}
    label {
      white-space: normal;
      &.selected {
         background: white;
        color: ${BlueJeans};
        font-weight: bold;
      }
      &:hover {
        background: ${LightGray1};
      }
      font-weight: normal;
      input[type=radio] {
        margin: 0 5px;
      }
    }
  }
`
