import React from 'react';

import styled from 'styled-components';

export const GuideWrapper = styled.div`
  margin: 30px 30px;
  h2 {
    margin: 60px 0 10px 0;
  }
  h3 {
    margin: 30px 0 8px 0;
  }
  pre {
    font-size: 12px;
    span {
      background: transparent !important;
    }
  }
  .react-syntax-highlighter-line-number {
    display: block;
    text-align: right;
    color: rgba(0,0,0,.5);
    margin-right: 15px;
  }
`;

export const GuideSection = styled.div`
`;