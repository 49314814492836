import styled, {css} from 'styled-components';

import {
  MediumGray, Grass, DarkGray, DarkGray1, Sunflower, BlueJeans, MediumGray1, LightGray, LightGray1,
  BitterSweet
} from '../app/StyleCommon';

export const AreaWrapper = styled.div`
  .hero {
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .num {
      margin-right: 5px;
      font-weight: bold;
      &.blue {
        color: rgba(87, 170, 249, 1);
      }
      &.green {
        color: rgba(54, 175, 122, 1);
      }
      &.red {
        color: ${BitterSweet};
      }
    }

  }
  svg {
    width: 100%;
    .axis--x {
      text {
        color: ${DarkGray1} !important;
      }
      path, line {
        stroke: ${MediumGray1};
      }
      .tick {
        line {
          opacity: 0;
        }
        &:last-child line{
          opacity: 0 !important;
        }
        &:nth-child(15n +1) line{
          opacity: 1;
        }
      }
    }
    .axis--y {
      text {
        color: ${DarkGray1} !important;
      }
      path {
        fill: none;
        stroke: none;
      }
      line {
        stroke-width: 1px;
        stroke: ${LightGray1};
        stroke-dasharray: 5, 8;
      }
      .tick {
        &:first-child line{
          stroke-dasharray: none;
        }
      }
    }
  }
`;

export const TooltipWrapper = styled.div`
  font-size: 12px;
  min-width: 150px;
  .time {
    margin: 5px 10px;
  }
  .metricList {
    white-space: pre-wrap;
    .metric {
      white-space: nowrap;
      margin: 5px 10px;
      display: block;
      i {
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
        width: 20px;
      }
      .icon {
        width: 10px;
        height: 10px;
        display: inline-block;
        margin-right: 5px;
        &.gray {
          background: ${MediumGray};
        }
        &.yellow {
          background: ${Sunflower};
        }
        &.green {
          background: ${Grass};
        }
      }
    }
  }
`;