import React from 'react';
import {MeWatchButtonGroupWrapper} from './custom-slick-arrow.style';

export const MeWatchButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const { carouselState: { currentSlide, containerWidth, totalItems, slidesToShow } } = rest;
  return (
    <MeWatchButtonGroupWrapper containerWidth={containerWidth}>
      {currentSlide !== 0 &&
        <div
          className="arrow arrow-left react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
          onClick={() => previous()}>
            <div className="arrow-left-pointer"></div>
        </div>
      }
      {totalItems > slidesToShow && currentSlide !== (totalItems - slidesToShow) &&
        <div
          className="arrow arrow-right react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
          onClick={() => next()}>
            <div className="arrow-right-pointer"></div>
        </div>
      }
    </MeWatchButtonGroupWrapper>
  );
};
