import React from 'react'
import ReactDOM from 'react-dom';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import moment from 'moment';
import * as d3 from 'd3';
import {withConsumer} from '../app/ApplicationContext';
import {TagManagerWrapper} from './tagManager.style';
import {Btn, CondirmDialog} from '../uikit/index';
import {SelectStyled} from './select.style';
import RichEditor from '../uikit/editor/index';

export default withConsumer(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: props.tags,
      description: props.description,
      dropdownKey: Date.now(),
      rawTagMap: {

      }
    };
  }

  render() {
    const {tags = [], rawTagMap, description, dropdownKey} = this.state;
    let tagProcessed = tags.map(d => _.zipObject(['type', 'value'], d.split('::')));
    let tagGrouped = _.groupBy(tagProcessed, _.property('type'));
    tagGrouped = {
      project: [],
      platform: [],
      page: [],
      placement: [],
      others: [],
      ...tagGrouped
    }
    let typeLabelMap = {
      'project': 'Project',
      'platform': 'Platform',
      'page': 'Page',
      'placement': 'Placement',
      'others': 'Other Tags'
    }
    return (
      <TagManagerWrapper>
        <p><strong>Tags: </strong>You can categorize the widget with different kind of tags, this will help us to generate more insights with
          the traffic data. </p>
        <div className="tag-groups">
          {
            Object.keys(tagGrouped).map(type => {
              const currentTagRaw = rawTagMap[type];
              const newTag = `${type}::${currentTagRaw}`;
              const tagValid = /^[a-z0-9_-\s]+$/ig.test(currentTagRaw);
              return (
                <div key={type} className="tag-list-group" autoComplete="on">
                  <label>
                    <span>{typeLabelMap[type]}: </span>
                    {
                      ['project', 'platform', 'page'].indexOf(type) >= 0 &&
                      <SelectStyled
                        defaultLabel=""
                        inputable={true}
                        plainStyle={true}
                        key={type + dropdownKey}
                        selected={currentTagRaw}
                        data={({
                          project: ['TEST', 'BETA', 'PROD'],
                          platform: ['Web', 'App'],
                          page: ['Home Page', 'Article Detail Page', 'Video Detail Page','Video Section Page', 'Video Synopsis Page'],
                        }[type]).map(v => ({key: v, label: v}))}
                        onChange={v => {
                          this.setState({rawTagMap: {
                              ...rawTagMap,
                              [type]: v
                            }})
                        }}/>
                    }
                    {
                      ['project', 'platform', 'page'].indexOf(type) < 0 &&
                      <input
                        type="text"
                        className="tag-input"
                        value={currentTagRaw} onChange={e => {
                        this.setState({rawTagMap: {
                            ...rawTagMap,
                            [type]: e.target.value
                          }})
                      }}/>
                    }


                    <Btn
                      disabled={!currentTagRaw || tags.indexOf(newTag) >= 0 || !tagValid}
                      onClick={e => {
                        this.setState({
                          tags: [...tags, newTag],
                          dropdownKey: Date.now(),
                          rawTagMap: {
                            ...rawTagMap,
                            [type]: undefined
                          }});
                      }}>Add</Btn>
                  </label>
                  {
                    (currentTagRaw && !tagValid) &&
                    <div className="error">Tag name can only contain letters, numbers, space and underscores</div>
                  }
                  <div className="tag-list">
                    {!tagGrouped[type].length &&
                    <div className="no-data">
                      No tags yet
                    </div>
                    }
                    {tagGrouped[type].map(d => {
                      return (
                        <div key={d.value} className={`tag ${type}`}>
                          <span>{d.value}</span>
                          <i className="fa fa-times" onClick={e => {
                            let newTags = tags.filter(t => t !== `${type}::${d.value}`);
                            this.setState({tags: newTags});
                          }}/>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })
          }
        </div>

        <div className="desc-editor">
          <p><strong>Description: </strong></p>
          <RichEditor
            data={description}
            onChange={description =>{
              this.setState({description: description});
            }}/>
        </div>
        <div className="actions">
          <Btn type="reset" onClick={e => this.props.onCancel()}>Cancel</Btn>
          <Btn type="primary" onClick={e => {
            let types = ['project', 'platform', 'page'].filter(k => !!rawTagMap[k]);
            if(types.length) {
              let quit = window.confirm(`Do you want to ignore the selected ${types.join(', ')} tags (selected but not added to the taglist yet)?`);
              if(!quit) {
                return;
              }
            }
            this.props.onChange(tags, description)
          }}>OK</Btn>
        </div>
      </TagManagerWrapper>
    );
  }
})
