import styled from 'styled-components';
import {DarkGray1, LightGray1, MediumGray, MediumGray1} from "../../app/StyleCommon";


export const HintWrapper = styled.div`
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  position: relative;
  //transform: translateX(0);
  margin: auto 5px;
  cursor: help;
  &.bright-hint {
    &.lightAnker .hint-anker {
      font-size: 11px;
    }
    .hint-popup {
      background: white;
      border: 1px solid ${LightGray1};
      color: #555;
      &:after {
        color: white;
      }
    }
  }
  .hint-anker {
    color: #777;
    font-size: 14px;
    display: inline-flex;
    span {
      font-weight: bold;
      margin-left: 5px;
      margin-right: 5px;
      font-size: 18px;
    }
    i {
      vertical-align: middle;
    }
  }
  &:hover .hint-popup{
    opacity: 1;
    pointer-events: inherit;
  }
`;

export const HintPopup = styled.div`
  height: auto;
  text-align: left;
  display: block;
  transition: all 0.2s ease;
  pointer-events: none;
  opacity: 0;
  position: absolute;
  border: 1px solid #434a54;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: ${DarkGray1};
  color: white;
  font-size: 12px;
  transform: translate(-50%, -100%) scale(1);
  top: 0;
  left: 50%;
  z-index: 10000;
  box-shadow: 0px 1px 5px rgba(255,255,255, .1), 0px 1px 10px rgba(255,255,255, 0.05);
  max-height: 600px;
  overflow: auto;
  white-space: normal !important;
  overflow: visible;
  font-size: 12px;
  @media (max-width: 667px) {
    position: fixed;
    left: 10px !important;
    right: 10px !important;
    width: auto !important;
    &:after {
      display: none !important;
    }
  }
  ul {
    list-style: circle;
  }
  h5 {
    margin: 0;
  }
  &:after {
    position: absolute;
    display: block;
    content: '▼';
    color: #434a54;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 60%);
    vertical-align: top;
  }
  &.righttop {
    top: 50%;
    right: 0;
    transform: translate(15px, -20px) scale(1);
    &:after {
      top: 20px;
      bottom: auto;
      right: auto;
      left: -8px;
      transform: translate(0, -50%) rotate(90deg);
    }
  }
  &.lefttop {
    top: 50%;
    left: -10px;
    transform: translate(-100%, -10px) scale(1);
    &:after {
      top: 20px;
      bottom: auto;
      right: auto;
      left: 100%;
      transform: translate(-2px, -50%) rotate(-90deg);
    }
  }
  &.left {
    top: 50%;
    left: 0;
    transform: translate(-100%, -50%) scale(1);
    &:after {
      top: 50%;
      bottom: auto;
      left: auto;
      right: -8px;
      transform: translate(0, -50%) rotate(-90deg);
    }
  }
  &.bottom {
    transform: translate(-50%, 100%) scale(1);
    top: auto;
    bottom: 0;
    left: 50%;
    &:after {
      top: 0;
      bottom: auto;
      transform: translate(-50%, -60%) rotate(180deg);
    }
  }
  &.right {
    top: 50%;
    left: 100%;
    transform: translate(10px, -50%) scale(1);
    &:after {
      top: 50%;
      left: -8px;
      bottom: auto;
      height: auto;
      transform: translate(0, -50%) rotate(90deg);
    }
  }
`;