import React from 'react';
import styled, {css} from 'styled-components';

const SvgWrapper = styled.svg`
  path {
  }

`

export default props => {
  return (
    <SvgWrapper xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M19,6.184V6c0-1.657-1.343-3-3-3s-3,1.343-3,3h-2c0-1.657-1.343-3-3-3S5,4.343,5,6v0.184C3.839,6.599,3,7.698,3,9v9  c0,1.654,1.346,3,3,3h12c1.654,0,3-1.346,3-3V9C21,7.698,20.161,6.599,19,6.184z M15,6c0-0.552,0.447-1,1-1s1,0.448,1,1v2  c0,0.552-0.447,1-1,1s-1-0.448-1-1V6z M7,6c0-0.552,0.447-1,1-1s1,0.448,1,1v2c0,0.552-0.447,1-1,1S7,8.552,7,8V6z M19,18  c0,0.551-0.448,1-1,1H6c-0.552,0-1-0.449-1-1v-6h14V18z"/>
    </SvgWrapper>
  )
}
