import React from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import _ from 'lodash';
import moment from 'moment';

import {withConsumer} from '../app/ApplicationContext';
import {post} from '../utils/request';
import {ProgressBar, DateSelectorDropdown, Btn, CondirmDialog, Transition} from '../uikit/index';
import {TableScrollWrapper} from '../uikit/index';
import MainLayout from '../layout/index';
import {StyledDropdown} from '../uikit/dropdown/styled';
import {ABDetailsWrapper, ABOverview, ABBasicInfo, ABMetricsOverview, KeymetricCard} from './details.style';
// import {processAbTestingDistData} from './details-misc';
// import Timeseries from './timeseries';
import ErrorLogPanel from '../widgets/errorLogPanel';
import InsightExplorer from '../insights/index';
import DeploymentCode from './deployment-code';
import VariantSummery from './variant-summery';
import EngineSummery from './variant-summery-engine';
import TargetSummery from './target-summery';
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import {algorithms, datePeriodWindows, metrics} from '../utils/metadata';
import {GridContainer} from '../uikit/grid/index.style';
import Sparkline from '../dashboard/sparkline';
import {dashboardCards, dashboardCardsMewatch, timeseriesDimensions} from './index.metadata';
import {SelectInlineStyled} from '../dashboard/index.style';
import Dashboardimeseries from "../dashboard/timeseries";
import ClickLogPanel from "../widgets/clickLogPanel";
import {DarkGray} from "../app/StyleCommon";
import HistogramTimeseries from "../dashboard/histogram";
import MeWatchHistogramTimeseries from "../dashboard/histogram.mewatch";
import MewatchDashboard from "../dashboard/index.mewatch";

export default withRouter(withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    this.id = this.props.computedMatch.params.id;
    this.state = {
      period: 'last30days',
      timeseriesDimension: 'variant',
    };
  }

  buildInsightQuery() {
    const abtestingId = this.props.computedMatch.params.id;
    return {...(_.omit(this.state, ['error', 'loading', 'data', 'runningStatus'])), id: abtestingId};
  }

  render() {
    const {readOnly, appState: {session = {}} = {}} = this.props;
    const {data, error, loading, period} = this.state;
    const {timeseriesDimension, runningStatus} = this.state;
    const {isAdmin, isOperator} = (session || {});
    const {
      abtesting,
      keyMetrics = {},
      variantsComparison,
      histogramBreakDown,
      deployedDate,
      variantMap,
      widgets = [],
      mewatchEngineReport,
      mewatchVariantReport,
      mewatchHistogramReport,
    } = data || {};
    const {
      id, status, name, site, description, targeting = [], schedule, creator,
      CreatedDateTime, UpdatedDateTime, variants = [], isEngineSticky
    } = abtesting || {};
    const {start = '', end = ''} = schedule || {};
    let dashboardCardsFiltered = isAdmin ? dashboardCards : dashboardCards.filter(d => d.key !== 'errorsStat');
    const isMewatchTAIntegration = ['mewatch', 'preprod_mewatch'].includes(site) && isEngineSticky;
    if(isMewatchTAIntegration) {
      dashboardCardsFiltered = dashboardCardsMewatch;
    }
    const tags = _.uniq(_.flatten(variants.map(v => {
      let widget = widgets.find(w => w.id === v.id) || {};
      return widget.tags || [];
    })));
    if(variantMap) {
      Object.keys(variantMap).forEach(id => {
        let widget = widgets.find(w => w.id === id) || {};
        variantMap[id].tags = widget.tags || [];
        variantMap[id].algorithm = this.getWidgetAlgorithm(widget);
      })
    }
    return (
      <MainLayout activeItem="abtesting" breadcrumb={[
        {path: '/abtesting', label: 'A/B Test'},
        {path: `/abtesting/${this.id}`, label: 'A/B Test Details'},
      ]}>
        {
          !data && !error &&
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/queryABTestingDetailsNew`}
            params={this.buildInsightQuery()}
            successHandler={data => this.setState({data: data})}
            errorHandler={e => this.setState({error: e.message})}/>
        }
        {
          !!error &&
          <ABDetailsWrapper>
            <ErrorMessage message={error}></ErrorMessage>
          </ABDetailsWrapper>
        }
        {
          !!data &&
          <ABDetailsWrapper>
            <div className="top">
              <ABBasicInfo>
                <div className="header">
                  <div className="title">
                    <img className="site" src={`/img/property/${site}.png`}/>
                    <div className="name">
                      <div>
                        <h3>{name}</h3>
                        <Btn size="small" type="link" disabled={!isAdmin && !isOperator}>
                          <Link to={`/abtesting/edit/${id}`}><i className="fa fa-edit"/></Link>
                        </Btn>
                      </div>
                      <p>
                        <div
                          dangerouslySetInnerHTML={{__html: description || ''}}
                        />
                      </p>
                    </div>
                  </div>
                  <div className="filters">
                    <DateSelectorDropdown
                      mode='range'
                      placeholder='Select Period'
                      period={period}
                      historyDateAllowed={true}
                      shortcuts={datePeriodWindows}
                      onDateRangeUpdated={period => this.setState({period, data: null})}/>
                    <StyledDropdown
                      className="actions" toggler={<i className="fa fa-ellipsis-v"/>}>
                      <div className={`menu-item ${!isAdmin ? 'disabled' : ''}`}>
                        <a onClick={e => this.duplicateABTesting(abtesting)}><i className="fa fa-copy"/>Duplicate</a>
                      </div>
                      <div className={`menu-item ${!isAdmin ? 'disabled' : ''}`}>
                        <Link to={`/abtesting/edit/${id}`}><i className="fa fa-pencil-square-o"/>Edit</Link>
                      </div>
                      <div className={`menu-item ${!isAdmin ? 'disabled' : ''}`}>
                        <a><i className="fa fa-stop-circle"/>Disable</a>
                      </div>
                      <div className={`menu-item ${!isAdmin ? 'disabled' : ''}`}>
                        <a onClick={() => this.deleteABTesting(abtesting)}><i className="fa fa-trash"/>Delete</a>
                      </div>
                      <div className="menu-item">
                        <Link to={`/abtesting/history/${id}`}><i className="fa fa-clock-o"/>Revision History</Link>
                      </div>
                    </StyledDropdown>
                  </div>
                </div>
              </ABBasicInfo>
            </div>
            <ABOverview>
              <div className="details-item">
                <div>
                  {
                    !!CreatedDateTime &&
                    <span>Created by {creator} at {moment(CreatedDateTime).format('DD-MMM-YYYY hh:mm A')}</span>
                  }
                  {
                    !!UpdatedDateTime && UpdatedDateTime!== CreatedDateTime &&
                    <span>, Last modified at {moment(UpdatedDateTime).format('DD-MMM-YYYY hh:mm A')}</span>
                  }

                  <Link to={`/abtesting/history/${id}`}>
                    &nbsp;&nbsp;&nbsp;<i className="fa fa-clock-o"></i>&nbsp; Show all revisions <i
                    className="fa fa-angle-double-right"/>
                  </Link>
                </div>
              </div>
              <div style={{borderTop: '1px solid #dbdfe3', padding: '20px 0', fontWeight: 'bold', color: DarkGray}}>
                <label className="table-title">Key Metrics</label>
              </div>
              <div className="details-item">
                <ABMetricsOverview className="metrics">
                  <GridContainer type={'flex'} flex={true}>
                    {!!data && dashboardCardsFiltered.map(({formatter, key, label, color, icon}) => {
                      let keyMetricData = keyMetrics[key];
                      let value = keyMetricData;
                      let trending = null;
                      if (keyMetricData && typeof keyMetricData === 'object') {
                        value = keyMetricData.value;
                        trending = keyMetricData.data;
                      }
                      if(!session.isAdmin && key === 'errorRateStat') {
                        return null;
                      } else if(session.isAdmin && key === 'clickStat') {
                        return null;
                      }
                      return (
                        <KeymetricCard
                          className={`card ${key}`}
                          key={key + value}
                          type={color}
                          onClick={e => {
                            this.showDeeperInsights(key, 'both');
                          }}>
                          <div className="top">
                            <i className={icon} aria-hidden="true"></i>
                            <span>{label}</span>
                          </div>
                          <div className="bottom">
                            <h3><Transition>{formatter(value)}</Transition></h3>
                            <div className="rate">
                              <Sparkline data={trending} formatter={formatter} size="small"/>
                            </div>
                          </div>
                        </KeymetricCard>
                      )
                    })}
                  </GridContainer>
                </ABMetricsOverview>
              </div>
              <div className="details-item">
                <ABMetricsOverview className="metrics">
                  {
                    !!variantsComparison &&
                    <VariantSummery
                      abtesting={abtesting}
                      widgets={widgets}
                      period={period}
                      showBreakdown={session.isAdmin}
                      showTimeout={session.isAdmin}
                      data={isMewatchTAIntegration ? mewatchEngineReport : variantsComparison}>
                    </VariantSummery>
                  }
                  {
                    !!mewatchEngineReport &&
                    <EngineSummery
                      showDeeperInsights={this.showDeeperInsights.bind(this)}
                      abtesting={abtesting}
                      widgets={widgets}
                      period={period}
                      showBreakdown={session.isAdmin}
                      showTimeout={session.isAdmin}
                      data={mewatchEngineReport}
                      mewatchVariantReport={mewatchVariantReport}
                    >
                    </EngineSummery>
                  }

                </ABMetricsOverview>
              </div>
              {
                !!mewatchEngineReport &&
                <div style={{textAlign: 'right', margin: '-10px 20px 0 0'}}>
                  <a href={`/abtesting/${abtesting.id}`} onClick={e => {
                  e.preventDefault();
                  this.showDeeperInsights('impressions', 'both');
                }}>&gt;&gt;More breakdown</a>
                </div>
              }
              {(!!histogramBreakDown || !!mewatchHistogramReport) &&
                <div className="timeseries">
                  <div style={{alignSelf: 'flex-start', marginBottom: '20px'}}>Traffic Histogram</div>
                  <div>
                    {
                      !isMewatchTAIntegration &&
                      <HistogramTimeseries
                        showPerformanceBreakdown={true}
                        labelMap={variantMap}
                        key={histogramBreakDown.dimension + histogramBreakDown.timestamp}
                        dimension={histogramBreakDown.dimension}
                        data={histogramBreakDown}/>
                    }
                    {
                      !!isMewatchTAIntegration &&
                      <MeWatchHistogramTimeseries
                        showPerformanceBreakdown={true}
                        labelMap={variantMap}
                        key={(mewatchHistogramReport || {}).dimension + (mewatchHistogramReport || {}).timestamp}
                        dimension={(mewatchHistogramReport || {}).dimension}
                        availableMetrics={[
                          'impressions', 'clicks', 'video_starts','video_completions',
                          'watch_time',
                          'ctr', 'vpr', 'vcr'
                        ]}
                        data={mewatchHistogramReport}/>
                    }

                  </div>
                </div>
              }
            </ABOverview>
          </ABDetailsWrapper>
        }
      </MainLayout>
    )
  }

  showDeployment(abtesting) {
    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      title: 'Deployment Code',
      width: '800px',
      dialogBody: (
        <div style={{maxHeight: '80vh', overflow: 'auto', marginBottom: '20px'}}>
          <DeploymentCode abtesting={abtesting}/>
        </div>
      ),
      onConfirm: () => {
        CondirmDialog.closeAll();
      },
      onCancel: () => {
        CondirmDialog.closeAll();
      }
    }

    ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById('global-confirm'));
  }

  duplicateABTesting(d) {
    this.setState({loading: true})
    return post('/api/duplicateABTesting', {id: d.id}).then(newABTesting => {
      this.setState({loading: false});
      this.props.history.push(`/abtesting/create/${newABTesting.id}?clone=y`);
    }).catch(e => {
      this.setState({loading: false});
      console.error(e);
    })
  }

  deleteABTesting(d) {
    CondirmDialog.showConfirm(`Do you really want to delete A/B test ${d.name || d.id}?`, () => {
      this.setState({loading: true})
      return post('/api/deleteABTesting', {id: d.id}).then(results => {
        this.setState({loading: false});
        this.props.history.push(`/abtesting`);
      }).catch(e => {
        this.setState({loading: false});
        console.error(e);
      })
    });
  }

  getWidgetAlgorithm(d) {
    if(d.cxenseWidget) {
      return 'Cxense';
    }
    else if(d.thinkWidget) {
      return 'Think Analytics';
    } else {
      const {layoutParams = {}} = d.layoutConfig || {};
      let usedSlots = _.uniq(Object.values(layoutParams.slotGroupMapping || {}));
      let algos = _.uniq((d.slotGroups || []).filter(s => usedSlots.indexOf(s.name) >= 0).map(s => s.algorithm));
      if(algos.length > 1) {
        return 'Hybrid'
      }
      let meta = algorithms.find(d => d.key === algos[0]);
      return meta ? meta.label : algos[0];
    }
  }

  showErrorLogs() {
    const {data, period} = this.state;

    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      title: <strong>Error Log</strong>,
      width: '950px',
      hideCancel: true,
      hideOK: true,
      onCancel: () => CondirmDialog.closeAll(),
      dialogBody: <ErrorLogPanel
        type='abtesting'
        id={data.abtesting.id}
        period={period}
        onConfirm={() => null}
        onCancel={() => {
          CondirmDialog.closeAll();
        }}
      />,
    }
    ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById('global-confirm'));
  }


  showDeeperInsights(metric, engine) {
    const {data, period} = this.state;
    const {abtesting} = data || {};
    const metricKey = metric.replace('Stat', '');
    const metricMeta = metrics.find(m => m.key === metricKey);
    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      title: <strong>{metricMeta.label} Insights</strong>,
      width: engine === 'both' ? '1024px' : '1000px',
      hideCancel: true,
      hideOK: true,
      onCancel: () => CondirmDialog.closeAll(),
      dialogBody: (
        <div style={{overflow: 'auto', maxHeight: '80vh'}}>
          <InsightExplorer
            period={period}
            type='abtesting'
            abtesting={abtesting}
            metric={metricKey}
            engine={engine}
            onConfirm={() => null}
            onCancel={() => {
              CondirmDialog.closeAll();
            }}
          />
        </div>
      )
    }
    ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById('global-confirm'));
  }
}))
