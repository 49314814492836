import React, {Component} from 'react';
import {SortableWrapper, SortableWrapperMap} from './index.style';

export default class extends Component {

  static get defaultProps() {
    return {
      sortableSelector: '.sortable-item',
      onReplacePosition: (keyFrom, keyTo) => {
      },
      placeholderClasses: ['sortable-item-hovering']
    };
  }

  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.dragStart = this._dragStart.bind(this);
    this.dragOver = this._dragOver.bind(this);
    this.dragEnd = this._dragEnd.bind(this);
  }

  render() {
    const {disabled} = this.props;
    if (disabled) {
      return <div>{this.props.children}</div>
    }
    const Wrapper = SortableWrapperMap[this.props.tag || 'div'];
    // const Wrapper = SortableWrapper(this.props.tag || 'div');
    return (
      <Wrapper ref={this.wrapperRef} className={`sortable ${this.props.className}`}>
        {this.props.children}
      </Wrapper>
    );
  }

  _applyDragEvents(item, action) {
    let handle = item;
    if (typeof this.props.handleSelector !== 'undefined') {
      handle = item.querySelector(this.props.handleSelector);
    }
    handle.draggable = true;
    handle[action + 'EventListener']('dragstart', this.dragStart);
    item[action + 'EventListener']('dragover', this.dragOver);
    item[action + 'EventListener']('dragend', this.dragEnd);
  }

  componentDidMount() {
    const {disabled} = this.props;
    if (!disabled) {
      this.applyIfDraggableChild(sortableItem => this._applyDragEvents(sortableItem, 'add'));
    }

  }

  componentDidUpdate() {
    const {disabled} = this.props;
    if (!disabled) {
      this.applyIfDraggableChild(sortableItem => {
        this._applyDragEvents(sortableItem, 'remove');
        this._applyDragEvents(sortableItem, 'add');
      });
    }
  }

  componentWillUnmount() {
    const {disabled} = this.props;
    if (!disabled) {
      this.applyIfDraggableChild(sortableItem => this._applyDragEvents(sortableItem, 'remove'));
    }
  }

  applyIfDraggableChild(action) {
    const $sortable = this.wrapperRef.current;
    if ($sortable) {
      const ignoreElements = Array.from($sortable.querySelectorAll(`:scope ${this.props.sortableSelector} ${this.props.sortableSelector}`));
      Array.from($sortable.querySelectorAll(this.props.sortableSelector)).forEach(sortableItem => {
        if (ignoreElements.indexOf(sortableItem) === -1) {
          action(sortableItem);
        }
      });
    }
  }

  _dragStart(e) {
    const thisDOM = this.wrapperRef.current;
    const elem = this.findNearestParent(e.currentTarget, thisDOM, this.props.sortableSelector);

    this.constructor.GLOBAL_DRAGGABLE = this.dragged = elem;
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', e.currentTarget);
  }

  _dragEnd(e) {
    if (!this.over) {
      return;
    }
    this.over.classList.remove(...this.props.placeholderClasses);
    // Update state
    const parentNode = this.dragged.parentNode;
    const fromIndex = Array.prototype.indexOf.call(parentNode.childNodes, this.dragged);
    const toIndex = Array.prototype.indexOf.call(parentNode.childNodes, this.over);
    if (fromIndex !== toIndex) {
      const type = this.dragged.dataset[this.props.acceptCheck];
      let relevantNodes = Array.from(parentNode.childNodes);
      if (this.props.acceptCheck) {
        relevantNodes = relevantNodes.filter(node => node.dataset[this.props.acceptCheck] === type);
      }
      const relateToIndex = relevantNodes.indexOf(this.over);
      const relateFromIndex = relevantNodes.indexOf(this.dragged);
      if (fromIndex > toIndex) {
        parentNode.insertBefore(this.dragged, this.over);
      } else if (fromIndex < toIndex) {
        parentNode.insertBefore(this.dragged, this.over.nextSibling);
      }
      if (this.props.acceptCheck) {
        this.props.onReplacePosition(relateFromIndex, relateToIndex, type);
      } else {
        this.props.onReplacePosition(fromIndex, relateToIndex);
      }
    }
    delete this.constructor.GLOBAL_DRAGGABLE;
  }

  _dragOver(e) {
    const thisDOM = this.wrapperRef.current;
    const elem = this.findNearestParent(e.currentTarget, thisDOM, this.props.sortableSelector);

    if (!thisDOM.contains(this.constructor.GLOBAL_DRAGGABLE) ||
      thisDOM.contains(this.findNearestParent(this.constructor.GLOBAL_DRAGGABLE, thisDOM, '.sortable'))) {
      return false;
    } else if (this.props.acceptCheck) {
      let targetType = elem.dataset[this.props.acceptCheck];
      let sourceType = this.constructor.GLOBAL_DRAGGABLE.dataset[this.props.acceptCheck];
      if (targetType !== sourceType) {
        return false;
      }
    }

    if (this.over && this.over === elem) return;

    if (this.over && this.over.matches(this.props.sortableSelector)) {
      this.over.classList.remove(...this.props.placeholderClasses);
    }
    this.over = elem;
    this.over.classList.add(...this.props.placeholderClasses);
  }

  findNearestParent(elem, upperLimit, selector) {
    do {
      if (elem.matches(selector)) return elem;
      elem = elem.parentNode;
    } while (elem && elem !== upperLimit);
  }
};
