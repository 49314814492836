import {facetMeta} from '../utils/metadata';

const dimensionOptions = facetMeta.filter(d => !d.disabledInCustomRule);

const numberOpOptions = [
  {key: 'eq', label: 'equal'},
  {key: 'ne', label: 'not equal'},
  {key: 'gt', label: 'more than'},
  {key: 'lt', label: 'less than'},
  {key: 'ge', label: 'at least'},
  {key: 'le', label: 'at most'},
]

export default [
  {
    type: 'Item Limiter',
    key: 'item_count_limiter',
    description: 'Recommended ${op} ${value} items from ${dimension_value} ${dimension}',
    singleton: false,
    parameters: {
      dimension_value: {
        type: 'string',
        default_value: '',
        label: 'singapore',
        multiple: true,
        keyParameter: 'dimension',
        options: [{key: 'same', label: 'The same'}],
      },
      dimension: {
        type: 'string',
        default_value: 'section',
        label: 'Attribute',
        valueParameter: 'dimension_value',
        options: dimensionOptions
      },
      op: {
        type: 'string',
        default_value: 'gt',
        label: 'Operator',
        options: numberOpOptions
      },
      value: {
        type: 'integer',
        min: 0,
        default_value: 1
      },
    }
  },
  {
    type: 'Item Limiter',
    key: 'context_item_count_limiter',
    description: 'Recommended ${op} ${value} items which is the same ${dimension} as current content',
    singleton: false,
    parameters: {
      dimension: {
        type: 'string',
        default_value: 'section',
        label: 'Attribute',
        options: dimensionOptions
      },
      op: {
        type: 'string',
        default_value: 'gt',
        label: 'Operator',
        options: numberOpOptions
      },
      value: {
        type: 'integer',
        min: 0,
        default_value: 1
      },
    }
  },
  {
    type: 'Metric Filter',
    key: 'traffic_metric_filter',
    singleton: false,
    description: 'Recommend items with ${metric} ${op} ${value}',
    parameters: {
      'metric': {
        type: 'string',
        default_value: 'view_count',
        label: 'Traffic Metric',
        options: [
          {key: 'view_count', label: 'Page Views'},
          {key: 'view_count_by_source/social', label: 'Social Page Views'},
        ]
      },
      'op': {
        label: 'Operator',
        type: 'string',
        default_value: 'gt',
        options: numberOpOptions
      },
      'value': {
        label: 'Metric Value',
        type: 'integer',
        default_value: 100,
        min: 0
      }
    }
  },
  {
    type: 'Metric Filter',
    key: 'percentile_metric_filter',
    singleton: false,
    description: 'Filter out items with ${value} ${metric}',
    parameters: {
      'metric': {
        type: 'string',
        default_value: 'avg_time_spent_on_page',
        label: 'Metric',
        options: [
          {key: 'avg_time_spent_on_page', label: 'Dwell Time'},
          {key: 'velocity', label: 'Velocity'},
          {key: 'concurrents', label: 'Concurrents'},
        ]
      },
      'value': {
        type: 'string',
        default_value: 'low',
        label: 'Attribute value',
        options: [
          {key: 'low', label: 'Low'},
          {key: 'medium', label: 'Medium'},
          {key: 'high', label: 'High'},
        ]
      },
    }
  },
  {
    type: 'Item Filter',
    key: 'publish_date_filter',
    singleton: false,
    description: 'Exclude items published in last ${value} ${unit}',
    parameters: {
      'unit': {
        type: 'string',
        default_value: 'hour',
        label: 'Unit',
        options: [
          {key: 'minute', label: 'Minute'},
          {key: 'hour', label: 'Hour'},
          {key: 'day', label: 'Day'},
          {key: 'month', label: 'Month'},
          {key: 'year', label: 'Year'},
        ]
      },
      'value': {
        type: 'integer',
        default_value: 24,
        min: 0,
        label: 'Value',
      },
    }
  },
  {
    type: 'Item Filter',
    key: 'item_dimension_filter',
    singleton: false,
    description: 'Filter out items from ${dimension_value} ${dimension}',
    parameters: {
      dimension_value: {
        type: 'string',
        default_value: '',
        label: 'singapore',
        multiple: true,
        keyParameter: 'dimension',
        options: []
      },
      dimension: {
        type: 'string',
        default_value: 'section',
        label: 'Attribute',
        valueParameter: 'dimension_value',
        options: dimensionOptions
      },
    }
  },
  {
    type: 'Item Filter',
    key: 'consumed_content_filter',
    singleton: true,
    description: 'Filter out ${content_types} that user have already read.',
    parameters: {
      content_types: {
        type: 'string',
        default_value: '',
        label: 'articles',
        multiple: true,
        options: [{key: 'article', label: 'Article'}, {key: 'video', label: 'Video'}]
      },
    }
  },
  {
    type: 'Boost Function',
    key: 'tag_weight_boost',
    singleton: true,
    description: 'Give ${op} weightage on ${dimension_value} ${dimension}',
    parameters: {
      'op': {
        type: 'string',
        default_value: 'higher',
        label: 'Weightage',
        options: [
          {key: 'higher', label: 'higher'},
          {key: 'lower', label: 'lower'},
        ]
      },
      dimension_value: {
        type: 'string',
        default_value: '',
        label: 'singapore',
        multiple: true,
        keyParameter: 'dimension',
        options: []
      },
      dimension: {
        type: 'string',
        default_value: 'section',
        label: 'Attribute',
        options: dimensionOptions
      },
    }
  },
  {
    type: 'Boost Function',
    key: 'context_weight_boost',
    singleton: true,
    description: 'Give ${op} weightage on the same ${dimension} as current content',
    parameters: {
      'op': {
        type: 'string',
        default_value: 'higher',
        label: 'Weightage',
        options: [
          {key: 'higher', label: 'higher'},
          {key: 'lower', label: 'lower'},
        ]
      },
      dimension: {
        type: 'string',
        default_value: 'section',
        label: 'Attribute',
        options: dimensionOptions
      },
    }
  },
  {
    type: 'Boost Function',
    key: 'freshness_weight_boost',
    singleton: true,
    description: 'Give ${op} weightage to fresh contents',
    parameters: {
      'op': {
        type: 'string',
        default_value: 'higher',
        label: 'Weightage',
        options: [
          {key: 'higher', label: 'higher'},
          {key: 'lower', label: 'lower'},
        ]
      }
    }
  }
]