import React from 'react';
import ReactDOM from 'react-dom';
import * as d3 from 'd3';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import {TableScrollWrapper} from '../uikit/index';
import {withConsumer} from '../app/ApplicationContext';
import VariantPreview from '../widgets/previewBox';
import {Table, CondirmDialog} from '../uikit/index';
import {BarWrapper, SelectInlineStyled} from "../dashboard/index.style";
import {
  filterTags,
  intFormatter,
  pct2Formatter,
  pctFormatter,
  responseTimeFormatter,
  uniquesFormatter
} from '../utils/formatter';
import ClickLogPanel from "../widgets/clickLogPanel";
import {algorithms, engineMap, metricMap, metrics} from "../utils/metadata";
import styled from "styled-components";
import Sparkline3 from "../dashboard/sparkline3";

const BreakdownTableWrapper = styled.table`
  width: 100%;
  text-align: center;
  min-width: 200px;
  table-layout: fixed;
  max-width: 350px;
  th {
    font-size: 11px !important;
    font-weight: normal !important;
    padding: 0 3px 0 0 !important;
  }
  td{
    border: none !important;
    text-align: left !important;
    padding: 2px 3px 2px 0 !important;
    font-size: 11px !important;
    word-break: break-all;
  }
  tr:not(:last-child) td{
    border-bottom: 1px dashed #d1d2d3 !important;
  }
  tr td:first-child{
    text-align: left;
  }
`
export default withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {mode: 'engine', sortBy: 'impressions', sortDirection: 'desc'};
  }

  render() {
    const {appState: {session}} = this.props;
    const isAdmin = (session || {}).isAdmin;
    const {mode} = this.state;
    const {abtesting, data, widgets, showTimeout, showBreakdown, mewatchVariantReport} = this.props;
    const {status, variants, site, isEngineSticky} = abtesting || {};
    const {sortBy, sortDirection} = this.state;
    const reportData = mode === 'engine' ? data : mewatchVariantReport;

    let {items = [], maxImpressions} = reportData || {};
    let sortedItems = _.sortBy(items, d => {
      return d[sortBy] || 0;
    });
    if(sortDirection === 'desc') {
      sortedItems = sortedItems.reverse();
    }
    if(mode === 'variant') {
      sortedItems = sortedItems.filter(d => !!d.widget_name);
    }

    variants.forEach(d => {
      d.engine = d.algorithm === "Think Analytics" ? 'ta' : 'boost'
    })
    return (
      <TableScrollWrapper>
        <Table
          columns={[
            {
              key: 'name',
              label: (
                <SelectInlineStyled
                  useFixed={true}
                  selected={mode}
                  data={[
                    {key: 'engine', label: 'Engine'},
                    {key: 'variant', label: 'Variant'},
                  ]}
                  onChange={newMode => {
                    this.setState({mode: newMode})
                  }}/>
              ),
              align: 'left',
              renderer: (d, index) => {
                if(mode === 'variant') {
                  return (
                    <Link to={`/widget/${d.widget_id}`} className="widget-name-card">
                      <div>{d.widget_name || d.widget_id}</div>
                      <div className="tag-list">
                        {filterTags(d.tags || [], 'widget', isAdmin).map(t => {
                          return <span className={`tag ${t.split('::')[0]}`} key={t}>{t.split('::').pop()}</span>
                        })}
                        {(!d.tags || !d.tags.length) && '--'}
                        {isAdmin && <span className={'tag'}>{this.renderWidgetAlgorithmMewatch(d.algorithm)}</span>}
                      </div>
                    </Link>
                  )
                }
                let engineVariants = variants.filter(v => v.engine === (d.engine || 'boost'));
                return (
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    {d.engine === 'ta' && <img className="publisher" src={`/img/property/${d.engine}.png`} style={{width: '24px'}}></img>}
                    {d.engine !== 'ta' && <img className="publisher" src={`/img/property/boost.png`} style={{width: '36px'}}></img>}
                    <span style={{marginRight: '8px'}}></span>
                    <div>
                      {engineMap[d.engine]}
                      {/*{engineVariants.map(v => {*/}
                      {/*  return <div key={v.id} ><a href={`/widget/${v.id}`} style={{marginRight: '5px'}}>{v.name}</a></div>;*/}
                      {/*})}*/}
                    </div>
                  </div>
                )
              }
            },
            ...(['impressions', 'ctr', 'video_starts','vcr', 'watch_time', 'uvv'].map(metric => {
              return {
                key: metric,
                label: metricMap[metric],
                align: 'left',
                // style: {minWidth: '100px'},
                sortable: true,
                renderer: d => {
                  let max = _.max(items.map(item => item[metric] || 0));
                  let metricMeta = metrics.find(m => m.key === metric);
                  let desc = {
                    ctr: `${intFormatter(d.clicks)} Clicks`,
                    vpr: `${intFormatter(d.video_starts)} Video Plays`,
                    vcr: `${intFormatter(d.video_completions)} Video Completions`,
                    uvv: `${intFormatter(Math.round(d.uv))} Avg daily UV`
                  }[metric];
                  return (
                    <BarWrapper className="small clickable" onClick={e => {
                      mode === 'engine' && this.props.showDeeperInsights(metric, d.engine);
                    }}>
                      <div className="num">{metricMeta.formatter(d[metric])}</div>
                      {
                        !!d[metric] &&
                        <div className="bar"
                             style={{width: pctFormatter(d[metric] / max)}}></div>
                      }
                      {
                        !!desc &&
                        <div className="desc">{desc}</div>
                      }
                    </BarWrapper>
                  )
                }
              }
            })),
            ...(mode !== 'variant' ? [] : [
              {
                key: 'breakdown',
                hidden: !isAdmin,
                label: (
                  <div>
                    <div>Model Breakdown</div>
                    <BreakdownTableWrapper>
                      <tr>
                        <th colSpan="3" >Model</th>
                        <th>Coverage</th>
                        <th>CTR</th>
                      </tr>
                    </BreakdownTableWrapper>
                  </div>
                ),
                align: 'left',
                colSpan: 3,
                style: {paddingLeft: '0'},
                renderer: d => {
                  return (
                    <BreakdownTableWrapper>
                      {_.sortBy((d.breakdown || []), r => r.impressions).reverse().filter(r => r.coverage).map(r => {
                        return (
                          <tr key={r.algorithm} style={{fontSize: '11px', color: '#535353'}}>
                            <td colSpan="3" >{r.algorithm}</td>
                            <td title={r.coverage}>{pctFormatter(r.coverage)}</td>
                            <td title={r.ctr}>{pctFormatter(r.ctr)}</td>
                          </tr>
                        )
                      })}
                    </BreakdownTableWrapper>
                  )
                }
              }
            ])

          ]}
          rows={sortedItems}
          sort={{sortColumn: sortBy, direction: sortDirection}}
          sortChange={orderBy => {
            this.setState({sortBy: orderBy, page: 1})
          }}
          directionChange={direction => {
            this.setState({sortDirection: direction, page: 1})
          }}
          noDataText={"No Data"}
        />
      </TableScrollWrapper>
    );
  }
  renderWidgetAlgorithmMewatch(algorithm) {
    let meta = algorithms.find(d => d.key === algorithm);
    return meta ? meta.label : algorithm
  }

  renderWidgetAlgorithm(d) {
    if (d.cxenseWidget) {
      return <span className="tag algo">Cxense</span>;
    }
    else if (d.thinkWidget) {
      return <span className="tag algo">Think Analytics</span>;
    } else {
      const {layoutParams = {}} = d.layoutConfig || {};
      let usedSlots = _.uniq(Object.values(layoutParams.slotGroupMapping || {}));
      let usedSlotGroup = (d.slotGroups || []).filter(s => usedSlots.indexOf(s.name) >= 0);
      let algos = _.uniq(usedSlotGroup.map(s => s.algorithm));
      let backfill = _.uniq(_.compact(usedSlotGroup.map(s => (s.backfill || {}).algorithm)))[0];
      if (algos.length > 1) {
        return <span className="tag algo">Hybrid</span>
      } else if (algos.length === 1) {
        let algo = algos[0];
        let meta = algorithms.find(d => d.key === algo);
        let backfillmeta = algorithms.find(d => d.key === backfill);
        return (
          <>
            <span className="tag algo" key={algo}>{meta ? meta.label : algo}</span>
            {!!backfill && <span className="tag backfill"
                                 key={backfill}>Fallback: <strong>{backfillmeta ? backfillmeta.label : backfill}</strong></span>}
          </>
        )
      }
    }
  }

  showPreviewPopup(widgetId) {
    const {abtesting} = this.props;
    const {appState: {session}} = this.props;
    const siteOption = session.sites.find(s => s.key === abtesting.site);
    const confirmInfo = {
      type: 'form',
      backgroundClose: false,
      width: '650px',
      hideCancel: true,
      hideOK: true,
      dialogBody: <VariantPreview id={widgetId} site={abtesting.site} siteOption={siteOption}/>,
      onConfirm: () => {
        CondirmDialog.closeAll();
      },
      onCancel: () => {
        CondirmDialog.closeAll();
      }
    }

    ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById('global-confirm'));
  }

  showClickDetails(d) {
    const {period} = this.props;
    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      title: <strong>Click Logs for {d.name}</strong>,
      width: '1000px',
      hideCancel: true,
      hideOK: true,
      onCancel: () => CondirmDialog.closeAll(),
      dialogBody: <ClickLogPanel
        type='widget'
        id={d.id}
        period={period}
        onConfirm={() => null}
        onCancel={() => {
          CondirmDialog.closeAll();
        }}
      />,
    }
    ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById('global-confirm'));
  }
})
