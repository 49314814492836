import styled, {css} from 'styled-components';
import {
  DropdownZIndex,
  FontSizeBig,
  FontSizeMedium,
  LightGray,
  LightGray1,
  MediumGray,
  BlueJeans, DarkGray, BlueJeans1, FontSizeSmall, DarkGray1, MediumGray1, BitterSweet, Grass, Grass1, Grass0, LightBlue
} from "../../app/StyleCommon";

export const SelectWrapper = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
`;

export const SelectToggler = styled.div`
  position: relative;
  cursor: pointer;
  padding: 5px 10px;
  padding-right: 40px;
  min-width: 200px;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${FontSizeMedium};
  white-space: nowrap;
  background-color: transparent;
  img.icon {
    width: 16px;
    vertical-align: middle;
    margin-right: 10px;
  }
  ${props => props.unSelectable && css`
     &&&{
      padding-right: 60px;
     }
  `}
  ${props => props.disabled && css`
    cursor: not-allowed;
    opacity: .5;
    > i.caret {
      display: none;
    }
  `}
  ${props => !props.disabled && css`
    &:hover {
      background: ${MediumGray};
    }
  `}
  &.has-clear-btn {
    position: relative;
    &:before {
      display: block;
      content: '';
      right: 0px;
      top: 0;
      bottom: 0;
      position: absolute;
      right: 0;
      background-color: white;
      width: 45px;
      opacity: .9;
      
    }
  }
  .default-label {
    font-size: 14px;
    color: ${MediumGray1};
    display: inline-block;
    transform: translate(3px, 0);
  }
  .search-input {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border: none;
    opacity: 0;
    padding: 0 10px;
    outline: none;
    font-size: 12px;
    color: black;
    &::placeholder {
      font-size: 14px;
      color: ${MediumGray1};
      line-height: 1.5em; 
    }
    &:focus {
      opacity: 1;
    }
  }
  > i {
    margin-right: 5px;
    font-size: 12px;
  }
  > i.caret {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translate(0, -50%);
    font-size: ${FontSizeMedium};
  }
  > i.clear-all {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 12px;
    font-weight: 300;
  }
  
   background-color: ${LightGray};
    border: 1px solid ${MediumGray}
    color: ${DarkGray1};
    filter: none;
    border-radius: 4px;
    padding: 7px 10px;
    padding-right: 30px;
    border-radius: 5px;
    font-size: 14px;
    width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all .5s ease;
    &:before {
      background: transparent;
    }
    ${props => props.togglerTheme && css`
      background-color: ${props.togglerTheme} !important;
      color: white  !important;;
      span {
        color: white  !important;;
      }
    `}
    ${props => props.togglerMinWidth && css`
      min-width: ${props.togglerMinWidth}px;
    `}
    &:hover {
      background-color: ${LightGray1};
    }
    .default-label {
      color: ${DarkGray}
    }
    .prefix-label {
      margin-right: 10px;
      font-weight: bold;
    }
`;

export const SelectMenu = styled.div`
  position: absolute;
  top: 40px;
  left: 0px;
  right: 0;
  overflow: hidden;
  //transition: all .3s ease;
  opacity: 0;
  z-index: ${DropdownZIndex};
  min-width: 100px;
  //box-shadow: 0px 1px 3px rgba(0,0,0,.1);
  background-color: white;
  filter: drop-shadow(0 1px 5px rgba(0, 0, 0, .1));
  ${props => props.active && css`
     padding: 5px 0 0 0;
     opacity: 1;
  `}
  ${props => !props.active && css`
     pointer-events: none !important;
     display: none;
  `}
`

export const MenuItem = styled.div`
  padding: 2px 10px;
  cursor: pointer;
  display: block;
  font-size: 12px;
  color: ${DarkGray1};
  .menu-item-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    white-space: nowrap;
    > label {
      display: block;
      flex-grow: 1;
      margin-right: 20px;
      cursor: pointer;
      &:hover {
        color: ${BlueJeans1};
      }
    }
    .sub-trigger {
      padding: 5px 10px;
      cursor: pointer;
      &:hover {
        background: ${LightGray};
      }
      i {
      
      }
    }
    
  }
  .menu-item-children {
    max-height: 400px;
    overflow: auto;
    .sub-menu-item {
      padding-left: 15px;
      font-size: 11px;
      display: block;
      white-space: nowrap;
      &:hover {
        background: ${BlueJeans};
      }
    }
  }
  input {
    margin-right: 10px;
    vertical-align: middle;
    height: 20px;
  }
  
  ${props => props.disabled && css`
    opacity: .5;
    cursor: not-allowed;
  `}
`;

export const ActionsBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  border-top: 1px solid ${LightBlue};
  margin-top: 10px;
  a {
    display: block;
    width: 50%;
    padding: 5px;
    background: ${LightGray};
    text-align: center;
    color: ${BlueJeans};
    cursor:pointer;
    font-size: 12px;
    font-weight: bold;
    &:hover {
      background: ${LightGray1};
    }
    &:first-child {
      border-right: 1px solid ${LightBlue};
    }
    &.disabled {
      opacity: .5;
      pointer-events: none;
    }
  }
`;