import React from 'react';
import styled from 'styled-components';
import range from 'lodash/range';
import {layouts, itemStyles} from '../../utils/layouts';
import {duration} from './dep';
import {WidgetWrapper, WidgetBody, CarouselStyled, GlobalStyle} from './grid.style';
import VisibilitySensor from "react-visibility-sensor";
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import './zhcn'
import {DarkGray, LightGray1} from "../../app/StyleCommon";

import {CNA_Web_Home_MostPopular} from './CNA_Web_Home_MostPopular.style';
import {CNA_Web_Home_MostWatched} from './CNA_Web_Home_MostWatched.style';
import {CNA_Web_Video_RelatedVideos} from './CNA_Web_Video_RelatedVideos.style';
import {iPrice_DFP_300x250} from './iPrice_DFP_300x250.style';
import {iPrice_DFP_300x250_2} from './iPrice_DFP_300x250_2.style';
import {iPrice_DFP_300x250_default} from './iPrice_DFP_300x250_default.style';

import {WidgetWrapperCNA} from './grid.cna.style';
import {WidgetWrapper8World} from './grid.8world.style';
import {WidgetWrapperMewatch} from './grid.mewatch.style';
import {WidgetWrapperToday} from './grid.tdy.style';
import {WidgetWrapperCNALifestyle} from './grid.cnalifestyle.style';
import {WidgetWrapperCNALuxury} from './grid.cnaluxury.style';
import {WidgetWrapperBerita} from './grid.berita.style';
import {WidgetWrapperSeithi} from './grid.seithi.style';
import {WidgetWrapper8Days} from './grid.8days.style';

import {MeWatchButtonGroup} from './custom-slick-arrow';
import {getResponsiveOptions} from './responsive-options';
import ejs from "ejs";

dayjs.extend(localizedFormat).extend(relativeTime);

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mediaWidth: window.innerWidth,
      hasError: false
    };
    if(window.location.href.match(/\/widget\/(edit|create)/g)) {
      this.state.mediaWidth = window.innerWidth - 580;
    }
    this.wrapperRef = React.createRef();
    this.resizeHandler = this.resizeHandler.bind(this);
  }

  componentDidCatch(error, info) {
    this.setState({hasError: true});
    const {trackEvent} = window.recApp || {};
    const {widget, context} = this.props;
    if (trackEvent === 'function') {
      trackEvent(widget, context, 'error', {error, info});
    }
  }

  componentDidMount() {
    this.resizeHandler();
    window.addEventListener('resize', this.resizeHandler)
    window.previewReady = true;

    const {widget, context} = this.props;
    if (context && context.isWebView) {
      document.addEventListener('touchmove', function (event) {
        event = event.originalEvent || event;
        if (event.touches && event.touches.length !== 1) {
          event.preventDefault();
        }
      }, false);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler)
  }

  getWidgetWrapper(site, tags) {
    let specialStyle = [
      {tag: 'CNA_Web_Home_MostPopular', style: CNA_Web_Home_MostPopular},
      {tag: 'CNA_Web_Home_StoriesForYou', style: CNA_Web_Home_MostPopular},
      {tag: 'CNA_Web_Home_ElsewhereFromMediacorp', style: CNA_Web_Home_MostPopular},
      {tag: 'CNA_Web_Home_MostWatched', style: CNA_Web_Home_MostWatched},
      {tag: 'CNA_Web_Video_RelatedVideos', style: CNA_Web_Video_RelatedVideos},
      {tag: 'iPrice_DFP_300x250', style: iPrice_DFP_300x250},
      {tag: 'iPrice_DFP_300x250_2', style: iPrice_DFP_300x250_2},
      {tag: 'iPrice_DFP_300x250_default', style: iPrice_DFP_300x250_default},
      {tag: 'mewatch-carousel', style: WidgetWrapperMewatch},
    ].find(d => (tags || []).some(tag => tag.split('::').pop() === d.tag));
    if(specialStyle) {
      return specialStyle.style;
    }
    return {
      'cna': WidgetWrapperCNA,
      '8world': WidgetWrapper8World,
      'mewatch': WidgetWrapperMewatch,
      'tdy': WidgetWrapperToday,
      'cnalifestyle': WidgetWrapperCNALifestyle,
      'cnaluxury': WidgetWrapperCNALuxury,
      'berita': WidgetWrapperBerita,
      'seithi': WidgetWrapperSeithi,
      '8days': WidgetWrapper8Days,
    }[site] || WidgetWrapper;
  }

  render() {

    const {hideContent} = this.state;
    const {widget, context} = this.props;
    let {site, layoutConfig, items = [], ranking, cxenseWidget, thinkWidget, tags = []} = widget || {};

    if(layoutConfig && layoutConfig.tmpl) {
      let tmplCompiled = ejs.compile(layoutConfig.tmpl, {});
      return <div dangerouslySetInnerHTML={{__html: `
        <style>${layoutConfig.css}</style>
        ${tmplCompiled({...widget, items})}
      `}} />;
    }

    if (this.state.hasError) {
      return null;
    }


    const {autoRanking = false} = ranking || {};
    const {mediaWidth, visibilitySensorStop} = this.state;
    const {title, headers, itemStyle, layout, layoutParams, css, stickiness} = layoutConfig || {};
    const {row = 1, column = 1, slotGroupMapping = {}} = layoutParams || {};
    const layoutOptions = layouts.find(d => d.key === layout);
    const {main, thumbnail} = layoutOptions || {};
    const hasMain = !!main;
    // const Combiner = hasMain ? WidgetBody : React.Fragment;
    // const CombinerClass = hasMain ? {className: 'widget-body'} : {};
    const {isWebView, inWidgetBuilder} = context || {};

    if (context.debug && (items || []).length < widget.slotCount) {
      let newItems = [], candidates = [...items];
      const appendCandidateForPosition = slot_position => {
        let itemIndex = (autoRanking || cxenseWidget || thinkWidget) ? 0 : candidates.findIndex(d => d.slot_position === slot_position);
        if (itemIndex >= 0) {
          newItems.push(candidates[itemIndex]);
          candidates.splice(itemIndex, 1); //delete the one from existing array;
        } else {
          newItems.push({
            id: slot_position,
            isPlaceholder: true,
            thumbnail: `https://via.placeholder.com/120x80/CCD1D9/434A54?text=`,
            title: '',
            url: '#',
            // section: range(0, 10).map(i => '□').join(''),
            section: '....',
            section_label: '....',
            publish_date: '',
          })
        }
      }
      if (main) {
        appendCandidateForPosition('main');
      }
      for (var i = 1; i <= row; i++) {
        for (var j = 1; j <= column; j++) {
          appendCandidateForPosition(`p_${i}_${j}`);
        }
      }
      items = newItems.concat(candidates);
    }
    if (!items || !items.length) {
      return null;
    }

    const StyledWidgetWrapper = this.getWidgetWrapper(site, tags);
    return (
      <VisibilitySensor
        active={!visibilitySensorStop}
        partialVisibility={true}
        offset={{top: 50, bottom: 20}}
        scrollCheck={true}
        resizeCheck={true}
        delayedCall={true}
        onChange={isVisible => this.visibilityChange(isVisible)}>
        <StyledWidgetWrapper
          ref={this.wrapperRef}
          site={site}
          column={column}
          row={row}
          mediaWidth={mediaWidth}
          customStyle={css}
          className={`${layout} ${row}x${column}`}
          inWidgetBuilder={inWidgetBuilder}
          darkMode={!!context.dark_mode}
          stickiness={isWebView ? '' : stickiness}
          isWebView={isWebView}>
          {isWebView && <GlobalStyle/>}
          <div className={`mc-widget-wrapper cont-${mediaWidth} ${site}`}>
            {this.renderHeader()}
            {
              layout === 'single_row' &&
              <CarouselStyled
                arrows={false}
                // renderButtonGroupOutside={!isWebView}
                // centerMode={true}
                // partialVisbile={!!isWebView}
                swipeable={!!isWebView}
                draggable={!!isWebView}
                infinite={false}
                isWebView={!!isWebView}
                customTransition={`transform ${isWebView ? 1200 : 600}ms ease-out`}
                transitionDuration={isWebView ? 1200 : 600}
                minimumTouchDrag={30}
                customButtonGroup={isWebView ? null : <MeWatchButtonGroup/>}
                containerClass="item-list react-multi-carousel-list"
                sliderClass="item-list-slider react-multi-carousel-track"
                itemClass="item-wrapper"
                responsive={getResponsiveOptions(isWebView, Math.floor(mediaWidth / 200), column)}>
                {
                  items.slice(0, column).map((item, i) => this.renderItem(item, i))
                }
              </CarouselStyled>
            }
            {
              layout !== 'single_row' &&
              <WidgetBody className={`widget-body ${hideContent ? 'hide': 'show'}`}>
                {!!main && this.renderItem(items[0], 'main')}
                <div className="item-list">
                  {
                    items.slice(hasMain ? 1 : 0).slice(0, row * column).map((item, i) => this.renderItem(item, i))
                  }
                </div>
              </WidgetBody>
            }
            {this.renderFooter()}
          </div>
        </StyledWidgetWrapper>
      </VisibilitySensor>
    )
  }

  renderHeader() {
    const {widget, context} = this.props;
    const {hideContent} = this.state;
    const {layoutConfig, tags, items = []} = widget || {};
    const {title, headers} = layoutConfig || {};
    let headersCombined = (headers || []).filter(d => !!d.title);
    if (!headersCombined.length && !!title) {
      headersCombined = [{lang: 'en', title: title}];
    }
    if (!headersCombined.length) {
      return null;
    }
    let lang = context.lang || 'en';
    let selectedHeader = headersCombined.find(d => d.lang === lang);
    selectedHeader = selectedHeader || headersCombined[0];

    let headerHTML = selectedHeader.title;
    if(items[0] && tags && tags.find(t => t.indexOf('iPrice_DFP') >= 0)) {
      headerHTML = headerHTML.replace(/{{categoryImage}}/g, `<img src="${items[0].categoryImage}"/>`);
      headerHTML = headerHTML.replace(/{{categoryLabel}}/g, items[0].categoryLabel);
    }
    headerHTML = headerHTML.replace(/\{\{.*\}\}/g, '');

    return (
      <h3 className="widget-title">
        {!selectedHeader.titleLink && <span dangerouslySetInnerHTML={{__html: headerHTML}} />}
        {!!selectedHeader.titleLink && <a href={selectedHeader.titleLink}>{headerHTML}</a>}

        <a className={`hide-btn ${hideContent ? 'hide': 'show'}`} onClick={e => this.setState({hideContent: !hideContent})}>{hideContent ? 'Show' : 'Hide'}</a>
      </h3>
    )
  }

  renderFooter() {
    const {widget, context} = this.props;
    const {hideContent} = this.state;
    const {layoutConfig} = widget || {};
    let {footer} = layoutConfig || {};
    if (!footer) {
      return null;
    }
    if(context.env === 'dfp') {
      footer = footer.replace(/src=\"\/img\//g, `src="img/`);
      footer = footer.replace(/{{(clickTag\d*)}}/g, function (){return `javascript:void(window.open(${arguments[1]}))`});
    }
    return (
      <h3 className="widget-footer">
        <span dangerouslySetInnerHTML={{__html: footer}} />
      </h3>
    )
  }

  renderItem(d, decorator) {
    if (!d) {
      return null;
    }

    const {widget, context} = this.props;
    const {layoutConfig, tags = []} = widget || {};
    const {itemStyle} = layoutConfig || {};
    let tooltip = '', cloned;
    if (context && context.debug) {
      cloned = JSON.parse(JSON.stringify(d));
      // ['images', 'link', 'image', 'abstract', 'url', 'thumbnail'].forEach(k => {
      //   delete cloned[k];
      // })
      tooltip = JSON.stringify(cloned, null, 2);
    }
    let ipriceTag = tags.find(t => t.indexOf('iPrice_DFP_300x250_default') >= 0);
    if(!d.isPlaceholder && ipriceTag) {
      return this.renderIpriceItem(d, ipriceTag);
    }
    let openTarget = context.debug ? '_blank' : '_self';
    let itemTooltip = d.isPlaceholder ? undefined : window.itemTooltip;
    return (
      <div key={d.id}
           className={`item ${itemStyle} ${decorator || ''} ${d.isPlaceholder ? 'placeholder' : ''}`}
           title={itemTooltip ? undefined : tooltip}>
        <a className="item-inner"
           href={!d.isPlaceholder ? this.deepLink(d, decorator) : '#'}
           target={openTarget}
           onMouseOver={itemTooltip ? itemTooltip.onMouseOver(cloned) : undefined}
           onMouseLeave={itemTooltip ? itemTooltip.onMouseOut() : undefined}
           onClick={e => this.onItemClicked(e, d)}>
          {
            (itemStyle !== 'text_only' || decorator === 'main') && d.content_type !== 'sponsor_ads' &&
            <div className="thumbnail"
                 data-src={this.chooseThumbnail(d)}
                 style={{backgroundImage: `url(${CSS.escape(this.chooseThumbnail(d))})`}}>
              {
                d.thumbnail_type === 'video' &&
                // <div className="video-icon">{d.video_length}</div>
                <div className="video-icon"></div>
              }
            </div>
          }
          {
            (itemStyle !== 'text_only' || decorator === 'main') && d.content_type === 'sponsor_ads' &&
            <div className="thumbnail sponsor_ads"
                 data-src={this.chooseThumbnail(d)}
                 style={{backgroundImage: `url(${CSS.escape(this.chooseThumbnail(d))})`}}>
              <img src={this.chooseThumbnail(d)}/>
              {
                d.thumbnail_type === 'video' &&
                // <div className="video-icon">{d.video_length}</div>
                <div className="video-icon"></div>
              }
            </div>
          }
          <div className="brief">
            {
              !!d.section_label &&
              <div className="section" style={this.getSectionStyle(d.section, decorator)}>
                {!d.isPlaceholder ? d.section_label : range(0, 16).map(i => <span key={i}>&nbsp;</span>)}
              </div>
            }
            <div className="title">
              {!d.isPlaceholder ? d.title : range(0, 160).map(i => <span key={i}>&nbsp;</span>)}
              {d.site === 'iprice' && !!d.label && <span className="label-tag">{d.label}</span>}
              {
                !!tags && !!tags.find(t => t.indexOf('iPrice_DFP_300x250_2') >= 0) &&
                <a className="cta-button" href={d.redirectLink}>
                  Promot Code
                </a>
              }
            </div>
            <div className={`abstract ${d.abstract && decorator === 'main' ? '' : 'hidden'}`}>{d.abstract}</div>
            <div className="publish_date">
              {!d.isPlaceholder ? this.formatDate(d.publish_date) : range(0, 32).map(i => <span key={i}>&nbsp;</span>)}
            </div>
            <div className="site">
              {!d.isPlaceholder ? this.mapSite(d.site) : range(0, 16).map(i => <span key={i}>&nbsp;</span>)}
            </div>
            <div className="author">
              By {!d.isPlaceholder ? d.author : range(0, 16).map(i => <span key={i}>&nbsp;</span>)}
            </div>
            {
              d.content_type === 'sponsor_ads' &&
              <div className="sponsored_ads">
                Sponsored Ad
              </div>
            }

            {
              !!tags && !!tags.find(t => t.indexOf('iPrice_DFP_300x250') >= 0) &&
              <div className="expire">
                Expires on {dayjs(d.expiresAt).format('DD MMM YYYY')}
              </div>
            }
            {
              !!tags && !!tags.find(t => t.indexOf('iPrice_DFP_300x250') >= 0) &&
              <div className="store">
                by {d.store}
              </div>
            }
          </div>
        </a>
      </div>
    )
  }

  renderIpriceItem(d, tag) {
    const recApp = window.recApp || {};
    const {widget, context} = this.props;
    let logoImagePath = '/img/iprice.png';
    if(d.site === 'iprice' && context.env === 'dfp') {
      logoImagePath = 'img/iprice.png';
    }
    let deepLink = this.deepLink(d);
    return (
      <a className="link-wrapper" href={deepLink} target={'_blank'} onClick={e => {
        if(window.clickTag && window.clickTag !== deepLink) {
          e.preventDefault();
          window.open(window.clickTag);
        }
      }}>
      <div className="coupon"

           style={{backgroundImage: `url(${d.categoryImage1 || d.categoryImage})`}}>
        <div className="offerText">{d.offerText || 'Promo Code'}</div>
        <div className="category">{d.store} voucher on TODAYonline</div>
        <div className="details">
          <div className="title">
            <img className="store" src={d.storeImage}/>
            <span>{d.title}</span>
          </div>
          <div className="desc">{d.details}</div>
          <div className="cta-button" onClick={e => {
            e.preventDefault();
            window.open(window.clickTag2 || "https://vouchers.todayonline.com/");
          }}>
            More vouchers on TODAYonline &gt;&gt;
          </div>
          <span className="iprice-cpr">
            <span>powered by iprice</span>
          </span>
        </div>
      </div>
      </a>
    );
  }

  formatDate(date) {
    if (!date) {
      return '';
    }
    const {widget, context} = this.props;
    if (widget.site === '8world') {
      let dateMnt = dayjs(date);
      let diff = dayjs().diff(dateMnt, 'hour');
      if (diff < 24) {
        // return dayjs().locale('zh-cn').from(dateMnt);
        return dateMnt.locale('zh-cn').fromNow();
      } else {
        return dateMnt.locale('zh-cn').format('lll');
      }
    } else if (widget.site === 'tdy') {
      return dayjs(date).locale('en').format('DD MMMM');
    } else {
      return dayjs(date).locale('en').fromNow();
      // return dayjs().locale('en').from(dayjs(date));
    }
  }

  chooseThumbnail(d) {
    let url = (d.site !== 'tdy') ? (d.thumbnail || d.image) : (d.image || d.thumbnail);
    return (url || '/img/default-thumbnail.png').replace(/'/g, "%27");
  }
  mapSite(site) {
    return {
      cna: 'CNA',
      '8world': '8 World',
      tdy: 'TODAYonline',
      mewatch: 'meWATCH',
      merewards: 'meREWARDS',
      '8days': '8 Days'
    }[site] || site;
  }

  resizeHandler() {
    try {
      const parent = this.wrapperRef.current;
      if (parent && parent.parentNode) {
        this.setState({
          mediaWidth: parent.offsetWidth
        })
      }
    } catch (e) {

    }
  }

  getSectionStyle(section, decorator) {
    if (!section || decorator === 'main') {
      return {};
    }
    const {widget, context} = this.props;
    if (widget.site === '8world') {
      if (section.indexOf(':news') >= 0) {
        return {color: '#326ed7'};
      } else {
        return {color: '#c20072', outlineColor: '#c20072'};
      }

    } else if (widget.site === 'cna') {
      return {color: '#a12528'};
    } else {
      return {};
    }
  }

  visibilityChange(isVisible) {
    const {visibilitySensorStop} = this.state;
    if (isVisible && !visibilitySensorStop) {
      this.setState({visibilitySensorStop: true});
      const {trackEvent} = window.recApp || {};
      const {widget, context} = this.props;
      if (trackEvent === 'function') {
        trackEvent(widget, context, 'visible_impression');
      }
    }
  }

  onItemClicked(e, d) {
    const {trackEvent} = window.recApp || {};
    const {widget, context} = this.props;
    const {isWebView} = context || {};
    if (isWebView) {
      //trackEvent in async function context, otherwise it will not be working.
      setTimeout(function () {
        trackEvent(widget, context, 'click', {clickItem: d});
      }, 5);
    }
  }

  deepLink(d, decorator) {
    //inside webview, we can use the original url, and bind the url click handler to send the 'click' pixel.
    const recApp = window.recApp || {};
    const {widget, context} = this.props;
    const {isWebView} = context || {};
    if(d.site === 'iprice' && context.env === 'dfp') {
      let clickTagVar = 'clickTag' + (decorator ? (decorator + 1) : '');
      window[clickTagVar] = window[clickTagVar] || d.click_through_url;
      return window[clickTagVar];
    }
    else if (!isWebView && typeof recApp.buildPixelData === 'function') {
      let pixelData = recApp.buildPixelData(widget, context, 'click', {clickItem: d});
      let pixelStr = JSON.stringify(pixelData);
      if (window.btoa) {
        pixelStr = 'pb64=' + window.btoa(pixelStr);
      } else {
        pixelStr = 'pixel=' + pixelStr;
      }
      if (recApp.trackingDisabled) {
        pixelStr = 'notracking'
      }
      // return `${process.env.REACT_APP_SERVER_URL}/click?${pixelStr}&eur=${d.url_encrypted}`;
      return d.click_through_url;
    } else if (!isWebView) {
      return d.click_through_url;
      // return `${process.env.REACT_APP_SERVER_URL}/click?eur=${d.url_encrypted}`;
    } else {
      if (d.thumbnail_type !== 'video') {
        return d.url;
      } else {
        const hasParam = (d.url || '').indexOf('?') > 0;
        let content_id = d.content_id || (d.id || '').split('-').pop();
        if (!content_id) {
          content_id = ((d.url || '').split('?')[0]).split('-').pop();
          if (!isNaN(content_id)) {
            content_id = '';
          }
        }
        return d.url + (hasParam ? '&' : '?') + `type=video&-${content_id}`;
      }
    }
  }
}