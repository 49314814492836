import styled, {css} from 'styled-components';

import {DarkGray, LightGray, LightGray1, MediumGray1, BitterSweet, BlueJeans} from '../app/StyleCommon';

export const TargetingWrapper = styled.div`
  table {
    overflow: visible;
    border-collapse: separate;
    border-radius: 5px;
    background: ${LightGray};
    thead {
      background: ${LightGray};
      border: none;
      tr {
        th {
          color: ${DarkGray};
          font-weight: normal;
          background: transparent;
          &:last-child {
            border-top-right-radius: 5px;
            border-right: none;
          }
        }
      }
    }
    tbody {
      tr {
        background: white;
        &:hover {
          background: white;
          .sorter {
            opacity: 1;
          }
        }
        &:not(:last-child) {
          border-bottom: 1px solid ${LightGray};
          td {
            border-bottom: 1px solid ${LightGray};
          }
        }
        &:last-child td {
          &:first-child {
            border-bottom-left-radius: 5px;
          }
          &:last-child {
            border-bottom-right-radius: 5px;
          }
        }
        td {
          background: white;
          font-size: 14px;
          padding: 5px;
          &:not(:last-child) {
            border-right: 1px solid ${LightGray};
          }
          &:last-child {
            max-width: 30px;
          }
        }
      }
    }
  }
  .targets-list-header {
    text-align: right;
    .add-target {
      padding: 5px 0;
      box-sizing: border-box;
    }
  }
  .select-box {
    width: 100%;
    .toggler {
      background: transparent;
      border: none;
      text-align: left;
      font-size: 12px;
      width: 100%;
    }
    .menu {
      top: 40px;
      max-height: 240px;
      overflow: auto;
    }
  }
  .action-items {
    .delete-btn {
      color: ${BitterSweet};
      font-size: 16px;
    }
  }
  .ip-input {
    outline: none;
    width: 100%;
    border: none;
    padding-left: 10px;
    font-size: 12px;
    &:focus {
      border-radius: 5px;
      background: white;
      border-bottom: 1px dotted #d1d2d3;
    }
  }
`;

export const NoData = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  color: ${DarkGray};
  @media screen and (max-width: 667px) {
    display: block;
    .blockIfMobile {
      display: block;
      margin: 10px 0;
    }
  }
  a {
    cursor: pointer;
    text-decoration: underline;
    display: inline-block;
    margin: 0 5px;
  }
`;