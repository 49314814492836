import React from 'react';
import styled, {css} from 'styled-components';
import {DarkGray, DarkGray1, LightGray, MediumGray, MediumGray1, Sunflower, Sunflower1} from "../../app/StyleCommon";
import {Spinner} from '../Loader/index.style';

export const InfoMessageWrapper = styled.div`
  color: ${MediumGray1};
  display: block;
  font-weight: bold;
  padding: 10px 10px;
  border-radius: 5px;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${props => props.align === 'center' && css`
    justify-content: center;
  `}
  i {
    color: ${MediumGray1};
    margin-right: 5px;
    font-size: 18px;
    margin-right: 5px;
    vertical-align: middle;
  }
  .spinner {
    transform: scale(0.5);
  }
`
export const InfoMessage = props => (
  <InfoMessageWrapper {...props}>
    {props.type === 'syncing' && <Spinner type="small"/>}
    {props.type !== 'syncing' && <i className="fa fa-info-circle" aria-hidden="true"></i>}
    {props.message}
  </InfoMessageWrapper>
)
