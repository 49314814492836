import React from 'react';

function setParitialVisibilityGutter(itemCount) {
  switch(itemCount) {
    case 1:
      return 135;
    case 2:
      return 30;
    case 3:
      return 20;
    default:
      return 30;
  }
}

export function getResponsiveOptions(isMobile, itemsToShow, totalItems) {
  const additionalConfig = {};

  if (!!isMobile) {
    additionalConfig.paritialVisibilityGutter = setParitialVisibilityGutter(itemsToShow);
  }

  return {
    screen: {
      breakpoint: { max: 4000, min: 0 },
      slidesToSlide: !!isMobile ? totalItems : itemsToShow,
      items: itemsToShow,
      ...additionalConfig
    }
  }
};
