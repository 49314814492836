import React, {Component} from 'react';
import {findDOMNode} from 'react-dom';
import _ from 'lodash';
import {SelectWrapper, SelectMenu, SelectToggler, MenuGroup, MenuItem, MenuGroupHeader} from './index.style'
import {FixedHook} from './hooks';

export default class extends Component {
  static defaultProps = {
    disabled: false,
    selected: [],
    defaultLabel: 'Select Option',
    labelPrefix: '',
    allSelectedLabel: undefined,
    labelIgnoreAllSelectedGroup: false,
    groupClosable: false, //if this is true, each group can perform open|close action
    groups: undefined, //if group is not empty, will render in group mode
    data: [], //if groups is empty, will render single group mode using 'data' array.
    multi: false, //multiple selection mode
    mutuallyExclusive: false, //if true, only one group allowed to be selected.
    showGroupBatch: false, //if true, every group will have a select all button.
    onChange: _ => console.log(_), // change handler
    disableFunc: _ => false, //no disable item
    searchable: false,
    inputable: false,
    toggleLableFunc: undefined,
    useFixed: false
  };

  constructor(props) {
    super(props);
    this.state = {active: false}
    this.menuRef = React.createRef();
    this.wrapperRef = React.createRef();
  }


  // shouldComponentUpdate(nextProps, nextState) {
  //   // return false;
  //   return !_.isEqual(nextProps, this.props) || (this.state.active !== nextState.active);
  // }

  render() {
    const {
      className, disabled, selected = [], defaultLabel, labelPrefix, labelSubfix,
      unSelectable, searchable, inputable, toggleLableFunc,
      tabIndex, useFixed, fixedTop
    } = this.props;
    const {active} = this.state;
    let toggleLabel = this.serializeSelected();
    if (toggleLableFunc) {
      toggleLabel = toggleLableFunc(selected, toggleLabel);
    }
    toggleLabel = toggleLabel || defaultLabel;
    const showClearBtn = (unSelectable && selected && (Array.isArray(selected) ? !!selected.length : true));
    const {keyword} = this.state;
    return (
      <SelectWrapper ref={this.wrapperRef} className={`select-box ${className}`} disabled={disabled}
                     tabIndex={tabIndex} style={this.props.style}>
        <SelectToggler
          className={`toggler ${active ? 'active' : ''} ${showClearBtn ? 'has-clear-btn' : ''}`}
          title={typeof toggleLabel === 'string' ? toggleLabel : ''}
          disabled={disabled}
          unSelectable={unSelectable}
          onClick={e => this.handleToggle(e)}>
          {this.renderSelectedGroupIcon()} {labelPrefix} {toggleLabel} {labelSubfix}
          {
            (!!searchable || !!inputable) && !disabled &&
            <input type="text"
                   className="search-input"
                   autoComplete="false"
                   placeholder={inputable ? 'Input...' : 'Search...'}
                   value={inputable ? selected : keyword}
                   onFocus={e => {
                     e.target.select();
                     setTimeout(() => {
                       this.setState({active: true});
                     }, 100)
                   }}
                   onClick={e => {
                     e.stopPropagation();
                   }}
                   onChange={e => {
                     this.setState({keyword: e.target.value, dynamicData: [], page: 0});
                     if (inputable) {
                       this.props.onChange(e.target.value);
                     }
                   }}/>
          }
          {showClearBtn &&
          <i className="clear-all fa fa-times" onClick={e => this.props.onChange(undefined)}/>
          }
          <i className="caret fa fa-caret-down"/>
        </SelectToggler>
        {this.renderGroups()}
        {!!useFixed && <FixedHook wrapperRef={this.wrapperRef} fixedRef={this.menuRef} offset={{top: fixedTop || 35}}/>}
      </SelectWrapper>
    );
  }

  renderGroups() {
    const {menuHeader, unSelectable, AjaxDataLoader} = this.props;
    const {keyword, active} = this.state;
    const keywordLowerCase = (keyword || '').toLowerCase();

    const isMatch = (label, keyword) => {
      return !keyword || label.toLowerCase().indexOf(keyword) >= 0;
    }
    let {groups, data} = this.props;
    let {dynamicData = [], page = 0} = this.state;

    if (groups && groups.length) {
      groups = groups.map(g => {
        return {
          ...g,
          data: g.data.filter(d => isMatch(d.label, keywordLowerCase))
        }
      }).filter(g => g.data.length);
      if (groups.length) {
        return (
          <SelectMenu ref={this.menuRef} className="menu" active={this.state.active}>
            {menuHeader}
            {groups.map(g => this.renderGroup(g))}
          </SelectMenu>
        )
      }
    } else {
      data = data.filter(d => d && isMatch(d.label, keywordLowerCase));
      if (data.length || !!AjaxDataLoader) {
        return (
          <SelectMenu ref={this.menuRef} className="menu" active={this.state.active}>
            {menuHeader}
            {this.renderGroup({key: 'basic', data: data})}
            {!!dynamicData.length && this.renderGroup({key: 'dynamic', data: dynamicData})}
            {!!active && !!AjaxDataLoader &&
            <AjaxDataLoader
              keywords={keyword}
              page={page}
              onLoad={newPage => {
                this.setState({dynamicData: dynamicData.concat(newPage), page: page + 1})
              }}/>}
          </SelectMenu>
        )
      }
    }
  }

  renderGroup(g) {
    const {data, label: groupName, key: groupKey, icon} = g;
    const {selected, multi, showGroupBatch, allSelectedLabel, disableFunc, groupClosable, itemRenderer} = this.props;
    const {openState = {}} = this.state;
    const open = openState[groupKey];
    let groupSelectedInfo = ''
    if (showGroupBatch) {
      const inGroupSelected = selected.filter(v => data.find(d => d.key === v));
      if (inGroupSelected.length !== data.length) {
        groupSelectedInfo = `(${inGroupSelected.length} / ${data.length})`;
      }
    }

    return (
      <MenuGroup key={groupKey|| 'none'}>
        {
          groupName &&
          <MenuGroupHeader
            closable={groupClosable}
            onClick={e => this.handleGroupHeaderClick(e, groupKey, open)}>
            <h5>
              {icon && <i className={icon} style={{marginRight: '8px'}}/>}
              <span>{groupName} {groupSelectedInfo}</span>
            </h5>
            {groupClosable && <i className="caret fa fa-caret-down"/>}
          </MenuGroupHeader>
        }
        <ul className={`${groupClosable && !open ? 'closed' : 'open'}`}>
          {
            showGroupBatch &&
            <MenuItem key={groupKey}>
              <input
                type="checkbox"
                checked={data.every(d => selected.indexOf(d.key) >= 0)}
                onChange={e => this.handleGroupBatch(e, groupKey, e.target.checked)}/>
              <span>All {_.capitalize(groupKey)}</span>
            </MenuItem>
          }
          {data.map(d => {
            let active = multi ? selected.indexOf(d.key) >= 0 : selected === d.key;
            return (
              <MenuItem
                key={d.key}
                title={typeof d.label === 'string' ? d.label : ''}
                disabled={disableFunc(d)}
                multi={multi}
                selected={active}
                className={active ? 'selected' : ''}>
                <input
                  disabled={disableFunc(d)}
                  type={multi ? 'checkbox' : 'radio'}
                  onClick={e => this.handleRadioBtnClick(e, d.key)}
                  checked={multi ? selected.indexOf(d.key) >= 0 : selected === d.key}
                  onChange={e => this.handleChange(e, d.key, e.target.checked)}/>
                {itemRenderer ? itemRenderer(d) : d.label}
              </MenuItem>
            )
          })}
        </ul>
      </MenuGroup>
    );
  }

  handleGroupHeaderClick(e, groupKey, open) {
    const {openState} = this.state;
    this.setState({openState: {...openState, [groupKey]: !open}});
  }

  renderSelectedGroupIcon() {
    const {data, groups, selected} = this.props;
    if (groups && selected) {
      let currentGroup = groups.find(g => g.data.some(d => selected.indexOf(d.key) >= 0));
      if (currentGroup && currentGroup.icon) {
        return <i className={currentGroup.icon}/>
      }
    } else if (typeof selected === 'string') {
      let selectItem = data.find(d => d.key === selected);
      if(selectItem && typeof selectItem.icon === 'string') {
        return <img className="icon" src={selectItem.icon}/>
      }
    }
  }

  serializeSelected() {
    const {inputable, groups, data, multi, selected, defaultLabel, mutuallyExclusive, allSelectedLabel, labelIgnoreAllSelectedGroup} = this.props;
    const {dynamicData} = this.state;
    let allData = data;
    let searchableStyled = <span className="default-label">{defaultLabel}</span>
    if (groups && groups.length > 0 && !mutuallyExclusive) {
      let significants = groups.filter(g => {
        return g.data.some(d => {
          if(multi) {
            return selected.indexOf(d.key) < 0 && !g.data.every(d => selected.indexOf(d.key) >= 0);
          } else {
            return selected === d.key;
          }
        });
      });
      let selectedOption = _.flatten(significants.map(g => g.data)).filter(d => selected.indexOf(d.key) >= 0);
      if (selectedOption.length) {
        return selectedOption.map(d => d.label).join(', ');
      }
    } else if (groups && groups.length > 0 && mutuallyExclusive) {
      allData = _.flatten(groups.map(g => g.data));
    }
    if(dynamicData) {
      allData = allData.concat(dynamicData);
    }
    if (multi) {
      let fixedSelected = _.flatten([selected]);
      let dataOptions = _.uniqBy([...allData, ...fixedSelected.map(k => ({key: k, label: k}))], 'key');
      if (allSelectedLabel && selected.length === dataOptions.length) {
        return allSelectedLabel;
      } else if (groups && labelIgnoreAllSelectedGroup) {

      } else {
        return fixedSelected
          .map(key => dataOptions.find(d => d.key === key))
          .filter(d => !!d)
          .map(d => d.label)
          .join(', ') || searchableStyled;
      }
    } else {
      let singleSelected = _.flatten([selected])[0];
      let dataOptions = allData;
      if(singleSelected) {
        dataOptions = [...allData, {key: selected, label: selected}];
      }
      let item = dataOptions.find(d => selected && d.key === selected);
      return item ? item.label : searchableStyled;
    }
  }

  handleToggle(e) {
    const {disabled} = this.props;
    if (!disabled) {
      this.setState({active: !this.state.active});
    }
  }

  handleGroupBatch(e, groupKey, checked) {
    const {groups, data} = this.props;
    let newSelected = this.props.selected;
    if (!groups) {
      newSelected = checked ? data.map(d => d.key) : [];
    } else {
      let currentGroup = groups.find(g => g.key === groupKey);
      //get rest selected
      newSelected = newSelected.filter(key => !currentGroup.data.find(d => d.key === key));
      if (checked) {
        newSelected = newSelected.concat(currentGroup.data.map(d => d.key));
      }
    }
    this.props.onChange(newSelected);
  }

  handleChange(e, key, checked) {
    const {groups, multi, mutuallyExclusive} = this.props;
    if (multi) {
      let newSelected = this.props.selected;
      if (checked) {
        if (groups && mutuallyExclusive) {
          let currentGroup = groups.find(g => g.data.find(d => d.key === key));
          newSelected = newSelected.filter(key => currentGroup.data.find(d => d.key === key));
        }
        newSelected.push(key);
      } else {
        newSelected = newSelected.filter(k => k !== key);
      }
      this.props.onChange(newSelected);
    } else {
      this.props.onChange(key);
      this.setState({active: false});
    }
  }

  handleRadioBtnClick(e, key) {
    const {selected, multi} = this.props;
    const {active} = this.state;
    if (active && !multi && selected === key) {
      this.setState({active: false})
    }
  }

  handleClickOutside(event) {
    let {active} = this.state;
    if (active) {
      try {
        const self = findDOMNode(this)
        if (!self || !self.contains(event.target)) {
          this.setState({active: false});
        }
      } catch (e) {
        //happens when the dom is already destroyed
        // console.error(e);
      }
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside.bind(this), true); // capture phase
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside.bind(this), true);
  }
}