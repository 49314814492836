import React from 'react';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import queryString from 'qs';

import MainLayout from '../layout/index';
import {GuideWrapper, GuideSection} from './abtesting-guide.style';

export default class extends React.Component {

  render() {
    const params = queryString.parse(window.location.search.slice(1));
    const abtestId = params.id || 'p_QbmdrO5';
    return (
      <MainLayout
        activeItem="abtesting"
        breadcrumb={[{path: '/abtesting', label: 'A/B Test'}]}>
          {/* <li className="selected"><a onClick={e => window.history.go(-1)}>Back</a></li>
          <li className="seperator">&lt;</li>
          <li>Integration Guide</li> */}
        <GuideWrapper>
          <h1>Integration With CMS</h1>
          <p>
            This article will tell you how to integrate the widget and affiliate link into your website.
          </p>
          <p>
            Whatever code list below also apply the same way if you are integrating affiliate link. Because affiliate
            Link works the same way as widget. It includes a link tag and a script tag. the script tag can
            control the sso login and can disable the link dynamically in admin portal.
          </p>

          <h2>1. Default CMS Integration (The simple way)</h2>
          <GuideSection>
            <p>
              If your CMS system support pasting javascript code directlly in the article editor. the integration will
              be
              very simple for you.
              just copy the code in widget builder and paste it in side the article editor.
            </p>
            <div className="code-panel-body">
              <SyntaxHighlighter language="html" wrapLines={false}>
                {`<div id="${abtestId}" data-role="abtesting"><script async src="${process.env.REACT_APP_SERVER_URL}/abtesting/loader?id=${abtestId}"></script></div>`}
              </SyntaxHighlighter>
            </div>
          </GuideSection>
        </GuideWrapper>
      </MainLayout>
    )
  }
}
