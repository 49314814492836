import styled, {css} from 'styled-components';
import {
  Aqua, BitterSweet,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass, Grass1, LightGray, LightGray1, MediumGray,
  MediumGray1, Mint, Sunflower1
} from "../app/StyleCommon";

export const SetupPageWrapper = styled.div`
  padding: 10px 20px 20px 20px;
  h3 {
    color: ${DarkGray1};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    margin: 10px 0;
    a {
      font-size: 12px;
      color: ${MediumGray1};
      margin-left: 10px;
      i {
        margin-right: 5px;
      }
      &:hover {
        color: ${BlueJeans1};
      }
    }
  }
  @media screen and (max-width: 667px) {
    padding: 10px 5px;
  }
`;
export const StepProgressbar = styled.div`
  margin: 20px 10px 30px 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media screen and (max-width: 667px) {
    margin: 20px 10px;
    .step {
      width: 80px !important;
      .step-name {
        font-size: 12px !important;
        white-space: normal !important;
      }
      .step-desc {
        display: none;
      }
       &:before,
        &:after {
          width: 20px !important;
      }
    }
  }
  .step {
    text-align: center;
    position: relative;
    width: 200px;
    cursor: not-allowed;
    &.clickable {
      cursor: pointer;
    }
    &.current {
      .step-icon, &:before, &:after {

      }
      .step-icon {
        //border: 1px solid ${BlueJeans};
        background-color: #e8f5e8 !important;
        border: 3px solid ${Grass1};
        color: ${DarkGray1};
        i {
          color: ${Grass1} !important;
        }
      }
      .step-name {
        font-weight: bold;
        color: ${DarkGray1} !important;
      }
      .step-desc {
        font-weight: bold;
      }
    }
    &.success {
      .step-icon, &:before, &:after {
        background-color: ${Grass};
      }
      .step-icon i {
        color: white;
      }
      .step-name {
        i {
          color: ${Grass1};
        }
      }
    }
    .step-icon {
      width: 38px;
      height: 38px;
      color: white;
      display: inline-block;
      display: inline-flex;
      background-color: ${LightGray1};
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      border: 2px solid transparent;
      i {
        color: ${DarkGray};
      }
      img {
        width: 20px;
        height: 20px;
      }
    }
    .step-name {
      font-size: 14px;
      white-space: nowrap;
    }
    .step-desc {
      width: 180px;
      font-size: 11px;
      margin: auto;
    }
    &:first-child:before,
    &:last-child:after {
      display: none;
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      height: 5px;
      width: 80px;
      top: 20px;
      background-color: ${LightGray1};
      //background-image: linear-gradient(36deg, #f1f2f3 25%, #dddddd 25%, #dddddd 50%, #f1f2f3 50%, #f1f2f3 75%, #dddddd 75%, #dddddd 100%);
      //background-size: 17.01px 12.36px;
    }
    &:before {
      left: 50%;
      transform: translate(-100%, 0) translate(-20px, 0);
    }
    &:after {
      left: 50%;
      transform: translate(20px, 0);
    }
  }
`;

export const StepStack = styled.div`
  margin: 20px;
  counter-reset: section;
  @media screen and (max-width: 667px) {
    margin: 20px 10px 20px 5px;
  }
`;

export const StepFormWrapper = styled.div`
  position: relative;
  padding-left: 30px;
  min-height: 3px;
  &:not(:last-child) {
    border-left: 2px dashed ${LightGray1};
  }
  &:before {
    counter-increment: section;
    content: counter(section);
    z-index: counter(section);
    ${props => css`
      z-index: ${props.step < props.current ? props.step : (5 - props.step)};
    `}
    display: block;
    position: absolute;
    left: 0;
    top: 10px;
    font-weight: bold;
    transform: translate(-50%, -5px);
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${LightGray1};
    font-size: 8ox;
    opacity: 1;
    color: white;
    border-radius: 50%;
    border: 1px solid white;
  }
  @media screen and (max-width: 667px) {
    padding-left: 20px;
  }
  &.active {
    :before {
      background-color: ${BlueJeans1};
      z-index: 5;
      opacity: 1;
      font-size: 11px;
    }
    .step-header {
      display: flex;
      padding: 5px 10px 5px 5px;
      //border-radius: 5px;
      background-color: #fafbfc;
    }
    .step-body {
      //transform: translate(0, 0);
      opacity: 1;
      height: auto;
      overflow: visible;
      margin: 15px 0px 30px 0px;
      //min-height: calc(100vh - 480px);
    }
  }
  .step-header {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 10px;
    display: none;
    background-color: white;
    margin-left: -5px;
    padding-left: 5px;
    border: 1px solid ${MediumGray};
    border-radius: 5px;
    @media screen and (max-width: 667px) {
      .cur-step-name {
        display: none;
      }
    }
    a {
      cursor: pointer;
      font-weight: bold;
      color: ${BlueJeans};
      margin-right: 20px;
    }
    .step-actions {
      transition: all .4s ease-in-out;
      transition-delay: .2s;
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      &.visible {
        opacity: 1;
        max-height: 50px;
      }
      button {
        text-decoration: none !important;;
        color: ${BlueJeans1} !important;
        font-weight: bold;
        &:first-child {
          color: gray !important;
        }
      }
    }
    .nav-actions {
      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-right: 5px;
        margin-top: -4px;
      }
      button {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
        &.reset {
          color: gray !important;
        }
      }
    }
  }
  .step-body {
    transition: all .5s ease;
    opacity: 0;
    margin: 0 0px 20px 0px;
    height: 0;
    overflow: hidden;
    //transform: translate(0, 100px);
    *::placeholder {
      color: ${MediumGray1} !important;
    }
    .step-flash {
      border: 1px solid #b7da91;
      background-color: #f9fbf7;
      font-size: 12px;
      border-radius: 5px;
      opacity: 0;
      max-height: 0;
      overflow: hidden;
      transition: all .3s ease;
      padding: 0;
      margin: 0;
      position: absolute;
      left: 25px;
      right: 0px;
      top: 35px;
      &.visible {
        opacity: 1;
        max-height: 40px;
        margin-bottom: 20px;
        padding: 5px 10px;
      }
    }
  }
`;

export const PromptBody = styled.div`
  margin: 20px 0;
  i {
    color: ${Sunflower1};
    margin-right: 10px;
  }
`;

export const PublishDialogWrapper = styled.div`
  svg {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 5px;
  }
  .res-success-icon {
    border: 3px solid ${Grass1};
    path {
      fill: ${Grass1};
    }
  }
  .res-failure-icon {
    border: 3px solid ${BitterSweet};
    path {
      fill: ${BitterSweet};
    }
  }
`;

export const Forecaster = styled.div`
  //background-color: ${LightGray};
  border-radius: 50%;
  //border: 1px solid ${MediumGray};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  button {
    background: white !important;
    margin-right: -20px;
    position: relative;
  }
  img {
    height: 14px;
    margin-right: 8px;
    border-bottom: 3px solid transparent;
    vertical-align: middle;
  }
`;
export const ForecastResult = styled.div`
  .with-spark-line {
    display: flex;
    align-items: center;
    strong {
      margin-left: 5px;
    }
    .sparkline {
      align-self: flex-end;
      margin-left: 30px;
    }
  }
  .bar-wrapper {
    width: 100%;
    background-color: ${LightGray1};
    border-radius: 5px;
    margin: 2px 0 15px 0;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    .bar {
      flex: none;
      height: 20px;
      background-color: ${MediumGray};
      min-width: 2px;
      box-sizing: border-box;
      padding-left: 5px;
      &.blue {
        background-color: ${BlueJeans};
      }
      &.green {
        background-color: ${Grass1};
      }
      color: white;
      font-size: 12px;
      font-weight: bold;
      padding-top: 2px;
      overflow: visible;
      &.infinite {
        color: ${DarkGray};
        font-size: 18px;
        background-color: transparent;
      }
    }
  }
  .rect {
    display: inline-block;
    margin-right: 10px;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    &.blue {
      background-color: ${BlueJeans};
    }
    &.green {
      background-color: ${Grass1};
    }
    &.gray {
      background-color: ${MediumGray};
    }
  }
`;