import styled, {css} from 'styled-components';
import Dropdown from "./index";
import {BlueJeans} from "../../app/StyleCommon";

export const StyledDropdown = styled(Dropdown)`
  .dropdown-toggle {
    width: 50px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      border-radius: 50%;
      width: 24px;
      height: 24px;
    }
    i {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 8px;
      color: #aaa;
    }
  }
  .menu {
    top: 40px;
    right: 0;
    left: auto;
    ${props => props.up && css`
      transform: translate(0, -100%) translate(0, -30px);
      box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.16);
    `}
    .menu-item {
      a {
        text-decoration: none;
      }
      &.disabled {
        cursor: not-allowed;
        pointer-events: none;
        opacity: .5;
      }
      &:hover {
        background-color: ${BlueJeans};
        a {
          color: white;
        }
      }
    }
  }
`