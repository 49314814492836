import React from 'react';
import styled, {css} from 'styled-components';
import {
  BitterSweet,
  BitterSweet1,
  BlueJeans, BlueJeans1, Dark, DarkGray, DarkGray1, Grass, Grass1, LightBlue, LightGray, LightGray1, MediumGray,
  MediumGray1, Sunflower1
} from "../app/StyleCommon";

export const CssEditorWrapper = styled.div`
  position: relative;
  h3 {
    border-bottom: 1px solid ${MediumGray};
    width: 100%;
    padding: 0 0 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      color: ${DarkGray} !important;
      font-size: 12px !important;
      &.selected {
        color: #819d63 !important;
        font-weight: bold !important;
      }
      &:hover {
        color: ${BlueJeans1} !important;
      }
      i {
        font-size: 12px;
        margin-right: 5px;
      }
    }

  }
  .editor-wrapper {
    min-height: 200px;
    max-height: 420px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    flex-wrap: nowrap;
    @media screen and (max-width: 667px) {
      display: block;
      overflow: auto;
      > div {
        width: 100% !important;
        .rse-StyleEditor-root {
          height: auto !important;
        }
      }
    }
    >div {
      width: 48%;
      flex: none;
      box-sizing: border-box;
      padding-bottom: 20px;
      &:first-child {
        border-right: 1px solid ${LightGray1};
        margin-right: 10px;
      }
      &.css {
        margin-right: 0px;
        padding-right: 20px;
      }
      &.html {
        .html-code {
          overflow: auto;
          height: 400px;
        }
      }
    }
    pre {
      background: white !important;
      overflow: auto;
      font-size: 12px;
      min-height: 400px;
      padding: 0 !important;

    }
  }
  .simple-css-editor {
    width: 100%;
    width: calc(100% - 10px);
    resize: none;
    min-height: 380px;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 10px;
    color: ${DarkGray1};
    outline: none;
    font-family: monospace;
    line-height: 1.5em;
  }
  .rse-Rule-isInvalid,
  .rse-Declaration-isInvalid {
    text-decoration: none;
  }
  //.rse-StyleEditor-isLocked {
  //  display: none;
  //}
  .rse-Alert-root {
    display: none;
  }
  .rse-StyleEditor-root {
    height: 380px;
    border: none;
    padding-right: 10px;
    margin-right: -18px;
    .rse-Rule-root {
      margin-bottom: 10px;
    }
    .rse-Checkbox-root {
      border-color: ${BlueJeans};
      border-radius: 3px;
      &:after {
        border-color: ${BlueJeans};
      }
    }
  }
  .actions {
    margin-top: 50px;
    border-top: 1px solid ${MediumGray};
    padding-top: 15px;
    text-align: right;
  }

`