import React, {Component, useEffect} from 'react';
import ReactServer from 'react-dom/server';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';
import {ReRenderHook} from '../utils/hooks';
import {
  BlueJeans, BlueJeans0,
  BlueJeans1,
  DarkGray,
  Grass, Grass1,
  LightBlue, LightGray,
  MediumGray1,
  Sunflower,
  Sunflower1
} from "../app/StyleCommon";
import {
  intFormatter,
  pct0Formatter,
  pct2Formatter,
  pctFormatter,
  uniquesFormatter,
  uniquesFormatter0
} from "../utils/formatter";
import styled from "styled-components";

const ChartWrapper = styled.div`
  legend {
    font-size: 14px;
    font-weight: bold;
    color: ${DarkGray};
  }
`

export default class extends Component {

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.renderChart();
  }

  render() {
    return (
      <ChartWrapper>
        <div ref={this.containerRef}/>
      </ChartWrapper>
    )
  }

  renderChart() {
    let {data: daypartReport, metric} = this.props;
    const container = this.containerRef.current;
    const height = 350;
    const myChart = echarts.init(container, null, {renderer: 'svg', height});
    const {items} = daypartReport;
    const hours = _.range(0, 24).map(d => (d < 10 ? '0': '') + d);
// prettier-ignore
    const days = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];
    const data = items.map(d => {
      d.impressions = d.impressions /  (d.daycount || 1);
      d.clicks = d.clicks /  (d.daycount || 1);
      d.errors = d.errors /  (d.daycount || 1);
      d.daycount = 1;
      d.ctr = d.clicks / d.impressions || 0;
      d.error_rate = d.errors / d.impressions || 0;
      return [Number(d.datehour), (Number(d.weekday) || 7) - 1, d[metric]];
    });
    const min = _.min(data.map(d => d[2]))
    const max = _.max(data.map(d => d[2]))
    let option = {
      colors: ['red', 'green'],
      tooltip: {
        position: 'top',
        valueFormatter: v => {
          let formatter = {
            impressions: uniquesFormatter0,
            error_rate: pct2Formatter,
            ctr: pct2Formatter,
          }[metric];
          return formatter(v);
        }
      },
      grid: {
        top: '10',
        bottom: '30',
        left: '140',
        right: '0',
      },
      xAxis: {
        type: 'category',
        data: hours.map((value, i) => {
          return moment(value, 'HH').format('h A');
        }),
        formatter: (value, i) => {
          return moment(value, 'HH').format('h A');
        },
        splitArea: {
          show: true
        },
        axisLabel: {
          color: BlueJeans1,
          fontWeight: 'bold'
        },
      },
      yAxis: {
        type: 'category',
        data: days,
        splitArea: {
          show: true
        },
        axisLabel: {
          fontWeight: 'bold',
          color: v => {
            return (v === 'Saturday' || v === 'Sunday') ? Grass1 : BlueJeans1;
          }
        },
      },
      visualMap: {
        min: min,
        max: max,
        calculable: true,
        orient: 'vertical',
        left: 'left',
        bottom: 'center',
        inRange : {
          color: ['white', {
            impressions: '#a1c5f2',
            error_rate: Sunflower,
            ctr: Grass,
          }[metric]], //From smaller to bigger value ->
        },
        formatter: function (value) {
          let formatter = {
            impressions: uniquesFormatter0,
            error_rate: pct2Formatter,
            ctr: pct2Formatter,
          }[metric];
          return formatter(value);
        }
      },
      series: [
        {
          name: '',
          type: 'heatmap',
          data: data,
          label: {
            show: true,
            fontSize: '10px',
            color: 'inherit',
            // borderColor: 'white',
            // borderWidth: 5,
            formatter: function (d) {
              let formatter = {
                impressions: uniquesFormatter0,
                error_rate: pct2Formatter,
                ctr: pct2Formatter,
              }[metric];
              return formatter(d.value[2]);
            }
          },
          itemStyle: {
            borderColor: 'rgba(255,255,255,.5)',
            borderWidth: 5,
            orderRadius: 5
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 11,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };;

    myChart.setOption(option);
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
}