import React from 'react'
import ReactDOM from 'react-dom';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import moment from 'moment';
import * as d3 from 'd3';
import {withConsumer} from '../app/ApplicationContext';
import CondirmDialog from '../uikit/dialog/index';
import {Btn, TabContainer, Table, ProgressBar, Loader} from '../uikit/index';
import {SelectStyled} from './select.style';
import {PaginationStyled, ErrorBox} from './index.style';
import {ErrorLogPanelWrapper} from './errorLogPanel.style';
import {BlueJeans} from "../app/StyleCommon";

export default withConsumer(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {page: 1};
  }

  render() {
    const {type, id, period} = this.props;
    const {data, error, loading, keyword, page} = this.state;
    const {errorLogs = [], total} = data || {};
    return (
      <ErrorLogPanelWrapper>
        {
          !data && !error &&
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/${type}/getErrors`}
            params={{period, id: id, limit: 10, offset: (page - 1) * 10}}
            successHandler={data => {
              this.setState({data: data, loading: false, error: false});
            }}
            errorHandler={e => this.setState({error: e.message})}/>
        }
        {
          !!loading && <Loader transparent={true} type="absolute"/>
        }
        {
          !!error && <ErrorBox>{error}</ErrorBox>
        }
        {/*<div className="searchBox">*/}
        {/*<i className="fa fa-search"/>*/}
        {/*<input*/}
        {/*type="text"*/}
        {/*placeholder="Search..."*/}
        {/*value={keyword}*/}
        {/*onChange={e => this.setState({keyword: e.target.value})}/>*/}
        {/*</div>*/}
        <div className="table-wrapper">
          <Table
            columns={[
              {
                key: 'no', label: 'No', align: 'center',
                renderer: (d, i) => i + 1
              },
              {
                key: 'context', label: 'Context', align: 'left', renderer: (d) => {
                return (
                  <div>
                    <div><strong>MeID: {d.userId}</strong></div>
                    <div style={{fontSize: '11px'}}>
                      <a href={d.url} target="_blank" rel="noopener noreferrer">{d.url}</a>
                    </div>
                    <div style={{fontSize: '11px'}}>{d.user_agent}</div>
                  </div>
                )
              }
              },
              {
                key: 'error', label: 'Error', align: 'left', renderer: (d) => {
                return (
                  <div>
                    <div className="error-msg"><strong>{d.error}</strong></div>
                    <div style={{fontSize: '11px'}}>Stacktrace: {d.stacktrace}</div>
                  </div>
                )
              }
              },
              {
                key: 'CreatedDateTime',
                label: 'Time',
                align: 'left',
                renderer: (d) => d.CreatedDateTime
              }
            ]}
            rows={errorLogs || []}
            noDataText={"No Error Log found!"}
          />
        </div>
        <PaginationStyled
          onChange={p => this.setState({page: p, data: null})}
          current={page}
          pageSize={10}
          total={total}
          hideOnSinglePage={true}/>
        <div className="actions">
          <Btn type="reset" onClick={e => this.props.onCancel()}>Close</Btn>
        </div>
      </ErrorLogPanelWrapper>
    );
  }
})
