import React from 'react'
import ReactDOM from 'react-dom';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import moment from 'moment';
import * as d3 from 'd3';
import {withConsumer} from '../app/ApplicationContext';
import {CssEditorWrapper, HTMLWrapper} from './cssEditor.style';
import {Btn, CondirmDialog} from '../uikit/index';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import StyleEditor from 'react-style-editor';

export default withConsumer(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      css: props.css || this.getDefaultCss(),
      tmpl: props.tmpl,
      editorKey: Date.now(),
      mode: 'interactive'
    };
  }

  render() {
    const {css, tmpl, editorKey, mode, touched} = this.state;
    return (
      <CssEditorWrapper>
        <p>
          You can define you style rules here on the left panel, the html on the right hand side gives you a glimps of
          widget code structure.
        </p>
        <div className="editor-wrapper">
          <div className="css">
            <h3>
              <span style={{marginRight: '10px'}}>CSS</span>
              <div>
                <Btn type="link" onClick={e => {
                  this.setState({css: this.getDefaultCss(), editorKey: Date.now()})
                }}>
                  <i className="fa fa-times"/> Clear
                </Btn>
                {
                  <Btn type="link" onClick={e => {
                    this.setState({css: this.props.css, editorKey: Date.now()})
                  }}>
                    <i className="fa fa-undo"/> Reset
                  </Btn>
                }

                <Btn type="link" onClick={e => {
                  let newMode = mode === 'simple' ? 'interactive' : 'simple';
                  let newCss = css;
                  if (newMode === 'simple') {
                    newCss = document.querySelector('.rse-Rule-block.rse-Rule-blockIsTop').innerText;
                  }
                  this.setState({mode: newMode, css: newCss});
                }}>
                  <i className="fa fa-code"/>
                  {mode === 'simple' ? 'Plain' : 'Interactive'}
                </Btn>
              </div>

            </h3>
            {
              mode === 'simple' &&
              <textarea
                key={editorKey}
                className="simple-css-editor" value={css} onChange={e => this.setState({css: e.target.value})}/>
            }
            {
              mode === 'interactive' &&
              <StyleEditor
                key={editorKey}
                defaultValue={css}
                outputFormats={'pretty'}
                onCopy={e => null}
              />
            }

          </div>
          <div className="html">
            <h3>
              <span>Html template</span>
              <div>
                {
                  <Btn className={tmpl ? '' : 'selected'} type="link" onClick={e => {
                    this.setState({tmpl: null, css: this.getDefaultCss(), editorKey: Date.now()})
                  }}>
                    <i className="fa fa-undo"/> Use Default
                  </Btn>
                }

                <Btn  className={tmpl ? 'selected' : ''} type="link" onClick={e => {
                  if(!tmpl) {
                    this.setState({tmpl: this.getDefaultTmpl(), css: this.getTmplCss(), editorKey: Date.now()});
                  }
                }}>
                  <i className="fa fa-code"/> Custom Template (ejs)
                </Btn>
              </div>
            </h3>
            <div className="html-code">
              {
                !!tmpl &&
                <textarea
                  key={editorKey}
                  className="simple-css-editor" value={tmpl} onChange={e => this.setState({tmpl: e.target.value})}/>
              }
              {
                !tmpl &&
                <SyntaxHighlighter language="html" wrapLines={true}>
                  {this.getHtml()}
                </SyntaxHighlighter>
              }
            </div>
          </div>


        </div>
        <div className="actions">
          <Btn type="reset" onClick={e => this.props.onCancel()}>Cancel</Btn>
          {/*<Btn type="reset" onClick={e => this.setState({css: '', editorKey: Date.now()})}>Clear CSS</Btn>*/}
          {/*<Btn type="reset" onClick={e => this.setState({css: this.props.css, editorKey: Date.now()})}>Reset</Btn>*/}
          {/*<Btn type="primary" onClick={e => this.props.onChange(css)}>OK</Btn>*/}
          <Btn type="primary" onClick={e => {
            const {css, mode} = this.state;
            let newCss = mode === 'simple' ? css : document.querySelector('.rse-Rule-block.rse-Rule-blockIsTop').innerText;
            this.props.onChange(newCss, tmpl)
          }}>OK</Btn>
        </div>
      </CssEditorWrapper>
    );
  }

  getDefaultTmpl() {
    return `<div class="widget-wrapper">
       <div class="widget-title"><%= layoutConfig.title %></div>
       <div class="item-list">
        <% items.forEach((article)=> { %>
            <div class="item">
                <img class="thumbnail" src="<%= article.thumbnail %>"/>
                <div class="item-inner">
                  <div class="title">
                      <%= article.title %>
                  </div>
                  <div class="publish_date">
                      <%= article.publish_date %>
                  </div>
                </div>
            </div>
        <% }) %>
        </div>
      </div>`
  }

  getTmplCss() {
    const {layout} = this.props.layoutConfig;
    return `
  .widget-wrapper { background: white; padding: 10px 10px;}
  .widget-title {
    font-weight: 700;
    padding: 28px 0 8px;
    font-size: 18px;
    line-height: 1.2;
  }
  .item-list {list-style-type: none}
  .item-list .item {margin: 15px 0; display: flex;}
  .item-list .item .thumbnail {width: 80px; height: auto; margin-right: 10px;}
  .item-list .item .item-inner .title {margin: 10px 0;}
  .item-list .item .item-inner .publish_date {}
  @media (max-width: 968px){

  }`;
  }

  getHtml() {
    const {layout} = this.props.layoutConfig;
    console.log(layout);
    let html = '';
    if (layout !== 'single_row') {
      html = `<div class="mc-widget-wrapper">
  <div class="title">Widget Title</div>
  <div class="item-list">
    <div class="item">
      <a class="item-inner" href="{{url}}">
        <div class="thumbnail"
             style="background-image: url(...)"></div>
        <div class="brief">
          <span class="section"></span>
          <span class="title"></span>
          <span class="publish_date"></span>
        </div>
      </a>
    </div>
    <-- other items -->
  </div>
</div>`
    } else {
      html = `<div class="mc-widget-wrapper">
  <h3 class="widget-title"></h3>
  <div class="item-list">
    <ul class="item-list-slider">
      <li className="item-wrapper">
        <div class="item thumbnail_top">
        <a class="item-inner">
          <div class="item thumbnail_top">
            <a class="item-inner" href="...">
              <div class="thumbnail">
                <div class="video-icon"></div>
              </div>
              <div class="brief">
                <div class="title"></div>
                <div class="abstract"></div>
                <div class="publish_date"></div>
              </div>
            </a>
          </div>
          </a>
        </div>
      </li>
      <-- other items -->
    </ul>
    <button class="arrow arrow-left"></button>
    <button class="arrow arrow-right"></button>
  </div>
</div>`
    }
    return html;
  }

  getDefaultCss() {
    const {layout} = this.props.layoutConfig;
    return `
  .mc-widget-wrapper {}
  .widget-title {}
  ${layout !== 'single_row' ? `.widget-body .item.main {}` : ''}
  .item-list {}
  .item-list .item {}
  .item-list .item .item-inner .thumbnail {}
  .item-list .item .item-inner .title {}
  .item-list .item .item-inner .publish_date {}
  ${layout !== 'single_row' ? `.section {}` : ''}
  ${layout === 'single_row' ? `.abstract {}` : ''}
  ${layout === 'single_row' ? `.arrow.arrow-left {}` : ''}
  ${layout === 'single_row' ? `.arrow.arrow-right {}` : ''}
  @media (max-width: 968px){

  }`;
  }
})
