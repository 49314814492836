import React from 'react'
import ReactDOM from 'react-dom';
import ReactServer from 'react-dom/server'
import MainLayout from '../layout/index';
import {withConsumer} from '../app/ApplicationContext';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import {withRouter} from 'react-router';
import {post} from '../utils/request';
import createTooltip from '../uikit/tooltip/index';
import {SelectStyled} from './select.style';
import {Spinner} from '../uikit/Loader/index.style';
import {ProgressBar, Btn, CondirmDialog, TabContainer, Hint, Table} from '../uikit/index';
import Sortable from '../uikit/Sortable/index';
import {FlexWrapper, RightPanel, PreviewWrapper, PreviewLayout, BrowserToolbar} from './step.design.style';
import {SettingPanel, TitleConfigItem, LayoutConfigItem, ItemConfigItem, AllocateConfigItem} from './step.design.style';
import {DesignConfigItem, GridSelectorWrapper, GridSelector, ItemTooltipWrapper} from './step.design.style';
import {DataProviderWrapper, DataProviderSelector, SlotGroupList, ResponseFields} from './step.design.style';
import {Taglist} from './index.style';
import CollectionEditor from './slotCollection';
import AdsCollectionEditor from './slotCollection.ad';
import CxenseSelector from './cxenseSelector';
import ThinkSelector from './thinkSelector';
import TagManager from './tagManager';
import SlotAllocator from './slotAllocator';
import CssEditor from './cssEditor';
import BuzRuleEditor from './buzRuleEditor';
import LangTitle from './langTitle';
import {recommenderTypes, algorithms, itemFields, defaultItemFields, facetMeta} from '../utils/metadata';
import {rankingModels, layouts, stickynessOptions, itemStyles} from '../utils/metadata';
import {getProperty} from '../utils/urlUtil';
import {serializeFilters} from '../utils/serializeUtil';
import {scroller} from 'react-scroll';
import widgetLoader from '../widgetLoader';
import {BlueJeans, colorCategory2, Grass1, MediumGray, Sunflower1} from '../app/StyleCommon'
import {TagList} from "../abtesting/step-variants.style";
import {filterTags} from "../utils/formatter";
import * as d3 from "d3";

export default withRouter(withConsumer(class extends React.Component {
    constructor(props) {
      super(props);
      const {appState: {session}} = props;
      const sites = session.sites;
      const site = props.widget.site || sites[0].key;
      const siteOption = sites.find(d => d.key === site) || {};
      this.state = {
        platform: 'design',
        widget: props.widget,
        context: {
          ...(siteOption.defaultContext || {}),
          meid: 'a61f9cfa-79a9-4ac7-ab2a-641e9d2e7cf2',
          site: site
        },
        settingTabIndex: 0
      };
      this.tooltip = this.createTagTooltip();
      window.itemTooltip = this.createItemTooltip();
      this.iframeRef = React.createRef();
    }

  componentDidCatch(error, info) {
    console.error(error, info);
  }

    componentDidMount() {
      this.normalizeSlotGroupMapping();
      this.refreshWidget();
    }

    componentWillReceiveProps(nextProps) {
      const {name, site} = this.state.widget;
      if (nextProps.widget.name !== name || nextProps.widget.site !== site) {
        const siteOption = sites.find(d => d.key === nextProps.widget.site) || {};
        const newContext = siteOption.defaultContext || {}
        this.setState({
          context: {
            ...this.state.context,
            url: newContext.url
          },
          widget: {
            ...this.state.widget,
            name: nextProps.widget.name,
            site: nextProps.widget.site
          }
        }, () => {
          if(nextProps.widget.site !== site) {
            this.refreshWidget();
          }
        });
      }
    }

    render() {
      const {recItems, error, loading, settingTabIndex} = this.state;
      const {widget, platform, context} = this.state;
      const {layoutConfig, site, slotGroups = [], cxenseWidget, thinkWidget, tags = []} = widget || {};
      const {title, headers, footer, layout, css, itemStyle, stickiness} = layoutConfig || {};
      const isMobile = window.innerWidth <= 768;
      let isMerewardsEDM = _.some(tags, d => d.indexOf('merewards_edm') > -1);
      return (
        <FlexWrapper>
          {
            !recItems && !error &&
            <ProgressBar
              withoutCache={true}
              fixcenter={true}
              url={`/api/recommend`}
              params={{
                widget: {
                  slotCount: this.countSlot(widget),
                  ...widget,
                },
                context: context
              }}
              successHandler={data => {
                this.setState({recItems: data.items, error: null}, () => this.refreshWidget());
              }}
              errorHandler={e => this.setState({error: e.message})}/>
          }
          <SettingPanel>
            <TabContainer
              renderAll={isMobile}
              activeIndex={settingTabIndex}
              onChange={i => this.setState({settingTabIndex: i})}
              hideMenu={!isMobile && !cxenseWidget && !thinkWidget && !slotGroups.length}
              tabTitles={['Layout',
                <span key="data">&nbsp;&nbsp;{isMobile ? 'Preview' : 'Data'} &nbsp;&nbsp;&nbsp;</span>]}>
              <div>
                <TitleConfigItem>
                  <label>Header (optional)</label>
                  <div className="multi-lang-input">
                    <input
                      type="text"
                      value={title}
                      placeholder={'Widget Title'}
                      onChange={e => this.setLangTitleSimple(e.target.value)}/>
                    <div className="lang-trigger" onClick={e => this.setLangTitle()}>
                      {/*{!!headers && headers.length > 1 && <span className="hint">{headers.length} lang</span>}*/}
                      {/*<i className="fa fa-language" aria-hidden="true"></i>*/}
                      {/*<i className="fa fa-ellipsis-v" aria-hidden="true"></i>*/}
                    </div>

                  </div>
                </TitleConfigItem>
                <ItemConfigItem>
                  <label>Item Style</label>
                  <SelectStyled
                    defaultLabel={'Select Item Style'}
                    selected={itemStyle}
                    data={itemStyles}
                    showGroupBatch={false}
                    itemRenderer={itemStyle => {
                      return this.renderItemStyle(itemStyle)
                    }}
                    onChange={itemStyle => {
                      this.layoutConfigChanged({...layoutConfig, itemStyle: itemStyle})
                    }}/>
                </ItemConfigItem>
                <LayoutConfigItem>
                  <label>Layout</label>
                  <SelectStyled
                    defaultLabel={'Select Layout'}
                    selected={layout}
                    data={layouts}
                    showGroupBatch={false}
                    itemRenderer={d => {
                      return (
                        <div className="layout-item" key={d.key}>
                          <img src={d.icon}/>
                          <div>
                            <div className="name">{d.label}</div>
                            <div className="description">{d.description}</div>
                          </div>
                        </div>
                      )
                    }}
                    onChange={layout => {
                      let layoutItem = layouts.find(d => d.key === layout);
                      const {layoutConfig} = this.state.widget || {};
                      const {layoutParams} = layoutConfig || {};
                      let {row = 1, column = 1} = layoutParams || {};
                      if (layoutItem.maxColumn && column > layoutItem.maxColumn) {
                        column = layoutItem.maxColumn;
                      }
                      if (layoutItem.maxRow && row > layoutItem.maxRow) {
                        row = layoutItem.maxRow;
                      }
                      this.layoutConfigChanged({
                        ...layoutConfig,
                        layout: layout,
                        layoutParams: {...layoutParams, row, column}
                      }, true);
                    }}/>

                  {this.renderGridSelector()}
                </LayoutConfigItem>
                <AllocateConfigItem>
                  <Btn type="primary" onClick={e => this.showSlotAllocator()}>
                    <i className="fa fa-hand-o-right"/> Populate Slots</Btn>
                </AllocateConfigItem>
                <TitleConfigItem>
                  <label>Footer (optional)</label>
                  <div>
                    <input
                      type="text"
                      value={footer}
                      placeholder={'Footer Template'}
                      onChange={e => this.setLangTitleSimple(e.target.value, 'footer')}/>
                  </div>
                </TitleConfigItem>
                {/*<ItemConfigItem>*/}
                  {/*<label>Sticky Widget</label>*/}
                  {/*<SelectStyled*/}
                    {/*up={false}*/}
                    {/*defaultLabel={'None'}*/}
                    {/*selected={stickiness || 'n'}*/}
                    {/*data={[*/}
                      {/*{key: '', label: 'None'},*/}
                      {/*...stickynessOptions*/}
                    {/*]}*/}
                    {/*itemRenderer={d => {*/}
                      {/*return (*/}
                        {/*<div className="stickyness-item" key={d.key}>*/}
                          {/*<img src={d.icon}/>*/}
                          {/*<div>*/}
                            {/*<div className="name">{d.label}</div>*/}
                            {/*<div className="description">{d.description}</div>*/}
                          {/*</div>*/}
                        {/*</div>*/}
                      {/*)*/}
                    {/*}}*/}
                    {/*showGroupBatch={false}*/}
                    {/*onChange={stickiness => {*/}
                      {/*this.layoutConfigChanged({...layoutConfig, stickiness: stickiness})*/}
                    {/*}}/>*/}
                {/*</ItemConfigItem>*/}
              </div>
              <div>
                {!isMobile && this.renderDataProvider()}
                {isMobile && this.renderPreviewPanel()}
              </div>
            </TabContainer>
          </SettingPanel>
          {
            !isMobile && this.renderPreviewPanel()
          }
        </FlexWrapper>
      )
    }

    renderPreviewPanel() {
      const {recItems, error, loading} = this.state;
      const {widget, platform, context} = this.state;
      const {layoutConfig, site, slotGroups = [], cxenseWidget, thinkWidget, tags = []} = widget || {};
      const {title, layout, css, itemStyle, stickiness} = layoutConfig || {};
      const widgetClean = _.omit(widget, ['items']);
      return (
        <RightPanel>
          <PreviewWrapper
            className={`${['design', 'darkmode'].indexOf(platform) >= 0 && stickiness ? 'stickiness' : ''}`}>
            <div className="preview-header">
              <div className="preview-modes">
                {/*<span>Preview</span>*/}
                <span className="platform-switch">
                    <a className={platform === 'design' ? 'selected' : ''}
                       // onClick={e => this.previewModeChange('design')}>
                       onClick={e => this.setState({recItems: null, error: null})}>
                      {/*<img src="/img/assets/preview.png"/>*/}
                      <i className="fa fa-refresh" style={{marginRight: '10px'}}/>
                      <span>Refresh</span>
                    </a>
                  {/*{*/}
                  {/*  site === 'cna' && false &&*/}
                  {/*  <a className={platform === 'darkmode' ? 'selected' : ''}*/}
                  {/*     onClick={e => this.previewModeChange('darkmode')}>*/}
                  {/*    <i className="fa fa-moon-o" aria-hidden="true"></i> &nbsp;<span>Dark Mode</span></a>*/}
                  {/*}*/}

                  {/*  <a className={platform === 'mobile' ? 'selected' : ''}*/}
                  {/*     onClick={e => this.previewModeChange('mobile')}>*/}
                  {/*    <img src="/img/assets/mobile.png"/> <span>Mobile</span></a>*/}
                  {/*  <a className={platform === 'desktop' ? 'selected' : ''}*/}
                  {/*     onClick={e => this.previewModeChange('desktop')}>*/}
                  {/*    <img src="/img/assets/desktop.png"/> <span>Desktop</span></a>*/}
                  </span>
              </div>
              <div className="custom-actions">
                <a
                  type="reset"
                  className="tag-list-button"
                  // onMouseOver={tags.length ? this.tooltip.onMouseOver({type: 'tag', data: widget}) : undefined}
                  // onMouseLeave={tags.length ? this.tooltip.onMouseOut() : undefined}
                  onClick={e => this.setCustomTags()}>
                  {
                    !!tags.length &&
                    <Taglist className="tag-list">
                      {tags.map(t => {
                        return <span className={`tag ${t.split('::')[0]}`} key={t}>{t.split('::').pop()}</span>
                      })}
                    </Taglist>
                  }
                  <img src="/img/icons/tag.png"/>
                  <span>Tags & Description</span>
                </a>
                <a type="reset" onClick={e => this.setCustomCss()}>
                  <img src="/img/layouts/css.png"/>
                  <span>Custom Style</span>
                </a>
              </div>
            </div>
            <BrowserToolbar>
              <span>
                Context
                <Hint
                  useFixed={true} width={190} content={
                  <div style={{textAlign: 'left', color: 'white', padding: '10px'}}>
                    Include a URL to preview sample recommendations below. Recommendations will be based on model selected in slot groups.
                  </div>
                }/>
              </span>
              <input className="addressBox"
                     type="text"
                     placeholder="Context url..."
                     name="context_url"
                     value={context.url}
                     onFocus={e => e.target.select()}
                // onChange={e => this.setState({context: {...context, url: e.target.value}})}/>
                     onChange={e => this.updateContextUrl(e.target.value)}/>
            </BrowserToolbar>
            <div className="transform-container">
              <div className={`preview-canvas`}>
                {
                  !!error && <div className="error-msg">{error}</div>
                }
                {this.renderLayoutError()}

                <PreviewLayout
                  className={`preview-layout mode-${platform}`}
                  platform={platform}
                  site={site}>
                  {
                    (platform === 'mobile' || platform === 'desktop') &&
                    <iframe
                      title="Widget Preview"
                      src=""
                      name="myIframe"
                      width="100%" height="100%">
                      <form
                        ref={this.iframeRef}
                        target="myIframe"
                        action={`${process.env.REACT_APP_SERVER_URL}/api/previewInSite`}
                        method="post">
                        <input name="widgetStr" type="hidden" value={JSON.stringify(widgetClean)}/>
                        <input name="contextStr" type="hidden" value={JSON.stringify(context)}/>
                      </form>
                    </iframe>
                  }
                  {
                    (platform === 'design' || platform === 'darkmode') &&
                    <div className="widget-loader" id="widget-loader">
                      <div className="widget-instance" id={`${widget.id}`}></div>
                    </div>
                  }
                </PreviewLayout>
              </div>
            </div>
          </PreviewWrapper>
        </RightPanel>
      );
    }

    renderDataProvider() {
      const {widget, context} = this.state;
      const {site, slotGroups = [], cxenseWidget, thinkWidget, ranking, select, otherSettings = {}} = widget || {};
      const {autoRanking = true, rankingAlgorithm} = ranking || {};
      return (
        <DataProviderWrapper>
          <SlotGroupList>
            <div className="slot-list-header">
              {!!slotGroups.length && <label>Slot Groups</label>}
              {!slotGroups.length && <label>Data Provider</label>}

              {
                !!slotGroups.length &&
                <Btn type="link" onClick={e => this.addSlotGroup()}><i className="fa fa-plus"/> Add</Btn>
              }
            </div>

            {
              !slotGroups.length && !cxenseWidget && !thinkWidget &&
              <DataProviderSelector className="provider-select-box">
                <div className="provider" onClick={e => this.addSlotGroup()}>
                  <span><img src="/img/icons/mediacorp.jpg"/></span><span>In house</span>
                </div>
                <div className="provider" onClick={e => this.selectCxenseWidget()}>
                  <span><img src="/img/icons/cxense.jpg"/></span><span>Cxense</span>
                </div>
                <div className="provider" onClick={e => this.selectThinkWidget()}>
                  <span><img src="/img/icons/think_analytics.png"/></span><span>Think Analytics</span>
                </div>
              </DataProviderSelector>
            }
            {
              !!cxenseWidget &&
              <div className="cxense-widget">
                <div className="cxense-widget-info" onClick={e => this.selectCxenseWidget()}>
                  <img src="/img/icons/cxense.jpg"/><strong>{cxenseWidget.description}</strong>
                </div>
                <div>
                  <Btn type="link" onClick={e => this.saveWidget({...widget, cxenseWidget: null})}>
                    <i className="fa fa-trash"/>
                  </Btn>
                </div>
              </div>
            }
            {
              !!thinkWidget &&
              <div className="cxense-widget">
                <div className="cxense-widget-info" onClick={e => this.selectThinkWidget()}>
                  <img src="/img/icons/think_analytics.png"/><strong>{thinkWidget.id}</strong>
                </div>
                <div>
                  <Btn type="link" onClick={e => this.saveWidget({...widget, thinkWidget: null})}>
                    <i className="fa fa-trash"/>
                  </Btn>
                </div>
              </div>
            }
            {
              !!slotGroups.length &&
              <Sortable sortableSelector=".slot-group-item" onReplacePosition={(a, b) => this.swapSlotGroup(a, b)}>
                {slotGroups.map((d, i) => {
                  let algoOption = algorithms.find(a => a.key === d.algorithm) || {};
                  let backfillAlgoOption = algorithms.find(a => a.key === (d.backfill || {}).algorithm) || {};
                  return (
                    <div key={d.name} className="slot-group-item">
                      <div className="slot-group-info">
                        <div className="header">
                          <div className="name" onClick={e => this.showSlotGroup(d, 'update_slot_group')}>
                            {d.name}
                          </div>
                          <div className="slot-group-actions">
                            <Btn
                              type="link"
                              title="Remove"
                              onClick={e => this.removeSlotGroup(d, i)}>
                              <i className="fa fa-trash"/>
                            </Btn>
                          </div>
                        </div>
                        <div className="desc" onClick={e => this.showSlotGroup(d, 'update_slot_group')}>
                          <span dangerouslySetInnerHTML={{__html: serializeFilters(d)}}/>
                          , <strong>{algoOption.label || 'Model not selected'}</strong>
                        </div>
                      </div>
                      {/*<div className="targeting-list segment-targeting-list">*/}
                      {/*  <div*/}
                      {/*    className="targeting-header"*/}
                      {/*    onClick={e => {*/}
                      {/*      let newTargeting = _.cloneDeep(_.omit(d, ['backfill', 'targetings']));*/}
                      {/*      newTargeting.name = d.name;*/}
                      {/*      newTargeting.type = 'custom_targeting';*/}
                      {/*      newTargeting.id = Date.now();*/}
                      {/*      this.showSlotGroup(newTargeting, 'create_targeting', d);*/}
                      {/*    }}>*/}
                      {/*    <span><i className="fa fa-bullseye"/> Custom Targetings</span>*/}
                      {/*    <span><i className="fa fa-plus"/>*/}
                      {/*    </span>*/}
                      {/*  </div>*/}
                      {/*  {*/}
                      {/*    !(d.targetings || []).length &&*/}
                      {/*    <div className="no-targeting-data">No custom targeting rule set yet.</div>*/}
                      {/*  }*/}
                      {/*  {*/}
                      {/*    (d.targetings || []).map(targeting => {*/}
                      {/*      let targetingAlgo = algorithms.find(a => a.key === targeting.algorithm) || {};*/}
                      {/*      return (*/}
                      {/*        <div className="targeting-item" key={targeting.id}>*/}
                      {/*          <div className="left" onClick={e => {this.showSlotGroup(targeting, 'update_targeting', d)}}>*/}
                      {/*            <span className="name">*/}
                      {/*              {*/}
                      {/*                !!targeting.segment &&*/}
                      {/*                <strong>Segment: {targeting.segment.name}</strong>*/}
                      {/*              }*/}
                      {/*              {*/}
                      {/*                !!targeting.daypart &&*/}
                      {/*                <strong>Daypart: {targeting.daypart.label}</strong>*/}
                      {/*              }*/}
                      {/*              {*/}
                      {/*                !!targeting.kv &&*/}
                      {/*                <strong>Key-Value: {targeting.kv}</strong>*/}
                      {/*              }*/}
                      {/*              -&nbsp;*/}
                      {/*              {targetingAlgo.label}*/}
                      {/*            </span>*/}
                      {/*          </div>*/}
                      {/*          <div className="right">*/}
                      {/*            <Btn type="blacklink" onClick={e => {*/}
                      {/*              d.targetings = d.targetings.filter(x => x.id !== targeting.id);*/}
                      {/*              this.updateSlotGroup(i, d);*/}
                      {/*            }}>*/}
                      {/*              <i className="fa fa-trash"/>*/}
                      {/*            </Btn>*/}
                      {/*          </div>*/}
                      {/*        </div>*/}
                      {/*      )*/}
                      {/*    })*/}
                      {/*  }*/}
                      {/*</div>*/}
                      <div className="backfill">
                        <div className="left" onClick={e => {
                          if (!d.backfill) {
                            let newBackfill = _.cloneDeep(_.omit(d, ['backfill', 'targetings']));
                            if(d.category === 'ads') {
                              newBackfill  = this.newSlotGroupTemplate();
                            }
                            newBackfill.name = 'Fallback';
                            this.showSlotGroup(newBackfill, 'create_backfill', d)
                          } else {
                            this.showSlotGroup(d.backfill, 'update_backfill', d)
                          }

                        }}>
                          <i className="fa fa-life-ring"/>
                          {!!d.backfill && <span className="name">Fallback - {backfillAlgoOption.label}</span>}
                          {!d.backfill && <span className="name">No fallback set, click to modify.</span>}
                        </div>
                        <div className="right">
                          {!!d.backfill &&
                          <Btn type="link" onClick={e => this.updateSlotGroup(i, _.omit(d, ['backfill']))}><i
                            className="fa fa-trash"/></Btn>}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Sortable>
            }
          </SlotGroupList>
          <ResponseFields>
            <div className="field-list-header">
              <label>Response Fields</label>
            </div>
            <SelectStyled
              disabled={otherSettings.recommendation_mode === 'ta_boost'}
              defaultLabel={'Response Field'}
              selected={select || defaultItemFields}
              multi={true}
              data={itemFields}
              showGroupBatch={false}
              extraStyle={`.menu {max-height: 450px; overflow: auto}`}
              onChange={select => {
                this.saveWidget({...widget, select: select}, true);
              }}/>
            <label style={{marginTop: '20px', display: 'flex', alignItems: 'center'}}>
              <input
                type="checkbox"
                style={{marginRight: '10px'}}
                checked={otherSettings.recommendation_mode === 'ta_boost'} onChange={e => {
                this.saveWidget({
                  ...widget,
                  otherSettings: {
                    ...otherSettings,
                    recommendation_mode: e.target.checked ? 'ta_boost' : 'boost'
                  }
                }, true);
              }}/>
              Return Think Analytics compatible fields
            </label>
          </ResponseFields>
        </DataProviderWrapper>
      )
    }

    renderGridSelector() {
      const {session} = this.props.appState || {};
      const {widget, platform, gridHover} = this.state;
      const {layoutConfig, slotGroups} = widget || {};
      const {layout, layoutParams} = layoutConfig || {};
      const {row = 1, column = 1, slotGroupMapping = {}} = layoutParams || {};
      const {hoverRow = 0, hoverColumn = 0} = gridHover || {};
      const layoutOptions = layouts.find(d => d.key === layout);
      let {maxColumn = Math.round(column * 1.5), maxRow = Math.round(row * 1.8), main, thumbnail} = layoutOptions || {};
      if(row === 1 && maxColumn < 5) {
        maxColumn = 5;
      }
      return (
        <GridSelectorWrapper>
          <div className="selector-wrapper-title">
            <span>
              <span>Dimension: ({row} x {column})</span>
              <span className="sub">Define widget dimensions and populate slots</span>
              {
                !session.isExternal &&
                <span style={{marginLeft: '10px', fontSize: '10px', color: Sunflower1}}>{this.renderAlgorithm()}</span>
              }
            </span>
          </div>
          <div className={`table-wrapper ${(column > 10 || layout === 'single_row') ? 'wide': 'default'}`}>
          <GridSelector
            className={layout}
            onMouseEnter={e => this.setState({gridHover: {}})}
            onMouseLeave={e => this.setState({gridHover: {}})}>
            <tbody>
            {main === 'left' && <tr className="main">
              <td className="main" rowSpan={maxRow}>{this.renderGridCellLabel('main')}</td>
            </tr>}
            {main === 'top' && <tr className="main">
              <td className="main" colSpan={maxColumn}>{this.renderGridCellLabel('main')}</td>
            </tr>}
            {_.range(1, maxRow + 1).map((rIndex) => {
              return (
                <tr key={rIndex} className={`${rIndex <= row ? 'active' : ''}  ${rIndex <= hoverRow ? 'hover' : ''}`}>
                  {_.range(1, maxColumn + 1).map((cIndex) => {
                    return (
                      <td
                        key={rIndex + '-' + cIndex}
                        className={`${cIndex <= column ? 'active' : ''} ${cIndex <= hoverColumn ? 'hover' : ''}`}
                        onMouseOver={e => this.setState({gridHover: {hoverRow: rIndex, hoverColumn: cIndex}})}
                        onClick={e => {
                          this.layoutConfigChanged({
                            ...layoutConfig,
                            layoutParams: {...layoutParams, row: rIndex, column: cIndex}
                          }, true)
                        }}>
                        <span className="assignment">{this.renderGridCellLabel(`p_${rIndex}_${cIndex}`)}</span>
                      </td>
                    )
                  })}
                  {main === 'right' && rIndex === 1 &&
                  <td className="main" rowSpan={maxRow}>{this.renderGridCellLabel('main')}</td>}
                </tr>
              )
            })}
            </tbody>
          </GridSelector>
          </div>
        </GridSelectorWrapper>
      )
    }

    renderLayoutError() {
      const {widget} = this.state;
      const {layoutConfig, tags = []} = widget || {};
      const {layout, layoutParams, itemStyle} = layoutConfig || {};
      const {row = 1, column = 1} = layoutParams || {};

      let specialStyle = [
        {tag: 'CNA_Web_Home_MostPopular', layout: 'grid_main_top', itemStyle: 'thumbnail_left'},
        {tag: 'CNA_Web_Home_StoriesForYou', layout: 'grid_main_top', itemStyle: 'thumbnail_top'},
        {tag: 'CNA_Web_Home_ElsewhereFromMediacorp', layout: 'grid', itemStyle: 'thumbnail_top'},
        {tag: 'CNA_Web_Home_MostWatched', layout: 'single_row', itemStyle: 'thumbnail_top'},
      ].find(d => (tags || []).some(tag => tag.split('::').pop() === d.tag));
      if(specialStyle && specialStyle.layout !== layout) {
        let layoutOption = layouts.find(d => d.key === specialStyle.layout);
        return <div className="error-msg">Layout not matching: please select {layoutOption.label} layout to matching {specialStyle.tag}</div>;
      } else if(specialStyle && specialStyle.itemStyle !== itemStyle) {
        let itemStyleOption = itemStyles.find(d => d.key === specialStyle.itemStyle);
        return <div className="error-msg">Item style not matching: please select {itemStyleOption.label} layout to matching {specialStyle.tag}</div>;
      }
      return null;
    }

    renderGridCellLabel(positionKey) {
      const {widget} = this.state;
      const {layoutConfig, slotGroups = [], cxenseWidget, thinkWidget, ranking} = widget || {};
      const {autoRanking} = ranking || {};

      const {layout, layoutParams} = layoutConfig || {};
      const {row = 1, column = 1, slotGroupMapping = {}} = layoutParams || {};
      const selectedSlotGroupName = slotGroupMapping[positionKey] || (slotGroups[0] || {}).name;
      const slotNameIndex = slotGroups.findIndex(d => d.name === selectedSlotGroupName);
      const bgColor = colorCategory2[slotNameIndex];
      if (!slotGroups.length) {
        return cxenseWidget ? 'Cxense' : (thinkWidget ? 'TA' : '');
      } else if (autoRanking) {
        return <div style={{color: Grass1}}>BOOST</div>;
      }
      return (
        <div style={{color: bgColor}}>
          {/*{slotGroupMapping[positionKey] || (slotGroups[0] || {}).name}*/}
          {slotGroupMapping[positionKey] || ''}
        </div>
      )
    }

    renderItemStyle(itemStyle) {
      const {key, label} = itemStyle;
      return (
        <div className="item">
          <p>{label}</p>
          <div className={`item-style ${key}`}>
            {key !== 'text_only' && <i className="fa fa-picture-o"/>}
            <div>
              <div className="title">
                {_.range(0, 30).map((i) => <span key={i}>&nbsp;</span>)}
              </div>
              <div className="description">
                {_.range(0, 250).map((i) => <span key={i}>&nbsp;</span>)}
              </div>
            </div>
          </div>
        </div>
      )
    }

  renderAlgorithm() {
    const {widget:d} = this.state;
    if(d.cxenseWidget || d.thinkWidget) {
      return null;
      // return <span className="tag algo">Cxense</span>;
    } else {
      const {layoutParams = {}} = d.layoutConfig || {};
      let usedSlots = _.uniq(Object.values(layoutParams.slotGroupMapping || {}));
      let algos = _.uniq((d.slotGroups || []).filter(s => usedSlots.indexOf(s.name) >= 0).map(s => s.algorithm));
      if(algos.length > 1) {
        return <span className="tag algo">Hybrid</span>
      }
      return algos.map(algo => {
        let meta = algorithms.find(d => d.key === algo);
        return <span className="tag algo" key={algo}>{meta ? meta.label : algo}</span>
      });
    }
  }

    updateContextUrl(newUrl) {
      const {context} = this.state;
      context.url = newUrl;
      let contentId = (newUrl.split('?')[0]).split('-').pop();
      if (!isNaN(contentId)) {
        context.content_id = contentId;
      } else {
        context.content_id = undefined;
      }
      const property = getProperty(newUrl) || {};
      context.site = property.key;
      this.setState({context: context, recItems: null});
    }

    setCustomCss() {
      const {widget} = this.state;
      const {layoutConfig} = widget || {};
      const {css, tmpl} = layoutConfig || {};
      const confirmInfo = {
        type: 'form',
        backgroundClose: true,
        title: <strong>Custom CSS Styles</strong>,
        width: `${Math.min(window.innerWidth * 0.9, 1100)}px`,
        hideCancel: true,
        hideOK: true,
        onCancel: () => CondirmDialog.closeAll(),
        dialogBody: <CssEditor
          layoutConfig={layoutConfig}
          css={css}
          tmpl={tmpl}
          onChange={(css, tmpl) => {
            this.layoutConfigChanged({...layoutConfig, css: css, tmpl: tmpl});
            CondirmDialog.closeAll();
          }}
          onCancel={() => {
            CondirmDialog.closeAll();
          }}
        />,
      }
      ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById('global-confirm'));
    }

    setCustomTags() {
      const {widget} = this.state;
      const {tags = [], description} = widget || {};
      const confirmInfo = {
        type: 'form',
        backgroundClose: true,
        title: <strong>Tags & Description</strong>,
        width: '900px',
        hideCancel: true,
        hideOK: true,
        onCancel: () => CondirmDialog.closeAll(),
        dialogBody: <TagManager
          tags={tags}
          description={description}
          onChange={(tags, description) => {
            CondirmDialog.closeAll();
            return this.saveWidget({...widget, tags: tags, description: description})
          }}
          onCancel={() => {
            CondirmDialog.closeAll();
          }}
        />,
      }
      ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById('global-confirm'));
    }

    setLangTitleSimple(value, pos = 'header') {
      if(value && value.toLowerCase().indexOf('script') >= 0) {
        return this.setState({error: 'Script is not allowed in the template'});
      }
      const {widget} = this.state;
      const {layoutConfig} = widget;
      let {title, headers, footer} = layoutConfig;
      if(pos === 'footer') {
        this.layoutConfigChanged({...layoutConfig, footer: value})
      } else {
        if(!headers || !headers.length) {
          headers = [{lang: 'en', title: value}];
        }
        headers[0].title = value;
        this.layoutConfigChanged({...layoutConfig, title: value, headers: headers})
      }
    }

    setLangTitle() {
      const {widget} = this.state;
      const {layoutConfig} = widget;
      const confirmInfo = {
        type: 'form',
        backgroundClose: true,
        title: <strong>Widget Title</strong>,
        width: '900px',
        hideCancel: true,
        hideOK: true,
        onCancel: () => CondirmDialog.closeAll(),
        dialogBody: <LangTitle
          layoutConfig={layoutConfig}
          onChange={headers => {
            CondirmDialog.closeAll();
            return this.saveWidget({...widget, layoutConfig: {
              ...layoutConfig,
              title: headers[0].title,
              headers: headers
            }})
          }}
          onCancel={() => {
            CondirmDialog.closeAll();
          }}
        />,
      }
      ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById('global-confirm'));
    }

    countSlot(widget) {
      widget = widget || this.state.widget;
      const {layoutConfig} = widget || {};
      const {layout, layoutParams} = layoutConfig || {};
      const {row = 1, column = 1} = layoutParams || {};
      const layoutOptions = layouts.find(d => d.key === layout);
      const {main} = layoutOptions || {};
      let slotCount = row * column;
      if (main) {
        slotCount += 1;
      }
      return slotCount;
    }

    autoScroll() {
      const {widget} = this.state;
      setTimeout(() => {
        scroller.scrollTo(`${widget.id}`, {
          duration: 1000,
          delay: 50,
          smooth: "easeInOutQuint",
          offset: 200,
          containerId: 'widget-loader'
        })
      }, 100)
    }

    layoutConfigChanged(layoutConfig, reload) {
      const {widget} = this.state;
      const {layoutParams = {}} = layoutConfig;
      const newWidget = {
        ...widget,
        layoutConfig: {
          ...widget.layoutConfig,
          ...layoutConfig
        }
      };
      let newSlotGroupMapping = this.normalizeSlotGroupMapping(newWidget);
      newWidget.layoutConfig.layoutParams.slotGroupMapping = newSlotGroupMapping;
      newWidget.slotCount = this.countSlot(newWidget);
      this.saveWidget(newWidget, reload);
      if (!reload) {
        this.refreshWidget(newWidget);
      }
    }

    normalizeSlotGroupMapping(widget, changingMap = {}) {
      widget = widget || this.state.widget;
      const {layoutConfig, slotGroups = []} = widget || {};
      if (!slotGroups.length) {
        return {};
      }
      const {layout, layoutParams} = layoutConfig || {};
      const {row = 1, column = 1, slotGroupMapping = {}} = layoutParams || {};
      const layoutOption = layouts.find(d => d.key === layout);
      let newMapping = {};
      if (layoutOption.main) { //if layout has main slot
        newMapping.main = slotGroupMapping.main; // || slotGroups[0].name;
      }
      for (var i = 1; i <= row; i++) {
        for (var j = 1; j <= column; j++) {
          let positionKey = `p_${i}_${j}`;
          let oldValue = slotGroupMapping[positionKey];
          let newValue = changingMap[oldValue] || oldValue;
          // if (!slotGroups.find(d => d.name === newValue)) {
          //   newValue = slotGroups[0].name;
          // }
          // newMapping[positionKey] = newValue || slotGroups[0].name;
          newMapping[positionKey] = newValue; //|| slotGroups[0].name;
        }
      }
      layoutParams.slotGroupMapping = newMapping;
      return newMapping;
    }

    previewModeChange(newMode) {
      const {widget} = this.state.data || {};
      this.setState({platform: newMode}, () => {
        this.refreshWidget();
        if (newMode !== 'all') {
          this.autoScroll();
        }
      })
    }

    refreshWidget(newWidget) {
      const {recItems, platform} = this.state;
      const widget = newWidget || this.state.widget;
      const darkmode = platform === 'darkmode';
      const isWebView = platform === 'mobile';
      if (platform === 'mobile' || platform === 'desktop') {
        if (this.iframeRef.current) {
          setTimeout(() => {
            this.iframeRef.current.submit();
          }, 100);
        }
      } else {
        widget.slotCount = widget.slotCount || this.countSlot(widget);
        window.recApp.renderWidget({
          ...widget, items: recItems
        }, {
          debug: true,
          dark_mode: darkmode,
          isWebView: isWebView,
          inWidgetBuilder: true
        });
      }
    }

    saveWidget(widget, reload, callback) {
      this.props.onChange(widget);
      this.setState({widget: widget, error: null}, () => {
        if (reload) {
          this.setState({recItems: null}); //to refresh the widget items;
        }
        callback && callback();
      });
    }

    selectCxenseWidget(popupConfig) {
      const {widget} = this.state;
      const {cxenseWidget} = widget;
      const {mountPoint, callback: confirmCallback} = popupConfig || {};
      const confirmInfo = {
        type: 'form',
        backgroundClose: true,
        title: <strong>Select Cxense Widget</strong>,
        width: '680px',
        hideCancel: true,
        hideOK: true,
        onCancel: () => CondirmDialog.closeAll(mountPoint),
        dialogBody: <CxenseSelector
          site={widget.site}
          selected={cxenseWidget ? [cxenseWidget.id] : []}
          onChange={cxenseWidget => {
            this.saveWidget({...widget, cxenseWidget: cxenseWidget, slotGroups: []}, true);
            CondirmDialog.closeAll(mountPoint);
            confirmCallback && confirmCallback(cxenseWidget);
          }}
          onCancel={() => {
            CondirmDialog.closeAll(mountPoint);
          }}
        />,
      }
      ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById(mountPoint || 'global-confirm'));
    }
    selectThinkWidget(popupConfig) {
      const {widget} = this.state;
      const {thinkWidget} = widget;
      const {mountPoint, callback: confirmCallback} = popupConfig || {};
      const confirmInfo = {
        type: 'form',
        backgroundClose: true,
        title: <strong>Select Think Analytics Widget</strong>,
        width: '680px',
        hideCancel: true,
        hideOK: true,
        onCancel: () => CondirmDialog.closeAll(mountPoint),
        dialogBody: <ThinkSelector
          site={widget.site}
          selected={thinkWidget ? [thinkWidget.id] : []}
          onChange={thinkWidget => {
            this.saveWidget({...widget, thinkWidget: thinkWidget, slotGroups: []}, true);
            CondirmDialog.closeAll(mountPoint);
            confirmCallback && confirmCallback(thinkWidget);
          }}
          onCancel={() => {
            CondirmDialog.closeAll(mountPoint);
          }}
        />,
      }
      ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById(mountPoint || 'global-confirm'));
    }

    addSlotGroup(popupConfig) {
      const newGroup = this.newSlotGroupTemplate(popupConfig);
      this.showSlotGroup(newGroup, 'create_slot_group', null, popupConfig);
    }

    newSlotGroupTemplate(popupConfig) {
      const {widget} = this.state;
      const {slotGroups = []} = widget || {};
      const slotGroupNums = slotGroups.map(s => {
        let matches = /^slot group ([\d]+)$/ig.exec(s.name);
        if (!matches) {
          return 0;
        } else {
          return Number(matches[1]) || 0;
        }
      });
      let curIncr = _.max(slotGroupNums) || 0;

      let newGroup = {
        name: `Slot Group ${curIncr + 1}`,
        resourceType: ['article'],
        algorithm: algorithms[0].key,
        site: [widget.site],
        maxAge: 90,
        maxAgeUnit: 'day',
        weight: (1 - _.sum(slotGroups.map(d => Number(d.weight) || 0))),
      }
      if(popupConfig && popupConfig.category === 'ads') {
        newGroup.backfill = {
          ...newGroup,
          name: 'Backfill'
        };
        newGroup.category = popupConfig.category;
        newGroup.algorithm = 'ads_rec_default';
        newGroup.name = newGroup.name.replace('Slot', 'Ads');
        newGroup.resourceType = ['sponsor_ads'];
        newGroup.freshnessKey = 'CreatedDateTime';
        delete newGroup.site;
      }
      return newGroup;
    }

    showSlotGroup(slotGroup, mode, parentSlotGroup, popupConfig) {
      const {session} = this.props.appState || {};
      const {widget} = this.state;
      const {site, slotGroups = [], ranking} = widget || {};
      const {autoRanking} = ranking || {};
      const {mountPoint, callback: confirmCallback, autoRanking: autoRankingEdited} = popupConfig || {};
      this.setState({editingSlotGroup: slotGroup});
      const isAds = slotGroup.category === 'ads';
      let DataSelectionComponent = isAds ? AdsCollectionEditor : CollectionEditor;

      const confirmInfo = {
        type: 'form',
        backgroundClose: true,
        title: (
          <strong>
            {mode === 'create_slot_group' && `Create ${isAds ? 'Ads ' : ''}Slot Group`}
            {mode === 'update_slot_group' && `Update ${isAds ? 'Ads ' : ''}Slot Group`}
            {mode === 'create_backfill' && 'Create Fallback Rules'}
            {mode === 'update_backfill' && 'Update Fallback Rules'}
            {mode === 'create_targeting' && 'Create Segment Targeting'}
            {mode === 'update_targeting' && 'Update Segment Targeting'}
          </strong>
        ),
        width: '1080px',
        hideCancel: true,
        hideOK: true,
        onCancel: () => CondirmDialog.closeAll(mountPoint),
        dialogBody: <DataSelectionComponent
          isAdmin={session.isAdmin}
          isExternal={session.isExternal}
          deploySite={site}
          parentSlotGroup={parentSlotGroup}
          rankingAlgorithmDisabled={typeof autoRankingEdited === 'undefined' ? autoRanking : autoRankingEdited}
          slotGroup={_.cloneDeep(slotGroup)}
          onConfirm={(retSlotGroup) => {
            const {editingSlotGroup} = this.state || {};
            delete widget.cxenseWidget; // cxenseWidget and slotGroup can't coexist;
            delete widget.thinkWidget; // thinkWidget and slotGroup can't coexist;
            if (mode === 'create_slot_group') {
              this.saveWidget({...widget, slotGroups: slotGroups.concat([retSlotGroup])}, false)
              confirmCallback && confirmCallback(retSlotGroup);
            } else if (mode === 'update_slot_group') {
              if (retSlotGroup.name && retSlotGroup.name !== slotGroup.name) {
                this.normalizeSlotGroupMapping(widget, {[slotGroup.name]: retSlotGroup.name});
              }
              const oldSlotGroup = _.cloneDeep(slotGroup);
              Object.assign(slotGroup, retSlotGroup);
              confirmCallback && confirmCallback(retSlotGroup, oldSlotGroup);
              this.saveWidget(widget);
            } else if (mode === 'create_backfill' || mode === 'update_backfill') {
              parentSlotGroup.backfill = retSlotGroup;
              this.saveWidget(widget)
            } else if (mode === 'create_targeting') {
              parentSlotGroup.targetings = parentSlotGroup.targetings || [];
              parentSlotGroup.targetings.push(retSlotGroup);
              this.saveWidget(widget)
            } else if (mode === 'update_targeting') {
              let updateIndex = parentSlotGroup.targetings.find(t => t.id === retSlotGroup.id);
              parentSlotGroup.targetings.splice(updateIndex, 1, retSlotGroup); //replace the item.
              this.saveWidget(widget)
            }
            this.setState({recItems: null}); //to refresh the widget items;
            CondirmDialog.closeAll(mountPoint);
          }}
          onCancel={() => {
            CondirmDialog.closeAll(mountPoint);
          }}
        />,
      }
      ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById(mountPoint || 'global-confirm'));
    }

    showSlotAllocator() {
      const {widget} = this.state;
      const {session} = this.props.appState || {};
      const confirmInfo = {
        type: 'form',
        backgroundClose: false,
        title: <strong>Populate Slots</strong>,
        width: 'auto',
        hideCancel: true,
        hideOK: true,
        onCancel: () => CondirmDialog.closeAll(),
        dialogBody: <SlotAllocator
          widget={widget}
          isAdmin={session.isAdmin}
          isExternal={session.isExternal}
          onSlotGroupRedirect={params => {
            if (params.type === 'create') {
              this.addSlotGroup({mountPoint: 'global-confirm2', ...params});
            } else {
              let popupConfig = {mountPoint: 'global-confirm2', ...params};
              this.showSlotGroup(params.slotGroup, 'update_slot_group', null, popupConfig);
            }
          }}
          onCxenseRedirect={params => {
            this.selectCxenseWidget({mountPoint: 'global-confirm2', callback: params.callback});
          }}
          onThinkAnalyticsRedirect={params => {
            this.selectThinkWidget({mountPoint: 'global-confirm2', callback: params.callback});
          }}
          onConfirm={(updatedWidget) => {
            // this.normalizeSlotGroupMapping(updatedWidget);
            this.saveWidget({...widget, ...updatedWidget}, true);
            CondirmDialog.closeAll();
          }}
          onCancel={() => {
            CondirmDialog.closeAll();
          }}
        />,
      }
      ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById('global-confirm'));
    }

    updateSlotGroup(i, slotGroup) {
      const {widget} = this.state;
      const {slotGroups} = widget;
      slotGroups[i] = slotGroup;
      this.saveWidget(widget);
    }

    swapSlotGroup(index1, index2) {
      const {widget} = this.state;
      const {slotGroups} = widget;
      const exchange = slotGroups[index1];
      slotGroups[index1] = slotGroups[index2];
      slotGroups[index2] = exchange;
      this.saveWidget(widget);
    }

    removeSlotGroup(slotGroup, i) {
      const {widget} = this.state;
      const {slotGroups} = widget;
      this.saveWidget({...widget, slotGroups: slotGroups.filter((x, index) => index !== i)}, true);
    }

    createTagTooltip() {
      return createTooltip({
        targetSelector: 'dynamic',
        tipCreator: d => {
          const {type, data} = d;
          if (type === 'tag') {
            return ReactServer.renderToString(
              <div style={{minWidth: '200px'}}>
                <h3>Tags: </h3>
                <div className="taglist">
                  {data.tags.map(t => {
                    let parts = t.split('::');
                    return (
                      <div key={t}><strong>{parts[0]}:</strong> {parts[1]}</div>
                    )
                  })}
                </div>
              </div>
            )
          } else if (type === 'site') {
            return ReactServer.renderToString(
              <div style={{minWidth: '200px'}}>
                <div>Deployment Site (Required): <strong>{data.site}:</strong></div>
              </div>
            )
          }

        },
        directionFunc: 'bottom'
      });
    }

  createItemTooltip() {
      return createTooltip({
        targetSelector: 'dynamic',
        tipCreator: d => {
          const {type, data} = d;
          return ReactServer.renderToString(
            <ItemTooltipWrapper>
              <Table
                columns={[
                  {
                    key: 'key',
                    label: 'Attribute',
                    align: 'left',
                  },
                  {
                    key: 'value',
                    label: 'Value',
                    align: 'left',
                    style: {whiteSpace: 'nowrap'},
                    renderer: d => {
                      return String(d.value);
                    }
                  }
                ]}
                rows={Object.keys(d).map(k => ({key: k, value: d[k]}))}
              />
            </ItemTooltipWrapper>
          )
        },
        directionFunc: 'bottom'
      });
    }
  }
))
