import React from 'react'
import {withConsumer} from '../app/ApplicationContext';
import {Redirect, Link} from 'react-router-dom';
import {post} from '../utils/request';
import {LoginWrapper, Logo, ErrorMsg, Tint} from './index.style';
import {FormWrapper, Header, ErrorMsgWrapper, FormField, SubmitBtn, BottomPart} from './index.style';
import {SideMenuItem} from "../layout/index.style";
import {Spinner} from "../uikit/Loader/index.style";

export default withConsumer(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  verify() {
    const {session, setAppState} = this.props.appState;
    let {code} = this.state;
    this.setState({loading: true});
    post('/api/login_verify', {
      userId: session.userId,
      whichApp: 'recommend',
      code: code,
    })
    .then((mfaResult) => {
      if(mfaResult.success) {
        delete session.require2FA;
        setAppState({session: session});
        this.setState({loading: false});
      } else {
        this.setState({loading: false, mfaError: mfaResult.error})
      }

    })
    .catch(e => {
      console.error(e);
      this.setState({loading: false, mfaError: e.message});
    });
  }

  resend(e) {
    e.preventDefault();
    e.stopPropagation();
    const {session, setAppState} = this.props.appState;
    let {code} = this.state;
    this.setState({resending: true});
    post('/api/resend_2fa', {
      userId: session.userId,
      whichApp: 'recommend',
    })
    .then((mfaResult) => {
      this.setState({resending: false, successMessage: "Verification code is sent, please check."})
    })
    .catch(e => {
      this.setState({resending: false, successMessage: null, mfaError: 'Failed to send verification code, please try again.'})
    });
  }

  render() {
    const {session} = this.props.appState;
    const {code, mfaError, loading, successMessage, resending} = this.state;
    const {from} = this.props.location.state || {from: {pathname: '/'}}
    if (session && !session.require2FA) {
      return <Redirect to={from}/>;
    }
    var isIE = !!navigator.userAgent.match(/Trident/g) || !!navigator.userAgent.match(/MSIE/g);
    return (
      <LoginWrapper isIE={isIE}>
        {!isIE && <Tint/>}
        <FormWrapper onSubmit={e => {
          e.preventDefault();
          this.verify(e)
        }}>
          <Header>
            Login to BOOST
            <div className="sub-title">2FA Verification</div>
          </Header>
          {
            !!mfaError && <ErrorMsgWrapper>{mfaError}</ErrorMsgWrapper>
          }
          <div>
            {!!successMessage && <p style={{color: 'green'}}>{successMessage}</p>}
          </div>
          <FormField>
            <label className="info-label">Please Check your email box, get the 2FA verify code and fill in below to proceed.</label>
            <input ref="codeInput"
                   value={code}
                   type="text"
                   placeholder="Verify Code"
                   onChange={e => this.setState({code: e.currentTarget.value})}/>
          </FormField>
          <SubmitBtn>
            {
              loading &&
              <Spinner className="spinner" type="mini"/>
            }
            Verify
          </SubmitBtn>
          <BottomPart>
            <Link to="/resend_2fa" onClick={e => this.resend(e)}>
              {
                resending &&
                <Spinner className="spinner" type="mini"/>
              }
              Resend Verification Code
            </Link>
            <Link to="/login" onClick={e => this.props.logout()}>Back</Link>
          </BottomPart>
        </FormWrapper>
      </LoginWrapper>
    );
  }
})