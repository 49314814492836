import React from 'react';
import styled, {css} from 'styled-components';

const SvgWrapper = styled.svg`
  path {
  }

`

export default props => {
  return (
    <SvgWrapper xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512" {...props}>
      <polygon points="396.6,160 416,180.7 256,352 96,180.7 115.3,160 256,310.5 "/>
    </SvgWrapper>
  )
}
