import React, {Component} from 'react';
import {findDOMNode} from 'react-dom';
import _ from 'lodash';
import {SelectWrapper, SelectMenu, SelectToggler} from './siteMapSelector.style'
import {MenuGroup, MenuItem, MenuGroupHeader, ActionsBar} from './siteMapSelector.style'
import {FixedHook} from './hooks';

/**
 * This component is customized from root component index.js
 */
export default class extends Component {

  constructor(props) {
    super(props);
    this.state = {
      active: false,
      subMenuOpenMap: {},
      selected: props.selected,
      sections: props.sections
    }
    this.menuRef = React.createRef();
    this.wrapperRef = React.createRef();
  }

  render() {
    const {className, disabled, defaultLabel, labelPrefix, unSelectable, useFixed} = this.props;
    const {active, selected = []} = this.state;
    const toggleLabel = this.serializeSelected();
    const showClearBtn = unSelectable && !!selected.length;
    const {keyword} = this.state;
    return (
      <SelectWrapper ref={this.wrapperRef} className={`select-box ${className}`} disabled={disabled}>
        <SelectToggler
          className={`toggler ${active ? 'active' : ''} ${showClearBtn ? 'has-clear-btn' : ''}`}
          title={typeof toggleLabel === 'string' ? toggleLabel : ''}
          disabled={disabled}
          unSelectable={unSelectable}
          onClick={e => this.handleToggle(e)}>
          {labelPrefix} {toggleLabel}
          {showClearBtn &&
          <i className="clear-all fa fa-times" onClick={e => this.props.onChange(undefined)}/>
          }
          <i className="caret fa fa-caret-down"/>
        </SelectToggler>
        {this.renderMenu()}
        {!!useFixed && <FixedHook wrapperRef={this.wrapperRef} fixedRef={this.menuRef} offset={{top: 35}}/>}
      </SelectWrapper>
    );
  }

  renderMenu() {
    let {data} = this.props;
    let {subMenuOpenMap, selected = [], sections} = this.state;
    let allSelected = this.isAllSiteSelected();
    return (
      <SelectMenu ref={this.menuRef} className="menu" active={this.state.active}>
        {
          data.length > 1 &&
          <MenuItem
            selected={allSelected}
            className={allSelected ? 'selected' : ''}>
            <div className="menu-item-body">
              <label>
                <input
                  type="checkbox"
                  checked={allSelected}
                  onChange={e => this.toggleAllSite(e.target.checked)}/>
                All Sites
              </label>
            </div>
          </MenuItem>
        }
        {data.map(d => {
          let active = selected.indexOf(d.key) >= 0;
          return (
            <MenuItem
              key={d.key}
              title={typeof d.label === 'string' ? d.label : d.key}
              selected={active}
              className={active ? 'selected' : ''}>
              <div className="menu-item-body">
                <label>
                  <input
                    type="checkbox"
                    checked={active}
                    onChange={e => this.handleChange(d.key, e.target.checked)}/>
                  {d.label}
                </label>
                {
                  active &&
                  <div className="sub-trigger" onClick={e => {
                    this.setState({
                      subMenuOpenMap: {
                        [d.key]: !subMenuOpenMap[d.key]
                      }
                    })
                  }}>
                  {d.data && d.data.length > 0 && <i className="caret fa fa-caret-down"/>}
                  </div>
                }
              </div>
              {
                active && subMenuOpenMap[d.key] &&
                <div className="menu-item-children">
                  {d.data && d.data.length > 0 &&
                  <label className="sub-menu-item">
                    <input
                      type="checkbox"
                      checked={d.data.every(sub => (!sections || (sections || []).indexOf(sub.key) >= 0))}
                      onChange={e => this.toggleSubMenuAll(d.key, e.target.checked)}/>
                    All
                  </label>
                  }
                  {d.data.map(sub => {
                    return (
                      <label className="sub-menu-item" key={sub.key}>
                        <input
                          type="checkbox"
                          checked={!sections || sections.indexOf(sub.key) >= 0}
                          onChange={e => this.handleSubMenuChange(d.key, sub.key, e.target.checked)}/>
                        {sub.label}
                      </label>
                    )
                  })}
                </div>
              }

            </MenuItem>
          )
        })}
        <ActionsBar>
          <a
            className={`action ${selected.length ? '' : 'disabled'}`}
            onClick={e => this.applyChange()}>Apply</a>
          <a className="action" onClick={e => this.cancelAndClose()}>Cancel</a>
        </ActionsBar>
      </SelectMenu>
    )
  }

  serializeSelected() {
    const {data = [], defaultLabel} = this.props;
    const {selected = [], sections} = this.state;

    const allSections = _.compact(_.flatten(data.map(d => d.data)).map(d => d ? d.key : null));
    let allSectionSelected = !sections || allSections.every(d => sections.indexOf(d) >= 0);
    if (data.length > 1 && data.every(d => selected.indexOf(d.key) >= 0) && allSectionSelected) {
      return 'All sites';
    } else {
      return selected
        .map(key => data.find(d => d.key === key))
        .filter(d => !!d)
        .map(d => d.label)
        .join(', ') || 'Select Site';
    }
  }

  handleToggle(e) {
    const {disabled} = this.props;
    if (!disabled) {
      this.setState({active: !this.state.active});
    }
  }

  toggleAllSite(checked) {
    let {data} = this.props;
    let {selected, sections} = this.state;
    if (checked) {
      selected = data.map(d => d.key);
      sections = undefined;
    } else {
      selected = [];
      sections = undefined;
    }
    this.setState({selected, sections});
  }

  isAllSiteSelected() {
    const {data = []} = this.props;
    const {selected = [], sections} = this.state;

    const allSections = _.compact(_.flatten(data.map(d => d.data)).map(d => d ? d.key : null));
    let allSectionSelected = !sections || allSections.every(d => sections.indexOf(d) >= 0);
    if (data.every(d => selected.indexOf(d.key) >= 0) && allSectionSelected) {
      return true;
    } else {
      return false;
    }
  }

  handleChange(key, checked) {
    const {data} = this.props;
    const {selected, sections} = this.state;
    let newSelected = selected;
    let newSections = sections;
    let siteSections = ((data.find(d => d.key === key) || {}).data || []).map(d => d.key);
    if (checked) {
      newSelected.push(key);
      if(newSections) {
        newSections = _.uniq([...newSections, ...siteSections]);
      }
    } else {
      newSelected = newSelected.filter(k => k !== key);
      if(newSections) {
        newSections = newSections.filter(s => siteSections.indexOf(s) < 0);
      }
    }
    this.setState({selected: newSelected, sections: newSections});
  }

  handleSubMenuChange(key, subKey, checked) {
    const {data} = this.props;
    const {sections, selected} = this.state;
    let newSections = sections;
    if (typeof newSections === 'undefined') {
      newSections = _.flatten(selected.map(site => {
        let sitedata = (data.find(d => d.key === site) || {}).data || [];
        return sitedata.map(d => d.key);
      }));
    }
    if (checked) {
      newSections.push(subKey);
    } else {
      newSections = newSections.filter(k => k !== subKey);
    }
    this.setState({sections: newSections});
  }

  toggleSubMenuAll(site, checked) {
    const {data} = this.props;
    const {sections, selected} = this.state;
    let newSections = sections || [];
    let newSelected = selected;
    let siteSections = ((data.find(d => d.key === site) || {}).data || []).map(d => d.key);

    if (checked) {
      newSections = _.uniq([...newSections, ...siteSections]);
    } else {
      newSections = newSections.filter(s => siteSections.indexOf(s) < 0);
      newSelected = newSelected.filter(s => s!== site);
    }
    this.setState({sections: newSections});
  }

  applyChange() {
    let {active, selected, sections} = this.state;
    this.props.onChange(selected);
    this.props.onSectionsChange(sections);
    this.close();
  }

  cancelAndClose() {
    this.setState({
      active: false,
      subMenuOpenMap: {},
      selected: this.props.selected,
      sections: this.props.sections
    });
  }

  close() {
    this.setState({active: false});
  }

  handleClickOutside(event) {
    let {active, selected, sections} = this.state;
    if (active) {
      try {
        const self = findDOMNode(this)
        if (!self || !self.contains(event.target)) {
          this.close();
        }
      } catch (e) {
        //happens when the dom is already destroyed
        // console.error(e);
      }
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside.bind(this), true); // capture phase
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside.bind(this), true);
  }
}