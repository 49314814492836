import React from 'react'
import classnames from 'classnames';
import ReactServer from 'react-dom/server';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import Dropzone from 'react-dropzone'
import moment from 'moment';
import * as d3 from 'd3';
import {withConsumer} from '../app/ApplicationContext';
import iabAdSizes from '../utils/iabAdSizes';
import {SelectStyled} from './select.style';
import {post} from '../utils/request';
import {isValidUrl} from '../utils/misc';
import {Btn, TableScrollWrapper, Table, ProgressBar, TabContainer, Loader} from '../uikit/index';
import {SelectorWrapper, ControlBar, UploadWrapper, DropzoneWrapper} from './builder-creative.style';
import {PaginationStyled} from '../uikit/Pagination/custom.style';
import {BlueJeans} from "../app/StyleCommon";
import {LoaderWrapper} from "../uikit/Loader/index.style";
import {ErrorMessage} from "../uikit/errorbox/errorMessage";

export default withConsumer(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 4,
      sortBy: 'name',
      sortDirection: 'asc',
      keyword: '',
      filterSize: null,
      newCreative: props.creative || {},
      imgSize: props.creative ? props.creative.size : undefined,
      advertiser: props.advertiser,
      errorMap: {}
    };
    this.searchKeyword = _.debounce(() => this.setState({data: null}), 500);
  }

  render() {
    const {mode, selected} = this.props;
    const {loading, data, error, keyword, advertiser, filterSize, page, pageSize, sortBy, sortDirection} = this.state;
    const advertiserOptions = (data || {}).advertisers || [];
    const advertiserSelected = advertiserOptions.find(d => d.label === advertiser || d.key === advertiser);
    return (
      <SelectorWrapper>
        {!!error && <ErrorMessage message={error}/>}
        {!!loading && <Loader type="absolute"/>}
        {
          !data && !error &&
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/queryCreatives`}
            params={this.buildQuery()}
            successHandler={data => this.setState({data})}
            errorHandler={e => this.setState({error: e.message})}/>
        }
        <ControlBar>
          <div className="searchBox">
            <i className="fa fa-search"/>
            <input
              type="text"
              onFocus={e => e.target.select()}
              placeholder="Search Creative"
              value={keyword}
              onChange={e => this.setState({keyword: e.target.value, page: 1}, () => this.searchKeyword())}/>
          </div>
          <SelectStyled
            defaultLabel="Select Size"
            searchable={true}
            selected={filterSize}
            data={this.getAvaiableSizeGroup()}
            onChange={size => {
              this.setState({filterSize: size, data: null})
            }}/>
        </ControlBar>
        <div className="table-wrapper">
          <Table
            columns={[
              {
                key: 'rank',
                align: 'center',
                label: 'No',
                renderer: (d, i) => i + 1
              },
              {
                key: 'name',
                sortable: true,
                label: 'Name',
                align: 'left',
                renderer: d => {
                  return (
                    <div className="creative" title={d.url}>
                      <img src={d.thumbnail}
                           style={{
                             width: `${d.size.split('x')[0] / 3}px`,
                             height: `${d.size.split('x')[1] / 3}px`,
                             background: `url(https://via.placeholder.com/${d.size}.png?text=Loading...) no-repeat`,
                             backgroundSize: 'cover'
                           }}/>
                      <div>[<strong>{d.id}</strong>] - {d.name} </div>
                    </div>
                  )
                }
              },
              {
                key: 'size',
                sortable: true,
                label: 'Size',
                align: 'left',
                renderer: d => {
                  return d.size;
                }
              },
              {
                key: 'action-btn',
                label: 'Actions',
                align: 'center',
                renderer: (d) => {
                  return (
                    <div className="actions">
                      <Btn type="primary" size="mini"
                           disabled={!!(selected || []).find(x => x.id === d.id)}
                           onClick={e => {
                             this.props.onConfirm(d);
                           }}>
                        Select</Btn>
                    </div>
                  )
                }
              }
            ]}
            rows={(data || {}).creatives || []}
            sort={{sortColumn: sortBy, direction: sortDirection}}
            sortChange={sortBy => {
              this.setState({sortBy, data: null})
            }}
            directionChange={sortDirection => {
              this.setState({sortDirection, data: null})
            }}
            noDataText="No creative matching selected tag size"
          />
        </div>
        <PaginationStyled
          onChange={page => {
            this.setState({page, data: null})
          }}
          current={page}
          pageSize={pageSize}
          total={(data || {}).total || 0}
          showLessItems={false}
          hideOnSinglePage={true}/>
      </SelectorWrapper>
    );
  }

  getAvaiableSizeGroup() {
    const {sizes: existingSizes} = this.state.data || {};
    if(existingSizes) {
      return existingSizes.map(d => ({key: d, label: d}));
    }
    const {allowSizes} = this.props;
    const sizes = (allowSizes || []).filter(d => !!d && d !== 'x');
    if (!sizes.length) {
      return iabAdSizes
    }
    return iabAdSizes.filter(x => sizes.indexOf(x.key) >= 0);
  }

  validateCreative() {
    const {newCreative, imgSize} = this.state;
    const {name, size, click_through_url, file, img_url} = newCreative;
    const errorMap = {};
    if (!name) {
      errorMap.name = 'Creative Name is required';
    }
    if (!/[a-z0-9_-]/ig.test(name)) {
      errorMap.name = 'Creative Name should only contains letters, number, underscore and dash.';
    }
    if (!size) {
      errorMap.size = 'Creative Size is required';
    }
    if (!click_through_url) {
      errorMap.click_through_url = 'Click through url is required';
    }
    if (!isValidUrl(click_through_url)) {
      errorMap.click_through_url = 'Not a valid url string';
    }
    if (!img_url && !file) {
      errorMap.file = 'Creative Image is required';
    }
    if (imgSize !== size) {
      const [x, y, x1, y1] = `${imgSize}x${size}`.split('x').map(p => Number(p));
      if ((x / y).toFixed(2) !== (x1 / y1).toFixed(2)) {
        errorMap.file = 'Creative Image Size not match.';
      }
    }
    return errorMap;
  }

  uploadCreative() {
    const errorMap = this.validateCreative();
    if (Object.keys(errorMap).length > 0) {
      this.setState({errorMap: errorMap});
      return;
    }
    const {newCreative} = this.state;

    var formData = new FormData();
    formData.append('advertiser', this.props.advertiser);
    Object.keys(newCreative).forEach(field => {
      formData.append(field, newCreative[field]);
    })
    this.setState({loading: true});
    post('/api/uploadCreative', formData, {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(result => {
      this.setState({loading: false});
      if (result.success) {
        this.props.onConfirm(result.creative);
      } else {
        this.setState({error: 'upload failed'});
      }
    }).catch(e => {
      this.setState({loading: false, error: e.message});
    });
  }

  buildQuery() {
    let {loading, data, error, keyword, advertiser, filterSize, page, pageSize, sortBy, sortDirection} = this.state;
    return {
      search: keyword,
      size: filterSize,
      page,
      pageSize,
      limit: pageSize,
      offset: (page-1) * pageSize,
      sortBy,
      sortDirection
    };
  }
})
