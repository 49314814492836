import React from 'react';
import ReactDOM from 'react-dom';
import {withRouter} from 'react-router';
import _ from 'lodash';
import queryString from 'qs';
import moment from 'moment';

import {withConsumer} from '../app/ApplicationContext';
import {post} from '../utils/request';
import {ProgressBar, Btn, CondirmDialog} from '../uikit/index';
import {Check, Close} from '../uikit/icons/index';
import MainLayout from '../layout/index';
import {ABCreateWrapper, StepContainer, Step, PromptBody, PublishDialogWrapper} from './create.style';
import NavigationPrompt from '../uikit/prompt/index';
import CreateBasic from './step-basic';
import CreateVariants from './step-variants';
import CreateTargeting from './step-targeting';
import CreateDeployment from './step-deploy';
import {abTestTargetOptions} from '../utils/metadata';
import {pct0Formatter} from "../utils/formatter";

export default withRouter(withConsumer(class extends React.Component {

  constructor(props) {
    super(props);

    const params = queryString.parse(window.location.search.slice(1));
    this.createMode = window.location.href.indexOf('create') > -1;
    this.editMode = window.location.href.indexOf('edit') > -1;
    this.persistABTesting = _.debounce(this._persistABTesting.bind(this), 500);
    this.state = {
      step: Number(params.step) || 1,
      touched: params.clone === 'y'
    }
  }

  render() {
    const {step = 1, data, error, loading, touched} = this.state;
    const {abtesting} = data || {};
    const {name = '', description = '', site = '', targeting = [], variants = [], schedule, status = 'Draft'} = abtesting || {};
    const {start = '', end = ''} = schedule || {};
    const abtestingId = this.props.computedMatch.params.abtestingId;

    return (
      <MainLayout
        activeItem="abtesting"
        loading={loading}
        breadcrumb={[
          {path: '/abtesting', label: 'A/B Test'},
          {path: '#', label: `${this.createMode ? 'Create' : 'Edit'} A/B Test`}
        ]}>
        {this.renderPrompt()}
        {
          !data && !error &&
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/getABTestingByID`}
            params={{abtestingId: abtestingId, createIfNotExist: true}}
            successHandler={data => {
              this.setState({data: data});
              const {abtesting} = data || {};
            }}
            errorHandler={e => this.setState({error: e.message})}/>
        }
        {!!data && !error &&
        <ABCreateWrapper>
          <h3>{this.createMode ? 'Create an A/B Test' : `Edit an A/B Test (${status})`}</h3>
          <StepContainer>
            <Step selected={step === 1}>
              <h3 onClick={e => this.setState({step: 1})}>
                <span>Basic</span>
                {step !== 1 &&
                  <div className="desc basic">
                    {<span><img className="site" src={`/img/property/${site}.png`}/></span>}
                    {<span style={{fontWeight: 'bold'}}> {name}</span>}
                    {!!description && <span dangerouslySetInnerHTML={{__html: description}}></span>}
                  </div>
                }
              </h3>
              <div className="content">
                {step === 1 &&
                <CreateBasic
                  abtesting={abtesting}
                  onChange={abtesting => this.saveABTesting(abtesting)}></CreateBasic>
                }
                <div className="actions">
                  <Btn
                    disabled={!name || !site}
                    type="primary"
                    onClick={e => this.setState({step: 2})}>Next</Btn>
                </div>
              </div>
            </Step>
            <Step
              disabled={!name || !site}
              selected={step === 2}>
              <h3 onClick={e => this.setState({step: 2})}>
               <span>Variants</span>
                {step !== 2 &&
                  <div className="desc">
                    {variants.length > 0 && variants.map(({isControl, name, weight}, index) => {
                      return (
                        <p key={name + index}>
                          {name} - {pct0Formatter(weight)}
                          {isControl && <span> (Control)</span>}
                        </p>
                      )
                    })}
                  </div>
                }
              </h3>
              <div className="content">
                {step === 2 &&
                <CreateVariants
                  abtesting={abtesting}
                  onChange={abtesting => this.saveABTesting(abtesting)}></CreateVariants>
                }
                <div className="actions">
                  <Btn type="reset" onClick={e => this.setState({step: 1})}>Back</Btn>
                  <Btn
                    disabled={!name || !site || !variants.length}
                    type="primary"
                    onClick={e => this.setState({step: 3})}>Next</Btn>
                </div>
              </div>
            </Step>
            {/*<Step*/}
            {/*  disabled={!name || !site}*/}
            {/*  selected={step === 3}>*/}
            {/*  <h3 onClick={e => this.setState({step: 3})}>*/}
            {/*    <span>Targeting (Optional)</span>*/}
            {/*    /!*{step !== 3 && this.renderTargetDescription(targeting)}*!/*/}
            {/*  </h3>*/}
            {/*  <div className="content">*/}
            {/*    {step === 3 &&*/}
            {/*    <CreateTargeting*/}
            {/*      abtesting={abtesting}*/}
            {/*      onChange={abtesting => this.saveABTesting(abtesting)}></CreateTargeting>*/}
            {/*    }*/}
            {/*    <div className="actions">*/}
            {/*      <Btn type="reset" onClick={e => this.setState({step: 2})}>Back</Btn>*/}
            {/*      <Btn*/}
            {/*        disabled={!name || !site}*/}
            {/*        type="primary"*/}
            {/*        onClick={e => this.setState({step: 4})}>Next</Btn>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</Step>*/}
            <Step
              disabled={!name || !site || !variants.length}
              selected={step === 3}>
              <h3 onClick={e => this.setState({step: 3})}>
                <span>Deployment</span>
                {/*{name && site && variants.length > 0 && step !== 4 &&*/}
                {/*  <div className="desc">*/}
                {/*    <span>Start Time: {start ? moment(start).format('MMM Do YYYY LTS') : 'Immediately'}</span>*/}
                {/*    <span>- End Time: {end ? moment(end).format('MMM Do YYYY LTS') : 'No End'}</span>*/}
                {/*  </div>*/}
                {/*}*/}
              </h3>
              <div className="content">
                {step === 3 &&
                <CreateDeployment
                  abtesting={abtesting}
                  onChange={abtesting => this.saveABTesting(abtesting)}></CreateDeployment>
                }
                <div className="actions last">
                  <Btn type="reset" onClick={e => this.setState({step: 2})}>Back</Btn>
                </div>
              </div>
            </Step>
          </StepContainer>
          <div className="wrapper-actions">
            {this.editMode && <Btn
              disabled={!touched}
              type="primary"
              onClick={() => this.saveABTesting(abtesting, {}, true)}>Save</Btn>
            }
            {status !== 'Published' && <Btn
              disabled={!name || !site || !variants.length}
              type="primary"
              onClick={() => this.releaseABTesting()}>Publish</Btn>
            }
          </div>
        </ABCreateWrapper>
        }
      </MainLayout>
    )
  }

  renderTargetDescription(targets) {
    const findItem = (options, key) => options.find(d => d.key === key);

    return (
      <div className="desc">
        {targets.length > 0 && targets.map(({key, op, value}, index) => {
          if (!key) {
            return null;
          }

          const item = findItem(abTestTargetOptions, key);
          const opName = op ? findItem(item.operators, op).label : 'n/a';

          let valueName = value;
          if (key !== 'ip') {
            const valueNames = value.map(e => {
              return findItem(item.data, e).label;
            });

            valueName = valueNames.join(', ');
          }

          return <p key={index}>{item.label} - {opName} - {valueName || 'n/a'}</p>;
        })}
      </div>
    )
  }

  renderPrompt() {
    const {data, touched} = this.state;
    const {abtesting} = data || {};
    return (
      <NavigationPrompt
        key={Date.now()}
        when={(curLocation, nextLocation) => {
          return (nextLocation.pathname).indexOf('abtesting/create/') < 0 &&
            (nextLocation.pathname).indexOf('abtesting/edit/') < 0;;
        }}
        disableNative={!touched}>
        {({onConfirm, onCancel}) => {
          if (!touched) {
            this.createMode && abtesting.status === 'Draft' && this.deleteDraft(abtesting, onConfirm);
            return onConfirm();
          }
          const confirmInfo = {
            type: 'form',
            backgroundClose: false,
            title: (
              <strong>
                {this.createMode && 'You are closing an A/B test that has not been saved.'}
                {this.editMode && 'You are closing an A/B test that is changed.'}
              </strong>
            ),
            width: '650px',
            cancelLabel: this.createMode ? 'Discard' : 'Discard Changes',
            okLabel: this.createMode ? 'Save as Draft' : 'Save Changes',
            ignoreLabel: 'Continue Editing',
            onIgnore: onCancel,
            onCancel: () => {
              this.createMode && this.deleteDraft(abtesting, onConfirm);
              this.editMode && onConfirm();
            },
            onConfirm: () => {
              this.editMode && this.persistABTesting(abtesting, onConfirm);
              this.createMode && onConfirm();
            },
            dialogBody: (
              <PromptBody>
                <i className="fa fa-warning"/>
                {this.createMode && 'To save the A/B test, click Save as Draft. The A/B test will be saved in your Drafts folder.'}
                {this.editMode && 'To save the changes, click "Save Changes" button. '}
              </PromptBody>
            ),
          }
          return <CondirmDialog {...confirmInfo} />;
        }}
      </NavigationPrompt>
    )
  }

  saveABTesting(abtesting, stateData = {}, forceEdit = false) {
    const {data} = this.state;
    this.setState({
      data: {...data, abtesting: abtesting},
      touched: true,
      ...stateData
    });

    if (!this.editMode || forceEdit) {
      this.persistABTesting(abtesting);
    }
  }

  deleteDraft(abtesting, callback) {
    const {appState: {session}} = this.props;
    const {isAdmin, isOperator} = session || {};
    if(!isAdmin && !isOperator) {
      return alert(`You don't have to previledge to change the config.`);
    }
    return post('/api/deleteABTesting', {id: abtesting.id}).then(() => {
      if (typeof callback === 'function') {
        callback();
      }
    }).catch(e => {
      console.error(e);
    })
  }

  releaseABTesting() {
    const {appState: {session}} = this.props;
    const {isAdmin, isOperator} = session || {};
    if(!isAdmin && !isOperator) {
      return alert(`You don't have to previledge to change the config.`);
    }

    const {data} = this.state;
    const {abtesting} = data || {};

    this.setState({loading: true, touched: false})
    return post('/api/releaseABTesting', {abtesting}).then((abtesting) => {
      this.showConfirm('success', 'AB Test published successfully!');
      this.setState({data: {...data, abtesting: abtesting}, loading: false});
    }).catch(e => {
      this.setState({loading: false, error: e.message});
      this.showConfirm('failure', 'Failed!');
      console.error(e);
    })
  }

  showConfirm(status, message, callback) {
    const confirmInfo = {
      title: (
        <PublishDialogWrapper>
          {status === 'success' && <Check className="res-success-icon"/>}
          {status === 'failure' && <Close className="res-failure-icon"/>}
        </PublishDialogWrapper>
      ),
      dialogBody: (
        <div style={{
          display: 'block',
          textAlign: 'center',
          margin: '20px 20px 10px 20px',
          lineHeight: '30px',
          fontWeight: '600'
        }}>{message}</div>
      ),
      hideCancel: true,
      onConfirm: () => {
        this.props.history.push(`/abtesting`);
        CondirmDialog.closeAll();
        callback && callback();
      },
      onCancel: () => {
        this.props.history.push(`/abtesting`);
        CondirmDialog.closeAll();
      }
    };
    ReactDOM.render(
      <CondirmDialog fullScreen={true}
                     width={'500px'}
                     {...confirmInfo} />,
      document.getElementById('global-confirm')
    );
  }

  _persistABTesting(abtesting, callback) {
    const {appState: {session}} = this.props;
    const {isAdmin, isOperator} = session || {};
    if(!isAdmin && !isOperator) {
      return alert(`You don't have to previledge to change the config.`);
    }

    return post('/api/updateABTesting', {abtesting}).then((abtesting) => {
      if (typeof callback === 'function') {
        callback();
      }
      this.setState({loading: false, touched: !this.editMode});
    }).catch(e => {
      console.error(e);
    })
  }
}))