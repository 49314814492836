import React from 'react';

import {withConsumer} from '../app/ApplicationContext';
import {abTestTargetOptions} from '../utils/metadata';

export default withConsumer(class extends React.Component {

  render() {
    const {targets} = this.props;


    if (targets.length === 0) {
      return <div>Not specified</div>
    } else {
      const tdata = targets.map(({key, op, value}, index) => {
        if (!key) {
          return '';
        }

        const item = this.findItem(abTestTargetOptions, key);
        const opName = op ? this.findItem(item.operators, op).label : 'n/a';
        let valueName = value;
        if (['ip', 'url'].indexOf(key) < 0) {
          const valueNames = value.map(e => {
            return this.findItem(item.data, e).label;
          });
          valueName = valueNames.join(', ');
        }

        return `${item.label} ${opName} ${valueName || 'n/a'}`;
      });

      return <div>{tdata.join(', ') || 'Not specified'}</div>
    }
  }

  findItem(options, key) {
    return options.find(d => d.key === key);
  }
})
