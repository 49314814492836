import React from 'react';
import styled, {css} from 'styled-components';
import {
  BitterSweet1,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass1, LightGray, LightGray1, MediumGray,
  MediumGray1, LightBlue
} from "../../app/StyleCommon";

import {WidgetWrapper} from './grid.style';

export const WidgetWrapperCNA = styled(WidgetWrapper)`
  &&& {
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    .item-list {
      padding: 0;
      .item {
        .item-inner  {
          .title {
            text-decoration: none;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  ${props => props.isWebView && css`
    @media (prefers-color-scheme: dark) {
      &&& {
        background: black;
        color: white;
        .item {
          border-bottom-color: rgba(255,255,255,.4) !important;
        }
        .title, .widget-title, .publish_date {
          color: white !important;
        }
      }
    }
  `}
  ${props => props.column === 1 && css`
    &&& {
      font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
      .widget-title {
        padding: 10px 0;
        border-bottom: 1px solid #ddd;
      }
      .item-list {
        padding: 0;
        .item {
          padding-left: 0;
          padding-right: 0;
          &:not(:last-child) {
            border-bottom: 1px solid #eaedf3;
          }
          .item-inner  {
            margin-left: 0;
            margin-right: 0;
            padding-left: 0;
            padding-right: 0;
            .thumbnail {
              border-radius: 5px;
            }
            .publish_date {
              display: block;
              margin: 0;
              text-decoration: none;
            }
            .title {
              font-size: 24px;
              overflow: hidden;
               text-overflow: ellipsis;
               display: -webkit-box;
               -webkit-box-orient: vertical;
               -webkit-line-clamp: 3;
            }
            .section {
              display: block;
              font-size: 20px;
            }
          }
        }
      }
      @media (max-width: 968px) {
       .item-list .item.thumbnail_left .item-inner .thumbnail,
       .item-list .item.thumbnail_right .item-inner .thumbnail {
          width: 98.91px;
          height: 55.63px;
          padding: 0;
          //margin-right: 10px;
       }
      }
      @media (min-width: 968px) {
       .item-list .item.thumbnail_left .item-inner .thumbnail,
       .item-list .item.thumbnail_right .item-inner .thumbnail {
          width: 165.39px;
          height: 93.03px;
          padding: 0;
          //margin-right: 20px;
       }
      }
    }  
  `}
`