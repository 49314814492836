import styled, {css} from 'styled-components';
import {
  DropdownZIndex,
  FontSizeBig,
  FontSizeMedium,
  LightGray,
  LightGray1,
  MediumGray,
  BlueJeans, DarkGray, BlueJeans1, FontSizeSmall, DarkGray1, Grass1, BitterSweet1
} from "../app/StyleCommon";

export const NotificationWrapper = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
`;

export const NotificationToggler = styled.div`
  position: relative;
  cursor: pointer;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${FontSizeMedium};
  background-color: transparent;
  border-left: 1px solid rgba(255,255,255, .1);
  border-right: 1px solid rgba(255,255,255, .1);
  font-size: 20px;
  color: ${DarkGray};
  &:hover {
    background-color: rgba(255,255,255,.1);
  }
  .count {
    font-size: 10px;
    font-weight: bold;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(5px, -15px);
    border-radius: 50%;
    border: 2px solid;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255,.9);
    color: ${DarkGray1}; 
  }
  ${props => props.active && css`
    background: white !important;
    color: ${DarkGray1};
    .count {
      border: 1px solid ${MediumGray}
    }
  `}
`;

export const NotificationMenu = styled.div`
  position: fixed;
  top: 50px;
  left: auto;
  right: -10px;
  overflow: hidden;
  width: 465px;
  transition: all .3s ease;
  opacity: 0;
  z-index: ${DropdownZIndex};
  min-width: 250px;
  box-shadow: 0px 1px 3px rgba(0,0,0,.3);
  background-color: white;
  max-height: 0;
  overflow-y: auto;
  @media (max-width: 667px) {
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: auto;
    height: auto;
    max-height: inherit;
  }
  ${props => props.active && css`
     padding: 10px;
     opacity: 1;
     max-height: 800px;
     max-height: calc(100vh - 100px);
  `}
  ${props => !props.active && css`
     pointer-events: none !important;
  `}
  > h3 {
    padding: 0px 10px;
    color: ${DarkGray1};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
export const MenuItem = styled.label`
  padding: 10px 10px;
  display: block;
  font-size: 12px;
  color: ${DarkGray1};
  border-top: 1px solid ${MediumGray}
  position: relative;
  ${props => props.isNew && css`
    background-color: rgba(0, 126, 255, 0.11);
  `}
  .noti-header {
    color: ${BlueJeans1};
    font-size: 16px;
    i {
      margin-right: 10px;
      &.fa-check {
        color: ${Grass1};
      }
      &.fa-close {
        color: ${BitterSweet1};
      }
    }
  }
  .noti-body {
    font-size: 12px;
    margin: 10px 0;
    // &:hover {
    //   text: ${MediumGray};
    // }
  }
  .noti-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${DarkGray}
  }
  .del {
    position: absolute;
    display: inline-block;
    padding: 5px;
    right: 15px;
    top: 5px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const EmptyBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`