import React from 'react'
import classNames from 'classnames';
import {Redirect, Link} from 'react-router-dom';
import {post} from '../utils/request';
import queryString from 'qs';
import {Spinner} from '../uikit/Loader/index.style';
import {LoginWrapper, Logo, ErrorMsg, Tint} from './index.style';
import {FormWrapper, Header, ErrorMsgWrapper, FormField, SubmitBtn, BottomPart} from './index.style';

export default class extends React.Component {

  constructor(props) {
    super(props);
    this.urlParams = queryString.parse(this.props.location.search.slice(1));
    this.state = {loading: false, error: null, ...this.urlParams}
  }

  render() {
    return (
      <LoginWrapper>
        {(!navigator.userAgent.match(/Trident/g) && !navigator.userAgent.match(/MSIE/g)) && <Tint/>}
        {this.renderResetForm()}
      </LoginWrapper>
    );
  }

  resetPassword(e) {
    e.preventDefault();
    const {userId: userIdRef, oldPassword: oldPasswordRef, newPassword: newPasswordRef} = this.refs;
    const userId = this.urlParams.userId || userIdRef.value;
    const oldPassword = oldPasswordRef ? oldPasswordRef.value : null;
    const newPassword = this.refs.newPassword.value;

    this.setState({success: false, loading: true, error: null});
    post('/api/resetPW', {userId, oldPassword, newPassword})
      .then(result => {
        this.setState({loading: false, success: true});
      }).catch(e => {
      console.error(e);
      this.setState({loading: false, error: e.message});
    });
  }

  renderResetForm() {
    const {userId, oldPassword, newPassword, error, loading} = this.state;
    return (
      <FormWrapper onSubmit={e => {
        e.preventDefault();
      }}>
        <Header>Update Password</Header>
        {
          !!error && <ErrorMsgWrapper>{error}</ErrorMsgWrapper>
        }
        <div>
          {this.state.success && <p style={{color: 'green'}}>Your password has been changed.</p>}
        </div>
        <FormField>
          <input ref="userInput"
                 value={userId}
                 type="text"
                 placeholder="Email"
                 onChange={e => this.setState({email: e.currentTarget.value})}/>
        </FormField>

        <FormField>
          <input ref="oldPassword"
                 value={oldPassword}
                 type={this.urlParams.oldPassword ? 'hidden' : 'password'}
                 placeholder="Old Password"
                 onChange={e => this.setState({oldPassword: e.currentTarget.value})}/>
        </FormField>

        <FormField>
          <input ref="newPassword"
                 value={newPassword}
                 type="password"
                 placeholder="New Password"
                 onChange={e => this.setState({newPassword: e.currentTarget.value})}/>
        </FormField>
        <SubmitBtn onClick={e => this.resetPassword(e)}>
          {
            loading &&
            <Spinner className="spinner" type="mini"/>
          }
          Update Password
        </SubmitBtn>
        <BottomPart>
          <Link to={'/login'}>Back</Link>
        </BottomPart>
      </FormWrapper>
    );
  }
}