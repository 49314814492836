import styled, {css} from 'styled-components';
import * as d3 from 'd3';

import {
  BlueJeans, LightGray1, DarkGray1, Grass, LightGray, PrimaryDarkBlue,
  BitterSweet, DarkGray, DarkBlue, MediumGray1, MediumGray, PrimaryMediumBlue,
  SecondaryMediumBlue, PrimaryLightBlue
} from '../app/StyleCommon';

export const Wrapper = styled.div`
  background-color: white;
  padding: 5px 0;
  width: calc(100% - 10px);
  @media screen and (max-width: 667px) {
    padding: 10px 10px;
  }

  h3 {
    background-color: ${LightGray};
    padding: 5px 10px;
  }
`;
