import styled from 'styled-components';

export const ScrollTableWraper = styled.div.attrs({
  'data-rp': 'ripple-table-scroll-wraper'
})`
  > div:first-child {
    overflow-x: auto;
    overflow-y: hidden;
    @media (max-width: 667px) {
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
    }
  }
`;