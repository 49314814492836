import styled, {css} from 'styled-components';

import {DarkGray} from '../app/StyleCommon';

export const DeployPanel = styled.div`
  // box-sizing: border-box;
  // width: 100%;
  // background: white;
  // padding: 0px 10px 10px 10px;
  // position: relative;
  // margin-bottom: 50px;
  pre {
    font-size: 12px;
  }
  .row-title {
    font-size: 14px;
    font-weight: 700;
    color: ${DarkGray};
  }
  .sub-row-title {
    font-size: 12px;
    font-weight: 600;
    margin: 15px 0 5px 0;
  }
  .code-snippet {
    margin-left: 20px;
  }
  .copy-able {
    position: relative;
    @media screen and (max-width: 667px) {
      margin-top: 30px;
    }
    &:hover .copy-cat{
      opacity: 1;
    }
    .copy-cat {
      opacity: 0.9;
      position: absolute;
      right: 0;
      top -22px;
      transition: all .3s ease-in-out;
    }
  }
`;