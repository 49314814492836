import React from 'react';
import styled, {css} from 'styled-components';
import {
  BitterSweet1,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass1, LightGray, LightGray1, MediumGray,
  MediumGray1, LightBlue
} from "../../app/StyleCommon";

import {WidgetWrapper} from './grid.style';

export const WidgetWrapperBerita = styled(WidgetWrapper)`
  &&& {
    font-family: 'Open Sans', Arial, Tahoma, sans-serif;
    margin: 10px 16px;
    .widget-title {
      font-size: 17px;
      line-height: 1.3;
      color: #323232;
      margin: 0;
      margin-bottom: 10px;
    }
    .item-list {
      background: transparent;
      display: flex;
      align-items: flex-start;
      .item {
        padding: 10px 0;
        .item-inner {
          padding: 0;
          .thumbnail {
          }
          .brief {
            .title {
              font-size: 12px;
              color: black;
              text-decoration: none;
              font-weight: 400;
              line-height: 19px;
              max-height: 4.8em;
              &:hover {
                text-decoration: none;
              }
            }
            .section {
              display: none;
            }
          }
        }
      }
    }
  }
  ${props => props.row === 1 && props.mediaWidth >= 700 && css`
    &&& {
      .widget-title {
      }
      .item-list {
        display: flex;
        align-items: flex-start;
        padding: 10px 0;
        .item {
          width: 50%;
          .item-inner {
            padding: 0 10px;
            display: flex;
            .thumbnail {
              width: 50%;
              height: 0;
              padding-bottom: 28%;
              max-width: inherit;
            }
            .brief {
              &:after {
                margin-left: auto;
              }
              .title {
                text-decoration: none;
                font-size: 13px;
                font-weight: bold;
                color: #666666;
                &:hover {
                  text-decoration: none;
                }
              }
              .section {
                display: none;
              }
            }
          }
        }
      }
    }
  `}
  
  ${props => props.row === 1 && props.mediaWidth < 700 && css`
    &&& {
      .item-list {
        .item.thumbnail_top {
          width: 100%;
          .item-inner {
            display: flex;
            .thumbnail {
              width: 50%;
              height: 0;
              padding-bottom: 19.6%;
              max-width: inherit;
            }
            .brief {
              .title {
                font-size: 12px;
              }
              
              .section {
                display: block;
              }
            }
          }
        }
      } 
    }
  `}
`