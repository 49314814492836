import styled, {keyframes} from 'styled-components';
import {
  BlueJeans, DarkGray, DarkGray1, Grass, Grass1, LightGray, LightGray1,
  MediumGray, DropdownZIndex, BlueJeans1, BlueJeans0, MediumGray1
} from "../../app/StyleCommon";

const rotateLeft = keyframes`
  from {
    transform: translate(0);
    opacity: 1;
  }

  to {
    transform: translate(-5px);
    opacity: 0;
  }
`;
const rotateRight = keyframes`
  from {
    transform: translate(0);
    opacity: 1;
  }

  to {
    transform: translate(5px);
    opacity: 0;
  }
`;

export const DateSelectorWrapper = styled.div.attrs({
  "data-type": "date-selector"
})`
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: space-between;
  flex-wrap: no-wrap;
  background: white;
  border-radius: 5px;
  &.single .month-panel{
    width: 100%;
  }

  .month-panel {
    color: ${DarkGray};
    user-select: none;
    width: 50%;
    padding: 15px;
    .month-nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: ${DarkGray};
      font-weight: bold;
      .month-nav-btn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        width: 35px;
        height: 35px;
        border-radius: 6px;
        background-color: ${LightGray};
        &.disabled {
          color: ${LightGray1};
          cursor: not-allowed;
          pointer-events: none;
          opacity: 0;
        }
      }
    }
  }

  .month-panel table {
    width: 100%;
    margin-left: -5px;
    table-layout: fixed;
    border-spacing: 7px 5px;
    border-collapse: separate;
    thead th {
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      max-width: 42px;
      height: 32px;
      text-align: center;
      vertical-align: middle;
      box-sizing: border-box;
      tbody tr {
        white-space: nowrap;
      }
    }
    .date {
      cursor: pointer;
      margin: 4px 3px;
      max-width: 42px;
      height: 25px;
      text-align: center;
      vertical-align: middle;
      font-size: 12px;
      color: ${DarkGray};
      font-weight: bold;
      box-sizing: border-box;
      border-radius: 6px;
      border-bottom: 1px solid white;
      &.today {
        text-decoration: underline
      }
      &.selected {
        background-color: ${BlueJeans0};
      }
      &.invalid {
        color: #cdcdcd;
        pointer-events: none;
      }
      &:not(.inCurrentMonth) {
        visibility: hidden;
      }
      &.start, &.end {
        color: white;
        background-color: ${BlueJeans};
        position: relative;
      }
      &.showLeftIndicate:before, 
      &.showRightIndicate:after{
        content: '';
        position: absolute;
        top: 6px;
        display: block;
        border: 5px solid transparent;
      }
      &.showLeftIndicate:before {
        left: -10px;
        border-right: 10px solid ${BlueJeans};
        animation: ${rotateLeft} 1s linear infinite;
      } 
      &.showRightIndicate:after {
        right: -10px;
        border-left: 10px solid  ${BlueJeans};
        animation: ${rotateRight} 1s linear infinite;
      }
      &.today.showLeftIndicate:before {
        display: none;
      }
    }
  }
`

export const DateSelectorDropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  &:focus {
    border: 1px solid ${Grass};
  }
  > a {
    position: relative;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    white-space: nowrap;
    background: ${LightGray}; 
    border: 1px solid ${MediumGray};
    border-radius: 5px;
    vertical-align: middle;
    display: flex;
    align-items: center;
    height: 36px;
    padding: 0 10px;
    cursor: pointer
    transition: all .3s ease;
    min-width: 200px;
    &:hover {
      background: ${LightGray1};
    }
    i {
      position: absolute;
      right: 10px;
      top: 12px;
    }
  }
  .dropdown-content {
    position: absolute;
    right: 0;
    left: auto;
    width: 500px;
    top: 38px;
    background-color: white;
    z-index: ${DropdownZIndex};
    box-shadow: 0px 1px 3px rgba(0,0,0,.05);
    border: 1px solid ${LightGray1}
    @media (max-width: 667px) {
      width: 100% !important;
      .month-panel {
        padding: 5px;
      }
    }
    .sep {
      position: relative;
      display: flex;
      padding: 0 50px;
      >span {
        z-index: 1;
        background-color: white;
      }
      &:before {
        position: absolute;
        content: '';
        height: 1px;
        top: 50%;
        left: 10px;
        width: 35px;
        background-color: ${LightGray1};
      }
      &:after {
        position: absolute;
        content: '';
        top: 50%;
        right: 20px;
        left: 180px;
        height: 1px;
        background-color: ${LightGray1};
      }
    }
    .shortcuts {
      margin: 20px 0;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
      li {
        font-size: 12px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        //width: 50%;
        margin: 0 8px;
        &.selected a{
          color: ${BlueJeans1};
        }
        a {
          display: block;
          cursor: pointer;
          white-space: nowrap;
          color: ${DarkGray};
          width: 100%;
          text-align: center;
          &:hover {
            color: ${BlueJeans};
          }
        }
      }
    }
    .date-selector {
      &.single {
        width: 278px;
      }
    }
  }
  
`