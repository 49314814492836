import * as React from "react";

export function isMouseMoveEvent(
  e
){
  return "clientX" && "clientY" in e;
}

export default class Carousel extends React.Component{
  previous;
  next;
  goToSlide;
  state;
  setClones; // reset carousel in infinite mode.
  setItemsToShow; // reset carousel in non-infinite mode.
  correctClonesPosition;
  onMove;
  direction;
  containerRef;
}
