import React, {Component, useEffect} from 'react';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';
import {ReRenderHook} from '../utils/hooks';
import {BitterSweet, Grass, LightBlue} from "../app/StyleCommon";
import {intFormatter, uniquesFormatter} from "../utils/formatter";

export default class extends Component {

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate() {
    this.renderChart();
  }

  render() {
    return (
      <div className="chart gauge-chart" style={{display: 'flex', justifyContent: 'center'}}>
        <div ref={this.containerRef}/>
      </div>
    )
  }

  renderChart() {
    let {data, formatter, markerValue, hideVisualMap, period, width, height} = this.props;
    const container = this.containerRef.current;
    const myChart = echarts.init(container, null, {renderer: 'svg', width: width || 70, height: height || 40});
    var option = {
      tooltip: {
        trigger: 'axis',
        formatter: (params) => {
          const param = params[0];
          const end = (period || {}).end;
          return `${moment(end).add(-data.length + param.dataIndex + 1, 'day').format('YYYY-MM-DD')}: ${formatter(param.value)}`;
        }
      },
      grid: {
        left: 5,
        right: 0,
        top: 10,
        bottom: 2
      },
      xAxis: {
        type: 'category',
        show: false,
        data: data.map((d, i) => i)
      },
      yAxis: {
        type: 'value',
        show: false,
        // min: hideVisualMap ? 0 : _.min(data) * 1.1,
        // max: _.max(data) * 1.1,
        position: 'right',
      },
      visualMap: hideVisualMap ? null : [
        {
          show: false,
          dimension: 1,
          pieces: [
            // {
            //   gt: -10000,
            //   lte: -0.001,
            //   color: BitterSweet
            // },
            {
              gt: 0,
              lte: 100000,
              color: Grass
            }
          ]
        }
      ],
      series: [{
        data: data.map(d => Number(d) || 0),
        type: 'line',
        showSymbol: false,
        animation: false,
        itemStyle: {normal: {color: '#abcdf4'}},
        // markLine: {
        //   symbol: 'none',
        //   showAllSymbol: false,
        //   showSymbol: false,
        //   symbolSize: '1',
        //   label: {show: false},
        //   lineStyle: {
        //     type: 'dotted',
        //     color: '#535353',
        //     opacity: 0.3
        //   },
        //   data: [
        //     {xAxis: Math.ceil(data.length / 2) - 1, symbol: 'none'},
        //   ]
        // },
        areaStyle: {
          opacity: 0.5,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#e2efff'
            },
            {
              offset: 1,
              color: '#e1f0f6'
            }
          ])
        }
      }]
    };
    myChart.setOption(option);
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
}