import styled, {css} from 'styled-components';
import {Dropdown} from "../uikit/index";
import Pagination from 'rc-pagination';
import {
  BitterSweet1, DarkGray1,
  BlueJeans, BlueJeans1, DarkGray, Grass, LightBlue, LightGray, LightGray1, MediumGray, MediumGray1,
  PrimaryDarkBlue, Grass1, Sunflower1
} from "../app/StyleCommon";

export const Taglist = styled.span`
  display: flex;
  padding: 0 !important;
  flex-wrap: wrap;
  .tag {
    //border: 1px solid ${LightGray1};
    border-radius: 5px;
    font-size: 11px;
    display: inline !important;;
    background: #dbf1c4;
    font-weight: bold;
    margin: 5px 5px 5px 0 !important;
    padding: 1px 4px !important;
    color: #6a717c;
    &.project {
      background: #d79beb78;
    }
    &.platform {
      background: #57c5f766;
      &:before {
        font-size: 11px !important;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        text-rendering: auto;
        margin-right: 3px;
      }
      &.App:before {
        content: "\\f10b";
        font-size: 13px !important;
        vertical-align: text-bottom;
      }
      &.Web:before {
        content: "\\f268";
      }
    }
    &.page {
      background: #a0d46894;
    }
    &.algo {
      background: #ffce54ab;
    }
    &.backfill {
      background: #f1f2f3;
      font-size: 9px;
    }
  }
`
export const WidgetHomeWrapper = styled.div`
  background-color: white;
  padding: 10px 20px;
  min-height: calc(100vh - 50px);
  width: 100%;
  box-sizing: border-box;
  @media screen and (max-width: 667px) {
    padding: 20px 10px;
  }
`;

export const FilterBarFirst = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 667px) {
    display: block;
    .layout-mode {
      display: none;
    }
    .create-btn {
      width: 100%;
      button {
        width: 100% !important;
      }
    }
  }
  .left, .right {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  h3 {
    margin-right: 30px;
  }
  .status-list {
    display: flex;
    flex-grow: 1;
    li {
      cursor: pointer;
      margin: 10px 10px 10px 0;
      padding: 5px 10px;
      border-radius: 5px;
      &.selected {
        color: ${BlueJeans1};
        background: #f1f3f6;
        font-weight: bold;
      }
    }
  }
  .layout-mode {
    margin: auto 10px;
    a {
      display: inline-block;
      margin-right: 10px;
      cursor: pointer;
      color: ${MediumGray};
      padding: 5px;
      border-radius: 5px;
      &:hover {
        background: ${LightGray};
      }
      &.selected {
        color: ${BlueJeans1}
      }
    }
  }
  .create-btn {
    button {
      width: 180px;
    }
  }
`;

export const FilterBarSecond = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  position: relative;
  .searchBox {
    flex-grow: 1;
    i {
      position: absolute;
      left: 10px;
      top: 10px;
    }
    input {
      padding: 8px 10px 8px 30px;
      font-size: 12px;
      border-radius: 5px;
      border: 1px solid ${MediumGray};
      background: ${LightGray};
      width: 100%;
      box-sizing: border-box;
      outline: none;
      &:focus {
        border-color: ${BlueJeans};
        box-shadow: inset 0 0 3px rgba(0,0,0,.1);
      }
    }
  }
  .select-box {
    margin-left: 20px;
  }
  @media screen and (max-width: 667px) {
    flex-wrap: wrap;
    .searchBox {
      width: 100%;
      margin-bottom: 5px;
    }
    .select-box {
      margin: 5px 0;
      width: 100%;
      .toggler {
        width: 100%;
      }
    }
  }
`;

export const WidgetTableView = styled.div`
 margin-top: 20px;
 .title {
  text-decoration: none;
  margin-bottom: 8px;
  &:hover {
    text-decoration: underline;
  }
 }
 table {
  border-top: none;
   thead th {
    background: white;
    border: none;
    border-top: none;
    border-left: 1px solid white;
    border-right: 1px solid white;
    border-bottom: 1px solid ${MediumGray};
   }
 }
 .dropdown-toggle {
    width: 30px !important;
 }

`
export const WidgetListView = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  ${props => props.mode === 'grid' && css`
    && {
      .widget-item {
        width: 32%;
        width: calc(33.333% - 15px);
        padding: 10px 20px;
        &:nth-child(3n + 2),
        &:nth-child(3n + 3) {
          margin-left: 20px;
        }
        @media screen and (max-width: 1024px) {
          width: 48％;
          width: calc(50% - 20px);
          margin-left: 0 !important;
          &:nth-child(2n) {
            margin-left: 20px !important;
          }
        }
        @media screen and (max-width: 667px) {
          width: 100%;
          margin-left: 0 !important;
          &:nth-child(2n) {
            margin-left: 0 !important;
          }
        }
        .abtesting {
          display: none !important;
        }
        .widget-layout {
          right: 5px;
          top: 20px;
          left: auto;
          display: none;
        }
        .widget-item-info {
          display: block
          .widget-item-basics {
            .author {
              display: none;
            }
          }
          .widget-item-metrics {
            width: 100%;
            display: block;
            margin: 0;
            margin-top: 0px;
            padding-top: 5px;
            //border-top: 1px solid #ddd;
            >label {
              display: block;
              margin: 0 0 5px 0;
              font-weight: bold;
              display: none;
              font-size: 13px;
            }
            .tab-items-wrapper {
              width: 100%;
              height: 116.75px;
            }
            .tab-menu-wrapper {
              padding: 0;
              &:before {
                content: 'Traffic';
                margin-right: 10px;
                display: inline-block;
                width: 70px;
                font-weight: normal;
                font-size: 13px;
                margin-top: 3px;
              }
              &:after {
                left: 105px;
                right: 15px;
                width: auto;
              }
              >li {
                font-size: 13px;
                font-weight: normal;
              }
            }
          }
        }

      }
    }
  `}
`


export const WidgetItem = styled.div`
  margin: 10px 0;
  display: inline-block;
  width: 100%;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;
  padding-left: 120px;
  position: relative;
  //background: ${LightGray};
  border: none;
  //background-image: linear-gradient(0deg, #fafbfc, #eeeded00);
  background-color: white;
  border: 1px solid ${LightGray1};
  box-shadow: 0px 1px 3px rgba(0,0,0, .1);
  .widget-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    border-radius: 5px 5px 0 0;
    &:hover {
      .author {
        opacity: 1;
      }
    }
    .site {
      height: 12px;
      width: auto;
      margin-right: 10px;
      vertical-align: middle;
      display: inline-block;
      margin: 0 10px 0 0px;
    }
    .title {
      font-size: 14px;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-decoration: none;
      display: inline-block;
      > span {
        width: 240px;
        word-break: break-word;
        white-space: normal;
        vertical-align: middle;
      }
      span:hover {
        text-decoration: underline;
      }
      .from-now {
        font-size: 10px;
        color: ${MediumGray1};
      }
      img {
        width: auto;
        height: 18px;
      }
       button {
        margin-left: 10px;
        color: ${DarkGray}
        a {
          color: ${DarkGray}
        }
      }
    }
    .status {
      margin-left: 10px;
      font-size: 12px;
      color: ${MediumGray1};
      font-style: normal;
    }
    .author {
      font-size: 13px;
      margin-left: 10px;
      font-weight: 300;
      opacity: 0;
      transition: all .5s ease .3s;
    }
    .fold-toggler {
      flex-grow: 1;
      height: 20px;
    }
    .widget-item-actions {
      display: flex;
      align-items: center;
      z-index: 1;
      button {
        color: ${DarkGray}
        a {
          color: ${DarkGray}
        }
        &:hover {
          background: ${LightGray};
        }
      }
      .dropdown-toggle {
        height: 30px;
        width: 20px;
      }
    }
  }
  .widget-item-body {
    min-height: 60px;
    &.close {
      display: none;
    }
    .widget-layout {
      left: 15px;
      width: 80px;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      text-align: center;
      .layout-icon {
        width: 24px;
        height: 24px;
        background-size: cover;
        display: inline-block;
        opacity: .9;
      }
      .layout-desc {
        font-size: 13px;
        display: block;
        text-align: center;
      }
    }
    .widget-item-info {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 13px;
      .widget-item-basics{
        //flex-grow: 0.8;
        min-width: 50%;
        .attr {
          line-height: 24px;
          //white-space: nowrap;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          align-items: flex-start;
          margin: 3px 0;
          // &:not(:last-child) span{
          //   border-bottom: 1px solid ${LightGray};
          // }
          &.layout span {
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
          label {
            display: inline-block;
            white-space: nowrap;
            width: 80px;
            margin-right: 10px;
            font-weight: normal;
          }
          span {
            display: inline-block;
            padding: 0px 10px;
            width: 100%;
          }
          img {
            width: 15px;
            margin-right: 5px;
          }
          a {
            margin-right: 10px;
            text-decoration: none;
            border-bottom: 1px dotted ${MediumGray1};
          }
        }
        img {
          width: 24px;
          margin-right: 5px;
          vertical-align: middle;
        }
      }
    }
    .widget-item-metrics {
        margin-right: 60px;
        margin-left: 20px;
        position: relative;
        width: 40%;
        max-width: 400px;
        margin-top: -25px;
        display: flex;
        align-items: flex-start;
        >label {
          font-weight: normal;
          font-size: 13px;
          margin-top: 5px;
          margin-right: 20px;
          white-space: nowrap;
        }
        .tab-container-wrapper {
          width: 100%;
          margin: 0;
          background-color: transparent;
          .tab-menu-wrapper {
            padding: 0  20px;
            white-space: nowrap;
            flex-wrap: nowrap;
            &:after {

            }
            li {
              font-weight: normal
              padding: 0 0px;
              &.selected {
                border-bottom: 2px solid ${MediumGray};
                border-radius: 5px 5px 0 0;
              }
            }
          }
        }
     }
    }
`
export const MetricTableWrapper = styled.div`
  table {
    border: none;
    overflow: visible;
    background: transparent;
    thead {
      background: transparent;
      tr {
        border: none;
        &:first-child {
          border-bottom: 1px solid ${LightGray1};
        }

        background: transparent;
        th {
          border: none;
          font-weight: normal;
          font-size: 13px;
          padding: 4px 10px;
          white-space: nowrap;
          background: transparent;
        }
      }
      .no-data {
        top: 40px;
        width: 100%;
      }
    }
    tbody {
      tr {
        background: transparent !important;
        td {
          font-size: 13px;
          background: transparent;
        }
      }
    }
  }
`;

export const NoData = styled.div`
  min-height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${DarkGray};
  a {
    display: inline-block;
    margin: 0 10px;
  }
`
export const SortByItemRenderer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  img {
    width: 16px;
    height: 16px;
    margin-right: 5px
    vertical-align: middle;
    &.hidden {
      display: none;
    }
  }
`

export const ListFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
  @media screen and (max-width: 667px) {
    flex-wrap: wrap;
    .info {
      display: block
      margin: 10px 0;
    }
    .rc-pagination {
    }
  }
  select {
    padding: 2px 5px;
    font-size: 14px;
    min-width: 50px;
    margin: auto 5px;
  }
`;

export const PaginationStyled = styled(Pagination)`
  >li {
    outline: none;
    min-width: 24px;
    min-height: 24px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    &:hover {
      border-color: ${BlueJeans};
    }
    a {
        font-size: 14px;
        line-height: 24px;
    }
    &.rc-pagination-item-active {
      background-color: ${BlueJeans};

    }
  }
`;

export const ErrorBox = styled.div`
  margin: 10px 0;
  color: ${BitterSweet1};
`

export const FilterDropdown = styled(Dropdown)`
  .dropdown-toggle {
    width: 100px;
    border: 1px solid ${MediumGray};
    padding: 0;
    display: flex;
    justify-content: center;
    padding: 6px 10px;
    align-items: center;
    margin-left: 20px;
    border-radius: 5px;
    background-color: ${LightGray};
    i {
      font-size: 16px;
      margin-right: 10px;
    }
    &:hover i {
      color: ${Grass};
    }
  }
  .menu {
    top: 40px;
    right: 0;
    left: auto;
    min-width: 200px;
    @media screen and (max-width: 667px) {
      left: 0;
    }
    h3 {
      margin: 0;
      padding: 10px 10px;
      font-weight: normal;
      border-bottom: 1px solid ${LightGray1};
    }
    .actions {
      padding: 10px;
    }
    .menu-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      >label {
        display: inline-block;
        width: 120px;
        margin-right: 10px;
        flex: none;
      }
      .select-box {
        flex-grow: 1;
        margin: 0;
        .toggler {
          border: none;
        }
        .menu {
          label {
            white-space: normal !important;
            word-break: break-all;
          }
        }
      }
    }
  }
`;

export const FilterTagWrapper = styled.div`
  display: flex;
  margin: 10px 0;
  .tag {
    padding: 5px 10px;
    background-color: ${LightGray};
    border: 1px solid ${MediumGray};
    margin: 5px 10px 10px 0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    strong{
      color: ${Grass1};
      margin: 0 5px;
    }
    i {
      margin-left: 5px;
      font-weight: bold;
      cursor: pointer;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${MediumGray};
      &:hover {
        color: ${Grass1};
      }
    }
  }
  @media screen and (max-width: 667px) {
    flex-wrap: wrap;
    .tag {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-right: 0;
    }
  }

`