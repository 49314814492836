export default [
  {key: 'DIRECT', label: 'Direct', description: 'For campaigns where buyers takeover a widget ads slot.'},
  // {key: 'Sponsorship', label: 'Sponsorship', description: 'Use this type for directly sold campaigns when your buyer wants to "takeover" an EDM. '},
  {key: 'STANDARD', label: 'Standard', description: 'For campaigns where buyers specify the number of impression to serve.'},
  // {key: 'Network', label: 'Network', description: 'Serve based upon a defined percentage of impressions. Use this for your partner ad networks which don\'t have an impression goal.'},
  // {key: 'Bulk', label: 'Bulk', description: `Serve based upon a defined impression goal, but because the inventory for bulk line items isn't guaranteed, the impression goal functions more like an impression cap, limiting the number of impressions that can be delivered. Use this for partners who have ordered a maximum number of impressions but aren't concerned about delivery timelines or guarantees.`},
  // {key: 'Pricepriority', label: 'Pricepriority', description: `Serve primarily based on price, with optional daily or lifetime delivery caps. Use this line item type to fill your site's unsold inventory with the highest-paying line item available.`},
  {key: 'HOUSE', label: 'House', description: `Served when there are no remnant campaigns available to serve.`},
]

