import React from 'react';
import styled, {css} from 'styled-components';
import {
  BitterSweet1,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass1, LightGray, LightGray1, MediumGray,
  MediumGray1, LightBlue
} from "../../app/StyleCommon";

// import Carousel from 'react-multi-carousel';
import Carousel from '../../uikit/react-slider';
// import 'react-multi-carousel/lib/styles.css';

import {createGlobalStyle} from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @media (prefers-color-scheme: dark) {
    html, body {
      background: black;
    }
  }
`;

export const CarouselStyled = styled(Carousel)`
    & {
      /* ------- react-multi-carousel default styles --------- */

      @font-face {
        font-family: revicons;
        fallback:fallback;src: url('/fonts/revicons.woff') format("woff"),url('/fonts/revicons.ttf') format("ttf"),url('/fonts/revicons.eot') format("ttf");
      }

      display: flex;
      align-items: center;
      overflow: hidden;
      position: relative;

      .react-multi-carousel-track {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        position: relative;
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        will-change: transform,transition;
      }
      .react-multiple-carousel__arrow {
        position: absolute;
        outline: 0;
        -webkit-transition: all .5s;
        transition: all .5s;
        border-radius: 35px;
        z-index: 1000;
        border: 0;
        background: rgba(0,0,0,.5);
        min-width: 43px;
        min-height: 43px;
        opacity: 1;
        cursor: pointer;
      }
      .react-multiple-carousel__arrow:hover {
        background: rgba(0,0,0,.8);
     }

     .react-multiple-carousel__arrow:before {
        font-size: 20px;
        color: #fff;
        display: block;
        font-family: revicons;
        text-align: center;
        z-index: 2;
        position: relative;
     }

     .react-multiple-carousel__arrow--left {
        left: calc(4% + 1px);
     }

     .react-multiple-carousel__arrow--left:before {
        content: "\\e824";
     }

     .react-multiple-carousel__arrow--right {
        right: calc(4% + 1px);
     }

    .react-multiple-carousel__arrow--right:before {
        content: "\\e825";
    }

    .react-multi-carousel-dot-list {
        position: absolute;
        bottom: 0;
        display: flex;
        left: 0;
        right: 0;
        justify-content: center;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
      }
      .react-multi-carousel-dot button {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        opacity: 1;
        box-shadow: none;
        -webkit-transition: background .5s;
        transition: background .5s;
        border: 2px solid grey;
        padding: 0;
        margin: 0 6px 0 0;
        outline: 0;
        cursor: pointer;
      }
      .react-multi-carousel-dot--active button,.react-multi-carousel-dot button:hover:active {
        background: #080808;
      }
      .react-multi-carousel-item {
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      }
      @media (-ms-high-contrast:active),(-ms-high-contrast:none) {
        .react-multi-carousel-item {
            flex-shrink: 0 !important;
        }
        .react-multi-carousel-track {
            overflow: visible !important;
        }
      }
      /* ------------------------------------- */

      padding: 10px 0px;
      box-sizing: border-box;
      .react-multi-carousel-track {
        li:first-child {
          padding-left: 0;
        }
      }
      .react-multi-carousel-item {
        padding: 0 5px;
        box-sizing: border-box;
        img {
          max-width: 100%;
        }
        span {
          text-align: right;
        }
        >.item {
          width: 100% !important;
        }
      }
    }
    .react-multiple-carousel__arrow,
    .arrow {
      outline: none;
      z-index: 10;
      position: absolute;
    }
  }
`;

export const WidgetWrapper = styled.div`
  background: white;
  overflow: hidden;
  -webkit-font-smoothing:antialiased;
  &&&& {
    ${props => props.customStyle}
  }
  ${props => props.isWebView && css`
    .title {
      text-decoration: none !important;
    }
  `}
  ${props => !!props.stickiness && props.stickiness !== 'none' && css`
      position: fixed;
      z-index: ${props.inWidgetBuilder ? 1 : 0};
      max-height: ${props.inWidgetBuilder ? '600px' : 'auto'};
  `}
  ${props => props.stickiness === 'top' && css`
      left: ${props.inWidgetBuilder ? 30 : 0}px;
      right: ${props.inWidgetBuilder ? 50 : 0}px;
      top: ${props.inWidgetBuilder ? 30 : 0}px;
      width: auto;
  `}
  ${props => props.stickiness === 'left' && css`
      left: ${props.inWidgetBuilder ? 30 : 0}px;
      width: ${props.inWidgetBuilder ? '400px' : 'auto'};
      top: ${props.inWidgetBuilder ? 30 : 0}px;
  `}
  ${props => props.stickiness === 'left_bottom' && css`
      left: ${props.inWidgetBuilder ? 30 : 0}px;
      bottom: ${props.inWidgetBuilder ? '20px' : 0};
      width: ${props.inWidgetBuilder ? '400px' : 'auto'};
  `}
  ${props => props.stickiness === 'right' && css`
      right: ${props.inWidgetBuilder ? 50 : 0}px;
      top: ${props.inWidgetBuilder ? 30 : 0}px;
      width: ${props.inWidgetBuilder ? '400px' : 'auto'};
  `}
  ${props => props.stickiness === 'right_bottom' && css`
      right: ${props.inWidgetBuilder ? 50 : 0}px;
      bottom: ${props.inWidgetBuilder ? '30px' : 0};
      width: ${props.inWidgetBuilder ? '400px' : 'auto'};
  `}
  ${props => props.stickiness === 'bottom' && css`
      left: ${props.inWidgetBuilder ? 30 : 0}px;
      right: ${props.inWidgetBuilder ? 50 : 0}px;
      bottom: ${props.inWidgetBuilder ? '10px' : 0};
      width: auto;
  `}

  ${props => css`
    .mc-widget-wrapper {
      position: relative;
    }
    .widget-title {
      font-weight: 700;
      margin: 0;
      padding: 28px 0 8px;
      font-size: 1.5625rem;
      line-height: 1.2;
      a {
        color: inherit;
        text-decoration: none;
      }
      .hide-btn {
        display: none;
      }
    }
    .item-list {
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 0;
      box-sizing: border-box;
    }
    .item {
      flex-grow: 0;
      width: ${100 / props.column}%;
      box-size: border-box;
      display: inline-block;
      padding: 23px 0;
      box-sizing: border-box;
      &.placeholder .item-inner .thumbnail {
        opacity: .5;
      }
      &.placeholder .item-inner .title,
      &.placeholder .item-inner .publish_date,
      &.placeholder .item-inner .section {
        display: block !important;
        line-height: 1em;
        font-size: 18px !important;
        >span {
          display: inline-block;
          font-size: 12px !important;
          line-height: 1em;
          margin-bottom: 1px 0;
          color: ${MediumGray} !important;
          background-color: ${LightGray1} !important;
        }
      }
      .item-inner {
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        padding: 5px 0;
        position: relative;
        text-decoration: none;
        cursor: pointer;
        color: black;
        .thumbnail {
          flex: none;
          width: 40%;
          padding-bottom: 24%;
          margin: 0 3% 0 0;
          display: inline-block;
          height: 0;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
          position: relative;
          background-size: 100% 100%;
          &.sponsor_ads {
            height: auto !important;
            padding-bottom: inherit !important;
          }
          > img {
            width: 100%;
            height: auto;
          }
          .video-icon {
            background: url(${process.env.REACT_APP_SERVER_URL}/img/icons/icon-play.svg);
            width: 16px;
            height: 16px;
            position: absolute;
            bottom: 10px;
            right: 10px;
            background-size: 100%;
            background-repeat: no-repeat;
          }
        }
        .section {
          display: none;
        }
        .site {
          display: none;
        }
        .title {
          font-size: 18px;
          padding-right: 8px;
          text-overflow: ellipsis;
          word-wrap: break-word;
          word-break: break-word;
          overflow: hidden;
          display: -webkit-box;
           -webkit-box-orient: vertical;
           -webkit-line-clamp: 3;
          max-height: 4.2em;
          line-height: 1.4em;
          max-width: 100%;
          margin: 5px 0;
          &:hover {
            text-decoration: underline;
          }
          &:active {
            text-decoration: none;
          }
        }
        .abstract {
          font-size: 16px;
          line-height: 1.18em;
          &.hidden {
            display: none;
          }
        }
        .publish_date {
          color: rgba(0,0,0,.5);
          margin-top: 5px;
          display: none;
        }
        .author {
          color: rgba(0,0,0,.5);
          margin-top: 5px;
          display: none;
        }
        .sponsored_ads {
          color: #999;
          font-size: 12px;
        }
      }
      &.thumbnail_left .item-inner {
        display: flex;
        .thumbnail {
          margin-right: 10px;
          width: 40%;
          flex-grow: 0;
          padding-bottom: 24%;
        }
        .brief {
          width: 60%;
          flex-grow: 1;
        }
      }
      &.thumbnail_top {
        .item-inner {
          display: block;
          padding-right: 20px;
          .thumbnail {
            margin-right: 10px;
            flex-grow: 0;
            padding: 0;
            width: 100%;
            max-width: 300px;
            height: ${0.5 * props.mediaWidth / props.column}px;
            max-height: 150px;
            display: block;
            margin-bottom: 15px;
          }
        }
      }
      &.thumbnail_right .item-inner{
        display: flex;
        flex-direction: row-reverse;
        .brief {
          flex-grow: 1;
          width: 60%;
        }
        .thumbnail {
          margin-left: 10px;
          width: 40%;
          flex: none;
        }
      }
    }
    &.grid_main_top,
    &.single_column_main_top {
      .widget-body {
        .item.main {
          width: 100%;
          padding: 10px 0;
          margin: 0;
          border-bottom: 1px solid #ddd;
          .item-inner {
            display: flex;
            text-decoration: none;
            justify-content: flex-start;
            padding: 0 0 0 0;
            .thumbnail {
              width: 38%;
              padding-bottom: 18%;
              margin: 0 0 0 0;
              display: inline-block;
              height: 0;
            }
            .brief{
              padding: 10px 20px;
              .section {
                display: block;
              }
              .title {
                 font-size: 18px;
                  line-height: ${18 * 1.2}px;
                  max-height: ${21.6 * 3}px;
                  -webkit-line-clamp: 3;
              }
              .publish_date {
                font-size: 16px;
                margin-top: 5px;
                display: block;
              }
            }
          }
        }
        .item-list {
          width: 100%;
        }
      }
    }
    &.grid_main_right {
      .widget-body {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: nowrap;
        align-items: flex-start;
        .item.main {
          width: 30%;
          flex: none;
          padding-top: 0;
          .item-inner {
            display: block;
            text-decoration: none;
            padding-top: 0;
            .thumbnail {
              width: 100%;
              padding-bottom: 60%;
              display: block;
              margin: 0;
              margin-bottom: 20px;
            }
            .brief{
              padding: 10px 20px;
              .title {
                 font-size: 18px;
                  line-height: ${18 * 1.2}px;
                  max-height: ${21.6 * 3}px;
                  -webkit-line-clamp: 3;
              }
              .section {
                display: block;
              }
              .publish_date {
                font-size: 16px;
                margin-top: 5px;
                display: block
              }
            }
          }
        }
        .item-list {
          width: 70%;
          border-right: 1px solid #ddd;
        }
      }
    }
    &.single_column {
      .item {
      }
    }
    &.single_row {
      .item {
        width: 100%;
      }
    }
  `}


  ${props => props.darkMode && css`
    &&& {
      background: black;
      color: white;
      .item {
        border-bottom-color: rgba(255,255,255,.4) !important;
      }
      .title, .widget-title, .publish_date {
        color: white !important;
      }
    }
  `}
`
export const WidgetBody = styled.div``