import queryString from 'qs';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';

export const elementInViewport = function (el) {
  var top = el.offsetTop;
  var left = el.offsetLeft;
  var width = el.offsetWidth;
  var height = el.offsetHeight;

  while (el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  return (
    top >= window.pageYOffset &&
    left >= window.pageXOffset &&
    (top + height) <= (window.pageYOffset + window.innerHeight) &&
    (left + width) <= (window.pageXOffset + window.innerWidth)
  );
}

export const scrollIntoView = function (rpType) {
  try {
    document.querySelector(`[data-rp = ${rpType}]`)
  } catch (e) {
    console.error(e);
  }
}

export const getURLParams = function () {
  return queryString.parse(window.location.search.slice(1)) || {};
}

export const validateUrl = async function (url) {
  if (!url || url === 'null' || url === 'undefined' || url === 'NA') {
    return false;
  }
  try {
    const result = await axios(url);
    if (result.statusCode < 400) {
      return true;
    }
    return false;
  } catch (e) {
    console.error(e);
  }
};

export const readCookie = function (name) {
  try {
    var cookieValue = '';
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length >= 2) {
      cookieValue = parts.pop().split(";").shift();
    }
    return cookieValue;
  } catch (e) {
    console.log(e);
  }

};

export const isValidUrl = function(string) {
  try {
    new URL(string);
  } catch (e) {
    return false;
  }
  return true;
}
