import {injectGlobal, createGlobalStyle} from 'styled-components';
import {DarkGray1} from "./StyleCommon";
import {paginationStyle} from "../uikit/Pagination/index.style";
import {tooltipStyles} from "../uikit/tooltip/index.style";
import {sliderStyles} from "../uikit/react-slider/rc-slider.style";
import {editorStyles} from "../uikit/editor/editor-css.style";
import {datepickerStyles} from "./externalStyles/react-datepicker-css.style";

export const GlobalStyle = createGlobalStyle`
  html, body {
    background-color: white;
    margin: 0;
    overflow-x: hidden;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: FKGroteskNeue, "Open Sans", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    background-color: white;
    color: ${DarkGray1};
  }
  input, button {
    font-family: "FKGroteskNeue", "Open Sans", Helvetica, Arial, sans-serif;
  }
  ul, ol {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  a,
  a:active,
  a:visited {
    color: ${DarkGray1};
    font-style: normal;
  }

  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: all 500ms ease-in;
  }

  .fade-leave {
    opacity: 1;
  }

  .fade-leave.fade-leave-active {
    opacity: 0.01;
    transition: all 300ms ease-in;
  }

  .fade-appear {
    opacity: 0.01;
    filter: blur(3px);
  }

  .fade-appear.fade-appear-active {
    opacity: 1;
    transition: all .5s ease-in;
    filter: blur(0);
  }

  .row-fade-appear {
    opacity: 0.01;
  }

  .row-fade-appear.row-fade-appear-active {
    opacity: 1;
    transition: all .5s ease-in;
  }

  ${paginationStyle}

  ${tooltipStyles}

  ${sliderStyles}

  ${editorStyles}

  ${datepickerStyles}
`;
