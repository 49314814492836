import React from 'react';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';

import {withConsumer} from '../app/ApplicationContext';
import {DeployPanel} from './deployment-code.style';
import copy from 'copy-to-clipboard';
import Btn from '../uikit/btn/index';

export default withConsumer(class extends React.Component {

  constructor(props) {
    super(props);

    this.state = {}
  }

  render() {
    const {abtesting} = this.props;
    const {id} = abtesting || {};
    const copyMap = this.state;

    return (
      <DeployPanel>
        <div>
          <h2 className="row-title">Deploy to website</h2>
          <h3 className="sub-row-title">Step 1: Add the lib to every page of your website</h3>
          <div className="copy-able">
            <div className="copy-cat">
              <Btn
                type="link"
                size="small"
                className={copyMap['tag'] ? 'copied' : ''}
                onClick={e => this.copyCode(this.genTag(), 'tag')}>
                <i className="fa fa-copy"/>
                {copyMap['tag'] ? 'Copied' : 'Copy Code'}
              </Btn>
            </div>
            <SyntaxHighlighter language="html" wrapLines={true}>
              {this.genTag()}
            </SyntaxHighlighter>
          </div>

          <h3 className="sub-row-title">Step 2: Copy and paste this code into your page</h3>
          <p>&nbsp;&nbsp; (Option 1) Render the A/B testing widget automatically: </p>
          <div className="copy-able">
            <div className="copy-cat">
              <Btn type="link" size="small"
                   className={copyMap['container'] ? 'copied' : ''}
                   onClick={e => this.copyCode(this.genCode(id), 'container')}>
                <i className="fa fa-copy"/>
                {copyMap['container'] ? 'Copied' : 'Copy Code'}
              </Btn>
            </div>
            <SyntaxHighlighter language="html" wrapLines={true}>
              {this.genCode(id)}
            </SyntaxHighlighter>
          </div>
          <p>&nbsp;&nbsp; (Option 2) Render the A/B testing widget manually: </p>
          <div className="copy-able">
            <div className="copy-cat">
              <Btn type="link" size="small"
                   className={copyMap['manually'] ? 'copied' : ''}
                   onClick={e => this.copyCode(this.genManualCode(id), 'manually')}>
                <i className="fa fa-copy"/>
                {copyMap['manually'] ? 'Copied' : 'Copy Code'}
              </Btn>
            </div>
            <SyntaxHighlighter language="javascript" wrapLines={true}>
              {this.genManualCode(id)}
            </SyntaxHighlighter>
          </div>

          {/*<h2 className="row-title">Deploy to mobile app</h2>*/}
          {/*<p>Replace the parameters in the following html code to the real value and put code into a webview in the*/}
          {/*  correct position</p>*/}

          {/*<div className="copy-able">*/}
          {/*  <div className="copy-cat">*/}
          {/*    <Btn type="link" size="small"*/}
          {/*         className={copyMap['mobile'] ? 'copied' : ''}*/}
          {/*         onClick={e => this.copyCode(this.genMobileCode(id), 'mobile')}>*/}
          {/*      <i className="fa fa-copy"/>*/}
          {/*      {copyMap['mobile'] ? 'Copied' : 'Copy Code'}*/}
          {/*    </Btn>*/}
          {/*  </div>*/}
          {/*  <SyntaxHighlighter language="html" wrapLines={true}>*/}
          {/*    {this.genMobileCode(id)}*/}
          {/*  </SyntaxHighlighter>*/}
          {/*</div>*/}

          <h4>Using REST API. </h4>
          <p>
            Call the following API to fetch recommendation data and render the
            items using your native code.
            correct position
          </p>
          <div className="copy-able">
            <div className="copy-cat">
              <Btn type="link" size="small"
                   className={copyMap['api'] ? 'copied' : ''}
                   onClick={e => this.copyCode(this.genRESTCode(), 'api')}>
                <i className="fa fa-copy"/>
                {copyMap['api'] ? 'Copied' : 'Copy Code'}
              </Btn>
            </div>
            <SyntaxHighlighter language="json" wrapLines={true}>
              {this.genRESTCode()}
            </SyntaxHighlighter>
            {/*<p>Response Structure:</p>*/}
            {/*<SyntaxHighlighter language="json" wrapLines={true}>*/}
            {/*  {`{"data": {"items": [...]}, "context": {...}}`}*/}
            {/*</SyntaxHighlighter>*/}
          </div>
        </div>
      </DeployPanel>
    )
  }

  genTag() {
    const {appState: {session}} = this.props;
    const {network = 'mediacorp'} = session || {};
    return `<script async src="${process.env.REACT_APP_SERVER_URL}/tag.js?network=${network}"></script>`;
  }

  genCode(id) {
    const code = `<div id="${id}" data-role="rec-abtesting"></div>`;
    return code;
  }

  genManualCode(id) {
    const code = `var recApp = recApp || {};
recApp.callQueue = recApp.callQueue || [];
recApp.callQueue.push(['insertWidget', {
  widgetId: '${id}',
  type: 'abtesting',
  renderFunction: function (data, context) {
    //use the the data: data.items to do any rendering you want.
  }
}])`;
    return code;
  }

  genMobileCode(id) {
    const {appState: {session}} = this.props;
    const {network = 'mediacorp'} = session || {};
    return `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
  <script>
    window.recApp = window.recApp || {};
    recApp.context = {
      isWebView: true,
      site: '{{site}}',
      lang: '{{lang}}',
      url: '{{url}}', //current article url
      referrer: '{{ueferrer}}',
      content_id: '{{content_id}}',
      meid: '{{meid}}',
      device_id: '{{device_id}}', //iOS IDFA or Androdi GAID
      cxense_id: '{{cxense_id}}', // used for a/b testing benchmark
    };
  </script>
</head>
<body>
  <div id="${id}" data-role="rec-abtesting"></div>
  <script async src="${process.env.REACT_APP_SERVER_URL}/tag.js?network=${network}"></script>
</body>
</html>`
  }

  copyCode(code, codeType) {
    copy(code);
    this.setState({[codeType]: true});
    setTimeout(() => {
      this.setState({[codeType]: false});
    }, 2000);
  }

  genManualCode() {
    const {abtesting} = this.props;
    const code = `var recApp = recApp || {};
recApp.callQueue = recApp.callQueue || [];
recApp.callQueue.push(['insertWidget', {
  widgetId: '${abtesting.id}',
  renderFunction: function (data, context) {
    //use the the data.items to do any rendering you want. for example:
    //recApp.renderWidget(data, context, '#recommendContainner')
  }
}])`;
    return code;
  }

  genRESTCode() {
    const {abtesting} = this.props;
    if(abtesting.isEngineSticky && abtesting.site === 'mewatch') {
      return `curl --location '${process.env.REACT_APP_SERVER_URL}/api/v1/mewatch/recommend/${abtesting.id}?userId=a1c7fcb8-195e-4f6a-aefd-169230a8dab1&?token=e6f6h28e26vbc8442b288eb6121d85b9a4' \\
--header 'Content-Type: application/json' \\
--data '{
    "contentItemId": "282669"
    //<other parameters same as TA>
}'
`
    } else {
      return `curl -X POST '${process.env.REACT_APP_SERVER_URL}/api/v1/loadWidget?token=e6f6h28e26vbc8442b288eb6121d85b9a4' -H 'Accept: application/json' -H 'Content-Type: application/json' --data-binary '{
  "id":"${abtesting.id}",
  "context": {
    "meid": "feea1fb7-093e-4904-842d-4efaa7e9856b",
    "url": "https://www.channelnewsasia.com/news/business/singapore-world-past-peak-trade-globalisation-cptpp-rcep-asean-12929886",
    "content_id": "12929886"
  }
}'`;
    }
  }
})
