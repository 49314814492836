import styled, {css} from 'styled-components';
import {Dropdown} from "../uikit/index";
import Pagination from 'rc-pagination';
import {
  BitterSweet1, DarkGray1,
  BlueJeans, BlueJeans1, DarkGray, Grass, LightBlue, LightGray, LightGray1, MediumGray, MediumGray1,
  PrimaryDarkBlue, Grass1, Sunflower1
} from "../app/StyleCommon";
import Carousel from '../uikit/react-slider';

export const HomeWrapper = styled.div`
  background-color: white;
  padding: 10px 20px;
  min-height: calc(100vh - 50px);
  width: 100%;
  box-sizing: border-box;
  @media screen and (max-width: 667px) {
    padding: 20px 10px;
  }
`;
