import React, {Component} from 'react';
import moment from 'moment';
import {findDOMNode} from 'react-dom';
import _ from 'lodash';
import DateSelector from './index';
import {DateSelectorDropdownWrapper} from './index.style'

export default class extends Component {
  static defaultProps = {};

  state = {active: false}

  render() {
    let {mode, period, tabIndex, shortcuts = []} = this.props;
    let calculatedPeriod = period;
    const InternalFormat = 'YYYY-MM-DD';
    if (period === 'last7days') {
      calculatedPeriod = {start: moment().add(-6, 'day').format(InternalFormat), end: moment().format(InternalFormat)}
    } else if (period === 'last30days') {
      calculatedPeriod = {start: moment().add(-29, 'day').format(InternalFormat), end: moment().format(InternalFormat)}
    } else if (period === 'last60days') {
      calculatedPeriod = {start: moment().add(-59, 'day').format(InternalFormat), end: moment().format(InternalFormat)}
    } else if (period === 'last90days') {
      calculatedPeriod = {start: moment().add(-89, 'day').format(InternalFormat), end: moment().format(InternalFormat)}
    } else if (period === 'last180days') {
      calculatedPeriod = {start: moment().add(-179, 'day').format(InternalFormat), end: moment().format(InternalFormat)}
    } else if (period && typeof period === 'object') {
      const delta = 480 + (new Date()).getTimezoneOffset(); //delta with singapore time
      calculatedPeriod = {
        start: moment(period.start).add(delta, 'minute').format(InternalFormat),
        end: moment(period.end).add(delta, 'minute').format(InternalFormat),
      }
    }
    let selectedShortcut = shortcuts.find(d => d.key === period);
    const {active} = this.state;
    return (
      <DateSelectorDropdownWrapper tabIndex={tabIndex} className={`date-selector-dropdown ${active ? 'active' : ''}`}>
        <a className='toggler' onClick={e => this.handleToggle(e)}>
          {
            typeof period === 'object' &&
            <React.Fragment>
              <span className="highlight">{moment(period.start).format('DD-MMM-YYYY')}</span>
              &nbsp;-&nbsp;
              <span className="highlight">{moment(period.end).format('DD-MMM-YYYY')}</span>
            </React.Fragment>
          }
          {
            !!selectedShortcut &&
            <span className="highlight">{selectedShortcut.label}</span>
          }
          {
            !period && <span>Select Date Range</span>
          }
          <i className="fa fa-calendar"/>
        </a>
        {
          active &&
          <div className="dropdown-content">
            <DateSelector
              mode={mode}
              period={calculatedPeriod}
              className={`date-selector ${mode}`}
              onDateRangeUpdated={newPeriod => {
                this.setState({active: false});
                this.notifyDateRangeUpdated(newPeriod);
              }}/>
            {
              !!shortcuts.length &&
              <label className="sep">
                <span style={{fontWeight: 'bold', fontSize: '12px', color: '#656D78'}}>OR Predefined Period</span>
              </label>
            }
            {
              !!shortcuts.length &&
              <ul className="shortcuts">
                {shortcuts.map(d => {
                  return (
                    <li key={d.key} className={period === d.key ? 'selected' : ''}>
                      <a onClick={e => {
                        this.setState({active: false});
                        this.notifyDateRangeUpdated(d.key)
                      }
                      }>{d.label}</a>
                    </li>
                  );
                })}
              </ul>
            }
          </div>
        }
      </DateSelectorDropdownWrapper>
    );
  }

  notifyDateRangeUpdated(period) {
    if (typeof period === 'string') {
      return this.props.onDateRangeUpdated(period);
    } else {
      let calculatedPeriod = {
        start: new Date(`${moment(period.start).format('YYYY-MM-DD')}T00:00:00.000+08:00`).toISOString(),
        end: new Date(`${moment(period.end).format('YYYY-MM-DD')}T23:59:59.999+08:00`).toISOString(),
      }
      return this.props.onDateRangeUpdated(calculatedPeriod);
    }
  }

  handleToggle(e) {
    const {disabled} = this.props;
    if (!disabled) {
      this.setState({active: !this.state.active});
    }
  }

  handleClickOutside(event) {
    let {active} = this.state;
    if (active) {
      try {
        const self = findDOMNode(this)
        if (!self || !self.contains(event.target)) {
          this.setState({active: false});
        }
      } catch (e) {
        //happens when the dom is already destroyed
        console.error(e);
      }
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside.bind(this), true); // capture phase
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside.bind(this), true);
  }
}