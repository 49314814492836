import React from 'react'
import {Redirect, Link} from 'react-router-dom';
import {post} from '../utils/request';
import {Spinner} from '../uikit/Loader/index.style';
import {LoginWrapper, Logo, ErrorMsg, Tint} from './index.style';
import {FormWrapper, Header, ErrorMsgWrapper, FormField, SubmitBtn, BottomPart} from './index.style';

export default class Login extends React.Component {

  state = {loading: false, error: null}

  render() {
    return (
      <LoginWrapper>
        {(!navigator.userAgent.match(/Trident/g) && !navigator.userAgent.match(/MSIE/g)) && <Tint/>}
        {this.renderForgotForm()}
      </LoginWrapper>
    );
  }

  forgotPassword(e) {
    e.preventDefault();
    var {email} = this.state;
    this.setState({success: false, loading: true, error: null});
    post('/api/forgotPW', {email})
      .then(result => {
        this.setState({loading: false, success: true});
      }).catch(e => {
      console.error(e);
      this.setState({loading: false, error: e.message});
    });
  }

  renderForgotForm() {
    const {userId, error, spinning, loading} = this.state;
    return (
      <FormWrapper onSubmit={e => {
        e.preventDefault();
      }}>
        <Header>Reset Password</Header>
        {
          !!error && <ErrorMsgWrapper>{error}</ErrorMsgWrapper>
        }
        <div>
          {this.state.success && <p style={{color: 'green'}}>New password will be sent to your email.</p>}
        </div>
        <FormField>
          <input ref="userInput"
                 value={userId}
                 type="text"
                 placeholder="Mediacorp Email"
                 onChange={e => this.setState({email: e.currentTarget.value})}/>
        </FormField>
        <SubmitBtn onClick={e => this.forgotPassword(e)}>
          {
            loading &&
            <Spinner className="spinner" type="mini"/>
          }
          Reset Password
        </SubmitBtn>
        <BottomPart>
          <Link to={'/'}>Back</Link>
        </BottomPart>
      </FormWrapper>
    );
  }
}