import React from 'react';
import styled, {css} from 'styled-components';
import {
  BitterSweet1,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass1, LightGray, LightGray1, MediumGray,
  MediumGray1, LightBlue
} from "../../app/StyleCommon";

import {WidgetWrapper} from './grid.style';

export const WidgetWrapper8Days = styled(WidgetWrapper)`
  &&& {
    font-family: "Josefin Sans",sans-serif;
    margin: 35px 0;
    .widget-title {
      font-size: 20px;
      letter-spacing: .2em;
      color: #14314e;
      font-weight: 600;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 3px solid #14314e;
      >span {
        margin-bottom: -20px;
        background: white;
        text-transform: uppercase;
        font-size: 15.33333px;
        letter-spacing: .15em;
        color: rgb(33, 37, 41);
        padding-right: 10px;
      }
    }
    .item-list {
      background: transparent;
      display: flex;
      align-items: flex-start;
      .item {
        &.thumbnail_left .item-inner .thumbnail,
        &.thumbnail_right .item-inner .thumbnail {
          width: 50%;
          height: 0;
          padding-bottom: 19.6%; 
        }
        .item-inner {
          padding: 0;
          .thumbnail {
          }
          .brief {
            .title {
              font-size: 14px;
              color: #11202f;
              text-decoration: none;
              font-weight: normal;
              line-height: 19px;
              max-height: 5.6em;
              color: #11202f;
              line-height: 1.4em;
              &:hover {
                text-decoration: none;
              }
            }
            .publish_date {
              display: none;
            }
            .section {
              display: block;
              font-size: 12px;
              color: #df3343;
              background-color: #fff;
              position: relative;
              display: inline-block;
            }
            .site {
              display: block;
              font-size: 8px;
              font-weight: normal;
              color: #666;
              text-transform: uppercase;
              letter-spacing: .3em;
              padding-top: 15px;
            }
          }
        }
      }
    }
  }
  ${props => props.row === 1 && props.mediaWidth >= 700 && css`
    &&& {
      .item-list {
        display: flex;
        align-items: flex-start;
        padding: 10px 0;
        flex-wrap: nowrap;
        .item {
          width: 25%;
          display: inline-block;
          .item-inner {
            padding: 15px 20px;
            .thumbnail {
              width: 100%;
              height: 0;
              padding-bottom: 56%;
            }
            .brief {
              &:after {
                margin-left: auto;
              }
              .title {
                font-size: 12px;
                text-decoration: none;
                font-weight: 400;
                &:hover {
                  text-decoration: none;
                }
              }
              .site {
              }
            }
          }
        }
      }
    }
  `}
  ${props => props.row === 1 && props.mediaWidth < 700 && props.mediaWidth >= 510 && css`
    &&& {
      .item-list {
        .item {
          width: 50%;
          .item-inner {
            padding: 10px;
            display: flex;
            .thumbnail {
              width: 50%;
              height: 0;
              padding-bottom: 28%;
              max-width: inherit;
            }
            .brief {
              .title {
                font-size: 12px;
              }
              .site {
              }
            }
          }
        }
      } 
    }
  `}
  
  ${props => props.row === 1 && props.mediaWidth < 510 && css`
    &&& {
      .item-list {
        .item {
          width: 100%;
          .item-inner {
            padding: 10px;
            display: flex;
            .thumbnail {
              width: 50%;
              height: 0;
              padding-bottom: 19.6%;
              max-width: inherit;
            }
            .brief {
              .title {
              }
              
              .site {
              }
            }
          }
        }
      } 
    }
  `}
`