import styled, {css} from 'styled-components';
import {BlueJeans1, Grass, Grass1, LightGray, MediumGray, Mint1} from "../../app/StyleCommon";

export const SwitchBoxWrapper = styled.span`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  ${props => props.disabled && css`
    pointer-events: none;
    opacity: .5;
    cursor: not-allowed;
  `}
  input[type=checkbox] {
    display: block;
    width: 62px;
    height: 24px;
    margin: 0;
    cursor: pointer;
    outline: none;
    opacity: 0;
    //visibility: hidden;
    & + span {
      pointer-events: none;
      cursor: pointer;
    }
    & + span::before {
      content: '';
      display: block;
      position: absolute;
      visibility: visible;
      left: 0;
      right: 0;
      top: 0px;
      bottom: 0px;
      background: ${MediumGray};
      border-radius: 4px;
    }
    & + span::after {
      content: 'OFF';
      font-size: 10px;
      font-weight: bold;
      color: #333;
      display: block;
      position: absolute;
      visibility: visible;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 2;
      width: 30px;
      height: 20px;
      left: 2px;
      top: 2px;
      background: ${LightGray};
      border-radius: 2px;
      transition: all 0.3s ease;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.12);
      ${props => props.offLabel && css`
        content: '${props.offLabel}';
      `}
    }
    &:checked {
      & + span::after {
        left: 30px;
        content: 'ON';
        color: ${BlueJeans1};
        ${props => props.onLabel && css`
          content: '${props.onLabel}';
        `}
      }
      & + span::before {
        background-color: ${BlueJeans1};
      }
    }
  }
`;