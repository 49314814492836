import React, {Component, useEffect} from 'react';
import ReactServer from 'react-dom/server';
import createTooltip from '../uikit/tooltip/index';
import * as echarts from 'echarts';
import classnames from 'classnames';
import moment from 'moment';
import styled from 'styled-components';
import _ from 'lodash';
import {ReRenderHook} from '../utils/hooks';
import {
  BitterSweet,
  BitterSweet1,
  BlueJeans, BlueJeans0,
  BlueJeans1, colorCategory1, DarkGray, GrapeFruit, Grass,
  Grass1,
  LightBlue,
  LightGray,
  LightGray1, MediumGray, MediumGray1,
  Sunflower,
  Sunflower1
} from "../app/StyleCommon";
import {intFormatter, pct0Formatter, pct2Formatter, pctFormatter, uniquesFormatter} from "../utils/formatter";
import {Table, TableScrollWrapper} from "../uikit";
import {BarWrapper} from "../home/index.style";
import Sparkline2 from "./sparkline2";
import {Link} from "react-router-dom";

const PerformanceWrapper = styled.div`
  table.performance-table {
    border: none;
    >thead >tr >th {
      background: white;
      border: none;
    }
    >tbody >tr:first-child {
      border-top: 1px solid ${MediumGray};
    }
  }
`;
export default class extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openMap: {},
      // openSite: 'cna'
    };
  }

  render() {
    const {data, period} = this.props;
    const {openMap, openSite} = this.state;
    data.forEach(d => {
      d.Web = d.Web || {};
      d.App = d.App || {};
    });
    const maxImpressions = _.max(_.flatten(data.map(d => [d.Web.impressions, d.App.impressions])));
    return (
      <PerformanceWrapper>
        <TableScrollWrapper className="middle">
          <Table
            className="performance-table"
            headerGroups={[
              {key: '1', label: '', colSpan: 1},
              {key: '2', label: <div style={{marginLeft: '10px', textAlign: 'left', fontWeight: 'normal'}}>Web</div>, colSpan: 2},
              {key: '3', label: <div style={{marginLeft: '10px', textAlign: 'left', fontWeight: 'normal'}}>Mobile</div>, colSpan: 2},
            ]}
            columns={[
              {
                key: 'abtesting',
                label: 'Site A/B Testing',
                align: 'left',
                style:{minWidth: '100px'},
                renderer: d => {
                  return (
                    <div>
                      <div style={{display: 'flex', flexWrap: 'wrap'}} onClick={e => {
                        if(!openSite ||  openSite !== d.site) {
                          this.setState({openSite: openSite === d.site ? null : d.site}, () => {
                            this.props.refreshChart('abtestingLiftReport', {openSite: d.site})
                          });
                        } else {
                          this.setState({openSite: openSite === d.site ? null : d.site}, () => {
                            window.dispatchEvent(new Event('resize'));
                          });
                        }
                      }}>
                        <a style={{marginRight: '10px'}}>
                          {openSite !== d.site && <i className="fa fa-plus-square-o" aria-hidden="true"></i>}
                          {openSite === d.site && <i className="fa fa-minus-square-o" aria-hidden="true"></i>}
                        </a>
                        <img src={`/img/property/${d.site}.png`} style={{width: '18px', height: 'auto', marginRight: '6px'}}/>
                        <div style={{cursor: 'pointer'}}>{d.label}</div>
                      </div>
                      {/*<div style={{fontSize: '12px', color: MediumGray1}}>{d.App.algorithm}</div>*/}
                    </div>
                  )
                }
              },
              ...(_.flatten(['Web', 'App'].map(platformKey => {
                return [
                  // {
                  //   key: 'impressions' + platformKey,
                  //   label: 'Impressions',
                  //   align: 'left',
                  //   sortable: true,
                  //   style: platformKey === 'Web' ? {borderLeft: '1px solid #e1e2e3'} : null,
                  //   renderer: d => {
                  //     let p = d[platformKey] || {};
                  //     if(!p.impressions) return null;
                  //     return (
                  //       <BarWrapper>
                  //         <div style={{fontSize: '11px', color: DarkGray, marginBottom: '5px', whiteSpace: 'nowrap'}}>Best Model: {p.algorithm}</div>
                  //         <div className="num">{uniquesFormatter(p.impressions)}</div>
                  //         <div className="bar"
                  //              style={{width: pct2Formatter(p.impressions / maxImpressions)}}></div>
                  //       </BarWrapper>
                  //     )
                  //   }
                  // },
                  // {
                  //   key: 'ctr' + platformKey,
                  //   label: 'CTR',
                  //   sortable: true,
                  //   align: 'left',
                  //   renderer: d => {
                  //     let p = d[platformKey] || {};
                  //     if(!p.impressions) return null;
                  //     return (
                  //       <div>
                  //         <div style={{fontSize: '11px', color: DarkGray, marginBottom: '5px', whiteSpace: 'nowrap'}}>&nbsp;</div>
                  //         {pct2Formatter(p.ctr)}
                  //       </div>
                  //     )
                  //   }
                  // },
                  {
                    key: 'lift' + platformKey,
                    label: 'CTR Lift compared to Cxense',
                    sortable: true,
                    align: 'left',
                    renderer: d => {
                      let p = d[platformKey] || {};
                      if(!p.impressions) return null;
                      return (
                        <div>
                          <div style={{fontSize: '11px', color: DarkGray, marginBottom: '5px'}}>Best Model: {p.algorithm}</div>
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <img
                              style={{width: '12px', height: '8px', marginRight: '5px'}}
                              src={
                                `https://ripple.mediacorp.sg/img/arrow3/${p.lift > 0 ? 'up' : 'down'}.png`
                              }/>
                            <div>
                              <div className="num">{pct2Formatter(p.lift)}</div>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  },
                  {
                    key: 'Trending' + platformKey,
                    label: 'Lift Trending',
                    sortable: true,
                    align: 'center',
                    style: platformKey === 'Web' ? {borderRight: '1px solid #e1e2e3'} : null,
                    renderer: d => {
                      let p = d[platformKey] || {};
                      if(!p.impressions) return null;
                      return (
                        <div>
                          {/*<div style={{fontSize: '11px', color: DarkGray, marginBottom: '5px', whiteSpace: 'nowrap'}}>&nbsp;</div>*/}
                          <Sparkline2
                            size={'small'}
                            data={p.liftTrend}
                            formatter={pct2Formatter}
                            markerValue={p.lift}
                            period={period}
                          />
                        </div>
                      )
                    }
                  },
                ]
              })))
            ]}
            rows={_.flatten((data || []).map(d => {
              return _.compact([
                d,
                openSite === d.site ? {isCustomRow: true, columns: [
                    {key: '1', content: '', colSpan: 1},
                    {key: '2', content: this.renderDetailsTable(d.Web), colSpan: 2, style: {verticalAlign: 'top'}},
                    {key: '3', content: this.renderDetailsTable(d.App), colSpan: 2, style: {verticalAlign: 'top'}},
                  ]} : null
              ]);
            }))}
            noDataText={"No Data"}
          />
        </TableScrollWrapper>
      </PerformanceWrapper>
    )
  }

  renderDetailsTable(d) {
    if(!d.id || !d.widgets) return null;
    const BreakdownTableWrapper = this.getBreakdownWrapper();
    return (
      <BreakdownTableWrapper>
        <table>
          <body>
          <tr className="row-header">
            <td>Variant</td>
            <td>Imprs</td>
            <td>CTR</td>
            <td>Lift</td>
            <td colSpan={3}>
              <div>Serving Model</div>
              <div>
                <td style={{paddingLeft: '0'}}>Model</td>
                <td style={{paddingLeft: '0'}}>Coverage</td>
                <td style={{paddingLeft: '0'}}>Lift</td>
              </div>
            </td>
          </tr>
          {d.widgets.map(w => {
            return (
              <tr key={w.id}>
                <td style={{maxWidth: '80px'}}>
                  <div>{w.label || w.name}</div>
                  {!!w.availableFrom && <div className="desc">Data available since {w.availableFrom}</div>}
                </td>
                <td>
                  <div>{uniquesFormatter(w.impressions)}</div>
                  {!!w.benchmark && <div className="desc">cxense: {uniquesFormatter(w.benchmark.impressions)}</div>}
                </td>
                <td>
                  <div>{pct2Formatter(w.ctr)}</div>
                  {!!w.benchmark && <div className="desc">cxense: {pct2Formatter(w.benchmark.ctr)}</div>}
                </td>
                <td style={{color: w.liftColor, fontWeight: w.lift > 0 ? 'bold': 'normal'}}>{pctFormatter(w.lift)}</td>
                <td colSpan={3}>
                  <table className="breakdown">
                    {(w.breakdown || []).map(r => {
                      return (
                        <tr key={r.name} style={{fontSize: '11px', color: '#535353'}}>
                          <td>{r.name}</td>
                          <td>{pctFormatter(r.coverage)}</td>
                          <td style={{color: r.liftColor, fontWeight: r.lift > 0 ? 'bold' : 'normal'}}>{pctFormatter(r.lift)}</td>
                        </tr>
                      )
                    })}
                  </table>
                </td>
              </tr>
            )
          })}
          </body>
        </table>
        <Link className="title" to={`/abtesting/${d.id}`} style={{textDecoration: 'none'}}>
          <span style={{margin: '10px 0 0 15px', color: MediumGray1}}>click here to see details &gt;&gt;</span>
        </Link>
      </BreakdownTableWrapper>
    )
  }

  getBreakdownWrapper() {
    return styled.div`
      margin-bottom: 20px;
      //max-height: 300px;
      //overflow-y: auto;
      table {
        border-collapse: collapse;
      }
      .desc {
        font-size: 11px;
        color: ${MediumGray1};
      }
      .row-header td {
        font-weight: bold;
      }
      table.breakdown {
        width: 100%;
        text-align: center;
        min-width: 200px;
      }
      table.breakdown td, table.breakdown th{
        border: none !important;
        padding: 3px;
        padding-left: 0;
      }
      table.breakdown tr:not(:last-child){
        border-bottom: 1px dashed #d1d2d3;
      }
      table.breakdown tr td:first-child{
        text-align: left;
      }
    `
  }
}