import React from 'react';
import _ from 'lodash';

import {withConsumer} from '../app/ApplicationContext';
import {BasicWrapper} from './step-basic.style';
import {SelectStyled} from './select.style';
import RichEditor from '../uikit/editor/index';

export default withConsumer(class extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      abtesting: this.props.abtesting
    }
  }

  render() {
    const {abtesting} = this.state;
    const {name = '', description = '', site = '', isEngineSticky = false} = abtesting || {};
    const {appState: {session}} = this.props;
    return (
      <BasicWrapper className="step-basic">
        <div className="row">
          <div className="col col-1-1">
            <label>A/B Test Name</label>
            <input
              type="text"
              placeholder='Enter experiment name'
              value={name}
              onChange={e => this.saveAbTesting({...abtesting, name: e.target.value})}/>
          </div>
          <div className="col col-1-1">
            <label>Deployment Site</label>
            <SelectStyled
              defaultLabel={'Select Site'}
              selected={site}
              data={session.sites}
              showGroupBatch={false}
              onChange={site => this.saveAbTesting({...abtesting, site: site})}/>
          </div>
        </div>
        <div>
          <label>A/B Test Description (optional)</label>
          <RichEditor
            data={description}
            onChange={description =>{
              this.saveAbTesting({...abtesting, description: description})
            }}/>
        </div>
      </BasicWrapper>
    )
  }

  saveAbTesting(abtesting) {
    this.setState({abtesting: abtesting});
    this.props.onChange(abtesting);
  }
})