import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import {withConsumer} from '../app/ApplicationContext';
import MainLayout from '../layout/index';
import {sites} from '../utils/metadata';
import {post} from '../utils/request';
import qs from 'querystring';
import {Check, Close} from '../uikit/icons/index';
import {StatusTag} from './index.style';
import {ProgressBar, Table, TableScrollWrapper, Hint, Btn, CondirmDialog, Loader} from '../uikit/index';
import {Spinner} from '../uikit/Loader/index.style';
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import {
  SetupPageWrapper,
  StepProgressbar,
  StepStack,
  StepFormWrapper,
  PromptBody,
  PublishDialogWrapper,
  Forecaster,
  ForecastResult
} from './builder.style';
import {SelectStyled} from './select.style';
import {StyledDropdown} from '../uikit/dropdown/styled';
import {PaginationStyled} from '../uikit/Pagination/index.style';
import {BlueJeans, DarkGray} from "../app/StyleCommon";
import BuilderCampaign from './builder-campaign';
import BuilderSelectSegment from './builder-segment';
import BuilderSelectPlacement from './builder-placement';
import BuilderCreative from './builder-creative';
import NavigationPrompt from "../uikit/prompt/index";
import {intFormatter, pct0Formatter, pct2Formatter} from "../utils/formatter";

export default withRouter(withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      originalCampaign: {widgets: [], creatives: [], segment: 'RON'},
      campaign: {widgets: null, creatives: [], segment: null},
      touched: false,
      errorMap: {},
      allTouches: []
    };
  }

  getStepConfig() {
    const {step, campaign} = this.state;
    return [
      {
        step: 0,
        done: !!campaign && this.isValid(0),
        icon: '/img/ads/builder/campaign.png',
        icon1: <i className="fa fa-bullhorn" aria-hidden="true"></i>,
        name: 'Campaign Details',
        desc: 'Setup basic campaign information'
      },
      {
        step: 1,
        done: !!campaign.segment,
        icon: '/img/ads/builder/audience.png',
        icon1: <i className="fa fa-users" aria-hidden="true"></i>,
        name: 'Select Segment',
        desc: 'Set target audience using MeID segment',
        // optional: false
      },
      {
        step: 2,
        done: !!campaign.placement,
        icon: '/img/ads/builder/placement.png',
        icon1: <i className="fa fa-window-restore" aria-hidden="true"></i>,
        name: 'Select Ads Slot',
        desc: 'Choose widget ads slot to display the Ad',
        // optional: false
      },
      {
        step: 3,
        done: !!campaign.creatives.length,
        icon: '/img/ads/builder/creative.png',
        icon1: <i className="fa fa-file-image-o" aria-hidden="true"></i>,
        name: 'Select Creatives',
        desc: 'Set display creative for the campaign',
      },
    ]
  }

  render() {
    const {loaded, loading, error, step, errorMap, touched, flashMessage, skipPrompt} = this.state;
    const {campaign, originalCampaign, advertisers, orders} = this.state;
    const currentStep = this.getStepConfig().find(d => d.step === step);
    const urlParams = qs.parse(window.location.search.slice(1));
    const stepConfig = this.getStepConfig();
    const {appState: {session}} = this.props;
    let pageTitle = `${urlParams.id ? (session.readOnly ? 'View' : 'Modify') : 'Setup'} Campaign`;
    let navTitle = (campaign.id && campaign.name) ? campaign.name : pageTitle;
    return (
      <MainLayout
        activeItem={`sponsored_ads`}
        loading={loading}
        breadcrumb={[
          {path: '/sponsored_ads', label: 'Sponsored Ads'},
          {path: `/sponsored_ads/campaigns/${urlParams.id ? 'edit' : 'create'}`, label: `Campaign Setup`}
        ]
        }>
        {!skipPrompt && this.renderPrompt()}
        <SetupPageWrapper>
          <h3>
            <span>{pageTitle}
              {/*{*/}
              {/*  !!campaign.id &&*/}
              {/*  <Link to={`/campaign-history/${campaign.id}`}><i className="fa fa-clock-o"/>History</Link>*/}
              {/*}*/}
            </span>
            <Forecaster>
            </Forecaster>
          </h3>
          {
            !loaded &&
            <ProgressBar
              withoutCache={true}
              fixcenter={false}
              url={`/api/adBuilderQuery`}
              params={{id: urlParams.id}}
              successHandler={({campaign, advertisers, orders, totalSSO}) => {
                this.setState({loaded: true, advertisers, orders, totalSSO});
                if(advertisers) {
                  this.setState({advertisers});
                }
                if (campaign) {
                  this.setState({originalCampaign: campaign, campaign: _.cloneDeep(campaign), advertisers});
                }
              }}
              errorHandler={e => this.setState({error: e.message})}/>
          }
          <StepProgressbar>
            {stepConfig.map((s, index) => {
              let clickable = false;
              let tail = stepConfig[index > 0 ? index - 1 : 0];
              clickable = s.step === step || (!touched && (s.done || tail.done));
              return (
                <div
                  key={'step_' + index}
                  className={classnames('step', {current: s.step === step, success: s.done, clickable: clickable})}
                  onClick={e => {
                    if (clickable) {
                      this.setState({step: s.step})
                    }
                  }}>
                  <div className="step-icon">
                    {/*<img src={s.icon}/>*/}
                    {s.icon1}
                  </div>
                  <div className="step-name">
                    {s.done && <i className="fa fa-check"/>} {s.name}
                  </div>
                  <div className="step-desc">{s.desc}</div>
                </div>
              )
            })}
          </StepProgressbar>
          {
            (!!loaded || !urlParams.id) &&
            <StepStack>
              {this.getStepConfig().map((s, stepIndex) => {
                const Component = [BuilderCampaign, BuilderSelectSegment, BuilderSelectPlacement, BuilderCreative][s.step];
                return (
                  <StepFormWrapper
                    key = {'step_stack_' + stepIndex}
                    current={step}
                    step={s.step} className={classnames({active: s.step === step})}>
                    <div className="step-header">
                      <div className="nav-actions">
                        {
                          step > 0 &&
                          <Btn
                            type="link"
                            disabled={!!touched && !!campaign.id}
                            onClick={e => this.gotoPrev()}>
                            {step > 0 && '< Prev'}
                          </Btn>
                        }
                        <span className="cur-step-name">
                          <img src={s.icon}/> {s.name}
                          {/*{s.step === 0 && campaign.status && <StatusTag status={campaign.status}> ({campaign.status})</StatusTag>}*/}
                        </span>
                      </div>

                      {
                        !!campaign.id &&
                        <div className={classnames('step-actions', {visible: !!touched})}>
                          <Btn className="reset" type="link" onClick={e => this.reset()}>
                            <i className="fa fa-rotate-left"/> Reset
                          </Btn>
                          <Btn
                            disabled={!this.isValid([0])}
                            type="link" onClick={e => this.saveCampaign()}>
                            <i className="fa fa-save"/> Save
                          </Btn>
                        </div>
                      }

                      <div className="nav-actions">
                        {
                          !campaign.id && !!touched &&
                          <Btn className="reset" type="link" onClick={e => this.reset()}>
                            <i className="fa fa-rotate-left"/> Reset
                          </Btn>
                        }
                        {
                          step === 3 && !campaign.id &&
                          <Btn
                            type="link"
                            disabled={!this.isValid([0, 1, 2])}
                            onClick={e => this.saveCampaign()}>
                            <i className="fa fa-save"/> Save
                          </Btn>
                        }
                        {
                          step < 3 &&
                          <Btn
                            type="link"
                            disabled={(!!touched && !!campaign.id) || !this.isValid(s.step)}
                            onClick={e => this.gotoNext()}>
                            {'Next >'}
                          </Btn>
                        }

                      </div>
                    </div>
                    <div className={classnames('step-body', {readOnly: !!session.readOnly})}>
                      {!!error && <ErrorMessage message={error}/>}
                      {!!errorMap.common && <ErrorMessage message={errorMap.common}/>}
                      <div className={classnames('step-flash', {visible: !!flashMessage})}>{flashMessage}</div>
                      <Component
                        readOnly={campaign.status === 'COMPLETED' || !!session.readOnly}
                        campaign={campaign}
                        advertisers={advertisers}
                        orders={orders}
                        errorMap={errorMap}
                        onChange={partial => {
                          this.setState({
                            touched: true,
                            errorMap: {...errorMap, ...(_.mapValues(partial, (v, k) => null))},
                            campaign: {...campaign, ...partial}
                          }, () => {
                            this.autoValidation(partial);
                          });
                        }}/>
                    </div>
                  </StepFormWrapper>
                )
              })}
            </StepStack>
          }
        </SetupPageWrapper>
      </MainLayout>
    )
  }

  reset() {
    const {originalCampaign} = this.state;
    const original = _.cloneDeep(originalCampaign);
    this.setState({
      touched: false,
      errorMap: {},
      allTouches: [],
      campaign: _.cloneDeep(original)
    })
  }

  autoValidation(changes) {
    const {step, allTouches, campaign} = this.state;
    if (step === 0) {
      let changeKeys = Object.keys(changes)
      let errorMap = this.validator(step);
      let newAllTouches = _.uniq([...allTouches, ...changeKeys]);
      this.setState({allTouches: newAllTouches});
      let allFilled = ['name', 'advertiser', 'start_date', 'priority', 'goal_value'].every(key => newAllTouches.indexOf(key) >= 0);
      let allTimeFilled = errorMap.end_date && ['start_date', 'end_date'].every(key => newAllTouches.indexOf(key) >= 0);
      let goalAndCap = ['frequency_cap'].some(key => newAllTouches.indexOf(key) >= 0);
      if (campaign.id || allFilled || allTimeFilled || goalAndCap) {
        // if (Object.keys(errorMap).length > 0) {
        //   this.setState({errorMap: errorMap});
        // }
        this.setState({errorMap: errorMap});
      }
    }
  }

  renderPrompt() {
    //everTouched means any step is touched (only apply for the new setup flow)
    const {campaign, touched, everTouched} = this.state;
    return (
      <NavigationPrompt
        key={Date.now()}
        when={(curLocation, nextLocation) => {
          return (nextLocation.pathname).indexOf('campaigns/setup') < 0 &&
            (nextLocation.pathname).indexOf('campaigns/edit') < 0;
        }}
        disableNative={!touched && !!campaign.id}>
        {({onConfirm, onCancel}) => {
          if (!touched && !!campaign.id) {
            return onConfirm();
          }
          if (!touched && !campaign.id && !everTouched) {
            return onConfirm();
          }
          const confirmInfo = {
            type: 'form',
            backgroundClose: false,
            title: (
              <strong>
              </strong>
            ),
            width: '650px',
            cancelLabel: 'Discard Changes',
            okLabel: 'Continue Editing',
            onCancel: () => {
              onConfirm();
            },
            onConfirm: () => {
              onCancel();
            },
            dialogBody: (
              <PromptBody>
                <i className="fa fa-warning"/>
                {'You are leaving the page with some state not saved. '}
                {'You can continue the setup flow or leave the page and discard the changes.'}

              </PromptBody>
            ),
          }
          return <CondirmDialog {...confirmInfo} />;
        }}
      </NavigationPrompt>
    )
  }

  isValid(steps) {
    return _.flatten([steps]).every(s => {
      let stepErrorMap = this.validator(s);
      return !Object.keys(stepErrorMap).length;
    })
  }

  validator(step) {
    const {originalCampaign, campaign} = this.state;
    const errorMap = {};
    if (step === 0) {
      const {name, advertiser, start_date, end_date, type, priority, goal_type, goal_value, frequency_cap, frequency_unit, status} = campaign || {};
      // if (status === 'COMPLETED') {
      //   errorMap.common = `You can't change settings for completed campaign.`;
      // }
      if (status === 'DELIVERING' && start_date !== originalCampaign.start_date) {
        errorMap.start_date = `You can't change campaign start time after campaign started.`;
      }
      if (status === 'DELIVERING' && end_date && end_date < moment().format('YYYY-MM-DD HH:mm:ss')) {
        errorMap.end_date = `You can't set the end time to before today`;
      }
      if (!name) {
        errorMap.name = 'Campaign Name is required';
      }
      if (!/[a-z0-9_-]/ig.test(name)) {
        errorMap.name = 'Campaign Name should only contains letters, number, underscore and dash.';
      }
      if (!advertiser) {
        errorMap.advertiser = 'Advertiser name is required';
      }
      if (!type) {
        errorMap.priority = 'Campaign type is required';
      } else if (!priority) {
        errorMap.priority = 'Priority value is required';
      }
      if (!start_date) {
        errorMap.start_date = 'Start Time is required';
      }
      if (end_date && end_date < start_date) {
        errorMap.end_date = 'End Time should not be ahead of Start Time';
      }
      if (type !== 'HOUSE' && (!goal_value || !goal_type)) {
        errorMap.goal_value = 'Goal is required for non-house campaign';
      }
      if (type === 'HOUSE') {
        delete errorMap.goal_value;
      }
      if (goal_value) {
        if (goal_type !== 'FIXED' && (goal_value <= 0 || goal_value > 100)) {
          errorMap.goal_value = 'Campaign Goal should be between 0 -  100% ';
        }
        if (goal_type === 'FIXED' && (goal_value <= 0 || !_.isInteger(Number(goal_value)))) {
          errorMap.goal_value = 'Campaign Goal should be a positive integer';
        }
      }
      if (frequency_cap) {
        if (frequency_unit && frequency_cap <= 0 || !_.isInteger(Number(frequency_cap))) {
          errorMap.frequency_cap = 'frequency cap should be a positive integer';
        }
      }

    }
    else if (step === 1) {
      const {segment} = this.state.campaign || {};
      if (!segment) {
        errorMap.segment = 'Please select target audience for this campaign';
      }
    }
    else if (step === 2) {
      const {widgets} = this.state.campaign || {};
      if (!widgets || !widgets.length) { //widget is not required
        // errorMap.widgets = 'Need to select at least 1 widget';
      }
    }
    else if (step === 3) {
      const {creatives} = this.state.campaign || {};
      if (!creatives || !creatives.length) {
        errorMap.creatives = 'Need to select at least 1 creative';
      }
    }
    return errorMap;
  }

  gotoPrev() {
    const {step, touched, campaign} = this.state;
    if (campaign.id) {
      this.setState({step: step - 1});
    } else {
      this.setState({step: step - 1, touched: false, originalCampaign: _.cloneDeep(campaign)});
    }

  }

  gotoNext() {
    const {step, touched, campaign, everTouched} = this.state;
    if (campaign.id) {
      this.setState({step: step + 1});
    } else {
      const errorMap = this.validator(step);
      if (Object.keys(errorMap).length > 0) {
        this.setState({errorMap: errorMap});
        return;
      }
      this.setState({
        step: step + 1,
        everTouched: everTouched || touched,
        touched: false,
        originalCampaign: _.cloneDeep(campaign)
      });
    }

  }

  saveCampaign() {
    const {step, campaign: editingCampaign} = this.state;
    const errorMap = this.validator(step);
    if (Object.keys(errorMap).length > 0) {
      this.setState({errorMap: errorMap});
      return;
    }
    this.setState({loading: true, error: null});
    let createMode = !editingCampaign.id;
    post(`/api/${createMode ? 'createCampaign' : 'updateCampaign'}`, editingCampaign).then(result => {
      this.setState({loading: false});
      let {success, campaign} = result;
      campaign = campaign || editingCampaign;
      if (success) {
        this.setState({
          touched: false,
          allTouches: [],
          errorMap: {},
          originalCampaign: _.cloneDeep(campaign),
          campaign: campaign,
          flashMessage: `Save campaign settings successfully!`,
          skipPrompt: true
        }, () => {
          if (createMode) {
            this.showConfirm('success', 'Campaign created successfully!', () => {
              this.props.history.push(`/sponsored_ads`);
            });
          }
          setTimeout(() => {
            this.setState({flashMessage: null});
          }, 2000);
        })
      } else {
        this.setState({error: 'update targeting failed'})
      }
    }).catch(e => {
      this.setState({loading: false, error: e.message});
    });
  }

  showConfirm(status, message, callback) {
    const confirmInfo = {
      title: (
        <PublishDialogWrapper>
          {status === 'success' && <Check className="res-success-icon"/>}
          {status === 'failure' && <Close className="res-failure-icon"/>}
        </PublishDialogWrapper>
      ),
      dialogBody: (
        <div style={{
          display: 'block',
          textAlign: 'center',
          margin: '20px 20px 10px 20px',
          lineHeight: '30px',
          fontWeight: '600'
        }}>{message}</div>
      ),
      hideCancel: true,
      onConfirm: () => {
        CondirmDialog.closeAll();
        callback && callback();
      },
      onCancel: () => {
        CondirmDialog.closeAll();
      }
    };
    ReactDOM.render(
      <CondirmDialog fullScreen={true}
                     width={'500px'}
                     {...confirmInfo} />,
      document.getElementById('global-confirm')
    );
  }
}))