import styled, {css} from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

import {DarkGray, LightGray, MediumGray} from '../app/StyleCommon';

export const DeploymentWrapper = styled.div`
  @media screen and (max-width: 667px) {
    margin-left: -20px;
  }
  > div {
    margin-top: 20px;
    .row {
      padding-left: 20px;
      &:first-child {
        margin-right: 20px !important;
      }
      > div {
        width: calc(50% - 10px) !important;
      }
      .col {
        margin-right: 0 !important;
      }
    }
  }
  label {
    margin-bottom: 10px;
  }
  input[type=checkbox] {
    cursor: pointer;
  }
  input[type=text] {
    &:disabled {
      opacity: 0.6;
    }
  }
  h2 {
    font-size: 14px;
    font-weight: 700;
    color: ${DarkGray};
  }
  .integration-step {
    margin-left: 0px !important;
    font-weight: normal;
  }
  .time-selector {
    display: flex;
    @media screen and (max-width: 667px) {
      display: block;
      .react-datepicker-popper {
        margin-left: -30px;
      }
    }
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .time-check {
    display: flex;
    cursor: pointer;
    flex: none;
    margin-bottom: 0;
    margin-right: 10px;
    align-items: center;
    input {
      width: auto;
      cursor: pointer;
      padding: 0;
      margin: 3px 5px 0 0;
      cursor: pointer;
      &:hover {
        box-shadow: 1px 1px 5px rgba(0,0,0,.1);
      }
    }
    span {
      padding-left: 5px;
    }
  }
  .time-start, .time-end {
    font-weight: 600;
  }
`;

export const CronBuilder = styled.div`
  .cron-input {
    display: flex;
    margin: 10px 0 20px 20px;
    align-items: center;
    label {
      margin-right: 10px;
      margin-bottom: 0;
    }
    input {
      border: none;
      border-bottom: 1px solid ${MediumGray};
      padding: 5px 10px;
      flex: 1;
    }
  }
  .c-host {
    margin: 10px 0 10px 20px;
    background: ${LightGray};
    padding: 10px;
    .c-and-list {
      display: block;
    }
    .c-every {
      margin: 5px 0;
    }
    .c-and-item {
      width: auto !important;
      display: inline-block;
    }
    .col-1 {
      white-space: nowrap;
    }
  }
`
