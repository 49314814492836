import React from 'react';
import _ from 'lodash';

import {withConsumer} from '../app/ApplicationContext';
import {Table, TableScrollWrapper, Btn} from '../uikit/index';
import {TargetingWrapper, NoData} from './step-targeting.style';
import {abTestTargetOptions} from '../utils/metadata';
import {SelectStyled} from './select.style';

export default withConsumer(class extends React.Component {

  constructor(props) {
    super(props);

    const {abtesting} = this.props;
    const {targeting} = abtesting || {};

    if (!targeting) {
      abtesting['targeting'] = [];
    }

    this.state = {abtesting};
  }

  render() {
    const {abtesting} = this.state;
    const {targeting = []} = abtesting || {};

    return (
      <TargetingWrapper className="step-targeting">
        {
          (!targeting || !targeting.length) &&
          <NoData>
            <span className="blockIfMobile">No targets found.</span>
            <span className="blockIfMobile">Please <a onClick={() => this.addTargetRow(abtesting)}>add</a> targets from here.</span>
          </NoData>
        }
        {!!targeting && targeting.length > 0 &&
        <div>
          <div className="targets-list-header">
            <p></p>
            <Btn
              type="link"
              className="add-target"
              onClick={() => this.addTargetRow(abtesting)}>
              <i className="fa fa-plus"/> Add
            </Btn>
          </div>
          <TableScrollWrapper className="targets-list-body">
            <Table
              columns={[
                {
                  key: 'key',
                  label: 'Name',
                  align: 'left',
                  style: {width: 250},
                  renderer: (d, i) => (
                    <SelectStyled
                      useFixed={true}
                      defaultLabel={'Select...'}
                      selected={d.key}
                      data={abTestTargetOptions}
                      onChange={e => this.onTargetChange({...d, key: e, value: e !== 'ip' ? [] : ''}, i, abtesting)}/>
                  )
                },
                {
                  key: 'op',
                  label: 'Operator',
                  align: 'center',
                  style: {width: 150},
                  renderer: (d, i) => (
                    <SelectStyled
                      useFixed={true}
                      defaultLabel={'Select...'}
                      selected={d.op}
                      data={d.key ? abTestTargetOptions.find(item => item.key === d.key).operators : []}
                      onChange={e => this.onTargetChange({...d, op: e}, i, abtesting)}/>
                  )
                },
                {
                  key: 'valueEditor',
                  label: 'Value',
                  align: 'center',
                  renderer: (d, i) => this.renderTargetValue(d, i, abtesting)
                },
                {
                  key: 'actions',
                  label: 'Actions',
                  align: 'center',
                  style: {width: '80px'},
                  renderer: (d, i) => (
                    <div className="action-items">
                      <Btn
                        type="link"
                        className="delete-btn"
                        onClick={e => this.removeTarget(abtesting, i)}><i
                        className="fa fa-trash"/>
                      </Btn>
                    </div>
                  )
                },
              ]}
              rows={targeting || []}
              noDataText={<span>No targets added</span>}
            />
          </TableScrollWrapper>
        </div>
        }
      </TargetingWrapper>
    )
  }

  saveAbTesting(abtesting) {
    this.setState({abtesting: abtesting});
    this.props.onChange(abtesting);
  }

  onTargetChange(rowItem, index, abtesting) {
    const {targeting} = abtesting || {};
    targeting[index] = rowItem;
    this.saveAbTesting(abtesting);
  }

  renderTargetValue(rowItem, index, abtesting) {
    const {key, value} = rowItem;

    if (key === 'ip') {
      return (
        <input
          className="ip-input"
          placeholder="Enter IP Address"
          type="text"
          value={value}
          onChange={e => this.onTargetChange({...rowItem, value: e.target.value}, index, abtesting)}/>
      )
    }
    let optionData = key ? abTestTargetOptions.find(item => item.key === key).data : [];
    return (
      <SelectStyled
        useFixed={true}
        defaultLabel={'Select...'}
        multi={true}
        selected={value}
        searchable={optionData.length > 5}
        data={optionData}
        onChange={e => this.onTargetChange({...rowItem, value: e}, index, abtesting)}/>
    )
  }

  addTargetRow(abtesting) {
    const {targeting} = abtesting || {};

    const target = {
      key: '',
      op: '',
      value: []
    }

    targeting.push(target);
    this.saveAbTesting(abtesting);
  }

  removeTarget(abtesting, i) {
    const {targeting} = abtesting || {};
    this.saveAbTesting({...abtesting, targeting: targeting.filter((x, index) => index !== i)});
  }
})
