import {facetMeta} from '../utils/metadata';

const dimensionOptions = facetMeta.filter(d => !d.disabledInCustomRule);

const numberOpOptions = [
  {key: 'eq', label: 'equal'},
  {key: 'ne', label: 'not equal'},
  {key: 'gt', label: 'more than'},
  {key: 'lt', label: 'less than'},
  {key: 'ge', label: 'at least'},
  {key: 'le', label: 'at most'},
]

export default [
  {
    type: 'Item Limiter',
    key: 'iprice_category_limiter',
    description: 'Recommended coupons only from single categoy',
    singleton: true,
    parameters: {
    }
  },
]