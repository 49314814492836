import styled, {css} from 'styled-components';
import SimpleSelect from '../selectbox/index';
import {
  Mint,
  Mint1,
  BitterSweet,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass1, LightGray, LightGray1, MediumGray,
  MediumGray1
} from "../../app/StyleCommon";
import {Select as SelectBox} from '../../uikit/index';

export const StyledSelectBox = styled(SelectBox)`
    margin-right: 10px;
    width: 250px;
   .toggler {
      background-color: white;
      border: 1px solid #eee !important;
      border-radius: 5px;
      padding: 7px 50px 7px 10px !important;
      ${props => props.togglerStyle}
   }
   .menu {
      top: 40px;
      right: 0;
      left: 0;
      max-height: 200px;
      overflow: auto;
      ${props => props.overflow && css`
        overflow: ${props.overflow};
      `}
      label {
        white-space: normal;
        line-height: 1.3em;
        //max-width: 250px;
        //overflow: hidden;
        //text-overflow: ellipsis;
      }
   }
`;

export const FilterBoxWrapper = styled.div`
  position: relative;
  ${props => props.formStyle && css`
    .actions {
      position: absolute;
      right: 0;
      top: -55px !important;
      margin-top: 20px !important;
      .dynamic-position {
        display: flex;
        align-items: center;
        .global-scope {
          margin-left: 10px;
        }
      }
      .btn {
        border: none;
      }
    }
  `}
  ${props => props.hasError && css`
    border: 1px solid ${BitterSweet};
  `}
  ${props => !props.supportScope && css`
      .global-scope {display: none;}
  `}
  ${props => props.readOnly && css`
      border: 1px solid ${MediumGray};
      //background-color: #fafbfc;
      border-radius: 5px;
      //pointer-events: none;
      .btn,
      .add-block-btn,
      .fa-plus,
      .Select-clear,
      .fa-trash,
      .fa-caret-down,
      .Select-arrow,
      .Select-value-icon,
      .Select-clear-zone,
      //.Select-input,
      .fa-cog,
      .block-controls,
      .Select-arrow-zone {
        display: none !important;
      }
      .Select-input input{
        border: none !important;
        background: #fafbfc;
      }
      .block-wrapper {
        padding-top: 5px;
      }
      .toggler,
      .Select-control.Select-control.Select-control {
        pointer-events: none;
        border: none !important;
        background-color: #fafbfc;
      }
      .Select-multi-value-wrapper {
        white-space: nowrap;
      }
      .Select-multi-value-wrapper .Select-value.Select-value {
        border: none !important;
      }
      input {
        pointer-events: none;
        background-color: #fafbfc;
        text-overflow: ellipsis;
      }
      .select-box {
        width: auto !important;
        min-width: 200px !important;
      }
      .select-box .toggler {
        border: none !important;
        background: #fafbfc;
        white-space: normal !important;
        width: auto;
        min-width: auto;
        font-size: 12px;
      }

      .block-wrapper {
        border: none !important;
        //margin: 10px 0 10px 10px;
        margin: 0;
        overflow: hidden;
        border-bottom: 1px dashed #f1f2f3;
        background-color: white;
      }
      .global-period-settings {
        border: none;
        border-bottom: 1px solid #f1f2f3;
        border-radius: 0;
        margin: 0;
      }
      .block-inner > .right {
        width: 5px;
        overflow: hidden;
        padding: 0;
        margin: 0;
      }
      .logical-op-select .Select-value {
        line-height: 20px;
      }
      .readonly-text-box {
        background: #fafbfc;
        border-radius: 5px;
        padding: 8px 10px;
        font-size: 12px;
      }
  `}
`
export const NodeConnector = styled.div`
  margin: 10px auto 4px auto;
  display: block;
  ${props => props.theme === 'small' && css`
    margin: -4px auto -10px auto;
  `}
  .Select-control.Select-control.Select-control {
    margin-right: 20px;
    height: auto;
    width: 68px;
    background: transparent;
    border: none !important;
    .Select-input {
      background: transparent;
    }
    .Select-value-label {
      font-size: 12px;
      font-weight: bold;
    }
  }
`

export const GlobalControls = styled.div`
  .select-box {
    margin-right: 0;
    width: auto !important;
    .toggler{
      width: auto;
      padding: 0 !important;
      background: transparent;
      border: none !important;
      i.fa {
        margin-right: 0;
      }
      .caret {
        display: none;
      }
    }
    .menu {
      top: 20px;
      right: 0;
      left: auto;
    }

    .menu {
      right: 0;
      left: auto;
    }
  }
`

export const BlockScopeMenuHeader = styled.div`
  padding: 5px 20px;
  text-align: left;
  margin-bottom: 10px;
  background: #f1f2f3;
  margin-top: -5px;
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid ${LightGray1};
`
export const BlockWrapper = styled.div`
  margin: 10px 0;
  padding: 30px 10px 5px 15px;
  border-radius: 6px;
  background-color: #fafafa;
  border: solid 1px #e1e2e3;
  position: relative;
  ${props => !!props.empty && css`
     border: solid 1px ${LightGray1};
  `}

  ${props => !!props.not && css`
     border: 1px solid #ffcaba;
  `}
  &:hover .not-controls {
    opacity: 0.7 !important;
  }
`

export const BlockControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 25px;
  padding: 0 20px;
  border-radius: 5px 5px 0 0;
  background: ${LightGray1};
  ${props => !!props.not && css`
     background-color: #ec6036;
     color: white;
     .hint-anker i {color: white !important;}
     .select-box .menu{
      color: ${DarkGray1};
     }
     .Select-value-label{
        color: white !important;
     }
     .Select-arrow{
        border-top-color: white !important;
     }
     .btn{
        color: white;
     }
  `}
  .select-box {
    .toggler{
      font-weight: bold;
      padding: 0 !important;
      background: transparent;
      border: none !important;
    }
    .menu {
      top: 20px;
    }
  }
`

export const BlockLeftControls = styled.div`
  display: flex;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  .win-btn {
    cursor: pointer;
    margin-right: 10px;
    background: ${LightGray1};
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover i{
      color: ${Mint1};
    }
    i {
      font-size: 12px;
      color: ${DarkGray};
    }
  }
`

export const ExtraControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .btn {
    margin-right: 15px;
    margin-top: 2px;
    padding: 0;
    border: none;
    background: transparent;
    min-width: auto;
    font-size: 14px;
    &:hover {
      border: none !important;
    }
  }
  .select-box {
    margin-right: 0;
    width: auto !important;
    .toggler{
      width: auto;
      font-size: 14px;
    }
    i.fa {
      margin-right: 0;
    }
    .caret {
      display: none;
    }
    .menu {
      right: 0;
      left: auto;
    }
  }
`
export const NodeWrapper = styled.div`
  &.highlight {
    background: #f1f2f3;
    box-shadow: none;
    border: 1px solid #e1e2e3;
    border-radius: 5px;
    //box-shadow: 0 1px 5px ${MediumGray};
    margin-bottom: 10px;
    margin: 10px 0;
    padding: 10px 10px;
    .extra-btn {
      color: ${Mint1};
    }
  }
  .block-inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: nowrap;
    padding: 5px;
  }

  .left {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex-wrap: nowrap;
    .select-box {
      width: 260px;
      //min-width: 260px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    white-space: nowrap;
    justify-content: space-between;
    width: 60px;
    margin-top: 3px;
    flex: none;
    .logical-op-select {
      margin-right: 0;
    }
    .Select {
      margin-right: 0 !important;
      margin-left: 10px;
      .Select-control {
        width: 60px;
        height: 30px;
      }
      .Select-value {
        padding-left: 8px;
        padding-right: 20px !important;
      }
      .Select-arrow-zone {
        width: 20px;
        padding: 0;
      }
      .Select-input {
        height: 30px;
      }
      .Select-value-label {
        line-height: 30px;
      }

    }
    .btn {
      background: none;
      border: none;
      width: 30px;
      min-width: auto;
      margin-left: 10px;
      cursor: pointer;
      &.extra-btn {
        width: 20px;
        margin-left: 10px;
        text-align: center;
        &:hover {
          color: ${Mint1};
        }
      }
    }
  }
  .dimension-select {
    width: 200px;
    flex: none;
    .Select-clear-zone {
      display: none !important;
    }
  }
  .node-op-select {
    width: 90px;
    flex: none;
    .Select-clear-zone {
      display: none !important;
    }
  }
  .value-select {
    width: 100%;
    .Select-multi-value-wrapper .Select-value {
      position: relative;
      &:not(:first-child) {
        margin-left: 30px;
        &:before {
          position: absolute;
          left: -15px;
          content: 'or';
          font-weight: 15px;
          transform: translate(-50%, -50%);
          top: 50%;
          color: ${MediumGray1};
        }
      }
    }
  }
  input.text-box {
    width: 100%;
    margin-right: 10px;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #eee;
    vertical-align: middle;
    outline: none;
    height: 34px;
    box-sizing: border-box;
    &:focus {
      border-color: #ddd;
    }
  }
  @media screen and (max-width: 992px) {
    width: 100%;
    padding: 10px !important;
    box-sizing: border-box;
    .fbox__body {
      padding: 0;
    }
    .block-inner {
      padding: 0;
      flex-wrap: wrap;
      .left {
        flex-wrap: wrap;
        ${StyledSelectBox} {
          width: 100% !important;
          margin-right: 0;
          .toggler {
            width: 100% !important;
          }
        }
        .dimension-select,
        .node-op-select,
        .value-select,
        .text-box {
          width: 100%;
          margin: 5px 0;
        }
      }
      .right {
        margin: 10px auto;
        display: flex;
        border: 1px solid #f1f2f3;
        justify-content: flex-end !important;
        width: 100%;
        border-radius: 5px;
        .Select.logical-op-select, .btn {
          margin-left: 0;
          margin-right: 10px;
        }
      }
    }
    .actions {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .btn {
        flex-grow: 1;
        margin: 5px 10px;
      }
    }
  }
`

export const Actions = styled.div`
  margin-top: 20px;
  text-align: right;
  ${Btn}, .btn {
    margin-left: 10px;
  }
  ${props => props.leftAlign && css`
    text-align: left;
    margin-left: 0;
    >.btn {
      margin-left: 0;
      margin-right: 15px;
    }
  `}
`;
export const Btn = styled.div`
  color: #555;
  font-size: 14px;
  border-radius: 6px;
  border: 1px solid #ddd;
  vertical-align: middle;
  min-width: 80px;
  height: 30px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${MediumGray};
  background-color: white;
  i {
    margin-right: 5px;
  }
  &:hover {
    color: skyblue;
    border: 1px solid skyblue;
  }
  &.primary {
    color: white;
    background-color: #4a89dc;
    font-weight: bold;
    &:hover {
      color: skyblue;
      border: 1px solid transparent;
      background-color: #3d7baf;
    }
  }
`;

export const PeriodWrapper = styled.div`
  margin: 10px 0 20px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #fafafa;
  border: 1px solid #f1f2f3;
  border-radius: 5px;
  padding-left: 15px;
  > label {
    font-size: 12px;
    color: ${DarkGray};
  }
`;

export const NodeSettingWrapper = styled.div`
  display: block;
  padding: 10px 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  ${props => props.disabled && css`
    opacity: .5;
    pointer-events: none;
  `}
  .setting-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    height: 30px;
    border-radius: 5px;
    margin: 10px 0;
    width: 100%;
    box-sizing: border-box;
    white-space: nowrap;
    border-radius: 5px;
    @media screen and (max-width: 992px) {
      width: 100%;
      flex-wrap: wrap;
      height: auto;
      >label {
        width: 100% !important;
        margin-bottom: 10px;
        margin: 10px 0 !important;
      }
      .setting-item-value {
        background-color: transparent !important;
        white-space: normal;
        .select-box {
          width: 100%;
          margin: 10px 0;
          background: white;
        }
        input {
          margin: 10px 0;
          margin-left: 0 !important;
          width: 100% !important;
          box-sizing: border-box;
        }
      }
    }
    >label {
      margin-right: 10px;
      padding: 0 10px;
      width: 240px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: ${LightGray1};
      border-radius: 5px;
      box-sizing: border-box;
    }
    .setting-item-value {
      background-color: white;
      border-radius: 5px;
      flex-grow: 1;
      .select-box {
        min-width: 150px;
      }
      > input {
        padding: 5px 5px;
        width: 100px;
        outline: none;
        border: 1px solid ${MediumGray}
        border-radius: 5px;
        margin-left: 10px;
        font-weight: bold;
        &:focus {
          border: 1px solid ${Mint1}
        }
      }
    }
  }
`;

export const StyledPeriodSelect = styled(SimpleSelect)`
  margin-left: 10px;
  .toggler {
    padding: 8px 40px 8px 10px !important;
    font-size: 12px;
    color: ${DarkGray1} !important;
    padding-right: 30px;
    font-weight: bold;
    background-color: transparent !important;
  }
  .menu {
    right: 0;
    top: 30px;
    left: auto;
    text-align: left;
    max-height: 300px;
    overflow-y: auto;
  }
`;
export const SelectWrapper = styled.div`
  display: inline-block;
  margin-right: 5px;
  &.logical-op-select {
    .Select-control {
      width: 80px;
    }
    .Select-clear-zone {
      display: none !important;
    }
  }
  .Select-control {
    border: 1px solid #eee !important;
    &:hover {
      box-shadow: none !important;
    }
  }
  .Select-value-label {
    max-width: 320px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
  }
  .Select-menu-outer {
    border: 1px solid #eee !important;
  }

  .Select--multi .Select-multi-value-wrapper .Select-value {
    white-space: nowrap;
    color: black !important;
    border: 1px solid #ddd !important;
    background: #f5f5f5 !important;
    .Select-value-icon {
      border-right-color: #ddd !important;
    }
  }
  .Select-placeholder .Select-value {
    font-size: 12px !important;
  }
  .Select .Select-value {
    padding-right: 30px !important;
  }
  .Select-menu-outer {
    max-height: 450px;
    z-index: 100;
    .Select-menu {
      max-height: 348px;
      font-size: 12px;
    }
  }

  .Select-input {
    > input {
      max-width: 650px;
      white-space: normal;
      line-height: inherit;
    }
  }
  .Select-menu-outer {
    max-height: 450px;
    z-index: 100;
    .Select-menu {
      max-height: 300px;
      font-size: 12px;
    }
    .Select-option {
      font-size: 12px !important;
    }
    img {
      height: 16px;
      width: auto;
      display: inline-block;
      margin-right: 10px;
      vertical-align: middle;
    }
  }
`

export const ContentOptionWrapper = styled.div`
  cursor: pointer;
  white-space: normal;
  position: relative;
  padding: 3px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .unique{
    color: ${DarkGray};
    display: inline-block;
    min-width: 40px;
  }
  img {
    max-width: 18px !important;
  }
`

export const Showmore = styled.div`
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  a {
    color: ${Mint}
  }
`


