import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import {withConsumer} from '../app/ApplicationContext';
import {withRouter} from 'react-router';
import moment from 'moment';
import * as d3 from 'd3';
import {Redirect, Link} from 'react-router-dom';
import {getFromCache, cacheResource} from '../utils/cache'
import {ProgressBar, Btn, Table, CondirmDialog, TabContainer, Loader, TableScrollWrapper} from '../uikit/index';
import {layouts, algorithms} from '../utils/metadata';
import {algorithms as adAlgorithms} from '../utils/metadata.ads';
import {widgetSortOptions} from './index.metadata';
import MainLayout from '../layout/index';
import {SelectStyled} from './select.style';
import PreviewBox from './previewBox';
import {WidgetHomeWrapper, FilterBarFirst, FilterBarSecond, WidgetListView, WidgetTableView, WidgetItem} from './index.style';
import {MetricTableWrapper, ListFooter, NoData, SortByItemRenderer, Taglist} from './index.style';
import {FilterDropdown, FilterTagWrapper} from './index.style';
import {PaginationStyled} from '../dashboard/index.style';
import {StyledDropdown} from '../uikit/dropdown/styled';
import Brief from './brief';
import AreaChart from './AreaChart';
import {post} from "../utils/request";
import {
  intFormatter,
  pctFormatter,
  uniquesFormatter,
  filterTags,
  pct2Formatter,
  responseTimeFormatter
} from "../utils/formatter";
import {BlueJeans, MediumGray1} from "../app/StyleCommon";
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import copy from 'copy-to-clipboard';
import {BarWrapper} from "../home/index.style";
import Sparkline2 from "../dashboard/sparkline3";
import RealtimeChart from "./realtimeChart";

const FILTER_CACHE_KEY = 'widget_home_filters';

export default withRouter(withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    const cachedSettings = getFromCache(FILTER_CACHE_KEY) || {};
    this.state = {
      mode: 'grid',
      status: 'Published',
      page: 1,
      PAGE_SIZE: 5,
      sortBy: 'last_hour_impressions',
      sortDirection: 'desc',
      chartIndex: {},
      ...(cachedSettings || {}),
    };
    this.search = _.debounce(() => this.setState({data: null, page: 1}), 800);
  }

  componentDidMount() {
    // this.realtimeTimer = setInterval(() => {
    //   post('/api/queryWidgets', this.buildQuery()).then(newData => (this.setState({data: newData}))).catch(e => null);
    // }, 30000);
  }

  componentWillUnmount() {
    this.realtimeTimer && clearInterval(this.realtimeTimer);
  }

  buildQuery() {
    const {data} = this.state;
    const {keyword, sortBy, sortDirection, page, PAGE_SIZE, status, site} = this.state;
    return {
      limit: PAGE_SIZE, offset: (page - 1) * PAGE_SIZE, keyword, site, sortBy, sortDirection, status,
    };
  }

  cacheParams() {
    cacheResource(FILTER_CACHE_KEY, _.pick(this.state, ['site', 'page', 'PAGE_SIZE', 'status', 'mode', 'page', 'sortBy', 'sortDirection', 'keyword']))
  }

  render() {
    const {data, error, loading} = this.state;
    const {keyword, sortBy, sortDirection, page, PAGE_SIZE, chartIndex} = this.state;
    const {status, site, mode} = this.state;
    const {widgets, total, newInstanceId} = data || {};
    const {appState: {session}} = this.props;
    const {isAdmin, isOperator} = session || {};
    const maxHourly= _.max(_.flattenDeep((widgets || []).map(x => ((x.report || {}).last_1_hour || []).map(y => y.impressions || 0))))
    let quickTags = ['Published', 'Draft'];
    if (['mewatch', 'preprod_mewatch'].includes(site) || (session.sites.length === 1 && ['mewatch', 'preprod_mewatch'].includes(session.sites[0] || {}).key)) {
      quickTags = ['Published', 'TA_BOOST', 'Draft'];
    }
    return (
      <MainLayout activeItem="widget" breadcrumb={[{path: '/widget', label: 'Widget'}]}>
        <WidgetHomeWrapper>
          {
            !data && !error &&
            <ProgressBar
              withoutCache={true}
              fixcenter={true}
              url={`/api/queryWidgets`}
              params={this.buildQuery()}
              successHandler={data => this.setState({data: data})}
              errorHandler={e => this.setState({error: e.message})}/>
          }

          <FilterBarFirst>
            <div className="left">
              <h3>Widgets</h3>
              <ul className="status-list">
                {
                  // ['All', 'Running', 'Ready', 'Disabled', 'Stopped', 'Draft']
                  quickTags.map(s => (
                    <li
                      key={s}
                      className={`${s} ${s === status ? 'selected' : ''}`}
                      onClick={e => this.setState({status: s, data: null}, () => this.cacheParams())}>
                      {s}
                    </li>
                  ))
                }
              </ul>
            </div>
            <div  className="right">
              {/*<div className="layout-mode">*/}
              {/*  <a className={mode === 'grid' ? 'selected' : ''} onClick={e => this.setState({mode: 'grid'})}>*/}
              {/*    <i className="fa fa-th"/>*/}
              {/*  </a>*/}
              {/*  <a className={mode === 'list' ? 'selected' : ''} onClick={e => this.setState({mode: 'list'})}>*/}
              {/*    <i className="fa fa-list"/>*/}
              {/*  </a>*/}
              {/*</div>*/}

              <Link className="create-btn" to={`/widget/create/${newInstanceId}`} onClick={e => {
                if(!isAdmin  && !isOperator) {
                  e.preventDefault();
                }
              }}>
                <Btn disabled={!isAdmin && !isOperator} type="primary"><i className="fa fa-plus"/> Create Widget</Btn>
              </Link>
            </div>
          </FilterBarFirst>
          <FilterBarSecond>
            <div className="searchBox">
              <i className="fa fa-search"/>
              <input
                type="text"
                placeholder="Enter widget ID, name , tag, creator name to search ..."
                value={keyword}
                onChange={e => this.setState({keyword: e.target.value}, () => {
                  this.search();
                  this.cacheParams();
                })}/>
            </div>
            {/*<FilterDropdown toggler={(*/}
            {/*  <div><i className="caret fa fa-filter"/> Filters</div>*/}
            {/*)}>*/}
            {/*  <h3>Filters: </h3>*/}
            {/*  <div className="menu-item">*/}
            {/*    <label>Project</label>*/}
            {/*    <SelectStyled*/}
            {/*      selected={null}*/}
            {/*      plainStyle={true}*/}
            {/*      defaultLabel="All"*/}
            {/*      data={[*/}
            {/*        {key: 'All', label: 'All'},*/}
            {/*      ].concat([].map(a => ({key: a, label: a})))}*/}
            {/*      onChange={advertiser => {*/}
            {/*        this.setState({page: 1, advertiser: advertiser, data: null})*/}
            {/*      }}/>*/}
            {/*  </div>*/}
            {/*  <div className="menu-item">*/}
            {/*    <label>Platform</label>*/}
            {/*    <SelectStyled*/}
            {/*      selected={null}*/}
            {/*      plainStyle={true}*/}
            {/*      defaultLabel="All"*/}
            {/*      data={[*/}
            {/*        {key: 'All', label: 'All'},*/}
            {/*      ].concat([].map(a => ({key: a, label: a})))}*/}
            {/*      onChange={io => {*/}
            {/*        this.setState({page: 1, io: io, data: null})*/}
            {/*      }}/>*/}
            {/*  </div>*/}
            {/*  <div className="menu-item">*/}
            {/*    <label>Page</label>*/}
            {/*    <SelectStyled*/}
            {/*      selected={null}*/}
            {/*      plainStyle={true}*/}
            {/*      defaultLabel="All"*/}
            {/*      data={[*/}
            {/*        {key: 'All', label: 'All'},*/}
            {/*      ].concat([].map(a => ({key: a, label: a})))}*/}
            {/*      onChange={io => {*/}
            {/*        this.setState({page: 1, io: io, data: null})*/}
            {/*      }}/>*/}
            {/*  </div>*/}
            {/*  <div className="actions">*/}
            {/*    <Btn type="reset" onClick={e => this.setState({page: 1, period: undefined, advertiser: null, type: null, status: null, data: null})}>Clear Filters</Btn>*/}
            {/*  </div>*/}
            {/*</FilterDropdown>*/}
            <SelectStyled
              selected={site || 'All'}
              defaultLabel={'All Sites'}
              labelPrefix={<i className="fa fa-sitemap" style={{color: BlueJeans, marginRight: '10px'}}/>}
              data={[
                {key: 'All', label: 'All Sites'},
              ].concat(session.sites)}
              showGroupBatch={false}
              onChange={site => {
                this.setState({site: site, page: 1, data: null}, () => this.cacheParams())
              }}/>
            <SelectStyled
              selected={sortBy}
              defaultLabel={`Sort By`}
              menuHeader={<div className="menu-header">Sort By:</div>}
              data={widgetSortOptions.map(d => {
                return {
                  ...d,
                  label: d.label,
                  icon: `/img/icons/sort-${sortDirection || d.direction}.png`
                }
              })}
              itemRenderer={d => {
                return (
                  <SortByItemRenderer onClick={e => {
                    if(d.key === sortBy) {
                      this.setState({sortDirection: (sortDirection || d.direction) === 'desc' ? 'asc' : 'desc', data: null});
                    }
                  }}>
                    <span>{d.label}</span>
                    <img src={`/img/icons/sort-${sortDirection || d.direction}.png`} className={d.key === sortBy ? '' : 'hidden'}/>
                  </SortByItemRenderer>
                )
              }}
              showGroupBatch={false}
              onChange={sortBy => {
                let direction = (widgetSortOptions.find(d => d.key === sortBy) || {}).direction || 'desc';
                this.setState({sortBy: sortBy, sortDirection: direction, page: 1, data: null}, () => this.cacheParams())
              }}/>
          </FilterBarSecond>
          {
            !!error && <ErrorMessage message={error}></ErrorMessage>
          }
          {!!data &&
            <WidgetTableView>{this.renderWidgetTable(widgets)}</WidgetTableView>
          }
          {
            total > 1 &&
            <ListFooter>
                <span className="info">
                  <strong>{d3.format(',')(total)}</strong> widgets found.
                  Show
                  <select
                    value={PAGE_SIZE}
                    onChange={e => this.setState({
                      data: null,
                      PAGE_SIZE: Number(e.target.value),
                      selected: [],
                      page: 1,
                      chartIndex: {},
                    }, () => this.cacheParams())}>
                  {[5, 10, 20, 50].map(d => {
                    return (
                      <option key={d} value={d}>{d}</option>
                    )
                  })}
                  </select>
                  per page
              </span>
              <PaginationStyled
                onChange={p => this.setState({page: p, chartIndex: {}, data: null}, () => this.cacheParams())}
                current={page}
                pageSize={PAGE_SIZE}
                total={total}
                hideOnSinglePage={false}/>
            </ListFooter>
          }
        </WidgetHomeWrapper>
      </MainLayout>
    )
  }

  renderWidgetTable(widgets) {
    const {keyword, sortBy, sortDirection, page, PAGE_SIZE, chartIndex} = this.state;
    const {status, site, mode} = this.state;
    const {appState: {session}} = this.props;
    const {isAdmin, isOperator} = session || {};
    const maxHourly= _.max(_.flattenDeep((widgets || []).map(x => ((x.report || {}).last_1_hour || []).map(y => y.impressions || 0))))
    return (
      <TableScrollWrapper className="middle">
        <Table
          columns={[
            {
              key: 'name',
              label: 'Widget Name',
              sortable: true,
              style: {maxWidth: '350px'},
              renderer: d => {
                return (
                  <div className="widget-info">
                    <div className="top" >
                      <img className="publisher" src={`/img/property/${d.site}.png`} style={{width: '16px', verticalAlign: 'middle', marginRight: '5px'}}></img>
                      <Link className="title" to={`/widget/${d.id}`}>
                        <span style={{marginRight: '6px'}}>{d.name}</span>
                      </Link>
                      <em className="status">{d.status === 'Published' ? '' : `(${d.status})`}</em>
                    </div>
                    <div>
                      <Taglist className="tag-list"  style={{marginLeft: '15px'}}>
                        {filterTags(d.tags, 'abtesting', isAdmin).map(t => {
                          return <span className={`tag ${t.split('::').join(' ')} `} key={t}>{t.split('::').pop()}</span>
                        })}
                        {!filterTags(d.tags, 'abtesting', isAdmin).length && ''}
                        {isAdmin && this.renderWidgetAlgorithm(d)}
                        {d.thinkWidget && <span className="tag">TA</span>}
                      </Taglist>
                    </div>
                  </div>
                )
              }
            },
            {
              key: 'layout',
              label: 'Layout',
              renderer: d => {
                const {layout, layoutParams} = d.layoutConfig || {};
                const {row = 1, column = 1} = layoutParams || {};
                const layoutOption = layouts.find(l => l.key === layout) || {};
                return (
                  <div className="widget-layout" onClick={e => this.showPreview(d)}>
                    <div className="layout-icon"
                         style={{backgroundImage: `url(${d.thumbnail || layoutOption.icon || '/img/icons/widget.png'})`}}>
                    </div>
                    <span
                      className="layout-desc">
                          {layoutOption.label || ' -- '}
                      &nbsp;
                      {layoutOption.key ? `(${row}x${column})` : ''}
                    </span>
                  </div>
                )
              }
            },
            // {
            //   key: 'slotCount',
            //   label: 'Slot Count',
            //   align: 'center',
            // },
            {
              key: 'CreatedDateTime',
              label: 'Created Time',
              sortable: true,
              renderer: d => {
                return (
                  <div>
                    <div>{moment(d.CreatedDateTime).format('DD MMM, YYYY HH:mm A')}</div>
                    <div style={{fontSize: '11px', color: MediumGray1}}>{d.creator}</div>
                  </div>
                )
              }
            },
            {
              key: 'UpdatedDateTime',
              sortable: true,
              label: 'Last Updated Time',
              renderer: d => {
                return (
                  <div>
                    <div>{moment(d.UpdatedDateTime).format('DD MMM, YYYY HH:mm A')}</div>
                    <div style={{fontSize: '11px', color: MediumGray1}}>by {d.lastModifyBy}</div>
                  </div>
                )
              }
            },
            {
              key: 'last_hour_impressions',
              label: 'Recent Traffic',
              align: 'left',
              style: {width: '180px'},
              sortable: true,
              renderer: d => {
                const {layout, layoutParams} = d.layoutConfig || {};
                const {row = 1, column = 1} = layoutParams || {};
                const layoutOption = layouts.find(l => l.key === layout) || {};
                return (
                  <RealtimeChart
                    showTimeout={!session.isExternal}
                    type="lastHour" data={(d.report || {}).last_1_hour}/>
                )
                // return (
                //   <AreaChart
                //     type="lastHour"
                //     data={(d.report || {}).last_1_hour}
                //     uniques={(d.report || {}).last_hour_uniques}
                //     maxHourly={maxHourly}
                //     cfg={{showErrors: !!isAdmin}}/>
                // )
              }
            },
            // {
            //   key: 'abtesting',
            //   label: 'A/B testing',
            //   align: 'center',
            //   renderer: d => {
            //     if(!d.abtestings || !d.abtestings[0]) {
            //       return '--';
            //     }
            //     return (
            //       <span>
            //         <Link to={`/abtesting/${d.abtestings[0].id}`}>
            //           <i className="fa fa-thermometer-full"/>
            //         </Link>
            //         {d.abtestings.length > 1 ? `and ${d.abtestings.length - 1} more` : ''}
            //       </span>
            //     )
            //   }
            // },
            {
              key: 'action',
              label: 'Actions',
              renderer: (d, index) => {
                return (
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    {
                      (isAdmin || isOperator) &&
                      <a size="small" type="link">
                        <Link to={`/widget/edit/${d.id}`}>
                          <i className={`fa fa-edit`}/></Link>
                      </a>
                    }


                    <StyledDropdown
                      closeOnClick={true}
                      up={index === widgets.length - 1}
                      useFixed={true}
                      fixedLeft={-150}
                      toggler={<i className="fa fa-ellipsis-v"/>}>
                      <div className={`menu-item`}>
                        <a onClick={e => copy(d.id)}><i className="fa fa-copy"/>Copy ID ({d.id})</a>
                      </div>
                      {
                        (isAdmin || isOperator) &&
                        <div className={`menu-item`}>
                          <a onClick={e => this.duplicateWidget(d)}><i className="fa fa-copy"/>Duplicate</a>
                        </div>
                      }
                      {
                        (isAdmin || isOperator) &&
                        <div className={`menu-item`}>
                          <a onClick={e => this.deleteWidget(d)}><i className="fa fa-trash"/>Delete</a>
                        </div>
                      }

                      <div className="menu-item">
                        <Link to={`/widget/history/${d.id}`}><i className="fa fa-clock-o"/>Revision History</Link>
                      </div>
                    </StyledDropdown>
                  </div>
                )
              }
            },
          ]}
          rows={widgets || []}
          sort={{sortColumn: sortBy, direction: sortDirection}}
          sortChange={sortBy => {
            this.setState({sortBy: sortBy}, () => {
              this.setState({sortBy: sortBy, page: 1, data: null}, () => this.cacheParams())
            });
          }}
          directionChange={direction => {
            this.setState({sortDirection: direction}, () => {
              this.setState({sortDirection: direction, page: 1, data: null}, () => this.cacheParams())
            });
          }}
          noDataText={<div style={{height: '110px', marginTop: '50px'}}>No Widgets Found</div>}
        />
      </TableScrollWrapper>
    )
  }

  renderWidgetAlgorithm(d) {
    const allAlgorithms = [...algorithms, ...adAlgorithms];
    if(d.cxenseWidget) {
      return <span className="tag algo">Cxense</span>;
    }
    else if(d.thinkWidget) {
      return <span className="tag algo">Think Analytics</span>;
    } else {
      const {layoutParams = {}} = d.layoutConfig || {};
      let usedSlots = _.uniq(Object.values(layoutParams.slotGroupMapping || {}));
      let usedSlotGroup = (d.slotGroups || []).filter(s => usedSlots.indexOf(s.name) >= 0);
      let algos = _.uniq(usedSlotGroup.map(s => s.algorithm));
      let backfill = _.uniq(_.compact(usedSlotGroup.map(s => (s.backfill || {}).algorithm)))[0];
      if(algos.length > 1) {
        return <span className="tag algo">Hybrid</span>
      } else if (algos.length === 1) {
        let algo = algos[0];
        let meta = allAlgorithms.find(d => d.key === algo);
        let backfillmeta = allAlgorithms.find(d => d.key === backfill);
        return (
          <>
            <span className="tag algo" key={algo}>{meta ? meta.label : algo}</span>
            {!!backfill && <span className="tag backfill" key={backfill}>Fallback: <strong>{backfillmeta ? backfillmeta.label : backfill}</strong></span>}
          </>
        )
      }
    }
  }

  showPreview(d) {
    const {appState: {session}} = this.props;
    const siteOption = session.sites.find(s => s.key === d.site);
    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      width: '750px',
      hideCancel: true,
      hideOK: true,
      dialogBody: (
        <div style={{maxHeight: '80vh', overflow: 'auto', marginBottom: '20px'}}>
          <PreviewBox id={d.id} site={d.site} siteOption={siteOption}/>
        </div>
      ),
      onConfirm: () => {
        CondirmDialog.closeAll();
      },
      onCancel: () => {
        CondirmDialog.closeAll();
      }
    }

    ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById('global-confirm'));
  }

  duplicateWidget(d) {
    this.setState({loading: true})
    return post('/api/duplicateWidget', {id: d.id}).then(newWidget => {
      this.setState({loading: false});
      this.props.history.push(`/widget/create/${newWidget.id}?clone=y`);
    }).catch(e => {
      this.setState({loading: false})
      console.error(e);
    })
  }

  loadLifetimeReport(widget) {
    this.setState({loading: true})
    return post('/api/queryWidgetLifetimeMetrics', {widgetId: widget.id}).then(ret => {
      widget.report = widget.report || {};
      widget.report.daily = ret.daily;
      widget.report.dailyLoaded = true;
      this.setState({loading: false});
    }).catch(e => {
      this.setState({loading: false})
      console.error(e);
    })
  }

  deleteWidget(d) {
    CondirmDialog.showConfirm(`Do you really want to delete widget ${d.name || d.id}?`, () => {
      this.setState({loading: true})
      return post('/api/deleteWidget', {id: d.id}).then(results => {
        this.setState({data: null, loading: false})
      }).catch(e => {
        this.setState({loading: false, error: e.message})
        console.error(e);
      })
    })
  }
}))