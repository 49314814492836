import styled, {css} from 'styled-components';
import {
  BlueJeans, DarkGray1, DarkGray, Grass, Sunflower, MediumGray, MediumGray1, LightGray,
  BlueJeans1
} from "../app/StyleCommon";
import {Dropdown} from "../uikit/index";

export const Wrapper = styled.div`
  min-height: 160px;
  // background: ${LightGray};
  .legend {
    display: flex;
    .legend-item {
      font-size: 12px;
      display: inline-flex;
      align-items: center;
      margin-right: 30px;
      .select-box {
        .toggler {
          border: none;
          display: inline;
          background: none;
        }
        .menu {
          top: 25px;
          text-align: left;
          right: auto;
        }
      }
    }
    .rect, .rect1, .line{
      width: 12px;
      height: 12px;
      display: inline-block;
      vertical-align: middle;
      &.green {
        background: ${Grass};
      }
      &.gray {
        background: ${MediumGray1};
      }
      &.yellow {
        background: ${Sunflower};
      }
      &.blue {
        background: ${BlueJeans};
      }
      &.green {
        background: ${Grass};
      }
    }
    .rect1 {
      width: 6px;
      height: 12px;
    }
    .line {
      width: 20px;
      height: 2px;
      background-color: gray;
      position: relative;
      &.blue:before {
        background: ${BlueJeans};
      }
      &.green:before {
        background: ${Grass};
      }
      &:before {
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        border: 1px solid white;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  svg {
    width: 100%;
    .axis-label {
      font-size: 11px;
      fill: ${DarkGray};
      //fill: ${BlueJeans1};
      font-weight: bold;
      transform-origin: 0% 0%;
      &.first {
        //transform: translate(5px, 20px);
      } 
      &.second {
        //transform: translate(-30px, 20px);
      }
      &.blue {
        fill: ${BlueJeans}
      }
    }
    .axis--x {
      path, line {
        stroke: ${MediumGray1};
      }
    }
    .axis--y,
    .axis--y1,
    .axis--y2 {
      text {
        color: ${DarkGray1} !important;
      }
      path {
        fill: none;
        stroke: none;
      }
      line {
        stroke-width: 2px;
        stroke: #f1f2f3;
        stroke-dasharray: 5, 8;
      }
    }
    .axis--y .tick line,
    .axis--y1 .tick line {
      stroke: none;
    }
    .axis--y1 {
        transform: translate(0px, 0);
        text {
          text-anchor: start;
        }
    }

    .focus {
      .line{
        stroke: ${Grass};
        stroke-width: 2px;
        fill: none;
        stroke-opacity:  .6;
        &.optimised {
          stroke: ${Grass};
        }
      }
      .bar {
        fill-opacity: 1;
        fill: ${MediumGray1};
        stroke: none;
        fill: url(#barGradient);
      }
      .circle, rect, g {
        transition: none;
        fill: ${Grass};
        &.optimised {
          fill: ${Grass};
        }
      }
    }
    .context {
      .axis {
        path, line {
          stroke: ${MediumGray};
        }
      }
      .selection, .handle {
        fill: ${BlueJeans} !important;
      }
      .handle {
        transform: translate(3px, 3px);
      }
    }
    .zoom {
      cursor: move;
      fill: none;
      pointer-events: all;
    }
    .brush {
      .selection{
        fill-opacity: .5;
      }
      .handle--e, .handle--w {
        fill: ${BlueJeans};
        fill-opacity: .5;
        cursor: ew-resize;
        width: 4px;
        height: 20px;
      }
      .brush-focus {
        fill: ${BlueJeans};
      }

      .selection {
        fill-opacity: 0.15;
        fill: ${BlueJeans};
      }
    }
  }
`;

export const TooltipWrapper = styled.div`
  font-size: 12px;
  .time {
    margin: 5px 10px;
  }
  .metricList {
    white-space: pre-wrap;
    .metric {
      white-space: nowrap;
      margin: 5px 10px;
      display: block;
      .icon {
        width: 10px;
        height: 10px;
        display: inline-block;
        margin-right: 5px;
        &.gray {
          background: ${MediumGray}
        }
        &.yellow {
          background: ${Sunflower}
        }
        &.green {
          background: ${Grass}
        }
      }
    }
  }
`;

export const MetricDropdown = styled(Dropdown)`
  display: inline-block;
  .dropdown-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    div {
      //border-bottom: 1px dotted ${MediumGray1};
    }
    i {
      margin-left: 8px;
      //display: none
      color: #aaa;
    }
  }
  .menu {
    top: 20px;
    left: 0;
    right: auto;
    .menu-item {
      text-align: left;
      a {
        text-decoration: none;
      }
      &.selected a{
        color: ${BlueJeans};
      }
      &:hover {
        background-color: ${BlueJeans};
        a {
          color: white;
        }
      }
    }
  }
  @media (max-width: 667px) {
    .menu {
      left: auto;
      right: 0;
    }
  }
`